<mbs-modal [title]="'backup:buyLicense' | i18next"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal>
  <div modal-body>
    {{ 'backup:youWillRedirectedToLicensesPage' | i18next }}
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="proceed()"> {{ 'app:buttons:proceed' | i18next }} </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'app:buttons:close' | i18next }} </mbs-button>
  </ng-container>
</mbs-modal>
