import { VirtualDisk } from '@modules/wizards/models/select-virtual-disks-models';

/**
 * Model for "Select Virtual Machines" step
 * @author Sergey.P
 */

/**
 * All variant of virtual machines type
 */
export enum VirtualMachinesPowerState {
  PoweredOff = 0,
  PoweredOn = 1,
  Suspended = 2,
  Unknown = 3
}
export enum VirtualMachinesType {
  VMWare = 0,
  HyperV = 1
}

/**
 * All variant of virtual machines selection
 */
export enum VirtualMachinesSelectedType {
  'All' = 'All',
  'Running' = 'Running',
  'Selected' = 'Selected'
}

/**
 * All variant of virtual machines selection with numbers value
 */
export enum VirtualMachinesSelectedWithNumbersType {
  'All',
  'Running',
  'Selected'
}

/**
 * This is the data model of the virtual machine obtained from the GetVirtualMachineList method
 */
export type VirtualMachine = {
  invalidName?: boolean;
  disks?: VirtualDisk[];
  invalidDisks?: boolean;
  clusterVm: boolean;
  name: string;
  displayName?: string;
  HostName?: string;
  powerState: VirtualMachinesPowerState;
  type: VirtualMachinesType;
  virtualMachineId: string;
  uiGuid?: string;
  disabled?: boolean;
};

/**
 * @interface ISelectVirtualMacinesStepValue - interface for "Select Virtual Machines" step
 * Yes, this is the type, but model always exports an interface, an we cannot extend a union type using an interface.
 */
export type SelectVirtualMachinesStepValue = {
  type: VirtualMachinesSelectedType;
  machines: string[];
  machinesFullFormat?: VirtualMachine[];
  valid: boolean;
};
