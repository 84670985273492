import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { catchError, of, take } from 'rxjs';

@Component({
  selector: 'mbs-enable-auto-renewal-modal',
  templateUrl: './enable-auto-renewal-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnableAutoRenewalModalComponent {
  public loading = false;
  public readonly alertType = MbsPopupType;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private cdr: ChangeDetectorRef) {}

  enable(): void {
    this.loading = true;

    const request = typeof this.baseModal.data.request === 'function' ? this.baseModal.data.request() : this.baseModal.data.request;

    request
      .pipe(
        take(1),
        catchError((error) => {
          this.loading = false;
          return of(error);
        })
      )
      .subscribe((res) => {
        this.loading = false;
        this.cdr.detectChanges();
        if (!res?.error) {
          this.baseModal.save();
        }
      });
  }
}
