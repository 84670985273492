import { Pipe, PipeTransform } from '@angular/core';
import { AbilityService } from './ability.service';
import { Action } from './types';

@Pipe({ name: 'can' })
export class CanPipe implements PipeTransform {
  constructor(private abilityService: AbilityService) {}

  transform(subject: any, action: Action, field?: string): any {
    return this.abilityService.can(action, subject, field);
  }
}
