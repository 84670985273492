import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from 'mbs-ui-kit';

@Component({
  selector: 'msp-buy-license-modal',
  templateUrl: './buy-license-modal.component.html'
})
export class BuyLicenseModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private router: Router) {}

  public proceed(): void {
    const licenseTypes = {
      1: 'sql',
      2: 'exch',
      7: 'baremetal',
      9: 'desktop',
      10: 'vm',
      11: 'vm',
      18: 'ultimate',
      29: 'deepinst'
    };

    this.router.navigate(['/AP/Licenses.aspx'], {
      queryParams: { buy: 'true', licenses: licenseTypes[this.baseModal.data?.licenseType] || '' }
    });
  }
}
