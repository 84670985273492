import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BuildAction } from '@components/sidepanel-downloads/sidepanel-downloads.constants';
import Brand from '@models/Brand';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';

@Component({
  selector: 'mbs-sidepanel-global-downloads-brands-modal',
  templateUrl: './brands-modal.component.html'
})
export class BrandsModalComponent implements OnInit {
  public licensedBrandList: Brand[] = [];
  public action: BuildAction = null;
  public brandForm = this.fb.group({
    brand: [null]
  });
  public confirmButtonText = null;
  public isActionActivated = false;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private fb: FormBuilder, private i18NPipe: I18NextPipe) {}

  ngOnInit() {
    const { licensedBrandList, action } = this.baseModal.data;
    this.action = action;
    this.brandForm.get('brand').patchValue(licensedBrandList[0]);
    this.licensedBrandList = licensedBrandList;
    this.confirmButtonText = this.getConfirmButtonText(action);
  }

  onActionButtonPressed(): void {
    const { brand } = this.brandForm.value;
    this.isActionActivated = true;

    this.baseModal.save(brand.id);
  }

  private getConfirmButtonText(action: BuildAction): string {
    switch (action) {
      case BuildAction.Download:
        return this.i18NPipe.transform('common.download', { format: 'title' });
      case BuildAction.CopyLink:
        return this.i18NPipe.transform('buttons:copyLink', { format: 'title' });
      case BuildAction.CopyCommand:
        return this.i18NPipe.transform('download-build.module:copyInstallationScript', { format: 'title' });
    }
  }
}
