import { VirtualMachinesFromBackend } from '@modules/wizards/models/base/virtual-machine-from-backend';
import { EncryptionAlgorithm } from '../compression-encription-models';
import { DeleteFileSettingsBase } from './base-models/plan-delete-file-settings-model';
import { EncryptionSettings } from './base-models/plan-encryption-settings-model';
import { SyntheticFull } from './base-models/plan-syntetic-full-model';
import { BasePlan } from './base-plan-models';

export type HyperVMachineExDisk = { UUID: string; Path: string; Enabled: boolean; uiGuid?: string };

export type HyperVMachineExType = {
  VMName: string;
  VMUUID: string;
  SnapshotConfig: { Disks: HyperVMachineExDisk[] };
};

export class BaseHyperVPlanModel extends BasePlan implements EncryptionSettings, DeleteFileSettingsBase, SyntheticFull {
  EncryptionAlgorithm: EncryptionAlgorithm;
  EncryptionKeySize: number;
  IsSyntheticSupported: boolean;
  RetentionDelay: string;
  RetentionDeleteLastVersion: boolean;
  RetentionNumberOfVersions: number;
  RetentionTime: string;
  RetentionUseDefaultSettings: boolean;
  SyntheticFull: boolean;
  UseBackupDate: boolean;
  UseServerSideEncryption: boolean;

  public HyperVMachinesEx: HyperVMachineExType[];
  public VirtualMachines: VirtualMachinesFromBackend[];
  constructor() {
    super();
  }
}
