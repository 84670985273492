export function isFontExists(fontName) {
  try {
    const code = document.getElementsByTagName('body')[0];
    const aDoc = document.createElement('span');

    aDoc.style.fontSize = '72px';
    aDoc.innerHTML = fontName;
    aDoc.style.fontFamily = 'noone_would_call_the_font_like_this';
    code.appendChild(aDoc);

    const eCode = aDoc.offsetWidth;
    const dCode = aDoc.offsetHeight;
    code.removeChild(aDoc);

    aDoc.style.fontFamily = fontName + ',noone_would_call_the_font_like_this';
    code.appendChild(aDoc);
    const fontRes = aDoc.offsetWidth !== eCode || aDoc.offsetHeight !== dCode;
    code.removeChild(aDoc);

    return fontRes;
  } catch (error) {
    // no-empty
  }
  return null;
}
