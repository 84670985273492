<mbs-modal [isCustomModal]="true" [showFooter]="true" [size]="MbsSize.xl" #modal [loading]="licenseTypesLoading">
  <ng-container modal-body *ngIf="!licenseTypesLoading">
    <div class="mb-2">Available licenses:</div>
    <mbs-table-grid [data]="licensesTypes" [headers]="headers" [showNav]="false">
      <ng-template let-license mbsTableCell>
        {{ license.name }}
      </ng-template>
      <ng-template let-license mbsTableCell>
        {{ license.count }}
      </ng-template>

      <ng-template let-license mbsTableCell>
        <mbs-button
          [type]="'success'"
          [loading]="loading"
          *ngIf="license.count && license.count > 0"
          [size]="'xs'"
          (click)="grantLicense(license.id)"
          >Grant</mbs-button
        >
      </ng-template>
    </mbs-table-grid>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button type="success" (click)="buyLicense()">Buy Now</mbs-button>
    <mbs-button type="secondary" data-dismiss="modal" (click)="modal.close()">Cancel</mbs-button>
  </ng-container>
</mbs-modal>
