import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, shareReplay } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ConnectSettingsService {
  private connectClientUrl: Observable<string>;

  constructor(private http: HttpClient) {
    this.connectClientUrl = this.http.get<string>(`/api/build/connect-url`).pipe(shareReplay());
  }

  public getConnectClientBuild(): Observable<string> {
    return this.connectClientUrl;
  }
}
