import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { ButtonModule } from 'mbs-ui-kit';
import { ErrorComponent } from './error.component';

@NgModule({
  imports: [CommonModule, I18NextModule, ButtonModule],
  declarations: [ErrorComponent],
  exports: [ErrorComponent]
})
export class ErrorModule {}
