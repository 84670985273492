<mbs-modal class="approve-modal"
           [title]="baseModal.data.title || titles[TFAParams.actionType]"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           [size]="MbsSize.sm">

  <ng-container modal-body>
    <mbs-incorrect-time-alert  *ngIf="isInvalidLocalTime" [expiredDate]="TFAParams.timeExpired"></mbs-incorrect-time-alert>

    <ng-container *ngIf="isDeclined">
      {{ 'tfa:TFADisableModal:actionDeclined' | i18next }}
    </ng-container>

    <ng-container *ngIf="!isDeclined">

      <ng-container *ngIf="!isCodeAuth && !TFAParams.isGoogleAuth">

        <span *ngIf="!isTimeExpired">
          <span>{{ 'tfa:confirmLogin:actionRequiresTFA' | i18next }}</span>
          <mbs-tfa-timer class="ml-1" *ngIf="!isInvalidLocalTime" [expiredDate]="TFAParams.timeExpired" (dateExpired)="isTimeExpired = true"></mbs-tfa-timer>.
          <span *ngIf="isInvalidLocalTime">limited period of time</span>
          {{ 'tfa:confirmLogin:confirmItOnRegisteredDevice' | i18next }}
        </span>

        <span *ngIf="isTimeExpired">
          {{ 'tfa:TFADisableModal:requestIsTimeout' | i18next }}
        </span>

      </ng-container>

      <ng-container *ngIf="TFAParams.isGoogleAuth && !isCodeAuth">

        {{ 'tfa:confirmLogin:actionRequiresTFAGoogle' | i18next }}

        <div class="form-row align-items-end mt-2">

          <div class="col-10">
            <mbs-input [label]="'tfa:confirmLogin:TFAVerificationCode' | i18next"
                       [isErrorsShow]="false"
                       [formControl]="googleCode"
                       (keyup.enter)="confirmGoogle()"
                       autocomplete="off"
                       name="two-fa-code"
                       mbsAutoFocus></mbs-input>
          </div>

          <div class="col-2">
            <mbs-button [block]="true"
                        [loading]="isLoading"
                        [disabled]="!googleCode.value"
                        (click)="confirmGoogle()"
                        type="primary">
              {{ 'tfa:validate' | i18next: { format: 'title' } }}
            </mbs-button>
          </div>

        </div>

        <mbs-input-errors *ngIf="googleCode.invalid && googleCode.touched && googleCode.errors?.tfa"
                          [errors]="[googleCode.errors?.tfa]"></mbs-input-errors>

      </ng-container>

      <mbs-alert [type]="alertType.info"
                 [icon]="true"
                 *ngIf="TFAParams.isRecoveryCodeUsageAvailable && !isCodeAuth"
                 class="mt-3">

        {{ 'tfa:TFADisableModal:' + (TFAParams.isGoogleAuth ? 'casUseRecoveryCodesGoogleAuth' : 'casUseRecoveryCodes') |
        i18next }}

        <mbs-button [isCtrl]="true"
                    (click)="toggleAuthType()"
                    class="d-block">
          {{ 'tfa:TFADisableModal:useRecoveryCodes' | i18next }}
        </mbs-button>

      </mbs-alert>

      <div class="d-flex align-items-center mt-2"
           *ngIf="!isCodeAuth && !TFAParams.isTFADisableAction && !TFAParams.doNotDisturbEnabled">

        <mbs-checkbox [(ngModel)]="isNotAskTFA"
                      [label]="'tfa:confirmLogin:doNotAskTFA' | i18next"></mbs-checkbox>

        <mbs-select [(ngModel)]="selectedPeriod"
                    [bindValue]="'value'"
                    [bindLabel]="'label'"
                    [clearable]="false"
                    [items]="periods"
                    [id]="'defaultAuthType'"
                    [dropdownPosition]="'bottom'"
                    appendTo="body"
                    [disabled]="!isNotAskTFA"
                    [multiple]="false"
                    [searchable]="false"
                    class="ml-2 period-select"></mbs-select>
      </div>

      <ng-container *ngIf="isCodeAuth">

        <mbs-input mbsAutoFocus
                   (keyup.enter)="confirmRecoveryCode()"
                   [imaskOpts]="recoveryCodeMask"
                   [unmask]="true"
                   [formControl]="recoveryCode"
                   [label]="'tfa:TFADisableModal:TFARecoveryCode' | i18next"></mbs-input>

      </ng-container>

    </ng-container>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button type="primary"
                data-dismiss="modal"
                [loading]="isLoading"
                *ngIf="!isCodeAuth && !TFAParams.isGoogleAuth && isTimeExpired"
                (click)="tryAgain()">
      {{ 'tfa:TFADisableModal:tryAgain' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button type="primary"
                data-dismiss="modal"
                [loading]="isLoading"
                *ngIf="isCodeAuth"
                (click)="confirmRecoveryCode()">
      {{ 'tfa:confirm' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button type="secondary"
                data-dismiss="modal"
                *ngIf="!TFAParams.isRecoveryCodes"
                (click)="baseModal.close()">
      {{ 'tfa:TFADisableModal:close' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
