import { Component, forwardRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { WizardComponent } from 'mbs-ui-kit';
import { first } from 'rxjs/operators';
import { IBBModelForStepsHelper } from '../../helpers/image-based-wizard-helpers/ibb-model-for-steps-helper';
import { IBBPlanFromStepsHelper } from '../../helpers/image-based-wizard-helpers/ibb-plan-from-steps-helper';
import { SupportMethodsForStepsFromPlan } from '../../helpers/support-methods-for-steps-from-plan';
import { BackupDiskImagePlan } from '../../models/base/ibb-plan-model';
import { PlanResponseModel } from '../../models/base/plan-response-model';
import { IBBFormModel } from '../../models/ibb/ibb-form-model';
import { WizardsServicesHubService } from '../../services/wizards-services-hub.service';
import { StepLoadInfo } from '../../steps/StepBase.class';
import { WizardBaseClass } from '../wizard-base.class';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';

@UntilDestroy()
@Component({
  selector: 'mbs-image-based-wizard',
  templateUrl: './image-based-wizard.component.html',
  providers: [
    {
      provide: WizardBaseClass,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ImageBasedWizard)
    }
  ]
})
export class ImageBasedWizard extends WizardBaseClass {
  public restoreVerificationEnabled = true;
  public fromGettingStarted = false;
  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;
  constructor(services: WizardsServicesHubService, public i18nPipe: I18NextPipe, stepService: WizardStepsService) {
    super(services, i18nPipe, stepService);
    this.fromGettingStarted = services.mainService.fromGettingStarted;

    this.FormModel = new IBBFormModel(this.isNBF, this.isLinux);
    if (!this.isRDMode || (this.isRDMode && this.isEdit)) {
      this.myPlan$
        .pipe(
          first((p) => !!p),
          untilDestroyed(this)
        )
        .subscribe((plan) => plan && this.updateStepData(plan));
      return;
    }
    this.updateStepData(new PlanResponseModel(), true);
  }

  updateStepData(plan, isDefault = false): void {
    this.setLoadedPlanAndRunChainRequestsForBackup(plan);

    if (!isDefault) {
      const formData = IBBModelForStepsHelper.IBBModelFromPlan(plan, this.defaultSettings());
      if (this.isRDMode) formData.advancedOptionsFlags = this.FormModel.advancedOptionsFlags;
      this.FormModel = formData;
    } else {
      this.FormModel.planNameStep.Name = SupportMethodsForStepsFromPlan.generatePlanName(
        this.mainService.compName,
        this.planType,
        this.mainService.timeZoneOffset
      );
      this.loadedPlan.plan = new BackupDiskImagePlan();
      this.updateStorages();
    }
  }

  restoreVerificationEnabledHandler(event: boolean): void {
    this.restoreVerificationEnabled = event;
  }

  protected loadInfoHandlerFinally(event?: StepLoadInfo) {
    if (this.currentStep.title === 'Consistency Check and Restore Verification' && (!event || !event.loading) && event.isHyperV) {
      this.wizard.changeStep(1);
      const step = this.wizard.steps.find((s) => s.title === 'Schedule Options');

      if (step) {
        this.wizard.currentStep.valid = true;
        this.wizard.currentStep = step;
      }
    }
  }

  handleSave(): void {
    const newPlan = IBBPlanFromStepsHelper.getUpdatedPlan(
      this.loadedPlan.plan,
      this.FormModel,
      this.defaultSettings(),
      this.isNBF,
      this.isLinux
    );
    this.savePlan(newPlan);
  }
}
