import { DiskImageRestoreTypeEnum, VirtualDiskFileFormat } from '../type-data-models';
import { CompressionAlgorithm } from './base-models/plan-compression-algorithm-model';
import { GlobalNotificationSettings } from './base-models/plan-global-notification-settings-model';
import { RestoreToAzureArgs } from './base-models/plan-restore-to-azure-args-model';
import { RestoreToEC2Args } from './base-models/plan-restore-to-ec2-args-model';
import { RestoreToGCEArgs } from './base-models/plan-restore-to-gce-args-model';
import { RestoreToVirtualDiskArgs } from './base-models/plan-restore-to-virtual-disk-args-model';
import { RestoreToVMwareArgs } from './base-models/plan-restore-to-vm-ware-args-model';
import { RestorePlan } from './restore-plan-model';

export class RestoreIbbBase extends RestorePlan {
  public DiskImageRestoreType: DiskImageRestoreTypeEnum;
  public SkipLostRevisions: boolean;
  public RemoteRestorePrefix: string;
  public IsRemoteRestore: boolean;
  public DiskPrepareOptions: any;
  public RemoteNotification: GlobalNotificationSettings;
  public RestoreToVirtualDisk: RestoreToVirtualDiskArgs;
  public RestoreToGCETemporaryInstance: RestoreToGCEArgs;
  public RestoreToGCE: RestoreToGCEArgs;
  public RestoreToAzureTemporaryInstance: RestoreToAzureArgs;
  public RestoreSourceSummary: string;
  public RestoreToAzure: RestoreToAzureArgs;
  public RestoreToEC2: RestoreToEC2Args;
  public RestoreToEC2TemporaryInstance: RestoreToEC2Args;
  public DestinationSummary: string;
  public VirtualDiskFileFormatList: VirtualDiskFileFormat[];
  public DefaultVirtualDiskFileFormat: VirtualDiskFileFormat;
  public ResetControllerConfigForPhysicalRestore: boolean;
  public RestoreToVMware: RestoreToVMwareArgs;
  public SpecificPartitions: boolean;
  public VirtualImageFileName: string;
  public OverwriteExisting: boolean;
  public FromExport: boolean;
  public IsRestoreTest: boolean;
  public DiskImages: any[];
  public GenericDiskAdapterType: any;
  public DiskImageFileFormat: VirtualDiskFileFormat;
  public RestoreDiskConfiguration: any;
  public ConvertToMBR: boolean;
  public DiskInfo: any[];
  public CompressionAlgorithm: CompressionAlgorithm;
  public VitrualDiskCapacity: number | string;
  public ForExport: boolean;
}
