<mbs-modal [isCustomModal]="true"
           [loading]="loading">
  <ng-container modal-header>{{title}}</ng-container>
  <ng-container modal-body>
    <div class="tree-in-modal">
      <mbs-tree *ngIf="treeData?.length"
                #mbsTreeComponent
                mode="file"
                [attr.data-test-element]="elementsSelector.name.treeElement"
                [height]="'460px'"
                (mbsChange)="changeTreeHandler($event)"
                [disableIfLoadingSubtree]="true"
                [childrenManualControl]="true"
                [offCheckAndIndeterminate]="true"
                [data]="treeData"
                [disableChildren]="false"
                [dontSelectChildren]="false"
                [loadMore]="getSubtree.bind(this)"
                [getItems]="getSubtree.bind(this)"
                [itemsCanBeHidden]="true"
                [onlyOneSelect]="onlyOneSelect"
                [lazy]="true">
        <ng-template treeElement
                     let-item>
          <span class="form-row align-items-center flex-nowrap _hover-parent">
            <span class="col-auto">
              <img [src]="item.icon"
                   class="tree-in-modal_img-main"
                   alt="Tree icon {{ item.id }}">
            </span>
            <span class="col overflow-hidden">
              <pre class="tree-in-modal_pre-wrap mb-0">{{ item.label }}</pre>
            </span>
            <span *ngIf="isVirtual && item.showNTFSAlert"
                  container="body"
                  class="ico ico-OverdueSettings tooltip-btn text-orange ml-1 mr-2"
                  placement="top"
                  [attr.data-test-element]="elementsSelector.name.nonNtfsIcon"
                  [ngbTooltip]="'wizards:selected_virtual_disks_non_ntfs_disk' | i18next"></span>
          </span>
        </ng-template>
      </mbs-tree>
    </div>

    <mbs-alert *ngIf="firstLoadError"
               [attr.data-test-element]="elementsSelector.name.errorAlert"
               [type]="mbsPopupType.danger"
               [icon]="true">
      <div *ngIf="firstLoadError.title"><strong>{{firstLoadError.title}}</strong></div>
      <div>{{firstLoadError.details}}</div>
    </mbs-alert>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button *ngIf="!firstLoadError"
                type="primary"
                [attr.data-test-element]="elementsSelector.name.confirmButton"
                (click)="save()">
      {{ 'buttons:confirm' | i18next }}
    </mbs-button>
    <mbs-button data-dismiss="modal"
                type="secondary"
                (click)="close()">
      {{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
