import * as moment from 'moment';

export function getSecondsFromDate(toDate: string | Date, fromDate = new Date()) {
  const diff = moment(toDate).diff(fromDate);

  return Math.floor(moment.duration(diff).asSeconds());
}

export function getInvalidDiffTime(timeToExpired: number) {
  const minutes = Math.floor(Math.abs(timeToExpired) / 60);
  const seconds = ('0' + Math.abs(timeToExpired) % 60).slice(-2);

  return `${minutes}:${seconds}`;
}
