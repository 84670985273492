import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'boolDisplay' })
export class BoolDisplayPipe implements PipeTransform {
  transform(value: boolean, mode: 'yes' | 'enabled' = 'yes'): any {
    if (mode == 'enabled') {
      return value ? 'Enabled' : 'Disabled';
    }
    return value ? 'Yes' : 'No';
  }
}
