import { BackupFileFormModel } from '../../models/backupFile/backup-form-model';
import { ScheduleType } from '../../models/schedule-models';
import { BaseForPlanHelper } from '../bases/base-for-plan-helper';
import { WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { PlanDataFromStepsHelper } from '../plan-data-from-steps-helper/plan-data-from-steps-helper';

export class BackupFilePlanFromStepsHelper extends BaseForPlanHelper {
  public static BackupModel: BackupFileFormModel = null;

  public static getUpdatedPlan(plan, model: BackupFileFormModel, settings: WizardHelpersSettings, isNBF = false, isLinux = false): any {
    this.BackupModel = model;
    this.isNBF = isNBF;
    this.isLinux = isLinux;
    this.updateSchedules(+ScheduleType[model.scheduleStep.ScheduleType] === ScheduleType.recurring, this.BackupModel.scheduleAdvancedStep);
    const newPlan = Object.assign({}, plan);
    this.updateModel(newPlan, settings);
    return newPlan;
  }

  static updateModel(newPlan, settings: WizardHelpersSettings): void {
    this.BackupModel.compressionEncryptionStep.UseFileNameEncryption = !!this.BackupModel.compressionEncryptionStep.UseFileNameEncryption;
    PlanDataFromStepsHelper.updateNewPlanByNameStep(newPlan, this.BackupModel.planNameStep);
    PlanDataFromStepsHelper.updateNewPlanByWhereBackupStep(newPlan, this.BackupModel.whereBackupStep, this.isNBF || this.isLinux);
    PlanDataFromStepsHelper.updateNewPlanByWhatBackupTreeStep(newPlan, this.BackupModel.whatBackupTreeStep);
    PlanDataFromStepsHelper.updateNewPlanByCompressionEncryptionStep(newPlan, this.BackupModel.compressionEncryptionStep, this.isNBF);

    if (!this.isNBF) {
      PlanDataFromStepsHelper.updateNewPlanByRetentionPolicyStep(
        newPlan,
        this.BackupModel.retentionPolicyStep,
        this.BackupModel.whereBackupStep.IsHybridBackup,
        this.isLinux
      );
      PlanDataFromStepsHelper.updateNewPlanByScheduleStep(
        newPlan,
        this.BackupModel.scheduleStep,
        this.BackupModel.scheduleAdvancedStep,
        this.BackupModel.simpleScheduleStep,
        this.isLinux
      );
    } else {
      PlanDataFromStepsHelper.updateNewPlanArchiveByArchiveConsistencyStep(newPlan, this.BackupModel.archiveConsistencyStep);
      PlanDataFromStepsHelper.updateNewNBFPlanByRetentionPolicyStep(newPlan, this.BackupModel.retentionPolicyStep, !this.isLinux);
      PlanDataFromStepsHelper.updateNewNBFPlanByScheduleStep(newPlan, this.BackupModel.scheduleStep, this.isLinux);
    }

    PlanDataFromStepsHelper.updateNewPlanByAdvancedOptionsStep(
      newPlan,
      this.BackupModel.advancedOptionsStep,
      this.BackupModel.advancedOptionsFlags,
      settings.planType,
      this.isNBF,
      this.isLinux
    );
    PlanDataFromStepsHelper.updateNewPlanByAdvancedFilterStep(newPlan, this.BackupModel.advancedFilterStep, this.isLinux);
    if (this.BackupModel.scheduleStep.ScheduleType !== 'instantly') {
      PlanDataFromStepsHelper.updateNewPlanByPrePostActionsStep(
        newPlan,
        this.BackupModel.prePostActionsStep,
        !settings.allowPrePostActions,
        this.isNBF,
        this.isLinux
      );
    } else newPlan.Actions = { Pre: null, Post: null };
    PlanDataFromStepsHelper.updateNewPlanByNotificationStep(newPlan, this.BackupModel.notificationStep, this.isLinux);
    PlanDataFromStepsHelper.preparePlanForSending(newPlan, !settings.isRD);
  }
}
