import { FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectorRef, Component, ExistingProvider, forwardRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import {
  DatabaseBackupType,
  DatabaseItem,
  SQLSourceDatabasesStepValue,
  SQLSourceDatabasesStepValueForm
} from '@modules/wizards/models/sql-source-databases-models';
import { FormsUtil, ModalService, ModalSettings, TableHeader } from 'mbs-ui-kit';
import { I18NextPipe } from 'angular-i18next';
import { isEqual } from 'lodash';

/**
 * Component for "SQL source databases" step
 * Used within the SQL wizard
 * @authors Roman.sh
 */
const SQLSourceDatabasesStepValueAccessor: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SQLSourceDatabasesStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-sql-source-databases-step',
  templateUrl: './sql-source-databases-step.component.html',
  providers: [SQLSourceDatabasesStepValueAccessor]
})
export class SQLSourceDatabasesStepComponent extends StepBase<SQLSourceDatabasesStepValue> implements OnInit {
  public readonly databaseBackupType = DatabaseBackupType;
  public readonly elementsSelector = {
    name: {
      databaseBackupTypeSelected: 'database-backup-type-selected',
      databaseListSelect: 'database-list-select'
    },
    id: {
      databaseBackupTypeAll: 'database-backup-type-all',
      databaseBackupTypeUser: 'database-backup-type-user',
      databaseBackupTypeSelected: 'database-backup-type-select',
      copyOnlyCheckbox: 'copy-only-checkbox'
    }
  };
  public readonly tableHeaders: TableHeader[] = [
    { name: this.i18nPipe.transform('wizards:sql:sqlDatabasesTableDBName'), gridColSize: '100fr', overflow: true }
  ];

  public showValidStatusListSelect = false;

  public databases: DatabaseItem[] = [];
  public selectedDatabases: string[] = [];
  public disabledRowBy = { key: 'disabled', value: true };

  private modalSettings: ModalSettings = {
    header: { title: this.i18nPipe.transform('wizards:sql:sqlDatabasesCopyOnlyModalTitle', { format: 'title' }) },
    footer: {
      okButton: { text: this.i18nPipe.transform('buttons:enable', { format: 'title' }) },
      cancelButton: { text: this.i18nPipe.transform('buttons:cancel', { format: 'title' }) }
    }
  };

  @ViewChild('copyOnlyModal', { static: true, read: TemplateRef }) copyOnlyModal: TemplateRef<any>;

  constructor(
    public mainService: RemoteManagementWizardsService,
    public i18nPipe: I18NextPipe,
    private cdr: ChangeDetectorRef,
    public modalService: ModalService
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();

    this.mainService.dataBases$
      .pipe(untilDestroyed(this))
      .subscribe({ next: (result: DatabaseItem[]) => this.updateDataFromLoadedDatabases(result || []) });
  }

  initForm(): void {
    this.stepForm = new FormGroup<SQLSourceDatabasesStepValueForm>({
      databaseBackupType: new FormControl(DatabaseBackupType.All),
      selectedDatabases: new FormControl([], Validators.required),
      copyOnly: new FormControl(false)
    });

    this.initFormEvents();
  }

  onStepFormChange(value: SQLSourceDatabasesStepValue): void {
    if (this.value) this.value = { ...value, valid: this.stepForm.valid };
  }

  forceValid(data: any = null): void {
    FormsUtil.triggerValidation(this.stepForm);
    this.resetValidStateForValidFields();

    this.showValidStatusListSelect = true;
  }

  updateDataFromLoadedDatabases(databases: DatabaseItem[]): void {
    const selected = [];
    const newDBItems = [];

    databases.forEach((dbItem: DatabaseItem) => {
      const newItem = { ...dbItem, IsChecked: this.stepForm.get('selectedDatabases').value.includes(dbItem.Name) };

      if (newItem.IsChecked) selected.push(newItem.Name);

      newDBItems.push(newItem);
    });

    this.databases = newDBItems;
    this.databasesCheckedHandler(selected);
    this.databaseBackupTypeChangeHandler(this.value?.databaseBackupType);
  }

  databaseBackupTypeChangeHandler(backupType: DatabaseBackupType): void {
    this.showValidStatusListSelect = false;

    if (backupType === DatabaseBackupType.Selected) {
      this.stepForm.get('selectedDatabases').enable();
      this.databases = this.databases.map((dbItem: DatabaseItem) => ({ ...dbItem, disabled: false }));

      return;
    }

    this.stepForm.get('selectedDatabases').disable();
    this.databases = this.databases.map((dbItem: DatabaseItem) => ({ ...dbItem, disabled: true }));
  }

  databasesCheckedHandler(selectedDatabases: string[]): void {
    if (this.databases.length && !isEqual(selectedDatabases, this.selectedDatabases)) {
      this.showValidStatusListSelect = false;

      this.selectedDatabases = selectedDatabases;

      if (this.value?.databaseBackupType === DatabaseBackupType.Selected) {
        this.stepForm.get('selectedDatabases').setValue(this.selectedDatabases);
      }

      this.cdr.detectChanges();
    }
  }

  copyOnlyChangeHandler(event: boolean): void {
    if (event) {
      this.modalService
        .open(this.modalSettings, this.copyOnlyModal)
        .then((result: boolean) => !result && this.resetCopyOnly())
        .catch(() => this.resetCopyOnly());
    }
  }

  private resetCopyOnly(): void {
    this.stepForm.get('copyOnly').reset(false);
  }
}
