<mbs-modal [title]="'computers.module:modals.backupAgentUpdateRequiredTitle' | i18next">
  <ng-container modal-body>
    <mbs-alert [type]="alertType"
               [icon]="true">
      {{ 'computers.module:modals.backupAgentUpdateRequiredText' | i18next: { computerName } }}
      <a *ngIf="'HelpMarketing' | can: 'read'"
         target="_blank"
         href="https://kb.msp360.com/kb10140">
        {{ 'buttons:learnMore' | i18next }}
      </a>
    </mbs-alert>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                (click)="baseModal.save(true)">
      {{ 'computers.module:modals.backupAgentUpdateRequiredButton' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
