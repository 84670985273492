import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwitchCommercial } from '@models/storage-accounts/SwitchCommercial';
import { UnifiedBillingAccount } from '@models/storage-accounts/UnifiedBillingAccount';
import StorageAccount from '@models/StorageAccount';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { CompanyType } from '@services/storage-accounts/storage-accounts.types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
// todo replace to @ngrx/data maybe
export class StorageAccountsService extends EntityCollectionServiceBase<StorageAccount> {
  private myLoadedFree$ = new BehaviorSubject<any>(false);
  public loadedFree$ = this.myLoadedFree$.asObservable();

  private myEntitiesFree$ = new BehaviorSubject<any>(null);
  public entitiesFree$ = this.myEntitiesFree$.asObservable();

  private myUnifiedBillings$ = new BehaviorSubject<{ totalCount: number; items: UnifiedBillingAccount[] }>({ totalCount: 0, items: [] });
  public unifiedBillings$ = this.myUnifiedBillings$.asObservable();

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super('StorageAccount', serviceElementsFactory);
  }

  public getS3(): Observable<StorageAccount[]> {
    return this.http.get<StorageAccount[]>('api/email-service-settings/available-s3-accounts');
  }

  public getCblStorage(): Observable<any> {
    return this.http.get('Admin/StorageAccounts/cblStorageExists').pipe(
      tap((cblStorage) => {
        this.myEntitiesFree$.next(cblStorage);
        this.myLoadedFree$.next(true);
      })
    );
  }

  public getStorageAccountsForRD(): Observable<any> {
    return this.http.get('apiRD/storageAccounts/');
  }

  public switchToCommercial(storageAccountId: string): Observable<SwitchCommercial> {
    return this.http.put<SwitchCommercial>(`/api/StorageAccounts/${storageAccountId}/SwitchToPaidMode`, null);
  }

  public getMinimumStorageDurationForWasabi(storageAccountId: string): Observable<{ duration: number }> {
    return this.http.get<{ duration: number }>(`/api/StorageAccounts/${storageAccountId}/MinimumStorageDuration`);
  }

  public minimumStorageDurationForWasabi(storageAccountId: string, duration: number): Observable<any> {
    return this.http.put<any>(`/api/StorageAccounts/${storageAccountId}/MinimumStorageDuration`, { minimumStorageDuration: duration });
  }

  public getBuyLink(storageAccountId: string): Observable<string> {
    return this.http.get<any>(`/api/StorageAccounts/${storageAccountId}/SwitchToPaidMode/geturl`).pipe(pluck('url'));
  }

  public getUnifiedBillings(
    params: HttpParams | { [key: string]: string } = { visible: 'true' }
  ): Observable<{ totalCount: number; items: UnifiedBillingAccount[] }> {
    const result$ = new Subject<{ totalCount: number; items: UnifiedBillingAccount[] }>();
    this.http.get<{ totalCount: number; items: UnifiedBillingAccount[] }>('/api/StorageAccounts/ub', { params }).subscribe(
      (data) => result$.next(data),
      (err) => result$.error(err),
      () => result$.complete()
    );

    result$.subscribe((data) => this.myUnifiedBillings$.next(data));
    return result$.asObservable();
  }

  public cancelDeletionUb(storageAccountId: string): Observable<any> {
    return this.http.put<SwitchCommercial>(`/api/StorageAccounts/${storageAccountId}/CancelDeletion`, null);
  }

  canSwitchToPaidMode(storageId: string): Observable<boolean> {
    return this.http.get<{ allowed: boolean }>('api/StorageAccounts/ub/CanSwitchToPaidMode').pipe(map((m) => m.allowed));
  }

  getCompanies(): Observable<CompanyType[]> {
    return this.http.get<CompanyType[]>('/Admin/StorageAccounts/GetProvidersCompanies');
  }
}
