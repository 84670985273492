import { RecurringSchedule } from '@models/ScheduleModel';

export type AllowSettings = { all: boolean; compression: boolean; encryption: boolean };

export class BaseForPlanHelper {
  public static isNBF = false;
  public static FullRecurringSchedule: RecurringSchedule = null;
  public static BlockLevelRecurringSchedule: RecurringSchedule = null;
  public static ScheduleDiff: RecurringSchedule = null;
  public static ScheduleTLog: RecurringSchedule = null;
  public static isLinux = false;

  static updateSchedules(needUpdate: boolean, step): void {
    if (needUpdate) {
      this.FullRecurringSchedule = step.forceFull ? step.forceFull.FormSchedule : null;
      this.BlockLevelRecurringSchedule = step.blockLevel ? step.blockLevel.FormSchedule : null;
      this.ScheduleDiff = step.scheduleDiff ? step.scheduleDiff.FormSchedule : null;
      this.ScheduleTLog = step.scheduleTLog ? step.scheduleTLog.FormSchedule : null;
    }
  }
}
