import { PlanFormatTypes, PlanTypes } from '@models/PlanTypes.enum';
import { OsType } from '@models/Computer';

export enum KindPlanEnum {
  Backup = 0,
  Restore = 1,
  ConsistencyCheck = 2
}

export type AvailablePlanType = { kind: KindPlanEnum; type?: PlanTypes; planType: PlanTypes; format?: PlanFormatTypes; needShow?: boolean };
export type AvailablePlanTypeStr = { kind: string; type?: string; planType: string; format?: string; needShow?: boolean };

export type AvailablePlanTypes = Array<AvailablePlanType | AvailablePlanTypeStr>;
