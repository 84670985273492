import { Injectable } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { SortParams } from '@models/Paging';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import { OnlineAccessPagingStoreActions, OnlineAccessPagingStoreSelectors } from '../store';

@Injectable()
export class OnlineAccessPagingFacade {
  currentPageNumber$ = this.store.select(OnlineAccessPagingStoreSelectors.selectCurrentPageNumber);
  currentPerPageSetting$ = this.store.select(OnlineAccessPagingStoreSelectors.selectPerPage);
  currentPageIds$ = this.store.select(OnlineAccessPagingStoreSelectors.selectCurrentPage);
  currentPageData$ = combineLatest([this.currentPageIds$, this.facade.computerEntities$]).pipe(
    map(([ids, entities]) => ids.map((id) => entities[id]))
  );
  sortSettings$ = this.store.select(OnlineAccessPagingStoreSelectors.selectSortSettings);
  total$ = this.store.select(OnlineAccessPagingStoreSelectors.selectTotalItems);
  pageLoading$ = this.store.select(OnlineAccessPagingStoreSelectors.selectLoading);
  loaded$ = this.store.select(OnlineAccessPagingStoreSelectors.selectLoaded);
  loading$ = combineLatest([this.pageLoading$, this.facade.computerLoading$]).pipe(map(([p, c]) => p || c));

  constructor(private store: Store, private facade: ComputersFacade) {}

  // Store
  loadPage(params: { pageNumber?: number; perPage?: number; isFullRefresh?: boolean }) {
    this.store.dispatch(
      OnlineAccessPagingStoreActions.loadPage({
        pageNumber: params.pageNumber,
        perPage: params.perPage,
        isFullRefresh: params.isFullRefresh
      })
    );
  }

  clearPages() {
    this.store.dispatch(OnlineAccessPagingStoreActions.clearPages());
  }

  setSortSettings(sort: SortParams) {
    this.store.dispatch(OnlineAccessPagingStoreActions.setSortSettings({ sort }));
  }

  setPageNumber(pageNumber: number) {
    this.store.dispatch(OnlineAccessPagingStoreActions.setPageNumber({ pageNumber }));
  }

  setPerPageSetting(perPage: number) {
    this.store.dispatch(OnlineAccessPagingStoreActions.setPerPageSetting({ perPage }));
  }

  refresh() {
    this.loadPage({ isFullRefresh: true });
  }
}
