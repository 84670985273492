<mbs-modal [title]="'wizards:addNetworkShare' | i18next: { format: 'title' }"
           [loading]="loading">
  <ng-container modal-body
                [formGroup]="form">
    <div *ngIf="viewMode === networkShareViewMode.form"
         [attr.data-test-element]="elementsSelector.name.formBlock">
      <mbs-button *ngIf="networkSharePathArray?.length"
                  class="mb-3"
                  [attr.data-test-element]="elementsSelector.name.backButton"
                  [disabled]="loading || credentialsLoading"
                  [isCtrl]="true"
                  (click)="backToListClickHandler()">
        <span class="fa fa-arrow-left"></span>
        {{ 'wizards:addNetworkShareBack' | i18next }}
      </mbs-button>

      <mbs-form-group>
        <mbs-input formControlName="path"
                   name="newPath"
                   (input)="resetTestResult()"
                   [id]="'new-path-input-1'"
                   [label]="'wizards:addNetworkSharePath' | i18next"
                   [placeholder]="'wizards:addNetworkSharePathPlaceholder' | i18next"
                   [validationWhitespace]="true"></mbs-input>
      </mbs-form-group>

      <div class="form-row mb-3">
        <div class="col-6">
          <mbs-input formControlName="login"
                     name="login"
                     (input)="resetTestResult()"
                     [id]="'login-input'"
                     [label]="'wizards:addNetworkShareLogin' | i18next"
                     [placeholder]="'wizards:addNetworkShareLoginPlaceholder' | i18next"
                     [validationWhitespace]="true"></mbs-input>
        </div>
        <div class="col-6">
          <mbs-input-password formControlName="password"
                              (input)="resetTestResult()"
                              [label]="'wizards:addNetworkSharePass' | i18next"
                              [placeholder]="'wizards:addNetworkSharePassPlaceholder' | i18next"
                              [id]="'add-network-share-password-input'"></mbs-input-password>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <mbs-button size="sm"
                    [loading]="credentialsLoading"
                    [type]="'secondary'"
                    (click)="testClickHandler()">{{ 'wizards:addNetworkShareTest' | i18next: { format: 'title' } }}</mbs-button>

        <mbs-alert *ngIf="testResult === checkCredentialsResultEnum.Success"
                   [attr.data-test-element]="elementsSelector.name.successAlert"
                   class="mb-0 ml-3"
                   size="sm"
                   [type]="mbsPopupType.success"
                   [icon]="true">
          {{'wizards:addNetworkShareSuccess' | i18next}}
        </mbs-alert>

        <mbs-alert *ngIf="testResult === checkCredentialsResultEnum.Error"
                   [attr.data-test-element]="elementsSelector.name.errorAlert"
                   class="mb-0 ml-3"
                   size="sm"
                   [type]="mbsPopupType.danger"
                   [icon]="true">
          {{'wizards:addNetworkShareError' | i18next}}
        </mbs-alert>
      </div>
    </div>

    <div *ngIf="viewMode === networkShareViewMode.list"
         [attr.data-test-element]="elementsSelector.name.listBlock">
      <mbs-list-select id="network-share-list-table"
                       listType="table"
                       [maxHeight]="'280px'"
                       [headers]="headers"
                       [showCheckboxes]="false"
                       [loadSource]="false"
                       [showHeaders]="true"
                       [showSearch]="false"
                       [data]="networkSharePathArray"
                       [noDataMessage]="'wizards:where_backup_no_data' | i18next">
        <ng-template mbsTableCell
                     let-item>{{item.path}}</ng-template>
        <ng-template mbsTableCell
                     let-item>{{item.login || '-'}}</ng-template>
        <ng-template mbsTableCell
                     let-item>
          <mbs-button-group>
            <mbs-button size="sm"
                        [type]="'secondary'"
                        [icon]="'fa fa-pencil'"
                        (click)="editItemClickHandler(item)"></mbs-button>
            <mbs-button size="sm"
                        [type]="'secondary'"
                        [icon]="'fa fa-trash'"
                        (click)="deleteItemClickHandler(item)"></mbs-button>
          </mbs-button-group>
        </ng-template>
      </mbs-list-select>

      <mbs-button class="mt-3"
                  [block]="true"
                  [type]="'secondary'"
                  (click)="addNewPathClickHandler()">
        {{ 'wizards:addNetworkShareAdd' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>
  </ng-container>

  <ng-container modalFooter>
    <button *ngIf="editPath || viewMode === networkShareViewMode.list"
            [attr.data-test-element]="elementsSelector.name.listBlockButton"
            type="button"
            class="btn btn-primary"
            (click)="saveClickHandler()">{{ 'buttons:save' | i18next: { format: 'title' } }}</button>
    <button *ngIf="!editPath && viewMode === networkShareViewMode.form"
            [attr.data-test-element]="elementsSelector.name.formBlockButton"
            type="button"
            class="btn btn-primary"
            (click)="addClickHandler()">{{ 'buttons:add' | i18next: { format: 'title' } }}</button>
    <button type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancelClickHandler()">{{ 'buttons:cancel' | i18next: { format: 'title' } }}</button>
  </ng-container>
</mbs-modal>
