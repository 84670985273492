import { EC2RestoreTypeEnum } from '../../type-data-models';
import { Architectures } from './plan-architectures-model';
import { OSType } from './plan-os-type-model';
import { VolumeType } from './plan-volume-type-model';

export class RestoreToEC2Args {
  public OSType: OSType;
  public VolumeType: VolumeType;
  public LicenseType: string;
  public RemoteRestorePrefix: string;
  public IsRemoteRestore: boolean;
  public LeftIntermediateFilesInStorage: boolean;
  public AIMRoleName: string;
  public AIMRoleId: string;
  public InstancePlanTag: string;
  public InstanceNameTag: string;
  public RegionName: string;
  public EC2ConnectionId: string;
  public IntermediateS3ConnectionId: string;
  public AMI: string;
  public InstanceType: string;
  public Architecture: Architectures;
  public SubnetId: string;
  public SecurityGroupName: string;
  public SecurityGroupId: string;
  public AvailabilityZone: string;
  public EC2RestoreType: EC2RestoreTypeEnum;
  public UseVhdx: boolean;
}
