export function firstNumberGreaterThanSecondIfNotFloat(first: string, second: string): boolean {
  if (first.length !== second.length) return first.length > second.length;
  return first > second;
}

export function multiplyNumbersInStringFormat(first: string, second: string): string {
  const firstRev = first.split('').reverse();
  const secondRev = second.split('').reverse();
  const resultStack = [];
  for (let i = 0; i < firstRev.length; i++) {
    for (let j = 0; j < secondRev.length; j++) {
      const prod = +firstRev[i] * +secondRev[j];
      resultStack[i + j] = resultStack[i + j] ? resultStack[i + j] + prod : prod;
    }
  }
  for (let i = 0; i < resultStack.length; i++) {
    const move = Math.floor(resultStack[i] / 10);
    resultStack[i] = resultStack[i] % 10;
    if (resultStack[i + 1]) resultStack[i + 1] += move;
    else if (move != 0) resultStack[i + 1] = move;
  }
  return resultStack.reverse().join('');
}
