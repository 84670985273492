import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClonePlanModalComponent } from '@components/plans-modal/clone-plan-modal/clone-plan-modal.component';
import { DeletePlanModalComponent } from '@components/plans-modal/delete-plan-modal/delete-plan-modal.component';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { ComputerModalsFacade } from '@facades/computer.modals.facade';
import Computer, { AgentType, OsType } from '@models/Computer';
import { OldPagePlanTypes, PlanCreateCommands, PlanFormatTypes, PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { AvailablePlanStartModes } from '@models/backup/available-plan-start-modes';
import { PlanInfo } from '@models/backup/plan-info-model';
import { PlanRunInfo } from '@models/backup/plan-run-info-model';
import { PlanRunSimpleStatus } from '@models/backup/plan-run-simple-status';
import { PlansWizardMode } from '@modules/wizards/models/base/base-plan-models';
import { RemoteManagementWizardsService, WizardSettings } from '@modules/wizards/services/remote-management-wizards.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { baseDateFormatWithSeconds } from '@shared/utils/date';
import { getWizardComponentType } from '@utils/rd-and-wizards/getWizardComponentType';
import { I18NextPipe } from 'angular-i18next';
import { MbsSize } from 'mbs-ui-kit/utils/enums/mbs-size-enum';
import * as moment from 'moment';
import { Observable, catchError, noop, of } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PlanModalWrapper {
  constructor(
    private facade: ComputerBackupFacade,
    public i18nPipe: I18NextPipe,
    private router: Router,
    private wizardService: RemoteManagementWizardsService,
    private modals: ComputerModalsFacade
  ) {}

  handleEditPlan(plan: PlanInfo, computer: Computer): void {
    if (this.checkVersionAndTypeForOldPage(plan.type, computer, plan.formatType === PlanFormatTypes.CBF)) {
      this.openOldEditPagePlan(computer, plan.type, plan.id);
      return;
    }

    if (plan.type === PlanTypes.CloudVMBackupEC2Plan) {
      this.openEC2Plan(computer, plan);
      return;
    }

    const isVirtual = plan.type === PlanTypes.BackupHyperV || plan.type === PlanTypes.BackupVMware;
    if (isVirtual && plan.formatType === PlanFormatTypes.NBF) {
      if (plan.isClusterBackupEnabled) {
        this.wizardService.openErrorVersionModal(true);
        return;
      }
      if (plan.isExcludedFilesPresent && !Computer.IsSupportedAgentVersion(computer, AgentType.Backup, 783)) {
        this.wizardService.openErrorVersionModal(true, this.i18nPipe.transform('wizards:virtual_wizards_error_fields_exclude_text_alert'));
        return;
      }
    }

    const planTypeRelatedComponent = getWizardComponentType(plan.type);

    this.actionAndReloadPlans(
      this.wizardService.setWizard(
        planTypeRelatedComponent,
        {
          size: MbsSize.lg
        },
        this.getWizardSettings(plan?.type, computer, plan, true, plan?.formatType === PlanFormatTypes.NBF)
      ).afterClosed
    );
  }

  handleCreatePlan(type: PlanTypes, computer: Computer, isNBF = false, needOldCreate = false): void {
    if (this.checkVersionAndTypeForOldPage(type, computer, !isNBF) || needOldCreate) {
      this.openOldEditPagePlan(computer, type);
      return;
    }

    if (type === PlanTypes.CloudVMBackupEC2Plan) {
      this.openEC2Plan(computer, { type } as PlanInfo);
      return;
    }

    const planTypeRelatedComponent = getWizardComponentType(type);
    this.actionAndReloadPlans(
      this.wizardService.setWizard(
        planTypeRelatedComponent,
        {
          size: MbsSize.lg
        },
        this.getWizardSettings(type, computer, null, false, isNBF)
      ).afterClosed
    );
  }

  handleDeletePlan(plan: PlanInfo, computer: Computer): void {
    this.modals.openPlanBasedModal(DeletePlanModalComponent, computer, plan);
  }

  handleClonePlan(plan: PlanInfo, computer: Computer): void {
    this.modals.openPlanBasedModal(ClonePlanModalComponent, computer, plan);
  }

  handleStartPlan(plan: PlanInfo, computer: Computer, mode: AvailablePlanStartModes = AvailablePlanStartModes.Regular): void {
    if (!plan.lastRunInfo) {
      plan.lastRunInfo = { status: PlanRunSimpleStatus.Started } as PlanRunInfo;
    } else plan.lastRunInfo.status = PlanRunSimpleStatus.Started;
    this.actionAndReloadPlans(
      this.facade.startPlan(computer, plan.id, mode).pipe(
        catchError((e) => {
          plan.lastRunInfo.status = PlanRunSimpleStatus.Failed;
          return e;
        })
      )
    );
  }

  handleStopPlan(plan: PlanInfo, computer: Computer): void {
    this.actionAndReloadPlans(
      this.facade.stopPlan(computer, plan.id).pipe(
        catchError((e) => {
          plan.lastRunInfo.status = PlanRunSimpleStatus.Failed;
          return e;
        })
      )
    );
  }

  private actionAndReloadPlans(action: Observable<any>) {
    action.subscribe(() => this.facade.loadPlans({ force: true, quiet: true }));
  }

  private openEC2Plan(computer: Computer, plan: PlanInfo): void {
    this.modals
      .openEC2PlanModal()
      .pipe(catchError(() => of(false)))
      .subscribe((confirm) => {
        if (confirm) {
          this.setParamsForOldWizardsToLocalStorage(computer, plan);
          this.router
            .navigate(['/AP', 'Computers.aspx'], {
              queryParams: { sidepanel: 'backup', hid: computer.hid, openEC2: 'true' }
            })
            .then(noop)
            .catch(noop);
        }
      });
  }

  private setParamsForOldWizardsToLocalStorage(computer: Computer, plan: PlanInfo): void {
    const lastRunInfo = plan?.lastRunInfo ? (plan.lastRunInfo.dateTimeUTC as string) : '';
    const params = {
      backupVersion: Computer.getAgent(computer, AgentType.Backup)?.version,
      email: computer.userAccount ? computer.userAccount.accountName : null,
      hid: computer.hid,
      id: plan?.id || this.wizardService.generateUUID(),
      isLinux: computer.os !== 'windows' ? 'True' : 'False',
      lastStart: lastRunInfo ? moment.utc(lastRunInfo, baseDateFormatWithSeconds).format('M/D/YYYY+h:mm+A') || '' : '',
      mode: !plan?.id ? 'create' : 'edit',
      name: computer.name,
      profile: '',
      type: PlanTypes[plan.type],
      userId: computer.userAccount ? computer.userAccount.id : null
    };
    localStorage.setItem(plan.type === PlanTypes.DataBasePlan ? 'sqlWizardData' : 'EC2WizardData', JSON.stringify(params));
  }

  private openOldEditPagePlan(computer: Computer, type: PlanTypes, id = ''): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/AP/EditPlan2.aspx'], {
        queryParams: {
          hid: computer.hid,
          prof: '',
          id: id || this.wizardService.generateUUID(),
          command: id ? 'edit' : PlanCreateCommands[type],
          name: computer.name,
          email: computer.userAccount ? computer.userAccount.accountName || '' : '',
          userId: computer.userAccount ? computer.userAccount.id || '' : '',
          returnUrl: this.router.url
        }
      })
    );
    window.open(url, '_blank');
  }

  // @TODO support linux editions
  // @TODO reuse from
  // sidepanel-remote-deploy-configuration/plans-tab/plans-tab.component.ts
  private getWizardSettings(type: PlanTypes, computer: Computer, plan: PlanInfo = null, edit = false, isNBF = false): WizardSettings {
    const backup = computer.apps && computer.apps.length ? computer.apps.find((app) => app.applicationId === 'backup') : null;
    const newParams: WizardSettings = {
      hid: computer.hid,
      mode: edit ? PlanMode.edit : PlanMode.create,
      wizardMode: PlansWizardMode.forRMM,
      planId: plan && plan.id ? plan.id : '',
      isNBF: isNBF,
      isOffline: !computer.online,
      type: type,
      isLinux: computer.os !== 'windows', // this.isLinuxMacTypeSelected
      compName: computer.name,
      email: computer.userAccount ? computer.userAccount.accountName : null,
      backupVersion: backup ? backup.version : null,
      userId: computer.userAccount ? computer.userAccount.id : null
    } as WizardSettings;
    if (edit) newParams.wasNotExecuted = !plan?.lastRunInfo?.status;
    return newParams;
  }

  private checkVersionAndTypeForOldPage(type: PlanTypes, computer: Computer, isCBF = false): boolean {
    const version = computer.os === OsType.windows ? 461 : 170;
    return (
      !!OldPagePlanTypes[type] ||
      (type === PlanTypes.DataBasePlan && !Computer.IsSupportedAgentVersion(computer, AgentType.Backup, version)) ||
      (type === PlanTypes.BackupHyperV && isCBF)
    );
  }
}
