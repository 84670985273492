import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';

@Injectable()
export class ModalWrapper extends ModalService {
  constructor(
    protected modalService: NgbModal,
    protected rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) protected document: Document,
    private i18n: I18NextPipe,
    private ability: AbilityService,
    private toastService: ToastService
  ) {
    super(modalService, rendererFactory, document);
  }

  protected openModal(content: any, options?: ModalSettings): NgbModalRef {
    if (options?.permissions?.length && options?.missingPermissionActionName) {
      const missingPermissions = options.permissions.filter((permission) => !this.ability.can('read', permission));
      if (missingPermissions.length) {
        const action = options?.missingPermissionActionName;
        const permissionsLabels = this.getPermissionsLabels(missingPermissions)
          .map((label) => `'${label}'`)
          .join(', ');

        this.toastService.error(
          this.i18n.transform('app:modals.permissionRequiredText', { param: permissionsLabels, action }),
          this.i18n.transform('app:modals.permissionRequiredTitle')
        );
        return null;
      }
    }

    return this.modalService.open(content, this.getOptions(options));
  }

  private getPermissionsLabels(permissions: string[]): string[] {
    return permissions.map((permission) => this.i18n.transform('app:permissionsLabels.' + permission));
  }
}
