import { Injectable } from '@angular/core';
import { ComputerTagsFacade } from '@facades/computer.tags.facade';
import {
  AgentType,
  agentTypeAdapter,
  ComputerBackupRestoreStatus,
  ComputersAPIFilterKeys,
  ComputersHealthFilterType,
  ComputerStatus,
  GetComputersParams,
  OSTypeAdapter
} from '@models/Computer';
import { ComputersStoreFilters } from '@models/ComputersStoreFilters';
import { QueryPagingParams, QuerySortParams } from '@models/Paging';
import { SmartSearchFilterOptions } from '@models/SmartSearchFilterOptions';
import { SmartSearchTemplateName } from '@modules/smart-search-templates/models/smart-search-templates-models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SmartSearchUtils } from '@utils/smartSearchUtils';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { getSmartSearchTagValues, SmartSearchModel } from 'mbs-ui-kit/smart-search/models/smart-search-model';
import { SmartSearchValidationState } from 'mbs-ui-kit/smart-search/models/smart-search-models';
import { map, Observable, of, switchMap, throwError } from 'rxjs';

export abstract class ComputersFiltersAbstractWrapper {
  abstract getComputersHttpParams(
    filter: ComputersStoreFilters,
    sorting?: QuerySortParams,
    paging?: QueryPagingParams
  ): Observable<GetComputersParams>;
  abstract validateSearchParams(search: SmartSearchModel): Observable<SmartSearchValidationState>;
}

const queryValueSeparator = ',';

const filters: SmartSearchFilterOptions[] = [
  { tag: SmartSearchTemplateName.Words, key: ComputersAPIFilterKeys.Search, singleResultValue: true },
  {
    tag: SmartSearchTemplateName.BackupStatus,
    key: ComputersAPIFilterKeys.BackupPlanStatuses,
    values: Object.values(ComputerBackupRestoreStatus)
  },
  {
    tag: SmartSearchTemplateName.RestoreStatus,
    key: ComputersAPIFilterKeys.RestorePlanStatuses,
    values: Object.values(ComputerBackupRestoreStatus)
  },
  {
    tag: SmartSearchTemplateName.ProductInstalled,
    key: ComputersAPIFilterKeys.AppIds,
    values: agentTypeAdapter.map((agent) => agent.displayAgentType.toLowerCase()),
    formatValue: (value: string) => {
      return agentTypeAdapter.find((agent) => agent.displayAgentType.toLowerCase() === value.toLowerCase())?.agentType || null;
    }
  },
  {
    tag: SmartSearchTemplateName.ProductNotInstalled,
    key: ComputersAPIFilterKeys.AppIdsNotInstalled,
    values: agentTypeAdapter.map((agent) => agent.displayAgentType.toLowerCase()),
    formatValue: (value: string) => {
      return agentTypeAdapter.find((agent) => agent.displayAgentType.toLowerCase() === value.toLowerCase())?.agentType || null;
    }
  },
  { tag: SmartSearchTemplateName.IP, key: ComputersAPIFilterKeys.IpAddresses },
  {
    tag: SmartSearchTemplateName.OS,
    key: ComputersAPIFilterKeys.OS,
    values: OSTypeAdapter.map((os) => os.displayOS.toLowerCase()),
    formatValue: (value: string) => {
      return OSTypeAdapter.find((osMap) => osMap.displayOS.toLowerCase() === value.toLowerCase())?.os || null;
    }
  },
  {
    tag: SmartSearchTemplateName.Status,
    key: ComputersAPIFilterKeys.Online,
    values: [ComputerStatus.Online, ComputerStatus.Offline],
    formatValue: (value: string) => {
      return value.toLowerCase() === ComputerStatus.Offline ? 'false' : null;
    },
    singleResultValue: true
  },
  {
    tag: SmartSearchTemplateName.Status,
    key: ComputersAPIFilterKeys.Offline,
    values: [ComputerStatus.Online, ComputerStatus.Offline],
    formatValue: (value: string) => {
      return value.toLowerCase() === ComputerStatus.Online ? 'false' : null;
    },
    singleResultValue: true
  }
];

@UntilDestroy()
@Injectable()
export class ComputersFiltersWrapper implements ComputersFiltersAbstractWrapper {
  constructor(public i18nPipe: I18NextPipe, private tagsFacade: ComputerTagsFacade) {}

  getComputersHttpParams(
    filter: ComputersStoreFilters,
    sorting?: QuerySortParams,
    paging?: QueryPagingParams
  ): Observable<GetComputersParams> {
    if (isNil(filter.searchModel)) return throwError(() => new Error('Incorrect filter!'));
    return this.prepareTagsFilter(filter.searchModel).pipe(
      map((tags) => ({
        ...this.prepareHealthFilters(filter.healthType),
        companyIds: filter.company ? [filter.company] : null,
        tags,
        hidden: filter.hidden || null,
        authStatus: filter.authStatus,
        ...sorting,
        ...paging
      })),
      map((payload) => this.enrichParamsByPayload(this.getSearchHttpParams(filter.searchModel), payload))
    );
  }

  private prepareHealthFilters(healthType: ComputersHealthFilterType): { [key: string]: any } {
    switch (healthType) {
      case ComputersHealthFilterType.BackupFailed:
        return { healthScopeAppId: AgentType.Backup, healthProblem: true };
      case ComputersHealthFilterType.BackupWarning:
        return { [ComputersAPIFilterKeys.BackupPlanStatuses]: ComputerBackupRestoreStatus.Warning };
      case ComputersHealthFilterType.BackupOverdue:
        return { [ComputersAPIFilterKeys.BackupPlanStatuses]: ComputerBackupRestoreStatus.Overdue };
      case ComputersHealthFilterType.RMMProblem:
        return { healthScopeAppId: AgentType.RMM, healthProblem: true };
      case ComputersHealthFilterType.RMMWarning:
        return { healthScopeAppId: AgentType.RMM, healthWarning: true };
      case ComputersHealthFilterType.Unsupported:
        return { type: ComputersHealthFilterType.Unsupported };
      default:
        return {};
    }
  }

  validateSearchParams(search: SmartSearchModel): Observable<SmartSearchValidationState> {
    const areValid = (filter: SmartSearchFilterOptions) => SmartSearchUtils.areValuesByTagValid(filter.tag, search, filter.values);
    const haveValuesAndValid = (filter: SmartSearchFilterOptions) =>
      SmartSearchUtils.haveValues(filter.tag, search) ? areValid(filter) : SmartSearchValidationState.Unknown;

    return this.tagsFacade
      .isTagsFilterValid(search)
      .pipe(
        map(
          (tagsFilterValidationState) =>
            Math.max(
              ...[tagsFilterValidationState].concat(filters.filter((filter) => !!filter.values).map((filter) => haveValuesAndValid(filter)))
            ) as SmartSearchValidationState
        )
      );
  }

  private enrichParamsByPayload(params: { [key: string]: any }, payload: { [key: string]: any }): { [key: string]: any } {
    for (const key in payload) {
      if (!isNil(payload[key])) {
        params[key] = payload[key];
      }
    }

    return params;
  }

  private getSearchHttpParams(search: SmartSearchModel): ComputersStoreFilters {
    const params = {} as ComputersStoreFilters;

    if (!search) {
      return params;
    }

    filters.forEach((filter) => {
      const values = getSmartSearchTagValues(filter.tag, search);
      if (values.length && SmartSearchUtils.areValuesValid(values, filter.values)) {
        const formattedValues = values
          .map((value) => (filter.formatValue ? filter.formatValue(value) : value.toLowerCase()))
          .filter(Boolean);
        formattedValues.length &&
          (params[filter.key] = filter.singleResultValue ? formattedValues.join(queryValueSeparator) : formattedValues);
      }
    });

    return params;
  }

  private prepareTagsFilter(search: SmartSearchModel): Observable<number[]> {
    return of(getSmartSearchTagValues(SmartSearchTemplateName.Tag, search)).pipe(
      switchMap((searchTags) =>
        searchTags.length
          ? this.tagsFacade
              .loadAllTags()
              .pipe(
                map((allTags) =>
                  allTags
                    .filter((tag) => searchTags.map((tagName) => tagName.toLowerCase()).includes(tag.name.toLowerCase()))
                    .map((tag) => tag.id)
                )
              )
          : of(undefined)
      )
    );
  }
}
