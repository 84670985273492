import { AgentType } from '@models/Computer';
import { I18NextPipe } from 'angular-i18next';

export const getTabsConfig = (i18N: I18NextPipe) => [
  {
    id: AgentType.Backup,
    title: i18N.transform('products.backup'),
    image: 'assets/images/agents/backup.svg',
    updateAlert: 'showBackupUpdateDot'
  },
  {
    id: AgentType.RMM,
    title: i18N.transform('products.rmm'),
    image: 'assets/images/agents/rmm.svg',
    updateAlert: 'showRmmUpdateDot'
  },
  {
    id: AgentType.RA,
    title: i18N.transform('products.remote_desktop'),
    image: 'assets/images/agents/connect.svg'
  },
  {
    id: 'bootableUSB',
    title: i18N.transform('products.bootableUSB'),
    image: 'assets/images/icons/bootable-usb.svg'
  },
  {
    id: AgentType.NetworkDiscovery,
    title: i18N.transform('products.networkDiscovery', { format: 'title' }),
    image: 'assets/images/icons/network.svg',
    beta: true
  }
];

export enum BuildAction {
  Download = 'download',
  CopyLink = 'copyLink',
  CopyCommand = 'copyCommand'
}

export enum CopyingStatus {
  Copying = 'copying',
  Copied = 'copied'
}

export enum BuildTopicStatus {
  Success = 'Success'
}
