<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           [loading]="isAvailableLicensesLoading"
           class="manage-modal"
           #modal>

  <div modal-body>
    <mbs-select bindLabel="licenseDisplayName"
                bindValue="licenseType"
                [(ngModel)]="selectedLicenseType"
                [label]="'licenses:manageModal:label' | i18next"
                [items]="licensesTypes | async"
                [placeholder]="'licenses:placeholders:licenses' | i18next"></mbs-select>
    <div class="pt-3">
      {{ "licenses:manageModal:description" | i18next }}
    </div>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                [loading]="loading"
                [disabled]="!selectedLicenseType"
                (click)="manage()">{{ 'licenses:manageModal:submit' | i18next: { format: 'title' } }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'licenses:common:cancel' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
