import { NotificationSettings, WindowsEventLogNotificationSettings } from '../notifications-models';
import { Actions } from './base-models/plan-actions-model';
import { ScheduleSettingsModels } from './schedule-settings-models';

export enum PlansWizardMode {
  forRMM = 0,
  forRD = 1
}
export class BasePlan {
  constructor() {}
  public GFSJobBackupSwitch: string;
  public TLogBackupSwitch: string;
  public Incremental0BackupSwitch: string;
  public DiffBackupSwitch: string;
  public RestartFailedSwitch: string;
  public FullParameterSwitch: string;
  public SavePlanInCloud: boolean;
  public Notification: NotificationSettings;
  public ForceMissedSchedule: boolean;
  public Schedule: ScheduleSettingsModels;
  public ExecuteNextPlan: boolean;
  public SyncBeforeRun: boolean;
  public ExecuteNextPlanOnlyIfSucces: boolean;
  public NextExectutionPlan: string;
  public WindowsEventLogNotification: WindowsEventLogNotificationSettings;
  public Actions: Actions;
  public ForceFullNextPlan: boolean;
  public ForwardIncremental: boolean;
  public EncryptionPassword: string;
  public ID: string;
  public SGAccountID: string;
  public Bucket: string;
  public ConnectionID: string;
  public Tag: string;
  public CreationDateUtc: Date;
  public UseEncryption: boolean;
  public Name: string;
}
