export class ListSelectGroupModel<T = any> {
  id: string;
  title: string;
  items: T[] = [];
  selectedItems?: T[] = [];
  mySelectedItems?: any[] = [];
  shown?: boolean = true;

  constructor(init?: Partial<ListSelectGroupModel<T>>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
