<mbs-sidepanel id="computer_backup_info"
               side="right"
               [large]="ability.can('read', 'MBS')"
               [innerContentType]="ability.can('read', 'MBS') ? 'tabs' : 'form'"
               [hideSave]="true"
               [showDelete]="false"
               showFooter="true"
               [headerClass]="'-light'"
               [loadingData]="loadingData"
               [showExpandedCross]="true"
               (panelClosed)="closeHandler()"
               (expandedChange)="fullscreen=$event">
  <div header-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-ico"
         [ngClass]="computer?.online ? 'text-primary' : 'text-muted'">
      <span class="{{ computer | computerOsIcon }}"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      {{computerName}}
      <small [class.text-success]="computer?.online"
             [class.font-weight-semibold]="computer?.online">
        ({{onlineStatusText}})
      </small>
    </div>
  </div>
  <div loading-data-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-ico">
      <span class="fa fa-windows"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      {{ 'app:common.loadingComputerInfo' | i18next }}
    </div>
  </div>


  <ng-container body>
    <mbs-alert *ngIf="showOutdatedAgentAlert"
               [type]="isOutdatedBackupAgentVersionStatus ? AlertType.info : AlertType.danger"
               [icon]="true">
      {{ 'computers.module:backupSidePanel.' + (isOutdatedBackupAgentVersionStatus ? 'agentOutdated' :
      'agentUnsupported') | i18next }}
    </mbs-alert>

    <mbs-alert *ngIf="offlineAlert && !showOfflineEditAlert"
               [type]="AlertType.warning"
               [icon]="true"
               class="mb-0">
      {{ offlineAlert }}
    </mbs-alert>

    <mbs-alert *ngIf="showOfflineEditAlert"
               [type]="AlertType.warning"
               [icon]="true"
               class="mb-0">
      <span *ngIf="isProvider"
            class="d-flex justify-content-between align-items-center">
        <span>{{ 'app:allowOfflineEdit.computerIsOffline' | i18next: { format: 'capitalize' } }}</span>
        <mbs-button [isCtrl]="true"
                    (click)="allowOfflineClickHandler()">
          {{ 'app:allowOfflineEdit.allow' | i18next: { format: 'capitalize' } }}
        </mbs-button>
      </span>

      <span *ngIf="!isProvider">
        {{ 'app:allowOfflineEdit.contact' | i18next }}
        <a [href]="'mailto:' + providerEmail">{{providerEmail}}</a>
        {{ 'app:allowOfflineEdit.toEnableOfflineEdit' | i18next }}
      </span>
    </mbs-alert>

    <div class="mbs-sidepanel_tabset -vertical">
      <div class="d-flex h-100 flex-column justify-content-between">

        <ul mbsTabset
            mbsTabSetValidated
            mode="vertical"
            (afterUpdate)="handleAfterTabChange($event)"
            #activeContent="mbsTabset">
          <li [mbsTabsetItem]="panelTab.general"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink>General</button>
            <ng-template mbsTabsetContent>
              <mbs-general-tab [fullscreen]="fullscreen"></mbs-general-tab>
            </ng-template>
          </li>
          <li [mbsTabsetItem]="panelTab.license"
              [mbsTabSetValid]="false"
              [disabled]="!online || !ability.can('read', 'Licenses')|| !isAuthorizedComputer">
            <button mbsTabsetLink>{{ 'app:common.license' | i18next }}</button>
            <ng-template mbsTabsetContent>
              <mbs-sidepanel-backup-license-tab [fullscreen]="fullscreen"
                                                [computer]="computer"></mbs-sidepanel-backup-license-tab>
            </ng-template>
          </li>
          <li [mbsTabsetItem]="panelTab.backupPlans"
              [disabled]="(!ability.can('read', 'AllowOfflineSettingsEdit') || computer?.os !== osType.windows) && (disableTabs$ | async)"
              [mbsTabSetValid]="false"
              [loading]="true">
            <button mbsTabsetLink>Backup Plans</button>
            <ng-template mbsTabsetContent>
              <mbs-sidepanel-plans-tab [readonly]="false"
                                       [mode]="'backup'"
                                       (switchToStorage)="handleSwitchToStorage($event)"
                                       [fullscreen]="fullscreen"></mbs-sidepanel-plans-tab>

            </ng-template>
          </li>
          <li [mbsTabsetItem]="panelTab.restorePlans"
              [disabled]="(!ability.can('read', 'AllowOfflineSettingsEdit') || computer?.os !== osType.windows) && (disableTabs$ | async)"
              [mbsTabSetValid]="false"
              [loading]="true">
            <button mbsTabsetLink>Restore Plans</button>
            <ng-template mbsTabsetContent>
              <mbs-sidepanel-plans-tab [readonly]="false"
                                       [mode]="'restore'"
                                       (switchToStorage)="handleSwitchToStorage($event)"
                                       [fullscreen]="fullscreen"></mbs-sidepanel-plans-tab>

            </ng-template>
          </li>
          <li [mbsTabsetItem]="panelTab.backupStorage"
              [fullHeight]="fullscreen"
              [mbsTabSetValid]="false"
              [loading]="true">
            <button mbsTabsetLink>Backup Storage{{isBeta ? ' (BETA)' : ''}}</button>
            <ng-template mbsTabsetContent>
              <mbs-backup-storage-tab *ngIf="isBeta"
                                      [fullscreen]="fullscreen"
                                      (fullScreenChange)="fullScreenChangeHandler()"></mbs-backup-storage-tab>
              <mbs-backup-storage-legacy-tab *ngIf="!isBeta"></mbs-backup-storage-legacy-tab>
            </ng-template>
          </li>

          <!--     Hidden until implementation     -->
          <li mbsTabsetItem="history"
              *ngIf="false"
              [disabled]="disableTabs$ | async"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink>History</button>
            <ng-template mbsTabsetContent>
              History stub
            </ng-template>
          </li>

        </ul>

        <ul class="mbs-tabset -vertical">
          <li *ngIf="hasAgent(computer, AgentType.RMM)"
              class="mbs-tabset_item mb-6">
            <button class="mbs-tabset_link py-1"
                    (click)="openRMM(computer)">
              <span class="ico ico-BarChart text-primary mr-2"></span>
              {{ 'app:common.open' | i18next: { format: 'title'} }}&nbsp;{{ 'app:products.rmm' | i18next }}
            </button>
          </li>

          <li class="mbs-tabset_item">
            <mbs-connect-button-group [computer]="computer"
                                      [isCtrl]="true"
                                      [customClass]="'mbs-tabset_link pr-1 py-1'"
                                      container="auto"
                                      tooltipPlacement="top-left"
                                      dropdownPlacement="top-left"></mbs-connect-button-group>
          </li>
        </ul>
      </div>

      <div class="mbs-form_content"
           [mbsTabsetOutlet]="activeContent"></div>
    </div>
  </ng-container>


  <ng-template #footerTemplate>
    <div class="w-100 d-flex align-items-center"
         [class.justify-content-between]="!fullscreen"
         [class.justify-content-end]="fullscreen">
      <mbs-switcher *ngIf="!fullscreen"
                    [(ngModel)]="isBeta"
                    label="Try new side panel (BETA)"></mbs-switcher>
      <mbs-button type="secondary"
                  (click)="close.emit()">
        {{ 'buttons:close' | i18next }}
      </mbs-button>
    </div>
  </ng-template>
</mbs-sidepanel>
