export class BaseForPlanHelper {
  
  public static ToPaskalCase(obj): any {
    const keys = Object.keys(obj);
    const newPlan = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const lowKey = key.toLowerCase();
      let newKey = key;
      if (lowKey === 'id') newKey = 'ID';
      else if (lowKey === 'sgaccountid') newKey = 'SGAccountID';
      else if (lowKey === 'vssproviderid') newKey = 'VSSProviderID';
      else if (lowKey === 'gfspolicysettings') newKey = 'GFSPolicySettings';
      else if (lowKey !== 'plan') newKey = key[0].toUpperCase() + key.slice(1);
      if (!Array.isArray(obj[key])) {
        newPlan[newKey] =
          !(obj[key] === null || obj[key] === undefined) && typeof obj[key] === 'object' ? this.ToPaskalCase(obj[key]) : obj[key];
      } else newPlan[newKey] = obj[key].map(i => (typeof i === 'object' ? this.ToPaskalCase(i) : i));
    }
    return newPlan;
  }
}
