import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Edition } from '@models/Computer';
import { ComputersAbstractService } from '@services/computers.service';
import { ModalComponent } from 'mbs-ui-kit';
import { catchError, Observable, of, take, tap } from 'rxjs';

@Component({
  selector: 'mbs-manage-license-computer-modal',
  templateUrl: './manage-license-computer-modal.component.html',
  styleUrls: ['./manage-license-computer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageComputerModalComponent implements AfterViewInit {
  public loading = false;
  public selectedLicenseType;
  public isAvailableLicensesLoading = true;
  public licensesTypes: Observable<Edition[]> = of(null);

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private computersService: ComputersAbstractService) {}

  manage(): void {
    this.loading = true;

    this.computersService
      .updateBackupAgentSettings(this.baseModal.data.endpointId, { mbsBuildType: this.selectedLicenseType })
      .pipe(
        take(1),
        catchError(() => {
          this.loading = false;
          return of(false);
        })
      )
      .subscribe(() => {
        this.baseModal.save();
        this.loading = false;
      });
  }

  ngAfterViewInit(): void {
    this.setLicensesTypes();
  }

  setLicensesTypes() {
    this.isAvailableLicensesLoading = true;
    this.licensesTypes = this.computersService.getAvailableEditions(this.baseModal.data.endpointId).pipe(
      take(1),
      catchError(() => of(null)),
      tap((editions) => {
        this.selectedLicenseType = editions?.find((edition) => edition.isCurrentBuild).buildValue;
        this.isAvailableLicensesLoading = false;
      })
    );
  }
}
