import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { SmartSearchAgentTemplateComponent } from './base-template/smart-search-agent-template.component';
import { SmartSearchBaseTemplateComponent } from './base-template/smart-search-base-template.component';
import { SmartSearchPlanStatusTemplateComponent } from './base-template/smart-search-plan-status-template.component';
import { SmartSearchBackupStatusTemplateComponent } from './custom-templates/smart-search-backup-status-template.component';
import { SmartSearchCompaniesTemplateComponent } from './custom-templates/smart-search-companies-template.component';
import { SmartSearchIPTemplateComponent } from './custom-templates/smart-search-ip-template.component';
import { SmartSearchOsTemplateComponent } from './custom-templates/smart-search-os-template.component';
import { SmartSearchProductInstalledTemplateComponent } from './custom-templates/smart-search-product-installed-template.component';
import { SmartSearchProductNotInstalledTemplateComponent } from './custom-templates/smart-search-product-not-installed-template.component';
import { SmartSearchRestoreStatusTemplateComponent } from './custom-templates/smart-search-restore-status-template.component';
import { SmartSearchStatusTemplateComponent } from './custom-templates/smart-search-status-template.component';
import { SmartSearchTagsTemplateComponent } from './custom-templates/smart-search-tags-template.component';
import { SmartSearchComputerNameTemplateComponent } from './custom-templates/smart-search-computer-template.component';

const templates = [
  SmartSearchBaseTemplateComponent,
  SmartSearchCompaniesTemplateComponent,
  SmartSearchOsTemplateComponent,
  SmartSearchStatusTemplateComponent,
  SmartSearchPlanStatusTemplateComponent,
  SmartSearchBackupStatusTemplateComponent,
  SmartSearchRestoreStatusTemplateComponent,
  SmartSearchIPTemplateComponent,
  SmartSearchAgentTemplateComponent,
  SmartSearchProductInstalledTemplateComponent,
  SmartSearchProductNotInstalledTemplateComponent,
  SmartSearchTagsTemplateComponent,
  SmartSearchComputerNameTemplateComponent
];

@NgModule({
  declarations: templates,
  exports: templates,
  imports: [CommonModule, I18NextModule]
})
export class SmartSearchTemplatesModule {}
