import { Tag } from '@models/tag';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const COMPUTER_TAGS_STORE_TYPE = 'Computer Tags';

export type ComputerTagsState = EntityState<Tag> & {
  selected: number;
  loading: boolean;
  loaded: boolean;
};

export const computerTagsAdapter: EntityAdapter<Tag> = createEntityAdapter<Tag>();

export const initialComputerTagsState: ComputerTagsState = computerTagsAdapter.getInitialState({
  selected: null,
  loading: false,
  loaded: false
});
