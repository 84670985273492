import { Component, forwardRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ApplicationStateFacade } from '@facades/application.facade';
import Administrator from '@models/Administrator';
import { PlanTypes } from '@models/PlanTypes.enum';
import { UiStorageKey, UiStorageType } from '@models/ui-storage';
import { ConfirmPassData } from '@modules/confirm-password/confirm-pass-model';
import { ForcePasswordRecoveryModalComponent } from '@modules/password-recovery/modals/force-password-recovery-modal/force-password-recovery-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { PasswordFromPlan } from '@utils/constants/misc-constants';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { FormsUtil, MbsPopupType, ModalService, ModalSettings } from 'mbs-ui-kit';
import { combineLatest, noop } from 'rxjs';
import { CompressionEncryptionStepValue, EncryptionAlgorithm } from '../../models/compression-encription-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const CompressionEncryptionStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CompressionEncryptionStepComponent),
  multi: true
};

function validatorForHint(formGroup: UntypedFormGroup): ValidationErrors | null {
  const control = formGroup.controls['hint'];
  const password = formGroup.controls['EncryptionPassword'];
  if (this.isLinux) {
    if (control && control.value && password && control.value.includes(password.value)) {
      control.setErrors({
        hintEqualsPassword: { message: this.i18nPipe.transform('wizards:compression_encryption_hint_contain_password_error') }
      });
    }
  } else if (control && control.value && password && password.value === control.value) {
    control.setErrors({
      hintEqualsPassword: { message: this.i18nPipe.transform('wizards:compression_encryption_hint_equals_password_error') }
    });
  }
  return formGroup.errors;
}

@UntilDestroy()
@Component({
  selector: 'mbs-compression-encryption-step',
  templateUrl: './compression-encryption-step.component.html',
  providers: [CompressionEncryptionStepValueAccessor]
})
export class CompressionEncryptionStepComponent extends StepBase<CompressionEncryptionStepValue> implements OnInit {
  @Input() loadingPlan: boolean;
  @Input() isS3Selected: boolean;
  @Input() SaveDeletedDataInCloud: boolean;

  public readonly elementSelectors = {
    id: {
      encryptionCheckbox: 'compression-encryption-server-side-encryption-checkbox',
      UseFileNameEncryption: 'compression-encryption-use-file-name-encryption-checkbox',
      UseFileNameEncryptionLegacyTooltip: 'compression-encryption-use-file-name-encryption-checkbox-legacy-tooltip'
    }
  };
  public readonly alertType = MbsPopupType;
  public readonly passwordPlaceholder = PasswordFromPlan;
  public needShowAlert = false;
  public correctlyVersionAgent = false;
  public InitialValue: CompressionEncryptionStepValue;
  public encryptionAlgorithmDisplays = [];
  public selectedEncryptionAlgorithmDisplays = '';
  public passwordFormData: ConfirmPassData = { password: '', confirmationPassword: '', valid: true };
  public needToForceRecovery = false;
  public isPassRecoveryAlertShowingPossible = false;
  public needToShowPassRecoveryAlert = false;
  public passwordRules: string[] = [];
  public useFileNameEncryptionChangeHandler = this.getLegacyPropertyChangeHandler(
    'UseFileNameEncryption',
    this.i18nPipe.transform('wizards:encryption_filenames_label'),
    this.modalService
  );

  protected legacyUnsupportedProperties = ['UseFileNameEncryption'];

  private shouldShowRebackupConfirmation = false;
  private isNeedSave = false;
  private uiStorage$ = this.applicationStateFacade.getUiStorage();
  private readonly notConfirmKeys = ['valid', 'hint', 'UseFileNameEncryption', 'UseServerSideEncryption'];
  private readonly algorithmMap = {
    [EncryptionAlgorithm.AES]: ['AES 128-bit', 'AES 192-bit', 'AES 256-bit'],
    [EncryptionAlgorithm.DES]: ['DES 64-bit'],
    [EncryptionAlgorithm.TripleDES]: ['3DES 128-bit', '3DES 168-bit'],
    [EncryptionAlgorithm.RC2]: [
      'RC2 40-bit',
      'RC2 48-bit',
      'RC2 56-bit',
      'RC2 64-bit',
      'RC2 72-bit',
      'RC2 80-bit',
      'RC2 88-bit',
      'RC2 96-bit',
      'RC2 104-bit',
      'RC2 112-bit',
      'RC2 120-bit',
      'RC2 128-bit'
    ]
  };

  get showUseFileNameEncryption(): boolean {
    const control = this.stepForm.get('UseFileNameEncryption');

    return (
      !this.isNBF &&
      control &&
      !(this.stepForm.get('UseFileNameEncryption').value === null || this.stepForm.get('UseFileNameEncryption').value === undefined)
    );
  }

  get isNotVirtual(): boolean {
    return !this.isHyperV && !this.isVMWare;
  }

  get showUseServerSideEncryption(): boolean {
    const control = this.stepForm.get('UseServerSideEncryption');
    const allow1 = this.isNotVirtual;
    const allow2 = !this.isS3Selected; // AWS Amazon S3 now Always use SSE
    const allow3 = control && !(control.value === null || control.value === undefined);

    return allow1 && allow2 && allow3;
  }

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    public i18nPipe: I18NextPipe,
    public mainService: RemoteManagementWizardsService,
    private store: Store,
    private applicationStateFacade: ApplicationStateFacade
  ) {
    super(mainService);
    if (this.isRDMode) this.correctlyVersionAgent = true;
    else {
      const version = mainService.backupVersionUpdated && mainService.backupVersionUpdated.substring(0, 2);
      this.correctlyVersionAgent = version && +version >= 41;
    }

    this.authService
      .getPasswordRules()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.passwordRules = data;
      });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup(
      {
        UseCompression: new FormControl(false),
        UseEncryption: new FormControl(false),
        EncryptionAlgorithm: new FormControl(3),
        EncryptionKeySize: new FormControl(128),
        EncryptionPassword: new FormControl('', [Validators.required, this.encryptionPasswordValidator.bind(this)]),
        UseFileNameEncryption: new FormControl(false),
        UseServerSideEncryption: new FormControl(false),
        hint: new FormControl('false'),
        rebackup: new FormControl(false)
      },
      [validatorForHint.bind(this)]
    );

    if (this.isCreate) {
      this.disableLegacyUnsupportedProperties(true);
    }

    this.initFormEvents();
  }

  protected initFormEvents(): void {
    super.initFormEvents();

    this.stepForm.controls['EncryptionPassword'].valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (pass: string) => this.updateRebackupConfirmationFromPass(pass)
    });

    combineLatest([this.authService.currentUser, this.uiStorage$]).subscribe({
      next: ([user, storage]) => this.userAndStorageSubscribeHandler(user, storage)
    });

    this.stepForm
      .get('UseEncryption')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe({ next: (useEncryption: boolean) => this.updatePassRecoveryFromUseEncryption(useEncryption) });
  }

  onStepFormChange(value: CompressionEncryptionStepValue): void {
    if (!this.stepForm.dirty && !this.stepForm.touched) return;

    const nbfOrIbb = this.isNBF || this.isIBBPlan;
    const notRDNotExecute = !this.isRDMode && !this.mainService.wasNotExecuted;

    if (this.InitialValue && nbfOrIbb && notRDNotExecute) {
      const mainVal = this.stepForm.getRawValue();
      const changed = Object.keys(this.InitialValue).some((k) => !this.notConfirmKeys.includes(k) && this.InitialValue[k] !== mainVal[k]);

      this.needShowAlert = changed && !(!this.isNBF && this.isBackupPlan);

      if (this.isIBBPlan && !this.isNBF && changed !== value.rebackup) {
        setTimeout(() => this.stepForm.get('rebackup').setValue(changed), 0);
      }
    }

    this.value = { ...value, valid: this.stepForm.valid && !this.shouldShowRebackupConfirmation };
  }

  encryptionPasswordValidator(control: AbstractControl): ValidationErrors | null {
    return this.passwordFormData.valid ? null : { passwordError: true };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm && changes.SaveDeletedDataInCloud) {
      this.toggleFormControls(['UseFileNameEncryption'], !changes.SaveDeletedDataInCloud.currentValue);
    }

    if (changes?.stepFocused?.currentValue) {
      this.openForcePasswordRecoveryModal(this.stepForm?.get('UseEncryption')?.value);
    }

    if (this.stepForm && this.isEdit && this.InitialValue && changes.loadingPlan?.previousValue && !changes.loadingPlan?.currentValue) {
      this.updateForm(this.InitialValue);
    }
  }

  updateForm(value: CompressionEncryptionStepValue): void {
    if (this.isEdit) this.InitialValue = value;

    if (value.UseEncryption && !value.EncryptionPassword) value.EncryptionPassword = PasswordFromPlan;

    this.passwordFormData = { password: value.EncryptionPassword, confirmationPassword: value.EncryptionPassword, valid: true };

    this.stepForm.reset(value);

    this.disableLegacyUnsupportedProperties();
    this.updateEncryptionAlgorithmDisplays(value.EncryptionAlgorithm, value.EncryptionKeySize);
    this.enableEncryptionChangeHandler(value.UseEncryption);
  }

  forceValid(data: any = null): void {
    this.isNeedSave = data.isSave;

    if (this.shouldShowRebackupConfirmation) {
      this.showRepackupConfirmationModal();
    } else {
      FormsUtil.triggerValidation(this.stepForm);
    }

    this.stepForm.updateValueAndValidity();
  }

  openForcePasswordRecoveryModal(useEncryption: boolean): void {
    if (useEncryption && this.needToForceRecovery && this.stepFocused) {
      this.modalService.openCustom(ForcePasswordRecoveryModalComponent).then().catch(noop);
    }
  }

  encryptionAlgorithmCloseHandler(): void {
    this.stepForm.markAllAsTouched();

    const stringAsArray = this.selectedEncryptionAlgorithmDisplays.split(' ');
    const name = stringAsArray[0];
    const size = +stringAsArray[1].split('-')[0];

    this.stepForm.get('EncryptionAlgorithm').setValue(EncryptionAlgorithm[name === '3DES' ? 'TripleDES' : name]);
    this.stepForm.get('EncryptionKeySize').setValue(size);
  }

  updateEncryptionAlgorithmDisplays(algorithm: EncryptionAlgorithm, size: number): void {
    this.encryptionAlgorithmDisplays = cloneDeep(this.algorithmMap[EncryptionAlgorithm.AES]);

    if (algorithm !== EncryptionAlgorithm.AES) this.encryptionAlgorithmDisplays.push(...this.algorithmMap[algorithm]);

    this.selectedEncryptionAlgorithmDisplays = `${EncryptionAlgorithm[algorithm]} ${size}-bit`;
  }

  enableEncryptionChangeHandler(event: boolean): void {
    const actionName = event ? 'enable' : 'disable';

    if (this.isNBF || (this.isLinux && this.correctlyVersionAgent)) this.stepForm.get('hint')[actionName]();

    this.stepForm.get('EncryptionPassword')[actionName]();

    if (!this.SaveDeletedDataInCloud || !event) this.stepForm.get('UseFileNameEncryption')[actionName]();

    this.updateEncryptionAlgorithmDisplays(this.stepForm.get('EncryptionAlgorithm').value, this.stepForm.get('EncryptionKeySize').value);
  }

  passwordNgModelChangeHandler(data: ConfirmPassData): void {
    if (data?.password && (!this.isEdit || data.password !== this.InitialValue.EncryptionPassword)) {
      this.stepForm.get('EncryptionPassword').markAsDirty();
      this.stepForm.get('EncryptionPassword').markAsTouched();
    }

    this.stepForm.get('EncryptionPassword').setValue(data.password);
  }

  private updateRebackupConfirmationFromPass(password: string): void {
    const validMode = !this.isRDMode && !this.isNBF && !this.mainService.wasNotExecuted && this.planType == PlanTypes.Plan;
    const changed = this.InitialValue && password !== this.InitialValue.EncryptionPassword;

    this.shouldShowRebackupConfirmation = validMode && changed;
  }

  private userAndStorageSubscribeHandler(user: Administrator, storage: UiStorageType): void {
    const hidePasswordRecoveryModal = storage?.[UiStorageKey.HidePasswordRecoveryModal] === 'true';
    const forcePasswordRecoveryPossible = !user.ProviderInfo.PasswordRecoveryEnabled && this.isNBF && !this.isLinux;

    this.needToForceRecovery = forcePasswordRecoveryPossible && user.IsProvider && !hidePasswordRecoveryModal;
    // Alert for subadmins instead of pass-recovery forcing modal
    this.isPassRecoveryAlertShowingPossible = forcePasswordRecoveryPossible && !user.IsProvider;
  }

  private updatePassRecoveryFromUseEncryption(useEncryption: boolean): void {
    this.openForcePasswordRecoveryModal(useEncryption);

    if (useEncryption && this.isPassRecoveryAlertShowingPossible) {
      this.needToShowPassRecoveryAlert = true;
    }

    if (!useEncryption) {
      this.needToShowPassRecoveryAlert = false;
    }
  }

  private showRepackupConfirmationModal(): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:compression_encryption_settings_changed_title') },
      footer: {
        okButton: { text: this.i18nPipe.transform('buttons:yes', { format: 'title' }) },
        cancelButton: { text: this.i18nPipe.transform('buttons:no', { format: 'title' }) }
      }
    };
    const message = this.i18nPipe.transform('wizards:compression_encryption_settings_changed_text');
    this.modalService
      .open(modalSettings, message)
      .then(this.goToNextStepAfterRebackupConfirmation.bind(this), this.goToNextStepAfterRebackupConfirmation.bind(this))
      .catch(noop);
  }

  private goToNextStepAfterRebackupConfirmation(result: boolean) {
    this.stepForm.get('rebackup').setValue(!!result);
    this.shouldShowRebackupConfirmation = false;
    this.stepForm.updateValueAndValidity();

    if (this.stepForm.valid) {
      this.nextStep.emit(this.isNeedSave);
    }
  }
}
