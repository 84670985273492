import { BaseForPlanHelper } from '@modules/wizards/helpers/bases/base-for-plan-helper';
import { SQLFormModel } from '@modules/wizards/models/SQL/sql-form-model';
import { WizardHelpersSettings } from '@modules/wizards/helpers/bases/base-for-steps-helper';
import { PlanDataFromStepsHelper } from '@modules/wizards/helpers/plan-data-from-steps-helper/plan-data-from-steps-helper';
import { PlanTypes } from '@models/PlanTypes.enum';

export class SQLPlanFromStepsHelper extends BaseForPlanHelper {
  public static SQLModel: SQLFormModel = null;

  public static getUpdatedPlan(plan, model: SQLFormModel, settings: WizardHelpersSettings): any {
    const newPlan = Object.assign({}, plan);

    this.SQLModel = model;
    this.updateModel(newPlan, settings);

    return newPlan;
  }

  static updateModel(newPlan, settings: WizardHelpersSettings): void {
    PlanDataFromStepsHelper.updateNewPlanByNameStep(newPlan, this.SQLModel.planNameStep);
    PlanDataFromStepsHelper.updateNewPlanByWhereBackupStep(newPlan, this.SQLModel.whereBackupStep, this.isNBF || this.isLinux);

    PlanDataFromStepsHelper.updateNewPlanBySQLServerInstance(newPlan, this.SQLModel.sqlServerInstanceStep);
    PlanDataFromStepsHelper.updateNewPlanBySQLSourceDatabases(newPlan, this.SQLModel.sqlSourceDatabasesStep);

    PlanDataFromStepsHelper.updateNewPlanFromAdvancedClassOrTier(
      newPlan,
      this.SQLModel.advancedOptionsStep,
      this.SQLModel.advancedOptionsFlags
    );
    PlanDataFromStepsHelper.updateNewPlanByCompressionEncryptionStep(newPlan, this.SQLModel.compressionEncryptionStep, this.isNBF);
    PlanDataFromStepsHelper.updateNewPlanByRetentionPolicyStep(
      newPlan,
      this.SQLModel.retentionPolicyStep,
      this.SQLModel.whereBackupStep.IsHybridBackup,
      false,
      PlanTypes.DataBasePlan
    );
    PlanDataFromStepsHelper.updateNewPlanByScheduleStep(
      newPlan,
      this.SQLModel.scheduleStep,
      this.SQLModel.scheduleAdvancedStep,
      this.SQLModel.simpleScheduleStep,
      this.isLinux,
      true
    );
    PlanDataFromStepsHelper.updateNewPlanByPrePostActionsStep(
      newPlan,
      this.SQLModel.prePostActionsStep,
      !settings.allowPrePostActions,
      this.isNBF
    );
    PlanDataFromStepsHelper.updateNewPlanByNotificationStep(newPlan, this.SQLModel.notificationStep);
    PlanDataFromStepsHelper.preparePlanForSending(newPlan, !settings.isRD);
  }
}
