<mbs-modal [isCustomModal]="true">
  <ng-container modal-header>{{title}}</ng-container>

  <ng-container modal-body>
    <mbs-alert [type]="alertType"
               [icon]="true"
               class="mb-0">
      {{text}}
    </mbs-alert>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button *ngIf="okButtonText"
                type="primary"
                [id]="'save-button'"
                (click)="save()">
      {{okButtonText}}
    </mbs-button>
    <mbs-button data-dismiss="modal"
                type="secondary"
                [id]="'close-button'"
                (click)="close()">
      {{cancelButtonText}}
    </mbs-button>
  </ng-container>
</mbs-modal>
