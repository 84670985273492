<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:advanced_settings_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-alert *ngIf='!isRDMode && !isRestore && !isAppAwareEnabled && isVMWare'
             class="mt-3 text-sm {{elementSelectors.css.appAwareInfoPanel}}"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:advanced_settings_app_aware_warning' | i18next}}
  </mbs-alert>

  <mbs-form-group *ngIf="isHyperV" class="pt-3">
    <mbs-checkbox formControlName="useChangeBlockTracking"
                  [label]="'wizards:advanced_settings_block_tracking_label' | i18next"
                  [id]="elementSelectors.id.CBTCheckbox">
      <ng-container appendLabel>
        <a *ngIf="ability.can('read', 'HelpMarketing')"
           class="ml-2"
           href="/AP/Help/backup/hyper-v/cbt-for-hyperv" target="_blank">
          {{'buttons:learnMore' | i18next}}
        </a>
        <div *ngIf="!isCBTAvailable"
             class="ico ico-InfoCircle ml-2 cursor-help text-info"
             tooltipClass="tooltip-lg"
             [attr.data-test-element]="elementSelectors.name.CBTCheckDoxDisableTooltip"
             [container]="'body'"
             [ngbTooltip]="'wizards:advanced_settings_block_tracking_label_disable_tooltip' | i18next"></div>
      </ng-container>
    </mbs-checkbox>
  </mbs-form-group>

  <mbs-form-group *ngIf="isVMWare"
                  class="{{ isAppAwareEnabled ? '' : 'pt-3' }}"
                  [id]="elementSelectors.id.VMWareFormGroup">
    <mbs-radio *ngIf='!isAppAwareEnabled'
               formControlName="quiesceType"
               name="vmQuiesceType"
               [id]="elementSelectors.id.quiesceTypeRadio1"
               [label]="'wizards:advanced_settings_try_label' | i18next"
               [value]="vmQuiesceType.TryApplicationConsistent">
      <ng-container appendLabel>
        <span class="ml-2 text-muted">{{'wizards:advanced_settings_try_label_append' | i18next}}</span>
      </ng-container>
    </mbs-radio>
    <mbs-radio *ngIf='!isAppAwareEnabled'
               formControlName="quiesceType"
               name="vmQuiesceType"
               [id]="elementSelectors.id.quiesceTypeRadio2"
               [label]="'wizards:advanced_settings_must_label' | i18next"
               [value]="vmQuiesceType.MustApplicationConsistent"></mbs-radio>
    <mbs-radio *ngIf='!isAppAwareEnabled'
               formControlName="quiesceType"
               name="vmQuiesceType"
               [id]="elementSelectors.id.quiesceTypeRadio3"
               [label]="'wizards:advanced_settings_disable_label' | i18next"
               [value]="vmQuiesceType.Disabled"></mbs-radio>

    <mbs-alert *ngIf="!isAppAwareEnabled"
               [type]="mbsPopupType.info"
               [icon]="true"
               class="mt-3">
      {{'wizards:advanced_settings_vmware_info_alert_text' | i18next}}
    </mbs-alert>

    <mbs-checkbox formControlName="useChangeBlockTracking"
                  [label]="useChangedBlockTrackingLabel"
                  [id]="elementSelectors.id.CBTCheckbox">
      <ng-container appendLabel>
        <a *ngIf="ability.can('read', 'HelpMarketing')"
           class="ml-2"
           href="/AP/Help/backup/vmware/cbt" target="_blank">
          {{'buttons:learnMore' | i18next}}
        </a>
      </ng-container>
    </mbs-checkbox>
  </mbs-form-group>

  <ng-container *ngIf="selectedStorage?.StorageType === storageType.AmazonS3">
    <mbs-form-group>
      <mbs-checkbox formControlName="useS3Acceleration"
                    [label]="'wizards:use_s3_label' | i18next"
                    [id]="'use-S3-acceleration-checkbox'">
        <ng-container appendLabel>
            <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                  [placement]="['right', 'bottom']"
                  [ngbTooltip]="'wizards:use_s3_label_tooltip' | i18next"></span>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group>
      <div class="row">
        <div class="col-4">
          <mbs-select formControlName="storageClass"
                      (change)="storageClassChangeHandler($event)"
                      [appendTo]="'.modal-content'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [clearable]="false"
                      [hideSelected]="true"
                      [items]="StorageClasses"
                      [id]="'advancedOptionsStorageClass'"
                      [label]="storageClassLabel"
                      [searchable]="false"></mbs-select>
        </div>
      </div>
    </mbs-form-group>
  </ng-container>

  <mbs-form-group *ngIf="selectedStorage?.StorageType === storageType.Azure">
    <div class="row">
      <div class="col-3">
        <mbs-select formControlName="azureAccessTier"
                    (change)="azureAccessTierChangeHandler($event)"
                    [appendTo]="'.modal-content'"
                    [bindValue]="'value'"
                    [bindLabel]="'label'"
                    [clearable]="false"
                    [hideSelected]="true"
                    [items]="AccessTires"
                    [id]="'advancedOptionsAzureAccessTier'"
                    [label]="azureAccessTierLabel"
                    [searchable]="false"></mbs-select>
      </div>
    </div>
  </mbs-form-group>

  <mbs-form-group *ngIf="isShowSyntheticFull">
    <mbs-checkbox formControlName="syntheticFull"
                  (change)="syntheticFullNeedValidate()"
                  [label]="'wizards:synthetic_full_label' | i18next"
                  [subtitle]="'wizards:synthetic_full_subtitle' | i18next"
                  [id]="'synthetic-full-checkbox'"></mbs-checkbox>
  </mbs-form-group>
</section>

<ng-template #storageClassLabel>
  {{'wizards:storage_class_label' | i18next}}
  <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
        tooltipClass="tooltip-xl"
        [placement]="['left', 'bottom-left', 'bottom']"
        [container]="'body'"
        [ngbTooltip]="classSettingPossibleTooltip"></span>
</ng-template>

<ng-template #classSettingPossibleTooltip>
  <dl class="text-left">
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_standard_label_tooltip' | i18next}}</span> {{'wizards:s3_standard_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_standard_ia_label_tooltip' | i18next}}</span> {{'wizards:s3_standard_ia_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_it_label_tooltip' | i18next}}</span> {{'wizards:s3_it_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_glacier_flex_label_tooltip' | i18next}}</span> {{'wizards:s3_glacier_flex_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_glacier_instant_label_tooltip' | i18next}}</span> {{'wizards:s3_glacier_instant_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_glacier_deep_label_tooltip' | i18next}}</span> {{'wizards:s3_glacier_deep_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_one_zone_label_tooltip' | i18next}}</span> {{'wizards:s3_one_zone_label_tooltip_desc' | i18next}}
    </dd>
  </dl>
</ng-template>

<ng-template #errorSyntheticFull>
  <p>{{'wizards:error_synthetic_text' | i18next}}</p>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{'wizards:error_synthetic_alert_text' | i18next}}
  </mbs-alert>
</ng-template>

<ng-template #azureAccessTierLabel>
  {{'wizards:access_tier_label' | i18next}}
  <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
        tooltipClass="tooltip-xl"
        [placement]="['left', 'bottom-left', 'bottom']"
        [container]="'body'"
        [ngbTooltip]="azureAccessTierTooltip"></span>
</ng-template>

<ng-template #azureAccessTierTooltip>
  <dl class="text-left">
    <dd>
      <span class="font-weight-semibold">{{'wizards:access_tier_hot_label_tooltip' | i18next}}</span> {{'wizards:access_tier_hot_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:access_tier_cool_label_tooltip' | i18next}}</span> {{'wizards:access_tier_cool_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:access_tier_archive_label_tooltip' | i18next}}</span> {{'wizards:access_tier_archive_label_tooltip_desc' | i18next}}
    </dd>
  </dl>
</ng-template>

<ng-template #useChangedBlockTrackingLabel>
  {{'wizards:advanced_settings_block_tracking_label' | i18next}}
  <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
        tooltipClass="tooltip-xl"
        [placement]="['left', 'bottom-left', 'bottom']"
        [container]="'body'"
        [ngbTooltip]="useChangedBlockTrackingTooltip"></span>
</ng-template>

<ng-template #useChangedBlockTrackingTooltip>
  <dl class="text-left mb-0">
    <dd>
      {{'wizards:advanced_settings_block_tracking_tooltip' | i18next}}
    </dd>
    <dd class="mb-0">
      {{'wizards:advanced_settings_block_tracking_tooltip_desc' | i18next}}
    </dd>
  </dl>
</ng-template>
