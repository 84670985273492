import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { AuthService } from '@services/auth.service';
import { finalize, Observable } from 'rxjs';

@Component({
  selector: 'mbs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public isMBSMode = true;
  public loadingPage = true;
  public loading$: Observable<boolean>;
  public authError = '';
  public vendor = '';

  constructor(private auth: AuthService, private router: Router, private appState: AppPersistentStateService) {
    this.loading$ = this.auth.loading$;
    if (this.auth.getOnlineAccessKey()) {
      this.isMBSMode = false;
      this.auth.getOnlineAccessInfo$.pipe(finalize(() => (this.loadingPage = false))).subscribe({
        next: (result) => {
          this.vendor = result?.info?.displayName;
        },
        error: () => {
          void this.router.navigateByUrl(RoutingPath.OnlineAccessNotFound);
        }
      });
    } else {
      this.loadingPage = false;
    }
  }

  onLoginSubmit(form: NgForm) {
    this.authError = '';
    if (form.valid) {
      this.auth.loginUser(form.value).subscribe({
        next: () => {
          localStorage.setItem('showForcePasswordRecovery', 'true');
          localStorage.setItem('showAllowOfflineEdit', 'true');

          if (this.isMBSMode) {
            void this.router.navigateByUrl(RoutingPath.ApComputers);
            this.appState.resetState();
          } else {
            this.router.navigateByUrl(RoutingPath.OnlineAccess);
          }
        },
        error: (err) => (this.authError = err.error)
      });
    }
  }
}
