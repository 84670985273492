import { DayOfWeek } from "@models/DayOfWeek.enum";
import { WeekNumber } from "@models/WeekNumber.enum";

export enum OverdueAfterSuccessPeriod {
  'Hour(s)' = 0,
  'Day(s)' = 1
}

/**
 * ffi flag Must be Keep in separated property - ForwardIncremental (GetPlan -> {plan: {ForwardIncremental: boolean}})
 */
export enum ScheduleType {
  noschedule,
  once,
  recurring,
  predefined,
  instantly,
  ffi
}

export interface IncrementalData {
  recurringPeriod: 'Daily' | 'Monthly';
  weeklyDayOfWeek: boolean[];
  occurrence: WeekNumber;
  dayOfWeek: DayOfWeek;
  dayOfMonthCount: number;
  dailyFreqPeriodOption: 'OccursAt' | 'OccursEvery';
  occursAtTime: string;
  occursEveryCount: number;
  occursEveryPeriod: string;
  occursEveryFromTime: string;
  occursEveryToTime: string;
  repeatEveryCount?: number;
  startFromDate?: Date;
}

export interface FullBackupData {
  enabled: boolean;
  recurringPeriod: 'Daily' | 'Monthly';
  weeklyDayOfWeek: boolean[];
  occurrence: WeekNumber;
  dayOfWeek: DayOfWeek;
  dayOfMonthCount: number;
  dailyFreqPeriodOption: 'OccursAt' | 'OccursEvery';
  occursAtTime: string;
  repeatEveryCount?: number;
  startFromDate?: Date;
}

export type ScheduleModalData = { incrementalData?: IncrementalData; fullBackupData?: FullBackupData };

export interface ScheduleStepValue {
  ScheduleType: string;
  specificDateGroup?: {
    date: Date | string;
    time: Date | string;
  };
  StopAfterEnabled: boolean;
  stopAfterHours: number;
  stopAfterMinutes: number;
  ForceMissedSchedule?: boolean;
  overdueAfterSuccessEnabled?: boolean;
  overdueAfterSuccessAmount?: number;
  overdueAfterSuccessPeriod?: number;
  incrementalData?: IncrementalData;
  fullBackupData?: FullBackupData;
  syncBeforeRun?: boolean;
  valid: boolean;
}
