import { AdvancedSettingsVirtualStepValue, VMQuiesceType } from '@modules/wizards/models/advanced-settings-vm-models';
import { VmwareHyperVBaseModel } from '@modules/wizards/models/base/vmware-hyper-v-base-model';
import { SelectHostStepValue } from '@modules/wizards/models/select-host-models';

export class VmwareFormModel extends VmwareHyperVBaseModel {
  advancedSettingsStep: AdvancedSettingsVirtualStepValue = {
    quiesceType: VMQuiesceType.TryApplicationConsistent,
    useChangeBlockTracking: true,
    useS3Acceleration: false,
    storageClass: 1,
    syntheticFull: true,
    valid: true
  };
  AvailableMachinePlans: any[] = [];
  selectHostStep: SelectHostStepValue = { server: '', login: '', password: '', remember: true, valid: true };

  constructor(public isLinux = false) {
    super(isLinux);
  }
}
