import { Component, forwardRef } from '@angular/core';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ModalService } from 'mbs-ui-kit';
import { BackupFileModelForStepsHelper } from '../../helpers/backup-file-model-helpers/backup-file-model-for-steps-helper';
import { BackupFilePlanFromStepsHelper } from '../../helpers/backup-file-model-helpers/backup-file-plan-from-steps-helper';
import { SupportMethodsForStepsFromPlan } from '../../helpers/support-methods-for-steps-from-plan';
import { BackupFileFormModel } from '../../models/backupFile/backup-form-model';
import { BackupPlan } from '../../models/base/backup-plan-model';
import { ScheduleType } from '../../models/schedule-models';
import { BackupFilter } from '../../models/what-backup-tree-model';
import { WizardsServicesHubService } from '../../services/wizards-services-hub.service';
import { WizardBaseClass } from '../wizard-base.class';

@UntilDestroy()
@Component({
  selector: 'mbs-backup-files-wizard',
  templateUrl: './backup-files-wizard.component.html',
  providers: [
    {
      provide: WizardBaseClass,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => BackupFilesWizard)
    }
  ]
})
export class BackupFilesWizard extends WizardBaseClass {
  public fromGettingStarted = false;

  constructor(
    private modalService: ModalService,
    services: WizardsServicesHubService,
    public i18nPipe: I18NextPipe,
    stepService: WizardStepsService
  ) {
    super(services, i18nPipe, stepService);

    this.fromGettingStarted = services.mainService.fromGettingStarted;
    this.FormModel = new BackupFileFormModel(this.isNBF, this.isLinux);

    this.initSubscribes();
  }

  updateStepData(plan, isDefault = false): void {
    this.setLoadedPlanAndRunChainRequestsForBackup(plan);

    if (!isDefault) {
      const formData = BackupFileModelForStepsHelper.BackupModelFromPlan(plan, this.defaultSettings());
      if (this.isRDMode) formData.advancedOptionsFlags = this.FormModel.advancedOptionsFlags;
      this.FormModel = formData;
    } else {
      this.FormModel.planNameStep.Name = SupportMethodsForStepsFromPlan.generatePlanName(
        this.mainService.compName,
        this.planType,
        this.mainService.timeZoneOffset
      );
      this.loadedPlan.plan = new BackupPlan();
      this.loadedPlan.plan.BackupFilter = new BackupFilter();
      this.updateStorages();
    }
  }

  enableVSSHandler(value: boolean): void {
    if (!this.isLinux) {
      this.FormModel.advancedOptionsStep.AlwaysUseVSS = value;
      if (!value) this.FormModel.advancedOptionsStep.UseFastNtfsScan = false;
      this.FormModel.advancedOptionsStep = { ...this.FormModel.advancedOptionsStep };
    }
  }

  offFastNTFSHandler(): void {
    this.FormModel.advancedOptionsStep.UseFastNtfsScan = false;
    this.FormModel.advancedOptionsStep.AlwaysUseVSS = true;
    this.FormModel.advancedOptionsStep = { ...this.FormModel.advancedOptionsStep };
    this.forceNextStepWizard(false, 'advancedOptionsStep', 'Advanced Options');
  }

  handleSave(): void {
    const recTypeNumber = +ScheduleType[this.FormModel.scheduleStep.ScheduleType];

    if (
      !this.isLinux &&
      !this.isNBF &&
      ((recTypeNumber === ScheduleType.predefined && this.FormModel.simpleScheduleStep.blockLevelBackup) ||
        (recTypeNumber === ScheduleType.recurring &&
          this.FormModel.scheduleAdvancedStep.blockLevel &&
          this.FormModel.scheduleAdvancedStep.blockLevel.Enabled))
    ) {
      this.enableVSSHandler(true);
    }

    const newPlan = BackupFilePlanFromStepsHelper.getUpdatedPlan(
      this.loadedPlan.plan,
      this.FormModel,
      this.defaultSettings(),
      this.isNBF,
      this.isLinux
    );

    this.savePlan(newPlan);
  }

  loadInfoHandler() {}

  protected loadInfoHandlerFinally() {}
}
