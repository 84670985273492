export enum RestoreType {
  LatestVersion = 0,
  PointInTime = 1,
  Manually = 2,
  ModificationPeriod = 3,
  BackupPeriod = 4
}

export enum BackupRestoreResult {
  success,
  Warn,
  Error
}

export interface RestorePointItem {
  backupResult: BackupRestoreResult;
  consistencyCheckResult: BackupRestoreResult;
  restoreVerificationResult: BackupRestoreResult;
  date: string;
  path?: string;
  encryptionPasswordHint: string;
  isCompressed: boolean;
  isEncrypted: boolean;
  isFull: boolean;
  needRunSync?: boolean;
  needDeepSync: boolean;
  originalSize: number;
  restorePointId: string;
  isContainNewData: boolean;
  restorePointName: string;
  sizeOnStorage: number;
  totalSavings: number;
}

export interface SpecificDateGroup {
  date: Date | string;
  time: Date | string;
}

export interface RestorePointStepValue {
  restoreType: RestoreType;
  manuallyDateTime?: SpecificDateGroup;
  fromDateTime: SpecificDateGroup;
  toDateTime: SpecificDateGroup;
  resultPoint?: RestorePointItem;
  syncBeforeRun?: boolean;
  valid: boolean;
}
