import { NgModule } from '@angular/core';
import { BillingPipe } from './billing.pipe';
import { PricePipe } from './price.pipe';

@NgModule({
  declarations: [PricePipe, BillingPipe],
  exports: [PricePipe, BillingPipe],
  providers: [PricePipe, BillingPipe]
})
export class LicensesPipesModule {}
