import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LicensesService } from '@services/licenses.service';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { TableHeader } from 'mbs-ui-kit';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';
import { MbsSize } from 'mbs-ui-kit/utils/enums/mbs-size-enum';
import { catchError, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-grant-license-for-computer-modal',
  templateUrl: './grant-license-for-computer-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrantLicenseForComputerModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  readonly MbsSize = MbsSize;
  readonly AlertType = MbsPopupType;

  public loading = false;
  public licenseTypesLoading = true;

  public headers: TableHeader[] = [
    { name: 'License type', gridColSize: '5fr' },
    { name: 'Count', class: '-end', gridColSize: '3fr' },
    { name: '', class: '-end', gridColSize: '2fr' }
  ];

  licensesTypes: Array<{
    id: string;
    name: string;
  }>;

  constructor(
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    private licensesService: LicensesService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private computersFacade: ComputersFacade
  ) {}

  ngOnInit(): void {
    this.licensesService.getTypes().subscribe((types) => {
      this.licensesTypes = this.baseModal.data.availableLicenses.map((license) => {
        const type = types?.find((l) => l.type === license.licenseType);

        return {
          count: license.count,
          id: type.type,
          name: type.displayName
        };
      });
      this.licenseTypesLoading = false;
      this.cdr.detectChanges();
    });
  }

  grantLicense(id: string): void {
    this.loading = true;
    this.licensesService
      .grantLicenseByType(this.baseModal?.data.hid, id)
      .pipe(catchError(() => of(false)))
      .subscribe((result) => {
        this.loading = false;
        this.computersFacade.loadComputerByHid({ hid: this.baseModal?.data.hid, force: true });
        result && this.baseModal.close();
      });
  }

  buyLicense(): void {
    void this.router.navigate([RoutingPath.ApLicensesAspx], { queryParams: { buy: 'true' } });
  }
}
