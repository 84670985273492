<mbs-modal [title]="'download-build.module:brandsModalTitle' | i18next: { format: 'title' }" #modal>
  <ng-container modal-body>
    <div class="mbs-form_content"
         id="chooseBrandModal">
      <form [formGroup]="brandForm">
        <div class="mbs-form-group">
          <mbs-select [items]="licensedBrandList"
                      formControlName="brand"
                      bindLabel="brandName"
                      [appendTo]="'body'"
                      [searchable]="true"
                      [clearable]="false"></mbs-select>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button type="primary" (click)="onActionButtonPressed()" [loading]="isActionActivated">
      {{ confirmButtonText }}
    </mbs-button>
    <mbs-button type="secondary" (click)="modal.close()">
      {{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
