import { Component, ViewChild } from '@angular/core';
import { ConnectionType } from '@models/ConnectionType';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalComponent } from 'mbs-ui-kit';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-desktop-license-select-modal',
  templateUrl: './remote-connection-desktop-license-select-modal.component.html'
})
export class RemoteConnectionDesktopLicenseSelectModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  type: ConnectionType;

  get isWebRTC(): boolean {
    return this.type === ConnectionType.WebRTC;
  }

  ngAfterViewInit(): void {
    if (this.baseModal.data) {
      this.type = this.baseModal.data.type;
    }
  }
}
