export enum BackupTargetVolumesEnum {
  None = 0,
  SystemRequired = 1,
  AllDrives = 2,
  SelectedOnly = 3,
  FixedOnly = 4
}

export enum BackupTargetVolumesStringEnum {
  'none' = 0,
  'system_required' = 1,
  'all_partitions' = 2,
  'manually' = 3
}

export enum FileSystemTypeEnum {
  Unsupported = -1,
  Unknown = 0,
  FAT = 1,
  NTFS = 2,
  Raw = 3,
  ReFS = 4
}

export enum PartitionTableTypeEnum {
  Unsupported = -1,
  Unknown = 0,
  GuidedPartition = 1,
  MBRPartition = 2,
  Dynamic = 3,
  EntireDisk = 4
}

export enum VolumeUsageStateEnum {
  None,
  New,
  Removed
}

export enum WhatBackupHeaderNames {
  Disk = 'Disk',
  Volume = 'Volume',
  Size = 'Size',
  Used = 'Used',
  Label = 'Label',
  KeepBitLocker = 'Keep BitLocker',
  UseVSS = 'Use VSS',
  FS = 'FS',
  SectorSize = 'Sector Size'
}

export class DiskVolumeView {
  ExtentBasedId: string;
  VolumeUsageState: VolumeUsageStateEnum;
  FileSystemType: FileSystemTypeEnum;
  Enabled: boolean;
  Supported: boolean;
  Identity: string;
  IdentityForUiField?: string;
  WindowsVolumeIdentity: string;
  Type: string;
  PartitionTableType: PartitionTableTypeEnum;
  TypeAsString: string;
  BiosType: number;
  BiosIsActive: boolean;
  MbrPartitionIndex: number;
  MountPoints: string[];
  Label: string;
  DriveType: string;
  UsedSpace: number;
  UsedSpaceString: string;
  LengthString: string;
  Length: number;
  MountPointsInfo: string;
  RequiredBySystem: boolean;
  GuidName: string;
  GuidAttributes: string;
  IsBoot: boolean;
  SectorSize?: number | string;
  StartOffset: number;
  ShortDescription: string;
  DiskNumber: number;
  DiskId: string;
  TargetSize: number;
  UseVSS: boolean;
  KeepBitLocker: boolean;
  CanKeepBitlocker: boolean;
  State?: number;
}

export interface WhatBackupStepValue {
  BackupVolumes: BackupTargetVolumesEnum;
  partitions: DiskVolumeView[];
  valid: boolean;
}
