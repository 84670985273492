<section *ngIf="showItem"
         [formGroup]="mainForm">
  <div class="form-row mb-1">
    <div class="col-9">
      <mbs-checkbox *ngIf="needShowEnabled; else enabledFieldsText"
                    formControlName="enabledFields"
                    [label]="labelForEnabledFields"
                    [subtitle]="subtitleForEnabledFields"
                    (change)="enabledFieldsChangeHandler($event)"
                    [id]="'exclude-enabled-checkbox'"></mbs-checkbox>
      <ng-template #enabledFieldsText>
        {{labelForEnabledFields}}<br>
        {{subtitleForEnabledFields}}
      </ng-template>
    </div>
    <div class="col-3 text-right">
      <div class="d-inline-block"
           container="body"
           placement="left-top bottom"
           [ngbTooltip]="'buttons:pasteFromClipboardNotSupported' | i18next"
           [disableTooltip]="!pastFromClipboardNotSupported">
        <mbs-button container="body"
                    placement="left-top bottom"
                    type="primary"
                    size="sm"
                    icon="fa fa-paste"
                    [ngbTooltip]="pasteTooltip"
                    [disabled]="!mainForm.get('enabledFields').value || !!editedString || pastFromClipboardNotSupported"
                    [isCtrl]="true"
                    (click)="pasteFromClipboardHandler(error)">
          <span class="font-weight-semibold">{{'buttons:paste_from_clipboard' | i18next}}</span>
        </mbs-button>
      </div>
    </div>
  </div>

  <mbs-form-group [formGroup]="newPathForm">
    <mbs-input formControlName="newPath"
               maxLength="32767"
               [appendButtons]="[
                     {
                       icon: editedString ? 'fa fa-check-circle text-success' : 'fa fa-plus-circle',
                       id: 'newPathsButton',
                       disabled: newPathForm.get('newPath').disabled,
                       type: 'secondary'
                     },
                     {
                       icon: 'fa fa-close',
                       id: 'closeNewPathsButton',
                       disabled: false,
                       hide: !editedString,
                       type: 'secondary'
                     }
                   ]"
               [id]="'new-exclude-rule'"
               [buttonClickOnEnter]="!!newPathForm.get('newPath')?.value"
               [validationWhitespace]="true"
               (blur)="newPathsBlur($event)"
               (buttonClick)="addNewPaths($event)"
               (input)="newPathInputHandler($event)"></mbs-input>
  </mbs-form-group>
  <div class="mt-2 pt-1">
    <mbs-card [size]="'xs'"
              [staticHeight]="128">
      <div class="form-row mb-2 align-items-center flex-nowrap _hover-parent"
           *ngFor="let str of mainForm.get('paths')?.value; let i = index">
        <div class="col-auto text-xs flex-shrink-1 text-overflow"
             [class.text-muted]="editedString && editedString.value !== str">
            <span ngbTooltip="{{ str }}"
                  container="body"
                  class="pre-wrap"
                  tooltipClass="tooltip-lg text-left">{{str}}</span>
        </div>
        <div class="col-auto _hover-target"
             *ngIf="!newPathForm.get('newPath')?.disabled && !editedString">
          <div class="row align-items-center no-gutters lh-1">
            <div class="col-auto">
              <mbs-button [isCtrl]="true"
                          type="primary"
                          icon="fa fa-pencil px-1"
                          (click)="editHandler(i)"></mbs-button>
            </div>
            <div class="col-auto">
              <mbs-button [isCtrl]="true"
                          type="primary"
                          icon="fa fa-times-circle px-1"
                          (click)="delExcludeRuleField(i)"></mbs-button>
            </div>
          </div>
        </div>
      </div>
    </mbs-card>
  </div>
</section>

<ng-template #pasteTooltip>
  <div class="tooltip_content text-left">
    {{'wizards:past_tooltip_text' | i18next}}<br>
    <ng-container *ngIf="!isLinux">
      D:\Folder 1<br>
      D:\Folder 2<br>
      D:\Folder 3
    </ng-container>
    <ng-container *ngIf="isLinux">
      /home/folder 1<br>
      /home/folder 2<br>
      /home/folder 3
    </ng-container>
  </div>
</ng-template>

<ng-template #error>
  <mbs-form-group>
    <img src="assets/clipboard.png"
         alt="No Backup Plans" />
  </mbs-form-group>
  {{'validation:read_permission_error' | i18next}}
</ng-template>
