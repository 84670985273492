import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { isWindows } from '@utils/is-windows';
import { AbilityService } from 'ability';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { noop } from 'rxjs';

@Component({
  selector: 'mbs-quick-restore-schema-modal',
  templateUrl: './quick-restore-schema-modal.component.html'
})
export class QuickRestoreSchemaModalComponent {
  public readonly isWindows = isWindows();
  public readonly mbsPopupType = MbsPopupType;
  private mySchemaParams: { [key: string]: string };

  @Input() onlyAlert = false;
  @Input() preparing = false;
  @Input() needPreparingTextShow = false;
  @Input() downloadLink: string;
  @Input() bootableUSBDescription: TemplateRef<any>;
  @Input() set createSchemaParams(params: { [key: string]: string }) {
    if (!this.isWindows) return;

    this.mySchemaParams = params;

    if (params) this.createQuickRestoreSchema();
  }

  get createSchemaParams(): { [key: string]: string } {
    return this.mySchemaParams;
  }

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(public ability: AbilityService) {}

  public downloadQuickRestore(): void {
    window.open(this.downloadLink, '_blank');
  }

  public stringToBase64(jsonString: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(new Blob([jsonString], { type: 'application/json' }));
    });
  }

  public createQuickRestoreSchema(): void {
    const jsonObject = JSON.stringify(this.createSchemaParams);

    this.stringToBase64(jsonObject)
      .then((result: string) => {
        if (!result) return;

        const data = result.split('base64,');

        if (!data?.[1]) return;

        const url = 'msp360-quick-restore://data=' + data[1].trim();
        window.location = encodeURI(url).replaceAll('+', '%2B') as unknown as Location;
      })
      .catch(noop);
  }
}
