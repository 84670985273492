import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { AuthService } from '@services/auth.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import Brand, { BrandingCompany } from '../models/Brand';
import BrandLicense from '../models/BrandLicense';
import BrandVersion from '../models/BrandVersion';

@Injectable({
  providedIn: 'root'
})
export class BrandsService extends EntityCollectionServiceBase<Brand> {
  private myLicenses$: BehaviorSubject<BrandLicense[]> = new BehaviorSubject<BrandLicense[]>(null);
  public licenses$ = this.myLicenses$.asObservable();
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient, private authService: AuthService) {
    super('Brand', serviceElementsFactory);
  }

  getLicenses(): void {
    this.http.get('api/brands/licenses').subscribe((licenses: BrandLicense[]) => {
      this.myLicenses$.next(licenses);
    });
  }

  assignLicenseToBrand(licenseId: string, brandId: string): Observable<void> {
    return this.http
      .put<void>(`api/brands/licenses/${licenseId}/assign`, { BrandId: brandId })
      .pipe(tap(() => this.authService.fetchCurrentUser().subscribe()));
  }

  deassignLicenseFromBrand(licenseId: string): Observable<void> {
    return this.assignLicenseToBrand(licenseId, null);
  }

  getVersions(brandId): Observable<BrandVersion[]> {
    return this.http.get<BrandVersion[]>(`api/brands/${brandId}/versions`);
  }

  getDownloadLink(brandId: string, code: string): Observable<string> {
    return this.http.get(`api/brands/${brandId}/geturl`, { params: { code }, responseType: 'text' });
  }

  getDefault(): Observable<Brand> {
    return this.http.get<Brand>(`api/brands/default`);
  }

  getActive(): Observable<Brand[]> {
    return this.http.get<Brand[]>(`api/brands`, { params: { onlyActive: 'true' } });
  }

  getAllBrandingCompanies(): Observable<BrandingCompany[]> {
    return this.http.get<BrandingCompany[]>('api/brands/allcompanies');
  }

  checkSymbols(value): Observable<any> {
    return this.http.get('api/brands/checkname', {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' }),
      params: { name: value }
    });
  }
}
