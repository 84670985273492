<div class="font-weight-semibold"
     *ngIf="!isResetCodes">{{ 'tfa:recoveryCode:accountIsSecured' | i18next }}</div>
<div [ngClass]="{'mt-2': !isResetCodes}">{{ 'tfa:recoveryCode:recoveryCodes' | i18next }}</div>
<div class="text-xs mt-1">{{ 'tfa:recoveryCode:emergencyCases' | i18next }}</div>

<div class="mt-3">{{ codesGeneratedDate }}</div>

<ul class="list-unstyled font-weight-semibold mt-1" id="codes">
  <li *ngFor="let code of codes">{{ code }}</li>
</ul>

<div>
  <mbs-button class="mr-2"
              type="secondary"
              icon="fa fa-download"
              (click)="save()">{{ 'tfa:recoveryCode:saveToFile' | i18next: { format: 'title' } }}
  </mbs-button>
  <mbs-button class="mr-2"
              type="secondary"
              icon="fa fa-clipboard"
              (click)="copy()">{{ 'tfa:recoveryCode:clipboard' | i18next: { format: 'title' } }}
  </mbs-button>
  <mbs-button type="secondary"
              icon="fa fa-print"
              (click)="print()">{{ 'tfa:recoveryCode:print' | i18next: { format: 'title' } }}
  </mbs-button>
</div>

<div class="mt-4 d-flex align-items-center justify-content-between">
  <mbs-checkbox [(ngModel)]="isSavedRecoveryCodes"
                (change)="onSaveRecoveryCodes.emit(isSavedRecoveryCodes)"
                label="{{ 'tfa:recoveryCode:savedCheckbox' | i18next }}"></mbs-checkbox>
  <mbs-button *ngIf="isLogin"
              (click)="finish()"
              type="primary"
              [disabled]="!isSavedRecoveryCodes">{{ 'tfa:recoveryCode:finish' | i18next: { format: "title" } }}
  </mbs-button>
</div>
