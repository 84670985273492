import { Component, forwardRef, Input, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { isCBFBunch } from '@utils/is-cbf-bunch';
import { PlanMode } from '@models/PlanTypes.enum';
import { DefaultSelectData } from '@models/SelectData';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { baseDateFormatWithSeconds, cobbledDateFormat, dateTimePointsFormat, getFullDateFromDateTimeObject } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { FormsUtil, GuidEmpty, MbsSize, SortEvent, TableHeader } from 'mbs-ui-kit';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { RestorePointItem, RestorePointStepValue, RestoreType, SpecificDateGroup } from '@modules/wizards/models/restore-point-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { ParamsForRCRestoreMethods, WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { SupportMethodsForStepsFromPlan } from '@modules/wizards/helpers/support-methods-for-steps-from-plan';

const DateForSelect = 'DD-MM-YYYY HH:mm:ss';

const RestorePointStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => RestorePointStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-restore-point-step',
  templateUrl: './restore-point-step.component.html',
  providers: [RestorePointStepValueAccessor]
})
export class RestorePointStepComponent extends StepBase<RestorePointStepValue> {
  @Input() storageId: string;
  @Input() computerName: string;
  @Input() bunchId: string;
  public MbsSize = MbsSize;
  public loadedRestorePoints: RestorePointItem[] = [];
  public restorePoints: DefaultSelectData[] = [];
  public selectedRestorePoint: string;
  public providerOffset: string;

  getDiskSizeFromMB(size: number): string {
    return StepsHelpers.getDiskSizeFromMB(+(size / 1024 / 1024).toFixed(2));
  }

  public orderBy: SortEvent = { column: 'isFull', direction: 'desc' };

  handleSort(sort: SortEvent): void {
    this.orderBy = sort;
    if (sort.column === 'isFull') {
      this.loadedRestorePoints = this.loadedRestorePoints.sort((a: RestorePointItem, b: RestorePointItem) => {
        return sort.direction === 'desc' ? (a.isFull ? 1 : -1) : a.isFull ? -1 : 1;
      });
    } else if (sort.column === 'date') {
      this.loadedRestorePoints = this.loadedRestorePoints.sort((a: RestorePointItem, b: RestorePointItem) => {
        return sort.direction === 'desc'
          ? moment(a.date).isBefore(moment(b.date))
            ? 1
            : -1
          : moment(a.date).isBefore(moment(b.date))
          ? -1
          : 1;
      });
    } else if (sort.column === 'sizeOnStorage') {
      this.loadedRestorePoints = this.loadedRestorePoints.sort((a: RestorePointItem, b: RestorePointItem) => {
        return sort.direction === 'desc' ? (a.sizeOnStorage < b.sizeOnStorage ? 1 : -1) : a.sizeOnStorage > b.sizeOnStorage ? 1 : -1;
      });
    } else if (sort.column === 'isCompressed') {
      this.loadedRestorePoints = this.loadedRestorePoints.sort((a: RestorePointItem, b: RestorePointItem) => {
        return sort.direction === 'desc' ? (a.isCompressed ? 1 : -1) : a.isCompressed ? -1 : 1;
      });
    } else if (sort.column === 'isEncrypted') {
      this.loadedRestorePoints = this.loadedRestorePoints.sort((a: RestorePointItem, b: RestorePointItem) => {
        return sort.direction === 'desc' ? (a.isEncrypted ? 1 : -1) : a.isEncrypted ? -1 : 1;
      });
    }
    this.loadedRestorePoints = Array.from(this.loadedRestorePoints);
  }

  public headers: TableHeader[] = [
    { name: 'Backup Type', sort: 'isFull', gridColSize: '15fr' },
    { name: 'Date', sort: 'date', gridColSize: '20fr', gridColMin: '135px' },
    { name: 'Size on Storage', sort: 'sizeOnStorage', gridColSize: '15fr' },
    { name: 'Compressed', sort: 'isCompressed', gridColSize: '13fr' },
    { name: 'Encrypted', sort: 'isEncrypted', gridColSize: '13fr' }
  ];

  private currentDate = new Date();
  public dateTimeForm = new UntypedFormGroup({
    pointOfTimeData: new FormControl(this.currentDate, Validators.required),
    pointOfTimeTime: new FormControl('00:00:00'),
    modifiedFromData: new FormControl(this.currentDate, Validators.required),
    modifiedFromTime: new FormControl('00:00:00'),
    modifiedToData: new FormControl(this.currentDate, [Validators.required, this.toDateValidator.bind(this, false)]),
    modifiedToTime: new FormControl('03:00:00', this.toTimeValidator.bind(this, false)),
    backupFromData: new FormControl(this.currentDate, Validators.required),
    backupFromTime: new FormControl('00:00:00'),
    backupToData: new FormControl(this.currentDate, [Validators.required, this.toDateValidator.bind(this, true)]),
    backupToTime: new FormControl('03:00:00', this.toTimeValidator.bind(this, true))
  });

  private needUpdateDeepSync = false;

  toDateValidator(isBackup: boolean): ValidationErrors | null {
    if (this.dateTimeForm) {
      return this.dateTimeForm.get(isBackup ? 'backupToTime' : 'modifiedToTime').invalid ? { invalidDate: true } : null;
    }
    return null;
  }

  toTimeValidator(isBackup: boolean, control: AbstractControl): ValidationErrors | null {
    if (this.dateTimeForm && control.value) {
      const toDate = this.dateTimeForm.get(isBackup ? 'backupToData' : 'modifiedToData').value;
      const toTime = this.dateTimeForm.get(isBackup ? 'backupToTime' : 'modifiedToTime').value;
      const fromDate = this.dateTimeForm.get(isBackup ? 'backupFromData' : 'modifiedFromData').value;
      const fromTime = this.dateTimeForm.get(isBackup ? 'backupFromTime' : 'modifiedFromTime').value;
      const isAfterOrIsSame =
        moment(getFullDateFromDateTimeObject({ date: fromDate, time: fromTime }, true)).isAfter(
          getFullDateFromDateTimeObject({ date: toDate, time: toTime }, true)
        ) ||
        moment(getFullDateFromDateTimeObject({ date: fromDate, time: fromTime }, true)).isSame(
          getFullDateFromDateTimeObject({ date: toDate, time: toTime }, true)
        );
      return isAfterOrIsSame ? { afterFrom: true } : null;
    }
    return null;
  }

  updateToState(isBackup = false): void {
    const name = isBackup ? 'backupToTime' : 'modifiedToTime';
    const nameDate = isBackup ? 'backupToData' : 'modifiedToData';
    this.dateTimeForm.get(name).markAsDirty();
    this.dateTimeForm.get(name).markAsTouched();
    this.dateTimeForm.get(name).updateValueAndValidity();
    queueMicrotask(() => {
      this.dateTimeForm.get(nameDate).markAsDirty();
      this.dateTimeForm.get(nameDate).markAsTouched();
      this.dateTimeForm.get(nameDate).updateValueAndValidity();
    });
  }

  constructor(public i18nPipe: I18NextPipe, public mainService: RemoteManagementWizardsService, public stepService: WizardStepsService) {
    super(mainService);
    const hour =
      this.mainService.timeZoneOffset > 0
        ? Math.floor(this.mainService.timeZoneOffset / 60)
        : Math.ceil(this.mainService.timeZoneOffset / 60);
    const minute = this.mainService.timeZoneOffset - hour * 60;
    this.providerOffset = `${this.mainService.timeZoneOffset > 0 ? '+' : ''}${hour}:${
      ('' + minute).length === 1 ? '0' + Math.abs(minute) : Math.abs(minute)
    }`;
  }

  ngOnInit(): void {
    this.stepService.deepSyncSuccess$.pipe(untilDestroyed(this)).subscribe((success) => {
      if (success) {
        this.loadedRestorePoints = this.loadedRestorePoints.map((rp) => {
          const newRP = Object.assign({}, rp);
          if (this.value && this.value.resultPoint && this.value.resultPoint.restorePointId === rp.restorePointId) {
            newRP.needDeepSync = false;
            newRP.needRunSync = false;
          }
          return newRP;
        });
        this.restorePoints = this.getRestorePointsArray();
        this.nextStep.emit();
      }
    });

    this.initForm();

    this.stepForm
      .get('restoreType')
      .valueChanges.pipe(untilDestroyed(this), debounceTime(200))
      .subscribe((value) => this.restoreTypeChangeHandler(value));
    this.dateTimeForm.valueChanges.pipe(untilDestroyed(this), debounceTime(200)).subscribe((value) => this.setDateTimeToStepForm(value));
    this.stepForm
      .get('fromDateTime')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => (this.isRestoreIbb || this.isNBF) && this.updateDateFromLoadedPoints(this.loadedRestorePoints, value));
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      restoreType: new FormControl(0),
      manuallyDateTime: new UntypedFormGroup({ date: new FormControl(''), time: new FormControl('00:00:00') }),
      fromDateTime: new UntypedFormGroup({ date: new FormControl(''), time: new FormControl('00:00:00') }),
      toDateTime: new UntypedFormGroup({ date: new FormControl(''), time: new FormControl('00:00:00') }),
      resultPoint: new FormControl(null),
      syncBeforeRun: new FormControl(false)
    });

    this.initFormEvents();
  }

  onStepFormChange(value: RestorePointStepValue): void {
    this.value = {
      ...value,
      valid:
        this.stepForm.valid &&
        this.dateTimeForm.status !== 'INVALID' &&
        (!value.resultPoint || !value.resultPoint.needDeepSync || !this.stepForm.touched || this.stepService.deepSyncSuccess$.value)
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bunchId && changes.bunchId.currentValue) {
      const restoreType = this.stepForm ? (this.stepForm.get('restoreType') as FormControl) : null;
      const cbf = isCBFBunch(changes.bunchId.currentValue);

      if (cbf && restoreType.disabled) restoreType.enable();

      this.updateSyncBeforeRun(cbf);

      if (this.computerName && this.storageId && this.storageId !== GuidEmpty) {
        const invalidType = [RestoreType.ModificationPeriod, RestoreType.BackupPeriod, RestoreType.Manually];

        if (!cbf && invalidType.includes(restoreType.value)) restoreType.reset(RestoreType.LatestVersion);

        if (
          changes.bunchId.previousValue &&
          changes.bunchId.previousValue !== GuidEmpty &&
          changes.bunchId.currentValue !== changes.bunchId.previousValue
        ) {
          this.stepForm.get('fromDateTime').reset('');
        }

        this.getRestorePoints(restoreType, cbf);
      }
    }
  }

  updateSyncBeforeRun(cbf: boolean): void {
    if (this.isLinux && this.isRestorePlan) {
      if (cbf) this.stepForm.get('syncBeforeRun').enable();
      else this.stepForm.get('syncBeforeRun').disable();
    }
  }

  getRestorePoints(restoreType: FormControl, cbf: boolean): void {
    const treeParams: ParamsForRCRestoreMethods = {
      agentType: 'backup',
      commandType: 'GetRestorePointList',
      params: {
        ConnectionId: this.storageId,
        BunchId: this.bunchId,
        RestoreSourcePrefix: this.computerName,
        GenerationId: null
      }
    };

    this.loadInfo.emit({ loading: true });
    this.stepService
      .getRemoteCommandData(treeParams, this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          if (data && data.data) {
            this.loadedRestorePoints = data.data;
            this.restorePoints = this.getRestorePointsArray();
            if (!cbf && (!this.restorePoints || !this.restorePoints.length)) {
              this.selectedRestorePoint = '';
              this.stepForm.get('fromDateTime').reset('');
              restoreType.disable();
            } else restoreType.enable();
            if (this.isRestoreIbb && restoreType.value === RestoreType.Manually && this.mainService.mode === PlanMode.edit) {
              this.stepForm.get('fromDateTime').setValue(this.stepForm.get('manuallyDateTime').value);
              this.updateDateFromLoadedPoints(data.data, this.stepForm.get('manuallyDateTime').value);
            } else this.updateDateFromLoadedPoints(data.data, this.stepForm.get('fromDateTime').value);
          }
          this.loadInfo.emit({ loading: false });
        },
        error: () => {
          this.loadInfo.emit({ loading: false });
          this.loadedRestorePoints = [];
          this.restorePoints = [];
          this.stepForm.get('resultPoint').setValue(null);
        }
      });
  }

  getRestorePointsArray(): DefaultSelectData[] {
    let ver = '';
    for (let i = 0; i < 5; i++) {
      if (this.mainService.backupVersionUpdated[i]) ver += this.mainService.backupVersionUpdated[i];
      else this.mainService.backupVersionUpdated += '0';
    }
    const lowVersion = !this.isLinux && +ver < 77127;
    return this.loadedRestorePoints
      .map((p) => {
        const size = p.sizeOnStorage / 1024;
        return lowVersion || p.isContainNewData
          ? {
              value: p.restorePointId,
              label: `${p.isFull ? 'Full' : 'Incremental'} — ${p.restorePointName} — ${
                size > 1024 ? StepsHelpers.getDiskSizeFromMB(size / 1024) : size.toFixed(2) + 'KB'
              } ${p.needDeepSync ? '— Need DeepSync' : ''}`
            }
          : null;
      })
      .filter((rp) => !!rp)
      .sort((a, b) => {
        return moment(a.value, cobbledDateFormat).isAfter(moment(b.value, cobbledDateFormat)) ? -1 : 1;
      });
  }

  forceValid(currentStep: any): void {
    if (currentStep.isNext && this.isNBF && this.needUpdateDeepSync) {
      const result = Object.assign({}, this.value.resultPoint);
      result.needRunSync = true;
      this.stepForm.get('resultPoint').reset(result);
    }
    FormsUtil.triggerValidation(this.dateTimeForm);
    FormsUtil.triggerValidation(this.stepForm);
  }

  updateDateFromLoadedPoints(points: RestorePointItem[], fromDateTime: SpecificDateGroup = null): void {
    const restoreType = this.stepForm.get('restoreType').value;
    let latestPoint: RestorePointItem;
    this.setSelectedRestorePoint(fromDateTime);
    if (this.isRestoreIbb && restoreType === RestoreType.Manually) {
      this.setSelectedRestorePointForManually(fromDateTime);
    } else this.setSelectedRestorePoint(fromDateTime);
    if (restoreType === RestoreType.LatestVersion && points.length && points[0].restorePointId) {
      latestPoint = points.reduce((r, n) => (moment(n.date).isAfter(r.date) ? n : r));
    } else if ((restoreType === RestoreType.PointInTime || restoreType === RestoreType.Manually) && fromDateTime && points.length) {
      const dateTime = moment.utc(getFullDateFromDateTimeObject(fromDateTime, true), baseDateFormatWithSeconds);
      if (!this.isNBF && restoreType !== RestoreType.Manually) {
        const filtered = points.filter((p) => dateTime.isAfter(p.date));
        if (filtered && filtered.length) latestPoint = filtered.reduce((r, n) => (moment(n.date).isAfter(r.date) ? n : r));
      } else {
        const filtered = points.filter((p) => {
          return dateTime.isSame(moment(p.date, baseDateFormatWithSeconds));
        });
        if (filtered && filtered.length) latestPoint = filtered[0];
      }
    }

    this.needUpdateDeepSync = latestPoint && latestPoint.needDeepSync;
    const currentPoint = this.stepForm.get('resultPoint').value;
    if (
      this.stepService.deepSyncSuccess$.value &&
      (!latestPoint || !this.needUpdateDeepSync || (currentPoint && latestPoint.restorePointId !== currentPoint.restorePointId))
    ) {
      this.stepService.deepSyncSuccess$.next(false);
    }
    this.stepForm.get('resultPoint').setValue(latestPoint);
  }

  setSelectedRestorePointForManually(fromDateTime: SpecificDateGroup = null): void {
    if (this.loadedRestorePoints && this.loadedRestorePoints.length) {
      if (fromDateTime) {
        const dateTime = moment(getFullDateFromDateTimeObject(fromDateTime, true), DateForSelect);
        const found = this.loadedRestorePoints.find((point) =>
          moment(
            moment(point.restorePointId, dateTimePointsFormat.test(point.restorePointId) ? cobbledDateFormat : DateForSelect).format(
              DateForSelect
            ),
            DateForSelect
          ).isSame(moment(dateTime))
        );
        this.selectedRestorePoint = found ? found.restorePointId : this.loadedRestorePoints[0].restorePointId;
      } else {
        this.selectedRestorePoint = this.loadedRestorePoints[0].restorePointId;
      }
    }
  }

  setSelectedRestorePoint(fromDateTime: SpecificDateGroup = null): void {
    if (this.restorePoints && this.restorePoints.length) {
      if (fromDateTime) {
        const dateTime = moment(getFullDateFromDateTimeObject(fromDateTime, true), DateForSelect);
        const found = this.restorePoints.find((point) =>
          moment(point.value, dateTimePointsFormat.test(point.value) ? cobbledDateFormat : DateForSelect).isSame(moment(dateTime))
        );
        this.selectedRestorePoint = found ? found.value : this.restorePoints[0].value;
      } else {
        this.selectedRestorePoint = this.restorePoints[0].value;
      }
    }
  }

  restorePointCheckedHandler(restorePoint, fromUI = false): void {
    if (restorePoint && restorePoint.length && restorePoint[0]) {
      this.selectedRestorePoint = restorePoint[0];
      this.selectedRestorePointChangeHandler(restorePoint[0]);
    } else {
      this.selectedRestorePoint = '';
      this.stepForm.get('fromDateTime').reset('');
    }
  }

  setDateTimeToStepForm(value): void {
    const restoreType = this.stepForm.get('restoreType').value;
    if (restoreType === RestoreType.PointInTime || restoreType === RestoreType.Manually) {
      this.stepForm.get('fromDateTime').reset({ date: value.pointOfTimeData, time: value.pointOfTimeTime });
    } else if (restoreType === RestoreType.ModificationPeriod) {
      this.stepForm.get('fromDateTime').reset({ date: value.modifiedFromData, time: value.modifiedFromTime });
      this.stepForm.get('toDateTime').reset({ date: value.modifiedToData, time: value.modifiedToTime });
    } else if (restoreType === RestoreType.BackupPeriod) {
      this.stepForm.get('fromDateTime').reset({ date: value.backupFromData, time: value.backupFromTime });
      this.stepForm.get('toDateTime').reset({ date: value.backupToData, time: value.backupToTime });
    }
  }

  restoreTypeChangeHandler(value: RestoreType): void {
    const isManually = value === RestoreType.Manually;
    if (value === RestoreType.PointInTime || isManually) {
      const fromDateTime = this.stepForm.get('fromDateTime').value;
      if (isManually && !this.selectedRestorePoint) this.setSelectedRestorePointForManually(fromDateTime);
      if ((!this.isLinux || (!fromDateTime.date && fromDateTime.time === '00:00:00')) && this.selectedRestorePoint) {
        this.selectedRestorePointChangeHandler(this.selectedRestorePoint);
      }
      this.switchDateTimeFormDisabledState(['pointOfTimeData', 'pointOfTimeTime']);
    } else if (value === RestoreType.ModificationPeriod) {
      this.switchDateTimeFormDisabledState(['modifiedFromData', 'modifiedFromTime', 'modifiedToData', 'modifiedToTime']);
    } else if (value === RestoreType.BackupPeriod) {
      this.switchDateTimeFormDisabledState(['backupFromData', 'backupFromTime', 'backupToData', 'backupToTime']);
    } else this.switchDateTimeFormDisabledState([]);
  }

  selectedRestorePointChangeHandler(event: string): void {
    const dateTime = SupportMethodsForStepsFromPlan.setSpecificDateGroup(
      true,
      moment(event, dateTimePointsFormat.test(event) ? cobbledDateFormat : DateForSelect).format(baseDateFormatWithSeconds)
    );
    this.stepForm.get('fromDateTime').reset(dateTime);
  }

  switchDateTimeFormDisabledState(enabledItems: string[]): void {
    const isVoidArray = !enabledItems.length;
    const fromData = this.stepForm.get('fromDateTime').value;
    const toData = this.stepForm.get('toDateTime').value;
    if (isVoidArray) {
      this.stepForm.get('fromDateTime').disable();
      this.stepForm.get('toDateTime').disable();
    } else if (enabledItems.length === 2) {
      this.stepForm.get('fromDateTime').enable();
      this.stepForm.get('toDateTime').disable();
    } else if (enabledItems.length > 2) {
      this.stepForm.get('fromDateTime').enable();
      this.stepForm.get('toDateTime').enable();
    }

    if (!fromData.date) fromData.date = this.currentDate;
    if (!toData.date) toData.date = this.currentDate;
    for (const key in this.dateTimeForm.getRawValue()) {
      if (isVoidArray) this.dateTimeForm.get(key).disable();
      else if (~enabledItems.findIndex((i) => i === key)) {
        this.dateTimeForm.get(key).enable();
        if (key == 'pointOfTimeData' || key === 'modifiedFromData' || key === 'backupFromData')
          this.dateTimeForm.get(key).reset(fromData.date);
        else if (fromData.time && (key == 'pointOfTimeTime' || key === 'modifiedFromTime' || key === 'backupFromTime'))
          this.dateTimeForm.get(key).reset(fromData.time);
        else if (key == 'modifiedToData' || key === 'backupToData') this.dateTimeForm.get(key).reset(toData.date);
        else if (toData.time && (key == 'modifiedToTime' || key === 'backupToTime')) this.dateTimeForm.get(key).reset(toData.time);
      } else this.dateTimeForm.get(key).disable();
    }

    if ((this.isRestoreIbb || this.isNBF) && this.loadedRestorePoints && this.loadedRestorePoints.length) {
      this.updateDateFromLoadedPoints(this.loadedRestorePoints, fromData);
    }
  }
}
