import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, noop, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationSettings } from '../models/Notification';
import { NotificationServerSettingsService } from './notification-server-settings.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalNotificationService {
  private myLoaded$ = new BehaviorSubject(false);
  public loaded$ = this.myLoaded$.asObservable();

  private myLoading$ = new BehaviorSubject(false);
  public loading$ = this.myLoading$.asObservable();

  private myNotification = new ReplaySubject<NotificationSettings>(1);
  public notification$ = this.myNotification.asObservable();

  constructor(private http: HttpClient) {}

  get(): Observable<NotificationSettings> {
    this.myLoading$.next(true);
    this.http.get<NotificationSettings>('api/notification-options').subscribe(
      notification => {
        this.myLoaded$.next(true);
        this.myNotification.next(notification);
      },
      noop,
      () => {
        this.myLoading$.next(false);
      }
    );
    return this.notification$;
  }

  save(settings: NotificationServerSettingsService): Observable<NotificationSettings> {
    return this.http.put<NotificationSettings>('api/notification-options', settings).pipe(
      tap(settings => {
        this.myNotification.next(settings);
      })
    );
  }
}
