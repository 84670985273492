export enum VirtualMachineType {
  Type = 'Type',
  Unknown = 'Unknown',
  PhysicalMachine = 'PhysicalMachine',
  VirtualMachine = 'VirtualMachine',
  PhysicalMachineHyperVhost = 'PhysicalMachineHyperVhost',
  VMWare = 'VMWare',
  HyperV = 'HyperV',
  Kvm = 'Kvm',
  KvmQemu = 'KvmQemu',
  Xen = 'Xen',
  Lxc = 'Lxc',
  Uml = 'Uml',
  Virtuozzo = 'Virtuozzo',
  AmazonV1 = 'AmazonV1',
  AmazonV2 = 'AmazonV2',
  Azure = 'Azure',
  Google = 'Google',
  OpenStack = 'OpenStack'
}
