import Administrator from '@models/Administrator';

export interface ApplicationState {
  mbsRA: boolean;
  currentAdmin: Administrator;
  footerExist: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storage: { [key: string]: any };
}

export const initialApplicationState: ApplicationState = {
  mbsRA: true,
  currentAdmin: null,
  footerExist: true,
  storage: null
};
