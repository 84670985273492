import { IBBFormModel } from '../../models/ibb/ibb-form-model';
import { ScheduleType } from '../../models/schedule-models';
import { BaseForPlanHelper } from '../bases/base-for-plan-helper';
import { WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { PlanDataFromStepsHelper } from '../plan-data-from-steps-helper/plan-data-from-steps-helper';
import { PlanTypes } from '@models/PlanTypes.enum';

export class IBBPlanFromStepsHelper extends BaseForPlanHelper {
  public static IBBModel: IBBFormModel = null;

  public static getUpdatedPlan(plan, model: IBBFormModel, settings: WizardHelpersSettings, isNBF = false, isLinux = false): any {
    this.IBBModel = model;
    this.isNBF = isNBF;
    this.isLinux = isLinux;
    this.updateSchedules(+ScheduleType[model.scheduleStep.ScheduleType] === ScheduleType.recurring, this.IBBModel.scheduleAdvancedStep);
    const newPlan = Object.assign({}, plan);
    this.updateModel(newPlan, settings);
    return newPlan;
  }

  static updateModel(newPlan, settings: WizardHelpersSettings): void {
    PlanDataFromStepsHelper.updateNewPlanByNameStep(newPlan, this.IBBModel.planNameStep);
    PlanDataFromStepsHelper.updateNewPlanByWhereBackupStep(newPlan, this.IBBModel.whereBackupStep, this.isNBF);
    PlanDataFromStepsHelper.updateNewPlanByCompressionEncryptionStep(newPlan, this.IBBModel.compressionEncryptionStep, this.isNBF);
    if (!this.isNBF) {
      PlanDataFromStepsHelper.updateNewPlanByRetentionPolicyStep(
        newPlan,
        this.IBBModel.retentionPolicyStep,
        this.IBBModel.whereBackupStep.IsHybridBackup,
        false,
        PlanTypes.BackupDiskImagePlan
      );
      PlanDataFromStepsHelper.updateNewPlanByScheduleStep(
        newPlan,
        this.IBBModel.scheduleStep,
        this.IBBModel.scheduleAdvancedStep,
        this.IBBModel.simpleScheduleStep
      );
    } else {
      PlanDataFromStepsHelper.updateNewNBFPlanByRetentionPolicyStep(newPlan, this.IBBModel.retentionPolicyStep, true);
      PlanDataFromStepsHelper.updateNewPlanArchiveByArchiveConsistencyStep(newPlan, this.IBBModel.archiveConsistencyStep, true);
      PlanDataFromStepsHelper.updateNewNBFPlanByScheduleStep(newPlan, this.IBBModel.scheduleStep);
    }
    PlanDataFromStepsHelper.updateNewPlanByWhatBackupStep(newPlan, this.IBBModel.whatBackupStep);
    PlanDataFromStepsHelper.updateNewPlanByAdvancedOptionsStep(
      newPlan,
      this.IBBModel.advancedOptionsStep,
      this.IBBModel.advancedOptionsFlags,
      settings.planType,
      this.isNBF
    );
    PlanDataFromStepsHelper.updateNewPlanByPrePostActionsStep(
      newPlan,
      this.IBBModel.prePostActionsStep,
      !settings.allowPrePostActions,
      this.isNBF
    );
    PlanDataFromStepsHelper.updateNewPlanByNotificationStep(newPlan, this.IBBModel.notificationStep);
    PlanDataFromStepsHelper.preparePlanForSending(newPlan, !settings.isRD);
  }
}
