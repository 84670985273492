import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterModule } from '@components/app-footer/app-footer.module';
import { ComputersSharedModule } from '@components/computers-shared/computers-shared.module';
import { POST_PAYMENT_TOKEN } from '@components/licenses/tokens/post-payment.token';
import { LoginModule } from '@components/login/login.module';
import { BackupSidePanelStorageModule } from '@root/mbs-ui/src/app/shared/components/sidepanel-backup/sidepanel-backup-storage.module';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18N_PROVIDERS } from '@root/mbs-ui/i18';
import { AuthService } from '@services/auth.service';
import { BuildService } from '@services/build-service/build.service';
import { ComputersAspxAbstractService, ComputersAspxService } from '@services/computers-legacy/computers-legacy.service';
import { ComputersAbstractService } from '@services/computers.service';
import { ConfigurationService } from '@services/configuration.service';
import { RmCommandsAbstractService, RmCommandsService } from '@services/rm-commands.service';
import { RmCommandsAbstractWrapper, RmCommandsWrapper } from '@services/rm-commands.wrapper';
import { APP_BACKEND_BUILD_VERSIONS } from '@utils/app-backend-build-versions';
import { WEB_CONNECT_URL } from '@utils/connect-url';
import { UtilsPipeModule } from '@utils/utils.pipe.module';
import { AbilityModule, AbilityService } from 'ability';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { APP_READONLY_MODE, MbsUiKitModule } from 'mbs-ui-kit';
import { map } from 'rxjs';
import { ErrorHandlerInterceptor } from '../../shared/interceptors/error-handler.interceptor';
import { RequestInterceptor } from '../../shared/interceptors/request.interceptor';
import { SessionInterceptor } from '../../shared/interceptors/session.interceptor';
import { ErrorModule } from '../error/error.module';
import { OnlineAccessAppComponent } from './components/app/app.component';
import { NotFoundOnlineAccessComponent } from './components/not-found/not-found.component';
import { OnlineAccessHeaderComponent } from './components/online-access-header/online-access-header.component';
import { OnlineAccessSidepanelBackupComponent } from './components/online-access-sidepanel-backup/online-access-sidepanel-backup.component';
import { OnlineAccessSidepanelWrapperComponent } from './components/online-access-sidepanel-wrapper/online-access-sidepanel-wrapper.component';
import { OnlineAccessPagingFacade } from './facades/onlineAccessPaging.facade';
import { OnlineAccessLayoutComponent } from './online-access-layout/online-access-layout.component';
import { OnlineAccessRoutingModule } from './online-access-routing.module';
import { OnlineAccessComponent } from './online-access.component';
import { OnlineAccessBuildService } from './services/build.service';
import { OnlineAccessComputersService } from './services/computers.service';
import { OnlineAccessDirectiveModule } from './shared/online-access-directive.module';
import { OnlineAccessSidepanelHandler } from './shared/online-access-sidepanel-handler.service';
import { OnlineAccessAppStoreModule } from './store/appStore.module';

const BASIC_APP_URL = window['__BASIC_APP_URL__'];
const BACKEND_BUILD_VERSIONS = window['__BACKEND_BUILD_VERSIONS__'];

@NgModule({
  declarations: [
    OnlineAccessAppComponent,
    OnlineAccessComponent,
    OnlineAccessSidepanelBackupComponent,
    OnlineAccessSidepanelWrapperComponent,
    OnlineAccessLayoutComponent,
    OnlineAccessHeaderComponent,
    NotFoundOnlineAccessComponent
  ],
  imports: [
    OnlineAccessAppStoreModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    I18NextModule.forRoot(),
    HttpClientModule,
    NgbModule,
    MbsUiKitModule,
    AbilityModule,
    UtilsPipeModule,
    ComputersSharedModule,
    FooterModule,
    OnlineAccessDirectiveModule,
    LoginModule,
    OnlineAccessRoutingModule,
    BackupSidePanelStorageModule,
    ErrorModule
  ],
  providers: [
    OnlineAccessPagingFacade,
    OnlineAccessSidepanelHandler,
    {
      provide: I18NEXT_NAMESPACE,
      useValue: ['online-access.module', 'computers.module']
    },

    { provide: APP_BASE_HREF, useValue: BASIC_APP_URL },
    { provide: APP_BACKEND_BUILD_VERSIONS, useValue: BACKEND_BUILD_VERSIONS },
    { provide: WEB_CONNECT_URL, useValue: RoutingPath.OnlineAccessWebConnect },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    ConfigurationService,
    {
      provide: POST_PAYMENT_TOKEN,
      deps: [AuthService],
      useFactory: (authService: AuthService) => authService.currentUser.pipe(map((r) => r?.ProviderInfo?.IsPostPayment))
    },
    Location,
    { provide: RmCommandsAbstractWrapper, useClass: RmCommandsWrapper },
    { provide: RmCommandsAbstractService, useClass: RmCommandsService },
    { provide: ComputersAspxAbstractService, useClass: ComputersAspxService },
    { provide: ComputersAbstractService, useClass: OnlineAccessComputersService },
    { provide: BuildService, useClass: OnlineAccessBuildService },
    {
      provide: APP_READONLY_MODE,
      useFactory: (ability: AbilityService): boolean => ability.can('read', 'Readonly'),
      deps: [AbilityService]
    },
    I18N_PROVIDERS(true)
  ],
  bootstrap: [OnlineAccessAppComponent]
})
export class OnlineAccessModule {}
