import * as moment from 'moment';
import { DayOfWeek } from './DayOfWeek.enum';
import { RecurType } from './RecurType.enum';
import { ScheduleType } from './schedule-type.enum';
import { WeekNumber } from './WeekNumber.enum';

export class DailyFrequency {
  PeriodOption: string;
  OccursAtTime: string;
  OccursEveryCount: number;
  OccursEveryPeriod: string;
  OccursEveryFromTime: string;
  OccursEveryToTime: string;
  OccursEveryDuration: moment.Duration;
  RepeatEveryCount?: number;
  StartFromDate?: Date;
  constructor(isLinux = false) {
    this.OccursEveryDuration = moment.duration(0);
    if (!isLinux) {
      this.StartFromDate = moment()
        .add(1, 'hours')
        .toDate();
    }
    this.OccursAtTime = moment().format('HH:mm:ss');
    this.OccursEveryCount = 1;
  }
}

export class WeeklyFrequency {
  DaysOfWeek: DayOfWeek[];
  RepeatEveryCount?: number;
  StartFromDate?: Date;
  constructor() {
    this.DaysOfWeek = [];
  }
}

export class MonthlyFrequency {
  OccurrencePeriod: WeekNumber;
  DayOfWeek: DayOfWeek;
  RepeatEveryCount?: number;
  StartFromDate?: Date;
  DayOfMonthCount: number;
}

export class YearlyFrequency {
  RepeatEveryCount: number;
  StartFromDate: Date;
}

export class RecurringSchedule {
  RecurringPeriod: RecurType;
  DailyFrequency: DailyFrequency;
  WeeklyFrequency: WeeklyFrequency;
  MonthlyFrequency: MonthlyFrequency;
  YearlyFrequency?: YearlyFrequency;
  constructor(isLinux = false) {
    this.RecurringPeriod = RecurType.Daily;
    this.DailyFrequency = new DailyFrequency(isLinux);
    this.WeeklyFrequency = new WeeklyFrequency();
    this.MonthlyFrequency = new MonthlyFrequency();
    if (!isLinux) this.YearlyFrequency = new YearlyFrequency();
  }
}

export class SpecificDateSchedule {
  OnceDate: Date;
  constructor() {
    this.OnceDate = new Date();
  }
}

export class ScheduleModel {
  ScheduleType: ScheduleType;
  SpecificDateSchedule: SpecificDateSchedule;
  RecurringSchedule: RecurringSchedule;
  constructor() {
    this.ScheduleType = ScheduleType.noschedule;
    this.SpecificDateSchedule = new SpecificDateSchedule();
    this.RecurringSchedule = new RecurringSchedule();
  }
}
