import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AdministratorLicense } from '@models/licenses/administrator-license';
import { AdministratorService } from '@services/administrator.service';
import { ModalComponent } from 'mbs-ui-kit';
import { catchError, Observable, of, switchMap, take } from 'rxjs';

@Component({
  selector: 'mbs-manage-license-admin-modal',
  templateUrl: './manage-license-admin-modal.component.html',
  styleUrls: ['./manage-license-admin-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageLicenseAdminModalComponent implements AfterViewInit {
  public loading = false;
  public selectedLicenseType;
  public isAvailableLicensesLoading = true;
  public licensesTypes: Observable<Array<AdministratorLicense>> = of(null);

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private administratorService: AdministratorService) {}

  manage(): void {
    this.loading = true;

    this.administratorService
      .grantLicense(this.selectedLicenseType, this.baseModal.data?.entityId, this.selectedLicenseType?.count > 0)
      .pipe(
        take(1),
        catchError(() => {
          this.loading = false;
          return of(false);
        })
      )
      .subscribe(() => {
        this.baseModal.save();
        this.loading = false;
      });
  }

  ngAfterViewInit(): void {
    this.setLicensesTypes();
  }

  setLicensesTypes() {
    this.isAvailableLicensesLoading = true;
    const typesFilter = this.baseModal.data.licenseTypesFilter;
    const licensesFilter: (id, second) => Observable<Array<AdministratorLicense>> = this.baseModal.data.filterLicensesFunc$;
    this.licensesTypes = this.administratorService.getAvailableLicenseSummaries().pipe(
      catchError(() => of(null)),
      switchMap((licenses) => {
        this.isAvailableLicensesLoading = false;
        if (licenses) {
          if (typesFilter && typesFilter.length > 0) {
            licenses = licenses.filter((x) => typesFilter.includes(x.licenseType));
          }
          if (licensesFilter) return licensesFilter(this.baseModal.data?.entityId, [...licenses]);
          return of(licenses);
        } else {
          return of([]);
        }
      })
    );
  }
}
