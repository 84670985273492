export enum ImagePath {
  'assets/backupFile.svg' = 1,
  'assets/file-restore.svg' = 2,
  'assets/sqlbackup.svg' = 4,
  'assets/vmware.svg' = 11,
  'assets/hyper-v.svg' = 13,
  'assets/ibb.svg' = 16,
  'assets/ibb-restore.svg' = 17,
  'assets/ec2backup.svg' = 19
}

export interface PlanNameStepValue {
  Name: string;
  SavePlanInCloud?: boolean;
  blockLevel?: boolean;
  valid: boolean;
}
