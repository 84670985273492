import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType } from 'mbs-ui-kit';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';

@UntilDestroy()
@Component({
  selector: 'backup-update-required-modal',
  templateUrl: './backup-update-required-modal.component.html'
})
export class BackupUpdateRequiredModalComponent implements AfterViewInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public computerName: string;
  public alertType = MbsPopupType.warning;

  constructor(private i18n: I18NextPipe, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.baseModal.data) {
      this.computerName = this.baseModal.data.computerName;
      this.cdr.detectChanges();
    }
  }
}
