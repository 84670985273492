import { Build } from '@models/build';
import { BuildType } from '@models/BuildType.enum';
import { SettingsType } from '@store/builds/builds.types';

export type BuildsState = SettingsType & {
  builds: Build[];
  buildsInProgress: BuildType[];
  showBackupUpdateDot: boolean;
  showRmmUpdateDot: boolean;
};

export const initialBuildsState: BuildsState = {
  isAutoUpdateEnabled: null,
  isSandBoxAvailable: null,
  isSettingsButtonVisible: null,
  builds: null,
  buildsInProgress: [],
  showBackupUpdateDot: false,
  showRmmUpdateDot: false
}
