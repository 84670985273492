import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  EditEmailsNotification,
  NotificationPlanRule,
  NotificationPlanTypeSettings,
  OldNotificationPerPlanSettings,
  OldNotificationTypes
} from '@models/Notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { GlobalNotificationService } from '@services/global-notification.service';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { EMAIL_REGEXP, MbsSize, MbsValidators, ModalService, ModalSettings, TableHeader } from 'mbs-ui-kit';
import { first } from 'rxjs/operators';
import { NotificationsStepValue } from '../../models/notifications-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const NotificationsStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => NotificationsStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-notifications-step',
  templateUrl: './notifications-step.component.html',
  providers: [NotificationsStepValueAccessor]
})
export class NotificationsStepComponent extends StepBase<NotificationsStepValue> implements OnInit {
  @Input() notification: OldNotificationPerPlanSettings = new OldNotificationPerPlanSettings();
  @Output() emailsNotificationChange = new EventEmitter();

  public readonly elementsSelector = {
    name: {
      mainNotificationRadio: 'mainNotificationRadio',
      notLinuxBlock: 'notLinuxBlock'
    }
  };
  private readonly emailRegExp = new RegExp(EMAIL_REGEXP);
  private readonly validationError = { email: { message: MbsValidators.validatorMessages.email() } };
  private readonly uniqueError = { unique: { message: this.i18nPipe.transform('wizards:notification_specify_label') } };
  private readonly addEmailModalSettings: ModalSettings = {
    responsive: true,
    header: { title: this.i18nPipe.transform('wizards:addSpecificEmail', { format: 'title' }), size: MbsSize.sm },
    footer: { okButton: { text: this.i18nPipe.transform('buttons:save') } },
    beforeDismiss: () => {
      this.newEmailForm.get('newEmail').reset();
      return true;
    }
  };

  public providerEmail: string;
  public isProvider: boolean;
  public NotificationPlanRule = NotificationPlanRule;
  public editEmailsNotification: EditEmailsNotification;
  public newEmailForm = new UntypedFormGroup({
    newEmail: new FormControl('', [
      Validators.required,
      Validators.email,
      this.customEmailValidator.bind(this),
      this.isUniqueEmail.bind(this)
    ])
  });

  public newEmailsHeaders: TableHeader[] = [
    { name: 'Email', gridColSize: '90fr' },
    { name: '', gridColSize: '10fr', class: '-center' }
  ];

  private globalNotification: NotificationPlanTypeSettings;

  private customEmailValidator(control: AbstractControl): ValidationErrors | null {
    return !control.value || this.emailRegExp.test(control.value) ? null : this.validationError;
  }

  private isUniqueEmail(control: AbstractControl): ValidationErrors | null {
    if (this.editEmailsNotification && ~this.editEmailsNotification.emails.findIndex((e) => e.email === control.value)) {
      return this.uniqueError;
    }

    return null;
  }

  constructor(
    auth: AuthService,
    public ability: AbilityService,
    public mainService: RemoteManagementWizardsService,
    private modalService: ModalService,
    private notificationService: GlobalNotificationService,
    public i18nPipe: I18NextPipe
  ) {
    super(mainService);
    auth.currentUser.pipe(first(), untilDestroyed(this)).subscribe((user) => {
      this.providerEmail = user.ProviderInfo.EmailNotificationsTo;
      this.isProvider = user.IsProvider;
    });
    if (!this.isRDMode) {
      notificationService.notification$.pipe(first(), untilDestroyed(this)).subscribe((data) => {
        this.globalNotification = this.isBackupPlan || this.isIBBPlan ? data.backupNotificationSettings : data.restoreNotificationSettings;
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      receiveNotificationOnCompleteGroup: new UntypedFormGroup({
        flag: new FormControl(false),
        case: new FormControl('whenFails')
      }),
      addEntryToEventLogOnCompleteGroup: new UntypedFormGroup({
        flag: new FormControl(false),
        case: new FormControl('whenFails')
      })
    });

    this.initFormEvents();
  }

  updateForm(value: NotificationsStepValue): void {
    this.stepForm.reset(value);

    if (value.receiveNotificationOnCompleteGroup) this.flagChangeHandler(value.receiveNotificationOnCompleteGroup.flag);
    if (value.addEntryToEventLogOnCompleteGroup) this.flagChangeHandler(value.addEntryToEventLogOnCompleteGroup.flag, false);
  }

  getPermission(type: OldNotificationTypes, rule: NotificationPlanRule): boolean {
    const rules =
      !this.notification.Enabled && this.globalNotification ? this.globalNotification[type.toLowerCase()] : this.notification[type];
    const currentRule = rules[!this.notification.Enabled ? 'rule' : 'Rule'];

    return Boolean(currentRule & rule);
  }

  setPermission(type: OldNotificationTypes, rule: NotificationPlanRule): void {
    const rules = this.notification[type];
    if (rule == NotificationPlanRule.Full) rules.Rule = rules.Rule > 0 ? 0 : NotificationPlanRule.Full;
    else rules.Rule ^= rule;
  }

  addClickHandler(type: OldNotificationTypes, component): void {
    if (this.notification.Enabled) {
      this.editEmailsNotification = { type, emails: this.notification[type].Emails.map((e) => ({ email: e })) };
      this.openModal(component);
    }
  }

  openModal(template: TemplateRef<any>): void {
    this.modalService
      .open(this.addEmailModalSettings, template)
      .then(this.saveEmails.bind(this))
      .catch(() => this.newEmailForm.get('newEmail').reset());
  }

  addEmailHandler(): void {
    const newEmail = this.newEmailForm.get('newEmail');

    if (newEmail.value && newEmail.valid) {
      const newArray = Array.from(this.editEmailsNotification.emails);
      newArray.push({ email: newEmail.value });
      this.editEmailsNotification.emails = newArray;
    }

    this.newEmailForm.get('newEmail').reset();
  }

  delEmailHandler(index): void {
    const newArray = Array.from(this.editEmailsNotification.emails);
    newArray.splice(index, 1);
    this.editEmailsNotification.emails = newArray;
  }

  saveEmails(): void {
    this.emailsNotificationChange.emit(this.editEmailsNotification);
    this.newEmailForm.get('newEmail').reset();
  }

  flagChangeHandler(event, isFirst = true): void {
    const control = this.stepForm.get(isFirst ? 'receiveNotificationOnCompleteGroup' : 'addEntryToEventLogOnCompleteGroup');
    event ? control.get('case').enable() : control.get('case').disable();
  }

  getTooltipByType(type: OldNotificationTypes): string {
    return this.i18nPipe.transform(`wizards:notification_${type.toLowerCase()}_tooltip`);
  }

  getEmailsCount(type: OldNotificationTypes): number {
    return !this.notification.Enabled && this.globalNotification
      ? this.globalNotification[type.toLowerCase()].emails.length
      : this.notification[type].Emails.length;
  }
}
