import { Injectable, NgModule } from '@angular/core';
import Company from '@models/Company';
import { PagedResponse, QueryFiltersParams, QueryPagingParams } from '@models/Paging';
import { Actions, EffectsModule } from '@ngrx/effects';
import { createSelector, MemoizedSelector, ReducerManager, Store } from '@ngrx/store';
import { CompaniesService } from '@services/companies.service';
import { Observable } from 'rxjs';
import { PagingStoreFactory } from '../pagingStoreFactory/pagingStoreFactory';
import { EntityStateModel, PagingSelectors } from '../pagingStoreFactory/pagingStoreTypes';
import { StoreTypes } from '../storeTypes.enum';

@Injectable({
  providedIn: 'root'
})
export class CompaniesStore extends PagingStoreFactory<Company> {
  protected storeType = StoreTypes.Companies;
  protected selectId = (entity: Company): string => {
    return entity.id;
  };

  protected getEntityById(id: string): Observable<Company> {
    return this.companiesService.getById(id);
  }
  protected getEntities(params: QueryFiltersParams & QueryPagingParams): Observable<PagedResponse<Company>> {
    return this.companiesService.get(params);
  }

  constructor(
    public actions$: Actions,
    public store: Store,
    public reducerManager: ReducerManager,
    private companiesService: CompaniesService
  ) {
    super(actions$, store, reducerManager);
    this.initStore();
  }

  // custom types
  public selectors: PagingSelectors<Company, Record<string, never>> & {
    selectDefaultCompany: MemoizedSelector<EntityStateModel<Company>, Company>;
  };

  // custom selectors
  protected prepareCustomSelectors(): void {
    this.selectors.selectDefaultCompany = createSelector(this.selectors.selectAll, (allEntities) =>
      allEntities.find((entity) => entity.defaultCompany)
    );
  }
}

@NgModule({
  imports: [EffectsModule.forFeature([CompaniesStore])]
})
export class CompaniesStoreModule {}
