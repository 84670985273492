export enum RunRestoreVerification {
  None,
  OnFull,
  OnDiff,
  OnAll
}

export interface ArchiveConsistencyStepValue {
  useFullConsistencyCheck: boolean;
  RunRestoreVerificationOn?: RunRestoreVerification;
  minutes?: number;
  seconds?: number;
  numCpu?: number;
  memoryInMb?: number;
  failureMinutes?: number;
  dynamicMemory?: {
    enabled: boolean,
    min?: number,
    max?: number
  }
  valid: boolean;
}
