import { PlanMode } from '@models/PlanTypes.enum';
import { RestoreIbbFormModel } from '../../models/restoreIBB/restore-ibb-form-model';
import { RestoreAsDiskEnum } from '../../models/type-data-models';
import { BaseForStepsHelper, WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { RestoreStepsDataFromPlanHelper } from '../restore-steps-data-from-plan-helper/restore-steps-data-from-plan-helper';
import { StepsDataFromPlanHelper } from '../steps-data-from-plan-helper/steps-data-from-plan-helper';
import { SupportMethodsForStepsFromPlan } from '../support-methods-for-steps-from-plan';

export class RestoreIbbModelForStepHelpers extends BaseForStepsHelper {
  public static RestoreIBBModel: RestoreIbbFormModel;

  public static RestoreIBBModelFromPlan(plan, settings: WizardHelpersSettings = this.DefaultSettings): RestoreIbbFormModel {
    this.RestoreIBBModel = new RestoreIbbFormModel(settings.isNBF);
    const newPlan = plan && (plan.plan || plan.Plan);
    if (newPlan) {
      this.setThisValues(plan, settings);
      this.setStorageType(settings.mode, newPlan.HybridID, newPlan.ConnectionID);
      this.updatePlanNameStep(newPlan, settings.compName);
      this.updateWhereBackupStep(newPlan);
      const restorePointArr = newPlan.RestorePoint ? newPlan.RestorePoint.split(this.isLinux ? '/' : '\\') : null;
      let backupPlanName = '';
      let restorePoint = '';
      if (restorePointArr && restorePointArr.length > 2) {
        backupPlanName = restorePointArr[1];
        restorePoint = restorePointArr[2];
      }
      this.RestoreIBBModel.computerToRestoreStep.computerName = newPlan.RestoreSourcePrefix || '';
      this.RestoreIBBModel.restorePointStep = RestoreStepsDataFromPlanHelper.updateRestorePoint(
        newPlan,
        false,
        restorePoint,
        settings.timeZoneOffset
      );
      this.RestoreIBBModel.fileFormats = RestoreStepsDataFromPlanHelper.updateFileFormats(plan.VirtualDiskFileFormatList);
      this.RestoreIBBModel.typeDataStep = RestoreStepsDataFromPlanHelper.updateTypeDataStep(newPlan);

      this.updateAzureOrEC2AndTemporary(this.RestoreIBBModel.typeDataStep.restoreAs, newPlan, this.RestoreIBBModel);

      this.RestoreIBBModel.backupToRestoreStep = RestoreStepsDataFromPlanHelper.updateBackupToRestore(
        newPlan,
        this.planType,
        backupPlanName
      );

      this.RestoreIBBModel.diskItems = newPlan.DiskInfo;
      // TODO Correct the DiskVolumes field when the backend with the desired field is ready
      this.RestoreIBBModel.partitions = RestoreStepsDataFromPlanHelper.updatePartitions(plan.DiskVolumes);
      this.RestoreIBBModel.partitionsStep = RestoreStepsDataFromPlanHelper.updatePartitionsStep(newPlan, plan.DiskVolumes);
      this.RestoreIBBModel.destinationStep = RestoreStepsDataFromPlanHelper.updateDestinationStep(newPlan, false, plan.DiskVolumes);
      this.RestoreIBBModel.encryptionOptionsStep = RestoreStepsDataFromPlanHelper.updateEncryptionOptionsStep(
        newPlan,
        settings.mode === PlanMode.create,
        this.isNBF
      );
      this.RestoreIBBModel.scheduleStep = StepsDataFromPlanHelper.updateSchedule(
        newPlan,
        plan.OverdueAfterSuccess,
        this.isCreate,
        this.isLinux,
        true
      );
      this.RestoreIBBModel.prePostActionsStep = StepsDataFromPlanHelper.updatePrePostActions(newPlan, true);
      this.RestoreIBBModel.notificationStep = StepsDataFromPlanHelper.updateNotification(
        newPlan.Notification,
        newPlan.WindowsEventLogNotification
      );
    }
    return this.RestoreIBBModel;
  }

  public static updatePlanNameStep(newPlan, compName: string): void {
    const name = newPlan.Name || SupportMethodsForStepsFromPlan.generatePlanName(compName, this.planType, this.timeZoneOffset);
    this.RestoreIBBModel.planNameStep = RestoreStepsDataFromPlanHelper.updatePlanNameStep(name, newPlan.DeletePlan);
  }
  public static updateWhereBackupStep(newPlan): void {
    this.RestoreIBBModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(
      newPlan.IsHybridBackup,
      newPlan.HybridID,
      newPlan.ConnectionID
    );
    this.RestoreIBBModel.Storages = this.storages;
  }

  private static updateAzureOrEC2AndTemporary(restoreAs: RestoreAsDiskEnum, newPlan, formModel): void {
    if (restoreAs === RestoreAsDiskEnum.AVM || restoreAs === RestoreAsDiskEnum.ADD) {
      formModel.azureData = RestoreStepsDataFromPlanHelper.updateAzureData(newPlan, formModel.azureData);
      formModel.azureStep = RestoreStepsDataFromPlanHelper.updateAzureStep(newPlan);
    } else if (restoreAs === RestoreAsDiskEnum.EBSVolume || restoreAs === RestoreAsDiskEnum.EC2) {
      formModel.EC2Data = RestoreStepsDataFromPlanHelper.updateEC2Data(newPlan, formModel.EC2Data);
      formModel.EC2 = RestoreStepsDataFromPlanHelper.updateEC2(newPlan);
      formModel.temporaryData = RestoreStepsDataFromPlanHelper.updateTemporaryData(newPlan, formModel.temporaryData);
      formModel.temporaryInstanceStep = RestoreStepsDataFromPlanHelper.updateTemporaryInstance(newPlan);
    }
  }
}
