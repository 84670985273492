import { HostInfoRow } from '@components/computers-shared/get-general-host-Info';
import { AvailablePlanTypes } from '@models/backup/available-plan-types';
import { PlanInfo } from '@models/backup/plan-info-model';
import { StorageAccountCamelCase } from '@models/StorageAccount';
import { createAction, props } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';

export const setComputer = createAction(`[${StoreTypes.ComputersBackup}] Set Computer Hid`, props<{ hid: string }>());
export const clear = createAction(`[${StoreTypes.ComputersBackup}] Clear`);

// General info
export const loadGeneralInfo = createAction(
  `[${StoreTypes.ComputersBackup}] Load General Info`,
  props<{ hid?: string; force?: boolean; quiet?: boolean }>()
);
export const loadGeneralInfoSuccess = createAction(
  `[${StoreTypes.ComputersBackup}] Load General Info Success`,
  props<{ hid: string; data: HostInfoRow[] }>()
);
export const loadGeneralInfoError = createAction(`[${StoreTypes.ComputersBackup}] Load General Info Error`, props<{ hid: string }>());

// Destination
export const loadDestinations = createAction(
  `[${StoreTypes.ComputersBackup}] Load Destinations`,
  props<{ hid?: string; force?: boolean; quiet?: boolean }>()
);
export const loadDestinationsSuccess = createAction(
  `[${StoreTypes.ComputersBackup}] Load Destinations Success`,
  props<{ hid: string; data: StorageAccountCamelCase[] }>()
);
export const loadDestinationsError = createAction(`[${StoreTypes.ComputersBackup}] Load Destinations Error`, props<{ hid: string }>());

// Plans
export const loadPlans = createAction(
  `[${StoreTypes.ComputersBackup}] Load Plans`,
  props<{ hid?: string; force?: boolean; quiet?: boolean }>()
);
export const loadPlansSuccess = createAction(
  `[${StoreTypes.ComputersBackup}] Load Plans Success`,
  props<{ hid: string; data: PlanInfo[]; timeZoneOffset: number }>()
);
export const loadPlansError = createAction(`[${StoreTypes.ComputersBackup}] Load Plans Error`, props<{ hid: string }>());

export const setPlanRunning = createAction(`${StoreTypes.ComputersBackup} Set Plan Running`, props<{ id: string; running: boolean }>());
export const setPlanSaving = createAction(`${StoreTypes.ComputersBackup} Set Plan Saving`, props<{ id: string; saving: boolean }>());

// Available Plans
export const loadAvailablePlans = createAction(
  `[${StoreTypes.ComputersBackup}] Load Available Plans`,
  props<{ hid?: string; force?: boolean }>()
);
export const loadAvailablePlansSuccess = createAction(
  `[${StoreTypes.ComputersBackup}] Load Available Plans Success`,
  props<{ hid: string; data: AvailablePlanTypes }>()
);
export const loadAvailablePlansError = createAction(`[${StoreTypes.ComputersBackup}] Load Available Plans Error`, props<{ hid: string }>());

// Timezone
export const setAgentTimezone = createAction(
  `[${StoreTypes.ComputersBackup}] Set Agent Timezone`,
  props<{ hid: string; timeZoneOffset: number }>()
);

export const loadSyncStatus = createAction(`[${StoreTypes.ComputersBackup}] Load Sync Status`, props<{ hid: string, force?: boolean }>());
export const loadSyncStatusSuccess = createAction(
  `[${StoreTypes.ComputersBackup}] Load Sync Status Success`,
  props<{ syncProcess: boolean }>()
);
