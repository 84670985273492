import { AccountTypeEnum } from './AccountTypeEnum';
import { AdministratorLicenseAvailabilityInfo } from './administrators/administrator-license-availability-info';
import Company from './Company';
import { MBSEntities } from './entity-decorator';
import { AdministratorLicense } from './licenses/administrator-license';
import { QueryPagingParams, SortDirections } from './Paging';
import Permissions from './Permissions';
import { ProviderData } from './ProviderData';

@MBSEntities.register()
export default class Administrator {
  public Id: string;
  public Email?: string = null;
  public Password?: string;
  public FirstName?: string = null;
  public LastName?: string = null;
  public Permissions?: Permissions = new Permissions();
  public Companies?: Company[];
  public DateCreated?: number = null;
  public LastLogin?: number = null;
  public Enabled? = true;
  public SendInstruction? = true;
  public Enabled2FA? = false;
  public IsProvider? = false;
  public IsSuperAdmin? = false;
  public IsReadOnly? = false;
  public IsWizardComplete? = true;
  public Is2FAEnabled? = false;
  public AccountType?: AccountTypeEnum = null;
  public ProviderInfo?: ProviderData;
  public ResellerInfo? = null;
  public License?: AdministratorLicense;
  public AdminTrialLicences?: { [key: number]: AdministratorLicenseAvailabilityInfo };
  public SignUpDate?: Date;

  public EnableOnlineAccessBackupData? = false;
  public EnableOnlineAccessConnect? = false;

  // TODO: Temp variable
  public balance = 1232.23;

  // @casl/ability
  static modelName = 'Administrator';
}

export type AdministratorsFilters = {
  search?: string;
  companyId?: string;
  enabled?: boolean;
};

export enum AdministratorsAPIFilterKeys {
  Search = 'search',
  Enabled = 'enabled'
}

export enum AdministratorsSortBy {
  Email = 'email',
  Company = 'company',
  License = 'license',
  FirstName = 'firstName',
  LastName = 'lastName',
  Enabled = 'enabled',
  DateCreated = 'dateCreated',
  LastLogin = 'lastLogin'
}

export interface AdministratorsSortParams {
  orderBy: `${AdministratorsSortBy}:${SortDirections}`;
}

export type AdministratorsSortFiltersParams = Partial<AdministratorsSortParams> & AdministratorsFilters;
export type AdministratorsQueryParams = AdministratorsSortFiltersParams & Partial<QueryPagingParams>;
