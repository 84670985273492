/* eslint camelcase: 0 */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GuardsCheckEnd, NavigationCancel, NavigationEnd, NavigationError, Router, RouterEvent } from '@angular/router';
import Administrator from '@models/Administrator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IsLoadingService } from '@service-work/is-loading';
import { AuthService } from '@services/auth.service';
import { AbilityService, EventTypes } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { noop, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-online-access-layout',
  templateUrl: './online-access-layout.component.html'
})
export class OnlineAccessLayoutComponent implements OnInit {
  isLoading$: Observable<boolean>;

  public activateRouteComponent: any;
  public currentUser: Administrator;

  constructor(
    private titleService: Title,
    private ability: AbilityService,
    private router: Router,
    private isLoadingService: IsLoadingService,
    private auth: AuthService,
    public i18nPipe: I18NextPipe
  ) {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.handleUpdateTitle();
    });
    this.ability.on(EventTypes.Update, () => this.handleUpdateTitle());
    this.handleUpdateTitle();

    this.auth.currentUser
      .pipe(
        first((user: Administrator) => !!user && !!user.ProviderInfo),
        untilDestroyed(this)
      )
      .subscribe((user: Administrator) => (this.currentUser = user));
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof GuardsCheckEnd ||
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        // If it's the end guard check, `add()` a loading indicator
        // For wait when resolve `DataChangeWatcher`
        if (event instanceof GuardsCheckEnd) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });
  }

  handleUpdateTitle(): void {
    this.titleService.setTitle('Online Access');
  }

  handleActiveRoute(componentInstance?: any): void {
    Promise.resolve()
      .then(() => {
        this.activateRouteComponent = componentInstance;
      })
      .catch(noop);
  }
}
