import { Tag } from '@models/tag';
import { Action, on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import * as ComputerTagsActions from './actions';
import { computerTagsAdapter, ComputerTagsState, initialComputerTagsState } from './state';

const reducer = createImmerReducer(
  initialComputerTagsState,
  on(ComputerTagsActions.loadComputerTagById, (state, action) => {
    if (!action.id || (!!state.entities[action.id] && !action.force) || action.quiet) return state;
    state.loading = true;
    return state;
  }),
  on(ComputerTagsActions.loadAllComputerTags, (state, action) => {
    if (action.quiet || (state.loaded && !action.force)) return state;
    state.loading = true;
    return state;
  }),
  on(ComputerTagsActions.setComputerTags, (state, action) => {
    const update = replaceEntities(action.tags, state);
    update.loading = false;
    update.loaded = true;
    return update;
  }),
  on(ComputerTagsActions.loadComputerTagsError, (state) => {
    state.loading = false;
    return state;
  }),
  on(ComputerTagsActions.setSelectedSuccess, (state, action) => {
    state.selected = action.selected;
    return state;
  }),
  on(ComputerTagsActions.clear, (state) => {
    const update = computerTagsAdapter.removeAll(state);
    update.loading = initialComputerTagsState.loading;
    update.selected = initialComputerTagsState.selected;
    update.loaded = initialComputerTagsState.loaded;
    return update;
  }),
  on(ComputerTagsActions.deleteComputerTag, (state, action) => {
    return computerTagsAdapter.removeOne(action.id, state);
  })
);

function replaceEntities(entities: Tag[], state: ComputerTagsState): ComputerTagsState {
  const newState = computerTagsAdapter.removeMany(
    entities.map((entity) => computerTagsAdapter.selectId(entity) as string),
    state
  );

  return computerTagsAdapter.addMany(entities, newState);
}

export function computerTagsReducer(state: ComputerTagsState | undefined, action: Action) {
  return reducer(state, action);
}
