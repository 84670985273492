import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { ViewMode } from '@modules/wizards/constants/view-mode';
import { FileSystemTypeEnum, WhatBackupHeaderNames } from '@modules/wizards/models/what-backup-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PlanMode } from '@root/mbs-ui/src/app/shared/models/PlanTypes.enum';
import { isEqual, isNil } from 'lodash';
import { TableHeader } from 'mbs-ui-kit';
import { BackupTargetVolumesEnum, DiskVolumeView, VolumeUsageStateEnum, WhatBackupStepValue } from '../../models/what-backup-models';
import { StepBase } from '../StepBase.class';

const WhatBackupStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => WhatBackupStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-what-backup-step',
  templateUrl: './what-backup-step.component.html',
  providers: [WhatBackupStepValueAccessor]
})
export class WhatBackupStepComponent extends StepBase<WhatBackupStepValue> implements OnInit {
  @Input() view: ViewMode = ViewMode.Default;
  @Input() settings?: {
    isNBF: boolean;
    backupVersionUpdated: string;
    mode?: PlanMode;
  };

  public readonly headerName = WhatBackupHeaderNames;
  public readonly viewMode = ViewMode;
  public readonly backupTargetVolumesEnum = BackupTargetVolumesEnum;
  public readonly FileSystemTypeEnum = FileSystemTypeEnum;
  public readonly disabledRowBy = { key: 'VolumeUsageState', value: +VolumeUsageStateEnum.Removed };
  public readonly bindSelected = 'IdentityForUiField';
  public readonly elementsSelector = {
    name: {
      defaultTitle: 'defaultTitle',
      onBoardingTitle: 'onBoardingTitle',
      fixedSelectedRadio: 'fixedSelectedRadio',
      selectedRadio: 'selectedRadio',
      listSelect: 'listSelect',
      isOfflineAlert: 'isOfflineAlert',
      agentVersionAlert: 'agentVersionAlert',
      requiredSystemAlert: 'requiredSystemAlert',
      changedImportedAlert: 'changedImportedAlert',
      vssNotUsedAlert: 'vssNotUsedAlert'
    }
  };
  private readonly headersMap: { [mode in ViewMode]: TableHeader[] } = {
    [ViewMode.Default]: [
      { name: WhatBackupHeaderNames.Disk, gridColSize: '11fr', overflow: true },
      { name: WhatBackupHeaderNames.Volume, gridColSize: '13fr', overflow: true },
      { name: WhatBackupHeaderNames.Size, gridColSize: '14fr' },
      { name: WhatBackupHeaderNames.Used, gridColSize: '14fr' },
      { name: WhatBackupHeaderNames.Label, gridColSize: '18fr', overflow: true },
      { name: WhatBackupHeaderNames.KeepBitLocker, class: '-center', gridColSize: '10fr' },
      { name: WhatBackupHeaderNames.UseVSS, class: '-center', gridColSize: '14fr' },
      { name: WhatBackupHeaderNames.FS, gridColSize: '10fr', overflow: true },
      { name: WhatBackupHeaderNames.SectorSize, gridColSize: '12fr' }
    ],
    [ViewMode.Onboarding]: [
      { name: WhatBackupHeaderNames.Disk, gridColSize: '11fr', overflow: true },
      { name: WhatBackupHeaderNames.Volume, gridColSize: '13fr', overflow: true },
      { name: WhatBackupHeaderNames.Size, gridColSize: '14fr' },
      { name: WhatBackupHeaderNames.Used, gridColSize: '14fr' },
      { name: WhatBackupHeaderNames.Label, gridColSize: '18fr', overflow: true },
      { name: WhatBackupHeaderNames.FS, gridColSize: '10fr', overflow: true }
    ]
  };

  public partitions: DiskVolumeView[] = [];
  public partitionsWithoutDisabled: DiskVolumeView[] = [];
  public requiredBySystemPartitions: DiskVolumeView[] = [];
  public onlyFixedPartitions: DiskVolumeView[] = [];
  public requiredBySystemPartitionsWithoutDisabled: DiskVolumeView[] = [];
  public mode: PlanMode;
  public canShowFixedOnly = false;

  public selectedItems: string[] = [];

  public VolumeUsageStateEnum = VolumeUsageStateEnum;

  private partitionsHash: { [key: string]: DiskVolumeView } = {};

  get isNBF(): boolean {
    return super.isNBF || !!this.settings?.isNBF;
  }

  get headers(): TableHeader[] {
    return this.headersMap[this.view] || [];
  }

  get requiredBySystemPartitionsIsNotSelected(): boolean {
    return this.requiredBySystemPartitions.some((i) => !this.selectedItems.some((e) => e === i[this.bindSelected]));
  }

  constructor(public mainService: RemoteManagementWizardsService) {
    super(mainService);
    this.updateCanShowFixedOnly();
  }

  ngOnInit(): void {
    this.initForm();

    this.mode = !isNil(this.settings?.mode) ? this.settings?.mode : this.mainService.mode;
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      BackupVolumes: new FormControl(1),
      partitions: new FormControl([])
    });

    this.initFormEvents();
  }

  onStepFormChange(value: WhatBackupStepValue): void {
    this.value = {
      ...value,
      valid: !this.isRDMode
        ? !this.isOffline || value.BackupVolumes === BackupTargetVolumesEnum.SelectedOnly
          ? !!value.partitions.length
          : true
        : true
    };
  }

  isColumnShown(name: WhatBackupHeaderNames): boolean {
    return this.headers.some((header) => header.name === name);
  }

  forceValid(): void {
    this.stepForm.updateValueAndValidity();
    this.stepForm.markAllAsTouched();
  }

  updateForm(value: WhatBackupStepValue): void {
    const existPartitions = value.partitions && value.partitions.length;
    this.changeImportedPlan(value);

    if (!this.partitions.length && existPartitions) {
      this.updatePartitionsFromNewValue(value);
    }

    if (!this.isRDMode) {
      this.updateSelectedItemsFromNewValue(value);
    }

    this.updateCanShowFixedOnly();
    this.partitions.forEach((part) => (this.partitionsHash[part[this.bindSelected]] = part));
    this.stepForm.get('BackupVolumes').setValue(value.BackupVolumes);

    if (!this.isRDMode && this.mode === PlanMode.edit && existPartitions) this.whatBackupChangeHandler(value.BackupVolumes);
  }

  whatBackupChangeHandler(event: BackupTargetVolumesEnum): void {
    if (this.isRDMode || (this.isOffline && this.isCreate)) return;

    let partitions = null;

    if (event === BackupTargetVolumesEnum.SystemRequired) {
      partitions = Array.from(
        this.requiredBySystemPartitionsWithoutDisabled.filter((i) => i[this.disabledRowBy.key] !== this.disabledRowBy.value)
      );
    }
    if (event === BackupTargetVolumesEnum.AllDrives) {
      partitions = Array.from(this.partitionsWithoutDisabled);
    }
    if (event === BackupTargetVolumesEnum.FixedOnly) {
      partitions = Array.from(this.partitionsWithoutDisabled.filter((d) => d.DriveType === 'Fixed'));
    }

    this.selectedItems = partitions ? partitions.map((i) => i[this.bindSelected]) : [...this.selectedItems];
    this.stepForm.get('partitions').setValue(partitions || this.selectedItems);
  }

  discChangeHandler(event, bindSelected, key): void {
    const newPartitions = Array.from(this.partitions);
    newPartitions.forEach((partition) => partition[this.bindSelected] === bindSelected && (partition[key] = event));
    this.partitions = newPartitions;

    const partitionsFromForm = this.stepForm.get('partitions').value;
    partitionsFromForm.forEach((partition) => partition[this.bindSelected] === bindSelected && (partition[key] = event));
    this.stepForm.get('partitions').setValue(partitionsFromForm);
  }

  itemsCheckedHandler(selectedItems: string[]): void {
    if (this.partitions.length) {
      this.changeSelectedBackupVolumesType(selectedItems);
      this.selectedItems = selectedItems;

      const newPartitions = [];
      this.partitions.forEach((part) => {
        part.Enabled = selectedItems.some((x) => x === part[this.bindSelected]);

        if (part.Enabled) newPartitions.push(Object.assign({}, part));
      });
      this.stepForm.get('partitions').setValue(newPartitions);
    }

    this.importedPlanChanged = false;
  }

  // Private Block
  private changeSelectedBackupVolumesType(selectedItems: string[]): void {
    const selectedDisabledCount = this.partitions.filter(
      (x) => selectedItems.includes(x[this.bindSelected]) && x[this.disabledRowBy.key] === this.disabledRowBy.value
    ).length;
    const notDisabledLength = this.partitionsWithoutDisabled.length;
    const fixedLength = this.onlyFixedPartitions.length;
    const selectedLength = selectedItems.length;
    const allSelectedIsNotDisabled = notDisabledLength && selectedLength - selectedDisabledCount === notDisabledLength;

    if (this.isOffline && isEqual(this.selectedItems, selectedItems)) return;
    if (allSelectedIsNotDisabled && selectedItems.every((str) => this.partitionsHash[str].RequiredBySystem)) return;
    if (allSelectedIsNotDisabled && (fixedLength !== notDisabledLength || this.value.BackupVolumes !== BackupTargetVolumesEnum.FixedOnly)) {
      return this.stepForm.get('BackupVolumes').setValue(BackupTargetVolumesEnum.AllDrives);
    }

    if (allSelectedIsNotDisabled) return;

    const notSystem =
      this.requiredBySystemPartitionsWithoutDisabled.length !== selectedLength ||
      this.requiredBySystemPartitionsWithoutDisabled.some((p) => !selectedItems.includes(p[this.bindSelected]));
    const notFixed = fixedLength !== selectedLength || this.onlyFixedPartitions.some((p) => !selectedItems.includes(p[this.bindSelected]));

    if (notSystem && (notFixed || !this.canShowFixedOnly)) {
      return void this.stepForm.get('BackupVolumes').setValue(BackupTargetVolumesEnum.SelectedOnly);
    }

    if (!notFixed && this.canShowFixedOnly) {
      return void this.stepForm.get('BackupVolumes').setValue(BackupTargetVolumesEnum.FixedOnly);
    }

    this.stepForm.get('BackupVolumes').setValue(BackupTargetVolumesEnum.SystemRequired);
  }

  private getBackupVersion(): number {
    return (
      (this.mainService.backupVersionUpdated && +this.mainService.backupVersionUpdated.substring(0, 3)) ||
      (this.settings?.backupVersionUpdated && +this.settings?.backupVersionUpdated.substring(0, 3))
    );
  }

  private updateCanShowFixedOnly(): void {
    this.canShowFixedOnly = this.isNBF && (this.isRDMode || this.getBackupVersion() >= 722);
  }

  private changeImportedPlan(value: WhatBackupStepValue): void {
    if (this.isRDMode && value.BackupVolumes === BackupTargetVolumesEnum.SelectedOnly) {
      value.BackupVolumes = BackupTargetVolumesEnum.SystemRequired;
      this.importedPlanChanged = true;
    }
  }

  private updatePartitionsFromNewValue(value: WhatBackupStepValue): void {
    this.partitions = Array.from(value.partitions);
    this.partitionsWithoutDisabled = value.partitions.filter((i) => i[this.disabledRowBy.key] !== this.disabledRowBy.value);

    if (!this.requiredBySystemPartitions.length) {
      this.requiredBySystemPartitions = Array.from(value.partitions.filter((part) => part.RequiredBySystem));
      this.onlyFixedPartitions = Array.from(this.partitionsWithoutDisabled.filter((d) => d.DriveType === 'Fixed'));
      this.requiredBySystemPartitionsWithoutDisabled = this.requiredBySystemPartitions.filter(
        (i) => i[this.disabledRowBy.key] !== this.disabledRowBy.value
      );
    }
  }

  private updateSelectedItemsFromNewValue(value: WhatBackupStepValue): void {
    if (this.mode === PlanMode.edit) {
      const newPartitions = value.partitions.filter((part) => part.Enabled);
      this.selectedItems = Array.from(newPartitions).map((i) => i[this.bindSelected]);

      return void this.stepForm.get('partitions').setValue(newPartitions);
    }

    this.whatBackupChangeHandler(
      value.BackupVolumes === BackupTargetVolumesEnum.AllDrives ? BackupTargetVolumesEnum.AllDrives : BackupTargetVolumesEnum.SystemRequired
    );
  }
}
