import { VirtualMachine } from './select-virtual-machines-models';

/**
 * Model for Application Aware step
 * @author Sergey P.
 */

export interface AppAwareStepValue {
  settings: AppAwareSettings[];
  valid: boolean;
}

/**
 * @interface AppAwareSettings - AppAwareGuestProcessingSettings
 * Array of AppAwareSettings - AppAwareGuestVmIndividualSettings
 * @property {String} VMName - Virtual Machine name. Key property
 * @property {String} virtualMachineId - temporary field. Only GUI needs it
 * @property {ProcessingMode} processingMode - Processing Mode settings (QuiesceType at agent side)
 */
export type AppAwareSettings = {
  virtualMachineId: string;
  VMName: string;
  processingMode: ProcessingMode;
  edited?: boolean;
}

export type AppAwareTableItem = AppAwareSettings & VirtualMachine;

/**
 * Variables fo ProcessingMode
 * At the agent side aka VMQuiesceType enum
 * Try (TryApplicationConsistent in VMQuiesceType) means "Try (Default)" in GUI
 * Required (MustApplicationConsistent in VMQuiesceType) means "Require" in GUI
 * Disabled (Disabled in VMQuiesceType) means "Disable" in GUI
 * @see AppAwareSettings
 */
export enum ProcessingMode {
  Try,
  Required,
  Disabled
}

export const getDefaultAppAwareSettings = (): AppAwareSettings => {
  return {
    virtualMachineId: null,
    VMName: null,
    processingMode: ProcessingMode.Try
  }
}
