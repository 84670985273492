import Administrator from '@models/Administrator';
import { UiStorageKey } from '@models/ui-storage';
import { createAction, props } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';

export const setMbsRAMode = createAction(`[${StoreTypes.Application}] Set MBS-RA Mode`, props<{ payload: boolean }>());
export const setCurrentAdmin = createAction(`[${StoreTypes.Application}] Set Current Admin`, props<{ payload: Administrator }>());
export const setFooterExist = createAction(`[${StoreTypes.Application}] Set Footer Exist`, props<{ payload: boolean }>());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setStorage = createAction(`[${StoreTypes.Application}] Set UI Storage`, props<{ payload: { [key: string]: any } }>());
export const setStorageSetting = createAction(
  `[${StoreTypes.Application}] Set UI Storage Setting`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: { key: UiStorageKey; value: any } }>()
);
