import { User } from '@models/user';
import { ActionCreator, MemoizedSelector } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { EntityStateModel } from '../pagingStoreFactory/pagingStoreTypes';
import { StoreTypes } from '../storeTypes.enum';

export type CustomUserStoreProperties = {
  adRequestsCount: number;
};

export enum UsersStoreCustomActionsTypes {
  loadADRequests = `Load AD Requests`,
  setADRequests = `Set AD Requests`
}

export type UsersStoreCustomActionsType = `[${StoreTypes}] ${UsersStoreCustomActionsTypes}`;

export type UsersCustomActionTypes = {
  loadADRequests: ActionCreator<UsersStoreCustomActionsType, () => TypedAction<UsersStoreCustomActionsType>>;
  setADRequests: ActionCreator<
    UsersStoreCustomActionsType,
    (props: { adRequestsCount: number }) => { adRequestsCount: number } & TypedAction<UsersStoreCustomActionsType>
  >;
};

export type UsersCustomSelectorTypes = {
  selectADRequests: MemoizedSelector<EntityStateModel<User, CustomUserStoreProperties>, number>;
};
