import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuildsState } from '@store/builds/state';
import { StoreTypes } from '../storeTypes.enum';

const selectBuildsState = createFeatureSelector<BuildsState>(StoreTypes.Builds);

export const selectState = createSelector(selectBuildsState, (store) => store);
export const selectBuilds = createSelector(selectBuildsState, (store) => store.builds);
export const selectBuildsInProgress = createSelector(selectBuildsState, (state) => state.buildsInProgress);
export const selectBuildSettings = createSelector(selectBuildsState, (state) => ({
  isAutoUpdateEnabled: state.isAutoUpdateEnabled,
  isSandBoxAvailable: state.isSandBoxAvailable,
  isSettingsButtonVisible: state.isSettingsButtonVisible
}));

export const selectNewVersionsAlerts = createSelector(selectBuildsState, ({ showBackupUpdateDot, showRmmUpdateDot }) => ({
  showBackupUpdateDot: showBackupUpdateDot,
  showRmmUpdateDot: showRmmUpdateDot
}))
