export class WizardStepQueue {
  public get isEmpty(): boolean {
    return !this.queue.length
  }

  private queue: Array<string> = [];

  registerRequiredStep(step: string): void {
    this.queue.push(step);
  }

  unregisterRequiredStep(step: string): void {
    this.queue = this.queue.filter(item => step !== item);
  }

  registerStepDisplay(step: string): void {
    this.queue = this.queue.filter(item => step !== item);
  }

  getQueue(): Array<string> {
    return [...this.queue];
  }

  clear(): void {
    this.queue = [];
  }
}
