import { ComputersStatisticHealthStateModel, ComputersStatisticModel, ComputersStatisticStateModel } from '@models/computersStatistic';
import { createAction, props } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';

export const loadHealthData = createAction(`[${StoreTypes.ComputersStatistic}] Load Health Data`, props<{ silent: boolean }>());
export const loadHealthDataSuccess = createAction(
  `[${StoreTypes.ComputersStatistic}] Load Health Data Success`,
  props<{ data: ComputersStatisticHealthStateModel }>()
);
export const loadHealthDataError = createAction(`[${StoreTypes.ComputersStatistic}] Load Health Data Error`, props<{ error: string }>());

export const loadStateData = createAction(`[${StoreTypes.ComputersStatistic}] Load State Data`, props<{ silent: boolean }>());
export const loadStateDataSuccess = createAction(
  `[${StoreTypes.ComputersStatistic}] Load State Data Success`,
  props<{ data: ComputersStatisticStateModel }>()
);
export const loadStateDataError = createAction(`[${StoreTypes.ComputersStatistic}] Load State Data Error`, props<{ error: string }>());
export const loadStatistic = createAction(`[${StoreTypes.ComputersStatistic}] Load Statistic`, props<{ silent: boolean }>());
export const loadStatisticSuccess = createAction(
  `[${StoreTypes.ComputersStatistic}] Load Statistic Success`,
  props<{ data: ComputersStatisticModel }>()
);
export const loadStatisticError = createAction(`[${StoreTypes.ComputersStatistic}] Load Statistic Error`, props<{ error: string }>());

export const clear = createAction(`[${StoreTypes.ComputersStatistic}] Clear Data`);

export const checkOneComputerExist = createAction(
  `[${StoreTypes.ComputersStatistic}] Check One Computer Exist`,
  props<{ force?: boolean }>()
);
export const checkOneComputerSuccess = createAction(
  `[${StoreTypes.ComputersStatistic}] Check One Computer Exist Success`,
  props<{ data: boolean }>()
);
export const checkOneComputerError = createAction(`[${StoreTypes.ComputersStatistic}] Check One Computer Exist Error`);
