import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UsersFacade } from '@facades/users.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services';
import { ComputersAbstractService } from '@services/computers.service';
import { FormsUtil, InputButton, InputType, MbsValidators, ModalComponent } from 'mbs-ui-kit';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-user-company-modal',
  templateUrl: './add-user-modal.component.html'
})
export class AddUserModalComponent implements OnInit {
  public appendButtons: InputButton[] = [
    {
      type: 'secondary',
      id: 'user-password-visibility',
      icon: 'fa fa-eye',
      loading: false
    }
  ];
  public modalForm: UntypedFormGroup = this.fb.group({
    userName: [null, [Validators.required]],
    password: [
      null,
      {
        validators: [Validators.required],
        asyncValidators: [MbsValidators.passwordStrengthValidator(this.authService)]
      }
    ]
  });
  public inputType: InputType = InputType.Password;
  public requestInProcess = false;
  public initialLoading = true;
  public passwordGenerationInProgress = false;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private fb: FormBuilder,
    private usersFacade: UsersFacade,
    private computersService: ComputersAbstractService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.usersFacade.loadData();
    this.computersService
      .suggestUserName(this.baseModal.data.hid, this.baseModal.data.companyId)
      .pipe(
        untilDestroyed(this),
        catchError(() => of(null))
      )
      .subscribe({
        next: ({ userName }) => {
          userName && this.modalForm.get('userName').patchValue(userName);
          this.initialLoading = false;
        }
      });
  }

  public onChangePasswordTypeButtonClick() {
    this.inputType = this.inputType === InputType.Password ? InputType.Text : InputType.Password;
    this.appendButtons = this.appendButtons.map((button) => {
      if (button.id === 'user-password-visibility') {
        return {
          ...button,
          icon: this.inputType === InputType.Password ? 'fa fa-eye' : 'fa fa-eye-slash'
        };
      }

      return button;
    });
  }

  public setDefaultPassword() {
    this.passwordGenerationInProgress = true;
    this.authService
      .generatePassword()
      .pipe(catchError(() => of('')))
      .subscribe((password) => {
        this.passwordGenerationInProgress = false;
        this.modalForm.get('password').patchValue(password);
      });
  }

  public onCreateUserButtonClick(): void {
    if (!this.modalForm.valid) {
      FormsUtil.triggerValidation(this.modalForm);

      return;
    }

    const { userName, password } = this.modalForm.value;
    this.requestInProcess = true;

    this.usersFacade
      .create({
        name: userName,
        password,
        companyId: this.baseModal.data.companyId,
        enabled: true
      })
      .pipe(
        switchMap((user) =>
          this.usersFacade.getData$().pipe(
            filter((users) => users.some((u) => u.id === user.id)),
            take(1),
            map(() => user)
          )
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (user) => this.baseModal.save(user),
        error: () => (this.requestInProcess = false)
      });
  }
}
