import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { StorageType } from '@models/StorageType.enum';
import { StorageConnection } from '@models/storge-connections';
import { SQLModelForStepsHelper } from '@modules/wizards/helpers/sql-model-helpers/sql-model-for-steps-helper';
import { SQLPlanFromStepsHelper } from '@modules/wizards/helpers/sql-model-helpers/sql-plan-from-steps-helper';
import { SupportMethodsForStepsFromPlan } from '@modules/wizards/helpers/support-methods-for-steps-from-plan';
import { BaseSQLPlanModel } from '@modules/wizards/models/base-sql-plan-model';
import { SQLFormModel } from '@modules/wizards/models/SQL/sql-form-model';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { WizardsServicesHubService } from '@modules/wizards/services/wizards-services-hub.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { ModalService } from 'mbs-ui-kit';
import { WizardBaseClass } from '../wizard-base.class';

@UntilDestroy()
@Component({
  selector: 'mbs-sql-wizard',
  templateUrl: './sql-wizard.component.html',
  providers: [
    {
      provide: WizardBaseClass,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SQLWizard)
    }
  ]
})
export class SQLWizard extends WizardBaseClass {
  public showAdvanced = false;
  public serverInstanceTitle = this.i18nPipe.transform('wizards:sql:sqlServerInstanceStepMenuTitle', { format: 'title' });

  protected get needSubscribeThroughPipe(): boolean {
    return !this.isRDMode || this.isEdit;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    services: WizardsServicesHubService,
    public i18nPipe: I18NextPipe,
    stepService: WizardStepsService
  ) {
    super(services, i18nPipe, stepService);

    this.FormModel = new SQLFormModel(this.isNBF, this.isLinux);

    this.initSubscribes();
  }

  updateStepData(plan, isDefault = false): void {
    this.setLoadedPlanAndRunChainRequestsForBackup(plan);

    if (isDefault) {
      this.FormModel.planNameStep.Name = SupportMethodsForStepsFromPlan.generatePlanName(
        this.mainService.compName,
        this.planType,
        this.mainService.timeZoneOffset
      );
      this.loadedPlan.plan = new BaseSQLPlanModel();

      return void this.updateStorages();
    }

    this.FormModel = SQLModelForStepsHelper.SQLModelFromPlan(plan, this.defaultSettings());
  }

  changeStorageSQLHandler(event: StorageConnection): void {
    if (isNil(event)) {
      delete this.storage;

      if (this.FormModel.advancedOptionsFlags) {
        this.FormModel.advancedOptionsFlags = {
          UseS3AccelerationPossible: false,
          AzureAccessTierType: false,
          StorageClassSettingPossible: false
        };

        this.showAdvanced = false;
      }

      return;
    }

    if (this.FormModel.advancedOptionsFlags) {
      this.storage = event;
      const storageType = this.storage?.StorageType;

      this.FormModel.advancedOptionsFlags = {
        UseS3AccelerationPossible: storageType === StorageType.AmazonS3,
        AzureAccessTierType: storageType === StorageType.Azure,
        StorageClassSettingPossible: storageType === StorageType.AmazonS3
      };

      this.showAdvanced = storageType === StorageType.AmazonS3 || storageType === StorageType.Azure;
    }
  }

  protected loadInfoHandlerFinally() {
    this.cdr.detectChanges();
  }

  handleSave(): void {
    const newPlan = SQLPlanFromStepsHelper.getUpdatedPlan(this.loadedPlan.plan, this.FormModel, this.defaultSettings());

    this.savePlan(newPlan);
  }
}
