import { ChangeDetectorRef, Component, forwardRef, Input, NgZone, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { DeepSyncEnum } from '@models/backup/storages-type';
import { PasswordModalComponent } from '@modules/password-modal/password-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { I18NextPipe } from 'angular-i18next';
import { FormsUtil, GuidEmpty, MbsPopupType, MbsSize, ModalService, ModalSettings, TableHeader, ToastService } from 'mbs-ui-kit';
import { defer, noop, of } from 'rxjs';
import { debounceTime, first, startWith, switchMapTo } from 'rxjs/operators';
import {
  GetBackupContentParams,
  ParamsForDeepSync,
  ParamsForRCRestoreMethods,
  WizardStepsService
} from '../../../services/wizard-steps.service';
import { TreeInModalComponent } from '../../components/tree-in-modal/tree-in-modal.component';
import { StepBase } from '../../StepBase.class';
import { PartitionTableType } from '@modules/wizards/models/base/base-models/plan-partition-table-type-model';
import { DiskCapacity, PartitionsStepValue } from '@modules/wizards/models/partitions-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { RestorePointItem } from '@modules/wizards/models/restore-point-models';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { FileSystemTypeEnum, VolumeUsageStateEnum } from '@modules/wizards/models/what-backup-models';
import { WhatRunAfterPassword } from '@modules/wizards/models/what-run-model';

const PartitionsStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => PartitionsStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-partitions-step',
  templateUrl: './partitions-step.component.html',
  providers: [PartitionsStepValueAccessor]
})
export class PartitionsStepComponent extends StepBase<PartitionsStepValue> implements OnInit {
  @Input() selectedFileFormat: string;
  @Input() bunchId: string;
  @Input() computerName: string;
  @Input() storageId: string;
  @Input() resultPoint: RestorePointItem;
  public fileSystemTypeEnum = FileSystemTypeEnum;
  public bindSelected = 'identityForUiField';
  public disabledRowBy = { key: 'volumeUsageState', value: +VolumeUsageStateEnum.Removed };
  public passwordRecoveryEnabled = false;
  public headers: TableHeader[] = [
    { name: 'Disk', gridColSize: '8fr', overflow: true },
    { name: 'Volume', gridColSize: '8fr' },
    { name: 'Size', gridColSize: '17fr', class: '-end', overflow: true },
    { name: 'Used', gridColSize: '17fr', class: '-end', overflow: true },
    { name: 'Target Size', gridColSize: '17fr', class: '-end', overflow: true },
    { name: 'Label', gridColSize: '11fr', overflow: true },
    { name: 'FS', gridColSize: '7fr', overflow: true },
    { name: 'Sector Size', gridColSize: '15fr', class: '-end' }
  ];
  public partitions: any[] = [];
  public selectedItems = [];

  public VolumeUsageStateEnum = VolumeUsageStateEnum;

  public needValidate = false;

  public targetSizeSum = 0;
  public selectedSizeFormats: DiskCapacity = DiskCapacity.GB;
  public sizeFormats: DiskCapacity[] = [DiskCapacity.MB, DiskCapacity.GB, DiskCapacity.TB];
  public sizeForm = new UntypedFormGroup({
    min: new FormControl(0.02),
    max: new FormControl(65536.0),
    size: new FormControl(0.02, [Validators.required, this.sizeValidator.bind(this)]),
    sizeFormat: new FormControl(DiskCapacity.GB, Validators.required)
  });

  public passwordInvalid = false;
  private isFirstPassError = true;

  private needShowModal = true;
  private savedMinMB = 0.02;
  private savedMaxMB = 0.02;

  private currentSyncData: { progress: number; deepSync: DeepSyncEnum; progressMessage: string };

  private passwordsForBunches: { [key: string]: string } = {};

  private getParamsForTreeModal(disk, pass: string): GetBackupContentParams {
    const basePath = this.resultPoint.path
      ? this.resultPoint.path + this.joinForInput
      : `${this.storageId}${this.joinForInput}${this.computerName}${this.joinForInput}${this.bunchId}${
          this.joinForInput
        }${StepsHelpers.getNormalDate(this.resultPoint.date)}${this.joinForInput}`;
    return {
      agentType: 'backup',
      commandType: 'GetBackupContent',
      params: {
        SessionId: null,
        ConnectionId: null,
        RestoreSourcePrefix: null,
        BunchId: null,
        RestorePointDateUtc: null,
        Password: pass,
        ContentFilter: 'Actual',
        path: `${basePath}Disk{${disk.diskId}}${this.joinForInput}Volume{${disk.identity}}`,
        offset: 0,
        limit: 120,
        order: 'DisplayNameAsc'
      }
    };
  }

  needShowModalSystemRequired(): boolean {
    return (
      this.needShowModal && this.partitions.some((i) => i.requiredBySystem && !this.selectedItems.some((e) => e === i[this.bindSelected]))
    );
  }

  sizeValidator(control: AbstractControl): ValidationErrors | null {
    return this.sizeForm && this.sizeForm.get('max').value < control.value ? { isMax: true } : null;
  }

  @ViewChild('systemRequiredModalContent', { static: true, read: TemplateRef }) systemRequiredModalContent: TemplateRef<any>;

  constructor(
    private cdr: ChangeDetectorRef,
    public mainService: RemoteManagementWizardsService,
    private stepService: WizardStepsService,
    private modalService: ModalService,
    private toastService: ToastService,
    private ngZone: NgZone,
    private authService: AuthService,
    public i18nPipe: I18NextPipe
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((user) => {
      this.passwordRecoveryEnabled = user?.ProviderInfo?.PasswordRecoveryEnabled && user.IsProvider;
    });

    this.initForm();

    this.authService.fetchCurrentUser();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      restoreConfigs: new FormControl(null),
      selectedPartitionsFromRDC: new FormControl([]),
      restorePartitions: new FormControl([]),
      restoreParentPartitions: new FormControl([]),
      convertToMBR: new FormControl(false)
    });

    this.initFormEvents();
  }

  onStepFormChange(value: PartitionsStepValue): void {
    const part = !this.isRDMode ? !!value.restorePartitions.length : true;
    this.value = { ...value, valid: part && !this.needShowModalSystemRequired() };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm && changes.selectedFileFormat) {
      this.selectedFileFormatChangeHandler(changes.selectedFileFormat.currentValue, true);
    }
    if (changes.resultPoint && !changes.resultPoint.currentValue) this.partitions = [];
    if (changes.bunchId && !changes.bunchId.currentValue) this.partitions = [];
    if (changes.computerName && !changes.computerName.currentValue) this.partitions = [];
    if (changes.storageId && (!changes.storageId.currentValue || changes.storageId.currentValue === GuidEmpty)) this.partitions = [];

    if (changes.bunchId?.currentValue && changes.bunchId.currentValue !== changes.bunchId.previousValue) {
      this.mainService.password.next(this.passwordsForBunches[changes.bunchId.currentValue] || '');
    }

    if (
      changes.resultPoint &&
      changes.resultPoint.currentValue &&
      (!this.isNBF || !changes.resultPoint.currentValue.needDeepSync || changes.resultPoint.currentValue.needRunSync) &&
      this.bunchId &&
      this.storageId &&
      this.computerName &&
      this.storageId !== GuidEmpty
    ) {
      this.isFirstPassError = true;
      this.getPartitionsRequest();
    }
  }

  getPartitionsRequest(): void {
    const treeParams: ParamsForRCRestoreMethods = {
      agentType: 'backup',
      commandType: 'GetDiskInfo',
      params: {
        ConnectionId: this.storageId,
        BunchId: this.bunchId,
        RestoreSourcePrefix: this.computerName,
        RestorePointDateUtc: StepsHelpers.getNormalDate(this.resultPoint.date)
      }
    };
    const selectedPartitionsFromRDC = this.stepForm.get('selectedPartitionsFromRDC').value;
    this.loadInfo.emit({ loading: true });
    this.stepService
      .getRemoteCommandData(treeParams, this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          if (data && data.data && data.data.length) {
            this.stepForm.get('restoreParentPartitions').setValue(data.data);
            this.partitions = this.getNewPartitions(data);
            selectedPartitionsFromRDC.forEach((id) => {
              const idx = this.partitions.findIndex((part) => part.identityForUiField.includes(id));
              if (idx !== -1 && !this.selectedItems.some((selected) => selected === this.partitions[idx].identityForUiField)) {
                this.selectedItems.push(this.partitions[idx].identityForUiField);
              }
            });
            if (!this.selectedItems.length && this.partitions.length === 1) {
              this.selectedItems.push(this.partitions[0].identityForUiField);
            }
            this.selectedFileFormatChangeHandler(this.selectedFileFormat);

            this.selectedItems = [...this.selectedItems];
            this.needShowModal = true;
            if (this.resultPoint && this.resultPoint.needDeepSync) this.stepService.deepSyncSuccess$.next(true);
            this.cdr.detectChanges();
          }
          this.loadInfo.emit({ loading: false });
        },
        error: (e) => {
          if (e && e.error && e.error.errorCode && +e.error.errorCode === 2526) {
            this.runDeepSync(this.mainService.password.value || '');
          } else {
            this.partitions = [];
            this.loadInfo.emit({ loading: false });
          }
        }
      });
  }

  getNewPartitions(data): any[] {
    const planData = this.mainService.Plan$.value;
    const newPartitions = [];
    this.targetSizeSum = 0;
    data.data.forEach((d) => {
      d.volumes.forEach((p) => {
        if (p.enabled) {
          p.diskNumber =
            d.driveType === 'Dynamic Disc'
              ? 'Dynamic Disc'
              : !(d.diskNumber === null || d.diskNumber === undefined)
              ? d.diskNumber.toString()
              : '-';
          p.identityForUiField = `${p.identity}__${p.diskId}__${p.windowsVolumeIdentity}`;
          p.partitionTableType = d.partitionTableType;
          p.diskCapacity = d.capacity;
          p.targetSize = +(p.length / 1024 / 1024).toFixed(3);
          // TODO Del it then Kirill add targetSize and sectorSize to disks into GetDiskInfo method
          if (planData && planData.DiskVolumes && planData.DiskVolumes.length) {
            const foundDisk = planData.DiskVolumes.find((d) => p.identity === d.Identity && p.diskId === d.DiskId);
            if (foundDisk) {
              p.sectorSize = foundDisk.SectorSize;
            }
          }
          newPartitions.push(p);
        }
      });
    });
    return newPartitions.length ? newPartitions.sort((a, b) => a.diskNumber - b.diskNumber) : [];
  }

  runDeepSync(pass = ''): void {
    const deepSyncParams = this.getDeepSyncParams(pass, true);
    this.stepService
      .getRemoteCommandData(deepSyncParams, this.mainService.hid)
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: (result) => {
          if (result && result.data) {
            const syncDataFromRes = { deepSync: DeepSyncEnum[result.data as string], progress: 0, progressMessage: '' };
            if (syncDataFromRes.deepSync === DeepSyncEnum.InvalidPassword || syncDataFromRes.deepSync === DeepSyncEnum.NeedPassword) {
              if (syncDataFromRes.deepSync === DeepSyncEnum.InvalidPassword) {
                if (!this.isFirstPassError) {
                  this.isFirstPassError = false;
                  this.passwordInvalid = true;
                } else this.isFirstPassError = false;
              }
              this.showPasswordModal(WhatRunAfterPassword.deepSync);
            } else {
              this.getDeepSyncStatus(pass, syncDataFromRes);
            }
          }
          this.loadInfo.emit({ loading: false });
        },
        error: () => this.loadInfo.emit({ loading: false })
      });
  }

  getDeepSyncStatus(pass = '', syncDate = { progress: 0, deepSync: DeepSyncEnum.InProgress, progressMessage: '' }): void {
    this.loadInfo.emit({ loading: true });
    const toast = { header: 'DeepSync process...', content: `${syncDate.progress}%`, type: MbsPopupType.info, delay: 5000 };
    if (syncDate.deepSync !== DeepSyncEnum.Error) {
      if (syncDate.deepSync === DeepSyncEnum.InvalidPassword || syncDate.deepSync === DeepSyncEnum.NeedPassword) {
        if (syncDate.deepSync === DeepSyncEnum.InvalidPassword) {
          if (!this.isFirstPassError) {
            this.isFirstPassError = false;
            this.passwordInvalid = true;
          } else this.isFirstPassError = false;
        }
        this.loadInfo.emit({ loading: false });
        this.showPasswordModal(WhatRunAfterPassword.deepSync);
        toast.content =
          syncDate.deepSync === DeepSyncEnum.InvalidPassword ? syncDate.progressMessage || 'Invalid Password' : 'Need Password';
        toast.type = MbsPopupType.danger;
        this.currentSyncData = null;
      } else if (syncDate.deepSync !== DeepSyncEnum.InProgressColdStorage && syncDate.deepSync !== DeepSyncEnum.InProgress) {
        this.currentSyncData = null;
        toast.type = MbsPopupType.success;
        this.loadInfo.emit({ loading: false });
        this.stepService.deepSyncSuccess$.next(true);
        this.getPartitionsRequest();
      } else {
        toast.type = MbsPopupType.info;
        const getDeepSyncStatusParams = this.getDeepSyncParams(pass);
        this.stepService
          .getRemoteCommandData(getDeepSyncStatusParams, this.mainService.hid)
          .pipe(first(), debounceTime(800), untilDestroyed(this))
          .subscribe({
            next: (res) => {
              if (res) {
                this.loadInfo.emit({ loading: false });
                if (res.data) {
                  const syncDataFromRes = {
                    deepSync: DeepSyncEnum[res.data.state as string],
                    progressMessage: res.data.progressMessage,
                    progress: res.data.state === 'Done' ? 100 : res.data.progress
                  };
                  this.getDeepSyncStatus(pass, syncDataFromRes);
                  this.currentSyncData = syncDataFromRes;
                } else if (res.ok) {
                  const syncDataFromRes = { deepSync: DeepSyncEnum.InProgress, progress: 0, progressMessage: '' };
                  this.getDeepSyncStatus(pass, syncDataFromRes);
                }
              }
            },
            error: (e) => {
              this.loadInfo.emit({ loading: false });
              const newToast = Object.assign({}, toast);
              newToast.content = (e && e.error && e.error.message) || unidentifiedErrorText;
              newToast.type = MbsPopupType.danger;
              this.toastService.toast(newToast);
              this.currentSyncData = null;
            }
          });
      }
    } else {
      toast.content = 'Error';
      toast.type = MbsPopupType.warning;
    }
    if (
      !this.currentSyncData ||
      this.currentSyncData.deepSync !== syncDate.deepSync ||
      this.currentSyncData.progress !== syncDate.progress
    ) {
      this.toastService.toast(toast);
    }
    this.currentSyncData = syncDate;
  }

  private getDeepSyncParams(pass = '', isRun = false): ParamsForDeepSync {
    const newParams: ParamsForDeepSync = {
      agentType: 'backup',
      commandType: isRun ? 'RunDeepSync' : 'GetDeepSyncStatus',
      params: {
        connectionId: this.storageId,
        restoreSourcePrefix: this.computerName,
        bunchId: this.bunchId,
        restorePointDateUtc: StepsHelpers.getNormalDate(this.resultPoint.date)
      }
    };
    if (pass) newParams.params.Password = pass;
    return newParams;
  }

  selectedFileFormatChangeHandler(selectedFileFormat: string, needResetToMin = false): void {
    if (this.partitions && this.partitions.length) {
      this.calcMaxGBFieldsIntoPartitions(selectedFileFormat, needResetToMin);
      this.partitions = Array.from(this.partitions);
    }
  }

  getDiskLimit(selectedFileFormat: string, partitionTableType: string, isVHD: boolean): number {
    return selectedFileFormat
      ? partitionTableType === 'GuidedPartition'
        ? isVHD
          ? 2048.0
          : 65536.0
        : isVHD
        ? 2048.0
        : 4096.0
      : partitionTableType === 'GuidedPartition'
      ? 65536.0
      : 2048.0;
  }

  getMaxGB(resultSum: number, partitionTableType: string, isVHD: boolean): number {
    return isVHD || partitionTableType !== 'GuidedPartition'
      ? resultSum >= 2048.0
        ? 2048.0
        : resultSum
      : resultSum >= 65536.0
      ? 65536.0
      : resultSum;
  }

  calcMaxGBFieldsIntoPartitions(selectedFileFormat: string, needResetToMin = false): void {
    let targetSizeSumInGB = 0;
    const isVHD =
      selectedFileFormat &&
      (selectedFileFormat === 'Hyper-V Virtual Disk (VHD-format) fixed' ||
        selectedFileFormat === 'Hyper-V Virtual Disk (VHD-format) dynamic');

    this.partitions.forEach((part) => {
      const isSelected = this.selectedItems.includes(part.identityForUiField);
      part.uiDiskLimit = this.getDiskLimit(selectedFileFormat, part.partitionTableType, isVHD);
      if (isSelected) {
        const resultSum = +(part.uiDiskLimit - targetSizeSumInGB).toFixed(3);
        part.maxGB = this.getMaxGB(resultSum, part.partitionTableType, isVHD);
        targetSizeSumInGB += +(part.targetSize / 1024).toFixed(3);
        if (part.maxGB < 0) part.maxGB = 0;
        if (needResetToMin && part.targetSize) {
          part.targetSize = +part.length > 0.02 ? (part.length / 1024 / 1024).toFixed(3) : 0.02;
        } else this.updateDiskTargetSize(part);
      } else {
        part.maxGB = this.getMaxGB(part.uiDiskLimit, part.partitionTableType, isVHD);
      }
    });
  }

  updateDiskTargetSize(disk): void {
    if (disk.targetSize >= 1024) {
      const gb = +(disk.targetSize / 1024).toFixed(3);
      const isGB = gb < 1024;
      if (isGB) {
        if (gb > disk.maxGB) disk.targetSize = +(disk.maxGB * 1024).toFixed(3);
        return;
      }
      const tb = +gb / 1024;
      const maxTb = disk.maxGB / 1024;
      if (!isGB && tb > maxTb) {
        disk.targetSize = +Math.floor(disk.maxGB * 1024).toFixed(3);
      }
    } else if (disk.targetSize > +(disk.maxGB * 1024).toFixed(3)) {
      disk.targetSize = +(disk.maxGB * 1024).toFixed(3);
    }
  }

  getDiskSizeFromMB(mb: number): string {
    return StepsHelpers.getDiskSizeFromMB(+mb, true);
  }

  updateForm(value: PartitionsStepValue): void {
    this.selectedItems = value.restorePartitions.map((i) => i[this.bindSelected]);
    this.cdr.detectChanges();

    this.stepForm.reset(value);
  }

  forceValid(data: any = null): void {
    if (this.needShowModalSystemRequired() && ((this.value.restorePartitions && this.value.restorePartitions.length) || this.isRDMode)) {
      this.showModalSystemRequired();
    }
    this.needValidate = true;
    this.stepForm.markAsTouched();
    FormsUtil.triggerValidation(this.stepForm);
  }

  itemsCheckedHandler(selectedItems: string[]): void {
    this.needShowModal = true;
    if (this.partitions.length) {
      this.ngZone.run(() => {
        this.selectedItems = selectedItems;
        const newPartitions = [];
        this.partitions.forEach((part: any) => {
          if (selectedItems.some((x) => x === part[this.bindSelected])) {
            newPartitions.push(Object.assign({}, part));
          }
        });
        this.stepForm.get('restorePartitions').setValue(newPartitions);
        this.needValidate = false;
        this.switchConvertToMBRDisableState(newPartitions);
        this.calcMaxGBFieldsIntoPartitions(this.selectedFileFormat);
      });
    }
  }

  switchConvertToMBRDisableState(partitions): void {
    if (partitions.some((part) => part.partitionTableType === PartitionTableType[PartitionTableType.MBRPartition])) {
      this.stepForm.get('convertToMBR').disable();
    } else {
      this.stepForm.get('convertToMBR').enable();
    }
  }

  getConfigsAndResultFolders(disk, needCalcFolders = false): { configs: string[]; resultFolders: string[] } {
    const restoreParentPartitions = this.stepForm.value.restoreParentPartitions;
    const foundDisk =
      restoreParentPartitions && restoreParentPartitions.length ? restoreParentPartitions.find((p) => p.diskId === disk.diskId) : null;
    const configs =
      foundDisk && this.stepForm.value.restoreConfigs && this.stepForm.value.restoreConfigs[`${disk.diskId}__${disk.identity}`];
    const resultFolders: string[] = [];
    if (needCalcFolders && configs && configs.length) {
      configs.forEach((rule) => resultFolders.push(rule.Folder));
    }
    return { configs, resultFolders };
  }

  getUsedSpaceStringClass(disk): string {
    const configAndFolders = this.getConfigsAndResultFolders(disk);
    return configAndFolders.configs && configAndFolders.configs.length ? 'font-weight-bold' : '';
  }

  getDataForTreeModal(disk, pass: string): any {
    const configAndFolders = this.getConfigsAndResultFolders(disk, true);
    return {
      title: this.i18nPipe.transform('wizards:partitions_tree_in_modal_title', { format: 'title' }),
      hid: this.mainService.hid,
      resultFolders: configAndFolders.resultFolders,
      isEncrypted: this.resultPoint.isEncrypted,
      encryptionPasswordHint: this.resultPoint.encryptionPasswordHint,
      passwordRecoveryEnabled: this.passwordRecoveryEnabled,
      resultPoint: this.resultPoint,
      backupVersionUpdated: this.mainService.backupVersionUpdated,
      params: this.getParamsForTreeModal(disk, pass),
      dataForPath: this.getValueForOperationsWithPath()
    };
  }

  showModalSystemRequired(): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:what_backup_title', { format: 'title' }) },
      footer: {
        okButton: { text: this.i18nPipe.transform('buttons:continue') },
        cancelButton: { text: this.i18nPipe.transform('buttons:cancel') }
      }
    };
    this.modalService
      .open(modalSettings, this.systemRequiredModalContent)
      .then((confirm) => {
        if (confirm) {
          this.needShowModal = false;
          this.stepForm.updateValueAndValidity();
          this.nextStep.emit();
        }
      })
      .catch(noop);
  }

  usedSpaceClickHandler(disk, pass = ''): void {
    this.ngZone.run(() => {
      this.modalService
        .openCustom(TreeInModalComponent, {
          data: this.getDataForTreeModal(disk, pass || this.mainService.password.value || ''),
          size: MbsSize.sm,
          collapsing: true
        })
        .then((result: any) => {
          const restoreConfigs = this.stepForm.get('restoreConfigs').value;
          const config: any[] = [];
          if (result && result.length) {
            result.forEach((path) => config.push({ DeleteFolder: true, Folder: path, Mask: '*', Recursive: true }));
          }
          restoreConfigs[`${disk.diskId}__${disk.identity}`] = config;
          this.stepForm.get('restoreConfigs').reset(restoreConfigs);
        })
        .catch(noop);
    });
  }

  targetSizeClickHandler(disk, targetSizeModal: TemplateRef<any>): void {
    this.setSizeFormFromDisk(disk);
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:partitions_size_modal_title', { format: 'title' }) },
      footer: {
        okButton: {
          text: this.i18nPipe.transform('wizards:partitions_size_confirm_btn', { format: 'title' }),
          disabled$: this.sizeForm.statusChanges.pipe(
            startWith(true),
            switchMapTo(
              defer(() =>
                of(
                  !this.sizeForm.valid ||
                    !+this.sizeForm.get('max').value ||
                    +this.sizeForm.get('size').value > +this.sizeForm.get('max').value
                )
              )
            )
          )
        }
      }
    };
    this.ngZone.run(() => {
      this.modalService
        .open(modalSettings, targetSizeModal)
        .then((confirm) => {
          if (confirm) {
            const sizeValue = this.sizeForm.value;
            if (sizeValue.sizeFormat === DiskCapacity.GB) disk.targetSize = +Math.ceil(sizeValue.size * 1024).toFixed(2);
            else if (sizeValue.sizeFormat === DiskCapacity.TB) disk.targetSize = +Math.ceil(sizeValue.size * 1024 * 1024).toFixed(2);
            else disk.targetSize = +Math.ceil(sizeValue.size).toFixed(2);
            const idx = this.partitions.findIndex((part) => part.diskId === disk.diskId && part.identity === disk.identity);
            if (idx !== -1) this.partitions[idx] = disk;
            this.partitions = Array.from(this.partitions).sort((a, b) => a.diskNumber - b.diskNumber);
            this.itemsCheckedHandler(this.selectedItems);
            this.resetSizeForm();
          }
        })
        .catch(noop);
    });
  }

  public numberIsFocused = false;

  capacityBlurHandler(): void {
    this.numberIsFocused = false;
    this.updateCapacityInput(+this.sizeForm.get('size').value, true);
  }

  capacityFocusHandler(): void {
    this.numberIsFocused = true;
  }

  sizeChangeHandler(size: number): void {
    if (!this.numberIsFocused) this.updateCapacityInput(size);
  }

  updateCapacityInput(capacity: number, fromBlur = false): void {
    if (fromBlur) {
      const min = +(this.sizeForm.get('min').value || 0.02);
      if (capacity < min) {
        this.sizeForm.get('size').setValue(min.toFixed(3));
        return;
      }
      const max = +(this.sizeForm.get('max').value || 65536);
      if (capacity > max) {
        this.sizeForm.get('size').setValue(max.toFixed(3));
        return;
      }
    }
    const capacityDec = capacity ? capacity.toString().split('.') : [];
    if (capacityDec[1] && capacityDec[1].length > 2) {
      this.sizeForm.get('size').setValue(Math.ceil(capacity * 100) / 100);
    }
  }

  setSizeFormFromDisk(disk): void {
    const usedInMB = +disk.length > 0.02 ? (disk.length / 1024 / 1024).toFixed(2) : 0.02;
    this.savedMinMB = Math.ceil(+usedInMB) || 0.02;
    if (disk.maxGB) this.savedMaxMB = +(disk.maxGB * 1024).toFixed(2);
    else this.savedMaxMB = 0;
    if (disk.targetSize > 1024) {
      const gb = Math.ceil(+disk.targetSize / 1024).toFixed(2);
      if (+gb < 1024) {
        this.sizeForm.get('min').setValue(Math.ceil(+usedInMB / 1024).toFixed(2) || 0.02);
        this.sizeForm.get('max').setValue(disk.maxGB || 0);
        this.sizeForm.get('sizeFormat').setValue(DiskCapacity.GB);
        this.sizeForm.get('size').setValue(gb);
      } else {
        const min = Math.ceil(+usedInMB / 1024 / 1024).toFixed(2);
        this.sizeForm.get('min').setValue(+min ? min : 0.02);
        this.sizeForm.get('max').setValue(disk.maxGB ? +(disk.maxGB / 1024).toFixed(2) : 0);
        this.sizeForm.get('sizeFormat').setValue(DiskCapacity.TB);
        this.sizeForm.get('size').setValue(Math.ceil(+gb / 1024).toFixed(2));
      }
    } else {
      this.sizeForm.get('min').setValue(this.savedMinMB);
      this.sizeForm.get('max').setValue(this.savedMaxMB);
      this.sizeForm.get('sizeFormat').setValue(DiskCapacity.MB);
      this.sizeForm.get('size').setValue(Math.ceil(+disk.targetSize));
    }
  }

  sizeFormatChangeHandler(event: DiskCapacity): void {
    let min = '0';
    let max = '0';
    switch (event) {
      case DiskCapacity.MB:
        min = Math.ceil(this.savedMinMB).toFixed(2);
        max = this.savedMaxMB.toFixed(2);
        break;
      case DiskCapacity.GB:
        min = Math.ceil(this.savedMinMB / 1024).toFixed(2);
        max = (this.savedMaxMB / 1024).toFixed(2);
        break;
      case DiskCapacity.TB:
        min = Math.ceil(this.savedMinMB / 1024 / 1024).toFixed(2);
        max = (this.savedMaxMB / 1024 / 1024).toFixed(2);
        break;
    }
    this.sizeForm.get('min').setValue(+min ? min : 0.02);
    this.sizeForm.get('max').setValue(+max);
    this.checkValidateSize(+min, +max);
    this.selectedSizeFormats = event;
  }

  checkValidateSize(min: number, max: number): void {
    const size = this.sizeForm.get('size');
    if (!size.value) {
      if (+size.value < min || +size.value > max) {
        size.reset(min);
      }
    } else size.reset(min);
  }

  resetSizeForm(): void {
    this.sizeForm.reset({ min: 0.02, size: 0.02, sizeFormat: DiskCapacity.GB });
  }

  showPasswordModal(whatRun: WhatRunAfterPassword, disk = null): void {
    const data = {
      password: this.mainService.password.value || '',
      hid: this.mainService.hid,
      passwordInvalid: this.passwordInvalid,
      showHint: false,
      passwordRecoveryEnabled: this.passwordRecoveryEnabled && this.isNBF && !this.isLinux,
      backupVersionUpdated: this.mainService.backupVersionUpdated,
      restorePoint: this.resultPoint
    };
    this.modalService
      .openCustom(PasswordModalComponent, { data, collapsing: true })
      .then((result: { password: FormControl }) => {
        const passControl = result.password;
        this.mainService.password.next(passControl.value);
        this.passwordsForBunches[this.bunchId] = passControl.value;
        if (passControl.valid) {
          if (whatRun === WhatRunAfterPassword.deepSync) {
            this.loadInfo.emit({ loading: true });
            this.runDeepSync(passControl.value);
          } else if (whatRun === WhatRunAfterPassword.statusDeepSync) {
            this.getDeepSyncStatus(passControl.value);
          } else if (whatRun === WhatRunAfterPassword.tree) {
            this.usedSpaceClickHandler(disk, passControl.value);
          }
        }
      })
      .catch(noop);
  }

  getSectorSize(size: string): string {
    if (size) {
      const matchData: RegExpMatchArray = /\D/.exec(size);
      const text: string[] = size.split('').splice(matchData.index);
      return text && text[0] ? size.replace(text[0], ` ${text[0].toUpperCase()}`) : size;
    }
    return size;
  }
}
