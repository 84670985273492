// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MBSEntity {}

export interface MBSEntityDecoratorData {
  className: string;
}

interface Constructor<T> {
  new (...args: any[]): T;
  readonly prototype: T;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MBSEntities {
  const implementations: { [id: string]: Constructor<MBSEntity> } = {};
  // eslint-disable-next-line no-inner-declarations
  export function GetImplementations(): { [id: string]: Constructor<MBSEntity> } {
    return implementations;
  }
  // eslint-disable-next-line no-inner-declarations
  export function register<T extends Constructor<MBSEntity>>() {
    // tslint:disable-next-line:ban-types
    return (target: any): any => {
      implementations[target.name] = target;
    };
  }
}
