import { Pipe, PipeTransform } from '@angular/core';
import { DetailedError } from '@models/backup/detailed-error';

@Pipe({
  name: 'priority'
})
export class PriorityPipe implements PipeTransform {
  transform(value: DetailedError['priority'], mode: 'icon' | 'class' = 'icon'): string {
    if (mode === 'class') return this.getClass(value);
    if (mode === 'icon') return this.getIcon(value);
    console.error('PlanStatusPipe: Unknown mode');
    return '';
  }

  private getClass(value: DetailedError['priority']): string {
    switch (value) {
      case 'Major':
      case 'Critical':
        return 'text-danger';
      case 'Warning':
        return 'text-warning';
      case 'Info':
        return 'text-info';
      case 'Minor':
        return 'text-secondary';
      default:
        return 'text-dark';
    }
  }

  private getIcon(value: DetailedError['priority']): string {
    switch (value) {
      case 'Major':
      case 'Critical':
        return 'ico ico-failed ' + this.getClass(value);
      case 'Warning':
        return 'ico ico-warning ' + this.getClass(value);
      case 'Info':
      case 'Minor':
      default:
        return 'ico ico-info-circle ' + this.getClass(value);
    }
  }
}
