import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import { unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { MaxSymbolInBrief } from '@utils/constants/misc-constants';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, ModalService, ModalSettings, ResponseError, TextBreak, Toast, ToastService } from 'mbs-ui-kit';
import { Button } from 'mbs-ui-kit/toast/toast.model';
import { noop } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  #errorHandlerSettings: {
    isDebug: boolean;
    delay: number;
  };
  #error: HttpErrorResponse;

  set error(e: HttpErrorResponse) {
    this.#error = e;
  }

  get error(): HttpErrorResponse {
    return this.#error;
  }

  get #traceId(): string {
    return this.error?.headers?.get('trace-id');
  }

  get #url(): string {
    return this.error?.url;
  }

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe,
    private clipboard: Clipboard,
    private authService: AuthService,
    private config: ConfigurationService
  ) {
    this.#errorHandlerSettings = config.get('errorHandlerSettings');
  }

  showToastWithDetails(options: { error?: ResponseError; title?: string; modalText?: string; toastText?: string }): void {
    const { error, title, modalText, toastText } = options || {};
    const briefDetail = error?.detail.slice(0, MaxSymbolInBrief);

    this.toastService.toast(
      new Toast({
        header: title || error?.title,
        content: toastText || `${briefDetail}...`,
        type: MbsPopupType.danger,
        icon: true,
        buttons: [
          {
            clickHandler: function (): void {
              this.#openModalWithDetail(modalText || error?.detail, title || error?.title);
            }.bind(this)
          }
        ]
      })
    );
  }

  #openModalWithDetail(content: string, title: string): void {
    const settings: ModalSettings = {
      header: { title },
      footer: {
        okButton: {
          show: false
        },
        cancelButton: {
          text: this.i18nPipe.transform('buttons:close')
        }
      }
    };

    this.modalService.open(settings, content).finally(noop);
  }

  showSubscriptionErrorToast(): void {
    this.toastService.error(this.i18nPipe.transform('licenses:subscribeError', { url: this.authService.subscriptionUrl }));
  }

  showErrorToast(message?: string, title?: string): void {
    if ((message === unidentifiedErrorText || !message) && this.#errorHandlerSettings?.isDebug) {
      this.#showDebugToast();
    } else {
      this.toastService.error(message, title);
    }
  }

  #showDebugToast(): void {
    this.toastService.toast({
      icon: false,
      header: unidentifiedErrorText,
      content: this.#getDebugText(),
      delay: this.#errorHandlerSettings?.delay || 60000,
      showClose: true,
      type: MbsPopupType.danger,
      textBreak: TextBreak.content,
      preventClose: true,
      buttons: this.#getCopyButtons()
    });
  }

  #getDebugText(): string {
    return `
      <b>Url:</b> ${this.#url}
      <br />
      <b>Trace id:</b> ${this.#traceId}
    `;
  }

  #getCopyButtons(): Array<Button> {
    return [
      {
        label: 'Copy URL',
        clickHandler: () => this.clipboard.copy(this.#url)
      },
      {
        label: 'Copy Trace ID',
        clickHandler: () => this.clipboard.copy(this.#traceId)
      }
    ];
  }

  isRemoteCommand = (error: HttpErrorResponse): boolean => !!error?.url?.includes('/remotecommand');
}
