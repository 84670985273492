import { MonthNamesEnum } from '@models/MonthNames.enum';
import { Period } from '@models/Period.enum';

export enum FromTime {
  'modification date' = 0,
  'backup date' = 1
}

export interface GFSStorageType {
  enabled: boolean;
  storePeriod: number;
}

export interface WeeklyStorageType extends GFSStorageType {
  takeBackupFromDayOfWeek?: string;
}

export interface MonthlyStorageType extends GFSStorageType {
  takeBackupFromDayOfMonth?: string;
}

export interface YearlyStorageType extends GFSStorageType {
  takeBackupFromMonth?: MonthNamesEnum;
}

export interface GFSPolicySettings {
  enabled: boolean;
  enabledImmutability?: boolean;
  weekly: WeeklyStorageType;
  monthly: MonthlyStorageType;
  yearly: YearlyStorageType;
}

export interface BaseRetentionPolicy {
  RetentionUseDefaultSettings?: boolean;
  deleteVersionsOlderThanCount: number;
  deleteVersionsOlderThanPeriod: number;
  keepNumberOfVersions?: boolean;
  RetentionNumberOfVersions?: number;
  deleteVersionsOlderThan?: boolean;
  fromTimeSelected?: FromTime;
  alwaysKeepLastVersion?: boolean;
  delayPurgeForEnabled?: boolean;
  delayPurgeFor?: number;
  delayPurgeForSelect?: Period;
}

export interface RetentionPolicyStepValue extends BaseRetentionPolicy {
  deleteIfLocallyDeleted?: boolean;
  deleteAfter?: number;
  doNotShowWarning?: boolean;
  intelligentRetention?: boolean;
  HybridRetentionPolicy?: BaseRetentionPolicy;
  GFSPolicySettings?: GFSPolicySettings;
  valid: boolean;
}

export const getDefaultGFSPolicySettings: () => GFSPolicySettings = () => {
  return {
    enabled: false,
    enabledImmutability: false,
    weekly: { enabled: false, storePeriod: 2 },
    monthly: { enabled: false, storePeriod: 4 },
    yearly: { enabled: false, storePeriod: 2, takeBackupFromMonth: 1 }
  };
};
