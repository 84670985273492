import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { AuthService } from '@services';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundWrapperComponent implements OnInit {
  constructor(private router: Router, private auth: AuthService) {}
  ngOnInit(): void {
    const redirect = this.auth.getOnlineAccessKey() ? RoutingPath.OnlineAccess : RoutingPath.ApNotFound;

    void this.router.navigate([redirect]);
  }
}
