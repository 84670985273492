<mbs-modal [title]="isFFI ? ('wizards:schedule_modal_title_ffi' | i18next: { format: 'title' }) : isIncremental ?
            ('wizards:schedule_modal_title_incremental' | i18next: { format: 'title' }) :
            'wizards:schedule_modal_title_not_incremental' | i18next: { format: 'title' }">
  <ng-container modal-body
                [formGroup]="form">
    <mbs-form-group class="mb-4">
      <div class="row align-items-start">
        <div class="col-4">
          <mbs-select formControlName="recurringPeriod"
                      [label]="'wizards:recurring_period_type_label' | i18next"
                      (change)="recurringPeriodChangeHandler($event)"
                      [appendTo]="'mbs-modal'"
                      [items]="recurringPeriodItems"
                      [clearable]="false"
                      [searchable]="false"
                      [id]="'modal-recurring-period-select'"></mbs-select>
        </div>
        <div *ngIf="form.get('recurringPeriod').value === 'Daily'"
             class="col-8">
          <div formArrayName="weeklyDayOfWeek"
               class="d-flex justify-content-between">
            <mbs-checkbox *ngFor="let day of daysOfWeekEnumArray; let i = index; let len = count"
                          class="mt-0"
                          [class.mr-3]="i !== len - 1"
                          [formControlName]="i.toString()"
                          [label]="day.label.slice(0,3)"
                          [labelPosition]="'top'"
                          [centerCheckbox]="true"></mbs-checkbox>
          </div>
        </div>
        <div *ngIf="!isIncremental && form.get('recurringPeriod').value === 'Monthly'"
             class="col-8">
          <div class="row">
            <div class="col-6">
              <mbs-select formControlName="occurrence"
                          [appendTo]="'mbs-modal'"
                          [bindValue]="'value'"
                          [bindLabel]="'label'"
                          [clearable]="false"
                          [id]="'modal-occurrence-select'"
                          [items]="weekNumberEnumArray"
                          [label]="'wizards:occurrence_label' | i18next"
                          [searchable]="false"></mbs-select>
            </div>
            <div class="col-6">
              <mbs-number *ngIf="form.get('occurrence').value === -1"
                          formControlName="dayOfMonthCount"
                          [label]="'wizards:day_label' | i18next"
                          min="1"
                          max="31"
                          [id]="'modal-day-of-month-count'"></mbs-number>
              <mbs-select *ngIf="form.get('occurrence').value !== -1"
                          formControlName="dayOfWeek"
                          [label]="'wizards:day_label' | i18next"
                          [appendTo]="'mbs-modal'"
                          [bindValue]="'value'"
                          [bindLabel]="'label'"
                          [clearable]="false"
                          [id]="'modal-day-of-week-select'"
                          [items]="daysOfWeekEnumArray"
                          [searchable]="false"></mbs-select>
            </div>
          </div>
        </div>
      </div>
    </mbs-form-group>

    <ng-container *ngIf="form.get('recurringPeriod').value === 'Daily'">
      <ng-container *ngIf="isIncremental">
        <mbs-form-group class="mb-0">
          <div class="row">
            <div class="col-4">
              <mbs-radio formControlName="dailyFreqPeriodOption"
                         value="OccursAt"
                         (change)="occursAtChangeHandler($event)"
                         [label]="'wizards:occurs_at_label' | i18next"
                         [descriptionOutside]="true"
                         [id]="'modal-occurs-at-radio-1'"
                         [name]="'modal-daily-period-option'">
                <ng-template radioDescription>
                  <div class="row">
                    <div class="col-12">
                      <mbs-timepicker formControlName="occursAtTime"
                                      [meridian]="true"
                                      [needChangeInvalid]="true"></mbs-timepicker>
                    </div>
                  </div>
                </ng-template>
              </mbs-radio>
            </div>
            <div class="col-8">
              <mbs-radio formControlName="dailyFreqPeriodOption"
                         value="OccursEvery"
                         (change)="occursAtChangeHandler($event)"
                         [label]="'wizards:occurs_every_label' | i18next"
                         [descriptionOutside]="true"
                         [id]="'modal-occurs-at-radio-2'"
                         [name]="'modal-daily-period-option'">
                <ng-template radioDescription>
                  <mbs-form-group class="mb-2">
                    <div class="form-row">
                      <div class="col-4">
                        <mbs-number formControlName="occursEveryCount"
                                    [max]="form.get('occursEveryPeriod').value === 'hours' ? maxHours : maxMinutes"
                                    min="1"></mbs-number>
                      </div>
                      <div class="col-5">
                        <mbs-select formControlName="occursEveryPeriod"
                                    (change)="occursEveryPeriodChangeHandler($event)"
                                    [items]="hoursOrMinutesItems"
                                    [bindLabel]="'label'"
                                    [bindValue]="'value'"
                                    [searchable]="false"
                                    [clearable]="false"></mbs-select>
                      </div>
                    </div>
                  </mbs-form-group>

                  <mbs-form-group>
                    <div class="form-row">
                      <div class="col-6">
                        <mbs-timepicker formControlName="occursEveryFromTime"
                                        [label]="'wizards:big_from_label' | i18next"
                                        [id]="'modal-occurs-every-from-time'"
                                        [meridian]="true"
                                        [needChangeInvalid]="true"></mbs-timepicker>
                      </div>
                      <div class="col-6">
                        <mbs-timepicker formControlName="occursEveryToTime"
                                        [label]="'wizards:big_to_label' | i18next"
                                        [id]="'modal-occurs-every-to-time'"
                                        [meridian]="true"
                                        [needChangeInvalid]="true"></mbs-timepicker>
                      </div>
                    </div>
                  </mbs-form-group>
                </ng-template>
              </mbs-radio>
            </div>
          </div>
        </mbs-form-group>
      </ng-container>
    </ng-container>

    <mbs-form-group *ngIf="isIncremental && form.get('recurringPeriod').value === 'Monthly'">
      <div class="form-row">
        <div class="col-4">
          <mbs-select formControlName="occurrence"
                      [label]="'wizards:occurrence_label' | i18next"
                      [appendTo]="'mbs-modal'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [clearable]="false"
                      [id]="'modal-occurrence-select'"
                      [items]="weekNumberEnumArray"
                      [searchable]="false"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-number *ngIf="form.get('occurrence').value === -1"
                      formControlName="dayOfMonthCount"
                      [label]="'wizards:day_label' | i18next"
                      min="1"
                      max="31"
                      [id]="'modal-day-of-month-count'"></mbs-number>
          <mbs-select *ngIf="form.get('occurrence').value !== -1"
                      formControlName="dayOfWeek"
                      [label]="'wizards:day_label' | i18next"
                      [appendTo]="'mbs-modal'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [clearable]="false"
                      [id]="'modal-day-of-week-select'"
                      [items]="daysOfWeekEnumArray"
                      [searchable]="false"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-timepicker formControlName="occursAtTime"
                          [label]="'wizards:occurs_at_label' | i18next"
                          [meridian]="true"
                          [needChangeInvalid]="true"></mbs-timepicker>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group *ngIf="validVersionForSchedule && (!this.isLinux || form.get('recurringPeriod').value === 'Monthly')">
      <div class="form-row">
        <div class="col-4">
          <mbs-input-label [label]="'wizards:repeat_every_label' | i18next"></mbs-input-label>
          <div class="form-row align-items-center">
            <div class="col-6">
              <mbs-number formControlName="repeatEveryCount"
                          min="1"
                          [max]="form.get('recurringPeriod').value === 'Daily'
                                  ? 31 : form.get('recurringPeriod').value === 'Weekly'
                                    ? 52 : form.get('recurringPeriod').value === 'Monthly'
                                      ? 12 : 99"
                          [id]="'schedule-modal-repeat-every-count'"></mbs-number>
            </div>
            <span class="col-auto">
              <label [for]="'schedule-modal-repeat-every-count'"
                     class="mbs-form_label mb-0">
                {{getCounterText()}}
              </label>
            </span>
          </div>
        </div>
        <div class="col-4">
          <mbs-datepicker formControlName="startFromDate"
                          [needSetCurrent]="true"
                          [label]="'wizards:start_from_label' | i18next"></mbs-datepicker>
        </div>
      </div>
    </mbs-form-group>

    <div *ngIf="!isIncremental"
         class="mbs-form-text">
      {{ 'wizards:not_incremental_inherited_text' | i18next: { occursAtTime: getFormatTime(form.get('occursAtTime').value) } }}
    </div>
  </ng-container>
  <ng-container modalFooter>
    <button type="button"
            class="btn btn-primary"
            (click)="saveClickHandler()">{{ 'buttons:save' | i18next: { format: 'title' } }}</button>
    <button type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancelClickHandler()">{{ 'buttons:cancel' | i18next: { format: 'title' } }}</button>
  </ng-container>
</mbs-modal>
