import { BaseFormModels } from '@modules/wizards/models/base/base-form-models';
import { CompressionEncryptionStepValue } from '@modules/wizards/models/compression-encription-models';
import { AdvancedOptionsFlags, AdvancedOptionsStepValue } from '@modules/wizards/models/advanced-options-models';
import { RetentionPolicyStepValue } from '@modules/wizards/models/retention-policy-models';
import * as moment from 'moment';
import { ScheduleStepValue } from '@modules/wizards/models/schedule-models';
import { ScheduleAdvancedStepValue, ScheduleFormSettings } from '@modules/wizards/models/schedule-advanced-models';
import { SimpleScheduleStepValue } from '@modules/wizards/models/simple-schedule-models';
import { SQLServerInstanceAuthenticationTypeEnum, SQLServerInstanceStepValue } from '@modules/wizards/models/sql-server-instance-models';
import { DatabaseBackupType, SQLSourceDatabasesStepValue } from '@modules/wizards/models/sql-source-databases-models';

export class SQLFormModel extends BaseFormModels {
  public date = moment(new Date()).toDate();
  public time = moment(this.date).format('HH:mm:ss');

  sqlServerInstanceStep: SQLServerInstanceStepValue = {
    sqlServerInstanceName: '',
    authenticationType: SQLServerInstanceAuthenticationTypeEnum['Windows Authentication'],
    userName: '',
    password: '',
    useSecureConnection: false,
    checkIsSysAdmin: true,
    valid: false
  };

  sqlSourceDatabasesStep: SQLSourceDatabasesStepValue = {
    databaseBackupType: DatabaseBackupType.All,
    selectedDatabases: [],
    copyOnly: false,
    valid: true
  };

  compressionEncryptionStep: CompressionEncryptionStepValue = {
    UseCompression: true,
    UseEncryption: false,
    EncryptionAlgorithm: 3,
    EncryptionKeySize: 256,
    EncryptionPassword: '',
    UseServerSideEncryption: false,
    valid: true
  };

  advancedOptionsStep: AdvancedOptionsStepValue = {
    UseS3Acceleration: false,
    StorageClass: 1,
    azureAccessTier: 0,
    valid: true
  };

  advancedOptionsFlags: AdvancedOptionsFlags = {
    UseS3AccelerationPossible: false,
    AzureAccessTierType: false,
    StorageClassSettingPossible: false
  };

  AvailableMachinePlans: any[] = [];
  retentionPolicyStep: RetentionPolicyStepValue = {
    RetentionUseDefaultSettings: true,
    keepNumberOfVersions: false,
    RetentionNumberOfVersions: 3,
    deleteVersionsOlderThan: false,
    deleteVersionsOlderThanCount: 1,
    deleteVersionsOlderThanPeriod: 2,
    fromTimeSelected: 0,
    deleteIfLocallyDeleted: false,
    deleteAfter: 30,
    HybridRetentionPolicy: {
      RetentionUseDefaultSettings: true,
      keepNumberOfVersions: false,
      RetentionNumberOfVersions: 1,
      deleteVersionsOlderThan: false,
      deleteVersionsOlderThanCount: 1,
      deleteVersionsOlderThanPeriod: 2,
      fromTimeSelected: 0
    },
    valid: true
  };

  scheduleStep: ScheduleStepValue = {
    ScheduleType: 'noschedule',
    specificDateGroup: { date: this.date, time: this.time },
    StopAfterEnabled: false,
    stopAfterHours: 0,
    stopAfterMinutes: 0,
    ForceMissedSchedule: false,
    overdueAfterSuccessEnabled: false,
    overdueAfterSuccessAmount: 7,
    overdueAfterSuccessPeriod: 1,
    syncBeforeRun: false,
    valid: true
  };

  scheduleAdvancedStep: ScheduleAdvancedStepValue = {
    forceFull: new ScheduleFormSettings(),
    blockLevel: new ScheduleFormSettings(null, null),
    scheduleDiff: new ScheduleFormSettings(),
    scheduleTLog: new ScheduleFormSettings(),
    valid: true
  };

  simpleScheduleStep: SimpleScheduleStepValue = {
    startFromDate: new Date(),
    startFromTime: this.time,
    scheduleTemplate: '',
    fullScheduleEnabled: true,
    fullBackupEveryHours: 168,
    fullBackupEveryMinutes: 0,
    blockLevelBackup: false,
    blockLevelBackupEveryHours: 24,
    blockLevelBackupEveryMinutes: 0,
    scheduleDiffEnabled: true,
    differentialBackupEveryHours: 24,
    differentialBackupEveryMinutes: 0,
    scheduleTLogEnabled: false,
    tLogBackupEveryHours: 0,
    tLogBackupEveryMinutes: 0,
    valid: true
  };

  constructor(public isNBF = false, public isLinux = false) {
    super(isNBF, isLinux);
  }
}
