<mbs-modal class="mbs-rd-enable"
           [title]="'computers.module:superAdmin.title' | i18next">
  <ng-container modal-body>
    <mbs-alert [type]="mbsPopupType.danger"
               [icon]="true">
               {{'computers.module:superAdmin.message' | i18next}}
    </mbs-alert>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button (click)="baseModal.close()">{{'buttons:close' | i18next}}</mbs-button>
  </ng-container>
</mbs-modal>
