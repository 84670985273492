import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgentOptions, RequestRestoreFeature } from '@models/AgentOptions';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ComputersAbstractService } from '@services/computers.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgentOptionsService extends EntityCollectionServiceBase<AgentOptions> {
  private myDefault = new BehaviorSubject<AgentOptions>(null);
  /**
   * Return default agent options
   */
  public default$ = this.myDefault.asObservable();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    private computersService: ComputersAbstractService
  ) {
    super('AgentOptions', serviceElementsFactory);
  }

  getDefault(): Observable<AgentOptions> {
    return this.getWithQuery({ type: 'default' }).pipe(
      map((options) => options[0]),
      tap((option) => this.myDefault.next(option))
    );
  }

  requestBackup(data: { restoreType: RequestRestoreFeature; request: string }): Observable<any> {
    return this.http.post('/Admin/Shared/SendRequestForVMRestore', data);
  }

  /*
   * Handle refresh `myDefault` after saving global agent options (MBS-12731)
   * @param agentOptions
   */
  refreshMyDefault(agentOptions: AgentOptions): void {
    this.myDefault.next(agentOptions);
  }

  // deprecated from rmm16
  getGlobalAgentOptions(): Observable<any> {
    return this.http.get('/api/notification-options/rmm');
  }

  getGlobalAgentOptionsDeepInstinct(): Observable<any> {
    return this.http.get('/api/notification-options/deepInst');
  }

  getComputerAgentOptions(hid: string) {
    return this.computersService.getComputerAgentOptions(hid);
  }
}
