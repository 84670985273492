import { AzureAccessTierType } from '@modules/wizards/models/advanced-options-models';

export enum StorageType {
  AmazonS3 = 0,
  Cloudian = 1,
  Constant = 2,

  /**
   *     S3Compatible
   */
  Scality = 3,
  Tiscali = 4,
  GreenQloud = 5,
  Connectria = 6,
  Dunkel = 7,
  HostEurope = 8,
  Seeweb = 9,
  Walrus = 10,
  Aruba = 11,
  DreamObjects = 12,
  Azure = 13,
  Havenco = 14,
  ThinkOn = 15,

  FTP = 16,
  SFTP = 17,

  /**
   * Remove from wizard 2/15/2016
   * @deprecated
   */
  Webzilla = 18,
  /**
   * Google Cloud Storage (service account)
   */
  Google = 19,
  /**
   * Google Drive (service account)
   */
  GoogleDrive = 20,
  /**
   * Google Cloud Storage (google account)
   */
  GoogleCloud = 21,
  /**
   * Google Drive (google account)
   */
  GoogleDriveOAuth = 22,
  AmazonS3Chinese = 23,

  LocalFS = 24,
  OpenStack = 25,
  OracleCloud = 26,
  CenturyLink = 27,
  ScalityOriginal = 28,
  BackblazeB2 = 29,
  Dedup = 30,

  AmazonGlacier = 31,
  RackSpace = 32,
  AmazonCloudDrive = 33,
  AmazonGlacierChinese = 34,
  AzureFile = 35,
  OneDrive = 36,
  SoftLayer = 37,
  VCloudAirEMC = 38,
  VCloudAirGoogle = 39,
  Akaza = 40,
  Auro = 41,
  Caringo = 42,
  Clodo = 43,
  CloudA = 44,
  CloudWatt = 45,
  DDN = 46,
  DinCloud = 47,
  EasyStorage = 48,
  Exoscale = 49,
  HGST = 50,
  Hitachi = 51,
  HPCloud = 52,
  HPHelion = 53,
  IDCFrontier = 54,
  LeoNovusS3 = 55,
  NetApp = 56,
  NiftyCloud = 57,
  Numergy = 58,
  OVH = 59,
  QNAP = 60,
  Selectel = 61,
  SwiftStack = 62,
  SwiftStackS3 = 63,
  Verizon = 64,
  Zetta = 65,
  Zettagrid = 66,
  AzureVM = 67,
  Cisco = 69,
  /**
   * Google Cloud Storage (access & secret keys)
   */
  GoogleASKey = 70,
  Wasabi = 71,
  Minio = 72,
  Alibaba = 73,
  CblWasabi = 1001,
  UbAmazonS3 = 1002
}

export enum CloudStorageProvider {
  Amazon,
  Google,
  Azure,
  Dunkel,
  Walrus,
  Nirvanix,
  Mezeo,
  SkyBack,
  LocalFS,
  Scality,
  Tiscaly,
  HostEurope,
  Seeweb,
  Connectria,
  OpenStack,
  Rackspace,
  Clodo,
  HPCloud,
  Glacier,
  Aruba,
  Cloudian,
  Constant,
  DreamObjects,
  EasyStorage,
  GreenQloud,
  Haylix,
  Unknown,
  FTP,
  SFTP,
  SoftLayer,
  Cloudwatt,
  Havenco,
  Thinkon,
  Hitachi,
  LeoNovus,
  IDCFrontier,
  Mandic,
  Dedup,
  Akaza,
  AmazonCloudDrive,
  Auro,
  AzureFile,
  Caringo,
  CloudA,
  DDN,
  DinCloud,
  Exoscale,
  GlacierChinese,
  GoogleDrive,
  HPHelion,
  NiftyCloud,
  Numergy,
  OneDrive,
  OracleCloud,
  OracleDatabase,
  QNAP,
  S3Chinese,
  S3Compatible,
  Selectel,
  Swiftstack,
  Verizon,
  VMwareVCloudAirEMC,
  VMwareVCloudAirGoogle,
  Zetta,
  AmazonGlacierGovCloud,
  CenturyLink,
  Cisco,
  LeoNovusS3,
  NetApp,
  OneDriveBusiness,
  SwiftStackS3,
  Zettagrid,
  OVH,
  HGST,
  B2,
  GoogleVM,
  Minio,
  Wasabi,
  Alibaba,
  WasabiUB = 79,
  AmazonUB = 80
}

export enum StorageClass {
  STANDARD = 1,
  GLACIER = 3,
  STANDARD_IA = 4,
  ONEZONE_IA = 5,
  INTELLIGENT_TIERING = 6,
  DEEP_ARCHIVE = 7,
  GLACIER_IR = 8
}

/**
 * Supported S3 / S3 compatible/minio / Wasabi / Azure / B2
 * Exclude S3 Glacier / S3 Glacier Deep Archive,  Azure Archive */
export const TypesForFFI = {
  supported: [
    StorageType.AmazonS3,
    StorageType.Scality,
    StorageType.AmazonS3Chinese,
    StorageType.Minio,
    StorageType.Wasabi,
    StorageType.CblWasabi,
    StorageType.Azure,
    StorageType.BackblazeB2,
    StorageType.UbAmazonS3 // fo any UB connection always FFIEnabled
  ],
  unsupported: {
    Azure: [AzureAccessTierType.Archive] as Array<AzureAccessTierType>,
    Amazon: [StorageClass.GLACIER, StorageClass.DEEP_ARCHIVE] as Array<StorageClass>
  },
  special: {
    [StorageType.Scality]: (storage: { S3CompatibleSynthetic?: boolean }) => {
      return !!storage.S3CompatibleSynthetic;
    },
    [StorageType.Minio]: (storage: { S3CompatibleSynthetic?: boolean }) => {
      return !!storage.S3CompatibleSynthetic;
    }
  },
  getUnmentioned: () => (Object.values(StorageType).filter((value) => typeof value !== 'string') as StorageType[]).filter(
    (value) => !TypesForFFI.supported.includes(value)
  ),
  isSupported: (storage: { StorageType?: StorageType; S3CompatibleSynthetic?: boolean }) => {
    const isSpecialStorage = Object.keys(TypesForFFI.special).includes(String(storage?.StorageType));

    return isSpecialStorage ? TypesForFFI.special[storage.StorageType](storage) : TypesForFFI.supported.includes(storage?.StorageType);
  }
};

export const TypesForSynthetic = {
  supported: [
    StorageType.Azure,
    StorageType.BackblazeB2,
    StorageType.AmazonS3,
    StorageType.AmazonS3Chinese,
    StorageType.Wasabi,
    StorageType.UbAmazonS3,
    StorageType.CblWasabi
  ],
  special: {
    [StorageType.Scality]: (storage: { S3CompatibleSynthetic?: boolean }) => {
      return !!storage.S3CompatibleSynthetic;
    },
    [StorageType.Minio]: (storage: { S3CompatibleSynthetic?: boolean }) => {
      return !!storage.S3CompatibleSynthetic;
    }
  },
  getUnmentioned: () =>
    (Object.values(StorageType).filter((value) => typeof value !== 'string') as StorageType[]).filter(
      (value) => !TypesForSynthetic.supported.includes(value)
    ),
  isSupported: (storage: { StorageType?: StorageType; S3CompatibleSynthetic?: boolean }): boolean => {
    const isSpecialStorage = Object.keys(TypesForSynthetic.special).includes(String(storage?.StorageType));

    return isSpecialStorage
      ? TypesForSynthetic.special[storage.StorageType](storage)
      : TypesForSynthetic.supported.includes(storage?.StorageType);
  },
  CBF: {
    supported: [
      StorageType.AmazonS3,
      StorageType.AmazonS3Chinese,
      StorageType.Wasabi,
      StorageType.UbAmazonS3,
      StorageType.CblWasabi
    ],
    special: {
      [StorageType.Scality]: (storage: { S3CompatibleSynthetic?: boolean }) => {
        return !!storage.S3CompatibleSynthetic;
      },
      [StorageType.Minio]: (storage: { S3CompatibleSynthetic?: boolean }) => {
        return !!storage.S3CompatibleSynthetic;
      }
    },
    getUnmentioned: () =>
      (Object.values(StorageType).filter((value) => typeof value !== 'string') as StorageType[]).filter(
        (value) => !TypesForSynthetic.CBF.supported.includes(value)
      ),
    isSupported: (storage: { StorageType?: StorageType; S3CompatibleSynthetic?: boolean }): boolean => {
      const isSpecialStorage = Object.keys(TypesForSynthetic.CBF.special).includes(String(storage?.StorageType));

      return isSpecialStorage
        ? TypesForSynthetic.CBF.special[storage.StorageType](storage)
        : TypesForSynthetic.CBF.supported.includes(storage?.StorageType);
    }
  }
};

const withoutB2 = () => (Object.values(StorageType).filter((value) => typeof value !== 'string') as StorageType[]).filter(
  (value) => value !== StorageType.BackblazeB2
)

/**
 * B2 not supports Intelligent Retention
 */
export const TypesForIntelligentRetention = {
  supported: Object.values(StorageType).filter((value) => value !== StorageType.BackblazeB2),
  unsupported: [
    StorageType.BackblazeB2
  ],
  getUnmentioned: withoutB2,
  isSupported: (storage: { StorageType?: StorageType }) => {
    return !!storage && !TypesForIntelligentRetention.unsupported.includes(storage?.StorageType);
  }
}
