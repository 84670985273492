import { LicenseType } from './LicenseType.enum';

export default class License {
  Type: LicenseType;
  ExpireDate: Date;
}

export class LicenseTypeSummary {
  type: LicenseType;
  displayName: string;
}

export interface ComputerGrantLicenseResultDTO {
  availableLicenses: LicenseOptionDTO[];
  isAllowBuy: boolean;
  isGranted: boolean;
  license: LicenseInfoDTO | null;
}

export interface LicenseInfoDTO {
  dateActivated: string;
  dateCreated: string;
  dateExpired: string;
  id: string;
  licenseNumber: number;
  licenseType: LicenseType;
}
export interface LicenseOptionDTO {
  count: number;
  licenseType: LicenseType;
}

export interface AvailableLicenses {
  trialLicense: {
    licenseId: string;
    days: number;
  };
  paidLicense: {
    licenseId: string;
    poolName: string;
    validUntil: string;
  };
  newLicensePrice: string;
}

export interface AvailablePaidLicense {
  count: number;
  licenseId: string;
  poolName: string;
  validUntil: string;
}

export enum ComputerLicenseStatus {
  TRIAL,
  PAID,
  EXPIRED,
  TRIALEXPIRED
}

export interface ComputerLicense {
  id?: string;
  licenseId?: string;
  licenseUsageId?: string;
  licenseType: {
    id: number;
    displayName: string;
  };
  socketsCount: number;
  activated: string;
  validUntil: string;
  autoRenew: boolean;
  licenseKind: number;
  isExpired: boolean;
  isTrial: boolean;
}

export interface AvailableLicenseType {
  id: number;
  displayName: string;
  isAllowForCompany: boolean;
}
