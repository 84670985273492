<mbs-modal #modal
           [title]="'pass-recovery:enablePasswordRecovery' | i18next"
           [loading]="loading"
           [isCustomModal]="true"
           class="mbs-password-recovery-enable"
           [showFooter]="true">
  <div modal-body>
    <mbs-alert [type]="'info'"
               [icon]="true">
      {{ 'pass-recovery:createdAfterEnablingAlert' | i18next }}
    </mbs-alert>

    <p class="my-1">{{ 'pass-recovery:copyOrDownloadKey' | i18next }}</p>

    <div class="text-break-all font-weight-bold my-3 px-2 py-2 mbs-password-recovery-enable_private-key border"
         (mousedown)="handleCopyOnText()">
      <div [ngClass]="{'visible': isOverlayVisible}"
           class="mbs-password-recovery-enable_overlay bg-white-95 d-flex justify-content-center align-items-center">
        {{ 'pass-recovery:keyCopiedToClipboardText' | i18next }}
      </div>
      {{ privateKey }}
    </div>

    <div class="row">
      <div class="col-auto">
        <mbs-button [type]="'secondary'"
                    [icon]="'fa fa-download'"
                    (click)="handleDownloadPrivateKey()">{{ 'buttons:saveToFile' | i18next }}
        </mbs-button>
      </div>
      <div class="col-auto">
        <mbs-button #copyButton
                    [type]="'secondary'"
                    [icon]="isCopyButtonClicked ? 'text-success fa fa-check-square' : 'fa fa-clipboard'"
                    (click)="handleCopyOnButton()">{{ 'buttons:copyToClipboard' | i18next }}
        </mbs-button>

      </div>
    </div>

    <form class="mt-3">
      <mbs-checkbox [formControl]="confirmControl"
                    [label]="'pass-recovery:keySavedCheckbox' | i18next">
      </mbs-checkbox>
    </form>
  </div>
  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                [disabled]="!confirmControl.value"
                (click)="handleEnable()">{{ 'buttons:enable' | i18next }}
    </mbs-button>
    <mbs-button [type]="'secondary'"
                (click)="handleClose()">{{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
