<mbs-modal [isCustomModal]="true"
           [showFooter]="true"
           title="{{ 'computers.module:modals:deletePlanCaption' | i18next : { format: 'title' } }}"
           #modal>
  <div modal-body>
    <mbs-alert [type]="alertType.warning"
               [icon]="true"
               class="mb-0">
      {{ 'computers.module:modals:deletePlanQuestion' | i18next }}&nbsp;&ldquo;<span class="font-weight-semibold">{{
        plan?.displayName }}</span>&rdquo;?
    </mbs-alert>
  </div>
  <ng-container modalFooter>
    <mbs-button type="danger"
                [loading]="loading"
                (click)="handleResolve()">
      {{ 'app:buttons:delete' | i18next : { format: 'title' } }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">
      {{ 'app:buttons:cancel' | i18next : { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
