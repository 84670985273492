<mbs-modal [title]="'computers.module:connectionLicense:licenseRequired' | i18next">
  <ng-container modal-body>
    <mbs-alert [type]="'info'">
      <ng-container *ngIf="!isWebRTC; else webrtc">
        {{ 'computers.module:connectionLicense:connectOrRMMlicenseIsRequiredDesktop' | i18next }}
      </ng-container>
      <ng-template #webrtc>
        {{ 'computers.module:connectionLicense:connectOrRMMlicenseIsRequiredWebRTC' | i18next }}
      </ng-template>
      <a *ngIf="'HelpMarketing' | can: 'read'"
         href="/AP/Help/remote-management/connect/licensing"
         target="_blank">
        {{ 'computers.module:connectionLicense:learnMore' | i18next }}
      </a>
    </mbs-alert>
    {{ 'computers.module:connectionLicense:onceYouGrantRequiredLicense' | i18next }}
  </ng-container>
  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                (click)="baseModal.save()">
      {{ 'app:buttons:proceed' | i18next }}
    </mbs-button>
    <mbs-button (click)="baseModal.close()">
      {{ 'app:buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
