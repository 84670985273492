<div class="mbs-page">
  <div class="mbs-page_content">
    <div class="mbs-page-fixed_header">
      <ng-container *ngTemplateOutlet="tableNav"></ng-container>
    </div>
    <div class="mbs-online-access-page-straight_content">
      <ng-container [ngTemplateOutlet]="mbsTableGrid"></ng-container>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<ng-template #tableNav>
  <mbs-table-nav [paginationOptions]="paginationOptions"
                 [showRefreshButton]="true"
                 [mbsTableSortGroup]="orderBy"
                 (refresh)="refreshComputers()"
                 (sort)="handleSort($event)"
                 (pageSwitch)="handlePageChange($event)"
                 (pageSizeChange)="handlePageSizeChange($event)">
    <ng-template mbsTableFilter="label">
      <h1 class="mbs-page_title mb-2">
        {{ 'online-access.module:title' | i18next }}
      </h1>
    </ng-template>
  </mbs-table-nav>
</ng-template>

<ng-template #mbsTableGrid>
  <mbs-table-grid (sort)="handleSort($event)"
                  [rotateSequence]="rotateSequence"
                  [changeSortState]="orderBy"
                  [data]="computers$ | async"
                  [headers]="headersRM"
                  [stripedTable]="false"
                  [showNav]="false"
                  [hoverTable]="false"
                  [myTrackBy]="trackByFn"
                  [mbsTableSortGroup]="orderBy"
                  [loading]="computersPageLoading$ | async"
                  [showHeaders]="true"
                  [viewMode]="SharedPersistentStateEnum.table"
                  classesTable="mbs-rm-table mb-0">
    <ng-container *ngFor="let header of sortedHeadersRM">
      <ng-template *ngIf="header.sort"
                   [mbsTableHeader]="header.name"
                   let-header>
        <div class="d-flex h-100 font-weight-semibold align-items-center"
             [ngClass]="{'justify-content-center': header.sort === 'os'}">
          <mbs-button [isCtrl]="true"
                      type="primary"
                      [columnName]="header.name"
                      [sortable]="header.sort"
                      size="xs">
            <span class="font-weight-semibold">
              {{ header.name }}
              <span class="ico"
                    sortArrow></span>
            </span>
          </mbs-button>
        </div>
      </ng-template>
    </ng-container>

    <ng-template mbsTableHeader="Backup"
                 let-computer>
      <div class="d-flex h-100 font-weight-semibold align-items-center">
        {{ 'online-access.module:backupStorage' | i18next }}
      </div>
    </ng-template>

    <ng-template *ngIf="isConnectAvailable"
                 mbsTableHeader="Connect"
                 let-computer>
      <div class="d-flex h-100 font-weight-semibold align-items-center">
        {{ 'online-access.module:connect' | i18next }}
      </div>
    </ng-template>

    <ng-template mbsTableCell
                 let-computer>
      <div class="d-flex h-100 w-100 align-items-center justify-content-center">
        <div class="mbs-table-grid_ico"
             tooltipClass="tooltip-lg">
          <span [ngClass]="[(computer | computerOsIcon), computer?.online ? 'text-primary' : 'text-muted']"></span>
        </div>
      </div>
    </ng-template>

    <ng-template mbsTableCell
                 let-computer>
      <ng-template #DisplayNameTooltip>
        {{ isDisplayNameExist( computer ) ? computer.displayName : computer.name }}
        <span *ngIf="isDisplayNameExist(computer)"
              class="text-gray-dark d-block">
          {{ computer.name }}
        </span>
      </ng-template>
      <div class="d-flex h-100 w-100 align-items-center">
        <div class="w-100">
          <div class="row">
            <div class="col">
              <div class="d-flex h-100 w-100 align-items-center">
                <div class="overflow-hidden">
                  {{ isDisplayNameExist( computer ) ? computer.displayName : computer.name }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isDisplayNameExist(computer)"
               class="row">
            <div class="col">
              <div class="d-flex h-100 w-100 align-items-center">
                <div class="overflow-hidden">
                  [{{ computer.name }}]
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template mbsTableCell
                 let-computer>
      <div class="form-row h-100 align-items-center">
        <div class="col overflow-hidden">
          <ng-container *ngIf="!hasAgent(computer, AgentType.Backup)">
            <span class="text-gray-dark d-block">
              {{ 'computers.module:agentNotInstalled' | i18next }}
            </span>
          </ng-container>
        </div>

        <div class="col-auto">
          <ng-container *ngIf="hasAgent(computer, AgentType.Backup) && currentUser.EnableOnlineAccessBackupData">
            <mbs-button class="mbs-rm-button -square"
                        placement="auto"
                        ngbTooltip="Configure"
                        container="body"
                        (click)="handleOpenBackupPanel(computer)"
                        tooltipClass="tooltip-lg">
              <span class="fa fa-cloud-upload text-primary"></span>
            </mbs-button>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template *ngIf="isConnectAvailable"
                 mbsTableCell
                 let-computer>
      <div class="d-flex h-100 align-items-center justify-content-end position-relative">
        <mbs-connect-button-group [computer]="computer"></mbs-connect-button-group>
      </div>
    </ng-template>
  </mbs-table-grid>
</ng-template>
