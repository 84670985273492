import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LicenseTypeDisplayPipe } from './pipes/LicenseTypeDisplay.pipe';
import { UtilsPipeModule } from './utils.pipe.module';

@NgModule({
  imports: [CommonModule, UtilsPipeModule],
  declarations: [LicenseTypeDisplayPipe],
  exports: [LicenseTypeDisplayPipe, UtilsPipeModule]
})
export class UtilsModule {}
