export interface SmartSearchModelField {
  value: string;
  condition?: string;
}

export class SmartSearchModel {
  [key: string]: SmartSearchModelField[] | boolean; // or hashtag
  words?: SmartSearchModelField[];
}

export function getSmartSearchTagValues(tagName: string, search: SmartSearchModel): string[] {
  return search && Array.isArray(search[tagName]) ? (search[tagName] as []).map(({ value }) => value).filter(Boolean) : [];
}
