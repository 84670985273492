import { Action, on } from '@ngrx/store';
import { cloneDeep, isEqual } from 'lodash';
import * as OnlineAccessPagingActions from './actions';
import { initialOnlineAccessPagingState, OnlineAccessPagingState } from './state';
import { createImmerReducer } from 'ngrx-immer/store';

const reducer = createImmerReducer(
  initialOnlineAccessPagingState,
  on(OnlineAccessPagingActions.loadPage, (state, action) => {
    state.loading = true;
    if (action.isFullRefresh) {
      state.pages = {};
      state.total = initialOnlineAccessPagingState.total;
      state.loaded = initialOnlineAccessPagingState.loaded;
    }
    if (action.perPage && state.perPage !== action.perPage) {
      state.perPage = action.perPage;
      state.currentPageNumber = initialOnlineAccessPagingState.currentPageNumber;
    }
    return state;
  }),
  on(OnlineAccessPagingActions.loadPageSuccess, (state, action) => {
    state.pages[action.page.id] = action.page.page;
    state.total = action.total;
    state.currentPageNumber = action.page.id;
    state.loading = false;
    state.loaded = true;
    return state;
  }),
  on(OnlineAccessPagingActions.loadPageError, (state) => {
    state.loading = false;
    return state;
  }),
  on(OnlineAccessPagingActions.clearPages, (state) => {
    return clearROPagingState(state);
  }),
  on(OnlineAccessPagingActions.setPageLoading, (state, action) => {
    state.loading = action.loading;
    return state;
  }),
  on(OnlineAccessPagingActions.setPerPageSetting, (state, action) => {
    state.perPage = action.perPage;
    state.currentPageNumber = initialOnlineAccessPagingState.currentPageNumber;
    return state;
  }),
  on(OnlineAccessPagingActions.setPageNumberSuccess, (state, action) => {
    state.currentPageNumber = action.pageNumber;
    return state;
  }),
  on(OnlineAccessPagingActions.setSortSettings, (state, action) => {
    if (isEqual(state.sort, action.sort)) return state;
    state.sort = cloneDeep(action.sort);
    return state;
  })
);

export function onlineAccessPagingReducer(state: OnlineAccessPagingState | undefined, action: Action) {
  return reducer(state, action);
}

function clearROPagingState(state: OnlineAccessPagingState): OnlineAccessPagingState {
  state.pages = {};
  state.total = initialOnlineAccessPagingState.total;
  state.currentPageNumber = initialOnlineAccessPagingState.currentPageNumber;
  state.loading = initialOnlineAccessPagingState.loading;
  state.loaded = initialOnlineAccessPagingState.loaded;
  return state;
}
