<mbs-modal [title]="'computers.module:modals.header' | i18next">
  <ng-container modal-body>
    <mbs-alert *ngIf="agent === agentType.RA"
               [type]="alertType.warning"
               [icon]="true"
               class="mb-4">
      {{ 'computers.module:modals:reAuthComputerForConnectCentrilizedConfiguration' | i18next }}
      <a *ngIf="('HelpMarketing' | can: 'read')"
         href="https://kb.msp360.com/kb10140"
         target="_blank">
        {{ 'buttons:learnMore' | i18next }}
      </a>
    </mbs-alert>
    {{ confirmationQuestion }}
  </ng-container>
  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="baseModal.save()">{{'buttons:install' | i18next}}</mbs-button>
    <mbs-button (click)="baseModal.close()">{{'buttons:close' | i18next}}</mbs-button>
  </ng-container>
</mbs-modal>
