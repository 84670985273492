import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import Computer, { AgentType } from '@models/Computer';
import { ConnectionType, PrepareConnectionMode } from '@models/ConnectionType';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConnectService } from '@services/connect.service';
import { ModalComponent } from 'mbs-ui-kit';
import { delay, filter, map, Observable, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-prepare-modal-component',
  templateUrl: './remote-connection-prepare-modal.component.html',
  styleUrls: ['./remote-connection-prepare-modal.component.scss']
})
export class RemoteConnectionPrepareModalComponent implements AfterViewInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public downloadLink: string;
  public computer$: Observable<Computer>;
  public connectionType: ConnectionType;
  public connectionMode$: Observable<PrepareConnectionMode>;
  public readonly Type = ConnectionType;
  public readonly PrepareConnectionMode = PrepareConnectionMode;

  readonly autoCloseTimeout = 60000;

  public get isAgentSupportWebRTC$(): Observable<boolean> {
    return this.computer$.pipe(map((computer) => this.connectService.isAgentSupportWebRTC(computer, AgentType.RA)));
  }

  constructor(private cdr: ChangeDetectorRef, public connectService: ConnectService) {}

  ngAfterViewInit(): void {
    if (this.baseModal.data) {
      this.downloadLink = this.baseModal.data.downloadLink;
      this.computer$ = this.baseModal.data.computer$;
      this.connectionType = this.baseModal.data.connectionType;
      this.connectionMode$ = this.baseModal.data.connectionMode$;
      this.cdr.detectChanges();

      this.connectionMode$
        .pipe(
          filter((mode) => mode === PrepareConnectionMode.Ready),
          take(1),
          delay(this.autoCloseTimeout)
        )
        .pipe(untilDestroyed(this))
        .subscribe(() => this.baseModal.close());
    }
  }

  connect(type: ConnectionType, retry = false) {
    this.computer$.pipe(filter(Boolean), take(1)).subscribe((computer) => {
      this.connectService.startConnect(computer, type, retry);
      type === ConnectionType.WebRTC && retry && this.baseModal.close();
    });
  }
}
