import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackupPlanInfo } from '@models/backup/backup-plan-info-model';
import Computer from '@models/Computer';
import { PlanTypes } from '@models/PlanTypes.enum';
import HostInfo from '@models/rmm/HostInfo';
import { AuthService } from '@services/auth.service';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BackupPlanLegacy } from './backup-plans-legacy-model';
import { SysInfo, SysInfoData } from './computer-system-info-legacy-model';

export abstract class ComputersAspxAbstractService {
  abstract getInfo(computer: Computer): Observable<any>;
  abstract getComputerPlansLegacy(computer: Computer): Observable<BackupPlanInfo[]>;
  abstract getComputerHostInfoLegacy(hid: string): Observable<HostInfo>;
}

@Injectable()
export class ComputersAspxService implements ComputersAspxAbstractService {
  constructor(private httpCopy: HttpClient, private auth: AuthService) {}

  public getInfo(computer: Computer): Observable<any> {
    const formData = new FormData();
    formData.append('computerHID', computer.hid);
    return this.httpCopy.post<any>('/Admin/Computers/GetRMComputerInfo', formData);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public getComputerPlansLegacy(computer: Computer): Observable<BackupPlanInfo[]> {
    if (computer.userAccount) {
      return this.getPlans(computer);
    }

    this.getInfo(computer).pipe(
      this.handleUnauthorize,
      switchMap((computerInfo) => {
        const { ProfileName, Email, IsOffline } = computerInfo;

        const payload: Partial<Computer> = {
          hid: computer.hid,
          id: computer.id,
          profileName: ProfileName,
          IsOffline: IsOffline,
          userAccount: {
            accountName: Email
          }
        };

        return this.getPlans(payload);
      })
    );
  }

  getComputerHostInfoLegacy(hid: string): Observable<HostInfo> {
    return this.httpCopy.get<HostInfo | SysInfoData>(`/Admin/Computers/GetSystemInfo?hid=${hid}&profile=`).pipe(
      this.handleUnauthorize,
      map((data) => {
        const sysInfo: SysInfo = (data as SysInfoData).sysInfo;
        if (!sysInfo) {
          if ((data as HostInfo).message || (data as HostInfo).success) {
            return data as HostInfo;
          } else {
            return null;
          }
        }

        return {
          computerName: sysInfo.LicenseInfo.ComputerName,
          coresCount: sysInfo.HardwareInfo.ProcessorArch,
          cpuName: sysInfo.ProcessorsInfo[0].Model,
          curCultureEngName: sysInfo.SystemInfo.CurCultureEngName,
          curCultureName: sysInfo.SystemInfo.CurCultureName,
          curUICultureEngName: sysInfo.SystemInfo.CurUICultureEngName,
          curUICultureName: sysInfo.SystemInfo.CurUICultureName,
          domainName: sysInfo.RunTimeInfo.DomainName,
          frameworkVersion: sysInfo.SystemInfo.FramVersion,
          gpuName: null,
          lastBoot: sysInfo.HardwareInfo.LastBoot,
          logicalProcessorsCount: sysInfo.HardwareInfo.ThreadCount,
          network: { ip4Address: null },
          osName: sysInfo.SystemInfo.OperatingSystem,
          osType: 'WINNT',
          physicalProcessorsCount: sysInfo.HardwareInfo.SocketsCount,
          systemUptime: sysInfo.SystemInfo.SystemUptime,
          temperatureInfos: [] as any,
          timeZone: sysInfo.SystemInfo.TimeZone,
          totalPhysicalMemory: String(sysInfo.HardwareInfo.PhysicalMemorySize),
          totalVirtualMemory: String(sysInfo.HardwareInfo.VirtualMemorySize),
          userDomainName: sysInfo.RunTimeInfo.UserDomainName,
          userName: sysInfo.RunTimeInfo.UserName,
          utcOffsetInMinutes: moment(sysInfo.SystemInfo.TimeDiff, 'hh:mm:ss').minutes(),
          version: sysInfo.SystemInfo.Version,
          vm: null,
          x64: sysInfo.SystemInfo.OperatingSystem.includes('64-bit'),
          success: true
        } as HostInfo;
      })
    );
  }

  private handleUnauthorize = <T>(source: Observable<T>): Observable<T> => {
    return source.pipe(
      switchMap((value: T) => {
        if (value['isNotAuth'] === true) {
          this.auth.logoutUser();
          return throwError(new Error('Unauthorize'));
        }

        return of(value);
      })
    );
  };

  // normalize LastStart property
  private getPlans(computer: Partial<Computer>) {
    const formData = new FormData();
    formData.append('computerHID', computer.hid);
    formData.append('profile', '');
    formData.append('email', computer.userAccount.accountName);
    formData.append('isOffline', String(computer.IsOffline));

    return this.httpCopy.post<BackupPlanLegacy[]>('/Admin/Computers/GetPlansShortInfo', formData).pipe(
      this.handleUnauthorize,
      map((plans) => {
        return plans.map((p) => {
          const newModel = {
            lastRun: {
              stats: p.LastRunStats,
              status: p.LastRunStatus,
              statusString: p.LastRunStatusString as any
            }
          } as BackupPlanInfo;
          const lastStartDate = moment(p.LastStart);
          if (lastStartDate.isValid()) {
            newModel.lastRun.startDate = lastStartDate.toJSON();
          } else {
            newModel.lastRun.startDate = new Date(p.LastStart).toJSON(); // moment(p.LastStart, 'MM/DD/YYYY h:mm:ss A').toJSON();
          }
          if (p.LastRunMessage) {
            try {
              newModel.lastRun.message = JSON.parse(p.LastRunMessage);
            } catch (error) {
              newModel.lastRun.messageStr = p.LastRunMessage;
            }
          }

          newModel.canStart = p.AvailableStartButton;
          newModel.canStop = p.AvailableStopButton;
          newModel.planFilesProcessingType = p.PlanFilesProcessingType;
          newModel.canForceFull = p.ForceFullSupported;
          newModel.planId = p.PlanId;
          newModel.planName = p.PlanName;
          newModel.planType = PlanTypes[p.PlanType] || PlanTypes.NA;
          newModel.planTypeDisplay = p.DisplayType;
          newModel.email = p.Email;
          newModel.userId = p.UserId;
          newModel.hid = p.Hid;
          newModel.profile = p.Profile;
          newModel.computerName = p.ComputerName;

          if (p.IsOverdue) {
            newModel.overdue = {
              duration: p.OverdueDuration,
              time: p.OverdueTime
            };
          }

          // if (p.OverdueSettings && typeof p.OverdueSettings == 'object') {
          //   newModel.overdueSettings = {
          //     enabled: p.OverdueSettings.Enabled,
          //     amount: p.OverdueSettings.Amount,
          //     period: p.OverdueSettings.Period
          //   };
          // }

          newModel.nextStart = p.NextStart;
          newModel.isRunning = p.IsRunning;

          return newModel;
        });
      })
    );
  }
}
