<form [formGroup]="stepForm"
      class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:what_backup_step_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-alert *ngIf="isOffline"
             class="isOfflineAlert"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:select_virtual_machines_offline_alert' | i18next}}
  </mbs-alert>

  <mbs-alert *ngIf="isHyperV && hyperVNotInstalled"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{ 'wizards:hyperVNotInstalled' | i18next }}
  </mbs-alert>

  <mbs-form-group>
    <mbs-radio formControlName="type"
               name="VirtualMachinesSelectedType"
               (change)="typeChangeHandler(virtualMachinesSelectedType.All)"
               [id]="elementsSelector.id.allRadio"
               [label]="'wizards:select_all_virtual_machines' | i18next"
               [value]="virtualMachinesSelectedType.All"></mbs-radio>
    <mbs-radio formControlName="type"
               name="VirtualMachinesSelectedType"
               (change)="typeChangeHandler(virtualMachinesSelectedType.Running)"
               [id]="elementsSelector.id.runningRadio"
               [label]="'wizards:select_running_virtual_machines' | i18next"
               [value]="virtualMachinesSelectedType.Running"></mbs-radio>
    <mbs-radio *ngIf="!isRDMode && (!isOffline || isEdit)"
               formControlName="type"
               name="VirtualMachinesSelectedType"
               (change)="typeChangeHandler(virtualMachinesSelectedType.Selected)"
               [id]="elementsSelector.id.selectedRadio"
               [label]="'wizards:select_selected_virtual_machines' | i18next"
               [value]="virtualMachinesSelectedType.Selected"></mbs-radio>
  </mbs-form-group>

  <mbs-form-group *ngIf="!isRDMode && (!isOffline || isEdit)">
    <mbs-list-select (changeSelected)="selectedMachinesChangesHandler($event)"
                     [data]="loadedMachines"
                     [headers]="headers"
                     [invalid]="stepForm.touched && (value?.type === virtualMachinesSelectedType.Selected && !value?.machines?.length)"
                     [keepState]="true"
                     [maxHeight]="'310px'"
                     [selectedData]="selectedMachines"
                     [showSearch]="false"
                     [showTopControls]="value?.type === virtualMachinesSelectedType.Selected"
                     [showCheckboxes]="value?.type === virtualMachinesSelectedType.Selected"
                     [isNeedSelectRow]="value?.type === virtualMachinesSelectedType.Selected"
                     [topControlsLabel]="true"
                     bindSelected="virtualMachineId"
                     [attr.data-test-element]="elementsSelector.name.vmListSelect"
                     id="virtual-machines-list">
      <ng-template prependTopControlsLabel>
        <strong>
          {{
            isOffline ?
              ('wizards:selected_virtual_machines_offline' | i18next: { selected: selectedMachines.length }) :
              'wizards:selected_virtual_machines' | i18next: { selected: selectedMachines.length, all: loadedMachines.length }
          }}
        </strong>
      </ng-template>
      <ng-template let-machine
                   mbsTableCell>
        <div class="form-row">
          <div class="col overflow-hidden">
            <div class="d-flex align-items-center">
              <img [src]="virtualMachinesIcon"
                   class="mr-2"
                   [alt]="machine.name">
              <mbs-text-ellipsis [tooltip]="machine.name">
                {{ machine.name }}
              </mbs-text-ellipsis>
            </div>
          </div>
          <div *ngIf="machine.invalidName"
               class="col-auto d-flex align-items-center">
            <span container="body"
                  class="ico ico-InfoCircle tooltip-btn text-danger ml-2"
                  placement="top"
                  [ngbTooltip]="'wizards:selected_virtual_machines_identical_names' | i18next"></span>
          </div>
          <div *ngIf="!machine.invalidName && machine.invalidDisks"
               class="col-auto d-flex align-items-center">
            <span container="body"
                  class="ico ico-InfoCircle tooltip-btn text-danger ml-2"
                  placement="top"
                  [ngbTooltip]="'wizards:selected_virtual_machines_void_disks' | i18next"></span>
          </div>
        </div>
      </ng-template>
    </mbs-list-select>
  </mbs-form-group>

  <mbs-alert *ngIf="stepForm?.errors?.duplicatesExist"
             class="duplicatesExistAlert"
             [type]="mbsPopupType.danger"
             [icon]="true">
    {{'wizards:selected_virtual_machines_identical_names_alert' | i18next}}
  </mbs-alert>

  <mbs-alert *ngIf="mainService?.machinesDisksStatuses$?.value?.loadingDisks"
             class="loadingDisksAlert"
             [class.mb-0]="hasAvailableSockets !== false"
             [type]="mbsPopupType.info"
             [icon]="true">
    <span class="d-flex align-items-center">
      <span class="loader mr-2"></span>
      {{ 'wizards:selected_virtual_machines_checking_alert' | i18next }}
    </span>
  </mbs-alert>

  <mbs-alert *ngIf="availableSocketsErrorDetail"
             class="hasAvailableSocketsAlert"
             [attr.data-test-element]="elementsSelector.name.availableSocketsErrorDetail"
             [type]="mbsPopupType.danger"
             [icon]="true">
    {{availableSocketsErrorDetail}}
  </mbs-alert>

</form>
