<mbs-modal class="mbs-rd-enable"
           [title]="'computers.module:enableConnect.titleProvider' | i18next : { format: 'title'}">
  <ng-container modal-body>
    <p>
      {{'computers.module:enableConnect.firstProviderText' | i18next}}
      <br/>
      <a href="https://help.mspbackups.com/remote-management/connect/enable-ra"
         target="_blank">
        {{'computers.module:enableConnect.providerTextLink' | i18next}}
      </a>
    </p>
    <mbs-checkbox [label]="'computers.module:enableConnect.agreeEnable' | i18next"
                  [formControl]="checkbox"></mbs-checkbox>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button (click)="handleSubmit()"
                [loading]="loading$ | async"
                [disabled]="!checkbox.value"
                [type]="'primary'">
      {{'buttons:enable' | i18next}}
    </mbs-button>
    <mbs-button (click)="baseModal.close()">{{'buttons:cancel' | i18next}}</mbs-button>
  </ng-container>
</mbs-modal>
