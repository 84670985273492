import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { QuickRestoreSchemaModalComponent } from '@components/quick-restore-schema-modal/quick-restore-schema-modal.component';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { TempTokenData } from '@models/auth-models';
import { ShortPlanInfo } from '@models/backup/plan-info-model';
import { MyTreeElements } from '@models/backup/storages-type';
import { Build } from '@models/build';
import Computer, { AgentType, OsType } from '@models/Computer';
import { StorageAccountCamelCase } from '@models/StorageAccount';
import { StorageType } from '@models/StorageType.enum';
import { StorageConnection } from '@models/storge-connections';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { BuildService } from '@services/build-service/build.service';
import { getRestorePointObjectPath, ParamsForRestorePointObjectPath } from '@utils/backup-storages.utils';
import { isWindows } from '@utils/is-windows';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, MbsSize, ModalService, ModalSettings, TabsetItemDirective, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, combineLatest, noop, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-backup-storage-tab',
  templateUrl: './backup-storage.component.html'
})
export class BackupStorageComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isOnlineAccess: boolean;
  @Input() fullscreen: boolean;

  @Output() fullScreenChange = new EventEmitter<boolean>();

  public readonly isWindows = isWindows;
  public readonly MbsSize = MbsSize;
  public readonly mbsPopupType = MbsPopupType;
  public readonly newRMSupportsMinimalWindowsVersion = 7223;
  public readonly newRMSupportsMinimalUnixVersion = 4100;
  public readonly elementsSelector = {
    name: {
      notStoragesBlock: 'not-storages-block',
      existStoragesBlock: 'exist-storages-block',
      quickRestoreButton: 'quick-restore-button',
      storagesBlock: 'storages-block',
      storageItem: 'storage-item',
      storageItemAccountName: 'storage-item-account-name',
      storageItemBucket: 'storage-item-bucket',
      storageItemRegion: 'storage-item-region',
      browseStoragesBlock: 'browse-storages-block'
    }
  };
  private readonly quickRestoreModalSettings: ModalSettings = {
    footer: {
      okButton: { show: false },
      cancelButton: { text: this.i18nPipe.transform('buttons:close', { format: 'title' }) }
    }
  };

  // Public block start
  public isOnline: boolean;
  public computerData: Computer;
  public backupVersionUpdated: string;
  public quickRestoreLoadingId: string;
  public storages$ = new BehaviorSubject<StorageConnection[]>([]);
  public planDestination$ = new BehaviorSubject<ShortPlanInfo>(null); // public for use from parent sidepanel component
  public downloadLinkData: Build = null;
  public currentPlanDestination: ShortPlanInfo = null;
  public notLocalStorages = [];
  public selectedBrowseStorage: StorageConnection = null;
  public isBackupAgentSupportsNewRM: boolean;
  // Public block end

  // Private block start
  private storageHashTable: {
    [key: string]: { prefix?: string; generationId?: string; isLoading?: boolean; isIbb?: boolean; data?: MyTreeElements[] };
  } = {};
  private selectedStorageForQR: StorageConnection[] = [];
  private ownerId: string;
  private joinForInput = '\\';
  // Private block end

  get needShowQuickRestoreAppButton(): boolean {
    return !!this.storages$?.value?.some((storage: StorageConnection) => storage.StorageType !== StorageType.LocalFS);
  }

  constructor(
    public ability: AbilityService,
    private authService: AuthService,
    private buildService: BuildService,
    private facade: ComputerBackupFacade,
    private modalService: ModalService,
    private stepService: WizardStepsService,
    public tabItem: TabsetItemDirective,
    public i18nPipe: I18NextPipe,
    private router: Router,
    private toastService: ToastService
  ) {
    this.authService.currentUser.subscribe((user) => {
      if (user) this.ownerId = user.ProviderInfo?.Id || '';
    });

    this.authService.fetchCurrentUser();
  }

  ngOnInit() {
    this.buildService
      .getAll()
      .pipe(first((data) => !!data))
      .subscribe((data) => {
        if (data?.builds?.length) this.downloadLinkData = data.builds.find((build) => build.buildType === 'QuickRestore');
      });

    this.facade.destinationsLoading$
      .pipe(
        untilDestroyed(this),
        tap((value) => queueMicrotask(() => (this.tabItem.loading = value)))
      )
      .subscribe();

    this.facade.computer$
      .pipe(
        tap((computer: Computer) => this.setCurrentComputer(computer)),
        untilDestroyed(this)
      )
      .subscribe(() => this.handleReload());

    this.loadStorages().subscribe((storages) => {
      const newStorages = storages
        .filter((storage) => storage.StorageType !== StorageType.AzureVM)
        .sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));

      this.storages$.next(newStorages);
      this.notLocalStorages = newStorages.filter((storage) => storage.StorageType !== StorageType.LocalFS);
    });

    combineLatest([this.storages$, this.planDestination$.asObservable()])
      .pipe(
        filter(
          ([storages, planDestination]) =>
            storages?.length && !!planDestination && storages.some((storage) => storage.ID === planDestination.destinationId)
        ),
        tap(([storages, planDestination]) => {
          this.currentPlanDestination = planDestination;
          this.browseStorageData(storages.find((storage) => storage.ID === planDestination.destinationId));
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fullscreen && !changes.fullscreen.currentValue && changes.fullscreen.previousValue) {
      this.selectedBrowseStorage = null;
    }
  }

  ngOnDestroy(): void {
    this.storageHashTable = {};
  }

  handleReload(force = false): void {
    this.isBackupAgentSupportsNewRM = this.getIsBackupAgentSupportsNewRM();
    this.facade.loadDestinations({ force });
  }

  openBackupHistory(): void {
    this.router.navigate(['/AP/BackupHistory'], { queryParams: { filter: 'hid:' + this.computerData?.hid } });
  }

  handleRefresh(): void {
    this.handleReload(true);
  }

  browseStorageData(storage: StorageConnection): void {
    if (!this.fullscreen) this.fullScreenChange.emit(true);

    this.selectedBrowseStorage = storage;
  }

  changedSelectedStorageChangeHandler(storage: StorageConnection): void {
    this.selectedBrowseStorage = storage;
  }

  backToStoragesClickHandler(): void {
    this.fullScreenChange.emit(false);
    this.selectedBrowseStorage = null;
  }

  quickRestoreAppClickHandler(storage: StorageConnection): void {
    this.selectedStorageForQR = storage ? [storage] : [];
    this.downloadStorageClickHandler(storage);
  }

  // Private methods section
  private loadStorages(): Observable<StorageConnection[]> {
    return this.facade.destinations$.pipe(
      map((storageAccounts: StorageAccountCamelCase[]) => {
        return storageAccounts.reduce((acc, account: StorageAccountCamelCase) => {
          const newBuckets = account.buckets.map((bucket) => {
            return {
              DisplayName: `${bucket.destinationName} (${bucket.bucketName})`,
              DestinationName: bucket.destinationName,
              ID: bucket.id,
              Region: bucket.regionDisplayName || 'N/A',
              StorageType: account.storageType,
              Bucket: bucket.bucketName,
              StorageAccountName: account.name,
              StorageAccountType: account.storageType
            };
          });
          acc.push(...newBuckets);

          return acc;
        }, []);
      }),
      untilDestroyed(this)
    );
  }

  private downloadStorageClickHandler(storage: StorageConnection): void {
    if (!isWindows() || (!this.ability.can('read', 'RemoteManagement') && this.authService.isMBSMode) || this.storageHashTable[storage.ID]?.prefix) {
      return void this.openModalWithQuickRestore();
    }

    this.quickRestoreLoadingId = storage.ID;

    const params = { agentType: 'backup', commandType: 'GetRestoreSourcePrefix', params: { connectionId: storage.ID } };

    this.stepService
      .getRemoteCommandData(params, this.computerData.hid)
      .pipe(
        switchMap((result) => {
          if (!result?.data?.length) return of(true);

          if (!this.storageHashTable[storage.ID]) this.storageHashTable[storage.ID] = {};

          const current = result.data.find((d) => d.isCurrent);
          this.storageHashTable[storage.ID].prefix = current ? current.name : this.computerData.name;

          this.quickRestoreLoadingId = '';
          return of(true);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => this.openModalWithQuickRestore(),
        error: (e) => {
          this.selectedStorageForQR = [];
          const title = e?.error?.title || e?.error?.message || e?.message || this.i18nPipe.transform('toast.error.title', { format: 'title' });
          const message = e?.error?.message || e?.message;

          this.quickRestoreLoadingId = '';
          if (message) return void this.toastService.error(message, title);

          this.toastService.error(title);
        }
      });
  }

  private openModalWithQuickRestore(): void {
    const modal = this.modalService.openRef(QuickRestoreSchemaModalComponent, this.quickRestoreModalSettings);
    modal.componentInstance.needPreparingTextShow = true;
    modal.componentInstance.downloadLink = this.downloadLinkData?.public?.downloadLink;

    modal.result.then(noop).catch(() => (this.selectedStorageForQR = []));

    if (isWindows() && (this.ability.can('read', 'RemoteManagement') || !this.authService.isMBSMode)) {
      queueMicrotask(() => this.createQuickRestoreSchema(modal));
    }
  }

  private createQuickRestoreSchema(modal: NgbModalRef): void {
    modal.componentInstance.preparing = true;

    this.authService
      .getTempToken(this.computerData.userAccount.id, this.computerData.hid, 'OneTimeQuickRestore')
      .pipe(
        catchError(() => of(null)),
        untilDestroyed(this)
      )
      .pipe(
        map((tokenData: TempTokenData) => ({
          UserToken: tokenData?.token,
          RestorePointObjectPath: getRestorePointObjectPath(this.getParamsForRestorePointObjectPath(), false),
          ownerId: this.ownerId
        })),
        untilDestroyed(this)
      )
      .subscribe({
        next: (jsonIbj: { [key: string]: string }) => {
          modal.componentInstance.preparing = false;
          modal.componentInstance.createSchemaParams = jsonIbj;
        },
        error: () => (modal.componentInstance.preparing = false)
      });
  }

  private getParamsForRestorePointObjectPath(): ParamsForRestorePointObjectPath {
    return { computer: this.computerData, storage: this.selectedStorageForQR[0], item: null, storageHash: this.storageHashTable };
  }

  private setCurrentComputer(computer: Computer): void {
    this.joinForInput = computer?.os.toLowerCase() !== 'windows' ? '/' : '\\';
    this.computerData = computer;
    this.isOnline = computer?.online && Computer.getAgent(computer, AgentType.Backup)?.online;
    this.backupVersionUpdated = Computer.getAgent(computer, AgentType.Backup)?.version?.replaceAll('.', '');
  }

  private getIsBackupAgentSupportsNewRM(): boolean {
    if (!this.backupVersionUpdated) return false;

    const version = Number(this.backupVersionUpdated.substring(0, 4));

    return (
      version >= (this.computerData?.os === OsType.windows ? this.newRMSupportsMinimalWindowsVersion : this.newRMSupportsMinimalUnixVersion)
    );
  }
}
