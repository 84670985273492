import { StorageClass } from '@models/StorageType.enum';
import { AzureAccessTierType } from './advanced-options-models';

export enum BackupSnapshotType {
  SnapshotTree,
  CurrentState
}

export enum VMQuiesceType {
  TryApplicationConsistent,
  MustApplicationConsistent,
  Disabled
}

/**
 * @type AdvancedSettingsVirtualStepValue - type for "Advanced settings" step for virtual machines wizards
 */
export type AdvancedSettingsVirtualStepValue = {
  quiesceType?: VMQuiesceType;
  useChangeBlockTracking: boolean;
  useS3Acceleration?: boolean;
  storageClass?: StorageClass;
  azureAccessTier?: AzureAccessTierType;
  syntheticFull?: boolean;
  valid: boolean;
};
