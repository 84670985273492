import { Inject, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NAValue } from '../constants/license';
import { Money } from '../models/license.model';
import { BALANCE_TOKEN } from '../tokens/balance.token';
import { Balance } from './../models/license.model';

@Pipe({ name: 'price' })
export class PricePipe implements PipeTransform {
  constructor(@Inject(BALANCE_TOKEN) public balance: BehaviorSubject<Balance>) {}

  transform(value: Money | string, period?: string): string {
    const currency = this.balance?.value?.subscriptionCurrencySign || '$';

    if (typeof value === 'string' && value.includes(NAValue)) {
      return NAValue;
    }

    if (typeof value === 'object') {
      const price = `${value?.currencyName || currency}${value?.integerValue}.${value?.remainderValue}`;
      return value ? (period ? `${price} / ${period}` : price) : '—';
    } else {
      return period ? `${currency}${value} / ${period}` : `${currency}${value}`;
    }
  }
}
