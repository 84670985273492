import { Injectable, Injector } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { ComputersFacade } from '@facades/computers.facade';
import Administrator from '@models/Administrator';
import { SidepanelRouteType } from '@models/backup/sidepanel-route-type';
import { BackupSidePanelTab } from '@models/backup/sidepanel-tab';
import Computer from '@models/Computer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { SidepanelService } from 'mbs-ui-kit';
import { interval, Subscription } from 'rxjs';
import { first, startWith, switchMap, take, tap } from 'rxjs/operators';
import { OnlineAccessSidepanelBackupComponent } from '../components/online-access-sidepanel-backup/online-access-sidepanel-backup.component';

const delayOnResetDataAfterPanelClosed = 500;

@UntilDestroy()
@Injectable()
export class OnlineAccessSidepanelHandler {
  currentUser: Administrator;
  private activeTabSubscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private sidepanelService: SidepanelService,
    private authService: AuthService,
    private computersFacade: ComputersFacade,
    private backupFacade: ComputerBackupFacade,
    private injector: Injector
  ) {
    this.authService.currentUser.subscribe((user) => (this.currentUser = user));
  }

  handleOpenBackupPanel(computer: Computer): boolean {
    const defaultTab = BackupSidePanelTab.backupStorage;

    this.backupFacade.setComputer(computer.hid);
    this.computersFacade.setSelected(computer?.hid);
    const backupPanel: OnlineAccessSidepanelBackupComponent = this.sidepanelService.add(
      OnlineAccessSidepanelBackupComponent,
      null,
      this.injector
    );
    this.activeTabSubscriptions.forEach((subscription) => subscription?.unsubscribe());
    this.activeTabSubscriptions.push(
      backupPanel.open.pipe(first(), untilDestroyed(this)).subscribe(() => this.updateSlug(computer, SidepanelRouteType.Backup))
    );
    this.activeTabSubscriptions.push(
      backupPanel.close
        .pipe(
          tap(() => this.updateSlug(null)),
          switchMap(() => interval(delayOnResetDataAfterPanelClosed)),
          first(),
          untilDestroyed(this)
        )
        .subscribe(() => this.backupFacade.setComputer(null))
    );
    this.activeTabSubscriptions.push(
      backupPanel.tabChanged.pipe(untilDestroyed(this)).subscribe(() => this.updateSlug(computer, SidepanelRouteType.Backup))
    );
    this.sidepanelService.openByType(OnlineAccessSidepanelBackupComponent).subscribe(() => {
      backupPanel.data$.next(computer);
      const selectTab = () => {
        backupPanel.tabset.select(defaultTab);
      };

      if (backupPanel.isOnline) {
        // if computer is offline tab list will not change
        // so - try to find requested tab
        this.activeTabSubscriptions.push(
          backupPanel.tabset.items.changes.pipe(startWith(backupPanel.tabset.items), take(1), untilDestroyed(this)).subscribe(selectTab)
        );
      } else {
        // if computer is online - tab list will change after all checks in sidepanel
        // so we wait for changes and then try to find requested tab in updated list
        selectTab();
      }
    });
    return true;
  }

  updateSlug(computer: Computer, sidepanelType: SidepanelRouteType = null): void {
    const basePath = `${this.router.url.split('OnlineAccess')[0]}OnlineAccess`;
    const opts: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: {
        sidepanel: sidepanelType
      }
    };
    const commands: unknown[] = [basePath];
    computer && computer.hid && commands.push(computer.hid);
    this.router.navigate(commands, opts);
  }
}
