export enum AzureAccessTierType {
  Hot,
  Cool,
  Archive
}

export enum BlockSizeEnum {
  '128 KB' = 131072,
  '256 KB' = 262144,
  '512 KB' = 524288,
  '1 MB' = 1048576
}

export interface AdvancedOptionsFlags {
  ExcludePossible?: boolean;
  UseS3AccelerationPossible?: boolean;
  AzureAccessTierType?: boolean;
  StorageClassSettingPossible?: boolean;
  SyntheticFullSettingPossible?: boolean;
  IgnoreBadSectorsPossible?: boolean;
  DisableVSSPossible?: boolean;
  UseVSSPossible?: boolean;
  PrefetchBlockCountPossible?: boolean;
  BlockSizeSettingPossible?: boolean;
  BackupNTFSPermissionsPossible?: boolean;
  UseFastNtfsScanPossible?: boolean;
  AlwaysUseVSSPossible?: boolean;
}

export interface DataForAdvancedStep {
  advancedOptionsStep: AdvancedOptionsStepValue;
  advancedOptionsFlags: AdvancedOptionsFlags;
}

/**
 * @interface AdvancedOptionsStepValue
 * @property {Boolean} KeepEFSFilesAsIs - flag, which says: decrypt or not decrypt EFS-encrypted files
 */
export interface AdvancedOptionsStepValue {
  UseS3Acceleration?: boolean;
  StorageClass?: number;
  azureAccessTier?: number;
  ExcludeEnabled?: boolean;
  excludeRules?: string[];
  SyntheticFull?: boolean;
  SaveDeletedDataInCloud?: boolean;
  IgnoreBadSectors?: boolean;
  DisableVSS?: boolean;
  useSystemVSSProvider?: boolean;
  PrefetchBlockCount?: number;
  BlockSize?: number;
  BackupNTFSPermissions?: boolean;
  UseFastNtfsScan?: boolean;
  AlwaysUseVSS?: boolean;
  valid: boolean;
  KeepEFSFilesAsIs?: boolean;
}
