import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { I18NextService } from 'angular-i18next';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { DisableAutoRenewalModalComponent } from '../disable-auto-renewal-modal/disable-auto-renewal-modal.component';
import { EnableAutoRenewalModalComponent } from '../enable-auto-renewal-modal/enable-auto-renewal-modal.component';
@Component({
  selector: 'mbs-auto-renewal-switcher',
  templateUrl: './auto-renewal-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoRenewalSwitcherComponent implements OnChanges {
  @Input() license;
  @Input() isAutoRenew;
  @Input() request: Observable<unknown>;
  @Output() autoRenewChange = new EventEmitter();

  public form: FormGroup;

  constructor(private cdr: ChangeDetectorRef, private modalService: ModalService, private i18nextService: I18NextService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.form = new FormGroup({
      isAutoRenew: new FormControl(Boolean(this.isAutoRenew || this.license?.isAutoRenew))
    });
  }

  public changeAutoRenewal(event) {
    event.target.checked ? this.openEnableAutoRenewModal() : this.openDisableAutoRenewModal();
  }

  public openEnableAutoRenewModal() {
    this.modalService
      .openCustom(EnableAutoRenewalModalComponent, {
        data: {
          title: this.i18nextService.t('licenses:enableAutoRenewalModal:title', {
            format: 'title'
          }),
          autoRenewalData: { search: null, ids: [this.license?.id], enable: false, itemsCount: 1 },
          request: this.request
        },
        size: MbsSize.sm
      })
      .then(() => {
        this.autoRenewChange.emit(true);
      })
      .catch(() => {
        this.form.controls.isAutoRenew.patchValue(false);
        this.cdr.detectChanges();
      });
  }

  public openDisableAutoRenewModal() {
    this.modalService
      .openCustom(DisableAutoRenewalModalComponent, {
        data: {
          title: this.i18nextService.t('licenses:disableAutoRenewalModal:title', {
            format: 'title'
          }),
          id: this.license?.id,
          request: this.request
        },
        size: MbsSize.sm
      })
      .then(() => {
        this.autoRenewChange.emit(false);
      })
      .catch(() => {
        this.form.controls.isAutoRenew.patchValue(true);
        this.cdr.detectChanges();
      });
  }
}
