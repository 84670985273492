import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { RecoveryCodeModalComponent } from '@components/tfa/components/recovery-code-modal/recovery-code-modal.component';
import { SignalRService } from '@modules/signal-r/signal-r.service';
import { AuthService } from '@services/auth.service';
import { I18NextService } from 'angular-i18next';
import { MbsPopupType, MbsSize, ModalComponent, ModalService } from 'mbs-ui-kit';
import { catchError, finalize, noop, of, take } from 'rxjs';
import { getPeriods } from '../../constants/time';
import { PostMessageTwoFactorAuthenticationType, TwoFactorAuthActionType } from '../../models/tfa';
import { getInvalidDiffTime, getSecondsFromDate } from '../../utils/date';
import { TFAService } from './../../services/tfa.service';

@Component({
  selector: 'mbs-two-factor-approve-modal',
  templateUrl: './two-factor-approve-modal.component.html',
  styles: [
    '.approve-modal { overflow: visible !important; } .approve-modal .modal-body { overflow: visible !important;} .period-select { min-width: 90px; width: max-content }'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFactorApproveModalComponent implements OnInit, OnDestroy {
  public readonly MbsSize = MbsSize;
  public isCodeAuth = false;
  public isDeclined = false;
  public isLoading = false;
  public isRecoveryCode = false;
  public recoveryCodeMask = {
    mask: '****-****-****',
    blocks: {
      '*': {
        mask: '*',
        prepare: function (char) {
          return char === ' ' ? '' : char;
        }
      }
    }
  };
  public googleCode = new FormControl(null, Validators.required);
  public recoveryCode = new FormControl(null, Validators.required);
  public isTimeExpired = false;
  public TFAParams = null;
  public readonly alertType = MbsPopupType;

  public size = MbsSize.sm;
  private tfaErrorObj = { tfa: { message: 'Invalid code' } };

  public periods = getPeriods(this.i18);
  public selectedPeriod = 60;
  public isNotAskTFA = false;

  public isInvalidLocalTime = false;
  public getInvalidDiffTime = getInvalidDiffTime;

  public titles = {
    [TwoFactorAuthActionType.EmergencyLogin]: 'Login to MSP Console',
    [TwoFactorAuthActionType.Login]: 'Login to MSP Console',
    [TwoFactorAuthActionType.TurnOnTwoFactorAuth]: 'Enable Two-Factor Authentication',
    [TwoFactorAuthActionType.TurnOffTwoFactorAuth]: 'Disable Two-Factor Authentication',
    [TwoFactorAuthActionType.ResetAlternativeCodes]: 'Reset 2FA Recovery Codes',
    [TwoFactorAuthActionType.RmmAction]: 'RMM Action',
    [TwoFactorAuthActionType.DropUser]: 'Delete User',
    [TwoFactorAuthActionType.DropCloudAccount]: 'Delete a Storage Account',
    [TwoFactorAuthActionType.ChangeEmail]: 'Change Provider Email Address',
    [TwoFactorAuthActionType.ChangePassword]: 'Change Password',
    [TwoFactorAuthActionType.DeleteCapacityReportUser]: 'Delete Data From Capacity Report',
    [TwoFactorAuthActionType.DeleteCapacityReportComputer]: 'Delete Data From Capacity Report',
    [TwoFactorAuthActionType.PasswordRecovery]: 'Password Recovery',
    [TwoFactorAuthActionType.PasswordRecoveryOnWizard]: 'Password Recovery'
  };

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private TFAService: TFAService,
    private i18: I18NextService,
    private modal: ModalService,
    private signalR: SignalRService
  ) {}

  ngOnInit(): void {
    this.init(this.baseModal.data);

    this.checkInvalidDate();

    this.signalR.authorizedZoneConnection.on('OnTwoFactorAuthProcessCompleted', (res) => {
      if (res.resolution === 2) {
        this.isDeclined = true;
        this.cdr.detectChanges();
        return;
      }

      if (this.isNotAskTFA) {
        this.doNotDisturb();
      }

      this.isRecoveryCode = false;

      this.baseModal.save(true);
    });
  }

  init(data) {
    this.TFAParams = {
      ...data,
      isResetCodes: data.actionType === TwoFactorAuthActionType.ResetAlternativeCodes,
      isRecoveryCodeUsageAvailable:
        data.actionType === TwoFactorAuthActionType.TurnOffTwoFactorAuth ||
        data.actionType === TwoFactorAuthActionType.ResetAlternativeCodes,
      isGoogleAuth: data.type === PostMessageTwoFactorAuthenticationType.Google,
      isTFADisableAction: data.actionType === TwoFactorAuthActionType.TurnOffTwoFactorAuth
    };
  }

  doNotDisturb() {
    this.TFAService.doNotDisturb(this.TFAParams.authId, this.selectedPeriod).pipe(take(1)).subscribe();
  }

  tryAgain() {
    this.isLoading = true;

    this.TFAService.tryAgain(this.TFAParams.authId)
      .pipe(
        finalize(() => {
          console.log(`${new Date()}: TFAService.tryAgain`);
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe((result) => {
        this.TFAParams.timeExpired = result.timeExpired;
        this.TFAParams.authId = result.authId;
        this.checkInvalidDate();
      });
  }
  private checkInvalidDate() {
    this.isTimeExpired = false;
    this.isInvalidLocalTime = getSecondsFromDate(this.TFAParams.timeExpired) < 0;

    this.cdr.detectChanges();
  }

  public toggleAuthType() {
    this.isCodeAuth = !this.isCodeAuth;
    this.isInvalidLocalTime = false;
  }

  private openRecoveryCodeModal(codes: Array<string>) {
    this.modal
      .openCustom(RecoveryCodeModalComponent, {
        data: {
          title: 'Reset 2FA Recovery Codes',
          codes
        },
        size: MbsSize.sm
      })
      .finally(noop);
  }

  public confirmRecoveryCode() {
    this.isLoading = true;
    this.isRecoveryCode = true;
    this.recoveryCode.setErrors(null);

    this.TFAService.confirmAlternativeCode(this.recoveryCode.value, this.TFAParams.authId)
      .pipe(catchError((error) => of(error)))
      .subscribe((result) => this.handleRecoveryCodeConfirmation(result));
  }

  private handleRecoveryCodeConfirmation(result) {
    this.isLoading = false;

    if (!result.isValid) {
      this.handleInvalidRecoveryCode();
      this.cdr.detectChanges();
      return;
    }

    if (!this.TFAParams.isResetCodes && result.codes.length) {
      this.openRecoveryCodeModal(result.codes);
    }
  }

  public confirmGoogle() {
    this.googleCode.setErrors(null);
    this.isLoading = true;
    this.TFAService.confirmGoogle(this.googleCode.value, this.TFAParams.authId)
      .pipe(
        take(1),
        catchError(() => {
          this.handleInvalidTFACode();
          return of({ isValid: false });
        })
      )
      .subscribe((result) => {
        if (!result.isValid) {
          this.handleInvalidTFACode();
        }
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  handleInvalidTFACode() {
    this.googleCode.setValue(null);
    this.googleCode.setErrors(this.tfaErrorObj);
  }

  handleInvalidRecoveryCode() {
    this.recoveryCode.setValue(null);
    this.recoveryCode.setErrors(this.tfaErrorObj);
  }

  ngOnDestroy(): void {
    this.signalR.authorizedZoneConnection.off('OnTwoFactorAuthProcessCompleted');
  }
}
