export enum TwoFactorMessages {
  InvokeDropAccountButtonClick = 'InvokeDropAccountButtonClick',
  InvokeSavePasswordButtonClick = 'InvokeSavePasswordButtonClick',
  InvokeDropUserButtonClick = 'InvokeDropUserButtonClick',
  TurnTwoFactorAuthCheckBox = 'TurnTwoFactorAuthCheckBox',
  InvokeResetAlternativeCodesClick = 'InvokeResetAlternativeCodesClick',
  TwoFactorAuthCheckBoxOnClickInvoke = 'TwoFactorAuthCheckBoxOnClickInvoke'
}

export enum TwoFactorAuthActionType {
  None = 0,
  EmergencyLogin = 1,
  Login = 2,
  DropCloudAccount = 3,
  DropUser = 4,
  RmmAction = 5,
  ResetAlternativeCodes = 6,
  TurnOffTwoFactorAuth = 7,
  TurnOnTwoFactorAuth = 8,
  ChangePassword = 9,
  PasswordRecovery = 10,
  ChangeEmail = 11,
  DeleteCapacityReportUser = 12,
  DeleteCapacityReportComputer = 13,
  DeleteDomain = 15,
  UpdateDomain = 16,
  PasswordRecoveryOnWizard = 18
}

export enum TwoFactorAuthenticationType {
  None = 0,
  Google = 'TimeBasedOneTimeCode',
  MbsApp = 'MbsApp',
  Alternative = 'Alternative'
}

export enum PostMessageTwoFactorAuthenticationType {
  None,
  Google,
  MbsApp,
  Alternative
}
