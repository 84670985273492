<div class="mbs-progress-bar progress"
     [ngStyle]="heightStyle">
  <div *ngIf="isWaiting"
       class="mbs-progress-bar-waiting">
  </div>
  <ng-container *ngIf="!isWaiting">
    <div *ngIf="!multiLineSettings; else MultiLine"
         class="progress-bar"
         [ngClass]="progressBarColorClass"
         [style.width.%]="progressWidth">
    </div>
  </ng-container>
</div>

<ng-template #MultiLine>
  <div class="mbs-progress-bar-container">
    <div *ngFor="let item of multiLineSettings"
         class="progress-bar"
         [ngClass]="multilineClasses(item.type, item.customClass)"
         [style.width.%]="item.lineWidth">
      <div *ngIf="item.icon && item.lineWidth"
           class="mbs-progress-bar-icon-box mbs-progress-bar-icon-container"
           [ngClass]="getBgColorClass(item.type)"
           [style.left.%]="item.iconLeft">
        <i class="mbs-progress-bar-icon"
           [ngClass]="item.icon"></i>
      </div>
    </div>
  </div>
</ng-template>
