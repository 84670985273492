import { Pipe, PipeTransform } from '@angular/core';
import { VirtualMachineType } from '@models/VirtualMachineType';

export function virtualMachineDisplay(vmType: VirtualMachineType): string {
  switch (vmType) {
    case VirtualMachineType.PhysicalMachine:
      return 'Physical Machine';
    case VirtualMachineType.VirtualMachine:
      return 'Virtual Machine';
    case VirtualMachineType.PhysicalMachineHyperVhost:
      return 'Physical Machine with HyperV';
    case VirtualMachineType.VMWare:
      return 'VMWare Virtual Machine';
    case VirtualMachineType.HyperV:
      return 'HyperV Virtual Machine';
    case VirtualMachineType.Kvm:
      return 'KVM Virtual Machine';
    case VirtualMachineType.KvmQemu:
      return 'KVM QEMU Virtual Machine';
    case VirtualMachineType.Xen:
      return 'XEN Virtual Machine';
    case VirtualMachineType.Lxc:
      return 'LXC Virtual Machine';
    case VirtualMachineType.Uml:
      return 'Uml Virtual Machine';
    case VirtualMachineType.Virtuozzo:
      return 'Virtuozzo Virtual Machine';
    case VirtualMachineType.AmazonV1:
      return 'Amazon EC2 Machine';
    case VirtualMachineType.AmazonV2:
      return 'Amazon EC2 Machine';
    case VirtualMachineType.Azure:
      return 'Azure Machine';
    case VirtualMachineType.Google:
      return 'Google Machine';
    case VirtualMachineType.OpenStack:
      return 'OpenStack Machine';
    case VirtualMachineType.Unknown:
    default:
      return 'Unknown';
  }
}

@Pipe({ name: 'vmDisplay' })
export class VirtualMachineTypeDisplayPipe implements PipeTransform {
  transform(value: VirtualMachineType): any {
    return virtualMachineDisplay(value);
  }
}
