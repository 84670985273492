export interface Rule {
  subject: string | Array<string>;
  actions: Action | Array<Action>;
  fields?: string[];
  conditions?: Object;
  inverted?: boolean;
}

export enum EventTypes {
  Update = 'update'
}

export type Events = Map<string, Array<Function>>;

export type Rules = Map<string, Partial<Rule>>;

export type Action = 'create' | 'update' | 'delete' | 'read' | 'save';
