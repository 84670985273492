import { ChangeDetectionStrategy, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import Administrator from '@models/Administrator';
import { DetailedError } from '@models/backup/detailed-error';
import { PlanInfo } from '@models/backup/plan-info-model';
import Computer, { AgentType, convertAgentTypeToNeededCase, SendLogsExtraOptions } from '@models/Computer';
import { ComputerBasedModal } from '@models/ComputersModals';
import { AgentRemoteCommand, ParamsSendLogs, RemoteCommandType } from '@models/rm/remote-command-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { RmCommandsAbstractService } from '@services/rm-commands.service';
import { AgentProviderLocalDateTime } from '@utils/date';
import { DateTimeFormatterHelper } from '@utils/helpers/dateTimeFormatterHelper';
import { versionCompare } from '@utils/version-compare';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { assign, cloneDeep, isNil } from 'lodash';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { ModalService, ModalSettings } from 'mbs-ui-kit/modal/modal.service';
import { SharedPersistentStateEnum } from 'mbs-ui-kit/services/storage/shared-persistent-state-enum';
import { TableHeader } from 'mbs-ui-kit/table-grid/models/table-header';
import Toast from 'mbs-ui-kit/toast/toast.model';
import { ToastService } from 'mbs-ui-kit/toast/toast.service';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';
import { MbsSize } from 'mbs-ui-kit/utils/enums/mbs-size-enum';
import { MbsValidators } from 'mbs-ui-kit/utils/mbs-validators';
import { BehaviorSubject, EMPTY, map, noop, Observable, of, switchMap, take } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-computers-send-logs-modal',
  templateUrl: './computers-send-logs-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputersSendLogsModalComponent implements ComputerBasedModal, OnInit {
  @ViewChild('errorTemplate', { static: true }) private errorTemplate: TemplateRef<any>;
  @ViewChild(ModalComponent, { static: true }) private baseModal: ModalComponent;
  @ViewChild(ModalComponent, { static: true, read: ElementRef }) private baseModalRef: ElementRef<HTMLElement>;
  @ViewChild('alertElementRef', { static: false, read: ElementRef }) private alertElementRef: ElementRef<HTMLElement>;

  public readonly MbsPopupType = MbsPopupType;
  public readonly AgentType = AgentType;
  public readonly sendLogsTitle: string;
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public readonly placeholderText = 'example1@gmail.com,example2@gmail.com';
  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public computer: Computer; // it's recorded outside component
  public plan: PlanInfo; // it's recorded outside component

  public formGroup: UntypedFormGroup;
  public computerName = '';

  // headers for table in modal with error
  public headers: TableHeader[] = [
    {
      name: 'Icon',
      class: '-stretch pl-0',
      gridColSize: '1fr',
      gridColMin: '150px'
    },
    {
      name: 'Status Bar',
      gridColSize: '250px',
      gridColMin: '250px',
      class: '-stretch text-right'
    }
  ];
  // subheaders for table in modal with error
  public subHeaders: TableHeader[] = [
    {
      name: 'Message',
      class: '-stretch pl-0',
      gridColSize: '1fr'
    }
  ];

  public get isSelectedParams(): boolean {
    const sendToSupportOrSuperAdmin = this.formGroup.get('sendToSuperAdmin').value || this.formGroup.get('params.sendToSupport').value;

    const sendToCustomEmail = this.formGroup.get('params.sendToCustomEmail').value;
    const customEmails = this.formGroup.get('params.customEmails').valid;

    return sendToSupportOrSuperAdmin || (sendToCustomEmail && customEmails);
  }

  public get isBackupAgentVersionValid(): boolean {
    const validVersion = '7.1.1';
    const backupAgent = Computer.getAgent(this.computer, AgentType.Backup);
    return !isNil(backupAgent) && versionCompare(backupAgent.version, validVersion) > 0;
  }

  public get isLastRunInfo(): boolean {
    return !!this.plan?.lastRunInfo;
  }

  constructor(
    private auth: AuthService,
    private rmCommandsService: RmCommandsAbstractService,
    private ability: AbilityService,
    private toastService: ToastService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private i18nPipe: I18NextPipe,
    private backupFacade: ComputerBackupFacade
  ) {
    this.sendLogsTitle = this.i18nPipe.transform('app:sendLogs.title.sendLogsName', { format: 'title' });
  }

  ngOnInit(): void {
    this.initForm();
    this.initStreams();
  }

  initForm(): void {
    this.formGroup = this.fb.group({
      agentCommands: this.fb.group(
        {
          backup: [false],
          rmm: [false],
          ra: [false]
        },
        { validators: [Validators.required, this.agentCommandsValidator.bind(this)] }
      ),
      includeEventLogs: [false],
      params: this.fb.group({
        profile: [''],
        sendToSupport: [!this.ability.can('read', 'Readonly') && !this.ability.can('read', 'SuperAdmin')],
        sendToCustomEmail: [false],
        customEmails: ['', this.emailMultiValidator.bind(this)]
      }),
      message: [''],
      ticket: [''],
      sendToSuperAdmin: [this.ability.can('read', 'SuperAdmin')]
    });

    if (this.isLastRunInfo) {
      this.formGroup.get('agentCommands').get(AgentType.Backup).setValue(true, { emitValue: false });
    }
  }

  /*
   * Validation for the presence of at least one agent
   * @param agentCommands
   * @private
   */
  private agentCommandsValidator(agentCommands: UntypedFormGroup): ValidationErrors | null {
    const Backup = agentCommands.get('backup').value;
    const RMM = agentCommands.get('rmm').value;
    const RA = agentCommands.get('ra').value;

    if (Backup || RMM || RA) {
      return null;
    }

    return { agent: { message: 'Not any agent type selected' } };
  }

  /*
   * Checking for at least one email address in a line (control)
   * @param control
   * @private
   */
  private emailMultiValidator(control: AbstractControl): ValidationErrors {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    const invalidEmails = value
      .split(',')
      .map((email: string) => MbsValidators.emailValidator({ value: email.trim() } as AbstractControl))
      .filter(Boolean);

    return invalidEmails.length > 0 ? invalidEmails[0] : null;
  }

  initStreams(): void {
    this.auth.currentUser.pipe(filter(Boolean), untilDestroyed(this)).subscribe((user: Administrator) => {
      this.computerName = this.computer?.displayName || this.computer?.name || '';
      this.formGroup.patchValue({
        params: {
          profile: this.computer.profileName,
          customEmails: user.Email
        }
      });
    });
  }

  /*
   * Send payload by type command
   */
  handleResolve(): void {
    this.loading$.next(true);
    this.prepareAgentCommands()
      .pipe(
        switchMap((agentCommands) =>
          this.rmCommandsService.groupRemoteCommand({
            commandType: RemoteCommandType.SendLogs,
            agentCommands // Created for RMM do not use in prod (EM)
          })
        ),
        finalize(() => this.loading$.next(false)),
        catchError(() => EMPTY)
      )
      .subscribe({
        next: (state) => {
          this.displayingResult(state);
          this.baseModal.close();
        }
      });
  }

  /*
   * Prepare agentCommands before PUT on server
   * @private
   */
  private prepareAgentCommands(): Observable<AgentRemoteCommand<ParamsSendLogs>[]> {
    return this.convertExtraOptionsToMessage().pipe(
      map((message: string) => {
        const computerHid = this.computer.hid;
        const agentCommands = this.formGroup.get('agentCommands').value;
        const params = this.formGroup.get('params').value as ParamsSendLogs;

        const customEmails = params.sendToCustomEmail ? this.prepareCustomEmail(params.customEmails) : [];

        if (this.ability.can('read', 'SuperAdmin')) {
          params['sendToSuperAdmin'] = this.formGroup.get('sendToSuperAdmin').value;
        }

        return Object.entries(agentCommands)
          .filter(([, value]) => !!value)
          .map(([key]) => {
            const agentType = key.toLowerCase() as AgentType;
            const includeEventLogs =
              agentType === AgentType.Backup && this.isBackupAgentVersionValid && this.formGroup.get('agentCommands.backup').value
                ? this.formGroup.get('includeEventLogs').value
                : null;
            return {
              computerHid,
              agentType,
              params: assign({}, params, { message, customEmails }, !isNil(includeEventLogs) && { includeEventLogs })
            };
          });
      })
    );
  }

  private convertExtraOptionsToMessage(): Observable<string> {
    const ticket: string = this.formGroup.get('ticket').value.trim();
    const description: string = this.formGroup.get('message').value.trim();
    const flatted = (obj) =>
      Object.values(obj)
        .map((value: string) => value)
        .join('\n');

    if (this.isLastRunInfo) {
      return DateTimeFormatterHelper.setTime(
        of(this.plan.lastRunInfo?.dateTimeUTC as string),
        this.backupFacade.getAgentUtcOffsetValue(this.computer.hid),
        this.auth.currentUser.pipe(map((user) => user?.ProviderInfo?.TimeZoneOffset)),
        this.i18nPipe.transform('app:unknown')
      ).pipe(
        take(1),
        map((dateTime: AgentProviderLocalDateTime) => {
          const errMessage: string = this.plan.lastRunInfo.errorDetails
            .map((errorDetail: DetailedError) => `${errorDetail.detail} (code: ${errorDetail.id})`)
            .join();

          const extraOptions: SendLogsExtraOptions = {
            ticket: `Ticket: ${ticket}`,
            plan: `Plan: ${this.plan.displayName ?? ''} (${this.plan.id ?? ''})`,
            lastRun: dateTime.isEmpty
              ? ''
              : `Last Run: Provider (${dateTime.provider.time}${dateTime.provider.utc ? ' ' + dateTime.provider.utc : ''}) Agent (${
                  dateTime.agent.time
                } ${dateTime.agent.utc ? ' ' + dateTime.agent.utc : ''})`,
            error: `Error: ${errMessage}`,
            description: `Problem Description: ${description}`
          };

          return flatted(extraOptions);
        })
      );
    } else {
      const extraOptions: SendLogsExtraOptions = {
        ticket: `Ticket: ${ticket}`,
        description: `Problem Description: ${description}`
      };

      return of(flatted(extraOptions));
    }
  }

  private prepareCustomEmail(emails: string | string[]): string[] {
    return typeof emails === 'string'
      ? emails
          .split(',')
          .map((email) => email.trim())
          .filter(Boolean)
      : emails;
  }

  /*
   * Show result Send logs in toast
   * @param state
   * @private
   */
  private displayingResult(state): void {
    const prepareState = cloneDeep(state);
    const newResults = [];

    prepareState.resultList.forEach((item) => {
      const found = newResults.find((res) => res?.hid === item.hid);

      if (found) {
        found.childs.push({ ...item.result, agentType: convertAgentTypeToNeededCase(item.agentType) });
      } else {
        item.childs = [{ ...item.result, agentType: convertAgentTypeToNeededCase(item.agentType) }];
        newResults.push(item);
      }
    });

    newResults.forEach((res) => {
      delete res.agentType;
      delete res.result;
    });

    prepareState.resultList = newResults;

    if (prepareState?.ok) {
      prepareState.isErrorsInResultList ? this.handleShowRejectToast(prepareState) : this.handleShowResolveToast(prepareState);
    } else {
      this.toastService.toast(
        new Toast({
          header: this.sendLogsTitle,
          content: `The command <span class="font-weight-semibold">Send Log</span> is not defined`,
          type: MbsPopupType.danger
        })
      );
    }
  }

  /*
   * Check the computer has agent (application)
   * @param agentType
   */
  public isAgentTypeByApplicationID(agentType: AgentType): boolean {
    return Computer.hasAgent(this.computer, agentType);
  }

  /*
   * Show resolve toast for Send logs success result
   * @param prepareState
   * @private
   */
  private handleShowResolveToast(prepareState): void {
    const getContentText = (): string => {
      const compSendLogsResult = prepareState.resultList[0];

      return `Logs have been sent for ${
        compSendLogsResult?.displayName.trim().toLowerCase() !== compSendLogsResult?.name.trim().toLowerCase()
          ? compSendLogsResult.displayName + ' [' + compSendLogsResult.name + ']'
          : compSendLogsResult.name
      }`;
    };

    this.toastService.toast(
      new Toast({
        header: this.sendLogsTitle,
        content: getContentText(),
        type: MbsPopupType.success,
        icon: true
      })
    );
  }

  /*
   * Show reject toast for Send logs failed result
   * @param prepareState
   * @private
   */
  private handleShowRejectToast(prepareState): void {
    this.toastService.toast(
      new Toast({
        header: this.sendLogsTitle,
        content: prepareState.message,
        type: MbsPopupType.danger,
        icon: true,
        buttons: [
          {
            clickHandler: function (): void {
              this.handleOpenModalWithDetail(prepareState, this.sendLogsTitle);
            }.bind(this)
          }
        ]
      })
    );
  }

  private handleOpenModalWithDetail(content, title: string): void {
    const settings: ModalSettings = {
      collapsing: true,
      header: {
        title: `${title} details`,
        showExpandedCross: true,
        textOverflow: true
      },
      data: {
        context: content
      },
      size: MbsSize.lg
    };

    this.modalService.open(settings, this.errorTemplate).catch(noop);
  }

  /*
   * Recursively unchecks checkbox `includeEventLogs`
   * @param state
   */
  public handleChangeIncludeEventLogs(state: boolean): void {
    if (this.isBackupAgentVersionValid && !state) {
      this.formGroup.get('includeEventLogs').patchValue(state);
    }
  }

  handleChangeToSupport(state: boolean): void {
    const modalContent = (this.baseModal as any)?.element.nativeElement.closest('.modal-content');
    const rect = this.alertElementRef?.nativeElement.getBoundingClientRect();
    const marginTop = 7; // [descriptionClasses]="'mt-2'"
    const offset = state ? 0 : ((Math.floor(rect.height) + marginTop) / 2) * -1;

    (modalContent as HTMLElement).style.transform = `translateY(${offset}px)`;
  }

  handleRedirectToSP(): void {
    this.auth.redirectToSupportPortal();
  }
}
