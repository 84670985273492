export enum DiskCapacity {
  'MB' = 'MB',
  'GB' = 'GB',
  'TB' = 'TB'
}

export interface PartitionsStepValue {
  restoreConfigs?: { [key: string]: any };
  selectedPartitionsFromRDC?: string[];
  restorePartitions: any[];
  restoreParentPartitions?: any[];
  convertToMBR: boolean;
  valid: boolean;
}
