export enum EncryptionAlgorithm {
  None = 0,
  DES = 1,
  TripleDES = 2,
  AES = 3,
  RC2 = 4
}

export class EncryptionCompressionModel {
  EnableCompression: boolean;
  EnableEncryption: boolean;
  EncryptionAlgorithm: EncryptionAlgorithm;
  EncryptionKeySize: number;
  Password: string;
  UseServerSideEncryption: boolean;
}

export interface CompressionEncryptionStepValue {
  UseCompression?: boolean;
  UseEncryption: boolean;
  EncryptionAlgorithm: number;
  EncryptionKeySize: number;
  EncryptionPassword: string;
  UseFileNameEncryption?: boolean;
  UseServerSideEncryption?: boolean;
  hint?: string;
  valid: boolean;
  rebackup?: boolean;
}
