import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';

type ProgressBarTypes = 'success' | 'info' | 'warning' | 'danger' | 'primary' | 'secondary' | 'dark' | 'light' | 'dangerous';

export class LineSettings {
  type: ProgressBarTypes;
  value: number;
  customClass?: string;
  lineWidth?: number;
  icon?: string; // works only with height = 10px
  iconLeft?: number;
}

@Component({
  selector: 'mbs-progress-bar',
  templateUrl: './progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() type: ProgressBarTypes;

  @Input() value = 0;

  @Input() dynamicColor: boolean;

  @Input() multiLineSettings: LineSettings[];

  /**
   * Possible values <br>
   * number: [height]="10" result in px; <br>
   * string: [height]="10px" any valid units, including the calc function.
   */
  @Input() height: number | string;

  public isWaiting: boolean;
  public progressWidth = 0;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.isWaiting = changes.value.currentValue === null;
      if (changes.value.currentValue >= 0) {
        setTimeout(() => {
          this.progressWidth = this.value;
          this.cd.detectChanges();
        }, 50);
      }
    }
    if (this.multiLineSettings !== undefined) {
      this.isWaiting = !changes.multiLineSettings.currentValue;
      if (changes.multiLineSettings.currentValue !== null) {
        this.multiLineSettings.forEach(item => (item.lineWidth = 0));
        setTimeout(() => {
          let iconLeft = 0;
          this.multiLineSettings.forEach(item => {
            item.lineWidth = item.value;
            if (item.icon) {
              item.iconLeft = item.lineWidth / 2 + iconLeft;
            }
            iconLeft += item.lineWidth;
          });
          this.cd.detectChanges();
        }, 50);
      }
    }
  }

  get progressBarColorClass(): string {
    if (this.dynamicColor) {
      return this.value <= 60 ? 'background-success' : this.value <= 85 ? 'background-warning' : 'background-danger';
    } else {
      return this.type ? this.getBgColorClass(this.type) : '';
    }
  }

  multilineClasses(type: ProgressBarTypes, customClass: string): string {
    const classStr = type ? `${this.getBgColorClass(type)} ` : '';
    return customClass ? classStr.concat(customClass) : classStr;
  }

  get heightStyle() {
    if (this.height) {
      const isHeightANumber = typeof this.height === 'number' || (typeof this.height === 'string' && this.height.match(/^\d+$/g));
      return { height: isHeightANumber ? `${this.height}px` : this.height };
    } else {
      return null;
    }
  }

  getBgColorClass(type: ProgressBarTypes): string {
    return type ? `background-${type}` : '';
  }
}
