import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildType } from '@models/BuildType.enum';
import Company, { CompaniesQueryParams, LicensesPoolType } from '@models/Company';
import { LicenseType } from '@models/LicenseType.enum';
import { PagedResponse } from '@models/Paging';
import { NoReadonlyType } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export type SupportLicense = NoReadonlyType<{ [k in keyof typeof BuildType]: Array<LicenseType> }>;
@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private readonly controllerPath = 'api/companies';

  constructor(private http: HttpClient) {}

  public get(params?: CompaniesQueryParams): Observable<PagedResponse<Company>> {
    return this.http.get<PagedResponse<Company>>(this.controllerPath, { params });
  }

  public getById(id: string): Observable<Company> {
    return this.http.get<Company>(`${this.controllerPath}/${id}`);
  }

  public getSupportBuilds(): Observable<BuildType[]> {
    return this.http.get<BuildType[]>(`${this.controllerPath}/backup-editions`).pipe(filter((build) => !!build));
  }

  public getBuildsMapLicenses(): Observable<SupportLicense> {
    return this.http.get<SupportLicense>(`${this.controllerPath}/licenses-for-editions`).pipe(filter((build) => !!build));
  }

  public assignLicensePoolTypeToCompany(licensePoolType: LicensesPoolType, companyId: string): Observable<any> {
    return this.http.put(`${this.controllerPath}/${companyId}/users/licenses-pool`, { licensePoolType });
  }

  public create(company: Company): Observable<Company> {
    return this.http.post<Company>(this.controllerPath, company);
  }

  public update(company: Company): Observable<Company> {
    return this.http.put<Company>(`${this.controllerPath}/${company.id}`, company);
  }

  public deleteCompany(id: string): Observable<null> {
    return this.http.delete<null>(`${this.controllerPath}/${id}`);
  }
}
