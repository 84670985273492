import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TFARequireModalComponent } from '@components/tfa/components/tfa-require-modal/tfa-require-modal.component';
import { ApplicationStateFacade } from '@facades/application.facade';
import { UiStorageKey } from '@models/ui-storage';
import { EnablePasswordRecoveryWizardComponent } from '@modules/password-recovery/modals/enable-password-recovery-wizard/enable-password-recovery-wizard.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { noop } from 'lodash';
import { MbsPopupType, MbsSize, ModalComponent, ModalService } from 'mbs-ui-kit';

UntilDestroy();
@Component({
  selector: 'mbs-force-password-recovery',
  templateUrl: './force-password-recovery-modal.component.html'
})
export class ForcePasswordRecoveryModalComponent {
  public readonly mbsPopupType = MbsPopupType;
  public tryToClose = false;
  public warningCheckbox = new FormControl(false);
  public uiStorageKey = UiStorageKey.HidePasswordRecoveryModal;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private modalService: ModalService,
    private ability: AbilityService,
    private i18N: I18NextPipe,
    private applicationStateFacade: ApplicationStateFacade
  ) {}

  public onPasswordRecoveryEnableButtonClick() {
    if (this.ability.cannot('read', '2FAEnabled')) {
      this.modalService
        .openCustom(TFARequireModalComponent, {
          size: MbsSize.sm,
          data: {
            actionName: this.i18N.transform('pass-recovery:passRecoveryTitle', { format: 'title' })
          }
        })
        .catch(noop);

      return;
    }

    this.modalService
      .openCustom(EnablePasswordRecoveryWizardComponent)
      .then(() => {
        this.baseModal.close();
      })
      .catch(noop);
  }

  public onCloseButtonClick(): void {
    if (!this.tryToClose) {
      this.tryToClose = true;
      return;
    }

    this.applicationStateFacade.setStorageSetting(this.uiStorageKey, 'true', true).subscribe({
      complete: () => this.baseModal.close()
    });
  }
}
