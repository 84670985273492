import { Injectable } from '@angular/core';
import { Action, Events, EventTypes, Rule, Rules } from './types';
import { getArrayValue } from './utils';

@Injectable({
  providedIn: 'root'
})
export class AbilityService {
  private rules: Rules = new Map();
  private events: Events = new Map();

  public on(eventType: EventTypes, callback: Function) {
    const event = this.events.get(eventType);

    if (event) {
      event.push(callback);
    } else {
      this.events.set(eventType, [callback]);
    }
  }

  public addRule(rule: Rule, emitEvent = false) {
    const subjects = getArrayValue(rule.subject);
    const actions = getArrayValue(rule.actions);
    const fields = rule.fields?.length && getArrayValue(rule.fields);

    subjects.forEach((subject) => {
      actions.forEach((action) => {
        if (fields) {
          fields.forEach((field) => {
            this.setRule(rule, subject, action, field);
          });
        } else {
          this.setRule(rule, subject, action);
        }
      });
    });

    emitEvent && this.emit(EventTypes.Update);
  }

  public update(rules: Rule[]) {
    rules.forEach((rule) => {
      this.addRule(rule);
    });
    this.emit(EventTypes.Update);
    return this.rules;
  }

  public can(action: Action, subject: any, field?: string): boolean {
    if (!this.rules.size) {
      return false;
    }

    const isObject = typeof subject === 'object';

    const subjectName = isObject ? Object.getPrototypeOf(subject).constructor.modelName : subject;
    const rule = this.rules.get(this.getRuleKey(subjectName, action)) || this.rules.get(this.getRuleKey(subjectName, action, field));

    let available = false;

    if (rule?.conditions) {
      available = Object.entries(rule.conditions).every(([param, callback]) => callback(subject[param]));
    } else {
      available = Boolean(rule);
    }

    return rule?.inverted ? !available : available;
  }

  public cannot(action: Action, subject: any, field?: string): boolean {
    return !this.can(action, subject, field);
  }

  public deleteRule(subject: string, action: string, field?: string) {
    this.rules.delete(this.getRuleKey(subject, action, field));

    this.emit(EventTypes.Update);
  }

  private getRuleKey(subject: string, action: string, field?: string) {
    return `${subject}.${action}.${field}`;
  }

  private emit(eventType: EventTypes) {
    this.events.get(eventType)?.forEach((callback) => {
      callback();
    });
  }

  private setRule(rule: Rule, subject: string, action: string, field?: string) {
    this.rules.set(this.getRuleKey(subject, action, field), {
      conditions: rule.conditions,
      inverted: rule.inverted
    });
  }
}
