import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UiStorageKey, UiStorageType } from '@models/ui-storage';

@Injectable({
  providedIn: 'root'
})
export class UiStorageService {
  private apiUrl = 'api/uistorage';

  constructor(private http: HttpClient) {
  }
  getSettings(): Observable<UiStorageType> {
    return this.http.get<{data: [{key: UiStorageKey; value: any}]; total: number }>(`${this.apiUrl}`).pipe(
      map(({ data}) => {
        return data.reduce((acc, current) => {
          acc[current.key] = current.value;

          return acc;
        }, {} as UiStorageType)
      })
    )
  }

  storeSetting(key: UiStorageKey, value: any, addAuditLogRecord = false): Observable<null> {
    return this.http.post<null>(`${this.apiUrl}/${key}${addAuditLogRecord ? '?eventlog=true' : ''}`, value);
  }

  deleteSetting(key: UiStorageKey): Observable<null> {
    return this.http.delete<null>(`${this.apiUrl}/${key}`);
  }
}
