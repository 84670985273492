import { Injectable, Injector } from '@angular/core';
import { AgentUpdateRequiredModalComponent } from '@components/computers-modal/agent-update-required-modal/agent-update-required-modal.component';
import { BackupAgentUpdateRequiredModalComponent } from '@components/computers-modal/backup-agent-update-required-modal/backup-agent-update-required-modal.component';
import { DeepInstinctModalComponent } from '@components/deep-instinct-modal/deep-instinct-modal.component';
import { SidepanelDownloadsComponent } from '@components/sidepanel-downloads/sidepanel-downloads.component';
import { ComputerModalsFacade } from '@facades/computer.modals.facade';
import { ComputersFacade } from '@facades/computers.facade';
import Administrator from '@models/Administrator';
import Computer, { AgentType } from '@models/Computer';
import { getNeededBuildType } from '@models/InstallAgent';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { BuildService } from '@services/build-service/build.service';
import { handleRMMError } from '@utils/handleError';
import { I18NextPipe } from 'angular-i18next';
import { MbsSize, ModalService, SidepanelService, ToastService } from 'mbs-ui-kit';
import { EMPTY, from, noop, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

const backupWithTokenSupportsAgentVersion = 770;
const linuxBackupSupportsAgentInstall = 421106;

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ComputerAgentWrapper {
  private downloadPanel = SidepanelDownloadsComponent;

  public currentUser: Administrator;

  constructor(
    private facade: ComputersFacade,
    private modals: ComputerModalsFacade,
    private sidepanelService: SidepanelService,
    private buildService: BuildService,
    private modalService: ModalService,
    private injector: Injector,
    private i18nPipe: I18NextPipe,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    this.authService.currentUser.pipe(filter<Administrator>(Boolean), untilDestroyed(this)).subscribe((user) => (this.currentUser = user));
  }

  handleInstallAgent(computer: Computer, agent: AgentType): void {
    if (
      Computer.isMacOrLinux(computer) &&
      agent === AgentType.RMM &&
      Computer.hasAgent(computer, AgentType.Backup) &&
      !Computer.IsSupportedAgentVersion(computer, AgentType.Backup, linuxBackupSupportsAgentInstall, true)
    ) {
      this.modalService.openCustom(AgentUpdateRequiredModalComponent, {
        data: {
          computer
        }
      });

      return;
    }

    if (
      agent === AgentType.RA &&
      Computer.hasAgent(computer, AgentType.Backup) &&
      !Computer.IsSupportedAgentVersion(computer, AgentType.Backup, backupWithTokenSupportsAgentVersion)
    ) {
      this.modalService.openCustom(BackupAgentUpdateRequiredModalComponent, {
        data: {
          computerName: Computer.getComputerName(computer)
        }
      });

      return;
    }

    const checkBuildAndInstall = () =>
      this.checkPublicBuild(computer, agent).pipe(
        switchMap((resultAgent) => this.facade.installAgent(computer, resultAgent)),
        switchMap(() => this.modals.openInstallationStartedModal(agent, computer))
      );

    if ([AgentType.Backup, AgentType.RMM].includes(agent)) {
      this.modals
        .openInstallAgentModal(agent as AgentType.Backup | AgentType.RMM, computer)
        .pipe(
          catchError(() => EMPTY),
          switchMap(() => checkBuildAndInstall())
        )
        .subscribe();
    } else if (agent === AgentType.RA) {
      checkBuildAndInstall().subscribe();
    } else if (agent === AgentType.DeepInst) {
      this.installDeepInstinct(computer);
    }
  }

  handleGroupInstallAgent(computers: Computer[], agent: AgentType) {
    this.facade
      .installAgentToComputers(computers, agent)
      .pipe(
        catchError(() => of(false)),
        filter(Boolean),
        switchMap(() => this.modals.openInstallationStartedModal(agent))
      )
      .subscribe();
  }

  handleBulkInstallAgent(agent: AgentType, skipPaging = false) {
    this.modals
      .openBulkInstallAgentModal(agent as AgentType.Backup | AgentType.RMM | AgentType.RA)
      .pipe(
        switchMap(() => this.facade.currentPageRequestParams$(false, skipPaging)),
        take(1),
        switchMap((filters) => this.facade.bulkInstallAgent(filters, agent)),
        catchError(() => of(false)),
        filter(Boolean),
        switchMap(() => this.modals.openInstallationStartedModal(agent))
      )
      .subscribe();
  }

  private installDeepInstinct(computer: Computer) {
    this.facade.applicationsCountAfterInit$
      .pipe(
        take(1),
        switchMap((installedApps) =>
          installedApps.deepInst ? this.installDeepInstinctAgent(computer) : this.openDeepInstinctModal(computer)
        ),
        untilDestroyed(this)
      )
      .subscribe();

    this.facade.loadApplicationsCount();
  }

  private installDeepInstinctAgent(computer: Computer): Observable<any> {
    return this.facade.installAgent(computer, AgentType.DeepInst).pipe(
      tap((result) => result?.isErrorsInResultList && handleRMMError(result.resultList, this.toastService)),
      switchMap((result) =>
        result?.isErrorsInResultList ? of(result) : this.modals.openInstallationStartedModal(AgentType.DeepInst, computer)
      )
    );
  }

  private openDeepInstinctModal(computer: Computer): Observable<unknown> {
    return from(
      this.modalService
        .openCustom(DeepInstinctModalComponent, {
          data: {
            title: this.i18nPipe.transform('app:deepInstinctModal:title', {
              format: 'title'
            }),
            computer,
            userId: this.currentUser?.Id
          },
          size: MbsSize.lg
        })
        .catch(noop)
    );
  }

  handleOpenDownloadPanel(): void {
    this.sidepanelService.addAndOpenByType(this.downloadPanel, null, this.injector).pipe(untilDestroyed(this)).subscribe();
  }

  private checkPublicBuild(computer: Computer, agent: AgentType): Observable<AgentType> {
    return this.getFirstPublicBuildApplicationAsAgentType(computer, agent).pipe(
      switchMap((agent) =>
        (agent
          ? of(false)
          : this.modals.openNoPublicBuildsModal().pipe(
              tap((result) => !result && this.handleOpenDownloadPanel()),
              map(() => true)
            )
        ).pipe(
          filter((res) => !res),
          map(() => agent)
        )
      )
    );
  }

  private getFirstPublicBuildApplicationAsAgentType(computer: Computer, agent: AgentType): Observable<AgentType | null> {
    return this.getPublicBuild(computer, agent).pipe(map((builds) => (builds?.[0]?.applicationId as AgentType) ?? null));
  }

  private getPublicBuild(computer: Computer, agent: AgentType) {
    const buildType = getNeededBuildType(computer, agent);
    return this.buildService
      .getAll()
      .pipe(
        map((builds) =>
          builds && builds.builds && Array.isArray(builds.builds)
            ? builds.builds.filter((build) => build.mbsBuildType === buildType && build.public)
            : []
        )
      );
  }
}
