import { Injectable } from '@angular/core';
import { QueryParams } from '@models/Paging';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { combineLatest, filter, map, of, switchMap, take, withLatestFrom } from 'rxjs';
import { PagingStoreFactory } from './pagingStoreFactory';

@Injectable({
  providedIn: 'root'
})
// eslint-disable-next-line @typescript-eslint/ban-types
export class PagingStoreFacade<T, F extends {} = Record<string, never>> {
  constructor(public store: Store, public factory: PagingStoreFactory<T, F>) {}

  public getById$ = (id: string, withLoad = false, force = false) => {
    return this.getByIdCombined$(id, false, withLoad, force);
  };

  public getLoadedById$ = (id: string, withLoad = false, force = false) => {
    return this.getByIdCombined$(id, true, withLoad, force);
  };

  private getByIdCombined$ = (id: string, loadedById = false, withLoad = false, force = false) => {
    withLoad && this.loadById({ id, force });

    return this.store.select(loadedById ? this.factory.selectors.selectLoadedById(id) : this.factory.selectors.selectById(id));
  };
  public getAnyFirst$ = this.store.select(this.factory.selectors.selectFirstEntity);

  // common
  public getSelected$ = this.store.select(this.factory.selectors.selectSelectedEntity);
  public getLoading$ = this.store.select(this.factory.selectors.selectLoading);

  // data
  public getDataLoading$ = this.store.select(this.factory.selectors.selectDataLoading);
  public getDataLoaded$ = this.store.select(this.factory.selectors.selectDataLoaded);
  public getDataTotal$ = this.store.select(this.factory.selectors.selectDataTotal);
  public getData$ = (withLoad = false) => {
    withLoad && this.loadData();

    return this.store.select(this.factory.selectors.selectData);
  };
  public getDataCount$ = this.store.select(this.factory.selectors.selectDataCount);
  public getAllDataLoaded$ = this.store.select(this.factory.selectors.selectAllDataLoaded);

  // paging
  public getPagingLoading$ = this.store.select(this.factory.selectors.selectPagingLoading);
  public getPagingLoaded$ = this.store.select(this.factory.selectors.selectPagingLoaded);
  public getPagingCurrentPageNumber$ = this.store.select(this.factory.selectors.selectPagingCurrentPageNumber);
  public getPagingPerPage$ = this.store.select(this.factory.selectors.selectPagingPerPage);
  public getPagingTotal$ = this.store.select(this.factory.selectors.selectPagingTotal);
  public getPagingParams$ = this.store.select(this.factory.selectors.selectPagingParams);
  public getPagingFilters$ = this.store.select(this.factory.selectors.selectPagingFilters);
  public getCurrentPage$ = this.store.select(this.factory.selectors.selectCurrentPage);

  public getPagingEmptyFiltersAndFirstCurrentPage = combineLatest([this.getPagingFilters$, this.getPagingCurrentPageNumber$]).pipe(
    map(([filters, currentPageNumber]) => isEmpty(filters) && currentPageNumber === 1)
  );

  // combined
  public getDataEntityLoading$ = this.store.select(this.factory.selectors.selectDataEntityLoading);
  public getPagingEntityLoading$ = this.store.select(this.factory.selectors.selectPagingEntityLoading);
  public getDataWhenLoaded$ = this.getData$().pipe(
    withLatestFrom(this.getDataLoaded$),
    filter(([, loaded]) => loaded),
    map(([data]) => data)
  );

  /**
   * Load entity by id in Entity store
   * @param {{id: string, quiet: boolean, force: boolean}} params Parameters
   * @param {string} params.id Entity Id
   * @param {boolean} params.quiet Quiet loading of entity. Without  changes in 'loading' store field (without spinner)
   * @param {boolean} params.force Force loading data from the backend (even if the data is already in store)
   * @return {void}
   */
  public loadById(params: { id: string; quiet?: boolean; force?: boolean }): void {
    this.store.dispatch(this.factory.actions.loadEntityById(params));
  }
  public setSelected(selected: string, force = false): void {
    this.store.dispatch(this.factory.actions.setSelected({ selected, force }));
  }
  public clearEntities(): void {
    this.store.dispatch(this.factory.actions.clear());
  }
  public setEntities(entities: T[]): void {
    this.store.dispatch(this.factory.actions.setEntities({ entities }));
  }

  // data
  public loadData(isFullRefresh = false, allowLoadMore = false, limit: number = null): void {
    this.store.dispatch(this.factory.actions.loadData({ isFullRefresh, allowLoadMore, limit }));
  }
  public refreshData(): void {
    this.loadData(true);
  }
  public clearData(): void {
    this.store.dispatch(this.factory.actions.clearData());
  }

  // paging
  public loadPage(params: { filters?: QueryParams; pageNumber?: number; perPage?: number; isFullRefresh?: boolean }): void {
    this.store.dispatch(this.factory.actions.loadPage(params));
  }
  public setPageNumber(pageNumber: number): void {
    this.store.dispatch(this.factory.actions.setPageNumber({ pageNumber }));
  }
  public setPerPage(perPage: number): void {
    this.store.dispatch(this.factory.actions.setPerPage({ perPage }));
  }
  public refreshPaging(): void {
    this.loadPage({ isFullRefresh: true });
  }
  public clearPaging(): void {
    this.store.dispatch(this.factory.actions.clearPages());
  }

  public clearAll(): void {
    this.clearData();
    this.clearPaging();
    this.clearEntities();
  }

  public refreshDataAndPaging(): void {
    this.store.dispatch(this.factory.actions.refreshDataAndPaging());
  }

  public refreshAfterCRUD(newEntity: T = null, nameField: string = null): void {
    of(newEntity)
      .pipe(
        switchMap((newEntity) =>
          newEntity
            ? this.getById$(this.factory.adapter.selectId(newEntity) as string).pipe(
                take(1),
                map((oldEntity) => ({
                  hasNewEntity: true,
                  updateEntityOnly: (oldEntity as any)?.[nameField ?? 'name'] === (newEntity as any)?.[nameField ?? 'name']
                }))
              )
            : of({ hasNewEntity: false, updateEntityOnly: false })
        ),
        switchMap(({ hasNewEntity, updateEntityOnly }) =>
          updateEntityOnly
            ? of({ hasNewEntity, updateEntityOnly, dataLoaded: null, pagingLoaded: null, initialPageAndFilter: false })
            : combineLatest([this.getDataLoaded$, this.getPagingLoaded$, this.getPagingEmptyFiltersAndFirstCurrentPage]).pipe(
                take(1),
                map(([dataLoaded, pagingLoaded, initialPageAndFilter]) => ({
                  hasNewEntity,
                  updateEntityOnly,
                  dataLoaded,
                  pagingLoaded,
                  initialPageAndFilter
                }))
              )
        )
      )
      .subscribe(({ hasNewEntity, updateEntityOnly, dataLoaded, pagingLoaded, initialPageAndFilter }) => {
        hasNewEntity && this.setEntities([newEntity]);

        if (updateEntityOnly) return;

        this.refreshInvalid(dataLoaded, pagingLoaded, initialPageAndFilter);
      });
  }

  public invalidate(): void {
    combineLatest([this.getDataLoaded$, this.getPagingLoaded$, this.getPagingEmptyFiltersAndFirstCurrentPage])
      .pipe(take(1))
      .subscribe(([dataLoaded, pagingLoaded, initialPageAndFilter]) => {
        this.refreshInvalid(dataLoaded, pagingLoaded, initialPageAndFilter);
      });
  }

  private refreshInvalid(dataLoaded: boolean, pagingLoaded: boolean, initialPageAndFilter: boolean): void {
    if (pagingLoaded && dataLoaded && initialPageAndFilter) {
      return this.refreshDataAndPaging();
    }

    dataLoaded && this.refreshData();
    pagingLoaded && this.refreshPaging();
  }
}
