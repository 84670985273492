import { formatDate } from '@angular/common';
import { Period } from '@models/Period.enum';
import * as moment from 'moment';
import { ISOFormat } from './constants/c-sharp-constants';

export const cobbledDateFormat = 'YYYYMMDDHHmmss';
export const mediumTime = 'HH:mm';
export const mediumDate = 'MMM d, y';
export const mediumDateWithTime = 'MMM d, y, h:mm a';
export const mediumDateWithTimeMoment = 'MMM\u00A0D,\u00A0YYYY, h:mm\u00A0A';
export const mediumDateWithoutTimeMoment = 'MMM\u00A0D,\u00A0YYYY';
export const baseDateFileNameFormatHoursAndMinutes = 'YYYY-MM-DD--HH-mm';
export const dateFormatWithoutTWithA = 'M/D/YYYY h:mm A';
export const onlyDateFormat = 'YYYY-MM-DD';
export const baseDateFormat = 'YYYY-MM-DDTHH:mm:00';
export const baseDateFormatWithSeconds = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeFormatForRestorePoint = 'D.MM.YYYY HH:mm';
export const dateFormatWithoutT = 'M/D/YYYY HH:mm:ss';
export const AMPMRegex = /\s(AM|PM)$/i;
export const dateTimePointsFormatWithAm = /^\d{1,2}\/\d{1,2}\/\d{3,4}\s\d{1,2}:\d{1,2}:\d{1,2}\s(AM|PM)/;
export const dateTimePointsFormatWithoutAm = /^\d{1,2}\/\d{1,2}\/\d{3,4}\s\d{1,2}:\d{1,2}:\d{1,2}/;
export const dateTimePointsReg = /^\d{3,4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}Z/;
export const dateTimePointsFormat = /^(\d\d\d\d\d\d\d\d\d\d\d\d\d\d)/;
export const hoursRegex = /^(\d\d?)/;
export const minutesRegex = /:(\d\d?)/;
export const timeSpanStringRegex = /^(\d+.)*?(\d+):(\d+):(\d+)/;
export const ticksDateRegex = /^\/Date\((.+)\)\//;
export type TimeGroup = { value?: number; timePeriod?: number };

export function transformDate(time, format = 'mediumDate'): string {
  const myDate = new Date(time * 1000);
  const locale = 'en-US';
  return formatDate(myDate, format, locale);
}

export function timeIntervalString(timeBegin, timeEnd): string {
  function timeDiff(time1, time2): number {
    const diff = (time2.getTime() - time1.getTime()) / 1000;
    return Math.abs(Math.round(diff));
  }

  let totalSeconds = timeDiff(timeBegin, new Date(timeEnd));

  const days = Math.floor(totalSeconds / 86400);
  totalSeconds -= days * 86400;

  const hours = Math.floor(totalSeconds / 3600) % 24;
  totalSeconds -= hours * 3600;

  const minutes = Math.floor(totalSeconds / 60) % 60;
  totalSeconds -= minutes * 60;

  return (days > 0 ? days + ' days ' : '') + (hours > 0 ? hours + ' hours ' : '') + (minutes > 0 ? minutes + ' min' : '');
}

export function TimeSpanToTimeGroup(timeSpan: any): TimeGroup {
  let value = 0;
  let timePeriod = Period.year;
  const daysPerYear = 365;
  const daysPerMonth = 30;
  const daysPerWeek = 7;
  let _timeSpan = timeSpan;

  if (typeof timeSpan === typeof '' && timeSpanStringRegex.test(timeSpan)) {
    const matches = timeSpanStringRegex.exec(timeSpan);
    _timeSpan = {
      Days: matches[1]
    };
  }

  if (_timeSpan.Days >= 1) {
    if (_timeSpan.Days % daysPerYear == 0) {
      value = _timeSpan.Days / daysPerYear;
      timePeriod = Period.year;
    } else if (_timeSpan.Days % daysPerMonth == 0) {
      value = _timeSpan.Days / daysPerMonth;
      timePeriod = Period.month;
    } else if (_timeSpan.Days % daysPerWeek == 0) {
      value = _timeSpan.Days / daysPerWeek;
      timePeriod = Period.week;
    } else {
      value = +_timeSpan.Days;
      timePeriod = Period.day;
    }
  } else {
    value = 1;
    timePeriod = Period.day;
  }

  return { value, timePeriod };
}

export function GetDateFromString(dateString: string): Date {
  if (ticksDateRegex.test(dateString)) {
    const matches = ticksDateRegex.exec(dateString);
    return new Date(Number.parseInt(matches[1]));
  } else {
    try {
      dateString = dateString.replace(/[zZ]/gi, '');
      dateString = dateString.split('+')[0];
      return moment(dateString, 'YYYY-MM-DDTHH:mm:ss').toDate();
    } catch {
      return new Date();
    }
  }
}

export function GetTime24FormatFromDuration(duration: moment.Duration): string {
  const sHours = duration.hours();
  const sMinutes = duration.minutes();
  let minutesStr = '00';
  let hoursStr = '00';
  minutesStr = sMinutes < 10 ? '0' + sMinutes.toString() : sMinutes.toString();
  hoursStr = sHours < 10 ? '0' + sHours.toString() : sHours.toString();
  return hoursStr + ':' + minutesStr;
}

export function GetTimeSpanString(duration: moment.Duration): string {
  return `${duration.days()}.${duration.hours()}:${duration.minutes()}:${duration.seconds()}.${duration.milliseconds()}`;
}

export function GetHoursFrom24hFormatString(time: string): number {
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  return Number(time.match(hoursRegex)[1]);
}

export function GetHoursFrom12HoursFormatString(time): number {
  let hours = Number(time.match(hoursRegex)[1]);
  const AMPM = time.match(AMPMRegex)[1];
  if ((AMPM == 'PM' || AMPM == 'pm') && hours < 12) {
    hours = hours + 12;
  } else if ((AMPM == 'AM' || AMPM == 'am') && hours == 12) {
    hours = hours - 12;
  }
  return hours;
}

export function GetMinutes(time): number {
  return Number(time.match(minutesRegex)[1]);
}

export function GetDateFromTimeString(str: string): Date {
  const isAmPmFormat = AMPMRegex.test(str);
  const dt = new Date();
  if (isAmPmFormat) {
    dt.setHours(GetHoursFrom12HoursFormatString(str));
  } else {
    dt.setHours(GetHoursFrom24hFormatString(str));
  }
  dt.setMinutes(GetMinutes(str));
  return dt;
}

export function TimeGroupToTimeSpanString(timeGroup: { value: number; period: Period }): string {
  let timeMultiplier = 1;
  switch (timeGroup.period) {
    case Period.day:
      timeMultiplier = 1;
      break;
    case Period.month:
      timeMultiplier = 30;
      break;
    case Period.week:
      timeMultiplier = 7;
      break;
    case Period.year:
      timeMultiplier = 365;
      break;
  }

  return `${+timeGroup.value * timeMultiplier}.00:00:00`;
}

export function getISODateFromTimeAndDate(time: string, date: Date | string, needZ = false): string {
  const formattedDate = moment(new Date(date), 'llll').format('YYYY-MM-DD').toString();
  if (needZ) {
    return moment.utc(moment(`${formattedDate} ${time}`, 'YYYY-MM-DD hh:mm:00')).format(baseDateFormat) + 'Z';
  } else return moment(`${formattedDate} ${time}`, 'YYYY-MM-DD hh:mm:00').format(baseDateFormat);
}

export function getDateWithoutZ(myDate): string {
  const index = myDate.length - 1;
  const lastSymbolDate = myDate[index];
  return lastSymbolDate === 'Z' ? myDate.slice(0, index) : myDate;
}

export function getFullDateFromDateTimeObject(obj: { date: Date | string; time: Date | string }, needSeconds = false): Date {
  if (!obj.time) obj.time = '00:00:00';
  let seconds = 0;
  if (needSeconds) {
    const timeArray = (obj.time as string).split(':');
    if (timeArray && timeArray[2]) seconds = +timeArray[2];
  }
  return moment(obj.date)
    .hours(GetHoursFrom24hFormatString(obj.time as string))
    .minutes(GetMinutes(obj.time))
    .second(seconds)
    .toDate();
}

export function getFullISODateFromDateTimeObject(obj: { date: Date | string; time: Date | string }, needSeconds = false): string {
  if (!obj.time) obj.time = '00:00:00';
  let seconds = 0;
  if (needSeconds) {
    const timeArray = (obj.time as string).split(':');
    if (timeArray && timeArray[2]) seconds = +timeArray[2];
  }
  return moment(obj.date)
    .hours(GetHoursFrom24hFormatString(obj.time as string))
    .minutes(GetMinutes(obj.time))
    .second(seconds)
    .format(ISOFormat);
}

export type AgentProviderLocalDateTime = {
  isEmpty?: boolean,
  raw: {
    time: string;
  };
  agent: {
    utc: string;
    time: string;
  };
  provider: {
    utc: string;
    time: string;
  };
  local: {
    utc: string;
    time: string;
  };
};
