import { Injectable, NgModule } from '@angular/core';
import { PagedResponse, QueryFiltersParams, QueryPagingParams } from '@models/Paging';
import { User } from '@models/user';
import { Actions, createEffect, EffectsModule, ofType } from '@ngrx/effects';
import { Action, createAction, createSelector, on, props, ReducerManager, Store } from '@ngrx/store';
import { UsersService } from '@services/users/users.service';
import { map, mergeMap, Observable } from 'rxjs';
import { PagingStoreFactory } from '../pagingStoreFactory/pagingStoreFactory';
import { PagingActions, PagingSelectors } from '../pagingStoreFactory/pagingStoreTypes';
import { StoreTypes } from '../storeTypes.enum';
import {
  CustomUserStoreProperties,
  UsersCustomActionTypes,
  UsersCustomSelectorTypes,
  UsersStoreCustomActionsType,
  UsersStoreCustomActionsTypes
} from './usersStore.types';

@Injectable({
  providedIn: 'root'
})
export class UsersStore extends PagingStoreFactory<User, CustomUserStoreProperties> {
  protected storeType = StoreTypes.Users;
  protected selectId = (entity: User): string => {
    return entity.id;
  };

  protected getEntityById(id: string): Observable<User> {
    return this.usersService.getById(id);
  }
  protected getEntities(params: QueryFiltersParams & QueryPagingParams): Observable<PagedResponse<User>> {
    return this.usersService.get(params);
  }

  protected getCustomInitialState() {
    return {
      custom: { adRequestsCount: 0 } as CustomUserStoreProperties
    };
  }

  constructor(public actions$: Actions, public store: Store, public reducerManager: ReducerManager, private usersService: UsersService) {
    super(actions$, store, reducerManager);
    this.initStore();
  }

  // custom types
  public actions: PagingActions<User> & UsersCustomActionTypes;
  public selectors: PagingSelectors<User, CustomUserStoreProperties> & UsersCustomSelectorTypes;

  private getCustomActionType = (actionType: UsersStoreCustomActionsTypes): UsersStoreCustomActionsType =>
    `[${this.storeType}] ${actionType}`;

  // custom actions
  protected prepareCustomActions(): void {
    this.actions.loadADRequests = createAction(this.getCustomActionType(UsersStoreCustomActionsTypes.loadADRequests));
    this.actions.setADRequests = createAction(
      this.getCustomActionType(UsersStoreCustomActionsTypes.setADRequests),
      props<{ adRequestsCount: number }>()
    );
  }

  // custom selectors
  protected prepareCustomSelectors(): void {
    this.selectors.selectADRequests = createSelector(this.selectors.featureSelector, (state) => state.custom?.adRequestsCount ?? 0);
  }

  // custom reducer
  protected prepareCustomReducer(): void {
    this.customReducer = [
      on(this.actions.setADRequests, (state, action) => {
        state.custom.adRequestsCount = action.adRequestsCount;

        return state;
      })
    ];
  }

  // custom Effects
  public loadADRequests$: Observable<Action>;

  protected prepareCustomEffects(): void {
    this.loadADRequests$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.loadADRequests),
        mergeMap(() => {
          return this.usersService.getADRequests().pipe(map((result) => this.actions.setADRequests({ adRequestsCount: result?.length })));
        })
      );
    });
  }
}

@NgModule({
  imports: [EffectsModule.forFeature([UsersStore])]
})
export class UsersStoreModule {}
