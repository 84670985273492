import { createAction, props } from '@ngrx/store';
import { Build } from '@models/build';
import { BuildType } from '@models/BuildType.enum';
import { SettingsType } from '@store/builds/builds.types';
import { StoreTypes } from '../storeTypes.enum';

export const setBuilds = createAction(`[${StoreTypes.Builds}] Set Builds`, props<{ builds: Build[] }>());

export const loadInitialBuilds = createAction(`[${StoreTypes.Builds}] Load Initial Builds`);

export const updateBuilds = createAction(`[${StoreTypes.Builds}] Update Builds`);

export const loadInitialBuildsInProgress = createAction(`[${StoreTypes.Builds}] Load Initial Builds In Progress`);
export const setBuildsInProgress = createAction(`[${StoreTypes.Builds}] Set Builds In Progress`, props<{ buildTypes: BuildType[] }>());
export const addBuildsInProgress = createAction(`[${StoreTypes.Builds}] Add Builds In Progress`, props<{ buildTypes: BuildType[] }>());

export const updateSettings = createAction(`[${StoreTypes.Builds}] Update Settings`, props<{
  settings: SettingsType
}>());

export const updateShowNewVersionsAlertParams = createAction(`[${StoreTypes.Builds}] Update Show New Versions Alert`, props<{
  showBackupUpdateDot?: boolean;
  showRmmUpdateDot?: boolean;
}>());
