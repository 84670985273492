import { DayOfWeek } from './DayOfWeek.enum';
import { RecurType } from './RecurType.enum';
import { WeekNumber } from './WeekNumber.enum';

export class ScheduleSettings {
  constructor() {}
  /* [XmlIgnore] */
  public StopAfter: any;
  public DayOfMonth: number;
  public WeekNumber: WeekNumber;
  public DayOfWeek: DayOfWeek;
  public WeekDays: DayOfWeek[];
  public Seconds: number;
  public Minutes: number;
  public Hour: number;
  public DailyTillMinutes: number;
  public DailyTillHour: number;
  public DailyFromMinutes: number;
  public DailyFromHour: number;
  public DailyRecurrencePeriod: number;
  public DailyRecurrence: boolean;
  public OnceDate: string;
  public RepeatEvery: number;
  public RecurType: RecurType;
  public Enabled: boolean;
  public StopAfterTicks: string;
  public IsScheduled: boolean;
}
