import { GlacierRestoreTiers } from '../../models/base/base-models/plan-glacier-restor-tiers-model';
import { RestoreFileFormModel } from '../../models/restoreFile/restore-file-form-model';
import { ScheduleType } from '../../models/schedule-models';
import { BaseForPlanHelper } from '../bases/base-for-plan-helper';
import { WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { PlanDataFromStepsHelper } from '../plan-data-from-steps-helper/plan-data-from-steps-helper';
import { RestorePlanFromStepsHelper } from '../restore-plan-from-steps-helper/restore-plan-from-steps-helper';

export class RestoreFilePlanFromStepsHelpers extends BaseForPlanHelper {
  public static RestorePlanModel: RestoreFileFormModel = null;

  public static getUpdatedPlan(plan, model: RestoreFileFormModel, settings: WizardHelpersSettings): any {
    this.RestorePlanModel = model;
    this.updateSchedules(
      +ScheduleType[model.scheduleStep.ScheduleType] === ScheduleType.recurring,
      this.RestorePlanModel.scheduleAdvancedStep
    );
    const newPlan = Object.assign({}, plan);
    this.updateModel(newPlan, settings);
    return newPlan;
  }

  static updateModel(newPlan, settings: WizardHelpersSettings): void {
    RestorePlanFromStepsHelper.updateNewPlanByNameStep(newPlan, this.RestorePlanModel.planNameStep);
    PlanDataFromStepsHelper.updateNewPlanByWhereBackupStep(newPlan, this.RestorePlanModel.whereBackupStep);
    RestorePlanFromStepsHelper.updateNewPlanByBackupToRestoreStep(newPlan, this.RestorePlanModel.backupToRestoreStep);
    RestorePlanFromStepsHelper.updateNewPlanByRestorePointStep(newPlan, this.RestorePlanModel.restorePointStep, settings);
    RestorePlanFromStepsHelper.updateNewPlanByRestoreSourceStep(
      newPlan,
      this.RestorePlanModel.restoreSourceStep,
      settings.isLinux,
      settings.currentPrefix
    );
    RestorePlanFromStepsHelper.updateNewPlanByDestinationStep(
      newPlan,
      this.RestorePlanModel.destinationStep,
      null,
      false,
      !settings.isLinux || settings.isNBF
    );

    RestorePlanFromStepsHelper.updateNewPlanByEncryptionOptionsStep(newPlan, this.RestorePlanModel.encryptionOptionsStep);
    RestorePlanFromStepsHelper.updateNewPlanByScheduleStep(
      newPlan,
      this.RestorePlanModel.scheduleStep,
      this.RestorePlanModel.scheduleAdvancedStep,
      settings.isLinux,
      settings.isNBF
    );

    // TODO need update for linux then will be ready task: BACKUPMAC-3829
    if (!settings.isLinux) {
      PlanDataFromStepsHelper.updateNewPlanByPrePostActionsStep(
        newPlan,
        this.RestorePlanModel.prePostActionsStep,
        !settings.allowPrePostActions
      );
    }

    if (settings.isNBF) {
      newPlan.RestorePoint = `CBB_Archive\\${this.RestorePlanModel.backupToRestoreStep.selectedBunch}`;
      if (this.RestorePlanModel.restorePointStep.resultPoint) {
        newPlan.RestorePoint += `\\${this.RestorePlanModel.restorePointStep.resultPoint.restorePointId}`;
      }
    } else newPlan.RestorePoint = null;
    newPlan.RestoreSourcePrefix = this.RestorePlanModel.computerToRestoreStep.computerName;
    newPlan.GlacierRestoreTier = GlacierRestoreTiers.Standard;
    newPlan.LimitRestore = true;
    PlanDataFromStepsHelper.updateNewPlanByNotificationStep(newPlan, this.RestorePlanModel.notificationStep);
    PlanDataFromStepsHelper.preparePlanForSending(newPlan);
  }
}
