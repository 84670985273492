import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AllowOfflineEditModalComponent } from '@components/allow-offline-edit-modal/allow-offline-edit-modal.component';
import { BackupStorageComponent } from '@components/sidepanel-backup/tabs/backup-storage/backup-storage.component';
import { ApplicationStateFacade } from '@facades/application.facade';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import Administrator from '@models/Administrator';
import Computer, { AgentType, AgentVersionStatus, AuthStatus, ComputersMode, OsType } from '@models/Computer';
import { ShortPlanInfo } from '@models/backup/plan-info-model';
import { BackupSidePanelTab } from '@models/backup/sidepanel-tab';
import { UiStorageKey, UiStorageType } from '@models/ui-storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SeverityLevel, addBreadcrumb } from '@sentry/browser';
import { AuthService } from '@services';
import { AbilityService } from 'ability';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { DataChangeWatcherService, MbsPopupType, ModalService, SidepanelComponent, SidepanelCrudBase, SidepanelService } from 'mbs-ui-kit';
import { Observable, combineLatest, filter, interval, noop, of, take, takeWhile } from 'rxjs';
import { map } from 'rxjs/operators';

const tabViewInitTimeout = 500;
const tabViewInitIterations = 10;
export const RMBetaLSKey = 'rm_beta';

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-backup',
  templateUrl: './sidepanel-backup.component.html',
  styles: []
})
export class SidepanelBackupComponent extends SidepanelCrudBase<Computer> implements OnInit {
  @Output() openRMMPanel = new EventEmitter<Computer>();
  @Output() tabChanged = new EventEmitter<string>();

  public readonly AgentType = AgentType;
  public readonly AlertType = MbsPopupType;
  public readonly osType = OsType;

  panelTab = BackupSidePanelTab;
  public fullscreen: boolean;
  public computer: Computer;
  public computerName: string;
  public onlineStatusText: string;
  public online: boolean;
  public hasAgent = Computer.hasAgent;
  public ComputersMode = ComputersMode;
  public offlineAlert = '';
  public disableTabs$: Observable<boolean>;
  public ShowOfflineEditBanner = false;

  public get isBeta(): boolean {
    return this._isBeta;
  }
  public set isBeta(value) {
    if (this._isBeta !== value) {
      this._isBeta = value;

      localStorage.setItem(RMBetaLSKey, JSON.stringify(value));

      addBreadcrumb({
        category: 'sidepanel',
        timestamp: new Date().getTime() / 1000,
        message: `Switch backup sidepanel beta to ${value}`,
        level: 'info' as SeverityLevel
      });
    }
  }

  public get showOfflineEditAlert(): boolean {
    return (
      this.ShowOfflineEditBanner &&
      !this.ability.can('read', 'AllowOfflineSettingsEdit') &&
      !this.computer?.online &&
      Computer.isWindows(this.computer)
    );
  }

  public get isProvider(): boolean {
    return !!this.currentUser?.IsProvider;
  }

  public get providerEmail(): string {
    return this.currentUser?.ProviderInfo?.EmailNotificationsTo;
  }

  public get isAuthorizedComputer(): boolean {
    return this.computer?.authStatus === AuthStatus.Authorized;
  }

  public get backupAgentVersionStatus(): AgentVersionStatus {
    return Computer.getAgentVersionStatus(this.computer, AgentType.Backup);
  }

  public get isOutdatedBackupAgentVersionStatus(): boolean {
    return this.backupAgentVersionStatus === AgentVersionStatus.Outdated;
  }

  public get showOutdatedAgentAlert(): boolean {
    return [AgentVersionStatus.Unsupported, AgentVersionStatus.Outdated].includes(this.backupAgentVersionStatus);
  }

  private currentUser: Administrator;
  private _isBeta: boolean;

  @ViewChild(SidepanelComponent, { static: true }) sidepanelComponent: SidepanelComponent;
  @ViewChild(BackupStorageComponent, { static: false }) storage: BackupStorageComponent;

  constructor(
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    public ability: AbilityService,
    dcws: DataChangeWatcherService, // prettier
    private applicationStateFacade: ApplicationStateFacade,
    private auth: AuthService,
    private backupFacade: ComputerBackupFacade,
    private modalService: ModalService,
    private sidepanelService: SidepanelService
  ) {
    super(dcws);
    this.isBeta = JSON.parse(localStorage.getItem(RMBetaLSKey)) === false ? false : true;
    this.auth.currentUser.subscribe((user) => (this.currentUser = user));
    this.sidepanelService.onClose.pipe(untilDestroyed(this)).subscribe(() => this.closeHandler());
  }

  ngOnInit(): void {
    this.initStreams();
  }

  updateData(): void {
    //
  }

  initStreams(): void {
    const computerOnline$ = this.backupFacade.computerOnline$;
    const agentOnline$ = this.backupFacade.backupAgentOnline$;
    const onlineAndAgentOnline = combineLatest([computerOnline$, agentOnline$]);
    this.disableTabs$ = onlineAndAgentOnline.pipe(map(([online, agentOnline]) => !(online && agentOnline)));

    onlineAndAgentOnline.pipe(untilDestroyed(this)).subscribe(([online, agentOnline]) => {
      let alertText = '';
      if (!agentOnline) alertText = this.getI18Text('serviceUnavailable');
      if (!online) alertText = this.getI18Text('offline');
      this.offlineAlert = alertText;
      this.online = online && agentOnline;
    });

    // refresh template data
    this.backupFacade.computer$.pipe(untilDestroyed(this)).subscribe((computer: Computer) => {
      if (Computer.isAgentOnline(computer, AgentType.Backup)) this.backupFacade.loadGeneralInfo();

      this.computer = computer;
      this.computerName = Computer.getComputerName(computer);
      this.onlineStatusText = Computer.getComputerStatusDurationText(computer, this.i18nPipe, ' ');
    });

    this.applicationStateFacade
      .getUiStorage()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (storage: UiStorageType) => {
          this.ShowOfflineEditBanner = storage[UiStorageKey.ShowOfflineEditBanner] === 'true';
        }
      });
  }

  allowOfflineClickHandler(): void {
    this.applicationStateFacade
      .getUiStorage()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (storage) => {
          const allowOfflineEdit = storage?.AllowOfflineEdit ? JSON.parse(storage.AllowOfflineEdit) : null;
          const settings = { data: { allowOfflineEdit, id: this.currentUser.Id, simpleMode: true } };

          this.modalService.openCustom(AllowOfflineEditModalComponent, settings).then(noop).catch(noop);
        }
      });
  }

  handleAfterTabChange(event) {
    this.tabChanged.emit(event);
  }

  fullScreenChangeHandler(): void {
    this.sidepanelComponent.toggleExpand();
  }

  handleSwitchToStorage(planInfo: ShortPlanInfo) {
    this.tabset.select(BackupSidePanelTab.backupStorage);
    this.tabChanged.emit(BackupSidePanelTab.backupStorage);
    interval(tabViewInitTimeout)
      .pipe(
        take(tabViewInitIterations),
        takeWhile(() => !this.storage, true),
        filter(() => !!this.storage)
      )
      .subscribe(() => this.storage.planDestination$.next(planInfo));
  }

  handleSave(): Observable<boolean> {
    return of(false);
  }
  handleDelete(): Observable<boolean> {
    return of(false);
  }

  closeHandler(): void {
    if (this.fullscreen) this.sidepanelComponent.toggleExpand();
  }

  openRMM(computer: Computer): void {
    this.openRMMPanel.emit(computer);
  }

  private getI18Text(key: string): string {
    return this.i18nPipe.transform(`computers.module:backupSidePanel.${key}`);
  }
}
