<mbs-modal [isCustomModal]="true"
           [loading]='loading'>
  <ng-container modal-header>
    Restore selected files to
    <span class="font-weight-bold ml-1">{{ computerData.name }}</span>
  </ng-container>
  <ng-container modal-body>
    <mbs-alert *ngIf="isEncrypted && !user?.IsProvider && user?.ProviderInfo?.PasswordRecoveryEnabled"
               [icon]="true"
               [type]="mbsPopupType.warning">
      {{'wizards:recoveryAlertFirst' | i18next}}
      <a [href]="'mailto:' + user.ProviderInfo.EmailNotificationsTo">{{user.ProviderInfo.EmailNotificationsTo}}</a>
      {{'wizards:recoveryAlertSecond' | i18next}}
    </mbs-alert>

    <span class="d-block mb-3">
      Selected path: {{currentPath}}
    </span>

    <div [formGroup]="selectedPC">
      <div class="mbs-form-group">
        <mbs-input #selectedPath
                   formControlName="selectedPath"
                   label="Restore to"
                   name="selectedPath"
                   [type]="'text'"
                   readonly
                   (focus)="selectedPath.readonly = false"
                   [autocomplete]="false"
                   (buttonClick)="showComputerTree()"
                   [validationWhitespace]="true"
                   [appendButtons]="[
                     {
                       icon: 'fa fa-ellipsis-h',
                       id: 'change-selected-objects',
                       disabled: false,
                       type: 'secondary'
                     }
                   ]"></mbs-input>
      </div>

      <div *ngIf="isEncrypted"
           class="mbs-form-group">
        <mbs-input label="Enter password"
                   [id]="'PCPassword'"
                   name="PCPassword"
                   [autocomplete]="'off'"
                   [type]="passwordInputType"
                   formControlName="password"
                   (input)="passwordInputHandler()"
                   (buttonClick)="changePasswordType()"
                   [appendButtons]="[
                     {
                       icon: passwordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                       id: 'changeTypePCPassword',
                       disabled: false,
                       type: 'secondary'
                     }
                   ]">
        </mbs-input>
        <mbs-form-group *ngIf="encryptionPasswordHint"
                        class="mt-2">
          <mbs-button [isCtrl]="true"
                      type="primary"
                      (click)="showHint = !showHint">
            {{ 'wizards:pass_hint_btn_text' | i18next: { state: showHint ? 'Hide' : 'Show' } }}
          </mbs-button>
          <div *ngIf="showHint"
               class="font-weight-bold">{{encryptionPasswordHint}}</div>
        </mbs-form-group>

        <ng-container *ngIf="allowedRecovery && isNbf">
          <div class="my-2">
            <app-password-recovery-handler [resultPoint]="{ path: currentPath, restorePoint: restorePoint }"
                                           [hid]="computerData.hid"
                                           [passwordControl]="passwordControlFacade"
                                           [backupVersionUpdated]="backupVersionUpdated"></app-password-recovery-handler>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container modalFooter>
    <button type="button"
            class="btn btn-primary"
            [disabled]="selectedPC && (selectedPC.invalid || selectedPC?.get('selectedPath')?.errors?.required)"
            (click)="save()">Confirm</button>
    <button type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="close()">Cancel</button>
  </ng-container>
</mbs-modal>

<ng-template #treeModalHeader>
  Select a folder on <span class="font-weight-bold ml-1">{{computerData.name}}</span> to restore files
</ng-template>
