// Not to put any values before CBLSES in future, cause it will break logic
export enum NotificationMethod {
  CBLSMTP = 0,
  CBLSES = 1,
  SMTP = 2,
  SES = 3
}

export enum NotificationStatus {
  NotConfigured = 0,
  VerifyEmail = 1,
  ConfigureProductionAccess = 2,
  SendTestEmail = 3,
  Ready = 4
}

export enum NotificationPlanRule {
  None = 0,
  Provider = 1 << 0,
  Administrators = 1 << 1,
  Users = 1 << 2,
  SpecifiedEmails = 1 << 3,
  Full = Provider | Administrators | Users | SpecifiedEmails
}

export enum NotifyToPlatform {
  ServersOnly = 0,
  All = 1
}

export type OldNotificationTypes = 'Failure' | 'Success' | 'Warning';

export class OldNotificationRuleSettings {
  Rule: NotificationPlanRule = NotificationPlanRule.None;
  Admins: string[] = [];
  Emails: string[] = [];
}

export class OldNotificationPerPlanSettings {
  Enabled = false;
  PlanId: string;
  UserId: string;
  Failure: OldNotificationRuleSettings = new OldNotificationRuleSettings();
  Success: OldNotificationRuleSettings = new OldNotificationRuleSettings();
  Warning: OldNotificationRuleSettings = new OldNotificationRuleSettings();
}

export class NotificationRuleSettings {
  rule: NotificationPlanRule = NotificationPlanRule.None;
  admins: string[] = [];
  emails: string[] = [];
}

export class NotificationPlanTypeSettings {
  failure: NotificationRuleSettings = new NotificationRuleSettings();
  warning: NotificationRuleSettings = new NotificationRuleSettings();
  success: NotificationRuleSettings = new NotificationRuleSettings();
}

export class NotificationPerPlanSettings extends NotificationPlanTypeSettings {
  enabled = false;
  planId: string;
}

export class RmmNotificationSettings {
  recipient: any = {
    rule: NotificationPlanRule.None, // this field will be removed. It needed only for backward compatibility with mbs-notifications-checkboxes
    type: NotificationPlanRule.None,
    emails: []
  };
  highCpuUsage = { enabled: false };
  highMemoryUsage = { enabled: false };
  lowDiskSpace = { enabled: false };
  noAntivirus = { enabled: false };
  notUpdated = { enabled: false };
  smartNotHealthy = { enabled: false };
  wrongSystemTime = { enabled: false };
  connectionLoss = { enabled: false, notifyToPlatform: false };
  snmpDevice = { enabled: false, alerts: [] };
  softwareChanges = { enabled: false };
  hardwareChanges = { enabled: false };
  highTemperature = { enabled: false };
}

export class DeepInstNotificationSettings {
  recipient: any = {
    type: NotificationPlanRule.None,
    emails: []
  };
  malwareDetected = { enabled: false };
  malwarePrevented = { enabled: false };
}

export class NotificationSettings {
  allowNotifyEndUsers = false;
  backupNotificationSettings: NotificationPlanTypeSettings = new NotificationPlanTypeSettings();
  restoreNotificationSettings: NotificationPlanTypeSettings = new NotificationPlanTypeSettings();
  rmmAlertNotificationSettings: RmmNotificationSettings = new RmmNotificationSettings();
  deepInstAlertNotificationSettings: DeepInstNotificationSettings = new DeepInstNotificationSettings();
}

export class NotificationCustom extends NotificationSettings {
  companyId: string;
  customBackupNotification = false;
  customRestoreNotification = false;
  customRmmNotification = false;
  customDeepInstNotification = false;
}

export class CBLSettings {
  Status?: NotificationStatus;
}

export class SMTPSettings extends CBLSettings {
  Server: string;
  Port: string;
  SSL: boolean;
  User: string;
  Password: string;
  Enabled?: boolean;
}

export class SESSettings extends CBLSettings {
  Account: string;
  SESAccountError: string;
}

export class NotificationGeneralProvider {
  Status: NotificationStatus;
  Type = NotificationMethod.CBLSES;
  SMTPSettings: SMTPSettings;
  SESSettings: SESSettings;
  CBLSESSettings: CBLSettings;
  EmailFrom: string;
  To: string;
}

export class NotificationProvider extends NotificationSettings {
  General: NotificationGeneralProvider;
}

export interface EditEmailsNotification {
  type: OldNotificationTypes;
  emails: { email: string }[];
}

export enum EmailServiceStatus {
  NotConfigured = 0,
  InProgress = 1, // did not finish configuring
  Ready = 2
}
