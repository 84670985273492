import { PlanTypes } from '@models/PlanTypes.enum';
import { DefaultSelectData } from '@models/SelectData';
import { ISOFormatWithSec, MinDate } from '@utils/constants/c-sharp-constants';
import { onlyDateFormat } from '@utils/date';
import * as moment from 'moment';
import { AzureDataValue, AzureStepValue } from '../../models/azure-models';
import { BackupToRestoreStepValue, IdsForCurrentFormats } from '../../models/backup-to-restore-models';
import { DeletePlan } from '../../models/base/base-models/plan-restore-delete-plan-model';
import { DestinationStepValue } from '../../models/destination-models';
import { EC2DataValue, EC2StepValue } from '../../models/EC2-models';
import { EncryptionOptionsStepValue } from '../../models/encryption-options-models';
import { PartitionsStepValue } from '../../models/partitions-models';
import { PlanNameStepValue } from '../../models/plan-name-models';
import { RestorePointStepValue, RestoreType, SpecificDateGroup } from '../../models/restore-point-models';
import { RestoreSourceStepValue } from '../../models/restore-source-models';
import { TemporaryDataValue, TemporaryInstanceStepValue } from '../../models/temporary-instance-models';
import {
  AzureRestoreTypeEnum,
  DiskImageRestoreTypeEnum,
  EC2RestoreTypeEnum,
  RestoreAsDiskEnum,
  TypeDataStepValue,
  VirtualDiskFileFormat
} from '../../models/type-data-models';
import { DiskVolumeView } from '../../models/what-backup-models';
import { StepsHelpers } from '../steps-helpers';
import { SupportMethodsForStepsFromPlan } from '../support-methods-for-steps-from-plan';

export class RestoreStepsDataFromPlanHelper {
  public static updatePlanNameStep(planName: string, deletePlan: DeletePlan): PlanNameStepValue {
    return {
      Name: planName || '',
      SavePlanInCloud: deletePlan === DeletePlan.Never,
      valid: true
    };
  }

  public static updateFileFormats(formatsList: VirtualDiskFileFormat[]): string[] {
    return formatsList ? formatsList.map((item) => item.DisplayName).sort((a: string, b: string) => (a.length < b.length ? 1 : -1)) : [];
  }

  public static updateTypeDataStep(plan): TypeDataStepValue {
    const typeData = {
      restoreAs: RestoreAsDiskEnum.Physical,
      createAMI: false,
      selectedFileFormat: plan.DiskImageFileFormat ? plan.DiskImageFileFormat.DisplayName : '',
      valid: true
    };
    if (plan.DiskImageRestoreType === DiskImageRestoreTypeEnum.VirtualDisk) typeData.restoreAs = RestoreAsDiskEnum.Virtual;
    else if (plan.DiskImageRestoreType === DiskImageRestoreTypeEnum.RestoreToEC2) {
      if (plan.RestoreToEC2.EC2RestoreType === EC2RestoreTypeEnum.EC2Instance) typeData.restoreAs = RestoreAsDiskEnum.EC2;
      else if (plan.RestoreToEC2.EC2RestoreType === EC2RestoreTypeEnum.EC2Volume) typeData.restoreAs = RestoreAsDiskEnum.EBSVolume;
      else if (plan.RestoreToEC2.EC2RestoreType === EC2RestoreTypeEnum.EC2Image) {
        typeData.restoreAs = RestoreAsDiskEnum.EC2;
        typeData.createAMI = true;
      }
    } else if (plan.DiskImageRestoreType === DiskImageRestoreTypeEnum.RestoreToAzure) {
      typeData.restoreAs =
        plan.RestoreToAzure.AzureRestoreType === AzureRestoreTypeEnum.AzureInstance ? RestoreAsDiskEnum.AVM : RestoreAsDiskEnum.ADD;
    }
    return typeData;
  }

  public static updateAzureStep(plan): AzureStepValue {
    return {
      account: plan.RestoreToAzure.AzureVMConnectionID,
      computerName: plan.RestoreToAzure.InstanceName,
      dataDiskName: plan.RestoreToAzure.InstanceName,
      locations: plan.RestoreToAzure.Location,
      resourceGroup: plan.RestoreToAzure.ResourceGroupName,
      vmSize: plan.RestoreToAzure.VMSize,
      network: plan.RestoreToAzure.NetworkName,
      subnet: plan.RestoreToAzure.SubnetName,
      storage: plan.RestoreToAzure.StorageName,
      container: plan.RestoreToAzure.BucketName,
      bootDiagnosticStorage: plan.RestoreToAzure.BootDiagnosticStorageName,
      valid: true
    };
  }

  public static updateEC2(plan): EC2StepValue {
    return {
      account: plan.RestoreToEC2.EC2ConnectionId,
      region: plan.RestoreToEC2.RegionName,
      availabilityZones: plan.RestoreToEC2.AvailabilityZone,
      instanceType: plan.RestoreToEC2.InstanceType || 't1_micro',
      subnet: plan.RestoreToEC2.SubnetId,
      securityGroup: plan.RestoreToEC2.SecurityGroupId,
      valid: true
    };
  }

  public static updateTemporaryInstance(plan): TemporaryInstanceStepValue {
    return {
      useTemporary: plan.RestoreToEC2TemporaryInstance.IsRemoteRestore,
      account: plan.RestoreToEC2TemporaryInstance.EC2ConnectionId,
      region: plan.RestoreToEC2TemporaryInstance.RegionName,
      availabilityZones: plan.RestoreToEC2TemporaryInstance.AvailabilityZone,
      instanceType: plan.RestoreToEC2TemporaryInstance.InstanceType || 't1_micro',
      subnet: plan.RestoreToEC2TemporaryInstance.SubnetId,
      securityGroup: plan.RestoreToEC2TemporaryInstance.SecurityGroupId,
      ami: plan.RestoreToEC2TemporaryInstance.AMI,
      valid: true
    };
  }

  // TODO NEED ADD Azure EC2 Temporary Instance data
  public static updateAzureData(plan, currentData: AzureDataValue): AzureDataValue {
    return currentData;
  }

  public static updateEC2Data(plan, currentData: EC2DataValue): EC2DataValue {
    return currentData;
  }

  public static updateTemporaryData(plan, currentData: TemporaryDataValue): TemporaryDataValue {
    return currentData;
  }

  public static updatePartitions(diskInfo: any[]): DefaultSelectData[] {
    return diskInfo && diskInfo.length
      ? diskInfo.map((disk) => ({
          value: `${disk.Identity}__${disk.DiskId}__${disk.WindowsVolumeIdentity}`,
          label: `${disk.LengthString} (${disk.UsedSpaceString}) ${disk.MountPointsInfo || disk.Label}`
        }))
      : [];
  }

  public static updatePartitionsStep(plan, disks: DiskVolumeView[]): PartitionsStepValue {
    const diskVolumes: any = (disks || []).filter((disk) =>
      plan.RestoreDiskConfiguration.some(
        (c) => c.SourceDiskId === disk.DiskId && c.RestoreVolumes.some((v) => v.SourceVolumeId.Identity === disk.Identity)
      )
    );
    const restoreConfigs: { [key: string]: any } = {};
    const selectedPartitionsFromRDC = [];
    plan.RestoreDiskConfiguration.forEach((rdc) =>
      rdc.RestoreVolumes.forEach((rv) => {
        restoreConfigs[`${rv.SourceVolumeId.DiskId}__${rv.SourceVolumeId.Identity}`] = rv.ExcludeRules.map((r) => {
          if (r.Folder.startsWith('\\') && r.Folder.split('\\').length === 2) r.Folder = r.Folder.substring(1);
          return r;
        });
        selectedPartitionsFromRDC.push(`${rv.SourceVolumeId.Identity}__${rv.SourceVolumeId.DiskId}`);
      })
    );
    diskVolumes.forEach((x) => (x.identityForUiField = `${x.Identity}__${x.DiskId}__${x.WindowsVolumeIdentity}`));
    return {
      selectedPartitionsFromRDC: selectedPartitionsFromRDC,
      restoreConfigs: restoreConfigs,
      restorePartitions: diskVolumes,
      restoreParentPartitions: plan.RestoreDiskConfiguration,
      convertToMBR: plan.ConvertToMBR,
      valid: true
    };
  }

  public static updateDestinationStep(
    plan,
    notIbb = true,
    volumes: DiskVolumeView[] = [],
    isLinux = false,
    isNBF = false
  ): DestinationStepValue {
    if (notIbb) {
      const result: DestinationStepValue = {
        toOriginalLocation: plan.RestoreToOriginalLocation,
        destinationFolder: !plan.RestoreToOriginalLocation ? plan.Destination : '',
        overwriteExistingFiles: !plan.SkipExistingFiles,
        restoreOnlyNewFiles: !plan.SkipExistingFiles ? plan.OnlyNewFiles : false,
        saveLocationAsDefault: false,
        valid: true
      };

      if (!isLinux) {
        result.restoreNTFSPermissions = plan.RestoreNTFSPermissions;
        result.restoreDeletedFiles = !!plan.RestoreDeletedFiles;
      } else if (isNBF) {
        result.restoreDeletedFiles = !!plan.RestoreDeletedFiles;
      }

      return result;
    } else {
      const existDisks = plan.DiskInfo && plan.DiskInfo.length;
      const foundDisk = existDisks ? plan.DiskInfo.find((disk) => disk.Enabled) : null;
      const diskId = foundDisk ? foundDisk.DiskId || plan.DiskInfo[0].DiskId : '';
      const result: DestinationStepValue = {
        destinationFolder: plan.Destination || '',
        virtualImageName: plan.RestoreDiskConfiguration[0].Name,
        overwriteExistingFiles: plan.OverwriteExisting,
        isPhysical: plan.DiskImageRestoreType !== DiskImageRestoreTypeEnum.VirtualDisk && !plan.SpecificPartitions,
        selectedDisk:
          !plan.SpecificPartitions && plan.RestoreDiskConfiguration && plan.RestoreDiskConfiguration.length
            ? plan.RestoreDiskConfiguration[0].DestinationDiskId
            : diskId,
        selectedSpecificPartitions: [],
        valid: true
      };
      if (plan.DiskImageRestoreType !== DiskImageRestoreTypeEnum.VirtualDisk && plan.SpecificPartitions) {
        const isVolumes = volumes && volumes.length;
        const RestoreVolumes: any[] = [];
        plan.RestoreDiskConfiguration.forEach((rd) => RestoreVolumes.push(...rd.RestoreVolumes));
        result.capacity = +(
          plan.RestoreDiskConfiguration[0].Capacity ? plan.RestoreDiskConfiguration[0].Capacity / 1024 / 1024 / 1024 : 0.02
        ).toFixed(2);
        result.selectedSpecificPartitions = RestoreVolumes.map((v) => {
          let volumeWhat: any = null;
          let volumeTo: any = null;
          if (isVolumes) {
            volumeTo =
              (v.DestinationVolumeId &&
                volumes.find((d) => d.DiskId === v.DestinationVolumeId.DiskId && d.Identity === v.DestinationVolumeId.Identity)) ||
              null;
            volumeWhat = volumes.find((d) => d.DiskId === v.SourceVolumeId.DiskId && d.Identity === v.SourceVolumeId.Identity) || null;
          }
          const id = `${v.SourceVolumeId.Identity}__${v.SourceVolumeId.DiskId}__${volumeWhat ? volumeWhat.WindowsVolumeIdentity : null}`;
          return volumeTo ? `${volumeTo.Identity}__${volumeTo.DiskId}__${volumeTo.WindowsVolumeIdentity}--${v.Length}--${id}` : id;
        }).filter((part) => !!part);
      }
      return result;
    }
  }

  public static updateEncryptionOptionsStep(plan, isCreate = true, isNBF = false): EncryptionOptionsStepValue {
    if (isNBF) {
      return {
        password: isCreate ? '' : plan.EncryptionPassword || '',
        valid: true
      };
    } else {
      return {
        isCryptWithPassword: !!plan.UseEncryption,
        password: isCreate ? '' : plan.EncryptionPassword || '',
        valid: true
      };
    }
  }

  public static updateRestorePoint(plan, notIBB = true, restorePoint = '', offset: number, isLinux = false): RestorePointStepValue {
    const RestorePoint: RestorePointStepValue = {
      restoreType: RestoreType.LatestVersion,
      fromDateTime: { date: '', time: '' } as SpecificDateGroup,
      toDateTime: { date: '', time: '' } as SpecificDateGroup,
      valid: true
    };
    const restoreType = notIBB
      ? plan.RestoreType
      : plan.RestoreType === RestoreType.Manually ||
        plan.RestoreType === RestoreType.BackupPeriod ||
        plan.RestoreType === RestoreType.ModificationPeriod ||
        plan.RestoreType === RestoreType.PointInTime
      ? RestoreType.PointInTime
      : RestoreType.LatestVersion;
    if (restoreType === RestoreType.Manually) {
      if (plan.UseBackupDate) {
        RestorePoint.restoreType = RestoreType.BackupPeriod;
        RestorePoint.fromDateTime = SupportMethodsForStepsFromPlan.setSpecificDateGroup(true, plan.RestoreFromPointInTime, notIBB);
        RestorePoint.toDateTime = SupportMethodsForStepsFromPlan.setSpecificDateGroup(true, plan.PointInTime, notIBB);
      } else RestorePoint.restoreType = RestoreType.Manually;
    } else if (restoreType === RestoreType.LatestVersion) RestorePoint.restoreType = RestoreType.LatestVersion;
    else if (plan.RestoreFromPointInTime === MinDate) {
      RestorePoint.restoreType = plan.RestoreType !== RestoreType.Manually ? RestoreType.PointInTime : RestoreType.Manually;
      if (plan.IsArchive) {
        RestorePoint.fromDateTime = SupportMethodsForStepsFromPlan.setSpecificDateGroup(true, plan.PointInTime, notIBB || plan.IsArchive);
      } else {
        if (plan.RestoreType === RestoreType.Manually && !notIBB) {
          const dateTime = moment.utc(plan.PointInTime, ISOFormatWithSec);
          RestorePoint.manuallyDateTime = {
            date: moment(dateTime.format(onlyDateFormat), onlyDateFormat).toDate(),
            time: dateTime.format('HH:mm:ss')
          };
        } else {
          const dateTime = moment.utc(plan.PointInTime, ISOFormatWithSec).utcOffset(offset);
          RestorePoint.fromDateTime = {
            date: moment(dateTime.format(onlyDateFormat), onlyDateFormat).toDate(),
            time: dateTime.format('HH:mm:ss')
          };
        }
      }
    } else {
      RestorePoint.restoreType = plan.UseBackupDate ? RestoreType.BackupPeriod : RestoreType.ModificationPeriod;
      if (plan.IsArchive) {
        RestorePoint.fromDateTime = SupportMethodsForStepsFromPlan.setSpecificDateGroup(true, plan.RestoreFromPointInTime, notIBB);
        RestorePoint.toDateTime = SupportMethodsForStepsFromPlan.setSpecificDateGroup(true, plan.PointInTime, notIBB);
      } else {
        const fromDate = moment.utc(plan.RestoreFromPointInTime, ISOFormatWithSec).utcOffset(offset);
        const toDate = moment.utc(plan.PointInTime, ISOFormatWithSec).utcOffset(offset);
        RestorePoint.fromDateTime = {
          date: moment(fromDate.format(onlyDateFormat), onlyDateFormat).toDate(),
          time: fromDate.format('HH:mm:ss')
        };
        RestorePoint.toDateTime = { date: moment(toDate.format(onlyDateFormat), onlyDateFormat).toDate(), time: toDate.format('HH:mm:ss') };
      }
    }
    if (notIBB && isLinux) {
      RestorePoint.syncBeforeRun = plan.SyncBeforeRun;
    }
    return RestorePoint;
  }

  public static updateBackupToRestore(plan, planType: PlanTypes, name = '', isLinux = false): BackupToRestoreStepValue {
    return {
      selectedBunch: '',
      backupPlanName:
        name || plan.BackupPlanName || (planType === PlanTypes.RestoreDiskImagePlan ? IdsForCurrentFormats.Ibb : IdsForCurrentFormats.File),
      valid: false
    };
  }

  public static updateRestoreSource(plan, isLinux = false, compName: string): RestoreSourceStepValue {
    const step = {
      folders: [],
      valid: true
    };
    const join = isLinux ? '/' : '\\';
    if (plan.RestoreType === RestoreType.Manually) {
      if (plan.Versions.length) {
        step.folders = [
          ...plan.Versions.map(
            (item) =>
              StepsHelpers.getRootPathWithJoin(item.Path, join, isLinux, compName) +
              (!item.ModifyDate || item.ModifyDate === MinDate ? '' : `${join}${StepsHelpers.getNormalDate(item.ModifyDate)}`)
          )
        ];
      }
      if (plan.Items.length) {
        step.folders = [...step.folders, ...plan.Items.map((item) => StepsHelpers.getRootPathWithJoin(item.Path, join, isLinux, compName))];
      }
    } else {
      step.folders = [...plan.Items.map((item) => StepsHelpers.getRootPathWithJoin(item.Path, join, isLinux, compName))];
      if (plan.Versions.length) {
        step.folders = [
          ...step.folders,
          ...plan.Versions.map((item) => StepsHelpers.getRootPathWithJoin(item.Path, join, isLinux, compName))
        ];
      }
    }
    return step;
  }
}
