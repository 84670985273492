import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NetworkCredentials } from '@models/backup/network-credential';
import { NetworkShareModalData } from '@models/NetworkShareModels';
import { CheckCredentialsResultEnum, NetworkShareViewMode } from '@modules/wizards/models/what-backup-tree-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmCommandsAbstractWrapper } from '@services/rm-commands.wrapper';
import { networkPathValidator } from '@utils/validators';
import { I18NextPipe } from 'angular-i18next';
import { FormsUtil, MbsPopupType, ModalComponent, TableHeader, ToastService } from 'mbs-ui-kit';
import { noop } from 'rxjs';

const showAlertTimeout = 5000;

@UntilDestroy()
@Component({
  selector: 'mbs-add-network-share',
  templateUrl: './add-network-share-modal.component.html'
})
export class AddNetworkShareModalComponent implements OnInit {
  public readonly checkCredentialsResultEnum = CheckCredentialsResultEnum;
  public readonly networkShareViewMode = NetworkShareViewMode;
  public readonly mbsPopupType = MbsPopupType;
  public readonly elementsSelector = {
    name: {
      formBlock: 'formBlock',
      backButton: 'backButton',
      successAlert: 'successAlert',
      errorAlert: 'errorAlert',
      listBlock: 'listBlock',
      listBlockButton: 'listBlockButton',
      formBlockButton: 'formBlockButton'
    }
  };
  public readonly headers: TableHeader[] = [
    { name: 'Path', gridColSize: '42fr', overflow: true },
    { name: 'Login', gridColSize: '42fr', overflow: true },
    { name: '', gridColSize: '62px', headerClass: 'text-right', overflow: true }
  ];
  private readonly separator = ']-[';

  public editPath: NetworkCredentials;
  public viewMode: NetworkShareViewMode = NetworkShareViewMode.list;
  public testResult: CheckCredentialsResultEnum = CheckCredentialsResultEnum.Unknown;
  public canUseShareTree = false;
  public loading = false;
  public needSaveAfterAdd = false;
  public credentialsLoading = false;
  public lowPathArray: string[] = [];
  public networkSharePathArray: NetworkCredentials[] = [];
  public networkCredentialsMap: { [key: string]: NetworkCredentials } = {};
  public form: FormGroup<NetworkShareModalData> = new FormGroup<NetworkShareModalData>({
    path: new FormControl('', [
      Validators.required,
      networkPathValidator,
      this.isSelectedValidator.bind(this),
      this.credentialsValidator.bind(this)
    ]),
    login: new FormControl('', [this.credentialsIsRequired.bind(this), this.credentialsValidator.bind(this)]),
    password: new FormControl('', [this.credentialsValidator.bind(this)])
  });

  private hid: string;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private i18nPipe: I18NextPipe, private rmCommands: RmCommandsAbstractWrapper, private toast: ToastService) {}

  ngOnInit(): void {
    if (!this.baseModal.data) return;

    this.hid = this.baseModal.data.hid;
    this.loading = true;
    this.needSaveAfterAdd = this.baseModal.data.needSaveAfterAdd;

    this.setExistCredentials();
  }

  credentialsValidator(): ValidationErrors | null {
    return this.testResult === CheckCredentialsResultEnum.Error ? { invalidCredentials: true } : null;
  }

  credentialsIsRequired(control: AbstractControl): ValidationErrors | null {
    const valueLow = (this.form?.get('path')?.value || '').toLowerCase();
    const isCreateAndNotValue = !this.editPath && this.viewMode === NetworkShareViewMode.form && !control.value;

    return isCreateAndNotValue && this.lowPathArray.some((path: string) => valueLow === path || valueLow.startsWith(path))
      ? { isRequired: true }
      : null;
  }

  isSelectedValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const error = { isSelected: { message: this.i18nPipe.transform('validation:existing_folder') } };
    const lowString = control.value.toLowerCase();
    const createOrNotEqual = !this.editPath || lowString !== this.editPath.path.toLowerCase();

    return createOrNotEqual && this.networkSharePathArray.some((el: NetworkCredentials) => el.path.toLowerCase() === lowString)
      ? error
      : null;
  }

  testClickHandler(): void {
    if (!this.form.valid) return void this.setTestResult(CheckCredentialsResultEnum.Error);

    this.setCredentialsLoading(true);

    const formValue = this.form.value;
    const params = { path: formValue.path, login: formValue.login, password: formValue.password };

    this.rmCommands
      .checkNetworkCredentials(this.hid, params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.setTestResult(CheckCredentialsResultEnum.Success),
        error: (error) => {
          if (error?.error?.detail) this.toast.error(error.error.detail);

          this.setTestResult(CheckCredentialsResultEnum.Error);
        }
      });
  }

  editItemClickHandler(item: NetworkCredentials): void {
    this.editPath = item;
    this.viewMode = NetworkShareViewMode.form;
    this.form.setValue({ path: item.path, login: item.login, password: '' });
  }

  addNewPathClickHandler(): void {
    this.form.reset({ path: '', login: '', password: '' });
    this.viewMode = NetworkShareViewMode.form;
  }

  backToListClickHandler(): void {
    this.editPath = null;
    this.viewMode = NetworkShareViewMode.list;
  }

  resetTestResult(): void {
    if (this.testResult !== CheckCredentialsResultEnum.Unknown || !this.form.valid) {
      this.testResult = CheckCredentialsResultEnum.Unknown;
      this.form.reset(this.form.getRawValue());
      FormsUtil.triggerValidation(this.form);
    }
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }

  deleteItemClickHandler(item: NetworkCredentials): void {
    const arrayItems = Array.from(this.networkSharePathArray);
    const index = arrayItems.findIndex((el) => el.id === item.id);

    if (~index) {
      this.rmCommands
        .deleteNetworkCredentials(this?.hid, arrayItems[index].path)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            arrayItems.splice(index, 1);
            this.networkSharePathArray = arrayItems;
            this.toast.success();
          },
          error: (err: any) => {
            if (err?.error?.title) {
              this.toast.error(err.error.title, this.i18nPipe.transform('computers.module:modals:networkCredentials'));
            }
          }
        });
    }
  }

  saveClickHandler(): void {
    if (this.editPath && !this.form.valid) return FormsUtil.triggerValidation(this.form);

    const formValue = this.form.value;
    const arrayItems = Array.from(this.networkSharePathArray);
    const index = arrayItems.findIndex((el) => el.id === this.editPath?.id);

    if (~index) {
      this.rmCommands
        .addNetworkCredentials(this?.hid, { ...(formValue as any), check: false })
        .pipe(untilDestroyed(this))
        .subscribe({ next: noop, error: noop });

      arrayItems[index] = { path: formValue.path, login: formValue.login, id: this.editPath.id };
      this.networkSharePathArray = arrayItems;
      this.editPath = null;
    }

    this.baseModal.close();
  }

  addClickHandler(): void {
    FormsUtil.triggerValidation(this.form);

    if (!this.form.valid) return;

    this.setCredentialsLoading(true);

    const formValue = this.form.value;
    const newValue = {
      path: formValue.path,
      login: formValue.login,
      id: this.networkSharePathArray.length - 1 + this.separator + formValue.path.replaceAll(' ', '-')
    };

    this.rmCommands
      .addNetworkCredentials(this?.hid, { ...(formValue as any), check: false })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.networkSharePathArray.push(newValue);
          this.backToListClickHandler();
          this.toast.success();

          if (this.needSaveAfterAdd) this.baseModal.save(newValue);

          this.setCredentialsLoading(false);
        },
        error: () => this.setTestResult(CheckCredentialsResultEnum.Error)
      });
  }

  private setExistCredentials(): void {
    this.rmCommands
      .getNetworkCredentialsList(this.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: NetworkCredentials[]) => {
          result.forEach((item: NetworkCredentials, idx: number) => {
            item.id = item.id || idx + this.separator + item.path.replaceAll(' ', '-');
            this.networkCredentialsMap[item.path] = item;
            this.lowPathArray.push(item.path.toLowerCase());
          });

          this.networkSharePathArray = result;
          this.viewMode = this.networkSharePathArray?.length ? NetworkShareViewMode.list : NetworkShareViewMode.form;
          this.loading = false;
        },
        error: () => (this.loading = false)
      });
  }

  private setCredentialsLoading(state: boolean): void {
    this.credentialsLoading = state;
    this.form[state ? 'disable' : 'enable']();
  }

  private setTestResult(result: CheckCredentialsResultEnum): void {
    this.testResult = result;
    this.setCredentialsLoading(false);
    FormsUtil.triggerValidation(this.form);

    setTimeout(() => (this.testResult = CheckCredentialsResultEnum.Unknown), showAlertTimeout);
  }
}
