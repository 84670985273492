import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LicensesService } from '@services/licenses.service';
import { mediumDateWithoutTimeMoment } from '@utils/date';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { catchError, of, take } from 'rxjs';

@Component({
  selector: 'mbs-disable-auto-renewal-modal',
  templateUrl: './disable-auto-renewal-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisableAutoRenewalModalComponent implements OnInit {
  public loading = false;
  public loadingReleaseDate = false;
  public readonly alertType = MbsPopupType;
  public releaseDate: string;
  public mediumDateWithoutTimeMoment = mediumDateWithoutTimeMoment;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private licensesService: LicensesService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.baseModal.data.id && this.baseModal.data.isPremiumSupport) {
      this.setReleaseDate();
    }
  }

  disable(): void {
    this.loading = true;

    const request = typeof this.baseModal.data.request === 'function' ? this.baseModal.data.request() : this.baseModal.data.request;

    request
      .pipe(
        take(1),
        catchError((error) => {
          this.loading = false;
          return of(error);
        })
      )
      .subscribe((res) => {
        this.loading = false;
        this.cdr.detectChanges();
        if (!res?.error) {
          this.baseModal.save();
        }
      });
  }

  setReleaseDate(): void {
    this.loadingReleaseDate = true;
    this.licensesService
      .calculateReleaseDate(this.baseModal.data.id)
      .pipe(take(1))
      .subscribe((result) => {
        this.releaseDate = result.releaseDate;
        this.loadingReleaseDate = false;
        this.cdr.detectChanges();
      });
  }
}
