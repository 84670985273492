<mbs-wizard [(ngModel)]="currentStep"
            [buttonLoadingMode]="true"
            [canClosed]="!loadingPlan && wizardFinish"
            [isOpenSteps]="!loadingPlan && isEdit"
            [loading]="loadingPlan"
            [ignoreSteps]="[advancedTitle]"
            [stepsOrientation]="orientations"
            [cancelButton]="{ hide: false, text: 'buttons:cancel' | i18next: { format: 'title' } }"
            [saveButton]="{ text: 'buttons:save' | i18next: { format: 'title' } }"
            [stepQueue]="stepQueue"
            [stepsContainerClass]="mainService.isOffline ? 'overflow-hidden' : ''"
            (save)="handleSave()"
            [title]="wizardTitle + ' ' + mainService.compName">
  <div *ngIf="mainService.isOffline"
       prependStep
       class="pl-4 py-2 background-secondary">
    <span class="fa fa-power-off"></span> {{ prependStepText }}
  </div>

  <mbs-wizard-step [title]="'wizards:welcome' | i18next: { format: 'title' }"
                   [valid]="FormModel?.planNameStep?.valid"
                   (next)="planNameStep?.forceValid()">
    <mbs-plan-name-step #planNameStep
                        [(ngModel)]="FormModel.planNameStep"
                        [planTypeText]="planTypeText"></mbs-plan-name-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:where_backup_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.whereBackupStep?.valid"
                   (next)="whereBackupStep?.forceValid()">
    <mbs-where-backup-step #whereBackupStep
                           [(ngModel)]="FormModel.whereBackupStep"
                           (changeStorage)="changeStorageSQLHandler($event)"
                           [Storages]="FormModel?.Storages"></mbs-where-backup-step>
  </mbs-wizard-step>

  <!--Here new steps-->
  <mbs-wizard-step [title]="serverInstanceTitle"
                   [valid]="FormModel?.sqlServerInstanceStep?.valid"
                   (next)="sqlServerInstance?.forceValid(currentStep)">
    <mbs-sql-server-instance-step #sqlServerInstance
                                  [(ngModel)]="FormModel.sqlServerInstanceStep"
                                  (loadInfo)="loadInfoHandler($event)"
                                  (nextStep)="forceNextStepWizard($event, 'sqlServerInstanceStep', serverInstanceTitle)"
                                  [databaseServerInstances]="loadedPlan?.DatabaseServerInstances"></mbs-sql-server-instance-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:sql:sqlDatabasesMenuTitle' | i18next: { format: 'title' }"
                   [valid]="FormModel?.sqlSourceDatabasesStep?.valid"
                   (next)="sqlSourceDatabases?.forceValid(currentStep)">
    <mbs-sql-source-databases-step #sqlSourceDatabases
                                  [(ngModel)]="FormModel.sqlSourceDatabasesStep"></mbs-sql-source-databases-step>
  </mbs-wizard-step>
  <!--End new steps-->

  <mbs-wizard-step *ngIf="showAdvanced"
                   [title]="advancedTitle"
                   [valid]="FormModel?.advancedOptionsStep?.valid"
                   (next)="advancedOptionsStep?.forceValid(currentStep)">
    <mbs-advanced-options-step #advancedOptionsStep
                               [stepFocused]="currentStep?.title === advancedTitle"
                               [(ngModel)]="FormModel.advancedOptionsStep"
                               (changeStorageType)="changeAdvancedStorageHandler($event)"
                               (nextStep)="forceNextStepWizard($event, 'advancedOptionsStep', advancedTitle)"
                               [advancedOptionsFlags]="FormModel?.advancedOptionsFlags"></mbs-advanced-options-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="compressionEncryptionTitle"
                   [valid]="FormModel?.compressionEncryptionStep?.valid"
                   (next)="compressionEncryptionStep?.forceValid(currentStep)">
    <mbs-compression-encryption-step #compressionEncryptionStep
                                     [stepFocused]="currentStep?.title === compressionEncryptionTitle"
                                     [isS3Selected]="FormModel?.advancedOptionsFlags?.UseS3AccelerationPossible"
                                     (nextStep)="forceNextStepWizard($event, 'compressionEncryptionStep', 'Compression & Encryption')"
                                     [(ngModel)]="FormModel.compressionEncryptionStep"></mbs-compression-encryption-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="retentionTitle"
                   [valid]="FormModel?.retentionPolicyStep?.valid"
                   (next)="retentionPolicyStep?.forceValid()">
    <mbs-retention-policy-step #retentionPolicyStep
                               [minPeriod]="minPeriod"
                               [isHybrid]="FormModel.whereBackupStep.IsHybridBackup"
                               [(ngModel)]="FormModel.retentionPolicyStep"></mbs-retention-policy-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="scheduleTitle"
                   [valid]="FormModel?.scheduleStep?.valid"
                   (next)="scheduleStep?.forceValid(currentStep)">
    <mbs-schedule-step #scheduleStep
                       [(ngModel)]="FormModel.scheduleStep"
                       [stepFocused]="currentStep?.title === scheduleTitle"
                       (nextStep)="forceNextStepWizard($event, 'scheduleStep', scheduleTitle)"
                       (changeType)="scheduleTypeChangeHandler($event)"></mbs-schedule-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="!isNBF && FormModel?.scheduleStep?.ScheduleType === 'recurring'"
                   [title]="'wizards:advanced_schedule_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.scheduleAdvancedStep?.valid"
                   (next)="scheduleAdvancedStep?.forceValid()">
    <mbs-schedule-advanced-step #scheduleAdvancedStep
                                [copyOnly]="FormModel.sqlSourceDatabasesStep.copyOnly"
                                [(ngModel)]="FormModel.scheduleAdvancedStep"></mbs-schedule-advanced-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="!isNBF && FormModel?.scheduleStep?.ScheduleType === 'predefined'"
                   [title]="'wizards:simple_schedule_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.simpleScheduleStep?.valid"
                   (next)="simpleScheduleStep?.forceValid()">
    <mbs-simple-schedule-step #simpleScheduleStep
                              [copyOnly]="FormModel.sqlSourceDatabasesStep.copyOnly"
                              [(ngModel)]="FormModel.simpleScheduleStep"></mbs-simple-schedule-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="(mainService.allowPrePostActions | async) ? ('wizards:pre_post_title' | i18next: { format: 'title' }) :
                            ('wizards:pre_post_title_backup_chain' | i18next: { format: 'title' })"
                   [valid]="FormModel?.prePostActionsStep?.valid"
                   (next)="prePostActionsStep?.forceValid()">
    <mbs-pre-post-actions-step #prePostActionsStep
                               [(ngModel)]="FormModel.prePostActionsStep"
                               [ChainPlans]="FormModel?.AvailableMachinePlans"></mbs-pre-post-actions-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:notification_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.notificationStep?.valid">
    <mbs-notifications-step [(ngModel)]="FormModel.notificationStep"
                            (emailsNotificationChange)="emailsNotificationChangeHandler($event)"
                            [notification]="mainService.planNotification"></mbs-notifications-step>
  </mbs-wizard-step>
</mbs-wizard>
