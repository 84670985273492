import { Action, createReducer, on } from '@ngrx/store';
import { BuildsState, initialBuildsState } from '@store/builds/state';
import * as BuildsActions from './actions';
import { isNil } from 'lodash';

const reducer = createReducer(
  initialBuildsState,
  on(BuildsActions.setBuilds, (state, action): BuildsState => {
    return {
      ...state,
      builds: action.builds
    };
  }),
  on(BuildsActions.setBuildsInProgress, (state, action): BuildsState => {
    return {
      ...state,
      buildsInProgress: action.buildTypes
    };
  }),
  on(BuildsActions.addBuildsInProgress, (state, action): BuildsState => {
    return {
      ...state,
      buildsInProgress: [...state.buildsInProgress, ...action.buildTypes]
    };
  }),
  on(BuildsActions.updateSettings, (state, { settings }): BuildsState => {
    return {
      ...state,
      isAutoUpdateEnabled: settings.isAutoUpdateEnabled,
      isSettingsButtonVisible: settings.isSettingsButtonVisible,
      isSandBoxAvailable: settings.isSandBoxAvailable
    };
  }),
  on(BuildsActions.updateShowNewVersionsAlertParams, (state, { showBackupUpdateDot, showRmmUpdateDot }): BuildsState => {
    return {
      ...state,
      showBackupUpdateDot: !isNil(showBackupUpdateDot) ? showBackupUpdateDot : state.showBackupUpdateDot,
      showRmmUpdateDot: !isNil(showRmmUpdateDot) ?  showRmmUpdateDot : state. showRmmUpdateDot,
    }
  })
);

export function buildsReducer(state: BuildsState | undefined, action: Action) {
  return reducer(state, action);
}
