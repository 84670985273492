import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@services/error-handler.service';
import { forbiddenErrorText, invalidCredentialsText, unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { MaxSymbolInBrief } from '@utils/constants/misc-constants';
import { ErrorCode } from '@utils/errorCodes';
import { ToastService } from 'mbs-ui-kit';

@Injectable({ providedIn: 'root' })
export class ThrowErrorWithHandlingHelper {
  constructor(private toastService: ToastService, private errorService: ErrorHandlerService) {}

  init(req: HttpRequest<any>, error: HttpErrorResponse): void {
    const errorResponse = error.error;

    // title required field
    if (errorResponse?.title) {
      this.handleTitleError(errorResponse, error);
    } else {
      this.handleAnyError(errorResponse, error);
    }
  }

  public handleLicenseError() {
    this.errorService.showSubscriptionErrorToast();
  }

  private handleTitleError(errorResponse: any, error: HttpErrorResponse): void {
    if (errorResponse?.detail?.length > MaxSymbolInBrief) {
      const invalidCredentials =
        this.errorService.isRemoteCommand(error) &&
        errorResponse?.method === 'CheckVMSocketLicenses' &&
        errorResponse.detail?.includes('ESXi credential');
      const errorText = invalidCredentials ? invalidCredentialsText : errorResponse.ErrorMessage || errorResponse.detail;

      this.errorService.showToastWithDetails({ error: errorResponse, modalText: errorText });
    } else {
      this.errorService.showErrorToast(errorResponse.detail || errorResponse.title);
    }
  }

  private handleAnyError(errorResponse: any, error: HttpErrorResponse): void {
    if (errorResponse && typeof errorResponse !== 'string') {
      if (this.isGetBackupContentError(errorResponse)) {
        this.toastService.info('No backups to display');
      } else if (this.isOdataError) {
        this.errorService.showErrorToast(errorResponse.value || unidentifiedErrorText, 'Error');
      } else if (this.isGetComputerContentError(errorResponse)) {
        this.errorService.showErrorToast(errorResponse.error || 'The disk is locked.');
      } else if (this.isRemoteCommandError(error) || this.isDeepSyncError(errorResponse)) {
        this.errorService.showErrorToast(errorResponse.message || unidentifiedErrorText);
      } else {
        // Not processed
      }
    } else {
      this.errorService.showErrorToast(error.status === 403 ? forbiddenErrorText : unidentifiedErrorText);
    }
  }

  private isOdataError(errorResponse: any): boolean {
    return errorResponse?.value && errorResponse?.['@odata.context'];
  }

  private isGetBackupContentError(errorResponse: any): boolean {
    return errorResponse.method === 'GetBackupContent' && errorResponse.error.includes("Can't find part of the path: ");
  }

  private isGetComputerContentError(errorResponse: any): boolean {
    return errorResponse.method === 'GetComputerContent';
  }

  private isRemoteCommandError(error: HttpErrorResponse): boolean {
    return error.status === 400 && this.errorService.isRemoteCommand(error);
  }

  private isDeepSyncError(errorResponse: any): boolean {
    return +errorResponse.errorCode !== ErrorCode.DeepSyncIsRequired && +errorResponse.errorCode !== ErrorCode.InvalidDecryptionPassword;
  }
}
