import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuickRestoreSchemaModalComponent } from '@components/quick-restore-schema-modal/quick-restore-schema-modal.component';
import { BuildsFacade } from '@facades/builds.facade';
import { environment } from '@mbs-ui/environments/environment';
import Administrator from '@models/Administrator';
import Brand from '@models/Brand';
import { Build, BuildDetails } from '@models/build';
import { BuildType } from '@models/BuildType.enum';
import { AgentType } from '@models/Computer';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { BrandsService } from '@services/brands.service';
import { BuildService } from '@services/build-service/build.service';
import { AbilityService } from 'ability';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { isEqual, isNil } from 'lodash';
import { MbsPopupType, MbsSize, ModalService, ModalSettings, SidepanelBase, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, map, mergeMap, Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { CopyingStatus, getTabsConfig } from './sidepanel-downloads.constants';

@UntilDestroy()
@Component({
  selector: 'app-sidepanel-global-downloads',
  templateUrl: './sidepanel-downloads.component.html'
})
export class SidepanelDownloadsComponent extends SidepanelBase implements OnInit {
  public readonly agentType = AgentType;
  public readonly environment = environment;
  public readonly keys = Object.keys;
  public readonly alertSize = MbsSize;
  public readonly alertType = MbsPopupType;
  public readonly copyingStatus = CopyingStatus;

  /** Typed template tokens */
  public readonly tabContentToken: {
    builds: Build[];
    type: AgentType;
  };
  public readonly newBuildSectionToken: {
    section: BuildDetails;
    isSandbox: boolean;
  };

  public tabItems = [];
  public buildList = new BehaviorSubject<Build[]>(null);
  public backupBuilds$ = this.getBuildsByAgentType(AgentType.Backup);
  public rmmBuilds$ = this.getBuildsByAgentType(AgentType.RMM);
  public raBuilds$ = this.getBuildsByAgentType(AgentType.RA);
  public ndBuilds$ = this.getBuildsByAgentType(AgentType.NetworkDiscovery);
  public openedPanelIds = [];
  public settingsForm = new FormGroup({
    isSandboxEnabled: new FormControl(),
    isAutoUpdateEnabled: new FormControl()
  });
  public buildsInProgress: BuildType[] = [];
  public licensedBrandList: Brand[] = [];
  public currentUser: Administrator;
  public currentCopingStatus: CopyingStatus = null;
  public isSettingsButtonVisible = null;
  public newVersionsAlerts$ = this.buildsFacade.newVersionsAlerts$;

  get isBuilding(): boolean {
    return !!this.buildsInProgress?.length;
  }

  get isSandboxEnabled(): boolean {
    return this.settingsForm.get('isSandboxEnabled').value;
  }

  @ViewChild('bootableUSBDescription') bootableUSBDescription: TemplateRef<any>;
  @ViewChildren('copyTooltip') copyTooltips: QueryList<NgbTooltip>;

  constructor(
    private buildService: BuildService,
    private auth: AuthService,
    private brandsService: BrandsService,
    private toastService: ToastService,
    private modalService: ModalService,
    private ability: AbilityService,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    public buildsFacade: BuildsFacade
  ) {
    super();
  }

  ngOnInit(): void {
    this.initPanelOpeningSubscription();
    this.initUserSubscription();
    this.initSettingsFormChangesSubscription();
    this.initBuildsSubscriptions();
  }

  public generateBuild(build: Build, isSandboxBuildRequests = false): void {
    this.buildService
      .requestBuild({
        buildsTypeList: [build.mbsBuildType],
        isBuildDestinationSandbox: isSandboxBuildRequests
      })
      .subscribe((res) => this.handleResponse(res));
  }

  public onRemoveBuildHandle(): void {
    this.buildsFacade.updateBuildList();
  }

  public getNewBuildSection(build: Build): BuildDetails {
    return this.isSandboxEnabled ? build?.sandbox : build?.public;
  }

  public onBuildPanelClick({ panelId, opening }: { panelId: string; opening: boolean }) {
    if (opening) {
      this.openedPanelIds = [panelId];
    }
  }

  public onOpenQuickRestoreButtonClick() {
    const settings: ModalSettings = {
      footer: {
        okButton: { show: false },
        cancelButton: { text: this.i18nPipe.transform('buttons:close', { format: 'title' }) }
      },
      data: {
        title: this.i18nPipe.transform('sidepanel-downloads:creteBootableUSBModalTitle')
      }
    };
    const modal = this.modalService.openRef(QuickRestoreSchemaModalComponent, settings);

    modal.componentInstance.downloadLink = this.buildList
      .getValue()
      .find((build) => build.mbsBuildType === BuildType.QuickRestore)?.public?.downloadLink;
    modal.componentInstance.createSchemaParams = {
      ownerId: this.currentUser?.ProviderInfo?.Id,
      CreateRecoveryDisk: 'True'
    };
    modal.componentInstance.bootableUSBDescription = this.bootableUSBDescription;
  }

  public onTabUpdate(event: {nextId: string}): void {
    this.buildsFacade.onTabVisit(event.nextId);
  }

  private getBuildsByAgentType(agentType: AgentType): Observable<Build[]> {
    return this.buildList.pipe(map((builds) => builds?.filter((build) => build.applicationId === agentType)));
  }

  private initUserSubscription(): void {
    this.auth.currentUser.pipe(filter(Boolean), untilDestroyed(this)).subscribe((admin) => {
      this.currentUser = admin;
      this.modifyTabsItems();
    });
  }

  private initPanelOpeningSubscription() {
    this.genericPanel.panelOpened.pipe(untilDestroyed(this)).subscribe(() => {
      this.initBrands().subscribe();
    });
  }

  private initBrands(): Observable<Brand[]> {
    return this.brandsService.getActive().pipe(
      untilDestroyed(this),
      filter((brandList) => !!brandList),
      tap((brandList) => {
        this.licensedBrandList = brandList;
      })
    );
  }

  private initSettingsFormChangesSubscription(): void {
    this.settingsForm.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((first, second) => isEqual(first, second)),
        filter((settings) => !isNil(settings.isSandboxEnabled) && !isNil(settings.isAutoUpdateEnabled)),
        mergeMap((settings) => {
          return this.buildService.updateDownloadSettings(settings);
        })
      )
      .subscribe(() => this.buildsFacade.updateBuildList());
  }

  private initBuildsSubscriptions() {
    this.buildsFacade.builds$.pipe(untilDestroyed(this)).subscribe((builds) => {
      this.buildList.next(builds);
    });

    this.buildsFacade.buildsInProgress$.pipe(untilDestroyed(this)).subscribe((buildsInProgress) => {
      this.buildsInProgress = buildsInProgress;
    });

    this.buildsFacade.buildsSettings$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((first, second) => isEqual(first, second))
      )
      .subscribe(({ isSettingsButtonVisible, isAutoUpdateEnabled, isSandBoxAvailable }) => {
        this.isSettingsButtonVisible = isSettingsButtonVisible;

        this.settingsForm.patchValue(
          {
            isSandboxEnabled: isSandBoxAvailable,
            isAutoUpdateEnabled: isAutoUpdateEnabled
          },
          { emitEvent: false }
        );
      });
  }

  private handleResponse(res): void {
    this.toastService[res.success ? 'success' : 'error'](res.message);
  }

  private modifyTabsItems(): void {
    this.tabItems = getTabsConfig(this.i18nPipe).reduce((acc, item) => {
      if (item.id === AgentType.RA) {
        return this.currentUser.IsProvider || this.ability.can('read', 'ShowRemoteConnection') ? [...acc, item] : [...acc];
      }

      return [...acc, item];
    }, []);
  }
}
