<mbs-modal [title]='"computers.module:addNewUser" | i18next'
           #modal
           [loading]="initialLoading">
  <ng-container modal-body>
    <form [formGroup]="modalForm"
          class="mbs-form_content">
      <mbs-input [label]='"computers.module:userName" | i18next'
                 autocomplete="off"
                 formControlName="userName"></mbs-input>

      <div class="form-row mt-4 align-items-end">
        <div class="col-8">
          <!-- https://stackoverflow.com/questions/15738259/disabling-chrome-autofill -->
          <mbs-input [label]='"common.password" | i18next: { format: "title" }'
                     [showErrorsInTooltip]="true"
                     [placeholder]='"placeholders.password" | i18next'
                     formControlName="password"
                     autocomplete="new-password"
                     [type]="inputType"
                     [appendButtons]="appendButtons"
                     (buttonClick)="onChangePasswordTypeButtonClick()">
          </mbs-input>
        </div>

        <div class="col-4">
          <mbs-button type="secondary"
                      [loading]="passwordGenerationInProgress"
                      (click)="setDefaultPassword()">{{'computers.module:generatePassword' | i18next }}</mbs-button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="onCreateUserButtonClick()"
                [loading]="requestInProcess"
                [disabled]="requestInProcess">
      {{ 'buttons:create' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                (click)="modal.close()">
      {{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
