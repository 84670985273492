export const environment = {
  production: true,
  raDownloadURL: {
    windows: '/downloads/ConnectSetup',
    mac: 'https://apps.apple.com/app/msp360-remote-desktop/id1547429622',
    ios: 'https://apps.apple.com/us/app/msp360-remote-desktop/id1439328781',
    android: 'https://play.google.com/store/apps/details?id=com.msp360.remotedesktop&hl=en&gl=US'
  },
  baseHref: '',
  useSessionToken: true,
  sentry: {
    dsn: 'https://8736ae23fe9246718aa5f571f0ac6e8f@weblog.mspbackups.com/2'
  },
  localePath: '/assets/locales/{{lng}}/{{ns}}.json',
  webRTCServer: 'mbs.connect.ra.cloudberrylab.com'
};
