<div class="mbs-drop-file"
     [ngClass]="{
       '-show-label': showLabel || !imageSrc || !imageMode || error,
       '-disabled': disabledState,
       '-invalid': error
     }"
     [ngStyle]="{
       'width': width,
       'height': height
     }">
  <img *ngIf="imageMode && imageSrc"
       class="mbs-drop-file_img"
       [src]="imageSrc"
       onerror="imageSrc = ''"
       alt="" />
  <label mbsDropFile
         [for]="id"
         [ngClass]="labelClasses"
         class="mbs-drop-file_label mb-0"
         (dragover)="dragOverHandler($event)"
         (dragleave)="dragLeaveHandler($event)"
         (droppedFile)="fileLoadHandler($event)">
    <input class="d-none"
           type="file"
           [accept]="accept"
           [id]="id"
           [disabled]="disabledState"
           (change)="fileLoadHandler($event.target.files)"
           #fileInputElement />
  </label>

  <div *ngIf="clearButton && file && !error"
       class="p-2 mbs-drop-file_clear"
       placement="left"
       [ngbTooltip]="'Delete Image'"
       tooltipClass="tooltip-lg"
       container="body"
       (click)="handleClear($event, fileInputElement)">
    <div class="row no-gutters">
      <div class="col-auto">
        <span class="ico ico-Delete-text"></span>
      </div>
    </div>
  </div>


  <div class="mbs-drop-file_text px-2 w-100"
       [ngClass]="{
         '-text-with-file': (imageMode && imageSrc) || (!imageMode && file),
         '-text-without-file': (imageMode && !imageSrc) || (!imageMode && !file),
         '-horizontal': horizontal
       }">
    <div *ngIf="!hideIconIfError || !error"
         class="mbs-drop-file_icon">
      <span class="fa fa-plus-circle"></span>
    </div>
    <h6 *ngIf="imageMode && labelText"
        class="mt-2 mb-2 mbs-drop-file_title">{{labelText}}</h6>
    <h6 *ngIf="!imageMode && labelText"
        class="mt-2 mb-2 mbs-drop-file_title">{{!file ? labelText : file.name}}</h6>
    <div *ngIf="DropLabelTextTemplate || error"
         class="mbs-drop-file_inner-text">
      <ng-container [ngTemplateOutlet]="DropLabelTextTemplate ? DropLabelTextTemplate.template : ''"></ng-container>
      <span *ngIf="error && !hideTextError"
            class="text-danger">{{error}}</span>
    </div>
  </div>
</div>
