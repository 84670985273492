<mbs-modal [title]="'computers.module:modals.agentUpdateRequiredCaption' | i18next">
  <ng-container modal-body>
    <mbs-alert [type]="alertType"
               [icon]="true"
               class="mb-0">
      <a class="cursor-pointer"
         (click)="openDownloadsSidepanel()">
        {{ 'computers.module:modals.agentUpdateRequiredLinkText' | i18next }}
      </a>
      {{ 'computers.module:modals.agentUpdateRequiredText' | i18next: { osName } }}
    </mbs-alert>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button [type]="'secondary'"
                (click)="baseModal.close()">
      {{ 'app:buttons:close' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
