import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: 'ng-template[templateTyping]' })
export class TypedTemplateDirective<TypeToken> {
  @Input() templateTyping: TypeToken;

  /** Do not remove */
  constructor(private contentTemplate: TemplateRef<TypeToken>) {}

  static ngTemplateContextGuard<TypeToken>(dir: TypedTemplateDirective<TypeToken>, ctx: unknown): ctx is TypeToken {
    return true;
  }
}
