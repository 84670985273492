export interface VirtualDiskFileFormat {
  Name: string;
  VariantName: string;
  DisplayName: string;
  UseBuilder: boolean;
}

export enum DiskImageRestoreTypeEnum {
  PhysicalDisk = 1,
  VirtualDisk = 2,
  File = 4,
  FilesFromDiskImage = 8,
  RestoreToEC2 = 16,
  RestoreToAzure = 32,
  RestoreToGCE = 64,
  RestoreToVMware = 128,
  RestoreToHyperV = 256,
  RestoreToEC2FileLevel = 512
}

export enum EC2RestoreTypeEnum {
  EC2Instance = 0,
  EC2Image = 1,
  EC2Volume = 2
}

export enum AzureRestoreTypeEnum {
  AzureInstance = 0,
  AzureDataDisk = 1
}

export enum RestoreAsDiskEnum {
  Physical = 0,
  Virtual = 1,
  EC2 = 2,
  EBSVolume = 3,
  AVM = 4,
  ADD = 5
}

export interface TypeDataStepValue {
  restoreAs: RestoreAsDiskEnum;
  createAMI: boolean;
  selectedFileFormat: string;
  valid: boolean;
}
