import { Component, forwardRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { FormsUtil, ModalService } from 'mbs-ui-kit';
import { debounceTime } from 'rxjs/operators';
import { ArchiveConsistencyStepValue, RunRestoreVerification } from '../../models/archive-consistency-step-value';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { FormPipeOperators, StepBase } from '../StepBase.class';
import { dynamicMemoryCheck, maxStartupRAM, minStartupRAM, possibleMinMaxMemory } from './archive-consistency-step.utils';

const ArchiveConsistencyStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ArchiveConsistencyStepComponent),
  multi: true
};

const initialValues = {
  min: possibleMinMaxMemory[0],
  max: possibleMinMaxMemory[possibleMinMaxMemory.length - 1]
};

@UntilDestroy()
@Component({
  selector: 'mbs-archive-consistency-step',
  templateUrl: './archive-consistency-step.component.html',
  providers: [ArchiveConsistencyStepValueAccessor]
})
export class ArchiveConsistencyStepComponent extends StepBase<ArchiveConsistencyStepValue> implements OnInit, OnDestroy {
  @Input() restoreVerificationEnabled = true;

  public readonly restoreVerificationRequiresText = this.i18nextPipe.transform('wizards:restore_verification_requires_text', {
    returnObjects: true
  });
  private readonly restoreVerificationControlNames = ['minutes', 'seconds', 'numCpu', 'memoryInMb', 'failureMinutes', 'dynamicMemory'];

  public isHyperVExist: boolean;
  public needCheck = false;
  public possibleMinMaxMemory = possibleMinMaxMemory;
  public minMaxMemoryError: string = null;
  public minStartupRAM = minStartupRAM;
  public maxStartupRAM = maxStartupRAM;

  constructor(
    public ability: AbilityService,
    public mainService: RemoteManagementWizardsService,
    private modalService: ModalService,
    private stepService: WizardStepsService,
    public i18nextPipe: I18NextPipe
  ) {
    super(mainService);
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.initForm();
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    const dynamicMemoryValidator = this.dynamicMemoryValidator.bind(this);

    this.stepForm = new UntypedFormGroup({
      useFullConsistencyCheck: new FormControl(false),
      RunRestoreVerificationOn: new FormControl(0),
      minutes: new FormControl(1),
      seconds: new FormControl(1),
      numCpu: new FormControl(1),
      memoryInMb: new FormControl(1024, [Validators.min(512), Validators.max(65536), dynamicMemoryValidator]),
      failureMinutes: new FormControl(1),
      dynamicMemory: new UntypedFormGroup({
        enabled: new FormControl(false),
        min: new FormControl(initialValues.min, [Validators.required, dynamicMemoryValidator]),
        max: new FormControl(initialValues.max, [Validators.required, dynamicMemoryValidator])
      })
    });

    this.initFormEvents();

    if (!this.isIBBPlan) this.notIbbUpdates();
  }

  protected initFormEvents(): void {
    super.initFormEvents();

    if (this.isIBBPlan) {
      this.stepForm
        .get('RunRestoreVerificationOn')
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe((val) => this.restoreVerificationChangeHandler(val));
    }
  }

  onStepFormChange(value: ArchiveConsistencyStepValue): void {
    if (!this.stepForm.touched && !this.stepForm.dirty) return;

    this.value = {
      ...value,
      valid:
        this.stepForm.valid &&
        !this.minMaxMemoryError &&
        (!value.RunRestoreVerificationOn || this.isRDMode || (!isNil(this.isHyperVExist) && !this.needCheck))
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm && changes.restoreVerificationEnabled) {
      const form = this.stepForm.get('RunRestoreVerificationOn');

      form[changes.restoreVerificationEnabled.currentValue ? 'enable' : 'disable']();
      this.restoreVerificationChangeHandler(form.value);
    }
  }

  updateForm(value: ArchiveConsistencyStepValue): void {
    this.stepForm.reset(value);

    if (!this.isRDMode && this.isEdit && value.RunRestoreVerificationOn) this.runTestHyperV(false);

    this.dynamicMemoryChangeHandler(value.dynamicMemory?.enabled);
  }

  forceValid(data: any = null): void {
    const needTestHyperV =
      !this.isRDMode && (isNil(this.isHyperVExist) || (this.needCheck && !this.isHyperVExist)) && this.value.RunRestoreVerificationOn;

    if (!this.minMaxMemoryError && needTestHyperV) {
      this.runTestHyperV();
    } else {
      FormsUtil.triggerValidation(this.stepForm);
    }

    this.resetValidStateForValidFields();
    this.stepForm.markAllAsTouched();
    this.stepForm.updateValueAndValidity();
  }

  dynamicMemoryChangeHandler(value: boolean) {
    this.dynamicMemoryEnable(value);
  }

  needCheckChange(needCheck = true): void {
    this.needCheck = needCheck;
    if (needCheck) this.isHyperVExist = false;
  }

  private notIbbUpdates(): void {
    this.restoreVerificationChangeHandler(RunRestoreVerification.None);
    this.stepForm.get('RunRestoreVerificationOn').disable();
  }

  private dynamicMemoryValidator(): ValidationErrors | null {
    if (!this.stepForm?.get('dynamicMemory')?.get('enabled')?.value) {
      return void delete this.minMaxMemoryError;
    }

    const min = this.stepForm.get('dynamicMemory').get('min').value;
    const max = this.stepForm.get('dynamicMemory').get('max').value;
    const startupRAM = this.stepForm.get('memoryInMb').value;
    const validationResult = dynamicMemoryCheck(min || 0, max || 0, startupRAM);

    if (validationResult) {
      this.minMaxMemoryError = this.i18nextPipe.transform('wizards:dynamic_memory_error_message', validationResult);
      return { minMaxMemoryError: true };
    }

    return void delete this.minMaxMemoryError;
  }

  private dynamicMemoryEnable(enable: boolean): void {
    if (enable) {
      this.stepForm.get('dynamicMemory').get('min').enable();
      this.stepForm.get('dynamicMemory').get('max').enable();

      return Object.keys(initialValues).forEach((key) => {
        if (!this.stepForm.get('dynamicMemory').get(key).value) {
          this.stepForm.get('dynamicMemory').get(key).setValue(initialValues[key]);
        }
      });
    }

    this.stepForm.get('dynamicMemory').get('min').disable();
    this.stepForm.get('dynamicMemory').get('max').disable();
  }

  private restoreVerificationChangeHandler(value: number): void {
    this.toggleFormControls(this.restoreVerificationControlNames, !!value);
    this.dynamicMemoryEnable(!!this.stepForm.get('dynamicMemory').value.enabled);
  }

  private runTestHyperV(needShowModal = true): void {
    this.loadInfo.emit({ loading: true, isHyperV: false });

    this.stepService
      .VMFeatureTest(this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.isHyperVExist = value.data;

          if (needShowModal && !value.data) this.stepService.showHyperVInfoModal();

          this.needCheck = !needShowModal && !value.data;
          this.loadInfo.emit({ loading: false, isHyperV: value.data });
          this.stepForm.markAllAsTouched();
          this.stepForm.updateValueAndValidity();
        },
        error: () => this.loadInfo.emit({ loading: false, isHyperV: false })
      });
  }
}
