import Computer from '@models/Computer';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { FeaturePrefix } from './const';
import { SummaryComputer } from './summary-computer.model';

export const setCurrentComputer = createAction(`${FeaturePrefix} Set Current Computer`, props<{ current: Computer }>());

export const loadSummaryComputers = createAction(
  `${FeaturePrefix} Load SummaryComputers`,
  props<{ summaryComputers: SummaryComputer[] }>()
);

export const loadSummaryComputer = createAction(`${FeaturePrefix} Load SummaryComputer`, props<{ hid: Computer['hid']; optional?: any }>());

export const loadSummaryComputerSuccess = createAction(
  `${FeaturePrefix} Load SummaryComputer Success`,
  props<{ summaryComputer: SummaryComputer }>()
);

export const loadSummaryComputersSuccess = createAction(
  `${FeaturePrefix} Load SummaryComputers Success`,
  props<{ summaryComputer: SummaryComputer[] }>()
);

export const handleLoadError = createAction(`${FeaturePrefix} Load SummaryComputers Success`, props<{ error: any }>());

export const addSummaryComputer = createAction(`${FeaturePrefix} Add SummaryComputer`, props<{ summaryComputer: SummaryComputer }>());

export const upsertSummaryComputer = createAction(`${FeaturePrefix} Upsert SummaryComputer`, props<{ summaryComputer: SummaryComputer }>());

export const addSummaryComputers = createAction(`${FeaturePrefix} Add SummaryComputers`, props<{ summaryComputers: SummaryComputer[] }>());

export const upsertSummaryComputers = createAction(
  `${FeaturePrefix} Upsert SummaryComputers`,
  props<{ summaryComputers: SummaryComputer[] }>()
);

export const updateSummaryComputer = createAction(
  `${FeaturePrefix} Update SummaryComputer`,
  props<{ summaryComputer: Update<SummaryComputer> }>()
);

export const updateSummaryComputers = createAction(
  `${FeaturePrefix} Update SummaryComputers`,
  props<{ summaryComputers: Update<SummaryComputer>[] }>()
);

export const deleteSummaryComputer = createAction(`${FeaturePrefix} Delete SummaryComputer`, props<{ id: string }>());

export const deleteSummaryComputers = createAction(`${FeaturePrefix} Delete SummaryComputers`, props<{ ids: string[] }>());

export const clearSummaryComputers = createAction(`${FeaturePrefix} Clear SummaryComputers`);
