import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AddCompanyModalComponent } from '@components/add-company/add-company-modal.component';
import { ComputerAuthorizationModalMode } from '@components/computers-modal/computer-authorization/computer-authorization-modal.constants';
import { AddUserModalComponent } from '@domains/computers/modals/other/add-user/add-user-modal.component';
import { CompaniesFacade } from '@facades/companies.facade';
import { ComputersFacade } from '@facades/computers.facade';
import { UsersFacade } from '@facades/users.facade';
import Company from '@models/Company';
import Computer from '@models/Computer';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { User } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { FormsUtil, MbsPopupType, ModalComponent, ModalService, ToastService } from 'mbs-ui-kit';
import { EMPTY, Observable, combineLatest, from, of, take } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-computer-authorization-modal',
  templateUrl: './computer-authorization-modal.component.html'
})
export class ComputerAuthorizationModalComponent implements OnInit {
  public readonly alertType = MbsPopupType;
  public readonly computerAuthorizationModalMode = ComputerAuthorizationModalMode;
  public readonly permissionsEnum = PermissionsEnum;

  public loading$: Observable<boolean>;
  public companies: Company[] = null;
  public users: User[] = null;
  public modalForm: FormGroup<{
    company: FormControl<Company | null>;
    user: FormControl<User>;
    assignUserManually: FormControl<boolean>;
  }> = this.fb.group({
    company: [null as Company, [Validators.required]],
    user: [null],
    assignUserManually: [false]
  });
  public formIsValid = true;
  public showAssignUserManually = true;
  public selectedCompanyUsers: User[] = null;
  public computers: Computer[];
  public isGroupOperation = false;
  public groupOperationComputersCount$: Observable<number>;
  public requestInProgress = false;
  public mode = ComputerAuthorizationModalMode.Create;
  public showFakeCompany = false;
  public fakeCompanyId = '-1';
  public fakeCompany: Company = null;

  private get company(): FormControl<Company> {
    return this.modalForm?.get('company') as FormControl;
  }

  private get user(): FormControl<User> {
    return this.modalForm?.get('user') as FormControl;
  }

  private get assignUserManually(): FormControl<boolean> {
    return this.modalForm?.get('assignUserManually') as FormControl;
  }

  public get showAddUserButton(): boolean {
    return (
      !this.isGroupOperation &&
      this.computers.length &&
      this.company?.value?.id !== this.fakeCompanyId &&
      this.ability.can('read', this.permissionsEnum.UsersCreateEdit)
    );
  }

  public get authorizeComputersAlertText$(): Observable<string> {
    return this.groupOperationComputersCount$.pipe(
      map(
        (param) =>
          this.i18n.transform('computers.module:authorizeComputersAlertText', { param }) +
          this.i18n.transform('computers.module:' + (param === 1 ? 'oneComputer' : 'manyComputers'), { param })
      )
    );
  }

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private companiesFacade: CompaniesFacade,
    private fb: FormBuilder,
    private usersFacade: UsersFacade,
    private modalService: ModalService,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe,
    private toastService: ToastService,
    private ability: AbilityService
  ) {}

  ngOnInit(): void {
    this.mode =
      !this.isGroupOperation && this.computers.length && (!!this.computers[0]?.company || !!this.computers[0]?.userAccount?.id)
        ? ComputerAuthorizationModalMode.Edit
        : ComputerAuthorizationModalMode.Create;

    this.showFakeCompany =
      this.isGroupOperation || (this.computers.length && !this.computers[0]?.company && !!this.computers[0]?.userAccount?.id);

    this.companiesFacade.loadData();
    this.usersFacade.loadData();

    this.loading$ = combineLatest([this.companiesFacade.getDataEntityLoading$, this.usersFacade.getDataEntityLoading$]).pipe(
      map(([companiesLoading, usersLoading]) => companiesLoading || usersLoading)
    );

    combineLatest([this.companiesFacade.getDataWhenLoaded$, this.usersFacade.getDataWhenLoaded$])
      .pipe(
        map(([companies, users], index) => ({ companies, users, firstRun: !index })),
        debounceTime(500),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (!this.showFakeCompany) {
          this.companies = data.companies;
        } else {
          this.fakeCompany = {
            id: this.fakeCompanyId,
            name: `[${this.i18n.transform('computers.module:modals.userWithoutCompany')}]`
          } as Company;
          this.companies = [this.fakeCompany, ...data.companies];
        }

        this.users = data.users;

        (this.mode === ComputerAuthorizationModalMode.Edit || !data.firstRun) && this.setEditView(data.firstRun);
      });

    this.createFormSubscriptions();
  }

  public onAddCompanyButtonClick() {
    from(this.modalService.openCustom(AddCompanyModalComponent))
      .pipe(catchError(() => EMPTY))
      .subscribe((company) => {
        this.company.patchValue(company as Company);
        this.company.markAsDirty();
      });
  }

  public onAddUserButtonClick() {
    from(
      this.modalService.openCustom(AddUserModalComponent, {
        data: {
          companyId: this.modalForm.value.company.id,
          hid: this.computers[0].hid
        }
      })
    )
      .pipe(catchError(() => EMPTY))
      .subscribe((user) => {
        this.user.patchValue(user as User);
        this.user.markAsDirty();
      });
  }

  public onSaveButtonClick() {
    if (!this.modalForm.valid) {
      FormsUtil.triggerValidation(this.modalForm);
      return;
    }

    if (!this.isGroupOperation && !this.computers.length) {
      this.toastService.error(this.i18n.transform('computers.module:errors.incorrectComputerData'));
      return;
    }

    this.requestInProgress = true;

    of(this.isGroupOperation)
      .pipe(
        switchMap((isGroupOperation) => {
          const companyId = this.company.value.id === this.fakeCompanyId ? null : this.company.value.id;
          const userId = this.assignUserManually.value ? this.user?.value?.id : null;

          if (!isGroupOperation) return this.computersFacade.authorizeComputer(this.computers[0].hid, userId, companyId);

          if (this.computers.length)
            return this.computersFacade.groupAuthorizeComputers(
              this.computers.map((computer) => computer.hid),
              userId,
              companyId
            );

          return this.computersFacade.currentPageRequestParams$(true, true).pipe(
            take(1),
            switchMap((filter) => this.computersFacade.bulkAuthorizeComputers(filter, userId, companyId))
          );
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success();
          this.isGroupOperation && this.computersFacade.invalidate();
          !this.isGroupOperation &&
            this.computers.length &&
            this.computersFacade.loadComputerByHid({ hid: this.computers[0].hid, force: true });
          this.computersFacade.checkOneComputerExist(true);
          this.baseModal.save();
        },
        error: (errorDetails) => {
          if (errorDetails?.status === HttpStatusCode.NotFound) {
            this.companiesFacade.loadData(true);
            this.usersFacade.loadData(true);
          }
          this.requestInProgress = false;
        }
      });
  }

  private createFormSubscriptions(): void {
    if (this.mode === ComputerAuthorizationModalMode.Create || !this.computers[0]?.userAccount?.id) {
      this.modalForm
        .get('assignUserManually')
        .valueChanges?.pipe(untilDestroyed(this))
        .subscribe((value) => {
          const userControl = this.user;
          !value && this.user.patchValue(null);
          this.setUserValidators(value);
          userControl.updateValueAndValidity();
        });
    }

    this.modalForm
      .get('company')
      .valueChanges?.pipe(untilDestroyed(this))
      .subscribe((company) => {
        this.user.patchValue(null);
        this.refreshSelectedCompanyUsers(company);
      });

    this.modalForm.statusChanges
      .pipe(startWith(this.modalForm.status), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((status) => (this.formIsValid = status === 'VALID'));
  }

  private refreshSelectedCompanyUsers(company?: Company): void {
    const companyId = company?.id ?? this.company?.value?.id;

    this.selectedCompanyUsers = this.users.filter((user) =>
      companyId === this.fakeCompanyId ? !user?.company : user?.company?.id === companyId
    );
  }

  private setEditView(initialSet = false): void {
    if (initialSet) {
      this.showAssignUserManually = !this.computers[0]?.userAccount?.id;
      this.assignUserManually.setValue(!!this.computers[0]?.userAccount?.id);
      this.company.setValue(this.fakeCompany ? this.fakeCompany : this.computers[0]?.company);
      this.refreshSelectedCompanyUsers();
      this.user.setValue(this.selectedCompanyUsers?.find((user) => user.id === this.computers[0]?.userAccount?.id) ?? null);
      this.setUserValidators(!!this.computers[0]?.userAccount?.id);
      this.company.markAsDirty();
      this.user.markAsDirty();
    } else {
      const hasPreviousCompanyValue = !!this.company?.value?.id;
      const company = this.companies?.find((company) => company.id === this.company?.value?.id) ?? null;
      const hasNewCompanyValue = !!company;
      const lastUserValue = this.user?.value?.id;
      this.company.setValue(company);
      this.refreshSelectedCompanyUsers();
      if (hasPreviousCompanyValue && !hasNewCompanyValue) {
        this.showAssignUserManually = true;
        this.assignUserManually.setValue(false);
        this.user.setValue(null);
        this.setUserValidators(false);
      } else {
        this.user.setValue(
          this.assignUserManually.value ? this.selectedCompanyUsers?.find((user) => user.id === lastUserValue) ?? null : null
        );
      }
    }
  }

  private setUserValidators(setValidators = true): void {
    setValidators ? this.user.setValidators([Validators.required, this.userEnabled.bind(this)]) : this.user.clearValidators();
    this.user.updateValueAndValidity();
  }

  private userEnabled(control: AbstractControl): ValidationErrors | null {
    const user = this.users.find((user) => control.value?.id === user.id);

    if (!user) return null;

    if (!user.enabled) return { userEnabled: { message: this.i18n.transform('computers.module:modals.userNotEnabled') } };

    if (user.isDeleting) return { userEnabled: { message: this.i18n.transform('computers.module:modals.userIsDeleting') } };

    return null;
  }
}
