<form [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:select_host_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-form-group>
    <mbs-input [id]="'host-server'"
               [validationWhitespace]="true"
               [label]="'wizards:select_host_server' | i18next"
               (change)="resetIsValidCredentials()"
               placeholder="127.0.0.1"
               name="server"
               formControlName="server"></mbs-input>
  </mbs-form-group>

  <mbs-form-group>
    <mbs-input [id]="'host-login'"
               [validationWhitespace]="true"
               [label]="'wizards:select_host_login' | i18next"
               (change)="resetIsValidCredentials()"
               name="login"
               formControlName="login"></mbs-input>
  </mbs-form-group>

  <mbs-form-group>
    <mbs-input #hostPassField
               [label]="'wizards:select_host_password' | i18next"
               name="Password"
               [placeholder]="'wizards:password_placeholder' | i18next"
               formControlName="password"
               [id]="'host-password'"
               [autocomplete]="'off'"
               [type]="passwordInputType"
               (blur)="passwordBlurHandler($event)"
               (buttonClick)="changeTypePassword()"
               readonly
               (focus)="passwordFocusHandler($event); hostPassField.readonly = false"
               (change)="resetIsValidCredentials()"
               [appendButtons]="[
                 {
                   icon: passwordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                   id: 'changeTypePasswordOnEncryptionOptions',
                   disabled: stepForm.get('password')?.value === InitialValue?.password || stepForm.get('password')?.disabled,
                   type: 'secondary'
                 }
               ]"
               [validationWhitespace]="true">
    </mbs-input>
  </mbs-form-group>
</form>
