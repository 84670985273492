import { APP_BASE_HREF } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

export function removeDoubleSlash(url: string): string {
  const removeDoubleSlash = /(:\/)|(\/)+/g;

  return url.replace(removeDoubleSlash, '$1$2');
}

export function convertToClientUrl(rawUrl: string, baseHref = ''): string {
  if (rawUrl.startsWith('~')) {
    rawUrl = rawUrl.substring(2);
  }
  baseHref = baseHref || '';

  const url = `${baseHref}/${rawUrl}`;
  return removeDoubleSlash(url);
}

@Pipe({
  name: 'clientUrl'
})
export class ClientUrlPipe implements PipeTransform {
  constructor(@Inject(APP_BASE_HREF) private baseHref: string) {}

  transform(rawUrl: string, args?: any): any {
    return convertToClientUrl(rawUrl, this.baseHref);
  }
}
