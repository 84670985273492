import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CanPipe } from './ability.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CanPipe],
  exports: [CanPipe]
})
export class AbilityModule {}
