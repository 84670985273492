import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[mbsDropFile]'
})
export class DropFileDirective {
  @Output() droppedFile = new EventEmitter<any>();

  @HostListener('drop', ['$event']) public dropHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.droppedFile.emit(files);
    }
  }
}
