<mbs-modal [title]='"computers.module:addNewCompany" | i18next'
           #modal>
  <ng-container modal-body>
    <form [formGroup]="modalForm"
          class="mbs-form_content">
      <mbs-input formControlName="companyName"
                 [label]='"computers.module:companyName" | i18next'
                 [placeholder]='"computers.module:addNewCompanyInputPlaceholder" | i18next'
                 [validationWhitespace]="true"></mbs-input>
    </form>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="onCreateCompanyButtonClick()"
                [loading]="requestInProgress">
      {{ 'buttons:create' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                (click)="modal.close()">
      {{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
