import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AgentType } from '@models/Computer';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';

@UntilDestroy()
@Component({
  selector: 'mbs-bulk-agent-install-confirmation-modal-component',
  templateUrl: './computers-bulk-install-agent-confirmation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputersBulkInstallAgentConfirmationModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public readonly mbsPopupType = MbsPopupType;
  public readonly agentType = AgentType;
  public readonly alertType = AlertType;
  public agent: AgentType;

  constructor(private i18nPipe: I18NextPipe) {}

  public get agentName(): string {
    return this.i18nPipe.transform(`computers.module:${this.agent}Agent`);
  }

  public get confirmationQuestion(): string {
    return this.i18nPipe.transform('computers.module:modals.bulkInstallAgentQuestion',{param: this.agentName});
  }
}
