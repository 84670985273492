import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DateTimeFormatModule } from '@components/date-time-format/date-time-format.module';
import { SmartSearchTemplatesModule } from '@modules/smart-search-templates/smart-search-templates.module';
import { NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UtilsModule } from '@utils/utils.module';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule, SwitcherModule } from 'mbs-ui-kit';
import { AutoRenewalSwitcherComponent } from './components/auto-renewal-switcher/auto-renewal-switcher.component';
import { DisableAutoRenewalModalComponent } from './components/disable-auto-renewal-modal/disable-auto-renewal-modal.component';
import { EnableAutoRenewalModalComponent } from './components/enable-auto-renewal-modal/enable-auto-renewal-modal.component';
import { ManageLicenseAdminModalComponent } from './components/manage-license-admin-modal/manage-license-admin-modal.component';
import { ManageComputerModalComponent } from './components/manage-license-computer-modal/manage-license-computer-modal.component';
import { ReleaseTrialModalComponent } from './components/release-trial-modal/release-trial-modal.component';
import { LicensesPipesModule } from './pipes/licenses-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    FormsModule,
    MbsUiKitModule,
    NgbModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    I18NextModule,
    AbilityModule,
    UtilsModule,
    RouterModule,
    SmartSearchTemplatesModule,
    LicensesPipesModule,
    DateTimeFormatModule,
    SwitcherModule
  ],
  declarations: [
    AutoRenewalSwitcherComponent,
    EnableAutoRenewalModalComponent,
    DisableAutoRenewalModalComponent,
    ManageLicenseAdminModalComponent,
    ManageComputerModalComponent,
    ReleaseTrialModalComponent
  ],
  exports: [
    AutoRenewalSwitcherComponent,
    EnableAutoRenewalModalComponent,
    DisableAutoRenewalModalComponent,
    ManageLicenseAdminModalComponent,
    ManageComputerModalComponent,
    ReleaseTrialModalComponent
  ],
  providers: [DatePipe]
})
export class LicensesSharedModule {}
