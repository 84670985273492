import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { linuxInvalidPath, windowsInvalidPath } from '@utils/constants/folders-path-constants';
import { linuxPathValidator, winPathValidator } from '@utils/validators';
import { I18NextService } from 'angular-i18next';
import { BaseForStepsHelper, DataForPath } from '../../../helpers/bases/base-for-steps-helper';
import { WizardStepsService } from '../../../services/wizard-steps.service';

export type EditedStringType = { value: string; index: number };
export type FilesFoldersData = { enabledFields: boolean; paths: string[]; valid?: boolean };

const FilesFoldersListValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => FilesFoldersListComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-files-folders-list',
  templateUrl: './files-folders-list.component.html',
  providers: [FilesFoldersListValueAccessor]
})
export class FilesFoldersListComponent implements OnInit, OnChanges {
  @Input() showItem = true;
  @Input() needShowEnabled = true;
  @Input() labelForEnabledFields = '';
  @Input() subtitleForEnabledFields = '';
  @Input() isLinux = false;
  public joinForInput = '\\';
  public splitForInput = '\\ ';
  public splitForInputBefore = ' \\';
  public regExpForInputBefore = /[ ]\\/;
  public regExpForInputAfter = /\\[ ]/;
  public newPathForm: UntypedFormGroup;
  public mainForm: UntypedFormGroup;

  public editedString: EditedStringType = null;
  private needValidate = false;

  private myValue: FilesFoldersData = null;
  get value(): FilesFoldersData {
    return this.myValue;
  }
  set value(value: FilesFoldersData) {
    this.myValue = value;
    this.notifyValueChange();
  }

  get pastFromClipboardNotSupported(): boolean {
    return !navigator?.clipboard?.readText;
  }

  constructor(public i18nextService: I18NextService, private stepService: WizardStepsService) {}

  ngOnInit(): void {
    this.newPathForm = new UntypedFormGroup({
      newPath: new FormControl('', [
        this.customRequiredValidator.bind(this),
        this.isLinux ? linuxPathValidator : winPathValidator,
        this.isSelectedValidator.bind(this)
      ])
    });
    this.mainForm = new UntypedFormGroup({
      enabledFields: new FormControl(false),
      paths: new FormControl([], [Validators.required])
    });
    this.newPathForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (!value.newPath) this.needValidate = false;
      this.mainForm.updateValueAndValidity();
    });
    this.mainForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => (this.value = { ...value, valid: this.mainForm.valid }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLinux && changes.isLinux.currentValue) {
      this.joinForInput = '/';
      this.splitForInput = '/ ';
      this.splitForInputBefore = ' /';
      this.regExpForInputBefore = /[ ]\//;
      this.regExpForInputAfter = /\/[ ]/;
    }

    if (this.mainForm && changes.needShowEnabled && !changes.needShowEnabled.currentValue) {
      this.mainForm.get('enabledFields').setValue(true);
    }
  }

  updateForm(value: FilesFoldersData): void {
    if (!value) return;
    this.mainForm.reset(value);
    this.enabledFieldsChangeHandler(value.enabledFields);
  }

  updateValidate(): void {
    this.needValidate = true;
    this.newPathForm.get('newPath').markAsDirty();
    this.newPathForm.get('newPath').markAsTouched();
    this.newPathForm.get('newPath').updateValueAndValidity();
  }

  customRequiredValidator(control: AbstractControl): ValidationErrors | null {
    if (this.mainForm) {
      const paths = this.mainForm.get('paths').value;
      return BaseForStepsHelper.helperForCustomRequired(control, this.needValidate, paths);
    }
    return null;
  }

  isSelectedValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const formArray = this.mainForm.get('paths').value;
      return BaseForStepsHelper.helperForIsSelectedValidator(
        control,
        this.isLinux,
        this.editedString,
        formArray,
        this.i18nextService.t('validation:existing_folder')
      );
    }
    return null;
  }

  enabledFieldsChangeHandler(event): void {
    if (event) {
      this.newPathForm.get('newPath').enable();
      this.mainForm.get('paths').enable();
    } else {
      this.newPathForm.get('newPath').disable();
      this.mainForm.get('paths').disable();
    }
  }

  pasteFromClipboardHandler(error: TemplateRef<any>): void {
    navigator.clipboard
      .readText()
      .then((value) => {
        const strings = BaseForStepsHelper.stringsArrayFromClipboard(value, this.isLinux, this.splitForInput, this.joinForInput);
        const formControl = this.mainForm.get('paths');
        const oldStrings = formControl.value;
        const exist = this.isLinux
          ? strings.some((s) => oldStrings.some((str) => s === str))
          : strings.some((s) => {
              const lowS = s.toLowerCase();
              return oldStrings.some((str) => lowS === str.toLowerCase());
            });
        const invalidPaths = strings.filter((s) => {
          return this.isLinux ? linuxInvalidPath.test(s) : windowsInvalidPath.test(s);
        });
        const valid = !invalidPaths.length && !exist;
        if (valid) {
          let filteredOldStrings = BaseForStepsHelper.getFilteredStrings(oldStrings, strings, this.joinForInput, this.isLinux);
          filteredOldStrings.push(...strings);
          filteredOldStrings = BaseForStepsHelper.filterFoldersIfExistParent(filteredOldStrings, this.joinForInput, this.isLinux);
          formControl.reset(filteredOldStrings);
        } else {
          if (!exist) {
            this.stepService.infoModalShow(
              this.i18nextService.t('validation:invalid', { name: 'Path' }),
              this.i18nextService.t('validation:clipboard_invalid_path', { paths: invalidPaths.join(', ') })
            );
          } else {
            this.stepService.infoModalShow(
              this.i18nextService.t('validation:must_unique', { name: 'path' }),
              this.i18nextService.t('validation:contains_selected_path')
            );
          }
        }
        if (!this.newPathForm.get('newPath').value) this.newPathForm.get('newPath').reset('');
      })
      .catch((_) => this.stepService.infoModalShow(this.i18nextService.t('validation:read_permission'), error));
  }

  newPathsBlur(event): void {
    if (!event.target.value) this.newPathForm.get('newPath').reset('');
  }

  addNewPaths(event): void {
    const strControl = this.newPathForm.get('newPath') as FormControl;
    if (event.id === 'closeNewPathsButton') {
      this.editedString = null;
      this.resetFormAfterChangeStrings();
      return;
    }
    if (BaseForStepsHelper.strControlIsValid(strControl) && event.id === 'newPathsButton') {
      const newStr = strControl.value.trim();
      if (newStr) {
        this.changeExcludeStringsArrays(newStr);
        this.resetFormAfterChangeStrings();
      }
    }
    this.mainForm.markAllAsTouched();
  }

  resetFormAfterChangeStrings(): void {
    this.newPathForm.get('newPath').reset('');
    this.mainForm.get('enabledFields').enable();
    this.mainForm.get('paths').enable();
    this.needValidate = false;
  }

  changeExcludeStringsArrays(newStr): void {
    const folders = BaseForStepsHelper.getFilteredStringsByString(
      this.mainForm.get('paths'),
      newStr,
      this.editedString && this.editedString.value,
      this.isLinux
    );
    if (!this.editedString) this.mainForm.get('paths').reset([...folders, newStr]);
    else {
      BaseForStepsHelper.updateFoldersAfterEdit(newStr, this.editedString.value, folders, this.isLinux);
      this.editedString = null;
      this.mainForm.get('paths').reset([...folders]);
    }
  }

  newPathInputHandler(event): void {
    const dataForPath: DataForPath = {
      isLinux: this.isLinux,
      regExpAfter: this.regExpForInputAfter,
      regExpBefore: this.regExpForInputBefore,
      split: this.splitForInput,
      join: this.joinForInput,
      splitForBefore: this.splitForInputBefore
    };
    const formattedObj = BaseForStepsHelper.getStringPathFormatted(event, dataForPath);
    if (formattedObj && formattedObj.needUpdate) {
      this.newPathForm.get('newPath').patchValue(formattedObj.path);
    }
  }

  editHandler(index: number): void {
    this.mainForm.markAllAsTouched();
    const strControl = this.newPathForm.get('newPath');
    const folders = this.mainForm.get('paths');
    this.editedString = { value: folders.value[index], index };
    strControl.setValue(folders.value[index]);
    this.mainForm.get('enabledFields').disable();
    this.mainForm.get('paths').disable();
  }

  delExcludeRuleField(index: number): void {
    const control = this.mainForm.get('paths');
    const array = Array.from(control.value);
    array.splice(index, 1);
    control.patchValue(array);
    this.newPathForm.get('newPath').updateValueAndValidity();
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: FilesFoldersData): void {
    this.myValue = value;
    this.updateForm(value);
  }

  onChange: (value) => {};
  onTouched: () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
