import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { HyperVModelForStepsHelper } from '@modules/wizards/helpers/hyper-v-model-helpers/hyper-v-model-for-steps-helper';
import { HyperVPlanFromStepsHelper } from '@modules/wizards/helpers/hyper-v-model-helpers/hyper-v-plan-from-steps-helper';
import { HyperVFormModel } from '@modules/wizards/models/HyperV/hyper-v-form-model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ModalService } from 'mbs-ui-kit';
import { StorageConnection } from '@models/storge-connections';
import { WizardsServicesHubService } from '../../services/wizards-services-hub.service';
import { VMWizardBaseClass } from '../vm-wizard-base.class';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';

@UntilDestroy()
@Component({
  selector: 'mbs-hyper-v-wizard',
  templateUrl: './hyper-v-wizard.component.html',
  providers: [
    {
      provide: VMWizardBaseClass,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => HyperVWizard)
    }
  ]
})
export class HyperVWizard extends VMWizardBaseClass {
  public FormModel: HyperVFormModel;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    services: WizardsServicesHubService,
    public i18nPipe: I18NextPipe,
    stepService: WizardStepsService
  ) {
    super(services, i18nPipe, stepService);
    this.FormModel = new HyperVFormModel(this.isLinux);

    this.initSubscribes();
  }

  protected generateFormModel(plan) {
    this.FormModel = HyperVModelForStepsHelper.HyperVModelFromPlan(plan, this.defaultSettings());
  }

  changeHyperVStorageHandler(connection: StorageConnection): void {
    this.changeVMStorageHandler(connection);
  }

  protected loadInfoHandlerFinally() {
    this.cdr.detectChanges();
  }

  protected clearAppAwareData(newPlan) {
    HyperVPlanFromStepsHelper.clearAppAwareData(newPlan);
  }

  handleSave(): void {
    const newPlan = HyperVPlanFromStepsHelper.getUpdatedPlan(this.loadedPlan.plan, this.FormModel, this.defaultSettings());

    this.savePlan(newPlan);
  }
}
