import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProviderData } from '@models/ProviderData';
import { Observable } from 'rxjs';

@Injectable()
export class ProviderService {
  private baseHref = '/api/provider';

  constructor(private http: HttpClient) {}

  public get(id): Observable<ProviderData> {
    return this.http.get<ProviderData>(`${this.baseHref}/${id}`);
  }

  public update(id, payload): Observable<null> {
    return this.http.put<null>(`${this.baseHref}/${id}`, payload);
  }

  public toggleRemoteAccess(targetState: boolean): Observable<null> {
    return this.http.put<null>(`${this.baseHref}/remoteaccess`, { enableRemoteAccess: targetState });
  }

  public setRAMode(id, modeState): Observable<null> {
    return this.http.post<null>(`${this.baseHref}/${id}/switchmode`, { RemoteAssistantOnly: modeState });
  }

  public setPasswordRecoveryState(targetState: boolean, publicKey?, mfa?: string): Observable<any> {
    const params: {
      publicKey?: any;
      mfaCode?: string;
      enabled: boolean;
    } = {
      enabled: targetState
    };
    if (targetState && publicKey) {
      params.publicKey = publicKey;
    }

    if (mfa) {
      params.mfaCode = mfa;
    }

    return this.http.put(`${this.baseHref}/password-recovery`, params);
  }

  public setUseOldConnectSettings(useOldConnectSettings: boolean): Observable<any> {
    const params = { useOldConnectSettings };

    return this.http.put(`${this.baseHref}/useoldconnectsettings`, params);
  }

  public getPasswordRecoveryState(): Observable<{ enabled: boolean; keyDate: string; publicKey: any }> {
    return this.http.get<{ enabled: boolean; keyDate: string; publicKey: any }>(`${this.baseHref}/password-recovery`, {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' })
    });
  }

  public getPasswordRecoveryData(hid: string, backupPath: string): Observable<any> {
    return this.http.post(
      'api/backup-plans/recover-password',
      { hid, backupPath },
      {
        headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' })
      }
    );
  }
}
