import { PlanMode } from '@models/PlanTypes.enum';
import { RestoreFileFormModel } from '../../models/restoreFile/restore-file-form-model';
import { BaseForStepsHelper, WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { RestoreStepsDataFromPlanHelper } from '../restore-steps-data-from-plan-helper/restore-steps-data-from-plan-helper';
import { StepsDataFromPlanHelper } from '../steps-data-from-plan-helper/steps-data-from-plan-helper';
import { SupportMethodsForStepsFromPlan } from '../support-methods-for-steps-from-plan';

export class RestoreFileModelForStepHelpers extends BaseForStepsHelper {
  public static RestoreFileModel: RestoreFileFormModel;

  public static RestoreFileModelFromPlan(plan, settings: WizardHelpersSettings = this.DefaultSettings): RestoreFileFormModel {
    this.RestoreFileModel = new RestoreFileFormModel(settings.isNBF, settings.isLinux);
    const newPlan = (plan && plan.plan) || (plan && plan.Plan);
    if (newPlan) {
      this.setThisValues(plan, settings);
      this.setStorageType(settings.mode, newPlan.HybridID, newPlan.ConnectionID);
      this.updatePlanNameStep(newPlan, settings.compName);
      this.updateWhereBackupStep(newPlan);
      const restorePointArr = newPlan.RestorePoint ? newPlan.RestorePoint.split('\\') : null;
      let backupPlanName = '';
      let restorePoint = '';
      if (restorePointArr?.length) {
        backupPlanName = restorePointArr[1];
        if (restorePointArr.length > 2) restorePoint = restorePointArr[2];
      }
      this.RestoreFileModel.computerToRestoreStep.computerName = newPlan.RestoreSourcePrefix || '';
      this.RestoreFileModel.backupToRestoreStep = RestoreStepsDataFromPlanHelper.updateBackupToRestore(
        newPlan,
        this.planType,
        backupPlanName,
        settings.isLinux
      );
      this.RestoreFileModel.restorePointStep = RestoreStepsDataFromPlanHelper.updateRestorePoint(
        newPlan,
        true,
        restorePoint,
        settings.timeZoneOffset,
        settings.isLinux
      );
      this.RestoreFileModel.restoreSourceStep = RestoreStepsDataFromPlanHelper.updateRestoreSource(
        newPlan,
        this.isLinux,
        settings.compName
      );
      this.RestoreFileModel.destinationStep = RestoreStepsDataFromPlanHelper.updateDestinationStep(
        newPlan,
        true,
        [],
        this.isLinux,
        this.isNBF
      );
      this.RestoreFileModel.encryptionOptionsStep = RestoreStepsDataFromPlanHelper.updateEncryptionOptionsStep(
        newPlan,
        settings.mode === PlanMode.create,
        this.isNBF
      );
      this.RestoreFileModel.scheduleStep = StepsDataFromPlanHelper.updateSchedule(
        newPlan,
        plan.OverdueAfterSuccess,
        this.isCreate,
        this.isLinux,
        true
      );
      this.RestoreFileModel.prePostActionsStep = StepsDataFromPlanHelper.updatePrePostActions(newPlan, true);
      this.RestoreFileModel.notificationStep = StepsDataFromPlanHelper.updateNotification(
        newPlan.Notification,
        newPlan.WindowsEventLogNotification
      );
    }
    return this.RestoreFileModel;
  }

  public static updatePlanNameStep(newPlan, compName: string): void {
    const name = newPlan.Name || SupportMethodsForStepsFromPlan.generatePlanName(compName, this.planType, this.timeZoneOffset);
    this.RestoreFileModel.planNameStep = RestoreStepsDataFromPlanHelper.updatePlanNameStep(name, newPlan.DeletePlan);
  }
  public static updateWhereBackupStep(newPlan): void {
    this.RestoreFileModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(
      newPlan.IsHybridBackup,
      newPlan.HybridID,
      newPlan.ConnectionID
    );
    this.RestoreFileModel.Storages = this.storages;
  }
}
