import { Period } from '@models/Period.enum';
import { IncrementalData } from '../../models/schedule-models';

/**
 * Also, according to BACKUP-13072, calculation of the minimum schedule period should follow this logic:
 * *
 * * Monthly = 30*Repeat every
 * * Weekly, one day selected = 7*Repeat every
 * * Weekly, several days = minimum interval between days.
 * *   e.g. Monday, Tuesday = 1;
 * *   Monday, Wednesday = 2;
 * *   Monday, Thursday = 3;
 * @param {Object} schedule
 * @param {Object} selected
 * @return {boolean}
 */
const isKeepBackupForCorrect: (
  schedule: IncrementalData,
  selected: { count: number; period: number }
) => { error: boolean; count: number; period: number } = (schedule, selected) => {
  const multiplications = {
    [Period.day]: 1,
    [Period.week]: 7,
    [Period.month]: 30,
    [Period.year]: 365
  };
  const isMonthMode = schedule.recurringPeriod === 'Monthly';
  let isInvalidData = false;
  let selectedDaysLength;
  let oneDaySelected;
  let severalDayMinLength;
  let expectedCountInDays: number;
  const selectedCountInDays = selected.count * multiplications[selected.period];

  if (!isMonthMode && schedule.weeklyDayOfWeek) {
    selectedDaysLength = schedule.weeklyDayOfWeek?.filter((checked) => !!checked).length;
    oneDaySelected = selectedDaysLength <= 1;

    if (!oneDaySelected) {
      /*
        Logic:
        We have the schedule for a week.
        After this week will be next week.
        Before this week was another week.
        And all together they - endless line.
        One week schedule looks like [true, false, true, false, false, false, false]
        Replace true with 1 and false with 0.
        So, we have 21 days. Look for patterns.
        11 means we have only 1 day between incremental of FFI. Monday, Tuesday. Or Sunday, Monday (the hardest case)
        101 means we have 2 days between incremental of FFI. Monday, Wednesday
        1001 means we have 3 days between incremental of FFI. Monday, Thursday
       */
      const endlessDataLine = [...schedule.weeklyDayOfWeek, ...schedule.weeklyDayOfWeek, ...schedule.weeklyDayOfWeek]
        .map((value) => (value ? 1 : 0))
        .join('');
      let min = 7; // just the biggest value

      ['11', '101', '1001', '10001', '100001', '1000001'].forEach((seq, i) => {
        if (endlessDataLine.includes(seq)) {
          min = Math.min(min, i + 1);
        }
      });

      severalDayMinLength = min;
    }
  }

  if (isMonthMode) {
    expectedCountInDays = multiplications[Period.month] * schedule.repeatEveryCount;
  } else {
    isInvalidData = !selectedDaysLength;
    expectedCountInDays = oneDaySelected ? multiplications[Period.week] * schedule.repeatEveryCount : severalDayMinLength;
  }

  if (isInvalidData || expectedCountInDays <= selectedCountInDays) {
    return {
      error: false,
      count: null,
      period: null
    };
  }

  return {
    error: true,
    count: isMonthMode ? Math.floor(expectedCountInDays / multiplications[Period.month]) : expectedCountInDays,
    period: isMonthMode ? Period.month : Period.day
  };
};

export { isKeepBackupForCorrect };
