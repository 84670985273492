export enum HostType {
  'VMWare' = 'VMWare',
  'HyperV' = 'HyperV'
}

/**
 * @interface SelectHostStepValue - interface for select host step
 * @property {Boolean} remember - is remember password (need correctly comment text)
 */
export interface SelectHostStepValue {
  server: string;
  login: string;
  password: string;
  remember?: boolean;
  valid?: boolean;
}
