export enum ConnectionType {
  RD = 'RD',
  CMD = 'CMD',
  PS = 'PS',
  SSH = 'Ssh',
  VNC = 'Vnc',
  WebRTC = 'webRTC'
}

export enum PrepareConnectionMode {
  Installation,
  Installed,
  Preparation,
  Ready
}
