import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';
import { computersBackupAdapter, ComputersBackupState } from './state';

export const selectComputerBackupStateFeatureSelector = createFeatureSelector<ComputersBackupState>(StoreTypes.ComputersBackup);

const { selectIds: selectIdsByAdapter, selectEntities: selectEntitiesByAdapter } = computersBackupAdapter.getSelectors();

const selectEntities = createSelector(selectComputerBackupStateFeatureSelector, selectEntitiesByAdapter);
const selectIds = createSelector(selectComputerBackupStateFeatureSelector, selectIdsByAdapter);

export const getSyncProcess = createSelector(selectComputerBackupStateFeatureSelector, (state) => state.syncProcess);

export const selectIsHidExist = (hid: string) => createSelector(selectIds, (ids) => (ids as string[]).includes(hid));
export const selectSelected = createSelector(selectComputerBackupStateFeatureSelector, (state) => state.selected);

// host info
export const selectCurrentHostInfo = createSelector(selectSelected, selectEntities, (hid, entities) => entities[hid]?.hostInfo?.data || []);
export const selectCurrentHostInfoLoading = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.hostInfo?.loading ?? false
);
export const selectCurrentHostInfoLoaded = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => !!entities[hid]?.hostInfo?.loaded ?? false
);
export const selectHostInfoByHid = (hid: string) => createSelector(selectEntities, (entities) => entities[hid]?.hostInfo?.data || []);
export const selectHostInfoByHidLoading = (hid: string) =>
  createSelector(selectEntities, (entities) => entities[hid]?.hostInfo?.loading ?? false);
export const selectHostInfoByHidLoaded = (hid: string) =>
  createSelector(selectEntities, (entities) => !!entities[hid]?.hostInfo?.loaded ?? false);
export const selectIsHostInfoByHidExist = (hid: string) => createSelector(selectEntities, (entities) => !!entities[hid]?.hostInfo?.data);

// destinations
export const selectCurrentDestinations = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.destinations?.data || []
);
export const selectCurrentDestinationsLoading = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.destinations?.loading ?? false
);
export const selectCurrentDestinationsLoaded = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.destinations?.loaded ?? false
);

export const selectDestinationsByHid = (hid: string) =>
  createSelector(selectEntities, (entities) => entities[hid]?.destinations?.data || []);
export const selectDestinationsByHidLoading = (hid: string) =>
  createSelector(selectEntities, (entities) => entities[hid]?.destinations?.loading ?? false);
export const selectDestinationsByHidLoaded = (hid: string) =>
  createSelector(selectEntities, (entities) => !!entities[hid]?.destinations?.loaded ?? false);
export const selectIsDestinationByHidExist = (hid: string) =>
  createSelector(selectEntities, (entities) => !!entities[hid]?.destinations?.data);

// plans
export const selectCurrentPlans = createSelector(selectSelected, selectEntities, (hid, entities) => entities[hid]?.plans?.data || []);
export const selectCurrentPlansLoading = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.plans?.loading ?? false
);
export const selectCurrentPlansLoaded = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.plans?.loaded ?? false
);

export const selectPlansByHid = (hid: string) => createSelector(selectEntities, (entities) => entities[hid]?.plans?.data || []);
export const selectPlansByHidLoading = (hid: string) =>
  createSelector(selectEntities, (entities) => entities[hid]?.plans?.loading ?? false);
export const selectPlansByHidLoaded = (hid: string) =>
  createSelector(selectEntities, (entities) => !!entities[hid]?.plans?.loaded ?? false);
export const selectIsPlansByHidExist = (hid: string) => createSelector(selectEntities, (entities) => !!entities[hid]?.plans?.data);
export const selectIsPlanRunningById = (id: string) =>
  createSelector(selectComputerBackupStateFeatureSelector, (state) => state.plansStatuses[id]?.running ?? false);
export const selectIsPlanSavingById = (id: string) =>
  createSelector(selectComputerBackupStateFeatureSelector, (state) => state.plansStatuses[id]?.saving ?? false);

// availablePlans
export const selectCurrentAvailablePlans = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.availablePlans?.data || []
);
export const selectCurrentAvailablePlansLoading = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.availablePlans?.loading ?? false
);
export const selectCurrentAvailablePlansLoaded = createSelector(
  selectSelected,
  selectEntities,
  (hid, entities) => entities[hid]?.availablePlans?.loaded ?? false
);

export const selectAvailablePlansByHid = (hid: string) =>
  createSelector(selectEntities, (entities) => entities[hid]?.availablePlans?.data || []);
export const selectAvailablePlansByHidLoading = (hid: string) =>
  createSelector(selectEntities, (entities) => entities[hid]?.availablePlans?.loading ?? false);
export const selectAvailablePlansByHidLoaded = (hid: string) =>
  createSelector(selectEntities, (entities) => !!entities[hid]?.availablePlans?.loaded ?? false);

export const selectIsAvailablePlansByHidExist = (hid: string) =>
  createSelector(selectEntities, (entities) => !!entities[hid]?.availablePlans?.data);

// timezone
export const selectCurrentAgentTimezone = createSelector(selectSelected, selectEntities, (hid, entities) => entities[hid]?.timeZoneOffset);
