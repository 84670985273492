export enum PlanStartModesDisplay {
  Regular = 0,
  'Force Synthetic Full Backup' = 1,
  'Restart Failed Backup' = 2,
  'Differential Backup' = 3,
  'Transaction log Backup' = 4,
  'Force Full Backup' = 6
}

export enum PlanStartModesDisplayWithRun {
  Regular = 0,
  'Run Force Synthetic Full Backup' = 1,
  'Run Restart Failed Backup' = 2,
  'Run Differential Backup' = 3,
  'Run Transaction log Backup' = 4,
  'Run Force Full Backup' = 6
}

export enum AvailablePlanStartModes {
  Regular = 0,
  ForceSyntheticFull = 1,
  RestartFailed = 2,
  DiffBackup = 3,
  LogBackup = 4,
  ForceFull = 6
}
