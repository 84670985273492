import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { forbiddenChars, forbiddenFileNames } from './constants/file-name-constants';
import { linuxInvalidPath, networkValidPath, windowsInvalidPath, windowsNetworkInvalidPath } from './constants/folders-path-constants';
import { getFullDateFromDateTimeObject } from './date';

export function isConfirmPasswordValidator(names, formGroup: UntypedFormGroup): void {
  const password = formGroup.controls[names.password];
  const confirmPassword = formGroup.controls[names.confirmPassword];
  const hasOtherErrors = password.errors && !password.errors.notMatch;
  if (password.disabled && confirmPassword.disabled) {
    password.setErrors(null);
    confirmPassword.setErrors(null);
    return;
  }

  if ((confirmPassword.touched || password.touched) && password.value !== confirmPassword.value) {
    confirmPassword.markAsDirty();
    confirmPassword.markAsTouched();
    confirmPassword.setErrors({ notMatch: { message: 'Passwords do not match' } });
  } else {
    !hasOtherErrors && password.setErrors(null);
    confirmPassword.setErrors(null);
  }
}

export function stopAfterMinutesValidator(
  this: { stepForm: AbstractControl },
  fieldName: string,
  control: AbstractControl
): ValidationErrors | null {
  if (this.stepForm && this.stepForm.dirty && !control.disabled && !control.value && !+this.stepForm.get(fieldName).value) {
    return { minValue: true };
  }
  return null;
}

export function afterCurrentDateForDateTimeGroupValidator(formGroup: UntypedFormGroup): ValidationErrors | null {
  if (formGroup.value.date && formGroup.value.time && moment().isAfter(getFullDateFromDateTimeObject(formGroup.value))) {
    return { afterCurrent: { message: 'The date is in the past' } };
  }
  return null;
}

export function winFileNameValidator(control: AbstractControl): ValidationErrors | null {
  const error = { invalidFileName: { message: 'Invalid file name' } };
  if (!control.value || control.value.length > 255 || !forbiddenChars.test(control.value) || forbiddenFileNames.test(control.value)) {
    return error;
  }

  return null;
}

const invalidPathError = 'Invalid path';

export function winPathValidator(control: AbstractControl): ValidationErrors | null {
  const error = { invalidPath: { message: invalidPathError } };
  return windowsInvalidPath.test(control.value) ? error : null;
}

export function winNetworkPathValidator(control: AbstractControl): ValidationErrors | null {
  const error = { invalidPath: { message: invalidPathError } };
  return windowsNetworkInvalidPath.test(control.value) ? error : null;
}

export function linuxPathValidator(control: AbstractControl): ValidationErrors | null {
  const error = { invalidPath: { message: invalidPathError } };
  return linuxInvalidPath.test(control.value) ? error : null;
}

export function networkPathValidator(control: AbstractControl): ValidationErrors | null {
  const error = { invalidPath: { message: invalidPathError } };
  return !networkValidPath.test(control.value) ? error : null;
}

export * from './validators/network.validator';
