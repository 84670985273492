<mbs-modal [title]='baseModal.data?.title || ("quick-restore-modal:title" | i18next)'
           [showFooter]="baseModal?.showFooter">
  <ng-container modal-body>
    <ng-container *ngIf="preparing">
      <p *ngIf="needPreparingTextShow; else loader"><span class="loader loader-lg mr-3"></span>Preparing...</p>
      <ng-template #loader>
        <mbs-loader></mbs-loader>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!preparing || needPreparingTextShow">
      <mbs-alert [class.mb-0]="!isWindows"
                 [type]="mbsPopupType.info"
                 [icon]="true">
        {{ "quick-restore-modal:availabilityAlertText" | i18next }}
      </mbs-alert>

      <ng-container *ngIf="isWindows">
        <ng-container *ngIf="bootableUSBDescription" [ngTemplateOutlet]="bootableUSBDescription">
        </ng-container>

        <p class="my-3">{{ 'quick-restore-modal:clickOpenQuickRestore' | i18next }}</p>
        {{ 'quick-restore-modal:ifNothingHappens' | i18next }}
        <mbs-button (click)="createQuickRestoreSchema()"
                    [isCtrl]="true">
          {{ 'quick-restore-modal:openQuickRestoreButtonText' | i18next }}
        </mbs-button> {{ 'quick-restore-modal:toRunQuickRestoreText' | i18next }}
        <ng-container *ngIf="downloadLink">
          <div class="mt-3">
            {{ 'quick-restore-modal:ifProblemsPersists' | i18next }}
            <mbs-button (click)="downloadQuickRestore()"
                        [isCtrl]="true">
              {{ 'app:common.download' | i18next }}
            </mbs-button>
            {{ 'quick-restore-modal:installManually' | i18next }}
            <mbs-button (click)="createQuickRestoreSchema()"
                        [isCtrl]="true">
              {{ 'quick-restore-modal:openQuickRestoreButtonText' | i18next }}
            </mbs-button> {{ 'quick-restore-modal:toRunQuickRestoreText' | i18next }}<br>
          </div>
        </ng-container>
        <a *ngIf="!bootableUSBDescription && ability.can('read', 'HelpMarketing')"
           href="/AP/Help/restore/quick-restore" target="_blank">
          {{'buttons:learnMore' | i18next}}
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
</mbs-modal>
