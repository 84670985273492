import { Component, forwardRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { VirtualMachine } from '@modules/wizards/models/select-virtual-machines-models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextService } from 'angular-i18next';
import { isEqual } from 'lodash';
import { AdvancedSearchModel, TableHeader } from 'mbs-ui-kit';
import { Subject } from 'rxjs';
import { AppAwareStepValue, AppAwareTableItem, getDefaultAppAwareSettings, ProcessingMode } from '../../models/app-aware-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const AppAwareStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => AppAwareStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-app-aware-step',
  templateUrl: './app-aware-step.component.html',
  providers: [AppAwareStepValueAccessor]
})
export class AppAwareStepComponent extends StepBase<AppAwareStepValue> implements OnInit {
  @Input() selectedMachines: Array<VirtualMachine>;

  public tableData: AppAwareTableItem[] = [];
  public processingModeInfo = this.i18nextService.t('wizards:appAwareTableViewHeaderPMInfo', { returnObjects: true });
  public processingValues = this.i18nextService.t('wizards:appAwarePMDropdown', { returnObjects: true });
  public canReadHelpMarketing = this.ability.can('read', 'HelpMarketing');
  public searchModel: AdvancedSearchModel = {};
  public headers: TableHeader[] = [
    {
      name: this.i18nextService.t('wizards:appAwareTableViewHeaderVM'),
      class: 'w-100',
      overflow: true,
      gridColSize: '60fr',
      gridColMin: '120px'
    },
    {
      headerName: 'ProcessingMode',
      name: '',
      class: '-left',
      overflow: false,
      gridColSize: '15fr',
      gridColMin: '120px'
    }
  ];
  public elementSelectors = {
    id: {
      infoPanel: 'app-aware-info-panel',
      searchString: 'app-aware-search-string',
      tableGrid: 'app-aware-table-grid',
      formDescriptionVMWare: 'app-aware-form-description-vmware',
      formDescriptionHyperV: 'app-aware-form-description-hyperv'
    },
    css: {
      tableGridNameCell: 'app-aware-name-cell',
      tableGridPMCell: 'app-aware-pm-cell',
      tableGridCell: (cell, id) => `${cell} app-aware-${id}-row`,
      editedIcon: 'app-aware-edited-icon',
      processingModeButton: (procMode) => `app-aware-processing-mode-${procMode}`
    }
  };
  private needUpdateTableSubject$: Subject<void> = new Subject();

  constructor(public ability: AbilityService, public mainService: RemoteManagementWizardsService, public i18nextService: I18NextService) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();

    this.needUpdateTableSubject$.subscribe({
      next: () => {
        this.updateTableData();
      }
    });
  }

  initForm(): void {
    this.stepForm = new FormGroup({});

    this.initFormEvents();
  }

  onStepFormChange(value: AppAwareStepValue): void {
    if (this.stepForm.dirty || this.stepForm.touched) {
      this.value = { ...value, valid: this.stepForm.valid };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const selectedMachines = changes?.selectedMachines?.currentValue;
    const selectedMachinesPrev = changes?.selectedMachines?.previousValue;

    if (selectedMachines) {
      const notEmptyMachines = !!selectedMachines?.length;
      const notEqual = !isEqual(selectedMachinesPrev, selectedMachines);

      if (notEmptyMachines && notEqual) {
        this.needUpdateTableSubject$.next(null);
      }
    }
  }

  updateTableData(value?: AdvancedSearchModel): void {
    const words = (value?.words || this.searchModel.words || []).map((v) => v.toLowerCase());
    let result = this.selectedMachines || [];

    if (words.length) {
      result = result.filter((machine) => {
        return words.some((word) => machine.name.toLowerCase().includes(word));
      });
    }

    this.tableData = this.unionMachinesAnsSettings(result);
  }

  private unionMachinesAnsSettings(data: VirtualMachine[]): AppAwareTableItem[] {
    return data.map((vm, i) => {
      const record = this.value?.settings.find((row) => row.VMName === vm.name);

      return { ...(record || getDefaultAppAwareSettings()), ...vm, VMName: vm.name };
    });
  }

  searchModelChange(value: AdvancedSearchModel): void {
    this.updateTableData(value);
  }

  setProcessingMode(tableRecord: AppAwareTableItem, processingMode: ProcessingMode) {
    const record = this.value.settings.find((row) => row.VMName === tableRecord.VMName);

    this.tableData = this.tableData.map((item) => {
      return item.VMName === tableRecord.VMName ? { ...tableRecord, processingMode, edited: true } : item;
    });

    this.value = {
      ...this.value,
      settings: this.value.settings
        .map((item) => {
          return item === record ? { ...record, processingMode } : item;
        })
        .concat(record ? [] : [{ ...getDefaultAppAwareSettings(), VMName: tableRecord.VMName, processingMode }])
    };
  }
}
