import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { StorageType } from '@models/StorageType.enum';
import { StorageConnection } from '@models/storge-connections';
import { VmwareModelForStepsHelper } from '@modules/wizards/helpers/vmware-model-helpers/vmware-model-for-steps-helper';
import { VmwarePlanFromStepsHelper } from '@modules/wizards/helpers/vmware-model-helpers/vmware-plan-from-steps-helper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ModalService } from 'mbs-ui-kit';
import { VmwareFormModel } from '../../models/VMWare/vmware-form-model';
import { WizardsServicesHubService } from '../../services/wizards-services-hub.service';
import { VMWizardBaseClass } from '../vm-wizard-base.class';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';

@UntilDestroy()
@Component({
  selector: 'mbs-vmware-wizard',
  templateUrl: './vmware-wizard.component.html',
  providers: [
    {
      provide: VMWizardBaseClass,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => VMWareWizard)
    }
  ]
})
export class VMWareWizard extends VMWizardBaseClass {
  public FormModel: VmwareFormModel;
  public hostTitle = this.i18nPipe.transform('wizards:select_host_step_title', { format: 'title' });

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    services: WizardsServicesHubService,
    public i18nPipe: I18NextPipe,
    stepService: WizardStepsService
  ) {
    super(services, i18nPipe, stepService);
    this.FormModel = new VmwareFormModel(this.isLinux);

    this.initSubscribes();
  }

  protected generateFormModel(plan) {
    this.FormModel = VmwareModelForStepsHelper.VMWareModelFromPlan(plan, this.defaultSettings());
  }

  changeVMWareStorageHandler(connection: StorageConnection): void {
    this.changeVMStorageHandler(connection);
  }

  protected loadInfoHandlerFinally() {
    this.cdr.detectChanges();
  }

  protected clearAppAwareData(newPlan) {
    VmwarePlanFromStepsHelper.clearAppAwareData(newPlan);
  }

  handleSave(): void {
    const newPlan = VmwarePlanFromStepsHelper.getUpdatedPlan(this.loadedPlan.plan, this.FormModel, this.defaultSettings());

    this.savePlan(newPlan);
  }
}
