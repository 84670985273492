import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Computer, { ComputersResponse, GetComputersParams } from '@models/Computer';
import { ConnectionType } from '@models/ConnectionType';
import { StorageAccountCamelCase } from '@models/StorageAccount';
import { ComputersAbstractService } from '@services/computers.service';
import { Observable } from 'rxjs';

const urlPrefix = 'api/computers';
@Injectable()
export class OnlineAccessComputersService implements Partial<ComputersAbstractService> {
  constructor(private http: HttpClient) {}

  getComputers(params?: GetComputersParams): Observable<ComputersResponse> {
    return this.http.get<ComputersResponse>(urlPrefix, { params });
  }

  getComputerByHid(hid: string): Observable<Computer> {
    return this.http.get<Computer>(`${urlPrefix}/${hid}`);
  }

  getDestinationsByComputerHid(hid: string): Observable<StorageAccountCamelCase[]> {
    return this.http.get<StorageAccountCamelCase[]>(`${urlPrefix}/${hid}/destinations`);
  }

  getConnectionUrl(computer: Computer, payload): Observable<any> {
    return this.http.post<any>(`${urlPrefix}/${computer.hid}/applications/connect/get-connection-url`, payload, {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' })
    });
  }

  isConnectionAllowed(hid: string, connectionType: ConnectionType): Observable<{ result: boolean }> {
    return this.http.get<{ result: boolean }>(`${urlPrefix}/${hid}/applications/connect/is-connection-allowed`, {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' }),
      params: { connectionType }
    });
  }
}
