import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[overlay]'
})
export class OverlayDirective {
  overlayElement;

  @Input() set overlay(isVisible: boolean) {
    if (isVisible) {
      this.renderer.appendChild(this.el.nativeElement, this.overlayElement);
      this.el.nativeElement.style.pointerEvents = 'none';
      this.el.nativeElement.querySelectorAll('button, a').forEach((el) => {
        el.style.pointerEvents = 'none';
      });
    } else {
      this.renderer.removeChild(this.el.nativeElement, this.overlayElement);
      this.el.nativeElement.style.pointerEvents = null;
      this.el.nativeElement.querySelectorAll('button, a').forEach((el) => {
        el.style.pointerEvents = null;
      });
    }
  }

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.overlayElement = this.renderer.createElement('div');
    this.overlayElement.classList.add('overlay');
  }
}
