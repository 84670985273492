import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as SummaryComputerActions from './summary-computer.actions';
import { State, SummaryComputer } from './summary-computer.model';

export const adapter: EntityAdapter<SummaryComputer> = createEntityAdapter<SummaryComputer>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  current: null,
  loading: false,
  access: true
});

export const reducer = createReducer(
  initialState,
  // loaders
  on(SummaryComputerActions.loadSummaryComputers, (state, action) => Object.assign({}, state, { loading: true, access: true })),
  on(SummaryComputerActions.loadSummaryComputer, (state, action) => {
    const update = adapter.upsertOne({ id: action.hid, loading: true } as SummaryComputer, state);
    update.loading = true;
    update.access = true;
    return update;
  }
  ),
  on(SummaryComputerActions.loadSummaryComputerSuccess, (state, action) => Object.assign({}, state, { loading: false })),
  on(SummaryComputerActions.loadSummaryComputersSuccess, (state, action) => Object.assign({}, state, { loading: false })),
  // select
  on(SummaryComputerActions.setCurrentComputer, (state, action) => {
    if (action.current === state.current) return state;
    return Object.assign({}, state, { current: action.current });
  }),
  // entity
  on(SummaryComputerActions.addSummaryComputer, (state, action) => adapter.addOne(action.summaryComputer, state)),
  on(SummaryComputerActions.upsertSummaryComputer, (state, action) => adapter.upsertOne(action.summaryComputer, state)),
  on(SummaryComputerActions.addSummaryComputers, (state, action) => adapter.addMany(action.summaryComputers, state)),
  on(SummaryComputerActions.upsertSummaryComputers, (state, action) => adapter.upsertMany(action.summaryComputers, state)),
  on(SummaryComputerActions.updateSummaryComputer, (state, action) => adapter.updateOne(action.summaryComputer, state)),
  on(SummaryComputerActions.updateSummaryComputers, (state, action) => adapter.updateMany(action.summaryComputers, state)),
  on(SummaryComputerActions.deleteSummaryComputer, (state, action) => adapter.removeOne(action.id, state)),
  on(SummaryComputerActions.deleteSummaryComputers, (state, action) => adapter.removeMany(action.ids, state)),
  on(SummaryComputerActions.loadSummaryComputerSuccess, (state, action) => adapter.upsertOne(action.summaryComputer, state)),
  on(SummaryComputerActions.clearSummaryComputers, state => adapter.removeAll(state)),

  on(SummaryComputerActions.handleLoadError, (state, action) => Object.assign({}, state, { access: action.error.status !== 401 }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
