import { Injectable } from '@angular/core';
import {
  DefaultPersistenceResultHandler,
  EntityAction,
  EntityActionPayload,
  EntityCollection,
  EntityCollectionReducerMethodMap,
  EntityCollectionReducerMethods,
  EntityDefinition,
  EntityDefinitionService
} from '@ngrx/data';
import { Action } from '@ngrx/store';

export interface ExtendedEntityCollection<T = any> extends EntityCollection<T> {
  total?: number;
}
export interface ExtendedEntityActionPayload<P = any> extends EntityActionPayload<P> {
  data?: P;
  total?: number;
}
export interface ExtendedAction<T = any> extends EntityAction<T> {
  payload: ExtendedEntityActionPayload<T>;
}

@Injectable()
export class ExtendedPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return (data: any) => {
      /** Default success action */
      const action: ExtendedAction = actionHandler.call(this, data);

      /** Change payload for query many */
      if (action && data?.total) {
        action.payload.total = data.total;
      }
      if (action && data?.data) {
        action.payload.data = data.data;
      }

      return action;
    };
  }
}

export class ExtendedEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  constructor(public entityName: string, public definition: EntityDefinition<T>) {
    super(entityName, definition);
  }
  protected queryManySuccess(collection: EntityCollection<T>, action: ExtendedAction<T[]>): ExtendedEntityCollection<T> {
    const ec: ExtendedEntityCollection<T> = super.queryManySuccess(collection, action);
    if (action.payload.total) {
      ec.total = action.payload.total;
    }
    return ec;
  }
  protected queryAllSuccess(collection: EntityCollection<T>, action: ExtendedAction<T[]>): ExtendedEntityCollection<T> {
    const ec: ExtendedEntityCollection<T> = super.queryAllSuccess(collection, action);
    if (action.payload.total) {
      ec.total = action.payload.total;
    }
    return ec;
  }
}

@Injectable()
export class ExtendedEntityCollectionReducerMethodsFactory {
  constructor(private entityDefinitionService: EntityDefinitionService) {}
  create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition = this.entityDefinitionService.getDefinition<T>(entityName);
    const methodsClass = new ExtendedEntityCollectionReducerMethods(entityName, definition);
    return methodsClass.methods;
  }
}
