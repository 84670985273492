import { Directive, Inject, Input, Optional } from '@angular/core';
import { TabsetDirectiveToken, TabsetDirectiveType } from '../tokens/tabset.directive.token';

@Directive({
  selector: '[mbsTabsetGroupTrigger]',
  exportAs: 'mbsTabsetGroupTrigger',
  host: {
    '(click)': 'handleClick($event)',
    '[class.mbs-tabset_link]': 'true',
    '[class.mbs-tabset_group-trigger]': 'true'
  }
})
export class TabsetGroupTriggerDirective {
  @Input('mbsTabsetGroupTrigger') id: string;

  constructor(@Optional() @Inject(TabsetDirectiveToken) public parentTabset: TabsetDirectiveType) {}

  handleClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.parentTabset.activateGroup(this.id);
  }
}
