import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { DayOfWeek } from '@models/DayOfWeek.enum';
import { AdvancedRecurTypeText, RecurType } from '@models/RecurType.enum';
import { DailyFrequency, RecurringSchedule } from '@models/ScheduleModel';
import { WeekNumber } from '@models/WeekNumber.enum';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import {
  HoursOrMinutesItems,
  OccursEveryPeriodType,
  ScheduleAdvancedStepValue,
  ScheduleFormSettings
} from '@modules/wizards/models/schedule-advanced-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { FormPipeOperators, StepBase } from '@modules/wizards/steps/StepBase.class';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import {
  AccordionComponent,
  EnumHelper,
  FormsUtil,
  MbsValidators,
  ModalService,
  ModalSettings,
  ShortDateParserFormatter
} from 'mbs-ui-kit';
import { noop } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const ScheduleAdvancedStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ScheduleAdvancedStepComponent),
  multi: true
};

export const getScheduleFormControls = () => {
  return {
    RecurringPeriod: new FormControl('Daily'),
    Occurrence: new FormControl(1),
    DayOfWeek: new FormControl(3),
    DayOfMonthCount: new FormControl(1),
    WeeklyDayOfWeek: new FormArray([]),
    RepeatEveryCount: new FormControl(1),
    StartFromDate: new FormControl(new Date()),
    DailyFreqPeriodOption: new FormControl('OccursAt'),
    OccursAtTime: new FormControl('00:00', MbsValidators.timeValidatorWithoutText),
    OccursEveryCount: new FormControl({ value: 0, disabled: true }),
    OccursEveryPeriod: new FormControl({ value: 'hours', disabled: true }),
    OccursEveryFromTime: new FormControl({ value: '00:00', disabled: true }, MbsValidators.timeValidatorWithoutText),
    OccursEveryToTime: new FormControl({ value: '23:59', disabled: true }, MbsValidators.timeValidatorWithoutText)
  };
};

// export const fullBackup = 'Full Backup Schedule';
export const fullBackup = 'Incremental Backup Schedule';
export const dayOfMonth = 'Day Of Month';

@UntilDestroy()
@Component({
  selector: 'mbs-schedule-advanced-step',
  templateUrl: './schedule-advanced-step.component.html',
  providers: [ScheduleAdvancedStepValueAccessor, { provide: NgbDateParserFormatter, useClass: ShortDateParserFormatter }]
})
export class ScheduleAdvancedStepComponent extends StepBase<ScheduleAdvancedStepValue> implements OnInit {
  @Input() copyOnly: boolean;

  public readonly maxHours = 23;
  public readonly maxMinutes = 1439;

  public scheduleSettingsForm = new UntypedFormGroup({
    forceFull: new UntypedFormGroup(getScheduleFormControls()),
    blockLevel: new UntypedFormGroup(getScheduleFormControls()),
    scheduleDiff: new UntypedFormGroup(getScheduleFormControls()),
    scheduleTLog: new UntypedFormGroup(getScheduleFormControls())
  });

  private myPanelsInfo = {
    forceFull: { tittle: fullBackup, infoString: '', isOpen: false },
    blockLevel: { tittle: 'Block-level Backup Schedule', infoString: '', isOpen: false },
    scheduleDiff: { tittle: 'Differential backup', infoString: '', isOpen: false },
    scheduleTLog: { tittle: 'Transaction log', infoString: '', isOpen: false }
  };

  public get panelsInfo() {
    return this.myPanelsInfo;
  }

  public hoursOrMinutesItems = HoursOrMinutesItems;
  public RecurringPeriodItems = ['Daily', 'Weekly', 'Monthly'];
  public DaysOfWeekEnumArray = EnumHelper.EnumToSelectIndexesArray(DayOfWeek);
  public WeekNumberEnumArray = EnumHelper.EnumToSelectIndexesArray(WeekNumber).sort((a, b) =>
    a.label === WeekNumber[-1] || b.label === 'Penultimate' ? -1 : 0
  );

  public panelNames = [];

  @ViewChild(AccordionComponent, { static: true }) accordion: AccordionComponent;

  constructor(public mainService: RemoteManagementWizardsService, public modalService: ModalService, protected cd: ChangeDetectorRef) {
    super(mainService);
    if (!this.isBackupPlan) {
      if (this.isRestore) {
        this.panelsInfo.forceFull.tittle = 'Schedule Recurring Options';
        this.panelsInfo.blockLevel.tittle = 'Block-level Incremental Restore Schedule';
      } else {
        this.panelsInfo.forceFull.tittle = 'Full Backup Schedule';
        this.panelsInfo.blockLevel.tittle = 'Block-level Incremental Backup Schedule';
      }
    }
  }

  stepFormValidator(formGroup: UntypedFormGroup): ValidationErrors | null {
    const value = formGroup.value;
    for (const key in value) {
      if (this.isScheduleSettingsKey(key) && !isNil(value[key].Enabled)) {
        const schedule = value[key].FormSchedule;
        if (
          value[key].Enabled &&
          schedule &&
          schedule.RecurringPeriod === RecurType.Weekly &&
          !schedule.WeeklyFrequency.DaysOfWeek.length
        ) {
          return { needSelectDay: { message: this.myPanelsInfo[key].tittle } };
        }
      }
    }
    return null;
  }

  ngOnInit(): void {
    if (!this.isLinux) this.RecurringPeriodItems.push('Yearly');

    this.initForm();
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup(
      {
        forceFull: new UntypedFormGroup({
          Enabled: new FormControl(false),
          FormSchedule: new FormControl(null)
        }),
        blockLevel: new UntypedFormGroup({
          Enabled: new FormControl(false),
          FormSchedule: new FormControl(null)
        }),
        scheduleDiff: new UntypedFormGroup({
          Enabled: new FormControl(false),
          FormSchedule: new FormControl(null)
        }),
        scheduleTLog: new UntypedFormGroup({
          Enabled: new FormControl(false),
          FormSchedule: new FormControl(null)
        }),
        ForceFullApplyDiffSizeCondition: new FormControl(false),
        ForceFullDiffSizeCondition: new FormControl(50)
      },
      this.stepFormValidator.bind(this)
    );

    this.initFormEvents();
  }

  protected initFormEvents(): void {
    super.initFormEvents();

    this.stepForm.controls['ForceFullApplyDiffSizeCondition'].valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (value) => {
        const action = value ? 'enable' : 'disable';
        this.stepForm.get('ForceFullDiffSizeCondition')[action]();
      }
    });

    (this.stepForm.controls['forceFull'] as UntypedFormGroup).controls['Enabled'].valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (enabled: boolean) => {
        this.toggleDisabledPanelStatesForNonForceFullPanels(enabled);
      }
    });
    (this.stepForm.controls['blockLevel'] as UntypedFormGroup).controls['Enabled'].valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (enabled) => {
        if (!this.isLinux && this.isBackupPlan) {
          this.toggleDisabledStateForForceFullDiffSizeCondition(enabled);
        }
      }
    });
  }

  onStepFormChange(value: ScheduleAdvancedStepValue): void {
    this.value = { ...value, valid: this.stepForm.valid };

    if (!this.panelNames.length) {
      for (const key in value) {
        if (this.isScheduleSettingsKey(key) && !isNil(value[key].Enabled) && (!this.isRestore || key === 'forceFull'))
          this.initScheduleFormAndPanelNames(value[key], key);
      }

      this.toggleDisabledPanelStatesForNonForceFullPanels(value.forceFull.Enabled);

      if (this.copyOnly && this.isSQLPlan && this.stepForm) {
        this.toggleFormControls(['scheduleDiff'], false);
        this.switcherChangeHandler(false, 'scheduleDiff');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.copyOnly && this.isSQLPlan && this.stepForm) {
      if (changes.copyOnly.currentValue && this.myPanelsInfo.scheduleDiff.isOpen) {
        this.togglePanel(false, 'scheduleDiff');
        this.accordion.collapse('scheduleDiff');
      }

      this.toggleFormControls(['scheduleDiff'], !changes.copyOnly.currentValue);
      this.switcherChangeHandler(!changes.copyOnly.currentValue && this.stepForm.get('scheduleDiff').value.Enabled, 'scheduleDiff', false);
    }
  }

  updateForm(value: ScheduleAdvancedStepValue): void {
    for (const key in value) {
      if (value[key] && value[key].FormSchedule)
        value[key].FormSchedule.RecurringPeriod = this.getRecurringPeriod(value[key].FormSchedule.RecurringPeriod);
    }

    this.stepForm.reset(value);

    if (this.isBackupPlan && !isNil(value.blockLevel.Enabled)) this.switcherChangeHandler(value.blockLevel.Enabled, 'blockLevel');
  }

  getRecurringPeriod(period: RecurType): RecurType {
    return !period || period === RecurType.Periodically || period === RecurType.Instantly || (this.isLinux && period === RecurType.Yearly)
      ? 1
      : period === RecurType.DayOfMonth
      ? RecurType.Monthly
      : period;
  }

  forceValid(): void {
    if (!this.stepForm.valid && this.stepForm.errors.needSelectDay) {
      const modalSettings: ModalSettings = {
        header: { title: 'Schedule Recurring Options' },
        footer: { okButton: { text: 'Ok' }, cancelButton: { show: false } }
      };
      this.modalService
        .confirm(
          modalSettings,
          this.isRestore
            ? 'At least one week day should be selected'
            : `At least one week day should be selected (on ${this.stepForm.errors.needSelectDay.message})`
        )
        .then()
        .catch(noop);
    }
    FormsUtil.triggerValidation(this.stepForm);
    this.resetFormsValidState();
  }

  resetFormsValidState(): void {
    if (this.value && (this.value as any).valid) {
      const formValue = this.scheduleSettingsForm.getRawValue();
      for (const key in formValue) {
        if (key !== 'valid') {
          this.scheduleSettingsForm.get(key).reset(formValue[key]);
        }
      }
      const ForceFullApplyDiffSizeCondition = this.stepForm.get('ForceFullApplyDiffSizeCondition') as FormControl;
      if (ForceFullApplyDiffSizeCondition && ForceFullApplyDiffSizeCondition.enabled && ForceFullApplyDiffSizeCondition.value) {
        this.stepForm.get('ForceFullDiffSizeCondition').reset(this.stepForm.get('ForceFullDiffSizeCondition').value);
      }
    }
  }

  initScheduleFormAndPanelNames(formData: ScheduleFormSettings, key: string): void {
    this.updateScheduleFormData(key, formData.FormSchedule);
    this.panelNames.push(key);
    this.scheduleSettingsForm
      .get(key)
      .valueChanges.pipe(untilDestroyed(this), debounceTime(200))
      .subscribe((value) => {
        const formGroup = this.stepForm.get(key) as UntypedFormGroup;
        const newFormSchedule = this.getUpdatedModel(formData.FormSchedule, value);
        formGroup.get('FormSchedule').setValue(newFormSchedule);
        this.setBackupScheduleString(key);
      });
    this.scheduleSettingsForm.get(key).updateValueAndValidity();
  }

  updateScheduleFormData(key: string, newFormData: RecurringSchedule): void {
    const period = RecurType[newFormData.RecurringPeriod || 1];
    const frequency = `${period}Frequency`;
    const form = this.scheduleSettingsForm.get(key) as UntypedFormGroup;
    form.get('RecurringPeriod').setValue(period, { emitEvent: false });
    const isMonthly = period === 'Monthly';
    if (!this.isLinux || isMonthly) {
      form.get('RepeatEveryCount').setValue(newFormData[frequency].RepeatEveryCount || 1, { emitEvent: false });
      form.get('StartFromDate').setValue(newFormData[frequency].StartFromDate, { emitEvent: false });
    }
    if (isMonthly) {
      form.get('Occurrence').setValue(newFormData[frequency].OccurrencePeriod || 0, { emitEvent: false });
      form.get('DayOfMonthCount').setValue(newFormData[frequency].DayOfMonthCount || 1, { emitEvent: false });
      form
        .get('DayOfWeek')
        .setValue(
          (typeof newFormData[frequency].DayOfWeek === 'string'
            ? DayOfWeek[newFormData[frequency].DayOfWeek]
            : newFormData[frequency].DayOfWeek) || 0,
          { emitEvent: false }
        );
    }
    if (period === 'Weekly') {
      const WeeklyDayOfWeek = form.get('WeeklyDayOfWeek') as FormArray;
      WeeklyDayOfWeek.clear();
      this.DaysOfWeekEnumArray.forEach((elem, idx) => {
        WeeklyDayOfWeek.push(new FormControl(newFormData[frequency].DaysOfWeek.some((day) => day === idx)));
      });
    }
    this.updateDailyFrequencyFormSide(newFormData.DailyFrequency, form);
    this.dailyFreqPeriodChangeHandler(newFormData.DailyFrequency.PeriodOption || 'OccursAt', key);
    this.updateWeeklyGroup(form);
  }

  updateDailyFrequencyFormSide(dailyFrequency: DailyFrequency, form: UntypedFormGroup): void {
    form.get('DailyFreqPeriodOption').setValue(dailyFrequency.PeriodOption || 'OccursAt', { emitEvent: false });
    form.get('OccursAtTime').setValue(dailyFrequency.OccursAtTime || '12:00 AM', { emitEvent: false });
    form.get('OccursEveryCount').setValue(dailyFrequency.OccursEveryCount || 0, { emitEvent: false });
    form.get('OccursEveryPeriod').setValue(dailyFrequency.OccursEveryPeriod || 'hours', { emitEvent: false });
    form.get('OccursEveryFromTime').setValue(dailyFrequency.OccursEveryFromTime || '00:00', { emitEvent: false });
    form.get('OccursEveryToTime').setValue(dailyFrequency.OccursEveryToTime || '23:59', { emitEvent: false });
  }

  dailyFreqPeriodChangeHandler(event: string, key: string): void {
    const form = this.scheduleSettingsForm.get(key) as UntypedFormGroup;
    if (event === 'OccursAt') {
      form.get('OccursAtTime').enable();
      form.get('OccursEveryCount').disable();
      form.get('OccursEveryPeriod').disable();
      form.get('OccursEveryFromTime').disable();
      form.get('OccursEveryToTime').disable();
    } else if (event === 'OccursEvery') {
      form.get('OccursAtTime').disable();
      form.get('OccursEveryCount').enable();
      form.get('OccursEveryPeriod').enable();
      form.get('OccursEveryFromTime').enable();
      form.get('OccursEveryToTime').enable();
    }
  }

  switcherChangeHandler(open: boolean, panelId: string, needExpand = true): void {
    this.togglePanel(open, panelId);

    if (!this.myPanelsInfo[panelId].infoString || !open) {
      this.scheduleSettingsForm.get(panelId).updateValueAndValidity();
    }

    if (needExpand) {
      // Can use without setTimeout only if remove [disabled]="panel.disabled" from ngb-panel into mbs-accordion
      if (open) {
        return void setTimeout(() => this.accordion.expand(panelId));
      }

      this.accordion.collapse(panelId);
    }
  }

  updateWeeklyGroup(form): void {
    const WeeklyDayOfWeek = form.get('WeeklyDayOfWeek') as FormArray;
    this.DaysOfWeekEnumArray.forEach(() => WeeklyDayOfWeek.push(new FormControl(false)));
  }

  toggleDisabledPanelStatesForNonForceFullPanels(enabled: boolean): void {
    this.panelNames.forEach((name) => {
      if (name !== 'forceFull' && (!this.copyOnly || name !== 'scheduleDiff' || !this.isSQLPlan)) {
        const panel = this.stepForm.get(name) as UntypedFormGroup;
        if (!enabled) {
          panel.get('Enabled').setValue(false);
          panel.disable();
        } else panel.enable();
      }
    });
  }

  toggleDisabledStateForForceFullDiffSizeCondition(enable: boolean): void {
    const action = enable ? 'enable' : 'disable';
    this.stepForm.get('ForceFullApplyDiffSizeCondition')[action]();
    if (!enable) {
      this.stepForm.get('ForceFullApplyDiffSizeCondition').setValue(enable);
    }
    if (this.stepForm.get('ForceFullApplyDiffSizeCondition').value) this.stepForm.get('ForceFullDiffSizeCondition')[action]();
  }

  togglePanel(open = true, panelId: string): void {
    this.myPanelsInfo[panelId].isOpen = open;
  }

  getPanelTitle(panelId: string): string {
    return this.myPanelsInfo[panelId].tittle;
  }

  setBackupScheduleString(key: string): void {
    const form = this.scheduleSettingsForm.get(key) as UntypedFormGroup;
    this.myPanelsInfo[key].infoString = StepsHelpers.getStringByScheduleData(form.value);
  }

  getCounterText(name: string): string {
    const formValue = this.stepForm.get(name).value;
    return formValue.FormSchedule ? AdvancedRecurTypeText[RecurType[formValue.FormSchedule.RecurringPeriod]] : '';
  }

  getUpdatedModel(schedule: RecurringSchedule, formValue): RecurringSchedule {
    const isDaily = formValue.RecurringPeriod === 'Daily';
    const isWeekly = formValue.RecurringPeriod === 'Weekly';
    const isMonthly = formValue.RecurringPeriod === 'Monthly';
    const isYearly = formValue.RecurringPeriod === 'Yearly';

    const model: RecurringSchedule = {
      RecurringPeriod: +RecurType[formValue.RecurringPeriod],
      DailyFrequency: this.getDailyFrequency(isDaily, formValue, schedule.DailyFrequency),
      WeeklyFrequency: this.getWeeklyFrequency(isWeekly, formValue, schedule.WeeklyFrequency),
      MonthlyFrequency: this.getMonthlyFrequency(isMonthly, formValue, schedule.MonthlyFrequency)
    };
    if (!this.isLinux || (isMonthly && !this.isRestore)) {
      model.YearlyFrequency = { RepeatEveryCount: formValue.RepeatEveryCount, StartFromDate: formValue.StartFromDate };
    }
    if (model.RecurringPeriod === RecurType.Monthly && model.MonthlyFrequency.OccurrencePeriod === WeekNumber[dayOfMonth]) {
      model.RecurringPeriod = RecurType.DayOfMonth;
    }
    return model;
  }

  getDailyFrequency(isDaily, formValue, dailyFrequency): any {
    const data: any = {
      PeriodOption: formValue.DailyFreqPeriodOption || dailyFrequency.PeriodOption,
      OccursAtTime: formValue.OccursAtTime || dailyFrequency.OccursAtTime,
      OccursEveryCount: formValue.OccursEveryCount || dailyFrequency.OccursEveryCount,
      OccursEveryPeriod: formValue.OccursEveryPeriod || dailyFrequency.OccursEveryPeriod,
      OccursEveryFromTime: formValue.OccursEveryFromTime || dailyFrequency.OccursEveryFromTime,
      OccursEveryToTime: formValue.OccursEveryToTime || dailyFrequency.OccursEveryToTime
    };
    if (!this.isLinux) {
      data.StartFromDate = isDaily ? formValue.StartFromDate : dailyFrequency.StartFromDate;
      data.RepeatEveryCount = isDaily ? formValue.RepeatEveryCount : dailyFrequency.RepeatEveryCount;
    }
    return data;
  }

  getWeeklyFrequency(isWeekly, formValue, weeklyFrequency): any {
    const daysOfWeek: DayOfWeek[] = [];
    if (isWeekly) {
      formValue.WeeklyDayOfWeek.forEach((dayOfWeekCheck, index) => {
        if (dayOfWeekCheck) {
          daysOfWeek.push(DayOfWeek[this.DaysOfWeekEnumArray[index].label as string]);
        }
      });
    }
    const data: any = { DaysOfWeek: isWeekly ? daysOfWeek : weeklyFrequency.DaysOfWeek };
    if (!this.isLinux) {
      data.StartFromDate = isWeekly ? formValue.StartFromDate : weeklyFrequency.StartFromDate;
      data.RepeatEveryCount = isWeekly ? formValue.RepeatEveryCount : weeklyFrequency.DaysOfWeek;
    }
    return data;
  }

  getMonthlyFrequency(isMonthly, formValue, monthlyFrequency): any {
    const data: any = {
      OccurrencePeriod: isMonthly ? formValue.Occurrence : monthlyFrequency.OccurrencePeriod,
      DayOfWeek: isMonthly ? DayOfWeek[formValue.DayOfWeek] : monthlyFrequency.DayOfWeek,
      DayOfMonthCount: isMonthly ? formValue.DayOfMonthCount || monthlyFrequency.DayOfMonthCount : monthlyFrequency.DayOfMonthCount
    };
    data.StartFromDate = isMonthly ? formValue.StartFromDate : monthlyFrequency.StartFromDate;
    data.RepeatEveryCount = isMonthly ? formValue.RepeatEveryCount : monthlyFrequency.RepeatEveryCount;
    return data;
  }

  recurringPeriodChangeHandler(event, name): void {
    if (this.scheduleSettingsForm.get(name) && !this.isLinux) {
      const control = this.scheduleSettingsForm.get(name).get('RepeatEveryCount');
      if (control.value) {
        if (event === 'Daily' && control.value > 31) {
          control.setValue(31);
        } else if (event === 'Weekly' && control.value > 52) {
          control.setValue(52);
        } else if (event === 'Monthly' && control.value > 12) {
          control.setValue(12);
        } else if (event === 'Yearly' && control.value > 99) {
          control.setValue(99);
        }
      }
    }
  }

  occursEveryPeriodChangeHandler(period: OccursEveryPeriodType, name: string): void {
    if (period === 'hours') {
      const countControl: FormControl<number> = this.scheduleSettingsForm.get(name)['controls'].OccursEveryCount;

      if (countControl.value > this.maxHours) countControl.setValue(this.maxHours);
    }
  }

  private isScheduleSettingsKey(key: string): boolean {
    return key !== 'ForceFullApplyDiffSizeCondition' && key !== 'ForceFullDiffSizeCondition';
  }
}
