import { AfterViewInit, Directive } from '@angular/core';
import { AgentType } from '@models/Computer';
import { PlanTypes } from '@models/PlanTypes.enum';
import { StorageType, TypesForIntelligentRetention } from '@models/StorageType.enum';
import { StorageConnection } from '@models/storge-connections';
import { WizardHelpersSettings } from '@modules/wizards/helpers/bases/base-for-steps-helper';
import { HyperVFormModel } from '@modules/wizards/models/HyperV/hyper-v-form-model';
import { VmwareFormModel } from '@modules/wizards/models/VMWare/vmware-form-model';
import { BaseHyperVPlanModel } from '@modules/wizards/models/base/base-hyper-v-plan-model';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { WindowsFeatures } from '@modules/wizards/services/wizard-steps.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { noop } from 'rxjs';
import { SupportMethodsForStepsFromPlan } from '../helpers/support-methods-for-steps-from-plan';
import { VirtualMachine, VirtualMachinesSelectedType } from '../models/select-virtual-machines-models';
import { WizardBaseClass } from './wizard-base.class';

const features = {
  [PlanTypes.BackupHyperV]: WindowsFeatures.hyperV,
  [PlanTypes.BackupVMware]: WindowsFeatures.vMware
};

@UntilDestroy()
@Directive()
export abstract class VMWizardBaseClass extends WizardBaseClass implements AfterViewInit {
  public FormModel: HyperVFormModel | VmwareFormModel;

  public useS3AccelerationPossible = false;
  public selectedStorage: StorageConnection;
  public selectVMTitle = this.i18nPipe.transform('wizards:what_backup_step_title', { format: 'title' });
  public advancedSettingsTitle = this.i18nPipe.transform('wizards:advanced_settings_title', { format: 'title' });
  public isAppAwareEnabled = RemoteManagementWizardsService.getWizardState(this.mainService).isAppAwareEnabled;
  public selectedMachines: Array<VirtualMachine> = [];

  public get canShowAppAwareStep(): boolean {
    return this.isAppAwareEnabled && this.isCBTAvailable;
  }

  public get canShowSelectVirtualDiskStep(): boolean {
    const machinesFullyLoaded = !!this.FormModel?.selectVirtualMachinesStep?.machinesFullFormat?.length;

    return machinesFullyLoaded && this.machinesManuallySelected;
  }

  protected isCBTAvailable = true;
  protected isHyperV = RemoteManagementWizardsService.getWizardState(this.mainService).isHyperV;
  protected get machinesManuallySelected(): boolean {
    return this.FormModel.selectVirtualMachinesStep.type === VirtualMachinesSelectedType.Selected;
  }

  ngAfterViewInit() {
    this.checkVMPlugin();

    if (this.isHyperV) {
      this.checkCBTAbility();
    }
  }

  defaultSettings(): WizardHelpersSettings {
    return { ...super.defaultSettings(), isAppAwareEnabled: this.isAppAwareEnabled, agentVersion: this.mainService.backupVersionUpdated };
  }

  protected updateStepData(plan, isDefault = false): void {
    this.loadedPlan = plan;
    if (this.isEdit && !this.isRDMode) this.mainService.GetPlanNotificationOptions();
    else if (!this.isRDMode) this.mainService.getGlobalNotification();

    if (!isDefault) {
      this.generateFormModel(plan);
    } else {
      this.FormModel.planNameStep.Name = SupportMethodsForStepsFromPlan.generatePlanName(
        this.mainService.compName,
        this.planType,
        this.mainService.timeZoneOffset
      );
      this.loadedPlan.plan = new BaseHyperVPlanModel();
      this.updateStorages();
    }
  }

  protected abstract generateFormModel(plan): void;

  protected changeVMStorageHandler(connection: StorageConnection): void {
    this.storage = connection;
    this.invalidIntelligentRetentionStorage = !TypesForIntelligentRetention.isSupported(connection);

    if (!connection || isNil(connection.StorageType)) {
      this.useS3AccelerationPossible = false;
      return;
    }

    if (this.FormModel.StoragesArray?.length) {
      this.selectedStorage = this.FormModel.StoragesArray.find((storage) => storage.ID === this.FormModel?.whereBackupStep?.ConnectionID);
    }

    if (this.FormModel.Storages.CloudStorages?.length) {
      this.canShowImmutabilityByStorage = SupportMethodsForStepsFromPlan.needShowImmutability(
        this.FormModel.Storages.CloudStorages,
        this.FormModel.whereBackupStep.ConnectionID
      );
    }

    this.useS3AccelerationPossible = connection.StorageType === StorageType.AmazonS3 || connection.StorageType === StorageType.UbAmazonS3;
    this.updateInvalidFFIStorage(connection);
  }

  protected abstract clearAppAwareData(newPlan): void;

  savePlan(newPlan) {
    if (!this.canShowAppAwareStep) {
      this.clearAppAwareData(newPlan);
    }

    super.savePlan(newPlan);
  }

  machinesSelectedHandler(machines: Array<VirtualMachine>): void {
    this.selectedMachines = [...machines];
  }

  private checkCBTAbility(): void {
    this.stepService
      .VMFeatureTest(this.mainService.hid, WindowsFeatures.hyperVChangedBlockTracking)
      .pipe(untilDestroyed(this))
      .subscribe((res) => void (this.isCBTAvailable = !!res?.data));
  }

  /**
   * For virtual machines, we will ask the agent - does he have the plugin installed?
   * If not, try to install remotely.
   * @private
   */
  private checkVMPlugin(): void {
    this.stepService
      .VMFeatureTest(this.mainService.hid, features[this.planType])
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res?.data === false) {
          this.installVMFeature();
        }
      });
  }

  /**
   * The method will call on the agent a task to install the appropriate plugin for Virtual Machines
   * @private
   */
  private installVMFeature(): void {
    this.stepService
      .getRemoteCommandData(
        {
          agentType: AgentType.Backup,
          commandType: 'InstallFeature',
          params: {
            Feature: features[this.planType]
          }
        },
        this.mainService.hid
      )
      .subscribe(noop);
  }
}
