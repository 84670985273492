import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { PlanTypes } from '@models/PlanTypes.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ImagePath, PlanNameStepValue } from '../../models/plan-name-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const PlanNameStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => PlanNameStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-plan-name-step',
  templateUrl: './plan-name-step.component.html',
  providers: [PlanNameStepValueAccessor]
})
export class PlanNameStepComponent extends StepBase<PlanNameStepValue> implements OnInit {
  @Output() restoreOnce = new EventEmitter();

  public readonly elementsSelector = {
    css: {
      planNameRestoreRadio: 'mbs-wizard_restore-radio',
      planNameBackupCheckbox: 'mbs-wizard_backup-checkbox'
    }
  };
  public readonly runRestoreOnceSubtitle = this.i18nPipe.transform('wizards:run_restore_once_subtitle', { returnObjects: true });

  get imageSrc(): string {
    return ImagePath[this.planType] || ImagePath[PlanTypes.BackupDiskImagePlan];
  }

  constructor(public mainService: RemoteManagementWizardsService, public i18nPipe: I18NextPipe) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      Name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      SavePlanInCloud: new FormControl(false)
    });

    this.initFormEvents();
  }

  onStepFormChange(value: PlanNameStepValue): void {
    if (this.isRestore && !this.stepForm.touched) this.changeSavePlanHandler(value.SavePlanInCloud, false);
    if (this.stepForm.dirty || this.stepForm.touched) this.value = { ...value, valid: this.stepForm.valid };
  }

  changeSavePlanHandler(event: boolean, fromLayout = true): void {
    if (!fromLayout) this.stepForm.markAsTouched();
    if (event) return this.stepForm.get('Name').enable({ emitEvent: false });

    if (this.isRestore) this.restoreOnce.emit(true);

    this.stepForm.get('Name').disable({ emitEvent: false });
  }
}
