import { Injectable } from '@angular/core';
import { getPagingParams, getSortParams, Page, PagingParams, QueryPagingParams, QuerySortParams } from '@models/Paging';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ComputersAbstractService } from '@services/computers.service';
import { ComputersStoreActions } from '@shared/store/computers';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { OnlineAccessPagingStoreActions, OnlineAccessPagingStoreSelectors } from '.';

@Injectable()
export class OnlineAccessPagingEffects {
  loadPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OnlineAccessPagingStoreActions.loadPage),
      concatLatestFrom(() => this.store.select(OnlineAccessPagingStoreSelectors.selectRequestParams)),
      map(([action, params]) => ({
        ...params,
        paging: { ...params.paging, currentPageNumber: action.pageNumber ?? params.paging.currentPageNumber } as PagingParams
      })),
      switchMap(({ paging, sorting }) => {
        return this.request(getPagingParams(paging), getSortParams(sorting)).pipe(
          map((result) =>
            OnlineAccessPagingStoreActions.loadPageSuccess({
              page: { id: paging.currentPageNumber, page: result.data } as Page,
              total: result.total
            })
          ),
          catchError(() => of(OnlineAccessPagingStoreActions.loadPageError()))
        );
      })
    );
  });

  setPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OnlineAccessPagingStoreActions.setPageNumber),
      map((action) => action.pageNumber),
      debounceTime(100),
      concatLatestFrom(() => this.store.select(OnlineAccessPagingStoreSelectors.selectPageNumbers)),
      switchMap(([pageNumber, storedPages]) => {
        return storedPages.some((pageIndex) => pageIndex === pageNumber)
          ? of(OnlineAccessPagingStoreActions.setPageNumberSuccess({ pageNumber }))
          : of(OnlineAccessPagingStoreActions.loadPage({ pageNumber }));
      })
    );
  });

  setPerPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OnlineAccessPagingStoreActions.setPerPageSetting),
      map((action) => action.perPage),
      debounceTime(100),
      switchMap((perPage) => of(OnlineAccessPagingStoreActions.loadPage({ perPage })))
    );
  });
  constructor(public store: Store, public actions$: Actions, private computersService: ComputersAbstractService) {}

  request = (paging: QueryPagingParams, sorting: QuerySortParams) =>
    of({ paging, sorting }).pipe(
      switchMap(({ paging, sorting }) => this.computersService.getComputers(this.removeEmptyProperties({ ...sorting, ...paging }))),
      tap((data) => this.store.dispatch(ComputersStoreActions.setComputers({ computers: [...data.data] }))),
      map((data) => ({ data: data.data.map((c) => c.hid), total: data.total }))
    );

  private removeEmptyProperties(obj) {
    return Object.entries(obj)
      .filter(([, value]) => value != null)
      .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});
  }
}
