import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplyToModel } from '@domains/remote-deploy/models/apply-to-model';
import { ApplyToEnum } from '@domains/remote-deploy/sidepanel-remote-deploy-configuration/apply-to-tab/apply-to-enum';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import Company from '@models/Company';
import Computer, { AgentType, ComputerWithStatus, OsType } from '@models/Computer';
import { RdConfigOsTypeEnum } from '@models/remote-deploy/rd-config-os-type.enum';
import { RemoteDeployConfiguration } from '@models/remote-deploy/remote-deploy-configuration';
import { RemoteDeployHistoryRecord } from '@models/remote-deploy/remote-deploy-configuration-history-record';
import { RDComputersWithStatus } from '@models/remote-deploy/remote-deploy-configurations-history-computer';
import StorageAccount from '@models/StorageAccount';
import { SAWithDestinations } from '@models/StorageAccountsWithDestinations';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { AbilityService } from 'ability';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, first, map, switchMap } from 'rxjs/operators';

export type ConfigParamsRelatedToChangingComputersList = Partial<
  Pick<RemoteDeployConfiguration, 'selectedComputers' | 'selectedCompanies' | 'applyTo'>
> & {
  os: RdConfigOsTypeEnum;
} & Partial<ApplyToModel>;

@Injectable({
  providedIn: 'root'
})
export class RemoteDeployDataService extends EntityCollectionServiceBase<RemoteDeployConfiguration> {
  // public ApplyToData$: BehaviorSubject<ApplyToModel> = new BehaviorSubject<ApplyToModel>(new ApplyToModel());
  public allStorageAccounts: StorageAccount[] = [];
  public SAWithDestinations$: BehaviorSubject<SAWithDestinations[]> = new BehaviorSubject<SAWithDestinations[]>([]);
  rdRelatedRequestPending$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentEqualNewApplyTo = false;
  public computersLoading$ = new BehaviorSubject<boolean>(false);
  private CurrentApplyTo: ApplyToModel = new ApplyToModel();

  // private initialOsType: RdConfigOsTypeEnum = null;
  private configMap: {
    [key: string]: {
      params$: BehaviorSubject<ConfigParamsRelatedToChangingComputersList>;
      computers$: BehaviorSubject<ComputerWithStatus[]>;
      subs: Subscription[];
    };
  } = {};

  private mergeComputersArray(computersWithStatuses: RDComputersWithStatus[], computers: Computer[]): ComputerWithStatus[] {
    const computersWithStatusHash: { [key: string]: RDComputersWithStatus } = {};
    computersWithStatuses.forEach((computer: RDComputersWithStatus) => {
      computersWithStatusHash[computer.computerId] = computer;
    });
    if (computers && computers.length) {
      return computers.map((computer: Computer) => {
        const newComputer = { ...computer } as ComputerWithStatus;
        if (computersWithStatusHash[computer.id]) {
          newComputer.lastStatus = computersWithStatusHash[computer.id].lastStatus;
          newComputer.lastStatusDate = computersWithStatusHash[computer.id].lastStatusDate;
        } else {
          newComputer.lastStatus = null;
          newComputer.lastStatusDate = null;
        }
        return newComputer;
      });
    } else {
      return computersWithStatuses;
    }
  }

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    private ability: AbilityService,
    private computersFacade: ComputersFacade
  ) {
    super('RemoteDeployConfiguration', serviceElementsFactory);
  }

  public getConfigSelectedParams$(config: RemoteDeployConfiguration): BehaviorSubject<ConfigParamsRelatedToChangingComputersList> {
    // if (!configId) return null;
    const confMap = this.configMap[config.id];
    if (confMap) {
      return confMap.params$;
    } else {
      const newParamsSubj$ = new BehaviorSubject<ConfigParamsRelatedToChangingComputersList>({
        os: null
      });
      const paramsSubscription = newParamsSubj$
        .pipe(
          filter((x) => !((x && x.os === null) || (x && x.os === undefined)) && !!this.configMap[config.id]),
          distinctUntilChanged((x, y) => {
            return (
              x.os === y.os &&
              isEqual(x.selectedCompanies, y.selectedCompanies) &&
              isEqual(x.selectedComputers, y.selectedComputers) &&
              x.applyTo === y.applyTo
            );
          }),
          switchMap((newParams) => {
            if (newParams.os !== RdConfigOsTypeEnum['All types']) {
              if (
                !(
                  this.ability.can('read', 'SubAdmin') &&
                  !this.ability.can('read', 'AccessToAllCompanies') &&
                  newParams.applyTo === ApplyToEnum.ApplyToAllCompanies
                )
              ) {
                this.getDestinations(newParams as ApplyToModel, config.id);
              } else this.SAWithDestinations$.next(this.SAWithDestinations$.value);
            }
            return this.getConfigComputersAggregatedRequest$(config.id);
          })
        )
        .subscribe();
      // const subscriptionForRequeryingDestinations = newParamsSubj$
      //   .pipe(
      //     filter(x => !(x && x.osType === null || x && x.osType=== undefined) && !!this.configMap[config.id]),
      //     distinctUntilChanged((x, y) => {
      //       return (
      //         x.applyTo === y.applyTo &&
      //         isEqual(x.selectedCompanies, y.selectedCompanies) &&
      //         isEqual(x.selectedComputers, y.selectedComputers)
      //       );
      //     }),
      //     tap(newParams => {
      //       if (
      //         !(
      //           this.ability.can('read', 'SubAdmin') &&
      //           !this.ability.can('read', 'AccessToAllCompanies') &&
      //           newParams.applyTo === ApplyToEnum.ApplyToAllCompanies
      //         )
      //       ) {
      //         this.getDestinations(newParams as ApplyToModel, config.id);
      //       } else this.SAWithDestinations$.next(this.SAWithDestinations$.value);
      //     })
      //   )
      //   .subscribe();
      this.configMap[config.id] = {
        params$: newParamsSubj$,
        computers$: new BehaviorSubject<Computer[]>([]),
        subs: [
          paramsSubscription
          // subscriptionForRequeryingDestinations
        ]
      };
      return newParamsSubj$;
    }
  }

  patchConfigSelectedParams(config: RemoteDeployConfiguration, newParams: Partial<ConfigParamsRelatedToChangingComputersList>) {
    const confMap = this.safeGetConfigMap(config);
    confMap.params$.next({
      ...confMap.params$.value,
      ...newParams
    });
  }

  private safeGetConfigMap(config: RemoteDeployConfiguration) {
    let confMap = this.configMap[config?.id];
    if (!confMap) {
      this.getConfigSelectedParams$(config);
      confMap = this.configMap[config?.id];
    }
    return confMap;
  }

  private getOsTypeValueForRequest(osType: RdConfigOsTypeEnum, needFullName = false) {
    let osTypeVal = RdConfigOsTypeEnum[osType];
    if (osType === RdConfigOsTypeEnum['All types']) {
      osTypeVal = needFullName ? 'AllTypes' : 'All';
    } else if (osType === RdConfigOsTypeEnum['macOS']) {
      osTypeVal = needFullName ? 'MacOS' : 'Mac';
    }
    return osTypeVal;
  }

  private getOsValueForComputersRequest(osType: RdConfigOsTypeEnum) {
    const OSTypeAdapter: { os: RdConfigOsTypeEnum; requestOS: OsType }[] = [
      { os: RdConfigOsTypeEnum.Windows, requestOS: OsType.windows },
      { os: RdConfigOsTypeEnum.Linux, requestOS: OsType.linux },
      { os: RdConfigOsTypeEnum.macOS, requestOS: OsType.apple }
    ];

    return OSTypeAdapter.find((osMap) => osMap.os === osType)?.requestOS || null;
  }

  private getConfigComputersAggregatedRequest$(
    configId: string,
    needAddApplyTo = false,
    hasNBF = null,
    hasFFI = null
  ): Observable<Computer[]> {
    const configMap = this.configMap[configId];
    if (!configMap) {
      return of();
    }
    const currentParamsVal = configMap.params$.value;

    const backupRequestParams = { appIds: [AgentType.Backup] };
    const osTypeVal = this.getOsValueForComputersRequest(currentParamsVal.os);
    const requestParams = osTypeVal ? { ...backupRequestParams, os: [osTypeVal] } : backupRequestParams;

    this.computersLoading$.next(true);
    const computers$ = this.computersFacade.getComputers(requestParams).pipe(
      map((response) => response.data),
      finalize(() => this.computersLoading$.next(false))
    );
    const computersWithStatus$ = configId ? this.getConfigComputersWithParams(configId, '', needAddApplyTo, hasNBF, hasFFI) : of([]);
    return combineLatest([computers$, computersWithStatus$]).pipe(
      switchMap(([computers, data]) => {
        let newComps = computers;
        if (data && data.length) {
          newComps = this.mergeComputersArray(data, computers);
        }
        if (currentParamsVal.applyTo === ApplyToEnum.ApplyToAllSelectedCompanies && currentParamsVal.selectedCompanies.length > 0) {
          newComps = newComps.filter((x) => currentParamsVal.selectedCompanies.includes(x.company && x.company.id));
        }
        // we don't do filtering by computers cause we need other computers so we can select them in the applyTo tab also!
        // else if (currentParamsVal.applyTo === ApplyToEnum.ApplyToSelectedOnly && currentParamsVal.selectedComputers.length > 0) {
        //   newComps = newComps.filter(x => currentParamsVal.selectedComputers.includes(x.id));
        // }
        this.configMap[configId].computers$.next(newComps);
        return of(newComps);
      })
    );
  }

  public refreshConfigComputers$(configId: string, needAddApplyTo = false, hasNBF = null, hasFFI = null): Observable<ComputerWithStatus[]> {
    return this.getConfigComputersAggregatedRequest$(configId, needAddApplyTo, hasNBF, hasFFI);
  }

  public getConfigComputers$(config: RemoteDeployConfiguration): BehaviorSubject<Computer[]> {
    const confMap = this.safeGetConfigMap(config);
    return confMap.computers$;
  }

  clearConfigMapData(configId: string): void {
    const confMap = this.configMap[configId];
    if (confMap) {
      confMap.subs.forEach((sub) => sub.unsubscribe());
      delete this.configMap[configId];
    }
  }

  public getDestinations(ApplyTo: ApplyToModel = null, configId: string): void {
    if (ApplyTo === null) {
      ApplyTo = this.configMap[configId].params$.value as ApplyToModel;
    }
    const computers =
      ApplyTo.applyTo === ApplyToEnum.ApplyToSelectedOnly && ApplyTo.selectedComputers && ApplyTo.selectedComputers.length
        ? ApplyTo.selectedComputers
        : null;
    const companies =
      ApplyTo.applyTo === ApplyToEnum.ApplyToAllSelectedCompanies && ApplyTo.selectedCompanies && ApplyTo.selectedCompanies.length
        ? ApplyTo.selectedCompanies
        : null;

    const data = { ApplyTo: ApplyTo.applyTo, SelectedComputers: computers, SelectedCompanies: companies };
    if (
      ApplyTo.applyTo === ApplyToEnum.ApplyToAllCompanies ||
      (ApplyTo.applyTo === ApplyToEnum.ApplyToAllSelectedCompanies && companies) ||
      (ApplyTo.applyTo === ApplyToEnum.ApplyToSelectedOnly && computers)
    ) {
      this.rdRelatedRequestPending$.next(true);
      this.http
        .post('api/remote-deploy/destinations', data, { headers: { 'Content-Type': 'application/json; charset=utf-8' } })
        .pipe(
          first(),
          finalize(() => this.rdRelatedRequestPending$.next(false))
        )
        .subscribe((data: SAWithDestinations[]) => this.SAWithDestinations$.next(data));
    } else {
      this.SAWithDestinations$.next([]);
    }
  }

  public getConfigComputersWithParams(
    configId: string,
    status: string,
    needAddApplyTo = false,
    hasNBF = null,
    hasFFI = null
  ): Observable<RDComputersWithStatus[]> {
    const configMap = this.safeGetConfigMap({ id: configId } as RemoteDeployConfiguration);
    const osTypeVal = this.getOsTypeValueForRequest(configMap.params$.value.os, true);

    const bodyParams: any = { status, osType: osTypeVal };
    if (needAddApplyTo) {
      const applyToParams = configMap.params$.value;
      bodyParams.applyTo = applyToParams.applyTo;
      bodyParams.selectedCompanies = applyToParams.selectedCompanies || [];
      bodyParams.selectedComputers = applyToParams.selectedComputers || [];
      bodyParams.excludedComputers = applyToParams.excludedComputers || [];
    }
    if (hasNBF ?? false) {
      bodyParams.hasNbfPlans = hasNBF;
    }
    if (hasFFI ?? false) {
      bodyParams.hasFfiPlans = hasFFI;
    }
    return this.http.post<RDComputersWithStatus[]>(`/api/remote-deploy/configurations/${configId}/computers`, bodyParams).pipe(
      map((response) => {
        if ((response as any).data) {
          return (response as any).data as RDComputersWithStatus[];
        } else return response;
      })
    );
  }

  public getConfigSelectedComputers(configuration: RemoteDeployConfiguration): Observable<Computer[]> {
    return this.http.get<Computer[]>('/api/remote-deploy/configurations/' + configuration.id + '/SelectedComputers');
  }

  public getConfigSelectedCompanies(configuration: RemoteDeployConfiguration): Observable<Company[]> {
    return this.http.get<Company[]>('/api/remote-deploy/configurations/' + configuration.id + '/SelectedCompanies');
  }

  public getHistory(): Observable<RemoteDeployHistoryRecord[]> {
    return this.http.get<RemoteDeployHistoryRecord[]>('/api/remote-deploy/history');
  }

  public cloneConfiguration(id: string): Observable<RemoteDeployConfiguration> {
    this.setLoading(true);
    return this.http.get<RemoteDeployConfiguration>(`/api/remote-deploy/configurations/${id}/clone`, {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED: 'MANUAL_ERROR_HANDLED' })
    });
  }

  public importConfiguration(computerId: string): Observable<any> {
    this.setLoading(true);
    return this.http.get(`/api/computers/${computerId}/importconfiguration`).pipe(
      finalize(() => {
        this.setLoading(false);
      })
    );
  }
}
