import { ComputersHealthModel } from '@models/computersStatistic';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';
import { ComputersStatisticState } from './state';

export const selectComputersStatisticFeatureState = createFeatureSelector<ComputersStatisticState>(StoreTypes.ComputersStatistic);

export const selectStatisticLoading = createSelector(selectComputersStatisticFeatureState, (state) => state.statisticLoading);
export const selectStatisticLoaded = createSelector(selectComputersStatisticFeatureState, (state) => state.statisticLoaded);
export const selectStatisticError = createSelector(selectComputersStatisticFeatureState, (state) => state.statisticError);

export const selectHealthy = createSelector(selectComputersStatisticFeatureState, (state) => state.healthState.healthy);
export const selectProblem = createSelector(selectComputersStatisticFeatureState, (state) => state.healthState.problem);
export const selectWarning = createSelector(selectComputersStatisticFeatureState, (state) => state.healthState.warning);
export const selectHealthTotal = createSelector(selectComputersStatisticFeatureState, (state) => state.healthTotal);
export const selectHealthLoading = createSelector(
  selectStatisticLoading,
  selectComputersStatisticFeatureState,
  (statisticLoading, state) => statisticLoading || state.healthLoading
);
export const selectHealthLoaded = createSelector(selectComputersStatisticFeatureState, (state) => state.healthLoaded);
export const selectHealthError = createSelector(
  selectStatisticError,
  selectComputersStatisticFeatureState,
  (error, state) => error ?? state.healthError
);
export const selectAuthStatus = createSelector(selectComputersStatisticFeatureState, (state) => state.authStatus);
export const selectVersionStatus = createSelector(selectComputersStatisticFeatureState, (state) => state.versionStatus);

export const selectHealthData = createSelector(
  selectHealthy,
  selectProblem,
  selectWarning,
  selectHealthTotal,
  (healthy, problem, warning, total) =>
    ({
      healthy,
      problem,
      warning,
      all: total
    } as ComputersHealthModel)
);

export const selectActive = createSelector(selectComputersStatisticFeatureState, (state) => state.state.active);
export const selectHidden = createSelector(selectComputersStatisticFeatureState, (state) => state.state.hidden);
export const selectStateTotal = createSelector(selectComputersStatisticFeatureState, (state) => state.stateTotal);
export const selectStateLoading = createSelector(
  selectStatisticLoading,
  selectComputersStatisticFeatureState,
  (statisticLoading, state) => statisticLoading || state.stateLoading
);
export const selectStateLoaded = createSelector(selectComputersStatisticFeatureState, (state) => state.stateLoaded);
export const selectStateError = createSelector(
  selectStatisticError,
  selectComputersStatisticFeatureState,
  (error, state) => error ?? state.stateError
);
export const selectIsStateEmpty = createSelector(selectComputersStatisticFeatureState, (state) => state.isStateEmpty);

export const selectOneComputerExist = createSelector(selectComputersStatisticFeatureState, (state) => state.oneComputerExist);
export const selectOneComputerLoading = createSelector(selectComputersStatisticFeatureState, (state) => state.oneComputerExistLoading);
export const selectOneComputerLoaded = createSelector(selectComputersStatisticFeatureState, (state) => state.oneComputerExistLoaded);
