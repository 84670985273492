import { Component, Input, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit';

@UntilDestroy()
@Component({
  selector: 'mbs-checkbox-legend',
  templateUrl: './checkbox-legend.component.html',
  styleUrls: ['./checkbox-legend.component.scss']
})
export class CheckboxLegendComponent {
  @Input() title = this.i18nPipe.transform('wizards:legendTitle', { format: 'title' });
  @Input() isRestore = false;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private i18nPipe: I18NextPipe) {}

  close(): void {
    this.baseModal.close();
  }
}
