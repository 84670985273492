import { EventEmitter, Injectable } from '@angular/core';
import { PersistentStateServiceBase } from 'mbs-ui-kit/services/storage/persistent-state-base.service';
import { AppPersistentState } from '../models/AppPersistentState';
@Injectable({
  providedIn: 'root'
})
export class AppPersistentStateService extends PersistentStateServiceBase<AppPersistentState> {
  public userId$: EventEmitter<string>;

  constructor() {
    super(AppPersistentState, new EventEmitter<string>());
    this.listenStorageChange();
    this.userId$ = this.getEmitterInstance();

    this.resetState();
  }

  public set(obj: Record<string, any>, key: string, value: any): void {
    super.set(obj, key, value);
  }

  public get<T = any>(obj: Record<string, any>, key: string): T {
    return super.get(obj, key);
  }

  public resetState(): void {
    // TODO someday remove. Requires for correct hide ub alerts
    if (typeof this.data.showUbMessage == 'boolean') {
      this.data.showUbMessage = {};
    }

    if (new Date().getTime() - new Date(this.data.lastSignIn).getTime() > 24 * 60 * 60 * 1000) {
      this.data.showUbMessage = {};
      this.data.showUnsupportedVersionsMessage = true;
      this.data.lastSignIn = new Date();
      this.data.showUnconfiguredEmailNotifications = true;
      this.data.feedbackRemindLateDate = null;
      this.data.timeZoneAlertDismissed = false;
    }
  }
}
