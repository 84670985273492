import { Component, Inject } from '@angular/core';
import Administrator from '@models/Administrator';
import { AppEnvironmentState } from '@models/AppEnvironmentState';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OnlineAccessInfo } from '@root/mbs-ui/src/app/shared/models/OnlineAccess';
import { AuthService } from '@services/auth.service';
import { APP_BACKEND_BUILD_VERSIONS } from '@utils/app-backend-build-versions';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-online-access-header',
  templateUrl: './online-access-header.component.html',
  styleUrls: ['./online-access-header.component.scss']
})
export class OnlineAccessHeaderComponent {
  public currentUser: Administrator;
  public appEnvState: AppEnvironmentState;
  private onlineAccessInfo: OnlineAccessInfo;

  constructor(
    private auth: AuthService,
    @Inject(APP_BACKEND_BUILD_VERSIONS) private backendVersion: string,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe
  ) {
    this.auth.currentUser.pipe(filter(Boolean), untilDestroyed(this)).subscribe((admin) => {
      this.currentUser = admin;
    });
    this.auth.getOnlineAccessInfo$.subscribe((info) => (this.onlineAccessInfo = info?.info));
  }

  get applicationVersions(): string {
    return '' + this.backendVersion;
  }

  get homePageUrl(): string {
    return '.';
  }

  get displayName(): string {
    return this.onlineAccessInfo?.displayName ?? '';
  }

  get email(): string {
    return (this.currentUser as any)?.Email ?? '';
  }

  handleLogout(): void {
    this.auth.logoutUser();
  }
}
