import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PrePostActionsStepValue } from '../../models/pre-post-actions-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const PrePostActionsStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => PrePostActionsStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-pre-post-actions-step',
  templateUrl: './pre-post-actions-step.component.html',
  providers: [PrePostActionsStepValueAccessor]
})
export class PrePostActionsStepComponent extends StepBase<PrePostActionsStepValue> implements OnInit, OnChanges {
  @Input() ChainPlans = [];

  public readonly elementsSelector = {
    name: {
      isRDModeAlert: 'isRDModeAlert',
      allowPrePostActionsBlock: 'allowPrePostActionsBlock',
      allowPrePostActionsSecBlock: 'allowPrePostActionsSecBlock',
      notLinuxNotRestore: 'notLinuxNotRestore'
    }
  };

  constructor(public mainService: RemoteManagementWizardsService) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      executeCommandBeforeBackup: new FormControl(false),
      preAction: new FormControl('', [Validators.required, Validators.minLength(2)]),
      exitOnPreActionFail: new FormControl(true),
      executeCommandAfterBackupCompletion: new FormControl(false),
      postAction: new FormControl('', [Validators.required, Validators.minLength(2)]),
      executePostActionOnSuccess: new FormControl(true),
      enableBackupChain: new FormControl(false),
      backupChainNextPlan: new FormControl('', [Validators.required]),
      executeChainPlanOnSuccess: new FormControl(false),
      ForceFullNextPlan: new FormControl(false)
    });

    this.initFormEvents();
  }

  onStepFormChange(value: PrePostActionsStepValue): void {
    if (this.stepForm.dirty || this.stepForm.touched) this.value = { ...value, valid: this.stepForm.valid };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm && changes.ChainPlans?.currentValue?.length && !this.stepForm.get('backupChainNextPlan').value) {
      this.stepForm.get('backupChainNextPlan').patchValue(changes.ChainPlans.currentValue[0].value);
    }
  }

  updateForm(value: PrePostActionsStepValue): void {
    this.stepForm.reset(value);

    this.beforeBackupChangeHandler(value.executeCommandBeforeBackup);
    this.afterBackupChangeHandler(value.executeCommandAfterBackupCompletion);
    this.backupChainChangeHandler(value.enableBackupChain);

    if (this.ChainPlans && this.ChainPlans.length && !value.enableBackupChain && !value.backupChainNextPlan)
      this.stepForm.get('backupChainNextPlan').patchValue(this.ChainPlans[0].value);
  }

  beforeBackupChangeHandler(event): void {
    this.toggleFormControls(['preAction', 'exitOnPreActionFail'], event);
  }

  afterBackupChangeHandler(event): void {
    this.toggleFormControls(['postAction', 'executePostActionOnSuccess'], event);
  }

  backupChainChangeHandler(event): void {
    this.toggleFormControls(['backupChainNextPlan', 'executeChainPlanOnSuccess', 'ForceFullNextPlan'], event);
  }
}
