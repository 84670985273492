import { Injectable } from '@angular/core';
import { CompaniesFacade } from '@facades/companies.facade';
import { ComputersFacade } from '@facades/computers.facade';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import Brand, { BrandingCompany } from '@models/Brand';
import Company, { LicensesSettings, LimitsMode } from '@models/Company';
import Computer from '@models/Computer';
import CustomDnsBinding from '@models/CustomDnsBinding';
import { DisabledSmartSearchKey, EnabledSmartSearchKey, SmartSearchTemplateKeys } from '@models/SmartSearchTemplates';
import StorageAccount from '@models/StorageAccount';
import { StorageType } from '@models/StorageType.enum';
import { AdministratorService } from '@services/administrator.service';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { EnumHelper, ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { BrandsService } from './brands.service';
import { CustomDnsBindingsService } from './custom-dns-bindings.service';
import SmartSearchTemplatesBase from './smart-search-templates-base';

@Injectable({ providedIn: 'root' })
export class SmartSearchTemplates extends SmartSearchTemplatesBase {
  protected readonly maxTake = 10;
  protected get maxTakeStr(): string {
    return String(this.maxTake);
  }
  constructor(
    private storageAccountsService: StorageAccountsService,
    private administratorService: AdministratorService,
    private computersFacade: ComputersFacade,
    private companiesFacade: CompaniesFacade,
    private brandsService: BrandsService,
    private bindingsService: CustomDnsBindingsService
  ) {
    super();
  }

  protected getSimpleModelItems<T>(
    tag: string,
    cachePrefix: string,
    request: (search: string, take: string) => Observable<T[]>,
    formatter: (item: T) => string,
    addGroupBrackets = true,
    filterDataByTerm: (data: T[], term: string) => T[] = undefined
  ): ModelTemplate<T> {
    return {
      tag,
      items: (state: SmartSearchState) =>
        this.searchTemplate$(state).pipe(
          map((term) => (filterDataByTerm ? '' : term)),
          switchMap((term) =>
            (this.cache[cachePrefix + term]
              ? of(this.cache[cachePrefix + term])
              : request(term, this.maxTakeStr).pipe(tap(this.writeToCache(cachePrefix + term)))
            ).pipe(map((data: T[]) => (filterDataByTerm ? filterDataByTerm(data, term) : data)))
          )
        ),
      itemFormatter: (value: T) => formatter(value),
      addGroupBrackets
    };
  }

  public readonly adminItems: ModelTemplate<AdministratorInCamelCase> = this.getSimpleModelItems<AdministratorInCamelCase>(
    'admin',
    'admin ',
    (search, take) => this.administratorService.getWithQuery({ search, take }),
    (value) => value.email,
    false
  );

  public readonly computerItems: ModelTemplate<Computer> = this.getSimpleModelItems<Computer>(
    'computer',
    'computer ',
    (search, take) => this.computersFacade.getComputers({ search, limit: parseInt(take, 10) }).pipe(map((response) => response.data)),
    (value) => value.displayName || value.name
  );

  public readonly companiesItems: ModelTemplate<Company> = this.getSimpleModelItems<Company>(
    'company',
    'company ',
    (search, take) => this.companiesFacade.getCompanies({ search, limit: parseInt(take, 10) }).pipe(map((response) => response.data)),
    (value) => value.name.toString()
  );

  public readonly brandingCompaniesItems: ModelTemplate<BrandingCompany> = this.getSimpleModelItems<BrandingCompany>(
    'company',
    'brandingCompany_',
    () => this.brandsService.getAllBrandingCompanies(),
    (value) => value.name.toString(),
    true,
    (data, term) => {
      const filtered = this.filterBrandingCompanyByTerm(data, term);
      return filtered.length > this.maxTake ? filtered.slice(0, this.maxTake) : filtered;
    }
  );

  public readonly adminStatusItems: ModelTemplate<string> = {
    tag: SmartSearchTemplateKeys.Status,
    items: (state: SmartSearchState) =>
      this.searchTemplate$(state).pipe(
        map((term) =>
          term
            ? term === EnabledSmartSearchKey || term === DisabledSmartSearchKey
              ? [term]
              : []
            : [EnabledSmartSearchKey, DisabledSmartSearchKey]
        )
      ),
    itemFormatter: (value: string) => value
  };

  public readonly licensesSettingsItems = (isPostPayment: boolean): ModelTemplate<string> => {
    return {
      tag: SmartSearchTemplateKeys.LicensesSettings,
      items: (state: SmartSearchState) =>
        this.searchTemplate$(state).pipe(
          map((term) => {
            const lowerTerm = term.toLocaleLowerCase();
            return EnumHelper.EnumToArray(isPostPayment ? LimitsMode : LicensesSettings)
              .filter((l) => l.toLocaleLowerCase().includes(lowerTerm))
              .slice(0, this.maxTake);
          })
        ),
      itemFormatter: (value: string) => value
    };
  };

  public readonly storageAccountTypeItems: ModelTemplate<string> = {
    tag: 'storage-type',
    items: (state: SmartSearchState) =>
      this.searchTemplate$(state).pipe(
        switchMap((term) => {
          return this.storageAccountsService.loaded$.pipe(
            switchMap((state) => (state ? this.storageAccountsService.entities$ : this.storageAccountsService.getAll())),
            first(),
            map((data) => this.filterStorageTypeByTerm(data, term))
          );
        })
      ),
    itemFormatter: (value: string) => value
  };

  public readonly brandsItems: ModelTemplate<Brand> = this.getSimpleModelItems<Brand>(
    'branding-name',
    'brand_',
    () => this.brandsService.getAll(),
    (value) => value.brandName.toString(),
    true,
    (data, term) => this.filterBrandsByTerm(data, term)
  );

  public readonly CustomBindingsDnsNameItems: ModelTemplate<CustomDnsBinding> = this.getSimpleModelItems<CustomDnsBinding>(
    'dns-name',
    'custom_bindings_dns_name_',
    (search, take) =>
      this.bindingsService.getWithQuery({ search, take }).pipe(map((bindings) => bindings.filter((b) => b.certificate !== 0))),
    (value) => value.dnsName,
    false
  );

  private filterBrandsByTerm(brands: Brand[], term: string): Brand[] {
    return term ? brands.filter((brand) => brand.brandName.toLowerCase().includes(term.toLowerCase())) : brands;
  }

  private filterBrandingCompanyByTerm(brandingCompany: BrandingCompany[], term: string): BrandingCompany[] {
    return term ? brandingCompany.filter((company) => company.name.toLowerCase().includes(term.toLowerCase())) : brandingCompany;
  }
  private filterStorageTypeByTerm(storageAccounts: StorageAccount[], term: string): string[] {
    const storageTypeEnum = StorageType;
    let filtered: StorageAccount[];

    if (term) {
      const termLowerCase = term.toLowerCase();
      filtered = storageAccounts.filter((storageAccount) => {
        return storageTypeEnum[storageAccount.StorageType]
          ? storageTypeEnum[storageAccount.StorageType].toLowerCase().includes(termLowerCase)
          : false;
      });
    } else {
      filtered = storageAccounts.filter((storageAccount) => !!storageTypeEnum[storageAccount.StorageType]);
    }
    const result = filtered
      .sort((a: StorageAccount, b: StorageAccount) =>
        storageTypeEnum[a.StorageType].toLowerCase() > storageTypeEnum[b.StorageType].toLowerCase() ? 1 : -1
      )
      .map((x) => storageTypeEnum[x.StorageType]);

    return [...new Set(result)];
  }
}
