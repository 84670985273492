import { Component, OnInit, ViewChild } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';

@Component({
  selector: 'mbs-cant-open-modal',
  templateUrl: './cant-open-modal.component.html'
})
export class CantOpenModalComponent implements OnInit {
  private readonly default = {
    alertType: MbsPopupType.info,
    title: this.i18nPipe.transform('wizards:virtual_wizards_error_version_title', { format: 'title' }),
    text: this.i18nPipe.transform('wizards:virtual_wizards_error_version_text_alert'),
    okButtonText: null,
    cancelButtonText: this.i18nPipe.transform('buttons:close')
  };

  public alertType = this.default.alertType;
  public title = this.default.title;
  public text = this.default.text;
  public okButtonText = null;
  public cancelButtonText = null;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  constructor(private i18nPipe: I18NextPipe) {}

  ngOnInit(): void {
    this.alertType = this.baseModal?.data?.alertType || this.default.alertType;
    this.okButtonText = this.baseModal?.data?.okButtonText || this.default.okButtonText;
    this.cancelButtonText = this.baseModal?.data?.cancelButtonText || this.default.cancelButtonText;

    if (this.baseModal?.data?.isInvalidFields) {
      this.title = this.baseModal.data.title || this.i18nPipe.transform('wizards:virtual_wizards_error_fields_title', { format: 'title' });
      this.text = this.baseModal.data.text || this.i18nPipe.transform('wizards:virtual_wizards_error_fields_text_alert');
    }
  }

  close(): void {
    this.baseModal.close();
  }

  save(): void {
    this.baseModal.save(true);
  }
}
