<mbs-modal [isCustomModal]="true"
           [loading]="loading">
  <ng-container modal-header>
    <div *ngIf="baseModal?.data?.simpleMode"
         [attr.data-test-element]="elementsSelector.name.simpleModeTitle">
      {{ 'app:allowOfflineEdit.simpleModalTitle' | i18next: { format: 'capitalize' } }}
    </div>

    <div *ngIf="!baseModal?.data?.simpleMode"
         [attr.data-test-element]="elementsSelector.name.notSimpleModeTitle"
         class="header-container">
      <img src="assets/images/other/allowOfflineEdit.svg" width="500" height="240" />
      <div class="header-container-close">
        <mbs-button (click)="tryLaterClickHandler()"
                    [isCtrl]="true"
                    [size]="MbsSize.lg"
                    icon="fa fa-close text-xl"
                    type="dark"
                    aria-label="Close"
                    data-dismiss="modal"></mbs-button>
      </div>
    </div>
  </ng-container>

  <ng-container modal-body>
    <h3 *ngIf="!baseModal?.data?.simpleMode"
        [attr.data-test-element]="elementsSelector.name.notSimpleModeContent"
        class="text-center">
      <mbs-tag [clickable]="false"
               [closable]="false"
               [active]="true"
               [noBorder]="true"
               [class]="'background-dark text-uppercase border-radius-sm-important mr-1'"
               [size]="MbsSize.xs">
        {{ 'app:beta' | i18next }}
      </mbs-tag>

      {{ 'app:allowOfflineEdit.backupPlanManagement' | i18next: { format: 'capitalize' } }} <br>
      <span class="ml-3">{{ 'app:allowOfflineEdit.offlineComputer' | i18next }}</span>
    </h3>
    <span class="d-block mb-2">
      {{ 'app:allowOfflineEdit.modalText' | i18next: { format: 'capitalize' } }}
    </span>
    <span class="d-block mb-3">
      {{ 'app:allowOfflineEdit.modalTextNote' | i18next: { format: 'capitalize' } }}
    </span>

    <mbs-alert class="mb-0"
               [type]="alertType.warning"
               [icon]="true">
      {{'app:allowOfflineEdit.agentVersion' | i18next}}
    </mbs-alert>
  </ng-container>

  <ng-container modalFooter>
    <div *ngIf="baseModal?.data?.simpleMode"
         [attr.data-test-element]="elementsSelector.name.simpleModeButtons">
      <mbs-button type="primary"
                  class="mr-3"
                  (click)="allowClickHandler()">
        {{ 'buttons:allow' | i18next : { format: 'title' } }}
      </mbs-button>
      <mbs-button type="secondary"
                  (click)="tryLaterClickHandler()">
        {{ 'buttons:cancel' | i18next : { format: 'title' } }}
      </mbs-button>
    </div>

    <div *ngIf="!baseModal?.data?.simpleMode"
         [attr.data-test-element]="elementsSelector.name.notSimpleModeButtons">
      <mbs-button type="secondary"
                  class="mr-3"
                  (click)="tryLaterClickHandler()">
        {{ 'app:allowOfflineEdit.tryLater' | i18next: { format: 'title' } }}
      </mbs-button>
      <mbs-button type="primary"
                  (click)="allowClickHandler()">
        {{ 'app:allowOfflineEdit.allow' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>
  </ng-container>
</mbs-modal>
