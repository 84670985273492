import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { PlanTypes } from '@models/PlanTypes.enum';
import { StorageConnection } from '@models/storge-connections';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { TableHeader } from 'mbs-ui-kit/table-grid/models/table-header';
import { GuidEmpty } from 'mbs-ui-kit/utils/constants';
import { Storages } from '../../models/base/base-models/plan-storages-model';
import { WhereBackupStepValue } from '../../models/where-backup-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const WhereBackupStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => WhereBackupStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-where-backup-step',
  templateUrl: './where-backup-step.component.html',
  providers: [WhereBackupStepValueAccessor]
})
export class WhereBackupStepComponent extends StepBase<WhereBackupStepValue> implements OnInit {
  @Input() Storages: Storages = null;
  @Output() changeStorage = new EventEmitter();
  @Output() hybridChangedToDefault = new EventEmitter();
  public readonly elementsSelector = {
    name: {
      configNotSupportedAlert: 'configNotSupportedAlert',
      noStoragesAlert: 'noStoragesAlert',
      needNewStorageAlert: 'needNewStorageAlert',
      notLocalStorageAlert: 'notLocalStorageAlert',
      radioButtonsBox: 'radioButtonsBox',
      loaderItem: 'loaderItem',
      listSelectLocalOrCloud: 'listSelectLocalOrCloud',
      listSelectLocal: 'listSelectLocal'
    }
  };
  public CloudStorages: StorageConnection[] = [];
  public LocalStorages: StorageConnection[] = [];

  public MixStorages: StorageConnection[] = [];

  public selectedLocalStorages: string[] = [];
  public selectedMixStorages: string[] = [];

  public correctlyVersionForShowHybrid = false;
  public isNotValidFirstTable = false;
  public isNotValidHybridTable = false;
  public firstLoad = true;

  public headers: TableHeader[] = [
    {
      name: '',
      gridColSize: '10fr',
      class: '-stretch'
    }
  ];

  public notLocalStorageTexts = this.i18nPipe.transform('wizards:not_local_storage_text', { returnObjects: true });

  get canBeHybrid(): boolean {
    return this.planType !== PlanTypes.CloudVMBackupEC2Plan && !this.isRestore;
  }

  get loadingTableDataForLocalOrCloudTable(): boolean {
    return !this.Storages;
  }

  get notExistRepositoryForCorrectStepState(): boolean {
    return this.stepForm.get('IsHybridBackup').value ? !this.CloudStorages.length : !this.MixStorages.length;
  }

  get needShowRadioButtons(): boolean {
    return (
      this.supportedModsAndTypes && !this.notExistRepositoryForCorrectStepState && this.existAllStorages && !this.needHideHybridControls
    );
  }

  private get needHideHybridControls(): boolean {
    return this.isEdit && (this.planType === PlanTypes.BackupDiskImagePlan || this.correctlyVersionForShowHybrid);
  }

  private get existAllStorages(): boolean {
    return this.Storages && (this.isSQLPlan || !!this.Storages.LocalStorages.length) && !!this.Storages.CloudStorages.length;
  }

  private get supportedModsAndTypes(): boolean {
    return !this.isRDMode && !this.isNBF && !this.isRestore && !this.isLinux;
  }

  constructor(
    public ability: AbilityService,
    private cdr: ChangeDetectorRef,
    public mainService: RemoteManagementWizardsService,
    public i18nPipe: I18NextPipe
  ) {
    super(mainService);
    this.correctlyVersionForShowHybrid = mainService.backupVersionUpdated && +mainService.backupVersionUpdated.substring(0, 3) > 711;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      IsHybridBackup: new FormControl({ value: false, disabled: this.isRDMode || !this.canBeHybrid }),
      ConnectionID: new FormControl(''),
      HybridID: new FormControl('')
    });

    this.initFormEvents();
  }

  onStepFormChange(value: WhereBackupStepValue): void {
    this.updateSelectedItems(value.HybridID, value.ConnectionID);
    if (this.stepForm.dirty || this.stepForm.touched) {
      const hybridNotEmpty = value.HybridID && value.HybridID !== GuidEmpty;
      this.isNotValidFirstTable = !value.ConnectionID || value.ConnectionID === GuidEmpty;
      this.isNotValidHybridTable = this.stepForm.get('IsHybridBackup').value && (this.LocalStorages.length == 0 || !hybridNotEmpty);
      const isStorageValid = hybridNotEmpty || !this.isNotValidFirstTable;
      const valid = !this.isNotValidHybridTable && this.stepForm.valid && isStorageValid;
      this.value = { ...value, valid };
    }
  }

  updateForm(value: WhereBackupStepValue): void {
    if (this.isRDMode) {
      if (value.IsHybridBackup || (value.HybridID && value.HybridID !== GuidEmpty)) {
        value.IsHybridBackup = false;
        value.HybridID = GuidEmpty;
        this.importedPlanChanged = true;
        this.hybridChangedToDefault.emit(true);
      }

      if (this.isEdit && value.ConnectionID === this.value.ConnectionID) {
        this.changeSelectedHandler([value.ConnectionID], true);
      }
    }

    this.stepForm.reset(value);

    if (value.IsHybridBackup) this.LocalStorages.forEach((storage: StorageConnection) => (storage.disabled = this.needHideHybridControls));
  }

  forceValid(): void {
    this.stepForm.markAllAsTouched();
    this.stepForm.updateValueAndValidity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Storages && changes.Storages.currentValue && !this.MixStorages.length) {
      this.CloudStorages = changes.Storages.currentValue.CloudStorages;
      this.LocalStorages = changes.Storages.currentValue.LocalStorages;
      this.MixStorages = cloneDeep(this.CloudStorages).concat(cloneDeep(this.LocalStorages));
      this.stepForm && this.stepForm.updateValueAndValidity();
      if (this.stepForm && !this.existAllStorages) this.stepForm.get('IsHybridBackup').reset(false);
    }
  }

  updateSelectedItems(HybridID: string, ConnectionID: string): void {
    if (this.stepForm.get('IsHybridBackup').value) {
      if (!this.idIsNotEmpty(ConnectionID) && this.CloudStorages.length === 1) {
        this.selectFirstItems('ConnectionID', 'CloudStorages');
        return;
      }
      if (!this.idIsNotEmpty(HybridID) && this.LocalStorages.length === 1) {
        this.selectFirstItems('HybridID', 'LocalStorages');
        return;
      }
      if (this.isValidId(ConnectionID, true, true) && this.isValidId(HybridID, false, true))
        this.updateSelectedIfHybrid(HybridID, ConnectionID);
      else this.resetFormItemsAndValidate();
    } else {
      const id = ConnectionID && ConnectionID !== GuidEmpty ? ConnectionID : HybridID;
      if (!this.idIsNotEmpty(id) && this.MixStorages.length === 1) {
        this.selectFirstItems('ConnectionID', 'MixStorages');
        return;
      }
      if (this.isValidId(id)) this.updateSelectedIfNotHybrid(id);
      else this.resetFormItemsAndValidate();
    }
    this.cdr.detectChanges();
  }

  updateSelectedIfHybrid(HybridID: string, ConnectionID: string): void {
    if (this.isValidId(ConnectionID, true, true) && this.isValidId(HybridID, false, true)) {
      if (HybridID) this.selectedLocalStorages = [HybridID];
      if (ConnectionID) this.selectedMixStorages = [ConnectionID];
      if (this.isEdit && this.firstLoad) {
        this.updateStorageArray('LocalStorages', this.selectedLocalStorages[0]);
        this.updateStorageArray('CloudStorages', this.selectedMixStorages[0]);
        if (!this.firstLoad) this.stepForm.updateValueAndValidity();
      }
    } else this.resetFormItemsAndValidate();
  }

  updateSelectedIfNotHybrid(id): void {
    if (this.isValidId(id)) {
      this.selectedMixStorages = [id];
      if (this.isEdit && this.firstLoad) {
        this.updateStorageArray('MixStorages', id);
        if (this.isRDMode) {
          const selected = this.MixStorages.find((i) => i.ID === id);
          selected && this.changeStorage.emit(selected);
        }
        if (!this.firstLoad) this.stepForm.updateValueAndValidity();
      }
    } else this.resetFormItemsAndValidate();
  }

  updateStorageArray(arrayName: string, selectedId: string): void {
    const idx = this[arrayName].findIndex((i) => i.ID === selectedId);
    if (~idx) {
      this[arrayName].unshift(...this[arrayName].splice(idx, 1));
      this[arrayName] = Array.from(this[arrayName]);
      this.firstLoad = false;
    }
  }

  isValidId(id: string, isConnection = true, isHybrid = false): boolean {
    if (!this.idIsNotEmpty(id)) return true;
    if (isHybrid) return isConnection ? this.MixStorages.some((i) => i.ID === id) : this.LocalStorages.some((i) => i.ID === id);
    return this.MixStorages.some((i) => i.ID === id);
  }

  resetFormItemsAndValidate(): void {
    this.firstLoad = false;
    const data = this.stepForm.value;
    data.ConnectionID = GuidEmpty;
    data.HybridID = GuidEmpty;
    if (this.stepForm && !this.existAllStorages) data.IsHybridBackup = false;
    this.stepForm.reset(data);
    setTimeout(() => this.forceValid());
  }

  changeSelectedHandler(selectedStorage: string[], firstTable = true): void {
    if (selectedStorage.length) {
      if (firstTable) {
        this.stepForm.get('ConnectionID').setValue(selectedStorage[0]);
        const selected = this.MixStorages.find((i) => i.ID === selectedStorage[0]);
        selected && setTimeout(() => this.changeStorage.emit(selected));
      } else if (this.value?.IsHybridBackup) this.stepForm.get('HybridID').setValue(selectedStorage[0]);
    } else {
      if (firstTable) {
        this.stepForm.get('ConnectionID').setValue(GuidEmpty);
        setTimeout(() => this.changeStorage.emit(null));
      } else if (this.value?.IsHybridBackup) this.stepForm.get('HybridID').setValue(GuidEmpty);
    }

    if ((!this.isEdit || !firstTable) && this.value?.IsHybridBackup) this.disableFieldsInLists(selectedStorage[0], firstTable);
  }

  clickHandler(): void {
    if (!this.stepForm.dirty) {
      this.stepForm.markAsDirty();
      this.stepForm.updateValueAndValidity();
    }
  }

  switchToLocalOrCloudOption(event): void {
    event.preventDefault();
    this.stepForm.get('IsHybridBackup').setValue(false);
  }

  isHybridBackupChangeHandler(event): void {
    const ConnectionID = this.stepForm.value.ConnectionID;
    if (event) {
      if (~this.LocalStorages.findIndex((s) => s.ID === ConnectionID)) {
        this.stepForm.get('HybridID').setValue(ConnectionID);
        this.stepForm.get('ConnectionID').setValue(GuidEmpty);
      }
    } else {
      const value = ConnectionID && ConnectionID !== GuidEmpty ? ConnectionID : this.stepForm.value.HybridID;
      this.stepForm.get('HybridID').setValue(GuidEmpty);
      this.stepForm.get('ConnectionID').setValue(value);
      this.updateStorageArray('MixStorages', value);
      this.disableFieldsInLists(null, false);
    }
  }

  selectFirstItems(fieldName: string, storageArrayName: string): void {
    this.stepForm.get(fieldName).patchValue(this[storageArrayName][0].ID);
    setTimeout(() => {
      this.changeStorage.emit(this[storageArrayName][0]);
      this.forceValid();
    });
  }

  idIsNotEmpty(id): boolean {
    return !!id && id !== GuidEmpty;
  }

  private disableFieldsInLists(id: string, firstTable: boolean): void {
    this[firstTable ? 'LocalStorages' : 'MixStorages'].forEach((storage: StorageConnection) => (storage.disabled = storage.ID === id));

    this.cdr.detectChanges();
  }
}
