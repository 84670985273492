<h4 class="mb-0">
  <span class="fa fa-long-arrow-right"></span>
  <span class="fa fa-desktop ml-1"></span>
  <span class="ml-2">{{ 'download-connect.module:connectTo' | i18next: { format: 'title' } }}</span>
</h4>

<p class="mb-0 mb-4">
  {{ 'download-connect.module:installRa' | i18next }} {{ 'download-connect.module:connectTo' | i18next }}
</p>

<ng-container *ngFor="let build of (builds$ | async)">
  <mbs-download-build [build]="build"
                      (raActionButtonClick)="configureButtonClick.emit(true)"
                      [showDeleteButton]="false"></mbs-download-build>
</ng-container>

<ng-container *ngIf="!hideConnectFrom">
  <section class="border-top mt-4">
    <h4 class="mb-0 mt-3">
      <span class="fa fa-desktop"></span>
      <span class="fa fa-long-arrow-right ml-1"></span>
      <span class="ml-2">{{ 'download-connect.module:connectFrom' | i18next: { format: 'title' } }}</span>
    </h4>

    <p>
      {{ 'download-connect.module:installRa' | i18next }} {{ 'download-connect.module:connectFrom' | i18next }}
    </p>
    <ul class="list-unstyled ml-3">
      <li class="mb-3">
        <a href="{{ connectClientUrl }}" target="_blank">
          <span class="download-connect-icon ico ico-windows"></span>
          <span class="ml-3">{{'download-connect.module:raClient.windows'| i18next}}</span>
        </a>
      </li>
      <li *ngFor="let platform of keys(raClientConfig)"
          class="mb-3">
        <a
          href="{{ environment.raDownloadURL[platform] }}"
          target="_blank">
          <span [class]="raClientConfig?.[platform]"></span>
          <span
            class="ml-3">{{'download-connect.module:raClient.' + platform | i18next}}</span>
        </a>
      </li>
    </ul>
  </section>
</ng-container>
