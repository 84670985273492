import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';
import { ApplicationState } from './state';

const selectApplicationState = createFeatureSelector<ApplicationState>(StoreTypes.Application);

export const selectMbsRaState = createSelector(selectApplicationState, (store: ApplicationState) => store.mbsRA);
export const selectCurrentAdmin = createSelector(selectApplicationState, (state: ApplicationState) => state.currentAdmin);
export const selectFooterExist = createSelector(selectApplicationState, (store: ApplicationState) => store.footerExist);
export const selectStorage = createSelector(selectApplicationState, (store: ApplicationState) => store.storage);
