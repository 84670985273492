import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { getInvalidDiffTime, getSecondsFromDate } from '@components/tfa/utils/date';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';

@Component({
  selector: 'mbs-incorrect-time-alert',
  templateUrl: './incorrect-time-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncorrectTimeAlertComponent implements OnInit {
  @Input() expiredDate: string | Date;

  public timeToExpire: number;

  protected readonly getInvalidDiffTime = getInvalidDiffTime;
  protected readonly AlertType = AlertType;

  ngOnInit() {
    this.timeToExpire = getSecondsFromDate(this.expiredDate);
  }
}
