<mbs-modal [titleTextOverflow]="true"
           [loading]="(licensesService?.licensesLoading$ | async) || (licensesService?.licenseRequestPending$ | async)">
  <ng-container modal-header>
    <mbs-text-ellipsis placement="top-left auto"
                       tooltipClass="tooltip-lg d-block"
                       [tooltip]="'Grant License to ' + settings?.adminEmail">
      {{
      'licenses:assignLicenseDialogueComponent:assignLicenseTo'
      | i18next
      : {
      action: (isPostPayment | async) ? ('licenses:actions:assign' | i18next) : ('licenses:actions:grant' | i18next),
      email: settings?.adminEmail
      }
      }}
    </mbs-text-ellipsis>
  </ng-container>
  <ng-container modal-body>
    <mbs-table-grid [changeSortState]="orderBy"
                    [data]="licenses"
                    [headers]="headers"
                    [showNav]="false"
                    [viewMode]="sharedPersistentStateEnum.table"
                    [disableViewSwitch]="true"
                    (sort)="handleSort($event)">
      <ng-template let-license
                   mbsTableCell>
        {{ license.licenseDisplayName }}
      </ng-template>
      <ng-container *ngIf="!(isPostPayment | async)">
        <ng-template let-license
                     mbsTableCell>
          {{ license.count }}
        </ng-template>
      </ng-container>
      <ng-template let-license
                   mbsTableCell>
        <mbs-button [type]="'success'"
                    *ngIf="license.count && license.count > 0"
                    [size]="'xs'"
                    (click)="grantLicense(license)">{{
          (isPostPayment | async) ? ('licenses:actions:assign' | i18next) : ('licenses:actions:grant' | i18next)
          }}</mbs-button>
      </ng-template>
      <ng-template let-license
                   mbsTableCell
                   *ngIf="settings?.showTrials">
        <mbs-button [type]="'success'"
                    *ngIf="license?.canTrial"
                    [size]="'xs'"
                    (click)="grantLicense(license, true)">Trial</mbs-button>
      </ng-template>
    </mbs-table-grid>
  </ng-container>

  <ng-container modalFooter>
    <div class="w-100 d-flex justify-content-between">
      <button type="button"
              class="btn btn-success"
              (click)="buyNewClickHandler()"
              *ngIf="settings?.showBuyBtn">{{ 'licenses:assignLicenseDialogueComponent:buyNew' | i18next }}</button>
      <button type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              (click)="cancelClickHandler()">Cancel</button>
    </div>
  </ng-container>
</mbs-modal>
