import { Component, Input } from '@angular/core';
import * as SummarySelectors from '@modules/summary-computers/store/summary-computer.selectors';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import * as moment from 'moment';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DateTimeFormatComponent } from './date-time-format.component';

@UntilDestroy()
@Component({
  selector: 'mbs-rmm-date-time-format',
  templateUrl: './date-time-format.component.html'
})
export class RmmDateTimeFormatComponent extends DateTimeFormatComponent {
  @Input() hid: string;

  constructor(public auth: AuthService, private store: Store) {
    super(auth);
  }

  getAgentUtcOffsetValue() {
    return of(this.hid).pipe(
      switchMap((hid) =>
        hid ? this.store.select(SummarySelectors.selectSystemTimeByHid(hid)) : this.store.select(SummarySelectors.selectCurrentSystemTime)
      ),
      map((data) => {
        if (data?.value) {
          const regexp = /UTC(.*)\)/;
          const utcOffsetValue = regexp.exec(data.value)[1];
          return utcOffsetValue ? moment().utcOffset(utcOffsetValue).utcOffset() : null;
        } else {
          return null;
        }
      })
    );
  }
}
