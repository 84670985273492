import * as moment from 'moment';
import { AgentProviderLocalDateTime, mediumDateWithTimeMoment } from '../date';
import { DateTimeUtils } from '../DateTimeUtils';
import { combineLatest, map, Observable, switchMap } from 'rxjs';

export class DateTimeFormatterHelper {

  public static setTime(
    timeInput$: Observable<string>,
    agentDiff$: Observable<number>,
    providerDiff$: Observable<number>,
    defaultText: string,
    isAgentTime = false,
    dateTimeFormat = mediumDateWithTimeMoment
  ): Observable<AgentProviderLocalDateTime> {
    return timeInput$.pipe(
      map((time) => new Date(time)),
      switchMap((time) =>
        combineLatest([agentDiff$, providerDiff$]).pipe(
          map(([agentDiff, providerDiff]) => ({
            agentDiff,
            providerDiff,
            raw: moment(time).utc().toISOString()
          })),
          map(({ raw, agentDiff, providerDiff }) => this.calcTime(raw, agentDiff, providerDiff, defaultText, isAgentTime, dateTimeFormat))
        )
      )
    );
  }

  /**
   * Calculate output time
   *
   * @private
   * @param {string} time
   * @param {number} agentDiff
   * @param {number} providerDiff
   * @param {string} defaultText
   * @param {boolean} isAgentTime
   * @param {string} dateTimeFormat
   * @return {AgentProviderLocalDateTime}
   */
  private static calcTime(
    time: string,
    agentDiff: number,
    providerDiff: number,
    defaultText: string,
    isAgentTime: boolean,
    dateTimeFormat = mediumDateWithTimeMoment
  ): AgentProviderLocalDateTime {
    const DEFAULT = {
      time: defaultText,
      utc: ''
    };

    if (time === null || moment(time).isSame('0001-01-01T00:00:00.000Z'))
      return {
        isEmpty: true,
        raw: {
          time: DEFAULT.time
        },
        agent: DEFAULT,
        provider: DEFAULT,
        local: DEFAULT
      };

    const formedDateWithAgentDiff = moment(time).utcOffset(agentDiff);

    const agent = moment(isAgentTime ? formedDateWithAgentDiff : time).utcOffset(agentDiff);
    const provider = moment(time).utcOffset(providerDiff);
    const local = moment(time);

    return {
      raw: {
        time: moment(isAgentTime ? formedDateWithAgentDiff : time).format(dateTimeFormat)
      },
      agent: this.utcOffsetWereProvided(agentDiff)
        ? {
            utc: this.humanizeUtcOffset(agent),
            time: agent.format(dateTimeFormat)
          }
        : DEFAULT,
      provider: this.utcOffsetWereProvided(providerDiff)
        ? {
            utc: this.humanizeUtcOffset(provider),
            time: provider.format(dateTimeFormat)
          }
        : DEFAULT,
      local: {
        utc: this.humanizeUtcOffset(local),
        time: local.format(dateTimeFormat)
      }
    };
  }

  /**
   * Humanize time
   *
   * @private
   * @param {moment.Moment} moment
   * @return {string}
   */
  private static humanizeUtcOffset(moment: moment.Moment): string {
    const offset = -1 * moment.utcOffset();
    return `(UTC${DateTimeUtils.NormalizeUtcOffset(offset)})`;
  }

  /**
   * Humanize time
   *
   * @private
   * @param {number} value
   * @return {boolean}
   */
  private static utcOffsetWereProvided(value: number): boolean {
    return !!(value === Number('-0') || value);
  }
}
