import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import * as ComputersStatisticActions from './actions';
import { ComputersStatisticState, initialComputersStatisticState } from './state';

const reducer = createReducer(
  initialComputersStatisticState,
  on(ComputersStatisticActions.loadHealthData, (state, action): ComputersStatisticState => {
    return action.silent ? state : { ...state, healthLoading: true };
  }),
  on(ComputersStatisticActions.loadHealthDataSuccess, (state, action): ComputersStatisticState => {
    const total = action.data?.healthy + action.data?.problem + action.data?.warning;
    return { ...state, healthState: action.data, healthTotal: total, healthLoading: false, healthError: null, healthLoaded: true };
  }),
  on(ComputersStatisticActions.loadHealthDataError, (state, action): ComputersStatisticState => {
    return { ...state, healthLoading: false, healthError: action.error };
  }),

  on(ComputersStatisticActions.loadStateData, (state, action): ComputersStatisticState => {
    return action.silent ? state : { ...state, stateLoading: true };
  }),
  on(ComputersStatisticActions.loadStateDataSuccess, (state, action): ComputersStatisticState => {
    const total = action.data?.active + action.data?.hidden;
    return {
      ...state,
      state: action.data,
      stateTotal: total,
      stateLoading: false,
      isStateEmpty: !total,
      stateError: null,
      stateLoaded: true
    };
  }),
  on(ComputersStatisticActions.loadStateDataError, (state, action): ComputersStatisticState => {
    return { ...state, stateLoading: false, stateError: action.error, isStateEmpty: false };
  }),

  on(ComputersStatisticActions.loadStatistic, (state, action): ComputersStatisticState => {
    return action.silent ? state : { ...state, statisticLoading: true };
  }),
  on(ComputersStatisticActions.loadStatisticSuccess, (state, action): ComputersStatisticState => {
    const healthTotal = action.data?.healthState?.healthy + action.data?.healthState?.problem + action.data?.healthState?.warning;
    const stateTotal = action.data?.state?.active + action.data?.state?.hidden;
    return {
      ...state,
      healthState: action.data?.healthState,
      healthTotal: healthTotal,
      healthError: null,
      healthLoaded: true,
      state: action.data?.state,
      stateTotal: stateTotal,
      isStateEmpty: !stateTotal,
      stateError: null,
      stateLoaded: true,
      authStatus: action.data?.authStatus,
      statisticLoading: false,
      statisticLoaded: true,
      versionStatus: action.data?.versionStatus
    };
  }),
  on(ComputersStatisticActions.loadStatisticError, (state, action): ComputersStatisticState => {
    return { ...state, statisticLoading: false, statisticError: action.error, isStateEmpty: false };
  }),

  on(ComputersStatisticActions.clear, (): ComputersStatisticState => {
    return cloneDeep(initialComputersStatisticState);
  }),

  on(ComputersStatisticActions.checkOneComputerExist, (state, action): ComputersStatisticState => {
    if (state.oneComputerExistLoaded && !action.force) return state;

    return { ...state, oneComputerExistLoading: true };
  }),
  on(ComputersStatisticActions.checkOneComputerSuccess, (state, action): ComputersStatisticState => {
    return {
      ...state,
      oneComputerExist: action.data,
      oneComputerExistLoading: false,
      oneComputerExistLoaded: true
    };
  }),
  on(ComputersStatisticActions.checkOneComputerError, (state): ComputersStatisticState => {
    return { ...state, oneComputerExistLoading: false };
  })
);

export function computersStatisticReducer(state: ComputersStatisticState | undefined, action: Action) {
  return reducer(state, action);
}
