import HostInfo, { LocationInfo } from '@models/rmm/HostInfo';
import HostVmOptionInfo from '@models/rmm/HostVmOptionInfo';
import { VirtualMachineType } from '@models/VirtualMachineType';
import { virtualMachineDisplay } from '@shared/utils/pipes/virtual-machine-type-display.pipe';
import { bytesFrom, formatBytes } from 'mbs-ui-kit/utils/converter';
import * as moment from 'moment';
import 'moment-duration-format';

type HostInfoRow = { key: string; value: any; visible?: () => boolean };

function getSystemUpdate(uptime: string): string {
  return moment.duration(uptime, 'seconds').format('d [days] HH [hours] mm [minutes]');
}

function getLocationInfo(location: string): string {
  if (location) {
    const parsedLocation: LocationInfo = JSON.parse(location);
    if (parsedLocation.country && parsedLocation.city) return `${parsedLocation.country} ${parsedLocation.city}`;
    if (parsedLocation.continentName) return parsedLocation.continentName;
  }
  return null;
}

function showVMInfo(vmInfo: HostVmOptionInfo): boolean {
  const visibleVM = new Set([VirtualMachineType.AmazonV1, VirtualMachineType.AmazonV2, VirtualMachineType.Google]);
  return vmInfo && visibleVM.has(vmInfo.enumerator);
}

function getGeneralHostInfo(hostData: HostInfo, online: boolean): HostInfoRow[] {
  if (!hostData) {
    return [];
  }

  return [
    { key: 'Computer Name', value: hostData.computerName },
    { key: 'Last Logged in User', value: `${hostData.userDomainName}\\${hostData.userName}` },
    { key: 'Last Login Time', value: hostData.lastLogin },
    { key: 'Domain', value: hostData.domainName },
    { key: 'IP Address', value: hostData.network ? hostData.network.ip4Address : '' },
    {
      key: 'Virtualization',
      value: hostData.vm ? virtualMachineDisplay(hostData.vm.enumerator) : '',
      visible: () => !!hostData.vm
    },
    { key: 'Instance ID', value: hostData.vm ? hostData.vm.instanceID : '', visible: () => showVMInfo(hostData.vm) },
    { key: 'Physical Memory', value: formatBytes(+hostData.totalPhysicalMemory * bytesFrom('KB')) },
    { key: 'Virtual Memory', value: formatBytes(+hostData.totalVirtualMemory * bytesFrom('KB')) },
    { key: 'Processor', value: hostData.cpuName },
    { key: 'Socket Count', value: hostData.physicalProcessorsCount },
    { key: 'Operating System', value: hostData.osName },
    { key: 'OS Type', value: hostData.productType },
    { key: 'Platform Type', value: hostData.systemType },
    { key: 'Manufactured', value: hostData.manufactured },
    { key: 'Model', value: hostData.model },
    { key: 'Serial Number', value: hostData.serialNumber },
    { key: 'System Uptime', value: getSystemUpdate(hostData.systemUptime), visible: () => online },
    { key: 'Net Framework Version', value: hostData.frameworkVersion },
    { key: 'TimeZone', value: hostData.timeZone },
    { key: 'Culture', value: hostData.curCultureName },
    { key: 'Location', value: getLocationInfo(hostData.location), visible: () => !!hostData.location }
  ];
}

export { HostInfoRow, getGeneralHostInfo };
