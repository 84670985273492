import { Component, ViewChild } from '@angular/core';
import { ApplicationStateFacade } from '@facades/application.facade';
import { UiStorageKey } from '@models/ui-storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services';
import { onlyDateFormat } from '@utils/date';
import { MbsPopupType, MbsSize, ModalComponent } from 'mbs-ui-kit';
import moment from 'moment';
import { first } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-allow-offline-edit-modal',
  styleUrls: ['./allow-offline-edit-modal.component.scss'],
  templateUrl: './allow-offline-edit-modal.component.html'
})
export class AllowOfflineEditModalComponent {
  public readonly MbsSize = MbsSize;
  public readonly alertType = MbsPopupType;

  public readonly elementsSelector = {
    name: {
      simpleModeTitle: 'simpleModeTitle',
      notSimpleModeTitle: 'notSimpleModeTitle',
      notSimpleModeContent: 'notSimpleModeContent',
      simpleModeButtons: 'simpleModeButtons',
      notSimpleModeButtons: 'notSimpleModeButtons'
    }
  };

  public loading = false;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private applicationStateFacade: ApplicationStateFacade, private auth: AuthService) {}

  tryLaterClickHandler(): void {
    if (!this.baseModal?.data?.simpleMode) this.updateStorageData(true);

    localStorage.removeItem('isOpenAllowOfflineEdit');
    this.auth.fetchCurrentUser().pipe(first()).subscribe();
    this.baseModal.close();
  }

  allowClickHandler(): void {
    this.loading = true;

    this.auth
      .allowOfflineEdit()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.updateStorageData();
          localStorage.removeItem('isOpenAllowOfflineEdit');
          this.baseModal.save();
          this.loading = false;
        },
        error: () => (this.loading = false)
      });
  }

  private updateStorageData(needUpdate = false): void {
    const allowEdit = this.baseModal.data.allowOfflineEdit || {};

    if (needUpdate) {
      allowEdit[this.baseModal.data.id] = {
        showed: (allowEdit[this.baseModal.data.id]?.showed || 0) + 1,
        lastShow: moment().format(onlyDateFormat)
      };

      return void this.applicationStateFacade
        .setStorageSetting(UiStorageKey.AllowOfflineEdit, JSON.stringify(allowEdit))
        .pipe(first())
        .subscribe();
    }

    const newData = Object.keys(allowEdit).reduce((acc, next) => {
      if (next !== this.baseModal.data.id) acc[next] = this.baseModal.data.allowOfflineEdit[next];

      return acc;
    }, {});

    this.applicationStateFacade.setStorageSetting(UiStorageKey.AllowOfflineEdit, JSON.stringify(newData)).pipe(first()).subscribe();
  }
}
