import { Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-super-admin-modal-component',
  templateUrl: './remote-connection-super-admin-modal.component.html'
})
export class RemoteConnectionSuperAdminModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  mbsPopupType = MbsPopupType;
}
