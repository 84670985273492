<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal>

  <div modal-body>
    {{ (baseModal.data.itemsCount === 1 ? "licenses:enableAutoRenewalModal:descriptionForOneLicense" :
    "licenses:enableAutoRenewalModal:description") | i18next: { count: baseModal.data.itemsCount } }}
    <mbs-alert [type]="alertType.warning"
               [icon]="true"
               *ngIf="!this.baseModal.data.isAdminPage"
               class="mt-3">
      {{ "licenses:enableAutoRenewalModal:hint" | i18next }}
    </mbs-alert>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                [loading]="loading"
                (click)="enable()">{{ 'licenses:enableAutoRenewalModal:submit' | i18next: { format: 'title' } }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'licenses:common:cancel' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
