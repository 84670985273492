import { Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import Computer from '@models/Computer';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-not-available-modal-component',
  templateUrl: './remote-connection-not-available-modal.component.html'
})
export class RemoteConnectionNotAvailableModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  computer: Computer;
  mbsPopupType = MbsPopupType;

  get computerName(): string {
    return Computer.getComputerName(this.computer);
  }

  ngOnInit(): void {
    if (this.baseModal.data) {
      this.computer = this.baseModal.data.computer;
    }
  }
}
