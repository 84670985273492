<mbs-sidepanel id="computer_backup_info"
               side="right"
               [large]="true"
               [innerContentType]="'tabs'"
               [hideSave]="true"
               [showDelete]="false"
               showFooter="true"
               [headerClass]="'-light'"
               [loadingData]="loadingData"
               [showExpandedCross]="true"
               (panelClosed)="closeHandler()"
               (expandedChange)="fullscreen=$event">
  <div header-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-ico"
         [ngClass]="isOnline ? 'text-primary' : 'text-muted'">
      <span class="{{ computer | computerOsIcon }}"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      {{computerName}} <small>({{onlineStatus}})</small>
    </div>
  </div>
  <div loading-data-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-ico">
      <span class="fa fa-windows"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      {{ 'app:common.loadingComputerInfo' | i18next }}
    </div>
  </div>


  <ng-container body>
    <mbs-alert *ngIf="this.offlineAlert"
               [type]="AlertType.warning"
               [icon]="true"
               class="mb-0">
      {{ this.offlineAlert }}
    </mbs-alert>

    <div class="mbs-sidepanel_tabset -vertical">
      <div class="d-flex h-100 flex-column justify-content-between">

        <ul mbsTabset
            mbsTabSetValidated
            mode="vertical"
            (afterUpdate)="handleAfterTabChange($event)"
            #activeContent="mbsTabset">
        <li [mbsTabsetItem]="panelTab.backupStorage"
            [fullHeight]="fullscreen"
            [mbsTabSetValid]="false"
            [loading]="true">
            <button mbsTabsetLink>Backup Storage{{isBeta ? ' (BETA)' : ''}}</button>
            <ng-template mbsTabsetContent>
              <mbs-backup-storage-tab *ngIf="isBeta"
                                      [fullscreen]="fullscreen"
                                      [isOnlineAccess]="true"
                                      (fullScreenChange)="fullScreenChangeHandler()"></mbs-backup-storage-tab>
              <mbs-backup-storage-legacy-tab *ngIf="!isBeta"></mbs-backup-storage-legacy-tab>
            </ng-template>
          </li>
        </ul>

        <ul class="mbs-tabset -vertical">
          <li class="mbs-tabset_item">
            <mbs-connect-button-group [computer]="computer"
                                      [isCtrl]="true"
                                      [customClass]="'mbs-tabset_link pr-1 py-1'"
                                      [dropdownPlacement]="'top-right'"></mbs-connect-button-group>
          </li>
        </ul>
      </div>

      <div class="mbs-form_content"
           [mbsTabsetOutlet]="activeContent"></div>
    </div>
  </ng-container>


  <ng-template #footerTemplate>
    <div class="w-100 d-flex align-items-center"
         [class.justify-content-between]="!fullscreen"
         [class.justify-content-end]="fullscreen">
      <mbs-switcher *ngIf="!fullscreen"
                    [(ngModel)]="isBeta"
                    label="Try new side panel (BETA)"></mbs-switcher>
      <mbs-button type="secondary"
                  (click)="close.emit()">
        {{ 'buttons:close' | i18next }}
      </mbs-button>
    </div>
  </ng-template>
</mbs-sidepanel>
