import { Injectable } from '@angular/core';
import { UiStorageKey, UiStorageType } from '@models/ui-storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { UiStorageService } from '@services/ui-storage.service';
import { ApplicationStoreActions, ApplicationStoreSelectors } from '@store/application';
import { Observable, of, take } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ApplicationStateFacade {
  constructor(private store: Store, private uiStorage: UiStorageService) {}

  getUiStorage(): Observable<UiStorageType> {
    return this.store.select(ApplicationStoreSelectors.selectStorage).pipe(
      untilDestroyed(this),
      take(1),
      switchMap((storage) => {
        if (storage) return of(storage);

        return this.uiStorage.getSettings().pipe(
          tap((storage) => {
            this.store.dispatch(ApplicationStoreActions.setStorage({ payload: storage }));
          }),
          catchError(() => of({}))
        );
      })
    );
  }

  setStorageSetting(key: UiStorageKey, value: any, addAuditLogRecord = false): Observable<UiStorageType> {
    return this.uiStorage.storeSetting(key, value, addAuditLogRecord).pipe(
      untilDestroyed(this),
      take(1),
      switchMap(() => {
        return this.getUiStorage();
      }),
      tap(() => {
        this.store.dispatch(
          ApplicationStoreActions.setStorageSetting({
            payload: { key, value }
          })
        );
      })
    );
  }
}
