import { PlanInfo } from '@models/backup/plan-info-model';
import { CloudStorageProvider, StorageType } from '@models/StorageType.enum';
import { GuidEmpty } from 'mbs-ui-kit';

export function getDestinationIconType(
  plan: PlanInfo,
  destinationTypes: { [key: string]: StorageType | CloudStorageProvider }
): StorageType | CloudStorageProvider {
  const knownDestinations = Object.keys(destinationTypes);
  
  if (!knownDestinations.includes(plan.destinationId) || plan.destinationId === GuidEmpty) {
    return null;
  }

  return (
    (destinationTypes && destinationTypes[plan.destinationId]) ||
    (plan.destinationProviderType === CloudStorageProvider.LocalFS ? CloudStorageProvider.Amazon : plan.destinationProviderType)
  );
}
