import { Component, ViewChild } from '@angular/core';
import Computer from '@models/Computer';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalComponent } from 'mbs-ui-kit';
import { ConnectionLicenseType } from '../../computers-shared/enums';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-web-license-select-modal-component',
  templateUrl: './remote-connection-web-license-select-modal.component.html'
})
export class RemoteConnectionWebLicenseSelectModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public connectionLicenseType = ConnectionLicenseType;
  public computer: Computer;

  public type = ConnectionLicenseType.Backup;

  public get isMacOrLinux(): boolean {
    return Computer.isMacOrLinux(this.computer);
  }

  ngAfterViewInit(): void {
    if (this.baseModal.data) {
      this.computer = this.baseModal.data.computer;
    }
  }
}
