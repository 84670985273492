export enum ConnectSettingsScope {
  Global = 'global',
  Computer = 'computer',
  Company = 'company'
}

export type ConnectSettingsResponse = {
  body: string;
};

export enum JoinSessionMode {
  Pin = 'Pin',
  Dialog = 'Dialog',
  DialogAndPin = 'DialogAndPin'
}

export enum LoggingLevel {
  Debug = 'Debug',
  Info = 'Info',
  Warning = 'Warning',
  NoLog = 'NoLog'
}

export enum ProxyMode {
  None = 'None',
  AutoDetect = 'AutoDetect',
  Manual = 'Manual'
}

export enum PinLength {
  Four = 4,
  Six = 6,
  Eight = 8,
  Ten = 10
}

export interface IConnectSettings {
  ConfigPassword: string;
  ManagedSystemConfig: {
    AllowOutgoingConnections: boolean;
    AllowGui: boolean;
  };
  NetworkConfig: {
    ProxyServerConfigMode: ProxyMode;
    ProxyServerHost: string;
    ProxyServerPort: string;
    ProxyServerUseBasicAuth: boolean;
    ProxyServerUserName: string;
    ProxyServerPassword: string;
  };
  ServerConfig: {
    Password: {
      Length: PinLength;
    };
    CustomPasswords: {
      MainItemValue: string;
      ActsAsPin: false;
    };
    JoinSessionMode: JoinSessionMode;
  };
  AppFeaturesConfig: {
    UpdateConfig: {
      ModeAuto: boolean;
    };
  };
  LoggerConfig: {
    LoggingLevel: LoggingLevel;
  };
}
