<mbs-modal [isCustomModal]="true">
  <ng-container modal-header>
    {{baseModal?.data?.enableStepTitle || 'wizards:enableStepTitle' | i18next : { format: 'title' } }}
  </ng-container>

  <ng-container modal-body>
    <mbs-alert [type]="alertType.warning"
               [icon]="true">
      {{baseModal?.data?.enableStepAlert || 'wizards:enableStepAlert' | i18next}}
    </mbs-alert>
    {{baseModal?.data?.enableStepText || 'wizards:enableStepText' | i18next}}
  </ng-container>

  <ng-container modalFooter>
    <mbs-button data-dismiss="modal"
                type="primary"
                (click)="save()">
      {{baseModal?.data?.enableStepButtonText ||  'wizards:enableStepTitle' | i18next }}
    </mbs-button>
    <mbs-button data-dismiss="modal"
                type="secondary"
                (click)="close()">
      {{baseModal?.data?.cancelButtonText ||   'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
