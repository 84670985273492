import { SmartSearchState } from 'mbs-ui-kit';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export default abstract class SmartSearchTemplatesBase {
  protected readonly storedTimeout = 4 * 60 * 60 * 1000;

  protected cache: {
    [key: string]: any[];
  } = {};
  protected cacheTimeout: {
    [key: string]: any;
  } = {};

  /*
   * Save API results in memory
   * @param {string} term current term
   */
  protected writeToCache<TData>(term: string): (x: TData[]) => void {
    return (res: TData[]) => {
      this.cache[term] = res;
      clearTimeout(this.cacheTimeout[term + '_timeout']);
      this.cacheTimeout[term + '_timeout'] = setTimeout(() => delete this.cache[term], this.storedTimeout);
    };
  }

  protected searchTemplate$ = (state: SmartSearchState) => of(state.leftCaretValue).pipe(debounceTime(300), distinctUntilChanged());
}
