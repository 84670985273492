import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { ApplicationStoreActions } from '@store/application';

@UntilDestroy()
@Component({
  selector: 'mbs-footer',
  templateUrl: './app-footer.component.html'
})
export class AppFooterComponent implements OnInit {
  footerImage: string;
  rebrandingActivated: boolean;
  private readonly body: HTMLElement;
  private renderer: Renderer2;
  public isShowFooter = true;

  constructor(
    private authService: AuthService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private store: Store
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.body = this.document.body;
  }

  get footerLogoImage(): string {
    return `${this.footerImage}`;
  }

  ngOnInit(): void {
    this.authService.BrandingSetting.pipe(untilDestroyed(this)).subscribe(data => {
      this.rebrandingActivated = data ? data.BrandingActivated : false;
      if (data && data.BrandingActivated && data.FooterLogoImageUrl) {
        this.footerImage = data.FooterLogoImageUrl;
      } else {
        this.footerImage = null;
      }
      this.isShowFooter =
        !this.rebrandingActivated || (this.rebrandingActivated && !(this.footerImage === null || this.footerImage === undefined));
    });
    if (this.isShowFooter) {
      this.renderer.addClass(this.body, '-showFooter');
    }

    this.store.dispatch(ApplicationStoreActions.setFooterExist({ payload: this.isShowFooter }));
  }

  currentDate = Date.now();
}
