export enum PermissionsEnum {
  // some modes but check as permission

  /** same that RA */
  RD = 'RA',
  /** same that RD */
  RA = 'RA',
  MBS = 'MBS',

  Provider = 'Provider',
  // end

  ADS = 'ADS',
  AccessToAllCompanies = 'AccessToAllCompanies',
  ActivateLicenseKey = 'ActivateLicenseKey',
  Administrator = 'Administrator',
  AssignTags = 'AssignTags',
  AuditLog = 'AuditLog',
  BackupHistory = 'BackupHistory',
  Billing = 'Billing',
  CapacityReport = 'CapacityReport',
  CloudRestore = 'CloudRestore',
  Dashboard = 'Dashboard',
  DeepInstinct = 'DeepInstinct',
  DirectConnectAccess = 'DirectConnectAccess',
  Downloads = 'Downloads',
  EC2Snapshots = 'EC2Snapshots',
  GoogleApps = 'GoogleApps',
  HelpMarketing = 'HelpMarketing',
  LicenseBuy = 'LicenseBuy',
  LicenseUsageHistory = 'LicenseUsageHistory',
  Licenses = 'Licenses',
  ManageCompanies = 'ManageCompanies',
  ManageImmutability = 'ManageImmutability',
  ManageTags = 'ManageTags',
  Menu2022 = 'Menu2022',
  Monitoring = 'Monitoring',
  Notification = 'Notification',
  OnlineAccess = 'OnlineAccess',
  Package = 'Package',
  PSA = 'PSA',
  PurchaseHistory = 'PurchaseHistory',
  Rebranding = 'Rebranding',
  RemoteConnection = 'RemoteConnection',
  RemoteDeploy = 'RemoteDeploy',
  RemoteManagement = 'RemoteManagement',
  Rmm = 'Rmm',
  Storage = 'Storage',
  StorageUsage = 'StorageUsage',
  ShowDeepInstinct = 'ShowDeepInstinct',
  ShowRemoteConnection = 'ShowRemoteConnection',
  ShowRmm = 'ShowRmm',
  UseOldConnectSettings = 'UseOldConnectSettings',
  Users = 'Users',
  UsersCreateEdit = 'UsersCreateEdit',
  UsersDelete = 'UsersDelete'
}
