import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTimeFormatModule } from '@components/date-time-format/date-time-format.module';
import { BackupStorageLegacyComponent } from '@components/sidepanel-backup/tabs/backup-storage-legacy/backup-storage-legacy.component';
import { BackupStorageComponent } from '@components/sidepanel-backup/tabs/backup-storage/backup-storage.component';
import { BrowseStorageTablesComponent } from '@components/sidepanel-backup/tabs/backup-storage/browse-storage/browse-storage-tables/browse-storage-tables.component';
import { BrowseStorageComponent } from '@components/sidepanel-backup/tabs/backup-storage/browse-storage/browse-storage.component';
import { DownloadToSelectedComputerComponent } from '@components/sidepanel-backup/tabs/download-to-selected-computer/download-to-selected-computer.component';
import { PasswordRecoveryModule } from '@modules/password-recovery/password-recovery.module';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { SwitcherModule } from 'mbs-ui-kit';
import { MbsUiKitModule } from 'mbs-ui-kit/mbs-ui-kit.module';
import { UtilsModule } from '../../utils/utils.module';
import { BackupDateTimeFormatComponent } from '../date-time-format/backup-date-time-format.component';
import { BackupDateTimeFormatModule } from '../date-time-format/backup-date-time-format.module';
import { LicensesSharedModule } from '../licenses/licenses-shared.module';
import { BuyLicenseModalComponent } from './modals/buy-license-modal/buy-license-modal.component';
import { ReleaseLicenseModalComponent } from './modals/release-license-modal/release-license-modal.component';
import { SelectLicenseModalComponent } from './modals/select-license-modal/select-license-modal.component';
import { SidepanelBackupLicenseTabCardComponent } from './tabs/sidepanel-backup-license-tab/sidepanel-backup-license-tab-card/sidepanel-backup-license-tab-card.component';
import { SidepanelBackupLicenseTabComponent } from './tabs/sidepanel-backup-license-tab/sidepanel-backup-license-tab.component';

const components = [
  BackupStorageComponent,
  BackupStorageLegacyComponent,
  BrowseStorageComponent,
  BrowseStorageTablesComponent,
  DownloadToSelectedComputerComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MbsUiKitModule,
    ReactiveFormsModule,
    UtilsModule,
    DateTimeFormatModule,
    SwitcherModule,
    LicensesSharedModule,
    I18NextModule,
    NgbDropdownModule,
    AbilityModule,
    NgbTooltipModule,
    PasswordRecoveryModule,
    BackupDateTimeFormatModule
  ],
  exports: [
    ...components,
    BackupDateTimeFormatComponent,
    SidepanelBackupLicenseTabComponent,
    BuyLicenseModalComponent,
    SelectLicenseModalComponent,
    SidepanelBackupLicenseTabCardComponent
  ],
  declarations: [
    ...components,
    SidepanelBackupLicenseTabComponent,
    ReleaseLicenseModalComponent,
    BuyLicenseModalComponent,
    SelectLicenseModalComponent,
    SidepanelBackupLicenseTabCardComponent
  ]
})
export class BackupSidePanelStorageModule {}
