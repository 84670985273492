import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { RMBetaLSKey } from '@components/sidepanel-backup/sidepanel-backup.component';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import Computer, { AgentType, ComputersMode } from '@models/Computer';
import { BackupSidePanelTab } from '@models/backup/sidepanel-tab';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { DataChangeWatcherService, MbsPopupType, SidepanelComponent, SidepanelCrudBase } from 'mbs-ui-kit';
import { Observable, combineLatest, of } from 'rxjs';
import {addBreadcrumb, SeverityLevel} from "@sentry/browser";

@UntilDestroy()
@Component({
  selector: 'mbs-online-access-sidepanel-backup',
  templateUrl: './online-access-sidepanel-backup.component.html',
  styles: []
})
export class OnlineAccessSidepanelBackupComponent extends SidepanelCrudBase<Computer> implements OnInit {
  @Output() tabChanged = new EventEmitter<string>();

  public panelTab = BackupSidePanelTab;
  public fullscreen: boolean;
  public computer: Computer;
  public computerName: string;
  public onlineStatus: string;
  public isOnline: boolean;
  public AgentType = AgentType;
  public hasAgent = Computer.hasAgent;
  public ComputersMode = ComputersMode;
  public AlertType = MbsPopupType;
  public offlineAlert = '';
  public get isBeta(): boolean {
    return this._isBeta;
  }
  public set isBeta(value) {
    if (this._isBeta !== value) {
      this._isBeta = value;

      localStorage.setItem(RMBetaLSKey, JSON.stringify(value));

      addBreadcrumb({
        category: 'sidepanel',
        timestamp: new Date().getTime() / 1000,
        message: `Switch backup sidepanel beta to ${value}`,
        level: 'info' as SeverityLevel
      });
    }
  }

  private _isBeta: boolean;

  @ViewChild(SidepanelComponent, { static: true }) sidepanelComponent: SidepanelComponent;

  constructor(
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    dcws: DataChangeWatcherService, // prettier
    private backupFacade: ComputerBackupFacade
  ) {
    super(dcws);
    this.isBeta = JSON.parse(localStorage.getItem(RMBetaLSKey)) === false ? false : true;
  }

  ngOnInit(): void {
    this.initStreams();
  }

  updateData(): void {
    //
  }

  initStreams(): void {
    const computerOnline$ = this.backupFacade.computerOnline$;
    const agentOnline$ = this.backupFacade.backupAgentOnline$;
    const onlineAndAgentOnline = combineLatest([computerOnline$, agentOnline$]);

    onlineAndAgentOnline.pipe(untilDestroyed(this)).subscribe(([online, agentOnline]) => {
      let alertText = '';
      if (!agentOnline) alertText = this.getI18Text('serviceUnavailable');
      if (!online) alertText = this.getI18Text('offline');
      this.offlineAlert = alertText;
    });

    // refresh template data
    this.backupFacade.computer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      this.computer = computer;
      this.computerName = Computer.getComputerName(computer);
      this.isOnline = computer?.online;
      this.onlineStatus = Computer.getComputerStatusDurationText(computer, this.i18nPipe, ' ');
    });
  }

  handleAfterTabChange(event) {
    this.tabChanged.emit(event);
  }

  handleSave(): Observable<boolean> {
    return of(false);
  }
  handleDelete(): Observable<boolean> {
    return of(false);
  }

  private getI18Text(key: string): string {
    return this.i18nPipe.transform(`computers.module:backupSidePanel.${key}`);
  }

  fullScreenChangeHandler(): void {
    this.sidepanelComponent.toggleExpand();
  }

  closeHandler(): void {
    if (this.fullscreen) this.sidepanelComponent.toggleExpand();
  }
}
