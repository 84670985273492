import Computer, { AgentType } from "@models/Computer";

export function getInstalledAgent(computer: Computer): AgentType {
  const agent = getAgent(computer, AgentType.Backup) || getAgent(computer, AgentType.RMM) || getAgent(computer, AgentType.RA);
  return agent?.applicationId || null;
}

export function getRMMVersion(computer: Computer): string {
  const agent = getAgent(computer, AgentType.RMM);
  return agent ? agent.version : null;
}

export function getBackupVersion(computer: Computer): string {
  const agent = getAgent(computer, AgentType.Backup);
  return agent?.version || null;
}

export function getConnectVersion(computer: Computer): string {
  const agent = getAgent(computer, AgentType.RA);
  return agent?.version || null;
}

export function getAgent(computer: Computer, agentType: AgentType) {
  return computer?.apps?.find((app) => app.applicationId?.toLowerCase() === agentType);
}
