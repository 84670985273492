import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { TreeBunchesIcons } from '@models/backup/storages-type';
import { SelectHostStepValue } from '@modules/wizards/models/select-host-models';
import {
  SelectVirtualMachinesStepValue,
  VirtualMachine,
  VirtualMachinesPowerState,
  VirtualMachinesSelectedType,
  VirtualMachinesSelectedWithNumbersType,
  VirtualMachinesType
} from '@modules/wizards/models/select-virtual-machines-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import {
  CheckLicenseResult,
  MachinesDisksLoadedStatuses,
  ParamsForCheckVMSocketLicenses,
  ParamsForGetVirtualMachinesOrDisksList,
  WizardStepsService
} from '@modules/wizards/services/wizard-steps.service';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { FormsUtil, GuidEmpty, TableHeader } from 'mbs-ui-kit';

/**
 * Component for "Select Virtual Machines" step
 * Displays a list of computers (virtual machines) available for selection
 * Used within the HyperV and VMWare wizards
 * @authors Sergey.P Roman.sh
 */
const SelectVirtualMachinesStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SelectVirtualMachinesStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-select-virtual-machines-step',
  templateUrl: './select-virtual-machines-step.component.html',
  providers: [SelectVirtualMachinesStepValueAccessor]
})
export class SelectVirtualMachinesStepComponent extends StepBase<SelectVirtualMachinesStepValue> implements OnInit {
  @Input() selectedHostCredentials: SelectHostStepValue;
  @Output() machinesSelected: EventEmitter<Array<VirtualMachine>> = new EventEmitter<Array<VirtualMachine>>();

  public readonly elementsSelector = {
    name: {
      availableSocketsErrorDetail: 'available-socket-detail-alert-error',
      vmListSelect: 'virtual-machines-list'
    },
    id: {
      allRadio: 'type-backup-all-1',
      runningRadio: 'type-backup-running-2',
      selectedRadio: 'type-backup-selected-3',
      virtualMachinesList: 'virtual-machines-list'
    }
  };

  public virtualMachinesSelectedType = VirtualMachinesSelectedType;
  public virtualMachinesPowerState = VirtualMachinesPowerState;
  public virtualMachinesIcon = TreeBunchesIcons.HyperV;

  public headers: TableHeader[] = [{ name: '', gridColSize: '1fr', overflow: true }];
  public loadedMachines: VirtualMachine[] = [];
  public loadedMachinesHashTable: { [key: string]: VirtualMachine } = {};
  public selectedMachinesNames: string[] = [];
  public selectedMachines: VirtualMachine[] = [];

  public invalidDisks = false;
  public hyperVNotInstalled = false;

  public availableSocketsErrorDetail = '';

  /** User must buy licence for backup of every virtual machine. */
  public hasAvailableSockets: boolean = undefined;
  public notRDAndNotOffline(): boolean {
    return !this.isRDMode && !this.isOffline;
  }

  private isFirstLoad = true;
  private lastValidCredentials: SelectHostStepValue;
  private availableSocketsRequest: { inProgress?: boolean; finished?: boolean; params?: ParamsForCheckVMSocketLicenses } = {};
  private cacheSelectedMachines: VirtualMachine[] = [];

  constructor(private cdr: ChangeDetectorRef, public mainService: RemoteManagementWizardsService, public stepService: WizardStepsService) {
    super(mainService);
    if (this.isVMWare) this.virtualMachinesIcon = TreeBunchesIcons.VmWare;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup(
      {
        type: new FormControl(VirtualMachinesSelectedType.All),
        machines: new FormControl([]),
        machinesFullFormat: new FormControl([])
      },
      this.machinesDuplicateValidator.bind(this)
    );

    this.mainService.machinesDisksStatuses$.pipe(untilDestroyed(this)).subscribe((statuses: MachinesDisksLoadedStatuses) => {
      if (statuses && this.stepForm) {
        queueMicrotask(() => {
          this.stepForm.updateValueAndValidity();
          this.cdr.detectChanges();
        });
        this.getMachineWithValidatedDisks(statuses.voidMachines, statuses?.allVoid);
      }
    });

    this.initFormEvents();
  }

  onStepFormChange(value: SelectVirtualMachinesStepValue): void {
    this.value = {
      ...value,
      valid:
        this.stepForm.valid &&
        (this.isRDMode ||
          this.isOffline ||
          ((value.type !== VirtualMachinesSelectedType.Selected || !!value.machines?.length) &&
            !this.invalidDisks &&
            !!this.hasAvailableSockets))
    };
  }

  machinesDuplicateValidator(formGroup: UntypedFormGroup): ValidationErrors | null {
    const machines = formGroup.get('machines').value;
    return new Set(machines).size !== machines.length ? { duplicatesExist: true } : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isVMWare && this.needLoadMachines(changes)) {
      this.loadMachines();
      this.lastValidCredentials = this.extractCred(changes.selectedHostCredentials.currentValue);
    }
  }

  private extractCred(data: Partial<SelectHostStepValue>): SelectHostStepValue {
    return {
      server: data?.server,
      login: data?.login,
      password: data?.password,
      valid: !!data?.valid
    };
  }

  private needLoadMachines(changes: SimpleChanges): boolean {
    const curCred = this.extractCred(changes?.selectedHostCredentials?.currentValue);
    const oldCred = this.extractCred(changes?.selectedHostCredentials?.previousValue);
    const isNew = !isEqual(curCred, oldCred) || !isEqual(curCred, this.lastValidCredentials);

    if (isNew && curCred.valid) {
      return !!(curCred.server && curCred.login && curCred.password);
    }

    return false;
  }

  updateForm(value: SelectVirtualMachinesStepValue): void {
    if (this.isOffline) {
      if (value.machines.length && value.type === VirtualMachinesSelectedType.All) value.type = VirtualMachinesSelectedType.Selected;
      if (!value.machinesFullFormat?.length && this.selectedMachines.length) value.machinesFullFormat = this.selectedMachines;
    }

    this.selectedMachinesNames = value.machines;
    this.stepForm.reset(value);

    if (this.isHyperV && !this.isFirstLoad) this.checkHyperVAndLoadMachines();
    this.isFirstLoad = false;
  }

  checkHyperVAndLoadMachines(): void {
    this.loadInfo.emit({ loading: true, isHyperV: false });
    this.stepService
      .VMFeatureTest(this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.hyperVNotInstalled = !value.data;
          if (this.hyperVNotInstalled) this.stepService.showHyperVInfoModal();
          else this.loadMachines();
          this.loadInfo.emit({ loading: false, isHyperV: value.data });
          this.stepForm.markAllAsTouched();
          this.stepForm.updateValueAndValidity();
        },
        error: () => this.loadInfo.emit({ loading: false, isHyperV: false })
      });
  }

  forceValid(stepData: any = null): void {
    const validSelected = this.selectedMachines.length || this.value?.type !== VirtualMachinesSelectedType.Selected;

    if (!this.isOffline && !this.isRDMode && stepData.isNext && validSelected && !this.stepForm?.errors?.duplicatesExist) {
      this.requestAvailableSockets(stepData.isSave);
    } else {
      this.stepForm.updateValueAndValidity();
      this.stepForm.markAllAsTouched();
    }
  }

  private requestAvailableSockets(isSave: boolean): void {
    const params: ParamsForCheckVMSocketLicenses = this.getSocketRequestParams();
    const isEqualRequest = isEqual(this.availableSocketsRequest.params, params);
    if (this.availableSocketsRequest.inProgress || (this.availableSocketsRequest.finished && isEqualRequest)) {
      return;
    }

    this.loadInfo.emit({ loading: true });
    this.stepService
      .getRemoteCommandData(params, this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          this.availableSocketsRequest = { finished: true, params };
          if (data && data.data) {
            this.updateFlagsFromCheckLicenseRequest(data.data);

            FormsUtil.triggerValidation(this.stepForm);
            this.loadInfo.emit({ loading: false });
            if (this.stepForm.valid && this.hasAvailableSockets) this.nextStep.emit(isSave);
          } else this.loadInfo.emit({ loading: false });
        },
        error: (e) => {
          if (+e?.error?.errorCode === 1003 && e?.error?.detail) {
            this.availableSocketsErrorDetail = e.error.detail;
          }

          this.loadInfo.emit({ loading: false });
          this.availableSocketsRequest = {};
        }
      });

    this.availableSocketsRequest = { inProgress: true };
  }

  updateFlagsFromCheckLicenseRequest(result: CheckLicenseResult): void {
    delete this.availableSocketsErrorDetail;

    const usedSocketsCount = result.usedSocketsCount;
    const availableSocketsCount = result.availableSocketsCount;

    this.hasAvailableSockets = result.isOk && availableSocketsCount >= usedSocketsCount;

    if (!this.hasAvailableSockets && result.message) {
      this.availableSocketsErrorDetail = result.message;
    }
  }

  loadMachines(): void {
    if (this.notRDAndNotOffline()) {
      const params: ParamsForGetVirtualMachinesOrDisksList = this.getMachineListRequestParams();
      this.loadInfo.emit({ loading: true });
      return void this.stepService
        .getRemoteCommandData(params, this.mainService.hid)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (data) => {
            if (data?.data?.items) {
              this.setStepDataFromLoadedMachines(this.filterMachinesList(data.data.items));
            } else {
              this.loadedMachines = [];
            }

            this.loadInfo.emit({ loading: false });
          },
          error: () => {
            this.loadedMachines = [];
            this.loadInfo.emit({ loading: false });
          }
        });
    }

    const newMachines: VirtualMachine[] = this.value.machines.map((name: string, idx: number) => ({
      virtualMachineId: name.replaceAll(' ', '') + idx,
      name: name
    })) as VirtualMachine[];

    this.setStepDataFromLoadedMachines(newMachines);
  }

  filterMachinesList(machines: VirtualMachine[]): VirtualMachine[] {
    return machines.filter((m: VirtualMachine) => m.virtualMachineId !== GuidEmpty);
  }

  setStepDataFromLoadedMachines(machinesArray: VirtualMachine[]): void {
    this.loadedMachines = machinesArray;
    this.loadedMachines.forEach((machine) => (this.loadedMachinesHashTable[machine.virtualMachineId] = machine));

    this.typeChangeHandler(this.value.type);

    if (this.selectedMachinesNames.length) {
      this.selectedMachinesChangesHandler(this.getSelectedMachinesFromArrayMachineName(this.selectedMachinesNames), true);
      this.selectedMachinesNames = [];
    }
  }

  private getSocketRequestParams(): ParamsForCheckVMSocketLicenses {
    const type = this.stepForm.get('type').value;
    return {
      agentType: 'backup',
      commandType: 'CheckVMSocketLicenses',
      params: {
        PlanId: this.mainService.planId,
        Type: this.isVMWare ? VirtualMachinesType.VMWare : VirtualMachinesType.HyperV,
        Server: this.selectedHostCredentials?.server || '',
        UserName: this.selectedHostCredentials?.login || '',
        Password: this.selectedHostCredentials?.password || '',
        VMBackupType: +VirtualMachinesSelectedWithNumbersType[type],
        Machines: type === VirtualMachinesSelectedType.All ? [] : this.selectedMachines.map((m) => m.virtualMachineId)
      }
    };
  }

  private getMachineListRequestParams(): ParamsForGetVirtualMachinesOrDisksList {
    return {
      agentType: 'backup',
      commandType: 'GetVirtualMachineList',
      params: {
        PlanId: this.mainService.planId,
        Type: this.isVMWare ? 'VMWare' : 'HyperV',
        Server: this.selectedHostCredentials?.server || '',
        Login: this.selectedHostCredentials?.login || '',
        Password: this.selectedHostCredentials?.password || '',
        IsCluster: false,
        Order: 'DisplayNameAsc'
      }
    };
  }

  typeChangeHandler(type: VirtualMachinesSelectedType): void {
    if (type === VirtualMachinesSelectedType.Selected) {
      this.selectedMachines = this.cacheSelectedMachines;
    } else {
      this.cacheSelectedMachines = this.selectedMachines;
      this.selectedMachines = [];
    }

    this.stepForm.get('machines').setValue(this.selectedMachines.map((m) => m.name));
    this.stepForm.get('machinesFullFormat').setValue(this.selectedMachines);

    this.availableSocketsErrorDetail = '';

    this.cdr.detectChanges();

    this.notifyMachinesSelected();
  }

  selectedMachinesChangesHandler(selectedMachines: VirtualMachine[], needUpdate = false): void {
    if (needUpdate || !isEqual(this.selectedMachines, selectedMachines)) {
      const result = selectedMachines.map((machine) => machine.name);

      this.selectedMachines = this.getMachineWithValidatedName(selectedMachines);
      this.stepForm.get('machines').setValue(result);
      this.stepForm.get('machinesFullFormat').setValue(this.selectedMachines);
      this.availableSocketsErrorDetail = '';
    }

    this.notifyMachinesSelected();
  }

  getMachineWithValidatedName(machines: VirtualMachine[]): VirtualMachine[] {
    this.loadedMachines.forEach((machine) => (machine.invalidName = false));
    const keys = Object.keys(this.loadedMachinesHashTable);

    return machines.map((machine) => {
      machine.invalidName = keys.some(
        (key) =>
          key !== machine.virtualMachineId &&
          machines.includes(this.loadedMachinesHashTable[key]) &&
          this.loadedMachinesHashTable[key].name === machine.name
      );

      return machine;
    });
  }

  getMachineWithValidatedDisks(errorIds: string[], all = false): void {
    const selectedType = this.stepForm.get('type').value === VirtualMachinesSelectedType.Selected;
    this.selectedMachines = this.selectedMachines.map((machine) => {
      machine.invalidDisks = selectedType && (all || errorIds.some((id) => id === machine.virtualMachineId));

      return machine;
    });
  }

  getSelectedMachinesFromArrayMachineName(machines: string[]): VirtualMachine[] {
    const selected: VirtualMachine[] = [];
    const machinesCount = machines.length;

    this.loadedMachines.forEach((machine) => {
      if (selected.length < machinesCount && machines.includes(machine.name)) {
        selected.push(machine);
      }
    });

    return selected;
  }

  private notifyMachinesSelected(): void {
    if (this.stepForm.get('type').value === VirtualMachinesSelectedType.Selected) {
      const selectedList = this.stepForm.get('machines').value;

      return void this.machinesSelected.emit(this.loadedMachines.filter((machine) => selectedList.includes(machine.name)));
    }

    this.machinesSelected.emit(this.loadedMachines);
  }
}
