import { FormControl } from '@angular/forms';

export enum DatabaseBackupType {
  All = 0,
  User = 1,
  System = 2,
  Selected = 3
}

export type DatabaseItem = { IsChecked: boolean; Name: string; disabled?: boolean };

export type SQLSourceDatabasesStepValueForm = {
  databaseBackupType: FormControl<DatabaseBackupType>;
  selectedDatabases: FormControl<string[]>;
  copyOnly: FormControl<boolean>;
};

export type SQLSourceDatabasesStepValue = {
  databaseBackupType: DatabaseBackupType;
  selectedDatabases: string[];
  copyOnly: boolean;
  valid: boolean;
};
