import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { FeatureKey } from './const';
import { State } from './summary-computer.model';
import * as fromReducer from './summary-computer.reducer';

const selectFeature = createFeatureSelector<State>(FeatureKey);
export const selectIds = createSelector(selectFeature, fromReducer.selectIds);
export const selectEntities = createSelector(selectFeature, fromReducer.selectEntities);
export const selectAll = createSelector(selectFeature, fromReducer.selectAll);
export const selectTotal = createSelector(selectFeature, fromReducer.selectTotal);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);
export const selectLoadingByHid = (hid: any) =>
  createSelector(selectEntities, (entities) => (entities[hid] ? entities[hid].loading : true));

export const selectAccessState = createSelector(selectFeature, (state) => state.access);

export const selectCurrentHid = createSelector(selectFeature, (state) => state.current?.hid);
export const selectCurrentComputer = createSelector(selectFeature, (state) => state.current);
export const selectCurrent = createSelector(selectEntities, selectCurrentHid, (entities, hid) => entities[hid]);
export const selectByHid = (hid: any) => createSelector(selectEntities, (entities) => entities[hid]);

export const selectDiskByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.hdd);
export const selectCpuByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.cpu);
export const selectMemoryByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.ram);
export const selectAntivirusByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.antivirus);
export const selectEventLogByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.eventLog);
export const selectUpdateByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.update);
export const selectAlertsByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.alerts);
export const selectSmartByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.smart);
export const selectTemperatureByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.temperature);
export const selectComputerStateByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.computerState);
export const selectSystemTimeByHid = (hid: any) => createSelector(selectByHid(hid), (summary = {} as any) => summary.systemTime);

export const selectAppTimeDiffByHid = (hid: any, unit: 'milliseconds' | 'seconds' | 'minutes' | 'hours' = 'hours') =>
  createSelector(selectSystemTimeByHid(hid), (time = {} as any) => {
    const systemTime = time.value ? new Date(time.value) : new Date();

    const momentSystem = moment(systemTime);
    const momentApp = moment();

    return momentSystem.diff(momentApp, unit);
  });

export const selectCurrentSystemTime = createSelector(selectCurrent, (current) => current?.systemTime);
