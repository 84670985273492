import { ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { RestoreFileModelForStepHelpers } from '../../helpers/restore-file-helpers/restore-file-model-for-step-helpers';
import { RestoreFilePlanFromStepsHelpers } from '../../helpers/restore-file-helpers/restore-file-plan-from-steps-helpers';
import { SupportMethodsForStepsFromPlan } from '../../helpers/support-methods-for-steps-from-plan';
import { RestorePlan } from '../../models/base/restore-plan-model';
import { RestoreType } from '../../models/restore-point-models';
import { RestoreFileFormModel } from '../../models/restoreFile/restore-file-form-model';
import { WizardsServicesHubService } from '../../services/wizards-services-hub.service';
import { WizardBaseClass } from '../wizard-base.class';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';

@UntilDestroy()
@Component({
  selector: 'mbs-restore-file-wizard',
  templateUrl: './restore-file-wizard.component.html'
})
export class RestoreFileWizard extends WizardBaseClass {
  public restoreType = RestoreType;
  public restorePointTitle = this.i18nPipe.transform('wizards:restore_point_title', { format: 'title' });

  constructor(
    private cdr: ChangeDetectorRef,
    public services: WizardsServicesHubService,
    public i18nPipe: I18NextPipe,
    stepService: WizardStepsService
  ) {
    super(services, i18nPipe, stepService);

    this.FormModel = new RestoreFileFormModel(this.isNBF, this.isLinux);

    this.initSubscribes();
  }

  updateStepData(plan, isDefault = false): void {
    this.loadedPlan = plan;
    if (this.isEdit && !this.isRDMode) this.mainService.GetPlanNotificationOptions();
    if (!isDefault) this.FormModel = RestoreFileModelForStepHelpers.RestoreFileModelFromPlan(plan, this.defaultSettings());
    else {
      this.FormModel.planNameStep.Name = SupportMethodsForStepsFromPlan.generatePlanName(
        this.mainService.compName,
        this.planType,
        this.mainService.timeZoneOffset
      );
      this.loadedPlan.plan = new RestorePlan();
      this.updateStorages();
    }
  }

  protected loadInfoHandlerFinally() {
    this.cdr.detectChanges();
  }

  handleSave(): void {
    const newPlan = RestoreFilePlanFromStepsHelpers.getUpdatedPlan(this.loadedPlan.Plan, this.FormModel, this.defaultSettings());
    this.savePlan(newPlan);
  }
}
