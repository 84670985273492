import { BaseForStepsHelper, WizardHelpersSettings } from '@modules/wizards/helpers/bases/base-for-steps-helper';
import { SQLFormModel } from '@modules/wizards/models/SQL/sql-form-model';
import { SupportMethodsForStepsFromPlan } from '@modules/wizards/helpers/support-methods-for-steps-from-plan';
import { StepsDataFromPlanHelper } from '@modules/wizards/helpers/steps-data-from-plan-helper/steps-data-from-plan-helper';
import { GuidEmpty } from 'mbs-ui-kit';
import { StorageType } from '@models/StorageType.enum';

export class SQLModelForStepsHelper extends BaseForStepsHelper {
  public static SQLModel: SQLFormModel = null;

  public static SQLModelFromPlan(plan, settings: WizardHelpersSettings = this.DefaultSettings): SQLFormModel {
    const newPlan = plan && (plan.plan || plan.Plan);

    this.SQLModel = null;

    if (newPlan) {
      this.setThisValues(plan, settings);
      this.setStorageType(settings.mode, newPlan.HybridID, newPlan.ConnectionID);
      this.setSQLModel();

      this.updatePlanNameStep(newPlan, settings.compName);
      this.updateWhereBackupStep(newPlan);

      this.SQLModel.sqlServerInstanceStep = StepsDataFromPlanHelper.updateSQLServerInstance(newPlan, this.isCreate);
      this.SQLModel.sqlSourceDatabasesStep = StepsDataFromPlanHelper.updateSQLSSourceDatabases(newPlan);

      this.updateAdvancedOptionsData(newPlan);
      this.SQLModel.retentionPolicyStep = StepsDataFromPlanHelper.updateRetentionPolicy(newPlan, this.isCreate, this.planType);
      this.SQLModel.compressionEncryptionStep = StepsDataFromPlanHelper.updateCompressionEncryption(
        newPlan,
        this.isNBF,
        this.isLinux,
        this.isCreate
      );
      this.SQLModel.scheduleStep = StepsDataFromPlanHelper.updateSchedule(
        newPlan,
        plan.OverdueAfterSuccess,
        this.isCreate,
        this.isLinux,
        true
      );
      this.SQLModel.prePostActionsStep = StepsDataFromPlanHelper.updatePrePostActions(newPlan);
      this.SQLModel.AvailableMachinePlans = StepsDataFromPlanHelper.updateAvailableMachinePlans(plan.AvailableMachinePlans, newPlan.ID);
      this.SQLModel.notificationStep = StepsDataFromPlanHelper.updateNotification(
        newPlan.Notification,
        newPlan.WindowsEventLogNotification
      );
    }

    return this.SQLModel;
  }

  public static setSQLModel(): void {
    this.SQLModel = new SQLFormModel(this.isNBF, this.isLinux);
  }

  public static updatePlanNameStep(newPlan, compName: string): void {
    const name = newPlan.Name || SupportMethodsForStepsFromPlan.generatePlanName(compName, this.planType, this.timeZoneOffset);

    this.SQLModel.planNameStep = StepsDataFromPlanHelper.updatePlanNameStep(name, newPlan.SavePlanInCloud, this.isNBF);
  }

  public static updateWhereBackupStep(newPlan): void {
    const isHybrid = newPlan.IsHybridBackup && !this.isLinux && !this.isNBF;

    if (!this.accounts) {
      this.SQLModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(isHybrid, newPlan.HybridID, newPlan.ConnectionID);
    } else {
      const id = this.bucketId || this.getBucketId(newPlan.Bucket, newPlan.SGAccountID);

      this.SQLModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(isHybrid, newPlan.HybridID, id || newPlan.ConnectionID);

      if (id && id !== GuidEmpty) {
        const storage = this.storages.CloudStorages.find((s) => s.ID === id) || this.storages.LocalStorages.find((s) => s.ID === id);
        this.storageType = storage ? storage.StorageType : this.storageType;
      }
    }

    if (!this.isRD) SupportMethodsForStepsFromPlan.getFilteredStoragesByTypes(this.storages, [StorageType.OpenStack]);
    this.SQLModel.Storages = this.storages;
  }

  public static updateAdvancedOptionsData(newPlan): void {
    const advancedOptionsData = StepsDataFromPlanHelper.updateAdvancedOptions(newPlan, this.storage);

    this.SQLModel.advancedOptionsStep = advancedOptionsData.advancedOptionsStep;
    this.SQLModel.advancedOptionsFlags = advancedOptionsData.advancedOptionsFlags;
  }
}
