import * as moment from 'moment';

/**
 * See more
 *
 * https://tools.ietf.org/html/rfc7519
 *
 * https://tools.ietf.org/html/rfc6749
 *
 */
export class JwtToken {
  /**
   * Not Before (Unix format)
   */
  nbf: number;

  /**
   * Expiration Time (Unix format)
   */
  exp: number;
  /**
   * Issuer (Identity endpoint)
   */
  iss: string;
  /**
   * Audience
   */
  aud: AudiencePermissionScope;
  client_id: 'mbs_provider_spa' | null;
  /**
   * Subject
   */
  sub: string;

  /**
   * Timestamp expire date
   */
  auth_time: number;
  idp: 'MBS' | null;
  role: 'provider' | 'subadmin' | null;
  /**
   * Provider identifier
   */
  owner_id: string;
  /**
   * Computer identifier
   * Exist for safe operation with 2FA
   */
  hid: string;
  scope: Array<AudiencePermissionScope>;

  /**
   * Authentication Methods References
   *
   * See more
   *
   * https://tools.ietf.org/html/rfc8176
   */
  amr: Array<'pwd' | 'internal_oidc_provider'>;

  constructor(obj?: Partial<JwtToken>) {
    Object.assign(this, obj);
  }

  public hasScope(scope: AudiencePermissionScope): boolean {
    return this.aud.includes(scope);
  }

  public isExpired(): boolean {
    return moment.unix(this.exp).isBefore();
  }
}

export enum AudiencePermissionScope {
  MBS_PROVIDER_API = 'mbs_provider_api',
  MBS_RM_ACCESS = 'mbs_rm_access'
}
