<mbs-wizard [(ngModel)]="currentStep"
            [buttonLoadingMode]="true"
            [canClosed]="!loadingPlan && wizardFinish"
            [isOpenSteps]="!loadingPlan && isEdit"
            [loading]="loadingPlan"
            [ignoreSteps]="[advancedSettingsTitle]"
            [stepsOrientation]="orientations"
            [cancelButton]="{ hide: false, text: 'buttons:cancel' | i18next: { format: 'title' } }"
            [saveButton]="{ text: 'buttons:save' | i18next: { format: 'title' } }"
            [stepQueue]="stepQueue"
            [stepsContainerClass]="mainService.isOffline ? 'overflow-hidden' : ''"
            (save)="handleSave()"
            [title]="wizardTitle + ' ' + mainService.compName">
  <div *ngIf="mainService.isOffline"
       prependStep
       class="pl-4 py-2 background-secondary">
    <span class="fa fa-power-off"></span> {{ prependStepText }}
  </div>
  <mbs-wizard-step [title]="'wizards:welcome' | i18next: { format: 'title' }"
                   [valid]="FormModel?.planNameStep?.valid"
                   (next)="planNameStep?.forceValid()">
    <mbs-plan-name-step #planNameStep
                        [(ngModel)]="FormModel.planNameStep"
                        [planTypeText]="planTypeText"></mbs-plan-name-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:where_backup_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.whereBackupStep?.valid"
                   (next)="whereBackupStep?.forceValid()">
    <mbs-where-backup-step #whereBackupStep
                           [(ngModel)]="FormModel.whereBackupStep"
                           (changeStorage)="changeVMWareStorageHandler($event)"
                           [Storages]="FormModel?.Storages"></mbs-where-backup-step>
  </mbs-wizard-step>


  <mbs-wizard-step [title]="hostTitle"
                   [valid]="FormModel?.selectHostStep?.valid"
                   (next)="selectHostStep?.forceValid(currentStep)">
    <mbs-select-host-step #selectHostStep
                          (loadInfo)="loadInfoHandler($event)"
                          (nextStep)="forceNextStepWizard($event, 'selectHostStep', hostTitle)"
                          [(ngModel)]="FormModel.selectHostStep"></mbs-select-host-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="selectVMTitle"
                   [valid]="FormModel?.selectVirtualMachinesStep?.valid"
                   (next)="selectVirtualMachinesStep?.forceValid(currentStep)">
    <mbs-select-virtual-machines-step #selectVirtualMachinesStep
                                      [selectedHostCredentials]="FormModel?.selectHostStep"
                                      (loadInfo)="loadInfoHandler($event)"
                                      (nextStep)="forceNextStepWizard($event, 'selectVirtualMachinesStep', selectVMTitle)"
                                      (machinesSelected)="machinesSelectedHandler($event)"
                                      [(ngModel)]="FormModel.selectVirtualMachinesStep"></mbs-select-virtual-machines-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf='canShowAppAwareStep'
                   [title]="'wizards:appAwareTitle' | i18next: { format: 'title' }"
                   [valid]="FormModel?.appAwareStep.valid"
                   (next)="appAwareStep?.forceValid(currentStep)">
    <mbs-app-aware-step #appAwareStep
                        [selectedMachines]="selectedMachines"
                        (loadInfo)="loadInfoHandler($event)"
                        [(ngModel)]="FormModel.appAwareStep"></mbs-app-aware-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="canShowSelectVirtualDiskStep"
                   [title]="'wizards:select_virtual_disks_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.selectVirtualDisksStep?.valid"
                   (next)="selectVirtualDisksStep?.forceValid()">
    <mbs-select-virtual-disks-step #selectVirtualDisksStep
                                   [selectedHostCredentials]="FormModel?.selectHostStep"
                                   [selectedMachinesStepValue]="FormModel?.selectVirtualMachinesStep"
                                   (loadInfo)="loadInfoHandler($event)"
                                   [(ngModel)]="FormModel.selectVirtualDisksStep"></mbs-select-virtual-disks-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="advancedSettingsTitle"
                   [valid]="FormModel?.advancedSettingsStep?.valid"
                   (next)="advancedSettingsStep?.forceValid(currentStep)">
    <mbs-advanced-settings-virtual-step #advancedSettingsStep
                                        [(ngModel)]="FormModel.advancedSettingsStep"
                                        [stepFocused]="currentStep?.title === advancedSettingsTitle"
                                        (changeStorageType)="changeAdvancedStorageHandler($event)"
                                        (nextStep)="forceNextStepWizard($event, 'advancedSettingsStep', advancedSettingsTitle)"
                                        [selectedStorage]="selectedStorage"></mbs-advanced-settings-virtual-step>
  </mbs-wizard-step>


  <mbs-wizard-step [title]="compressionEncryptionTitle"
                   [valid]="FormModel?.compressionEncryptionStep?.valid"
                   (next)="compressionEncryptionStep?.forceValid(currentStep)">
    <mbs-compression-encryption-step #compressionEncryptionStep
                                     [loadingPlan]="loadingPlan"
                                     [isS3Selected]="useS3AccelerationPossible"
                                     [stepFocused]="currentStep?.title === compressionEncryptionTitle"
                                     (nextStep)="forceNextStepWizard($event, 'compressionEncryptionStep', 'Compression & Encryption')"
                                     [(ngModel)]="FormModel.compressionEncryptionStep"></mbs-compression-encryption-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:archive_consistency_backup_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.archiveConsistencyStep?.valid"
                   (next)="archiveConsistencyStep?.forceValid()">
    <mbs-archive-consistency-step #archiveConsistencyStep
                                  [(ngModel)]="FormModel.archiveConsistencyStep"
                                  (loadInfo)="loadInfoHandler($event)"></mbs-archive-consistency-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="scheduleTitle"
                   [valid]="FormModel?.scheduleStep?.valid"
                   (next)="scheduleStep?.forceValid(currentStep)">
    <mbs-schedule-step #scheduleStep
                       [(ngModel)]="FormModel.scheduleStep"
                       [stepFocused]="currentStep?.title === scheduleTitle"
                       [invalidFFIStorage]="invalidFFIStorage"
                       [isGFSMode]="isGFSMode"
                       [retentionTitle]="retentionTitle"
                       [retentionUseDefault]="FormModel.retentionPolicyStep.RetentionUseDefaultSettings"
                       (nextStep)="forceNextStepWizard($event, 'scheduleStep', scheduleTitle)"
                       (registerRequiredStep)="stepQueue.registerRequiredStep($event)"
                       (unregisterRequiredStep)="stepQueue.unregisterRequiredStep($event)"
                       (incrementalData)="setIncrementalData($event)"
                       (changeType)="scheduleTypeChangeHandler($event)"></mbs-schedule-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="retentionTitle"
                   [valid]="FormModel?.retentionPolicyStep?.valid"
                   (next)="retentionPolicyStep?.forceValid(currentStep)">
    <mbs-retention-policy-step #retentionPolicyStep
                               [minPeriod]="minPeriod"
                               [isHybrid]="FormModel.whereBackupStep.IsHybridBackup"
                               [isFullBackup]="FormModel?.scheduleStep?.fullBackupData?.enabled"
                               [isMonthly]="FormModel?.scheduleStep?.fullBackupData?.recurringPeriod === 'Monthly'"
                               [showImmutability]="showImmutability"
                               [showIntelligentRetention]="!invalidIntelligentRetentionStorage"
                               [showGFS]="showGFS"
                               [isFFIMode]="isFFIMode"
                               [incrementalScheduleData]="incrementalData"
                               [stepFocused]="currentStep?.title === retentionTitle"
                               (backStep)="forceBackStepWizard('retentionPolicyStep', retentionTitle)"
                               (nextStep)="forceNextStepWizard($event, 'retentionPolicyStep', retentionTitle)"
                               [(ngModel)]="FormModel.retentionPolicyStep"></mbs-retention-policy-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="(mainService.allowPrePostActions | async) ? ('wizards:pre_post_title' | i18next: { format: 'title' }) :
                            ('wizards:pre_post_title_backup_chain' | i18next: { format: 'title' })"
                   [valid]="FormModel?.prePostActionsStep?.valid"
                   (next)="prePostActionsStep?.forceValid()">
    <mbs-pre-post-actions-step #prePostActionsStep
                               [(ngModel)]="FormModel.prePostActionsStep"
                               [ChainPlans]="FormModel?.AvailableMachinePlans"></mbs-pre-post-actions-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:notification_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.notificationStep?.valid">
    <mbs-notifications-step [(ngModel)]="FormModel.notificationStep"
                            (emailsNotificationChange)="emailsNotificationChangeHandler($event)"
                            [notification]="mainService.planNotification"></mbs-notifications-step>
  </mbs-wizard-step>
</mbs-wizard>
