import { HttpClient, HttpResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import {
  HttpClient as HubHttpClient,
  HttpRequest as HubHttpRequest,
  HttpResponse as HubHttpResponse,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';
import RmmHubResponse from '@models/rmm/RmmHubResponse';
import { defer, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, map, mapTo, share, switchMapTo, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ConfigurationService } from './configuration.service';

class CustomHttpClient extends HubHttpClient {
  constructor(private http: HttpClient) {
    super();
  }

  send(request: HubHttpRequest): Promise<HubHttpResponse> {
    return this.http
      .request<string>(request.method, request.url, { ...request, observe: 'response', responseType: 'text' } as any)
      .pipe(map((req: HttpResponse<string>) => new HubHttpResponse(req.status, req.statusText, req.body) as HubHttpResponse))
      .toPromise();
  }
}

@Injectable()
export class RmmWebsocketService {
  private connection: HubConnection;
  public get isConnected(): boolean {
    return this.connection && this.connection.state === HubConnectionState.Connected;
  }

  private myMessages$ = new Subject<RmmHubResponse<any>>();
  public readonly messages$ = this.myMessages$.asObservable();

  private myFailLastConnect = false;
  public get failLastConnect(): boolean {
    return this.myFailLastConnect;
  }

  private connectRequest$: Observable<any>;
  constructor(private auth: AuthService, private config: ConfigurationService, errorHandler: ErrorHandler, httpClient: HttpClient) {
    this.connection = new HubConnectionBuilder()
      .withUrl(config.get('rmmBaseSignalRHref'), {
        accessTokenFactory: () => auth.accessToken,
        httpClient: new CustomHttpClient(httpClient),
        logger: LogLevel.Error
      })
      // try reconnect in [0, 2, 10, 30] seconds
      .withAutomaticReconnect() // will not work if initial connection failed
      .build();

    // will be only call after 4 failure attempts
    this.connection.onclose((err) => {
      this.connection.off('Send');
      this.connection.off('Notify');
      errorHandler.handleError(err);
    });

    this.connection.onreconnected((connId) => {
      // @todo add handler
    });

    this.connectRequest$ = defer(() => this.connection.start()).pipe(
      tap(() => {
        this.connection.on('Send', this.handleSend.bind(this));
        this.connection.on('Notify', this.handleNotify.bind(this));
        this.myFailLastConnect = false;
      }),
      mapTo(true),
      catchError((err) => {
        this.myFailLastConnect = true;
        return of(false);
      }),
      share()
    );
  }

  public init(): Promise<boolean> {
    if (this.isConnected) {
      this.myFailLastConnect = false;
      return Promise.resolve(true);
    }

    return this.connectRequest$.toPromise();
  }

  public invokeMessage(data, asyncID) {
    const message: RmmHubResponse<unknown> = {
      MessageId: asyncID,
      Data: data?.data,
      Message: 'Command Complete'
    };

    this.myMessages$.next(message);
  }

  private handleSend(data): void {
    const obj = JSON.parse(data);
    this.myMessages$.next(obj);
  }

  private handleNotify(data): void {
    // empty
  }

  /*
   * Subscribe by messageId
   * @param messageId subscribe messageId
   *
   * @returns observable message for current messageId
   */
  public subscribe<TResult>(messageId): Observable<RmmHubResponse<TResult>> {
    // if we receive a new message before we subscribe
    const replayMessages$ = new ReplaySubject<RmmHubResponse<TResult>>();
    this.messages$.pipe(filter((r) => r.MessageId === messageId)).subscribe((m) => replayMessages$.next(m));
    return from(this.invoke('SubscribeAsync', messageId)).pipe(switchMapTo(replayMessages$));
  }

  public unsubscribe(messageId): Promise<any> {
    return this.invoke('UnsubscribeAsync', messageId);
  }

  public invoke<TData>(methodName, ...args: any[]): Promise<TData> {
    this.checkConnection();

    return this.connection.invoke(methodName, ...args).catch((error) => {
      console.log(error);
    });
  }

  private checkConnection(): void {
    if (this.isConnected) {
      return;
    }

    this.myFailLastConnect = true;

    throw Error("Hub isn't connected");
  }
}
