import { BuildType } from '@models/BuildType.enum';

export const buildImages = {
  windows: 'ico ico-windows',
  linux: 'fa fa-linux',
  mac: 'fa fa-apple'
};

export const buildsThatSupportBranding = [
  BuildType.VMware,
  BuildType.CommonWindowsBuild,
  BuildType.LinuxUbuntu14,
  BuildType.LinuxRedHat6
];

export enum BuildAction {
  Download = 'download',
  CopyLink = 'copyLink',
  CopyCommand = 'copyCommand'
}

export enum InstallWindowsAgentCmdName {
  Common = 'MBSAgent',
  RMM = 'RMMAgent',
  Connect = 'CONAgent'
}

export enum CopyingStatus {
  Copying = 'copying',
  Copied = 'copied'
}

export const buildsThatSupportCommand = [
  BuildType.CommonWindowsBuild,
  BuildType.LinuxDebian7,
  BuildType.LinuxEc2SnapshotAgent,
  BuildType.LinuxMac,
  BuildType.LinuxRedHat5,
  BuildType.LinuxRedHat6,
  BuildType.LinuxRedHat7,
  BuildType.LinuxUbuntu12,
  BuildType.LinuxUbuntu14,
  BuildType.Mac,
  BuildType.NetworkShares20,
  BuildType.NetworkShares5,
  BuildType.VMware,
  BuildType.RMMAgent,
  BuildType.RMMAgentMac,
  BuildType.RMMAgentLinuxDeb,
  BuildType.RMMAgentLinuxRpm,
  BuildType.CBLRA
];

export const buildsThatSupportLinuxRedHat = [BuildType.LinuxRedHat5, BuildType.LinuxRedHat6, BuildType.LinuxRedHat7, BuildType.RMMAgentLinuxRpm];

export const buildsThatSupportLinuxDebian = [
  BuildType.LinuxDebian7,
  BuildType.LinuxEc2SnapshotAgent,
  BuildType.LinuxUbuntu12,
  BuildType.LinuxUbuntu14,
  BuildType.RMMAgentLinuxDeb
];
