import { HttpStatusCode } from '@angular/common/http';
import { Component, ExistingProvider, forwardRef, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import {
  DatabaseServerInstances,
  SQLServerInstanceAndAuthData,
  SQLServerInstanceAuthenticationTypeEnum,
  SQLServerInstanceStepValue,
  SQLServerInstanceStepValueForm
} from '@modules/wizards/models/sql-server-instance-models';
import {
  RemoteManagementWizardsService,
  SQLIsSysAdminOrCredentialsModel
} from '@modules/wizards/services/remote-management-wizards.service';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { EnumHelper, FormsUtil, ModalService, ModalSettings, WizardStep } from 'mbs-ui-kit';
import { noop, of, switchMap } from 'rxjs';

/**
 * Component for "SQL server instance" step
 * Used within the SQL wizard
 * @authors Roman.sh
 */
const SQLServerInstanceStepValueAccessor: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SQLServerInstanceStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-sql-server-instance-step',
  templateUrl: './sql-server-instance-step.component.html',
  providers: [SQLServerInstanceStepValueAccessor]
})
export class SQLServerInstanceStepComponent extends StepBase<SQLServerInstanceStepValue> implements OnInit {
  @Input() databaseServerInstances: DatabaseServerInstances = [];

  public readonly SQLServerAuthTypes = EnumHelper.EnumToSelectIndexesArray(SQLServerInstanceAuthenticationTypeEnum);
  public readonly elementsSelector = {
    name: {
      sqlInstanceBlock: 'sql-instance-block',
      sqlInstanceControl: 'sql-instance-control',
      authTypeControl: 'auth-type-control',
      sqlUserNameInput: 'sql-user-name-input',
      sqlPasswordInput: 'sql-password-input',
      checkIsSysAdmin: 'sql-check-is-sys-admin-checkbox'
    }
  };

  public initialPass = '';
  public passwordInputType = 'password';
  public authTypeIsWindows = false;
  public canReadHelpMarketing = this.ability.can('read', 'HelpMarketing');

  private readonly modalSettings: ModalSettings = {
    header: { title: this.i18nPipe.transform('wizards:sql:sqlNotSysAdminModalTitle', { format: 'title' }) },
    footer: {
      okButton: { show: false },
      cancelButton: { text: this.i18nPipe.transform('buttons:close', { format: 'title' }) }
    }
  };
  private isSysAdmin = false;
  private credentialIsValid = null;

  private cashValueAfterCheck: SQLServerInstanceAndAuthData;

  @ViewChild('notSysAdminModal', { static: true, read: TemplateRef }) notSysAdminModal: TemplateRef<any>;

  constructor(
    public ability: AbilityService,
    public mainService: RemoteManagementWizardsService,
    public i18nPipe: I18NextPipe,
    public modalService: ModalService
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new FormGroup<SQLServerInstanceStepValueForm>({
      sqlServerInstanceName: new FormControl('', Validators.required),
      authenticationType: new FormControl(SQLServerInstanceAuthenticationTypeEnum['Windows Authentication']),
      userName: new FormControl('', [Validators.required, this.dbCredentialsValidator.bind(this, false)]),
      password: new FormControl('', [Validators.required, this.dbCredentialsValidator.bind(this, true)]),
      checkIsSysAdmin: new FormControl(true, this.checkSysAdminValidator.bind(this)),
      useSecureConnection: new FormControl(false)
    });

    this.initFormEvents();
  }

  protected initFormEvents(): void {
    super.initFormEvents();

    this.stepForm
      .get('authenticationType')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe({ next: (value) => this.authenticationTypeChangeHandler(value) });
  }

  onStepFormChange(value: SQLServerInstanceStepValue): void {
    this.value = { ...value, valid: this.databaseServerInstances?.length && this.stepForm.valid && this.credentialIsValid };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.databaseServerInstances?.currentValue?.length && !this.value.sqlServerInstanceName) {
      this.stepForm.get('sqlServerInstanceName').setValue(changes.databaseServerInstances?.currentValue[0].Name);
    }
  }

  updateForm(value: SQLServerInstanceStepValue): void {
    if (this.databaseServerInstances?.length && !value.sqlServerInstanceName) {
      value.sqlServerInstanceName = this.databaseServerInstances[0].Name;
    }

    this.stepForm.reset(value);

    if (this.isEdit) {
      this.initialPass = value.password;

      if (this.mainService.Plan$.value) this.loadDatabases();
    }
  }

  forceValid(step: WizardStep & { isSave?: boolean; isNext?: boolean } = null): void {
    if (!this.authTypeIsWindows && (!this.value.userName || !this.value.password)) {
      return void this.invalidCredentialsHandler();
    }

    if (this.databaseServerInstances?.length && !this.value.valid && (step.isNext || step.isSave)) {
      return void this.chainRequestsAfterNextOrSave(step.isSave);
    }

    FormsUtil.triggerValidation(this.stepForm);
    this.resetValidStateForValidFields();
    this.stepForm.get('checkIsSysAdmin').updateValueAndValidity();
  }

  authenticationTypeChangeHandler(authType: SQLServerInstanceAuthenticationTypeEnum): void {
    this.authTypeIsWindows = authType === SQLServerInstanceAuthenticationTypeEnum['Windows Authentication'];

    const action = this.authTypeIsWindows ? 'disable' : 'enable';

    ['userName', 'password'].forEach((key) => this.stepForm.get(key)[action]());
  }

  changePasswordType(): void {
    this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
  }

  resetValidStateIfChangedData(): void {
    const needCheckOrSQLAuthType = this.value.checkIsSysAdmin || !this.authTypeIsWindows;
    const notCashOrChangedValue =
      !this.cashValueAfterCheck ||
      Object.keys(this.cashValueAfterCheck).some(
        (key: string) => (key !== 'checkIsSysAdmin' || needCheckOrSQLAuthType) && this.cashValueAfterCheck[key] !== this.value[key]
      );

    if (notCashOrChangedValue) {
      this.resetValidFlags();
      this.resetValidStateForValidFields();
    }
  }

  private checkSysAdminValidator(control: AbstractControl): ValidationErrors | null {
    return !control.value || this.isSysAdmin ? null : { isNotSysAdmin: true };
  }

  private resetValidFlags(): void {
    this.isSysAdmin = false;
    this.credentialIsValid = null;
  }

  private getParamsForRequests(): SQLIsSysAdminOrCredentialsModel {
    const password = this.value.password || '';

    return {
      hid: this.mainService.hid,
      profile: this.mainService.profile,
      isWindowsAuth: this.authTypeIsWindows,
      instanceName: this.value.sqlServerInstanceName,
      userName: this.value.userName || '',
      password,
      planId: this.isCreate ? null : this.mainService.planId
    };
  }

  private loadDatabases(): void {
    this.loadInfo.emit({ loading: true });

    this.mainService
      .getDataBases(this.getParamsForRequests())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.loadInfo.emit({ loading: false }),
        error: () => this.loadInfo.emit({ loading: false })
      });
  }

  private chainRequestsAfterNextOrSave(isSave: boolean): void {
    this.loadInfo.emit({ loading: true });

    const isNeedCheck = this.value.checkIsSysAdmin && !this.isSysAdmin;
    const params = this.getParamsForRequests();

    this.resetValidFlags();

    this.mainService
      .checkSQLCredentials(params)
      .pipe(
        untilDestroyed(this),
        switchMap((result) => {
          const isOk = result?.PayLoad && result?.StatusCode === HttpStatusCode.Ok;

          if (isOk) {
            return isNeedCheck ? this.mainService.checkSQLInstanceIsSysadminRoleMember(params) : of(result);
          }

          return of(null);
        }),
        switchMap((result) => (result?.PayLoad ? this.mainService.getDataBases(params) : of(result)))
      )
      .subscribe({
        next: (result) => (result ? this.updateAfterLoadData(!!result.PayLoad, isSave) : this.invalidCredentialsHandler()),
        error: () => this.loadInfo.emit({ loading: false })
      });
  }

  private updateAfterLoadData(payload: boolean, isSave: boolean): void {
    this.isSysAdmin = payload;
    this.credentialIsValid = true;

    FormsUtil.triggerValidation(this.stepForm);
    this.loadInfo.emit({ loading: false });

    this.setCashValueAfterCheck();

    if (!this.isSysAdmin) {
      return void this.showNotSysAdminModal();
    }

    this.nextStep.emit(isSave);
  }

  private dbCredentialsValidator(isPassword: boolean, control: AbstractControl): ValidationErrors | null {
    return this.credentialIsValid === false
      ? {
          invalidCredentials: isPassword ? { message: this.i18nPipe.transform('wizards:sql:sqlInvalidCredentialsError') } : true
        }
      : null;
  }

  private setCashValueAfterCheck(): void {
    this.cashValueAfterCheck = {
      sqlServerInstanceName: this.value.sqlServerInstanceName,
      authenticationType: this.value.authenticationType,
      userName: this.value.userName,
      password: this.value.password,
      checkIsSysAdmin: this.value.checkIsSysAdmin
    };
  }

  private invalidCredentialsHandler(): void {
    this.credentialIsValid = false;
    FormsUtil.triggerValidation(this.stepForm);

    this.loadInfo.emit({ loading: false });
  }

  private showNotSysAdminModal(): void {
    this.modalService.open(this.modalSettings, this.notSysAdminModal).then(noop).catch(noop);
  }

  passwordFocusHandler(event): void {
    if (event?.target?.value && this.initialPass && event.target.value === this.initialPass) {
      this.stepForm.get('password').setValue('');
    }
  }

  passwordBlurHandler(event): void {
    if (event?.target && !event.target.value && this.initialPass) {
      this.stepForm.get('password').setValue(this.initialPass);
    }
  }
}
