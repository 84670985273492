import { Component, OnInit, ViewChild } from '@angular/core';
import { CompaniesFacade } from '@facades/companies.facade';
import { LicensesSettings } from '@models/Company';
import { ComputerLicense } from '@models/License';
import { LicensesService } from '@services/licenses.service';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { catchError, filter, of, take, tap } from 'rxjs';

@Component({
  selector: 'msp360-release-license-modal',
  templateUrl: './release-license-modal.component.html',
  styleUrls: ['./release-license-modal.component.css']
})
export class ReleaseLicenseModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public readonly MbsPopupType = MbsPopupType;
  public LicensesSettings = LicensesSettings;

  public loading = true;
  public releaseLoading = false;
  public companyPool: LicensesSettings;

  get computerName() {
    const computer = this.baseModal?.data?.computer;
    return computer?.displayName || computer?.name;
  }

  constructor(private companiesFacade: CompaniesFacade, private licensesService: LicensesService) {}

  ngOnInit(): void {
    this.setCompanyPool();
  }

  private setCompanyPool(): void {
    const companyId = this.baseModal?.data?.computer?.company?.id;
    this.loading = true;
    if (companyId) {
      this.companiesFacade
        .getById$(companyId, true, true)
        .pipe(
          catchError(() => of(null)),
          filter(Boolean),
          take(1),
          tap(() => (this.loading = false))
        )
        .subscribe((company) => (this.companyPool = company?.licensesSettings || null));
      this.companiesFacade.loadById(companyId);
    } else {
      this.companyPool = LicensesSettings.GlobalPool;
      this.loading = false;
    }
  }

  public release(): void {
    const license = this.baseModal?.data?.license as ComputerLicense;

    this.releaseLoading = true;
    this.licensesService
      .releaseComputerLicense(this.baseModal?.data?.computer?.hid, license?.licenseUsageId || license?.licenseType?.id)
      .pipe(
        catchError((error) => of(error)),
        tap(() => {
          const companyId = this.baseModal?.data.computer.company?.id;

          companyId && this.companiesFacade.loadById(companyId);
          this.releaseLoading = false;
        })
      )
      .subscribe((result) => this.baseModal.save(result));
  }
}
