import { Injectable } from '@angular/core';
import { environment } from '@mbs-ui/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RmmStateService {
  runtimeState$ = new BehaviorSubject<any>(null);
  debugMode = !environment.production;
}
