import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { AbilityService } from 'ability';
import Administrator from '@models/Administrator';
import Computer, { AgentType } from '@models/Computer';
import { PerPageSize, SortParams } from '@models/Paging';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { AuthService } from '@services/auth.service';
import { canAbilityCdRef } from '@utils/can-ability-cdRef';
import { I18NextPipe } from 'angular-i18next';
import { SharedPersistentStateEnum } from 'mbs-ui-kit';
import { SidepanelService } from 'mbs-ui-kit/sidepanel/sidepanel.service';
import { TableSortDirective } from 'mbs-ui-kit/table-grid/directives/table-sort.directive';
import { PaginationOptions } from 'mbs-ui-kit/table-grid/models/pagination-options';
import { commonRotateSequence, SortEvent } from 'mbs-ui-kit/table-grid/models/sort-event';
import { TableHeader } from 'mbs-ui-kit/table-grid/models/table-header';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import { combineLatest, merge, Observable } from 'rxjs';
import { debounceTime, filter, first, map, skip } from 'rxjs/operators';
import { OnlineAccessSidepanelBackupComponent } from './components/online-access-sidepanel-backup/online-access-sidepanel-backup.component';
import { OnlineAccessPagingFacade } from './facades/onlineAccessPaging.facade';
import { OnlineAccessSidepanelHandler } from './shared/online-access-sidepanel-handler.service';

@UntilDestroy()
@Component({
  selector: 'mbs-online-access',
  templateUrl: './online-access.component.html',
  styleUrls: ['./online-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnlineAccessComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(TableSortDirective) sortControls: QueryList<TableSortDirective>;

  public readonly SharedPersistentStateEnum = SharedPersistentStateEnum;
  public readonly PermissionsEnum = PermissionsEnum;
  public readonly AgentType = AgentType;
  public isWindows = Computer.isWindows;
  public hasAgent = Computer.hasAgent;
  public orderBy: SortEvent = { column: null, direction: null };
  public readonly rotateSequence = commonRotateSequence;
  public computers$: Observable<Computer[]>;
  public computersPageLoading$: Observable<boolean>;

  public currentUser: Administrator;

  private mainHeaders: TableHeader[] = [
    {
      name: this.i18nPipe.transform('online-access.module:os'),
      class: 'text-center',
      sort: 'os',
      gridColSize: '55px',
      gridColMin: '55px',
      isGridColumn: false
    },
    {
      name: this.i18nPipe.transform('computers.module:computerName'),
      overflow: true,
      sort: 'name',
      gridColSize: '15fr',
      gridColMin: '150px'
    },
    {
      name: 'Backup',
      gridColSize: '30fr',
      gridColMin: '340px'
    }
  ];
  private headerConnect: TableHeader[] = [
    {
      name: 'Connect',
      gridColSize: '183px',
      gridColMin: '183px',
      isGridColumn: false
    }
  ];

  public headersRM: TableHeader[] = [];

  public sortedHeadersRM: TableHeader[] = [];

  private paginationDefaultOptions: PaginationOptions = {
    dataSize: 0,
    maxSize: 6,
    page: 1,
    pageSize: this.appPersistentState.data.computersPerPage,
    rotate: true,
    pageSizeList: [PerPageSize.Small, PerPageSize.Medium, PerPageSize.Large]
  };

  public paginationOptions: PaginationOptions = {
    ...this.paginationDefaultOptions,
    pageSizeMenuFormatter: this.formatPageSize.bind(this)
  };

  public get isConnectAvailable(): boolean {
    const hasAbilityRemoteConnection = this.ability.can('read', 'ShowRemoteConnection');

    return this.currentUser?.EnableOnlineAccessConnect && (hasAbilityRemoteConnection || this.currentUser?.IsProvider);
  }

  constructor(
    private sidepanelService: SidepanelService,
    private cdRef: ChangeDetectorRef,
    private computerSidepanelHandler: OnlineAccessSidepanelHandler,
    public i18nPipe: I18NextPipe,
    private appPersistentState: AppPersistentStateService,
    private pagingFacade: OnlineAccessPagingFacade,
    private authService: AuthService,
    private ability: AbilityService
  ) {}

  ngOnInit(): void {
    this.initStreams();
  }

  ngOnDestroy(): void {
    this.sidepanelService.removeByType(OnlineAccessSidepanelBackupComponent);
  }

  ngAfterViewInit(): void {
    this.setInitialSorting();
  }

  setInitialSorting() {
    this.pagingFacade.sortSettings$.pipe(first()).subscribe((sortSettings) => {
      if (sortSettings && sortSettings.column && sortSettings.direction && this.sortControls.length) {
        this.orderBy.column = sortSettings.column;
        this.orderBy.direction = sortSettings.direction;
        queueMicrotask(() => canAbilityCdRef.call(this) && this.cdRef.markForCheck());
      }
    });
  }

  initStreams(): void {
    merge(
      this.pagingFacade.loaded$.pipe(
        first(),
        filter((loaded) => !loaded),
        map(() => true)
      ),
      this.pagingFacade.sortSettings$.pipe(
        skip(1),
        map(() => true)
      )
    )
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(() => {
        this.pagingFacade.loadPage({ pageNumber: 1, perPage: this.paginationOptions.pageSize, isFullRefresh: true });
      });

    combineLatest([this.pagingFacade.total$, this.pagingFacade.currentPageNumber$, this.pagingFacade.currentPerPageSetting$]).subscribe(
      ([total, pageNumber, perPage]) => {
        this.paginationOptions = Object.assign({}, this.paginationOptions, {
          dataSize: total || this.paginationDefaultOptions.dataSize,
          page: pageNumber || this.paginationDefaultOptions.page,
          pageSize: perPage || this.paginationDefaultOptions.pageSize
        });
      }
    );

    this.computers$ = this.pagingFacade.currentPageData$;
    this.computersPageLoading$ = this.pagingFacade.loading$;

    this.authService.currentUser.pipe(filter(Boolean), untilDestroyed(this)).subscribe((user) => {
      this.currentUser = user;
      this.headersRM = !this.isConnectAvailable ? this.mainHeaders : [...this.mainHeaders, ...this.headerConnect];
      this.sortedHeadersRM = this.headersRM.slice(0, 2);
    });
  }

  refreshComputers() {
    this.pagingFacade.refresh();
  }

  handleOpenBackupPanel(computer): void {
    this.computerSidepanelHandler.handleOpenBackupPanel(computer);
  }

  handleSort(sortEvent: SortEvent): void {
    this.orderBy = sortEvent;
    this.pagingFacade.setSortSettings(sortEvent as SortParams);
  }

  handlePageChange(options: PaginationOptions): void {
    this.pagingFacade.setPageNumber(options.page);
  }

  handlePageSizeChange(options: PaginationOptions): void {
    if (this.paginationOptions?.pageSize === options?.pageSize) return;
    this.pagingFacade.loadPage({ pageNumber: 1, perPage: options.pageSize, isFullRefresh: true });
    this.appPersistentState.data.computersPerPage = options.pageSize;
  }

  isDisplayNameExist(computer: Computer): boolean {
    return Computer.isDisplayNameExist(computer);
  }

  trackByFn(index: number, item: ExtendedTableRow): string {
    return (item?.item as Computer)?.hid;
  }

  formatPageSize(pageSize: number): string {
    return `${pageSize} ${this.i18nPipe.transform('computers.module:perPage')}`;
  }
}
