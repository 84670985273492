import { Component, ViewChild } from '@angular/core';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { PlanInfo } from '@models/backup/plan-info-model';
import Computer from '@models/Computer';
import { ComputerPlanBasedModal } from '@models/ComputersModals';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { ToastService } from 'mbs-ui-kit/toast/toast.service';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';
import { of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-delete-plan-modal',
  templateUrl: './delete-plan-modal.component.html'
})
export class DeletePlanModalComponent implements ComputerPlanBasedModal {
  public computer: Computer;
  public plan: PlanInfo;
  public loading = false;
  public readonly alertType = MbsPopupType;

  @ViewChild(ModalComponent, { static: true }) private baseModal;

  constructor(
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    public toast: ToastService,
    private facade: ComputerBackupFacade
  ) {}

  /**
   * Remove plan
   */
  handleResolve(): void {
    this.loading = true;
    const removePlanNotDefined = this.i18nPipe.transform('computers.module:modals:removePlanNotDefined');

    this.facade
      .removePlan(this.computer, this.plan?.id)
      .pipe(
        map(() => true),
        catchError((e) => {
          // TODO Remove this when the method for deleting the plan is ready.
          if (e && e.error && e.error.message === removePlanNotDefined) {
            alert(removePlanNotDefined);
          }
          return of(false);
        }),
        tap(() => {
          this.loading = false;
        }),
        filter(Boolean)
      )
      .subscribe(() => {
        this.facade.loadPlans({ hid: this.computer?.hid, force: true });
        this.toast.success(`The plan <span class="font-weight-semibold">${this.plan?.displayName} </span> has been deleted`);
        this.baseModal.close();
      });
  }
}
