<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal
           class="select-license-modal overflow-visible">
  <div modal-body>
    <mbs-alert *ngIf="(isLinux || isMac || isServer) && !(isPostPayment | async)"
               [type]="mbsPopupType.info"
               [icon]="true"
               [ngSwitch]="true">

      <span *ngSwitchCase="isLinux">{{ 'backup:availableLicenseForLinux' | i18next }}</span>
      <span *ngSwitchCase="isMac">{{ 'backup:availableLicenseForMac' | i18next }}</span>
      <span *ngSwitchCase="isServer && !isLinux && !isMac">{{ 'backup:availableLicenseForServer' | i18next }}</span>

      <a href="https://help.mspbackups.com/licenses/manage-licenses" target="_blank">{{ 'buttons:learnMore' | i18next }}...</a>
    </mbs-alert>

    <fieldset class="mbs-form_content">
      <mbs-select (change)="handleChangeLicenseType()"
                  [(ngModel)]="selectedLicenseType"
                  [bindValue]="'id'"
                  [bindLabel]="'name'"
                  [loading]="setLicenseTypesLoading"
                  [disabled]="setLicenseTypesLoading || !licenseTypes?.length"
                  [label]="'backup:licenseType' | i18next"
                  [items]="licenseTypes"
                  [placeholder]="(licenseTypes?.length || setLicenseTypesLoading) ? ('backup:selectLicenseType' | i18next) : 'No license types allowed. Contact your provider'"></mbs-select>
    </fieldset>

    <fieldset class="mbs-form_content mt-3"
              *ngIf="!(isPostPayment | async)">
      <mbs-select (close)="isShowAll = false"
                  [bindValue]="'id'"
                  [bindLabel]="'name'"
                  [(ngModel)]="selectedLicense"
                  [loading]="loading"
                  [disabled]="isNil(selectedLicenseType) || loading"
                  [label]="'backup:availableLicenses' | i18next"
                  [items]="isShowAll ? availablePaidLicenses : availableLicenses"
                  [placeholder]="'backup:selectLicenses' | i18next">
        <ng-template ng-option-tmp
                     let-item="item"
                     let-search="searchTerm"
                     let-index="index">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span class="text-gray-900">{{ item.name }}</span>
              <div class="text-sm"
                   [class]="item.class">{{ item.description }}</div>
            </div>

            <span class="hint"
                  *ngIf="!item.button">{{ item.hint }}</span>
            <mbs-button [isCtrl]="true"
                        *ngIf="item.button"
                        (click)="isShowAll = true"><b>{{ item.button }}</b></mbs-button>
          </div>
        </ng-template>

        <ng-template ng-footer-tmp>
          <mbs-button *ngIf="isShowAll"
                      [isCtrl]="true"
                      (click)="isShowAll = false"><b>{{ 'buttons:back' | i18next }}</b></mbs-button>

          <mbs-button *ngIf="availablePaidLicenses?.length && !isShowAll"
                      [isCtrl]="true"
                      (click)="isShowAll = true "><b>{{
            'backup:seeAvailablePaidLicenses' | i18next }}</b></mbs-button>
        </ng-template>
      </mbs-select>
    </fieldset>

    <span class="mt-3 d-block text-md"
          *ngIf="hasAllowForCompanyLicense">* - allowed license by company settings</span>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="isSelectBuyLicense ? buyLicense() : setLicense()"
                [disabled]="isApplyBtnDisabled(isPostPayment | async)"
                [loading]="setLicenseLoading"> {{ isSelectBuyLicense ? ('app:buttons:buy' | i18next) :
      baseModal.data.button }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'app:buttons.cancel' | i18next }} </mbs-button>
  </ng-container>
</mbs-modal>
