<div class="mbs-container">
  <div class="mbs-container-inner justify-content-center align-items-center">
    <ng-container *ngIf="!loadingPage">
      <div>
        <div>
          <ng-container *ngIf="isMBSMode; else onlineAccessHeader">
            <div class="mbs-form-welcome_header">
              <div class="mbs-form-welcome_header-text">{{ 'app:login.welcome' | i18next }}</div>
              <div class="mbs-form-welcome_header-name">{{ 'app:login.mbsDebug' | i18next }}</div>
            </div>
          </ng-container>
          <ng-template #onlineAccessHeader>
            <div class="mbs-form-welcome_header">
              <div class="mbs-form-welcome_header-text mb-2">{{ 'app:login.loginTo' | i18next }}</div>
              <div class="mbs-form-welcome_header-name mb-2">{{ vendor }}</div>
              <div class="mbs-form-welcome_header-text">{{ 'app:login.onlineAccess' | i18next }}</div>
            </div>
          </ng-template>
        </div>
      </div>
      <form class="mbs-form mbs-form-welcome"
            #login="ngForm"
            (ngSubmit)="onLoginSubmit(login)">
        <div class="mbs-form_content pt-0">
          <fieldset class="mbs-form-welcome_content">
            <div class="mbs-form-group">
              <input type="email"
                     class="form-control form-control-lg rounded-top rounded-bottom-0"
                     [placeholder]="'app:login.enterEmail' | i18next"
                     required
                     name="email"
                     [class.is-invalid]="email.touched && email.invalid"
                     #email="ngModel"
                     ngModel />
              <input type="password"
                     class="form-control form-control-lg rounded-bottom rounded-top-0"
                     [placeholder]="'app:login.password' | i18next"
                     #password="ngModel"
                     name="password"
                     required
                     [class.is-invalid]="password.touched && password.invalid"
                     ngModel />
            </div>
            <div *ngIf="authError"
                 class="small text-danger mb-2">{{ 'app:login.invalidPassword' | i18next }}</div>

            <div class="mbs-form-group d-flex">
              <mbs-checkbox value="false"
                            name="rememberMe"
                            [label]="'app:login.rememberMe' | i18next"
                            ngModel
                            [id]="'rememberMe'"></mbs-checkbox>
              <a *ngIf="isMBSMode"
                 routerLink="/forgotpassword"
                 class="ml-auto">{{ 'app:login.forgotPassword' | i18next }}</a>
            </div>

          </fieldset>

          <div class="mbs-form-group pb-4">
            <div class="row no-gutters">
              <div [ngClass]="{'col-10 pr-2': isMBSMode, 'col-12': !isMBSMode}">
                <mbs-button [disabled]="login.form.invalid"
                            [loading]="loading$ | async"
                            [buttonType]="'submit'"
                            type="warning"
                            size="lg"
                            block="true">{{ 'app:login.login' | i18next }}</mbs-button>

              </div>
              <div class="col-2"
                   *ngIf="isMBSMode">
                <button class="btn btn-secondary btn-lg btn-block"
                        [title]="'app:login.signInGoogle' | i18next">
                  <span class="i i-Google w-100 h-100 googleIcon"></span>
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="isMBSMode"
               class="mbs-form-group">
            <div class="text-center mbs-form_label">
              {{ 'app:login.noAccount' | i18next }}<a routerLink="/signup">{{ 'app:login.signUp' | i18next }}</a>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-template #loader>
      <mbs-loader></mbs-loader>
    </ng-template>
  </div>
</div>
