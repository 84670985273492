import { I18NextPipe } from 'angular-i18next';
import Computer, { AgentType } from '@models/Computer';

export const getComputerGeneralInfo = (computer: Computer, i18N: I18NextPipe) => {
  const emptyValue = i18N.transform('app:emptyValue');
  const backupAgentVersion = computer.apps.find((app) => app.applicationId === AgentType.Backup)?.version;
  const rmmAgentVersion = computer.apps.find((app) => app.applicationId === AgentType.RMM)?.version;
  const connectVersion = computer.apps.find((app) => app.applicationId === AgentType.RA)?.version;
  const deepInstVersion = computer.apps.find((app) => app.applicationId === AgentType.DeepInst)?.version;

  return [
    {
      label: i18N.transform('app:common.userName', { format: 'title' }),
      value: computer.userAccount?.accountName || emptyValue,
      show: true,
    },
    {
      label: i18N.transform('app:common.company', { format: 'title' }),
      value: computer.company?.name || emptyValue,
      show: true
    },
    {
      label: i18N.transform('app:common.status', {
        format: 'title'
      }),
      value: computer.authStatus || emptyValue,
      show: true
    },
    {
      label: i18N.transform('app:products.agent'),
      value: backupAgentVersion,
      show: !!backupAgentVersion
    },
    {
      label: i18N.transform('app:products.rmm'),
      value: rmmAgentVersion,
      show: !!rmmAgentVersion
    },
    {
      label: i18N.transform('app:products.ra'),
      value: connectVersion,
      show: !!connectVersion
    },
    {
      label: i18N.transform('app:products.deepinst'),
      value: deepInstVersion,
      show: !!deepInstVersion
    }
  ]
}
