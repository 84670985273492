import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Computer, { AgentType } from '@models/Computer';
import { ConnectMenuOptionModel } from '@models/MenuOptionModel';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConnectService } from '@services/connect.service';
import { MbsSize } from 'mbs-ui-kit/utils/enums/mbs-size-enum';
import { finalize } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-connect-button-group',
  styleUrls: ['./connect-button-group.component.scss'],
  templateUrl: './connect-button-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectButtonGroupComponent implements OnInit, OnChanges {
  @Input() computer: Computer;
  @Input() customClass = '';
  @Input() container = 'body';
  @Input() tooltipPlacement: PlacementArray = 'top-right';
  @Input() dropdownPlacement: PlacementArray = 'bottom-right auto';
  @Input() isCtrl = false;
  @Input() disabled = false;

  public loading = false;
  public size = MbsSize;

  public menuOptions: ConnectMenuOptionModel[];
  private firstMenuOption: ConnectMenuOptionModel;
  public defaultActionTooltip = '';
  public connectSettingsUrl = '';
  public isConnectSettingsVisible = false;

  public get isConnectAvailable(): boolean {
    return this.connectService.isConnectAvailable(this.computer);
  }

  public get isConnectDisabled(): boolean {
    return this.disabled || this.connectService.isConnectDisabled(this.computer);
  }

  constructor(private cdr: ChangeDetectorRef, private connectService: ConnectService) {}

  ngOnInit(): void {
    this.setupConnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.computer?.currentValue) this.setupConnect();
  }

  public connectAction(menuOption?: ConnectMenuOptionModel): void {
    this.setLoading(true);
    this.connectService
      .connectAction(this.computer, (menuOption ?? this.firstMenuOption).type)
      .pipe(
        finalize(() => this.setLoading(false)),
        untilDestroyed(this)
      )
      .subscribe({ error: () => this.setLoading(false) });
  }

  public isOldConnectSettingsInfoVisible() {
    return this.connectService.isConnectUseOldSettings();
  }

  public getOldConnectSettingsInfoText(): string {
    return this.connectService.getUseOldSettingsMenuItemInfoText();
  }

  public setLoading(value: boolean): void {
    this.loading = value;
    this.cdr.markForCheck();
  }

  private setupConnect(): void {
    const companyId = this.computer?.company?.id;
    this.connectSettingsUrl = companyId ? `/AP/Companies/${companyId}?tab=connectAgentOptions`  : '/AP/GlobalAgentOptions?active=ra';
    this.isConnectSettingsVisible = this.connectService.isConnectSettingsAvailable(this.computer);
    this.menuOptions = this.connectService.getMenuOptions(this.computer);
    this.firstMenuOption = this.connectService.getFirstMenuOption(this.menuOptions);
    const agentVersion = Computer.getAgent(this.computer, AgentType.RA)?.version ?? '';
    const agentVersionTooltip = agentVersion ? ` (v.${agentVersion})` : agentVersion;
    this.defaultActionTooltip = `${this.firstMenuOption.name}${agentVersionTooltip}`;
  }
}
