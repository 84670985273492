import { GlacierRestoreTiers } from '../../models/base/base-models/plan-glacier-restor-tiers-model';
import { RestoreIbbFormModel } from '../../models/restoreIBB/restore-ibb-form-model';
import { ScheduleType } from '../../models/schedule-models';
import { RestoreAsDiskEnum } from '../../models/type-data-models';
import { BaseForPlanHelper } from '../bases/base-for-plan-helper';
import { WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { PlanDataFromStepsHelper } from '../plan-data-from-steps-helper/plan-data-from-steps-helper';
import { RestorePlanFromStepsHelper } from '../restore-plan-from-steps-helper/restore-plan-from-steps-helper';

export class RestoreIbbPlanFromStepsHelpers extends BaseForPlanHelper {
  public static RestoreIBBModel: RestoreIbbFormModel = null;

  public static getUpdatedPlan(plan, model: RestoreIbbFormModel, settings: WizardHelpersSettings): any {
    this.RestoreIBBModel = model;
    this.updateSchedules(
      +ScheduleType[model.scheduleStep.ScheduleType] === ScheduleType.recurring,
      this.RestoreIBBModel.scheduleAdvancedStep
    );
    const newPlan = Object.assign({}, plan);
    this.updateModel(newPlan, settings);
    return newPlan;
  }

  static updateModel(newPlan, settings: WizardHelpersSettings): void {
    // TODO Add EC2, Azure and temporary instance
    RestorePlanFromStepsHelper.updateNewPlanByNameStep(newPlan, this.RestoreIBBModel.planNameStep);
    PlanDataFromStepsHelper.updateNewPlanByWhereBackupStep(newPlan, this.RestoreIBBModel.whereBackupStep);
    RestorePlanFromStepsHelper.updateNewPlanByBackupToRestoreStep(newPlan, this.RestoreIBBModel.backupToRestoreStep);
    RestorePlanFromStepsHelper.updateNewPlanByRestorePointStep(newPlan, this.RestoreIBBModel.restorePointStep, settings, true);
    RestorePlanFromStepsHelper.updateNewPlanByTypeDataStep(newPlan, this.RestoreIBBModel.typeDataStep);
    this.updateAzureOrEC2AndTemporary(this.RestoreIBBModel.typeDataStep.restoreAs, newPlan, this.RestoreIBBModel);
    RestorePlanFromStepsHelper.updateNewPlanByPartitionsStep(newPlan, this.RestoreIBBModel.partitionsStep);
    RestorePlanFromStepsHelper.updateNewPlanByDestinationStep(
      newPlan,
      this.RestoreIBBModel.destinationStep,
      this.RestoreIBBModel.partitionsStep
    );
    RestorePlanFromStepsHelper.updateNewPlanByEncryptionOptionsStep(newPlan, this.RestoreIBBModel.encryptionOptionsStep);
    RestorePlanFromStepsHelper.updateNewPlanByScheduleStep(
      newPlan,
      this.RestoreIBBModel.scheduleStep,
      this.RestoreIBBModel.scheduleAdvancedStep,
      settings.isLinux,
      settings.isNBF
    );
    PlanDataFromStepsHelper.updateNewPlanByPrePostActionsStep(
      newPlan,
      this.RestoreIBBModel.prePostActionsStep,
      !settings.allowPrePostActions
    );
    if (settings.isNBF) {
      newPlan.RestorePoint = `CBB_Archive\\${this.RestoreIBBModel.backupToRestoreStep.selectedBunch}\\${this.RestoreIBBModel.restorePointStep.resultPoint.restorePointId}`;
    } else newPlan.RestorePoint = null;
    newPlan.RestoreSourcePrefix = this.RestoreIBBModel.computerToRestoreStep.computerName;
    newPlan.GlacierRestoreTier = GlacierRestoreTiers.Standard;
    newPlan.LimitRestore = true;
    PlanDataFromStepsHelper.updateNewPlanByNotificationStep(newPlan, this.RestoreIBBModel.notificationStep);
    PlanDataFromStepsHelper.preparePlanForSending(newPlan);
  }

  private static updateAzureOrEC2AndTemporary(restoreAs: RestoreAsDiskEnum, newPlan, formModel): void {
    if (restoreAs === RestoreAsDiskEnum.AVM || restoreAs === RestoreAsDiskEnum.ADD) {
      RestorePlanFromStepsHelper.updatePlanFromAzureStep(newPlan, formModel.azureStep);
    } else if (restoreAs === RestoreAsDiskEnum.EBSVolume || restoreAs === RestoreAsDiskEnum.EC2) {
      RestorePlanFromStepsHelper.updatePlanFromEC2(newPlan, formModel.EC2);
      RestorePlanFromStepsHelper.updatePlanFromTemporaryInstance(newPlan, formModel.temporaryInstanceStep);
    }
  }
}
