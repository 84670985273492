import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { StorageType } from '@models/StorageType.enum';
import { StorageConnection } from '@models/storge-connections';
import { DataForAdvancedStep } from '@modules/wizards/models/advanced-options-models';
import { Storages } from '../../models/base/base-models/plan-storages-model';

export type EditedStringType = { value: string; index: number };
export type DataForPath = {
  isLinux: boolean;
  regExpAfter: RegExp;
  regExpBefore: RegExp;
  split: string;
  join: string;
  splitForBefore: string;
};
export interface WizardHelpersSettings {
  validVersionForSchedule?: boolean;
  timeZoneOffset?: number;
  agentVersion?: string;
  compName?: string;
  currentPrefix?: string;
  isNBF?: boolean;
  isLinux?: boolean;
  isOffline?: boolean;
  planType?: PlanTypes;
  mode: PlanMode;
  isRD: boolean;
  accounts: any;
  allowPrePostActions?: boolean;
  isAppAwareEnabled?: boolean;
}

export class BaseForStepsHelper {
  public static bucketId: string;
  public static timeZoneOffset: number;
  public static loadedPlan = null;
  public static planType = null;
  public static storage: StorageConnection;
  public static storageType: number;
  public static storages: Storages;
  public static accounts: any = null;
  public static isRD = false;
  public static isNBF = false;
  public static isLinux = false;
  public static isCreate = false;

  public static DefaultSettings: WizardHelpersSettings = {
    validVersionForSchedule: false,
    timeZoneOffset: 0,
    mode: 0,
    compName: '',
    accounts: null,
    isNBF: false,
    isLinux: false,
    isRD: false
  };

  private static validateStrings(firstStr: string, secStr: string, strLen: number, joinSymbol: string): boolean {
    return (
      (firstStr.includes(secStr) &&
        firstStr.startsWith(secStr) &&
        (firstStr[strLen] === joinSymbol || firstStr[strLen - 1] === joinSymbol)) ||
      (secStr.includes(firstStr) &&
        secStr.startsWith(firstStr) &&
        (secStr[firstStr.length] === joinSymbol || secStr[firstStr.length - 1] === joinSymbol))
    );
  }

  static setThisValues(plan, settings: WizardHelpersSettings): void {
    this.bucketId = null;
    this.accounts = settings.accounts;
    this.timeZoneOffset = settings.timeZoneOffset;
    this.isNBF = settings.isNBF;
    this.isLinux = settings.isLinux;
    this.isCreate = settings.mode === PlanMode.create;
    this.isRD = settings.isRD;
    this.loadedPlan = plan;
    this.planType = !(plan.PlanType === null || plan.PlanType === undefined) ? plan.PlanType : settings.planType;
    this.storages = {
      CloudStorages: plan.Storages.CloudStorages,
      LocalStorages: plan.Storages.LocalStorages.filter((s) => !settings.isLinux || s.StorageType).map((item) => {
        if (item.StorageType !== StorageType.LocalFS) item.StorageType = StorageType.LocalFS;

        return item;
      })
    };
  }

  static setStorage(storage: StorageConnection): void {
    this.storage = storage;
  }

  static setStorageType(mode, hybridID, connectionID): void {
    if (mode === PlanMode.create) {
      this.storageType = 17;
      this.setStorage(null);

      return;
    }

    const result = Array.from(this.storages.CloudStorages)
      .concat(...this.storages.LocalStorages)
      .find((storage) => storage.ID === hybridID || storage.ID === connectionID);

    this.storageType = result?.StorageType ?? 17;
    this.setStorage(result || null);
  }

  static getBucketId(name: string, SGAId: string): string {
    let bucketId: string;

    for (let i = 0; i < this.accounts.length; i++) {
      if (!bucketId && this.accounts[i].id === SGAId) {
        for (let j = 0; j < this.accounts[i].buckets.length; j++) {
          const bucket = this.accounts[i].buckets[j];
          if (name && (bucket.bucketName === name || name.includes(` (${bucket.bucketName})`))) {
            bucketId = bucket.id;
            break;
          }
        }
      }
    }

    return bucketId;
  }

  static getFilteredStringsByString(form, str: string, saveStr = '', isLinux: boolean): string[] {
    const last = str.slice(-1);
    const lowStr = isLinux ? str : str.toLowerCase();
    const lowSaveStr = isLinux ? saveStr || '' : (saveStr || '').toLowerCase();
    return Array.from(
      form.value.filter((r) => {
        const lowR = isLinux ? r : r.toLowerCase();
        const rLast = r.slice(-1);
        const rByStrLen = r[str.length];
        const strByRLen = str[r.length];
        return (
          lowR === lowSaveStr ||
          ((!(lowR.includes(lowStr) && lowR.startsWith(lowStr)) ||
            (last !== '\\' && last !== '/' && rByStrLen && rByStrLen !== '\\' && rByStrLen !== '/')) &&
            (!(lowStr.includes(lowR) && lowStr.startsWith(lowR)) ||
              (rLast !== '\\' && rLast !== '/' && strByRLen && strByRLen !== '\\' && strByRLen !== '/')))
        );
      })
    );
  }

  static getStringPathFormatted(event, data: DataForPath): { path: string; needUpdate: boolean } {
    let formatted = '';
    let needUpdate = false;
    if (!data.isLinux && /^([a-z]:\\)/.test(event.target.value)) {
      formatted = event.target.value[0].toUpperCase() + event.target.value.slice(1);
      needUpdate = true;
    }
    if (data.regExpAfter.test(event.target.value)) {
      formatted = BaseForStepsHelper.reformatString(event.target.value, data.split, data.join);
      needUpdate = true;
    } else if (data.regExpBefore.test(event.target.value)) {
      formatted = BaseForStepsHelper.reformatString(event.target.value, data.splitForBefore, data.join);
      needUpdate = true;
    }
    return { path: formatted, needUpdate };
  }

  static reformatString(str: string, split: string, join: string): string {
    return str
      .split(split)
      .map((s) => s.trim())
      .join(join);
  }

  static stringsArrayFromClipboard(str: string, isLinux = false, splitSymbol: string, joinSymbol: string): string[] {
    const strings = str
      .split('\n')
      .map((s) => s.trim())
      .filter((s) => !!s && s != '\n' && s != '\r' && s != ' ');
    let result: string[] = [];
    const splitReg = isLinux ? /( (?=\/))/ : /( (?=[A-Z]:\\))/;
    strings.forEach((s) => {
      if (splitReg.test(s)) {
        s.split(splitReg)
          .filter((s) => !!s && s != '\n' && s != '\r' && s != ' ')
          .forEach((newStr) => {
            newStr = newStr.trim();
            result.push(newStr);
          });
      } else result.push(s);
    });
    result = result.map((s) => s.trim());
    result = Array.from(new Set(result));
    return result.map((str) => this.reformatString(str, splitSymbol, joinSymbol));
  }

  static getFilteredStrings(oldStrings: string[], newStrings: string[], joinSymbol: string, isLinux: boolean): string[] {
    if (isLinux) {
      return oldStrings.filter((str) => {
        const strLen = str.length;
        return !newStrings.some((s) => {
          return this.validateStrings(s, str, strLen, joinSymbol);
        });
      });
    } else {
      return oldStrings.filter((str) => {
        const lowStr = str.toLowerCase();
        const strLen = str.length;
        return !newStrings.some((s) => {
          const lowS = s.toLowerCase();
          return this.validateStrings(lowS, lowStr, strLen, joinSymbol);
        });
      });
    }
  }

  static filterFoldersIfExistParent(strings: string[], symbol: string, isLinux: boolean): string[] {
    let result: string[] = Array.from(strings);
    if (isLinux) {
      strings.forEach((string) => {
        result = result.filter((str) => {
          return (
            str === string ||
            !(str.includes(string) && str.startsWith(string)) ||
            (str[string.length - 1] !== symbol && str[string.length] !== symbol)
          );
        });
      });
    } else {
      strings.forEach((string) => {
        const lowString = string.toLowerCase();
        result = result.filter((str) => {
          const lowStr = str.toLowerCase();
          return (
            lowStr === lowString || !lowStr.includes(lowString) || (str[string.length - 1] !== symbol && str[string.length] !== symbol)
          );
        });
      });
    }

    return result;
  }

  static updateFoldersAfterEdit(newStr: string, editedStr: string, folders: string[], isLinux: boolean): void {
    if (isLinux) {
      if (newStr !== editedStr) {
        const idx = folders.findIndex((f) => f === editedStr);
        if (~idx) folders[idx] = newStr;
      }
    } else {
      const lowEditStr = editedStr.toLowerCase();
      if (newStr.toLowerCase() !== lowEditStr) {
        const idx = folders.findIndex((f) => f.toLowerCase() === lowEditStr);
        if (~idx) folders[idx] = newStr;
      }
    }
  }

  public static strControlIsValid(control: FormControl): boolean {
    const errorsCount = !control.valid && Object.keys(control.errors).length;
    return (
      control.valid ||
      (errorsCount === 1
        ? !!control.errors.whitespace || !!control.errors.required
        : errorsCount === 2
        ? !!control.errors.whitespace && !!control.errors.required
        : false)
    );
  }

  public static helperForCustomRequired(control: AbstractControl, needValidate: boolean, incArray: string[]): ValidationErrors | null {
    const errors = control.errors ? Object.keys(control.errors) : [];
    const valid = control.valid && (!errors.length || (errors.length === 1 && control.errors.required));
    return needValidate && ((valid && control.value) || !incArray || !incArray.length)
      ? {
          required: {
            message: control.value
              ? 'Click + to add the path to the plan'
              : 'This field is required. Enter the path to the file/folder and click +'
          }
        }
      : null;
  }

  public static helperForIsSelectedValidator(
    control: AbstractControl,
    isLinux: boolean,
    editedString: EditedStringType,
    formArray: string[],
    errorText: string
  ): ValidationErrors | null {
    const error = { isSelected: { message: errorText } };
    if (isLinux) {
      if (editedString) return ~formArray.indexOf(control.value) && control.value !== editedString.value ? error : null;
      else return ~formArray.indexOf(control.value) ? error : null;
    } else {
      const lowVal = control.value.toLowerCase();
      if (editedString)
        return ~formArray.findIndex((v) => v.toLowerCase() === lowVal) && lowVal !== editedString.value.toLowerCase() ? error : null;
      else return ~formArray.findIndex((v) => v.toLowerCase() === lowVal) ? error : null;
    }
  }

  /*
   * BackupEfsFilesAsIs flag only can find for NBF File backup plan, and we must put in into advancedOptions.advancedOptionsStep.KeepEFSFilesAsIs
   * @param newPlan
   * @param isNBF
   * @param advancedOptions
   */
  public static updateEFSData(newPlan, isNBF: boolean, advancedOptions: DataForAdvancedStep): void {
    if (isNBF) {
      advancedOptions.advancedOptionsStep.KeepEFSFilesAsIs = newPlan.BackupEfsFilesAsIs || false;
    }
  }
}
