import { VmwareFormModel } from '../../models/VMWare/vmware-form-model';
import { WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { PlanDataFromStepsHelper } from '../plan-data-from-steps-helper/plan-data-from-steps-helper';
import { BaseForVMPlanHelper } from '../bases/base-for-vm-plan-helper';

export class VmwarePlanFromStepsHelper extends BaseForVMPlanHelper {
  public static VmwareModel: VmwareFormModel = null;

  public static getUpdatedPlan(plan, model: VmwareFormModel, settings: WizardHelpersSettings): any {
    this.VmwareModel = model;
    const newPlan = Object.assign({}, plan);
    this.updateModel(newPlan, settings);
    return newPlan;
  }

  static updateModel(newPlan, settings: WizardHelpersSettings): void {
    const forceRemoveAppAware = !settings.isAppAwareEnabled;

    PlanDataFromStepsHelper.updateNewPlanByNameStep(newPlan, this.VmwareModel.planNameStep);
    PlanDataFromStepsHelper.updateNewPlanByWhereBackupStep(newPlan, this.VmwareModel.whereBackupStep, true);
    PlanDataFromStepsHelper.updateNewPlanByVirtualAdvancedSettings(newPlan, this.VmwareModel.advancedSettingsStep);

    PlanDataFromStepsHelper.updateNewPlanBySelectHostStep(newPlan, this.VmwareModel.selectHostStep);
    PlanDataFromStepsHelper.updateNewPlanBySelectVirtualMachineStep(newPlan, this.VmwareModel.selectVirtualMachinesStep);
    PlanDataFromStepsHelper.updateNewPlanByAppAwareStep(newPlan, this.VmwareModel.appAwareStep, forceRemoveAppAware);
    PlanDataFromStepsHelper.updateNewPlanBySelectVirtualDisksStep(newPlan, this.VmwareModel.selectVirtualDisksStep, true);

    PlanDataFromStepsHelper.updateNewPlanArchiveByArchiveConsistencyStep(newPlan, this.VmwareModel.archiveConsistencyStep);
    PlanDataFromStepsHelper.updateNewPlanByCompressionEncryptionStep(newPlan, this.VmwareModel.compressionEncryptionStep, true);
    PlanDataFromStepsHelper.updateNewNBFPlanByScheduleStep(newPlan, this.VmwareModel.scheduleStep);
    PlanDataFromStepsHelper.updateNewNBFPlanByRetentionPolicyStep(newPlan, this.VmwareModel.retentionPolicyStep, true);
    PlanDataFromStepsHelper.updateNewPlanByPrePostActionsStep(
      newPlan,
      this.VmwareModel.prePostActionsStep,
      !settings.allowPrePostActions,
      true
    );
    PlanDataFromStepsHelper.updateNewPlanByNotificationStep(newPlan, this.VmwareModel.notificationStep, this.isLinux);
    PlanDataFromStepsHelper.preparePlanForSending(newPlan, false);
  }
}
