export enum FilterTypes {
  All = 0,
  UseIncludeMask = 1,
  UseExcludeMask = 2
}

export interface AdvancedFilterStepValue {
  backupAllFiles: FilterTypes;
  includeMask: string;
  excludeMask: string;
  BackupEmptyFolders: boolean;
  skipFolders: boolean;
  skipFolderName: string;
  backupFilesModified?: boolean;
  daysAgo?: number;
  backupFilesModifiedSince?: boolean;
  date?: Date | string;
  time?: string;
  doNotBackup?: boolean;
  fileSize?: number | string;
  ignoreSystemAndHidden: boolean;
  SkipInUseFiles?: boolean;
  ignoreOnDemand?: boolean;
  valid: boolean;
}
