<mbs-modal [title]="'backup:releaseLicense' | i18next"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           [loading]="loading"
           #modal>
  <div modal-body>
    <mbs-alert [type]="MbsPopupType.danger"
               [icon]="true">
      {{ 'backup:computerWillBeStopper' | i18next }}
    </mbs-alert>
    <div class="mt-3"
         *ngIf="companyPool">
      {{
      'backup:releaseLicenseQuestion'
      | i18next
      : {
      name: computerName,
      pool: companyPool === LicensesSettings.GlobalPool ? ('backup:pools:global' | i18next) : ('backup:pools:company' |
      i18next)
      }
      }}
    </div>
  </div>
  <ng-container modalFooter>
    <mbs-button type="outline-danger"
                (click)="release()"
                [loading]="releaseLoading"> {{ 'backup:releaseLicense' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'app:buttons:close' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
