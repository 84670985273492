import { DiskVolumeView } from '../what-backup-models';
import { BaseBackupPlan } from './base-backup-plan-model';
import { Storages } from './base-models/plan-storages-model';
import { BackupDiskImagePlan } from './ibb-plan-model';
import { RestoreIbbBase } from './restore-ibb-base-model';
import { RestorePlan } from './restore-plan-model';

export class PlanResponseModel {
  plan: BackupDiskImagePlan | BaseBackupPlan | RestoreIbbBase | RestorePlan;
  Storages: Storages;
  DiskVolumes: DiskVolumeView[];
}
