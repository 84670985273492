import Computer, { ComputersApplications, ComputersHealthFilterType, ComputersMode } from '@models/Computer';
import { ComputersStoreFilters } from '@models/ComputersStoreFilters';
import { AllPagesState, ClearPagingState, PagingState } from '@models/Paging';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EnumHelper } from 'mbs-ui-kit/utils/enum-helper';

// adapter
export function selectComputerHid(computer: Computer): string {
  return computer?.hid;
}

export const computersAdapter: EntityAdapter<Computer> = createEntityAdapter<Computer>({ selectId: selectComputerHid });

// state models
export interface ComputersPagingModeEntity extends PagingState {
  pages: AllPagesState;
  obsolescenceMark: number;
}

export const initialComputersPagingModeState: ComputersPagingModeEntity = {
  pages: {},
  loading: false,
  loaded: false,
  currentPageNumber: 1,
  perPage: null,
  total: 0,
  sort: {
    column: null,
    direction: null
  },
  obsolescenceMark: null
};

export const initialComputersFilters: ComputersStoreFilters = {
  healthType: ComputersHealthFilterType.All,
  hidden: null,
  company: null,
  searchModel: {},
  authStatus: null
};

export type ComputersState = EntityState<Computer> & {
  selected: string; // combined loading for data and entities
  loading: boolean;
  applicationsCount: ComputersApplications;
  applicationsCountInitiated: boolean;

  mode: ComputersMode;
  modeInitiated: boolean;
  pagingData: ComputersPagingModeEntity[];
  filtersData: ComputersStoreFilters[];
  filterInitiated: boolean;

  data: ClearPagingState & {
    // no filters, no sorting
    entities: string[];
    obsolescenceMark: number;
  };
};

export const initialComputersState: ComputersState = computersAdapter.getInitialState({
  selected: null,
  loading: false,
  applicationsCount: {},
  applicationsCountInitiated: false,

  mode: ComputersMode.Computers,
  modeInitiated: false,
  pagingData: EnumHelper.EnumToArray(ComputersMode).map(() => ({ ...initialComputersPagingModeState } as ComputersPagingModeEntity)),
  filtersData: EnumHelper.EnumToArray(ComputersMode).map(() => ({ ...initialComputersFilters } as ComputersStoreFilters)),
  filterInitiated: false,

  data: {
    loading: false,
    loaded: false,
    total: 0,
    entities: [],
    obsolescenceMark: null
  }
});
