<mbs-modal [title]="'Two-Factor Authentication'"
           [isCustomModal]="true"
           [titleTextOverflow]="true"
           [size]="MbsSize.sm"
           [showFooter]="false">

  <ng-container modal-body>

    To&nbsp;<ng-container *ngIf="!isVerb">use </ng-container>{{ actionName ?? 'Remote Management' }}, the <span class="nowrap">Two-Factor</span> Authentication (2FA) must be
    <a (click)="baseModal.close()"
       [routerLink]="['/AP/Settings']">
      enabled
    </a>

  </ng-container>

</mbs-modal>
