export enum LicenseType {
  NA = -1,
  Desktop = 0,
  MSSQL = 1,
  Exchange = 2,
  SqlExchage = 3,
  Network5 = 4,
  Network20 = 5,
  UnixAgent = 6,
  BareMetal = 7,
  MAC = 8,
  OnlyDesktop = 9,
  VirtualMachine = 10,
  VirtualMachineSocket = 11,

  // / <summary>
  // / Deprecated by Advanced Rebranding & Custom SSL
  // / exists MBSCustomSSLCertificate licenses will be works as before
  // / </summary>
  MBSCustomSSLCertificate = 12,
  Box = 13,
  Dedup = 14,
  DriveDesktop = 15,
  DriveServer = 16,
  Gapps = 17,
  Ultimate = 18,

  // / <summary>
  // / Deprecated by Advanced Rebranding & Custom SSL
  // / exists Rebranding licenses will be works as before
  // / </summary>
  Rebranding = 19,
  // / <summary>
  // / Advanced Rebranding & Custom SSL
  // / New license instead Rebranding, MBSCustomSSLCertificate
  // / </summary>
  'Advanced Rebranding & Custom SSL' = 20,
  RemoteDesktop = 21, //  Early RemoteAssistant
  RMM = 23,
  SharePoint = 24
}
