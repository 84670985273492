import { Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HostType, SelectHostStepValue } from '@modules/wizards/models/select-host-models';
import { VirtualMachinesSelectedWithNumbersType, VirtualMachinesType } from '@modules/wizards/models/select-virtual-machines-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import {
  ParamsForCheckVMSocketLicenses,
  ParamsForTestCredentials,
  WizardStepsService
} from '@modules/wizards/services/wizard-steps.service';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormsUtil, ModalService, ToastService, WizardStep } from 'mbs-ui-kit';
import { first } from 'rxjs';
import { tap } from 'rxjs/operators';

const SelectHostStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SelectHostStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-select-host-step',
  templateUrl: './select-host-step.component.html',
  providers: [SelectHostStepValueAccessor]
})
export class SelectHostStepComponent extends StepBase<SelectHostStepValue> implements OnInit {
  public InitialValue: SelectHostStepValue;
  public valueAfterVerified: SelectHostStepValue;
  public passwordInputType = 'password';
  public isValidCredentials: boolean = null;
  public isValidHost: boolean = null;
  public needValidateData = true;
  public notUpdatePassToInitial = false;

  constructor(
    public mainService: RemoteManagementWizardsService,
    private modalService: ModalService,
    private stepService: WizardStepsService,
    private toastService: ToastService
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      server: new FormControl('', [Validators.required, this.hostNameIsValid.bind(this)]),
      login: new FormControl('', [Validators.required, this.hostCredentialsIsValid.bind(this)]),
      password: new FormControl('', [Validators.required, this.hostCredentialsIsValid.bind(this)]),
      remember: new FormControl(false)
    });

    this.initFormEvents();
  }

  onStepFormChange(value: SelectHostStepValue): void {
    if (this.valueAfterVerified) {
      this.needValidateData =
        this.valueAfterVerified.login !== value.login ||
        this.valueAfterVerified.password !== value.password ||
        this.valueAfterVerified.server !== value.server;
    }

    this.value = { ...value, valid: this.stepForm.valid && (this.isRDMode || this.isOffline || !this.needValidateData) };
  }

  hostNameIsValid(): ValidationErrors | null {
    return this.isValidHost === false ? { invalidHost: true } : null;
  }

  hostCredentialsIsValid(): ValidationErrors | null {
    return this.isValidCredentials === false ? { invalidCredentials: true } : null;
  }

  updateForm(value: SelectHostStepValue): void {
    if (this.isEdit) {
      this.InitialValue = value;
      this.valueAfterVerified = { server: value.server, login: value.login, password: value.password, remember: null };
    }

    this.stepForm.reset(value);
  }

  forceValid(step: WizardStep & { isSave?: boolean; isNext?: boolean } = null): void {
    if (!this.isRDMode && !this.isOffline && this.needValidateData && this.stepForm.valid && step.isNext) {
      this.runValidationRequests(step.isSave);
    } else {
      FormsUtil.triggerValidation(this.stepForm);
      this.resetValidStateForValidFields();
    }
  }

  runValidationRequests(isSave: boolean): void {
    this.loadInfo.emit({ loading: true });

    this.stepService
      .getRemoteCommandData(this.getParams(false), this.mainService.hid)
      .pipe(
        first(),
        tap((result) => {
          this.isValidCredentials = !!result?.data?.result;
          this.isValidHost = true;

          if (this.isValidCredentials) {
            this.needValidateData = false;
            this.valueAfterVerified = this.stepForm.value;
            this.notUpdatePassToInitial = this.valueAfterVerified?.password !== this.InitialValue?.password;
          }

          FormsUtil.triggerValidation(this.stepForm);

          if (this.stepForm.valid) this.nextStep.emit(isSave);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => this.loadInfo.emit({ loading: false }),
        error: (e) => {
          this.isValidHost = e?.error?.errorCode !== 4999 && !e?.error?.detail?.includes('The remote name could not be resolved:');
          this.isValidCredentials = !e?.error?.detail?.includes('ESXi credential');

          FormsUtil.triggerValidation(this.stepForm);

          this.loadInfo.emit({ loading: false });
        }
      });
  }

  getParams(isVcenterCheck = true): ParamsForTestCredentials | ParamsForCheckVMSocketLicenses {
    const formValue = this.stepForm.value;
    if (isVcenterCheck) {
      return {
        agentType: 'backup',
        commandType: 'CheckVMSocketLicenses',
        params: {
          PlanId: this.mainService.planId,
          Type: VirtualMachinesType.VMWare,
          Server: formValue.server,
          UserName: formValue.login,
          Password: formValue.password,
          VMBackupType: +VirtualMachinesSelectedWithNumbersType['All'],
          Machines: []
        }
      };
    } else {
      return {
        agentType: 'backup',
        commandType: 'CheckVMCredentials',
        params: { Type: HostType.VMWare, Server: formValue.server, Login: formValue.login, Password: formValue.password }
      };
    }
  }

  resetIsValidCredentials(): void {
    if (this.isValidCredentials === false || this.isValidHost === false) {
      this.isValidCredentials = null;
      this.isValidHost = null;
      this.needValidateData = true;
      this.stepForm.get('server').updateValueAndValidity();
      this.stepForm.get('login').updateValueAndValidity();
      this.stepForm.get('password').updateValueAndValidity();
    }
  }

  changeTypePassword(): void {
    this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
  }

  passwordFocusHandler(event): void {
    if (event?.target?.value && this.InitialValue && event.target.value === this.InitialValue.password) {
      this.stepForm.get('password').setValue('');
    }
  }

  passwordBlurHandler(event): void {
    if (event?.target && !event.target.value && this.InitialValue?.password && !this.notUpdatePassToInitial) {
      this.stepForm.get('password').setValue(this.InitialValue.password);
    }
  }
}
