import { ChangeDetectorRef, Component } from '@angular/core';
import { StorageType } from '@models/StorageType.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { first } from 'rxjs/operators';
import { RestoreIbbModelForStepHelpers } from '../../helpers/restore-ibb-helpers/restore-ibb-model-for-step-helpers';
import { RestoreIbbPlanFromStepsHelpers } from '../../helpers/restore-ibb-helpers/restore-ibb-plan-from-steps-helpers';
import { SupportMethodsForStepsFromPlan } from '../../helpers/support-methods-for-steps-from-plan';
import { PlanResponseModel } from '../../models/base/plan-response-model';
import { RestoreIbbBase } from '../../models/base/restore-ibb-base-model';
import { RestoreIbbFormModel } from '../../models/restoreIBB/restore-ibb-form-model';
import { RestoreAsDiskEnum } from '../../models/type-data-models';
import { WizardsServicesHubService } from '../../services/wizards-services-hub.service';
import { WizardBaseClass } from '../wizard-base.class';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';

@UntilDestroy()
@Component({
  selector: 'mbs-image-based-restore-wizard',
  templateUrl: './image-based-restore-wizard.component.html'
})
export class ImageBasedRestoreWizard extends WizardBaseClass {
  public restoreAsDiskEnum = RestoreAsDiskEnum;
  public restorePointTitle = this.i18nPipe.transform('wizards:restore_point_title', { format: 'title' });
  public partitionsTitle = this.i18nPipe.transform('wizards:partitions_step_title', { format: 'title' });
  constructor(
    private cdr: ChangeDetectorRef,
    public services: WizardsServicesHubService,
    public i18nPipe: I18NextPipe,
    stepService: WizardStepsService
  ) {
    super(services, i18nPipe, stepService);
    this.FormModel = new RestoreIbbFormModel(this.isNBF);
    if (!this.isRDMode) {
      this.myPlan$
        .pipe(
          first((p) => !!p),
          untilDestroyed(this)
        )
        .subscribe((plan) => plan && this.updateStepData(plan));
      return;
    }
    this.updateStepData(new PlanResponseModel(), true);
  }

  updateStepData(plan, isDefault = false): void {
    this.loadedPlan = plan;
    if (this.isEdit && !this.isRDMode) this.mainService.GetPlanNotificationOptions();
    if (!isDefault) {
      this.FormModel = RestoreIbbModelForStepHelpers.RestoreIBBModelFromPlan(plan, this.defaultSettings());
    } else {
      this.FormModel.planNameStep.Name = SupportMethodsForStepsFromPlan.generatePlanName(
        this.mainService.compName,
        this.planType,
        this.mainService.timeZoneOffset
      );
      this.loadedPlan.plan = new RestoreIbbBase();
      this.updateStorages();
    }
  }

  restoreAsChangeHandler(event: RestoreAsDiskEnum): void {
    const newAccounts = [];
    if (this.storageAccounts.length) {
      if (!this.FormModel.azureData.accounts.length && (event === RestoreAsDiskEnum.ADD || event === RestoreAsDiskEnum.AVM)) {
        this.storageAccounts.forEach(
          (acc) => acc.storageType === StorageType.AzureVM && newAccounts.push({ value: acc.id, label: acc.name })
        );
        this.FormModel.azureData = { ...this.FormModel.azureData, accounts: newAccounts };
        if (!this.FormModel.azureStep.account) this.FormModel.azureStep.account = newAccounts[0].value;
      } else if (!this.FormModel.EC2Data.accounts.length && (event === RestoreAsDiskEnum.EC2 || event === RestoreAsDiskEnum.EBSVolume)) {
        this.storageAccounts.forEach(
          (acc) => acc.storageType === StorageType.AmazonS3 && newAccounts.push({ value: acc.id, label: acc.name })
        );
        this.FormModel.EC2Data = { ...this.FormModel.EC2Data, accounts: newAccounts };
        this.FormModel.temporaryData = { ...this.FormModel.temporaryData, accounts: newAccounts };
        if (!this.FormModel.EC2.account) this.FormModel.EC2.account = newAccounts[0].value;
        if (!this.FormModel.temporaryInstanceStep.account) this.FormModel.temporaryInstanceStep.account = newAccounts[0].value;
      }
    }
  }

  protected loadInfoHandlerFinally() {
    this.cdr.detectChanges();
  }

  handleSave(): void {
    const newPlan = RestoreIbbPlanFromStepsHelpers.getUpdatedPlan(this.loadedPlan.Plan, this.FormModel, this.defaultSettings());
    this.savePlan(newPlan);
  }
}
