import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { AppFooterComponent } from './app-footer.component';

const declarations = [AppFooterComponent];

@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [CommonModule, I18NextModule, FormsModule, ReactiveFormsModule, MbsUiKitModule, AbilityModule]
})
export class FooterModule {}
