import { FormControl } from '@angular/forms';

export enum TreeIconPath {
  Disk = 'assets/images/icons/file.svg',
  Folder = 'assets/images/icons/windowsFolder.svg',
  File = 'assets/images/icons/listFile.svg'
}

export enum AlternativeTreeIconPath {
  'assets/images/icons/listFile.svg' = 0,
  'assets/images/icons/shared-icon.svg' = 1,
  'assets/images/icons/windowsFolder.svg' = 2,
  'assets/images/icons/file.svg' = 3
}

export class BackupFilter {
  FilterType: number;
  Filters: string[];
  IncludeRecallOnDataAccessAttribute: boolean;
  IncludeSystemAndHidden: boolean;
  constructor() {
    this.FilterType = 0;
    this.Filters = [];
    this.IncludeRecallOnDataAccessAttribute = false;
    this.IncludeSystemAndHidden = false;
  }
}

export enum NetworkShareViewMode {
  form,
  list
}
export enum CheckCredentialsResultEnum {
  Unknown,
  Success,
  Error
}

export type WhatBackupTreeStepFoldersForm = {
  includeString: FormControl<string>;
  excludeString: FormControl<string>;
}

export type WhatBackupTreeStepValueForm = {
  includeFolders: FormControl<string[]>;
  excludeFolders: FormControl<string[]>;
}

export interface WhatBackupTreeStepValue {
  includeFolders?: string[];
  excludeFolders?: string[];
  valid: boolean;
}
