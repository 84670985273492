<section [formGroup]="stepForm"
         class="mbs-wizard_form">

  <ng-container *ngIf="view === viewMode.Default">
    <header class="mbs-wizard_form-header mb-4">
      <h4 class="mbs-wizard_form-title"
          [attr.data-test-element]="elementsSelector.name.defaultTitle">
        {{ 'wizards:what_backup_title' | i18next: { format: 'title' } }}
      </h4>
    </header>
  </ng-container>

  <ng-container *ngIf="view === viewMode.Onboarding">
    <h4 class="mbs-wizard_form-title"
        [attr.data-test-element]="elementsSelector.name.onBoardingTitle">
      {{ 'wizards:what_backup_title' | i18next: { format: 'title' } }}
    </h4>
  </ng-container>


  <div class="mbs-wizard_form-content">

    <mbs-alert *ngIf="isOffline"
               [attr.data-test-element]="elementsSelector.name.isOfflineAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:what_backup_offline_alert_text' | i18next}}
    </mbs-alert>

    <mbs-form-group>
      <mbs-radio formControlName="BackupVolumes"
                 name="BackupVolumes"
                 [label]="'wizards:system_partitions' | i18next"
                 [value]="1"
                 (change)="whatBackupChangeHandler($event)"
                 [id]="'what-backup-1'"></mbs-radio>
      <mbs-radio formControlName="BackupVolumes"
                 name="BackupVolumes"
                 [label]="'wizards:all_drives' | i18next"
                 [value]="2"
                 (change)="whatBackupChangeHandler($event)"
                 [id]="'what-backup-2'"></mbs-radio>
      <mbs-radio *ngIf="canShowFixedOnly"
                 formControlName="BackupVolumes"
                 name="BackupVolumes"
                 (change)="whatBackupChangeHandler($event)"
                 [attr.data-test-element]="elementsSelector.name.fixedSelectedRadio"
                 [label]="'wizards:selected_fixed' | i18next"
                 [value]="4"
                 [id]="'what-backup-4'"></mbs-radio>

      <mbs-radio *ngIf="!isRDMode && (!isOffline || partitions?.length)"
                 formControlName="BackupVolumes"
                 name="BackupVolumes"
                 [attr.data-test-element]="elementsSelector.name.selectedRadio"
                 [label]="'wizards:selected_partitions' | i18next"
                 [value]="3"
                 [id]="'what-backup-3'"></mbs-radio>
    </mbs-form-group>

    <mbs-alert *ngIf="isRDMode && isNBF && stepForm.get('BackupVolumes').value === backupTargetVolumesEnum.FixedOnly"
               [attr.data-test-element]="elementsSelector.name.agentVersionAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:agent_version_alert_text' | i18next}}
    </mbs-alert>

    <mbs-alert *ngIf="requiredBySystemPartitionsIsNotSelected"
               [attr.data-test-element]="elementsSelector.name.requiredSystemAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:system_required_partitions' | i18next}}
    </mbs-alert>

    <mbs-alert *ngIf="isRDMode && importedPlanChanged"
               [attr.data-test-element]="elementsSelector.name.changedImportedAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:where_backup_config_not_supported' | i18next}}
    </mbs-alert>

    <div *ngIf="!isRDMode && (!isOffline || partitions?.length)"
         class="mbs-form-group mb-0"
         [class.mb-3]="!isRDMode && (!isOffline || partitions?.length)">
      <mbs-list-select id="what-backup-partition-table"
                       listType="table"
                       [attr.data-test-element]="elementsSelector.name.listSelect"
                       [bindSelected]="bindSelected"
                       [bindDisabledValues]="disabledRowBy"
                       [invalid]="stepForm.touched && !value?.valid"
                       [keepState]="true"
                       [maxHeight]="'286px'"
                       [headers]="headers"
                       [isNeedSelectRow]="false"
                       [loadSource]="false"
                       [showCheckboxes]="true"
                       [showHeaders]="true"
                       [showSearch]="false"
                       [selectedData]="selectedItems"
                       [data]="partitions"
                       [noDataMessage]="'wizards:where_backup_no_data' | i18next"
                       (changeSelected)="itemsCheckedHandler($event)"
                       *ngIf="headers">
        <ng-container *ngIf="isColumnShown(headerName.Disk)">
          <ng-template mbsTableCell
                       let-disk>
            <mbs-text-ellipsis [tooltip]="disk.DriveType === 'Dynamic Disc' ? 'Dynamic Disc' : disk.DiskNumber || '-'">
              {{disk.DriveType === 'Dynamic Disc' ? 'Dynamic Disc' : disk.DiskNumber}}
            </mbs-text-ellipsis>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.Volume)">
          <ng-template mbsTableCell
                       let-disk>
            <mbs-text-ellipsis placement="top-left auto"
                               tooltipClass="tooltip-lg"
                               [tooltip]="disk.MountPointsInfo">
              {{disk.MountPointsInfo}}
              <ng-container *ngIf="isEdit && (disk[disabledRowBy.key] === disabledRowBy.value || disk[disabledRowBy.key] === +VolumeUsageStateEnum.New)">
              <span class="ico ico-InfoCircle text-primary"
                    [class.ml-2]="disk.MountPointsInfo"
                    container="body"
                    [ngbTooltip]="disk[disabledRowBy.key] === disabledRowBy.value ? ('wizards:volume_is_missing' | i18next) : 'wizards:volume_is_detected' | i18next"></span>
              </ng-container>
            </mbs-text-ellipsis>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.Size)">
          <ng-template mbsTableCell
                       let-disk>
            {{disk.LengthString}}
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.Used)">
          <ng-template mbsTableCell
                       let-disk>
            {{disk.UsedSpaceString}}
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.Label)">
          <ng-template mbsTableCell
                       let-disk>
            <mbs-text-ellipsis placement="top-left auto"
                               tooltipClass="tooltip-lg"
                               [tooltip]="disk.Label || '&mdash;'">
              {{disk.Label || '&mdash;'}}
            </mbs-text-ellipsis>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.KeepBitLocker)">
          <ng-template mbsTableCell
                       let-disk>
            <mbs-checkbox [checked]="disk.KeepBitLocker"
                          [disabled]="!disk.CanKeepBitlocker || disk[disabledRowBy.key] === disabledRowBy.value"
                          [id]="disk[bindSelected] + '_keep_bit_locker_checkbox'"
                          (change)="discChangeHandler($event, disk[bindSelected], 'KeepBitLocker')"></mbs-checkbox>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.UseVSS)">
          <ng-template mbsTableCell
                       let-disk>
            <mbs-checkbox [checked]="disk.UseVSS"
                          [disabled]="disk[disabledRowBy.key] === disabledRowBy.value"
                          [id]="disk[bindSelected] + '_use_vss_checkbox'"
                          (change)="discChangeHandler($event, disk[bindSelected], 'UseVSS')"></mbs-checkbox>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.FS)">
          <ng-template mbsTableCell
                       let-disk>
            <mbs-text-ellipsis placement="top-left auto"
                               tooltipClass="tooltip-lg"
                               [tooltip]="FileSystemTypeEnum[disk.FileSystemType]">
              {{FileSystemTypeEnum[disk.FileSystemType]}}
            </mbs-text-ellipsis>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isColumnShown(headerName.SectorSize)">
          <ng-template mbsTableCell
                       let-disk>
            {{disk.SectorSize}}
          </ng-template>
        </ng-container>
      </mbs-list-select>
    </div>
  </div>

  <mbs-alert *ngIf="!isRDMode && !(view === viewMode.Onboarding)"
             [attr.data-test-element]="elementsSelector.name.vssNotUsedAlert"
             [type]="mbsPopupType.info"
             [icon]="true">
    {{'wizards:vss_not_used_alert' | i18next}}
  </mbs-alert>
</section>
