import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';

@Injectable()
export class CurrentUserGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.currentUser.pipe(
      first(),
      map(user => {
        if (!user) {
          return false;
        }

        const onboardingPage = 'GettingStart';
        const isCurrentPathExcluded = [RoutingPath.ApHelp].some((path) => state.url.includes(path));

        if (state.url.includes(onboardingPage)) {
          return user.IsWizardComplete ? this.router.createUrlTree(['AP', 'Administrators']) : true;
        } else if (user.IsWizardComplete || isCurrentPathExcluded) {
          return true;
        } else if (this.router.url.includes(onboardingPage)) {
          return false;
        } else {
          return this.router.createUrlTree(['AP', onboardingPage]);
        }
      })
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.authService.loaded$, this.authService.loading$]).pipe(
      first(),
      switchMap(([loaded, loading]) => {
        if (!loading && !loaded) {
          return this.authService.fetchCurrentUser().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
          );
        }
        return of(true);
      })
    );
  }
}
