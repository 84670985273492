import { ItemTypeEnum } from '@models/backup/storages-type';

export enum ArchiveType {
  Files = 0,
  DiskImage = 2,
  HyperV = 5,
  VMware = 6
}

export enum IdsForCurrentFormats {
  File = 'Files Backup',
  FileLinux = 'File-level Backup',
  Ibb = 'Image Based',
  VMWare = 'VMware Hosts'
}

export enum ArchiveRestorePointSummaryStatus {
  Success,
  Warning,
  Fail,
  Running,
  Unknown,
  DeepSync
}

export const cbfIdArray: string[] = [
  IdsForCurrentFormats.Ibb,
  IdsForCurrentFormats.File,
  IdsForCurrentFormats.FileLinux,
  IdsForCurrentFormats.VMWare
];

export interface BunchItem {
  itemType?: ItemTypeEnum;
  isNBF?: boolean;
  lastBackupStatus?: ArchiveRestorePointSummaryStatus;
  retentionKeepBackupFor?: number;
  isCanBeDeleted?: boolean;
  isCanBeExpand?: boolean;
  isPlanExists?: boolean;
  bunchId: string;
  path?: string;
  bunchName: string;
  displayName?: string;
  firstRestoreDate: string;
  lastRestoreDate: string;
  originalSize: number;
  sizeOnStorage: number;
  totalSavings: number;
  type: string;
  daysToPurge: number;
  purgeSummary: string;
  countOfErrorRestorePoints?: number;
  countOfSuccessfulRestorePoints?: number;
  countOfWarningRestorePoints?: number
}

export interface BackupToRestoreStepValue {
  selectedBunch: string;
  backupPlanName?: string;
  valid: boolean;
}

export interface ComputerBackupStorageItem {
  displayName?: string;
  id?: string;
  isCurrent?: boolean;
  IsCanBeExpand?: boolean;
  itemType?: number;
  name?: string;
  path?: string;
}
