import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import Computer from '@models/Computer';
import { ConnectionType } from '@models/ConnectionType';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-remote-connection-choose-type-modal-component',
  templateUrl: './remote-connection-choose-type-modal.component.html'
})
export class RemoteConnectionChooseTypeModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public connectionType = ConnectionType;
  public mbsPopupType = MbsPopupType;
  public form = new FormControl(ConnectionType.SSH);
  public computer: Computer;

  ngAfterViewInit(): void {
    if (this.baseModal.data) {
      this.computer = this.baseModal.data.computer;
    }
  }
}
