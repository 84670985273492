import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildType } from '@models/BuildType.enum';
import { PagedResponse, QueryFiltersParams } from '@models/Paging';
import { ChangeCompanyInfo, CreateUserDto, DeleteUserMode, UpdateUserDto, User, UserComputerData } from '@models/user';
import { DeleteUsersGroupActionParamsType, EnableUsersGroupActionParamsType, UsersQueryParams } from '@services/users/users.types';
import { Observable } from 'rxjs';
import { MANUAL_ERROR_HANDLED } from '@shared/interceptors/error-handler.interceptor';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseHref = '/api/users';

  constructor(private http: HttpClient) {}

  get(params?: UsersQueryParams): Observable<PagedResponse<User>> {
    return this.http.get<PagedResponse<User>>(this.baseHref, { params });
  }

  getById(id: string): Observable<User> {
    return this.http.get<User>(`${this.baseHref}/${id}`);
  }

  create(params: CreateUserDto): Observable<User> {
    return this.http.post<User>(this.baseHref, params);
  }

  updateUser(params: UpdateUserDto, id: string): Observable<User> {
    return this.http.put<User>(`${this.baseHref}/${id}`, params);
  }

  enable(user: User, enabled: boolean): Observable<User> {
    return this.updateUser(
      {
        name: user.email,
        enabled
      },
      user.id
    );
  }

  deleteUser(id: string, deleteUserMode: DeleteUserMode, computers?: UserComputerData[]): Observable<null> {
    return this.http.delete<null>(`${this.baseHref}/${id}`, {
      body: { mode: deleteUserMode, ...(deleteUserMode === DeleteUserMode.DataOnly ? { computersData: computers } : {}) }
    });
  }

  resetPassword(
    id: string,
    params: {
      notificationEmails: string[];
      signUpNotificationBuildList: BuildType[];
    }
  ): Observable<null> {
    return this.http.post<null>(`${this.baseHref}/${id}/password/reset`, params);
  }

  getADRequests(): Observable<Record<string, unknown>[]> {
    return this.http.get<Record<string, unknown>[]>(`${this.baseHref}/ad-requests`);
  }

  cancelDeleting(userId: string): Observable<null> {
    return this.http.post<null>(`${this.baseHref}/${userId}/cancel-deleting`, null);
  }

  enableUsers(params: EnableUsersGroupActionParamsType): Observable<null> {
    return this.http.put<null>(`${this.baseHref}/enable`, params);
  }

  deleteUsers(params: DeleteUsersGroupActionParamsType): Observable<null> {
    return this.http.delete<null>(this.baseHref, {
      body: params
    });
  }

  updateCompany(userId: string, params: { companyId: string; leaveLicenseInLastCompany?: boolean }): Observable<User> {
    return this.http.put<User>(`${this.baseHref}/${userId}/company`, params);
  }

  changeCompanyInfo(userId: string): Observable<ChangeCompanyInfo> {
    return this.http.get<ChangeCompanyInfo>(`${this.baseHref}/${userId}/company/change-company-info`);
  }

  export(body: { users?: string[]; filter?: QueryFiltersParams }): Observable<string> {
    return this.http.post<string>(`${this.baseHref}/export`, body, {
      responseType: 'text' as 'json'
    });
  }

  getUserComputersData(id: string): Observable<UserComputerData[]> {
    return this.http.get<UserComputerData[]>(`${this.baseHref}/${id}/computers-data`);
  }

  changePassword(id: string, params: { password: string; logoutUser: boolean }): Observable<null> {
    return this.http.post<null>(`${this.baseHref}/${id}/password`, params, {
      headers: new HttpHeaders({ MANUAL_ERROR_HANDLED })
    });
  }

  isUserExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseHref}/exists`, {
      params: { email }
    })
  }
}
