import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ComputerModalsFacade } from '@facades/computer.modals.facade';
import { ComputersFacade } from '@facades/computers.facade';
import Computer, { AgentType } from '@models/Computer';
import { handleRMMError } from '@utils/handleError';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent, ToastService } from 'mbs-ui-kit';
import { take } from 'rxjs';
import { Debt } from '../licenses/models/license.model';
import { getAgent, getBackupVersion, getConnectVersion, getRMMVersion } from './utils';

@Component({
  selector: 'mbs-deep-instinct-modal',
  templateUrl: './deep-instinct-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeepInstinctModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public debt: Debt = null;
  public payLinkLoading = false;
  public acceptTerms = false;

  constructor(
    private facade: ComputersFacade,
    private toastService: ToastService,
    public i18nPipe: I18NextPipe,
    private modals: ComputerModalsFacade
  ) {}

  isWindows(computer: Computer) {
    return computer.os === 'windows';
  }

  compareVersions(firstVersion: string, secondVersion: string): boolean {
    if (!firstVersion || !secondVersion) {
      return false;
    }

    const firstVersionArray = firstVersion.split('.');
    const secondVersionArray = secondVersion.split('.');

    let isFirstGreatherOrEqualSecond = true;

    if (firstVersionArray.length > secondVersionArray.length) {
      return true;
    }
    if (firstVersionArray.length < secondVersionArray.length) {
      return false;
    }

    for (const [index, version] of firstVersionArray.entries()) {
      if (+version < +secondVersionArray[index]) {
        isFirstGreatherOrEqualSecond = false;
      }
      if (+version > +secondVersionArray[index]) {
        break;
      }
    }

    return isFirstGreatherOrEqualSecond;
  }

  isValidRMM(computer: Computer) {
    const validRMMVersion = '1.3.0.0';
    return (
      this.compareVersions(getRMMVersion(computer), validRMMVersion) && getAgent(computer, AgentType.RMM)?.applicationState === 'Enabled'
    );
  }

  isValidBackup(computer: Computer) {
    const validBackupVersion = '6.3.5.22';
    return (
      this.compareVersions(getBackupVersion(computer), validBackupVersion) &&
      getAgent(computer, AgentType.Backup)?.applicationState === 'Enabled'
    );
  }

  isValidConnect(computer: Computer) {
    const validConnectVersion = '2.4.1';
    return (
      this.compareVersions(getConnectVersion(computer), validConnectVersion) &&
      getAgent(computer, AgentType.RA)?.applicationState === 'Enabled'
    );
  }

  isValidComputer() {
    const computer = this.baseModal.data.computer;

    return computer
      ? this.isWindows(computer) && (this.isValidBackup(computer) || this.isValidConnect(computer) || this.isValidRMM(computer))
      : false;
  }

  confirm() {
    const computer = this.baseModal.data.computer;

    if (!this.isValidComputer()) {
      this.showError();
      return;
    }

    this.facade
      .installAgent(computer, AgentType.DeepInst)
      .pipe(take(1))
      .subscribe((result) => {
        if (result.isErrorsInResultList) {
          handleRMMError(result.resultList, this.toastService);
        } else {
          this.modals.openInstallationStartedModal(AgentType.DeepInst, computer);
        }
        this.baseModal.close();
      });
  }

  showError() {
    this.toastService.error(
      'For correct installation, Backup Agent version 6.3.5.22, or Connect version 2.4.1 or RMM version 1.3 must be installed and be in a running state. OS must be Windows'
    );
  }
}
