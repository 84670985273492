<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal>
  <div modal-body>
    <div class="d-flex">
      <img src="assets/images/deep-instinct/deep-instinct.svg"
           alt=""
           srcset="" />
      <div class="ml-3 w-50">
        <h4>Deep Instinct Endpoint Protection</h4>
        <span>The MSP360 collaboration with Deep Instinct provides you with total End Point Protection from Viruses and
          Malware</span>
      </div>
    </div>
    <h5 class="mt-3">Subscription Terms</h5>
    <div class="container">
      <div class="row gx-3">
        <div class="col-4 pl-0">
          <div class="bg-blue-10 pt-3 pl-3 rounded h-100 pb-3">
            <img src="assets/images/deep-instinct/like.svg"
                 alt=""
                 srcset="" />
            <ul class="p-0 mt-1">
              <li class="list-unstyled">Easy deployment</li>
              <li class="list-unstyled mt-2">Centralized event management</li>
            </ul>
          </div>
        </div>

        <div class="col-4">
          <div class="bg-blue-10 pt-3 pl-3 rounded h-100 pb-3">
            <img src="assets/images/deep-instinct/card.svg"
                 alt=""
                 srcset="" />
            <ul class="p-0 mt-1">
              <li class="list-unstyled">Pay-As-You-Go pricing model</li>
              <li class="list-unstyled mt-2">15 days free trial</li>
            </ul>
          </div>
        </div>

        <div class="col-4 pr-0">
          <div class="bg-blue-10 pt-3 pl-3 rounded h-100 pb-3">
            <img src="assets/images/deep-instinct/handshake.svg"
                 alt=""
                 srcset="" />
            <ul class="p-0 mt-1">
              <li class="list-unstyled">No Contracts</li>
              <li class="list-unstyled mt-2">Cancel any time</li>
              <li class="list-unstyled mt-2">Professional Onboarding Service</li>
            </ul>
          </div>
        </div>

      </div>
    </div>

  </div>
  <ng-container modalFooter>
    <div class="d-flex align-items-center justify-content-between w-100">
      <mbs-checkbox [(ngModel)]="acceptTerms"
                    label="I agree with">
        <a appendLabel
           class="ml-1"
           href="/AP/Help/security/di/terms-deep-instinct"
           target="_blank">Terms and Conditions <span class="fa fa-external-link"></span></a>
      </mbs-checkbox>
      <div>
        <mbs-button type="primary"
                    [disabled]="!acceptTerms"
                    [loading]="payLinkLoading"
                    (click)="confirm()">{{ 'app:deepInstinctModal:continue' | i18next: { format: 'title' } }}
        </mbs-button>
        <mbs-button type="secondary"
                    data-dismiss="modal"
                    class="ml-3"
                    (click)="modal.close()">{{ 'app:buttons:cancel' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>
    </div>
  </ng-container>
</mbs-modal>
