import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Computer from '@models/Computer';
import {
  AgentRemoteCommand,
  AgentRemoteCommandType,
  BulkRemoteCommand,
  BulkRemoteCommandType,
  GroupRemoteCommand,
  RemoteCommandResponse,
  RepositorySyncState,
  SingleRemoteCommand
} from '@models/rm/remote-command-model';
import { Observable } from 'rxjs';
@Injectable()
export abstract class RmCommandsAbstractService {
  abstract remoteCommandByHid<TResult>(hid: string, payload: SingleRemoteCommand): Observable<RemoteCommandResponse<TResult>>;
  abstract remoteCommand<TResult>(computer: Computer, payload: SingleRemoteCommand): Observable<RemoteCommandResponse<TResult>>;
  abstract groupRemoteCommand<TCommands extends AgentRemoteCommand<AgentRemoteCommandType>, TResult>(
    payload: GroupRemoteCommand<TCommands>
  ): Observable<TResult>;
  abstract bulkRemoteCommand<TParams extends BulkRemoteCommandType, TResult>(payload: BulkRemoteCommand<TParams>): Observable<TResult>;
  abstract uninstallAgents(payload: { hid: string; appIds: string }): Observable<any>;
  abstract getSyncStatus(hid: string): Observable<{ state: RepositorySyncState }>;
}

/**
 * Remote Management commands
 * https://utrack.cloudberrylab.com/articles/BACKUP-A-6/RM-API
 */
@Injectable()
export class RmCommandsService implements RmCommandsAbstractService {
  constructor(private http: HttpClient) {}

  remoteCommandByHid<TResult>(hid: string, payload: SingleRemoteCommand): Observable<RemoteCommandResponse<TResult>> {
    return this.http.post<RemoteCommandResponse<TResult>>(`api/computers/${hid}/remotecommand`, payload);
  }

  remoteCommand<TResult>(computer: Computer, payload: SingleRemoteCommand): Observable<RemoteCommandResponse<TResult>> {
    return this.remoteCommandByHid(computer.hid, payload);
  }

  groupRemoteCommand<TCommands extends AgentRemoteCommand<AgentRemoteCommandType>, TResult>(
    payload: GroupRemoteCommand<TCommands>
  ): Observable<TResult> {
    return this.http.post<TResult>(`api/computers/groupremotecommand`, payload);
  }

  bulkRemoteCommand<TParams extends BulkRemoteCommandType, TResult>(payload: BulkRemoteCommand<TParams>): Observable<TResult> {
    return this.http.post<TResult>(`api/computers/bulkremotecommand`, payload);
  }

  // installAgent and uninstallAgents -> remote commands!
  uninstallAgents(payload: { hid: string; appIds: string }): Observable<any> {
    return this.http.delete<any>(`api/computers/${payload.hid}/applications?${payload.appIds}`);
  }

  getSyncStatus(hid: string): Observable<{ state: RepositorySyncState }> {
    return this.http.get<any>(`api/computers/${hid}/repository-sync/state`);
  }
}
