import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@shared/services/auth.service';
import { isConfirmPasswordValidator } from '@shared/utils/validators';
import { MbsValidators } from 'mbs-ui-kit/utils/mbs-validators';
import { noop } from 'rxjs';
import { ConfirmPassData } from './confirm-pass-model';
import { I18NextPipe } from "angular-i18next";

const ConfirmPasswordValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ConfirmPasswordComponent),
  multi: true
};

/**
 * Author: Roman Sh.
 * Block with two password controls with confirm validator and password rules async validator
 * */
@UntilDestroy()
@Component({
  selector: 'mbs-confirm-password',
  templateUrl: './confirm-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmPasswordValueAccessor]
})
export class ConfirmPasswordComponent implements ControlValueAccessor, OnChanges {
  /* Main inputs section */
  @Input() initialPassword = '';
  @Input() disabled = false;
  @Input() needValidateOnMount = false;
  @Input() disabledButtons = false;
  @Input() label = '';
  @Input() confirmPasswordLabel = this.i18nPipe.transform('wizards:confirm_password_label');
  @Input() placeholder = '';
  @Input() confirmPlaceholder = '';
  @Input() id = 'confirmPassComponentPassword';
  @Input() fakePassword = '';
  @Input() showRequiredMark = true

  /* Error in tooltip inputs section */
  @Input() showErrorsInTooltip = false;
  @Input() errorsTooltipContainer = 'body';
  @Input() errorsTooltipPlacement = 'auto';
  @Input() errorsTooltipClass = 'tooltip-xl';
  @Input() errorTooltipTitle = '';

  @Output() valueChange = new EventEmitter<boolean>();

  /* Value accessor data section */
  private myValue: ConfirmPassData = { password: '', confirmationPassword: '', valid: false };

  get value(): ConfirmPassData {
    return this.myValue;
  }

  set value(value: ConfirmPassData) {
    this.myValue = value;
    this.notifyValueChange();
  }

  public passwordInputType = 'password';
  public confirmPasswordInputType = 'password';
  public passwordRules: string[] = [];
  public passwordForm = new UntypedFormGroup(
    {
      password: new FormControl('', {
        validators: [Validators.required],
        asyncValidators: [MbsValidators.passwordStrengthValidator(this.authService, this.fakePassword)],
        updateOn: 'change'
      }),
      confirmationPassword: new FormControl('', [Validators.required])
    },
    isConfirmPasswordValidator.bind(null, { password: 'password', confirmPassword: 'confirmationPassword' })
  );

  constructor(public i18nPipe: I18NextPipe, private authService: AuthService) {
    this.authService
      .getPasswordRules()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.passwordRules = data;
      });

    let isPending = false;
    this.passwordForm.statusChanges.pipe(untilDestroyed(this)).subscribe({
      next: (status) => {
        const pending = status === 'PENDING';

        if (isPending && !pending) {
          isPending = pending;
          return void this.passwordForm.updateValueAndValidity();
        }

        isPending = pending;
      }
    });

    this.passwordForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: ({ password, confirmationPassword }) => {
        this.valueChange.emit(true);
        this.value = {
          password,
          confirmationPassword,
          valid:
            this.passwordForm.valid ||
            (!this.passwordForm.get('password').pending &&
              !this.passwordForm.get('password').errors &&
              !this.passwordForm.get('confirmationPassword').errors)
        };
      },
      error: noop
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.passwordForm.get('password').disable();
        this.passwordForm.get('confirmationPassword').disable();
      } else {
        this.passwordForm.get('password').enable();
        this.passwordForm.get('confirmationPassword').enable();
      }
    }

    if (changes.fakePassword?.currentValue) {
      this.passwordForm.get('password').setAsyncValidators(MbsValidators.passwordStrengthValidator(this.authService, this.fakePassword));
    }

    if (changes.needValidateOnMount?.currentValue) {
      this.passwordForm.markAllAsTouched();
      this.passwordForm.updateValueAndValidity();
    }
  }

  changeTypePassword(): void {
    this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
  }

  changeTypeConfirmationPassword(): void {
    this.confirmPasswordInputType = this.confirmPasswordInputType === 'password' ? 'text' : 'password';
  }

  passwordFocusHandler(event, isConfirmation = false): void {
    if (event?.target?.value && this.initialPassword && event.target.value === this.initialPassword) {
      this.passwordForm.get(isConfirmation ? 'confirmationPassword' : 'password').setValue('');
    }
  }

  passwordBlurHandler(event, isConfirmation = false): void {
    if (!event?.target?.value && this.initialPassword) {
      this.passwordForm.get(isConfirmation ? 'confirmationPassword' : 'password').setValue(this.initialPassword);
    }
  }

  /* Value accessor functions section */
  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: ConfirmPassData): void {
    this.myValue = value;
    this.updateForm(value);
  }

  updateForm(value: ConfirmPassData): void {
    if (!value) return;

    this.passwordForm.reset({ password: value.password, confirmationPassword: value.confirmationPassword });

    if (this.needValidateOnMount) {
      this.passwordForm.markAllAsTouched();
      this.passwordForm.updateValueAndValidity();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
