export enum SmartSearchTemplateName {
  Words = 'words',
  Company = 'Company',
  OS = 'OS',
  Status = 'Computer status',
  BackupStatus = 'Backup status',
  RestoreStatus = 'Restore status',
  IP = 'IP',
  ProductInstalled = 'Product installed',
  ProductNotInstalled = 'Product not installed',
  Tag = 'Tag',
  ComputerName = 'Computer Name'
}

export class BasedSmartSearchFilterValue<T> {
  value: T;
  searchText: string;
  translation?: string;
  icon?: string;
}

export class SmartSearchFilterValue extends BasedSmartSearchFilterValue<string> {}
