<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:where_backup_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <div class="mbs-wizard_form-content">

    <mbs-alert *ngIf="isRDMode && importedPlanChanged"
               [attr.data-test-element]="elementsSelector.name.configNotSupportedAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:where_backup_config_not_supported' | i18next}}
    </mbs-alert>

    <mbs-alert *ngIf="notExistRepositoryForCorrectStepState"
               [attr.data-test-element]="elementsSelector.name.noStoragesAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:where_backup_no_storages' | i18next}}
    </mbs-alert>

    <mbs-form-group *ngIf="needShowRadioButtons"
                    [attr.data-test-element]="elementsSelector.name.radioButtonsBox">
      <mbs-radio formControlName="IsHybridBackup"
                 name="backupType"
                 [label]="'wizards:local_or_cloud' | i18next"
                 [subtitle]="'wizards:local_or_cloud_subtitle' | i18next"
                 (change)="isHybridBackupChangeHandler($event)"
                 [subtitleOutside]="false"
                 [value]="false"
                 [id]="'local-or-cloud-radio'"></mbs-radio>
      <mbs-radio formControlName="IsHybridBackup"
                 name="backupType"
                 [label]="'wizards:hybrid_label' | i18next"
                 [subtitle]="'wizards:hybrid_subtitle' | i18next"
                 (change)="isHybridBackupChangeHandler($event)"
                 [subtitleOutside]="false"
                 [value]="true"
                 [id]="'hybrid-radio'"></mbs-radio>
    </mbs-form-group>

    <mbs-loader *ngIf="loadingTableDataForLocalOrCloudTable"
                [attr.data-test-element]="elementsSelector.name.loaderItem"></mbs-loader>

    <mbs-form-group *ngIf="!loadingTableDataForLocalOrCloudTable && !notExistRepositoryForCorrectStepState">
      <div class="mbs-form_label">
        {{'wizards:local_or_cloud_storage' | i18next}}
      </div>

      <mbs-list-select bindSelected="ID"
                       id="list-local-or-cloud-storages"
                       [attr.data-test-element]="elementsSelector.name.listSelectLocalOrCloud"
                       [tableContentClass]="'cursor-pointer'"
                       [invalid]="isNotValidFirstTable && stepForm.get('ConnectionID').touched"
                       [keepState]="true"
                       [loadSource]="false"
                       [maxHeight]="isRDMode || isNBF || !canBeHybrid || !existAllStorages ? '336px' : '193px'"
                       [multipleSelect]="false"
                       [showCheckboxes]="false"
                       [showHeaders]="false"
                       [showSearch]="false"
                       [selectedData]="selectedMixStorages"
                       [data]="MixStorages"
                       (click)="clickHandler()"
                       (changeSelected)="changeSelectedHandler($event)"
                       [headers]="headers">
        <ng-template mbsTableCell
                     let-dStorage>
          <div class="row align-items-center py-1 flex-nowrap">
            <div class="col-auto">
              <span class="mbs-storage mbs-storage-sm mbs-storage_{{ dStorage.StorageType | storageIcon }}"></span>
            </div>
            <div class="col text-overflow font-weight-semibold text-black">
              {{dStorage.DisplayName}}
            </div>
          </div>
        </ng-template>
      </mbs-list-select>
    </mbs-form-group>

    <mbs-form-group *ngIf="stepForm.get('IsHybridBackup').value && canBeHybrid">
      <div class="mbs-form_label">
        {{'wizards:local_storage' | i18next}}
      </div>

      <ng-container *ngIf="LocalStorages.length === 0; else localStorageTable">
        <mbs-alert class="mt-3"
                   [attr.data-test-element]="elementsSelector.name.notLocalStorageAlert"
                   [type]="mbsPopupType.warning"
                   [icon]="true">
          {{'wizards:not_local_storage' | i18next}} <br>
          {{notLocalStorageTexts[0]}}
          <mbs-button [isCtrl]="true"
                      (click)="switchToLocalOrCloudOption($event)">
            {{notLocalStorageTexts[1]}}
          </mbs-button>
          {{notLocalStorageTexts[2]}}
        </mbs-alert>
      </ng-container>

      <ng-template #localStorageTable>
        <mbs-list-select bindSelected="ID"
                         id="list-local-storages"
                         [attr.data-test-element]="elementsSelector.name.listSelectLocal"
                         [invalid]="isNotValidHybridTable && stepForm.get('HybridID').touched"
                         [keepState]="true"
                         [loadSource]="false"
                         [maxHeight]="'193px'"
                         [multipleSelect]="false"
                         [showCheckboxes]="false"
                         [showHeaders]="false"
                         [showSearch]="false"
                         [selectedData]="selectedLocalStorages"
                         [tableContentClass]="'cursor-pointer'"
                         [data]="LocalStorages"
                         (click)="clickHandler()"
                         (changeSelected)="changeSelectedHandler($event, false)"
                         [headers]="headers">
          <ng-template mbsTableCell
                       let-lStorage>
            <div class="row align-items-center py-1 flex-nowrap">
              <div class="col-auto">
                <span class="mbs-storage mbs-storage-sm mbs-storage_{{ lStorage.StorageType | storageIcon }}"></span>
              </div>
              <div class="col text-overflow font-weight-semibold text-black">
                {{lStorage.DisplayName}}
              </div>
            </div>
          </ng-template>
        </mbs-list-select>
      </ng-template>
    </mbs-form-group>

    <ng-container *ngIf="(isNBF || isSQLPlan) && ability.can('read', 'HelpMarketing')">
      <mbs-alert class="mb-0 mt-3"
                 [attr.data-test-element]="elementsSelector.name.needNewStorageAlert"
                 [type]="mbsPopupType.info"
                 [icon]="true">
        {{'wizards:where_backup_need_new_storage' | i18next}}
        <a href="/AP/EditAccount.aspx" target="_blank">
          {{'buttons:clickHere' | i18next}}
          <span class="fa fa-link"></span>
        </a>
      </mbs-alert>
    </ng-container>
  </div>
</section>
