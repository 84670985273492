import { Pipe, PipeTransform } from '@angular/core';
import { NAValue } from '../constants/license';
import { Billing } from '../models/license.model';

@Pipe({ name: 'billing' })
export class BillingPipe implements PipeTransform {
  transform(billing: Billing): string {
    if (billing.value.includes(NAValue)) {
      return NAValue;
    }
    return billing?.value ? `${billing.value} ${billing?.unitName || 'Days'}` : '—';
  }
}
