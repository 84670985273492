/**
 * Model for "Select Virtual Disks" step
 * @author Roman.Sh
 */

/**
 * All variant of virtual disks selection
 */
export enum VirtualDisksSelectedType {
  'All' = 'All',
  'Selected' = 'Selected'
}

/**
 * This is the data model of the virtual disk obtained from the GetVirtualMachineDiskList method
 */
export type VirtualDisk = {
  uuid: string;
  fileName: string;
  label: string;
  changeId: string;
  deviceKey: number;
  diskCapacity: number;
  diskCapacityStr?: string;
  virtualMachineName: string;
  virtualMachineId: string;
  id: string;
  rootDiskId: string;
  excludes?: string[];
  uiGuid?: string;
};

/**
 * @type SelectVirtualDisksStepValue - type for "Select Virtual Disks" step
 * type - variant of virtual disks selection;
 * disks - array of selected disks;
 */
export type SelectVirtualDisksStepValue = {
  type: VirtualDisksSelectedType;
  disks: VirtualDisk[];
  allDisks?: VirtualDisk[];
  valid: boolean;
};
