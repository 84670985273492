import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadConfigurations, MBS_APP_CONFIG } from '@mbs-ui/config.loader';
import { OnlineAccessModule } from './online-access.module';
import { environment } from '@mbs-ui/environments/environment';

const getConfigUrl = () => {
  const { pathname, origin } = location;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const split = pathname.split('/');
  const bh = urlParams.get('bh');
  let base = bh || '/';
  if (!bh && split.length > 2 && split[1] != '' && split[2] != '') {
    base = ['/', split[1], '/', split[2], '/'].join('');
  }

  return `${origin}${base}assets/config/config.json`;
}

if (environment.production) {
  enableProdMode();
}

function mountApp(config: unknown) {
  platformBrowserDynamic([
    {
      provide: MBS_APP_CONFIG,
      useValue: config
    }
  ])
    .bootstrapModule(OnlineAccessModule)
    .then((module) => {
      if (!environment.production) {
        const applicationRef = module.injector.get(ApplicationRef);
        const appComponent = applicationRef.components[0];
        enableDebugTools(appComponent);
      }
    })
    .catch((err) => console.error(err));
}

loadConfigurations(getConfigUrl())
  .then((res: string) => {
    try {
      mountApp(JSON.parse(res));
    } catch (err) {
      console.error(err);
    }
  })
  .catch((err) => console.error(err));
