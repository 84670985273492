<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>{{ 'wizards:dest_step_title' | i18next: { format: 'title' } }}</h4>
  </header>

  <div *ngIf="!isRestorePlan"
       [attr.data-test-element]="elementsSelector.name.notRestorePlanBlock">
    <div *ngIf="isVirtual"
         [attr.data-test-element]="elementsSelector.name.isVirtualBlock">
      <mbs-form-group>
        <mbs-input formControlName="destinationFolder"
                   [label]="'wizards:dest_folder_label' | i18next"
                   name="destinationFolder"
                   (input)="includeExcludeInputHandler($event)"
                   [placeholder]="isLinux ? '/home' : 'C:\\Folder Name'"
                   [id]="'destination-folder-1'"
                   [validationWhitespace]="true"
                   (buttonClick)="destinationFolderTreeOpen()"
                   [appendButtons]="[
                     {
                       text: 'Browse',
                       id: 'destination-folder-tree-1',
                       disabled: stepForm.get('destinationFolder').disabled,
                       type: 'secondary'
                     }
                   ]"></mbs-input>
      </mbs-form-group>

      <mbs-form-group>
        <mbs-input formControlName="virtualImageName"
                   [attr.data-test-element]="elementsSelector.name.virtualImageName"
                   [label]="'wizards:dest_virtual_image_name_label' | i18next"
                   name="virtualImageName"
                   [id]="'virtual-image-name'"
                   [validationWhitespace]="true"></mbs-input>
      </mbs-form-group>

      <div class="form-row align-items-center mb-2">
        <div class="col-4">
          <label class="mbs-form_label mb-0"
                 for="capacity-number">
            {{'wizards:dest_capacity' | i18next}}
          </label>
        </div>
        <div class="col-4">
          <mbs-number formControlName="capacity"
                      (focus)="capacityFocusHandler()"
                      (blur)="capacityBlurHandler()"
                      (change)="capacityInputHandler($event)"
                      [min]="numberIsFocused ? 0 : minimumSizeGB || 0.02"
                      [max]="numberIsFocused ? 65536.00 : maximumSizeGB || 65536.00"
                      [id]="'capacity-number'"></mbs-number>
        </div>
        <div class="col-auto">
          GB
          <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                placement="right"
                [container]="'body'"
                [ngbTooltip]="'wizards:dest_capacity_label' | i18next"></span>
        </div>
      </div>
      <div class="form-row">
        <div class="col-6 offset-4">
          {{'wizards:dest_minimum_label' | i18next}} <span class="font-weight-semibold">{{minimumSizeGB || 0.02}} GB</span><br>
          {{'wizards:dest_maximum_label' | i18next}} <span class="font-weight-semibold">{{maximumSizeGB || 65536.00}} GB</span>
        </div>
      </div>
    </div>

    <mbs-form-group *ngIf="!isVirtual"
                    [attr.data-test-element]="elementsSelector.name.isNotVirtualBlock">
      <mbs-radio formControlName="isPhysical"
                 [label]="'wizards:dest_physical_disk_label' | i18next"
                 name="isPhysicalRadio"
                 (change)="isPhysicalChangeHandler($event)"
                 [descriptionOutside]="false"
                 [id]="'is-physical-disk-radio-0'"
                 [value]="true">
        <ng-template radioDescription>
          <mbs-select formControlName="selectedDisk"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [clearable]="false"
                      [id]="'physical-disk-select'"
                      [items]="diskItems"></mbs-select>
          <mbs-alert class="mb-0 mt-3"
                     [type]="mbsPopupType.info"
                     [icon]="true">
            {{'wizards:dest_physical_disk_label_alert' | i18next}}
          </mbs-alert>
        </ng-template>
      </mbs-radio>
      <mbs-radio formControlName="isPhysical"
                 [label]="'wizards:dest_specific_part_label' | i18next"
                 name="isPhysicalRadio"
                 (change)="isPhysicalChangeHandler($event)"
                 [descriptionOutside]="false"
                 [id]="'is-physical-disk-radio-1'"
                 [value]="false">
        <ng-template radioDescription>
          <mbs-table-grid id="destination-step-table"
                          listType="table"
                          [maxHeight]="'286px'"
                          [headers]="headers"
                          [isNeedSelectRow]="false"
                          [showHeaders]="true"
                          [switcherView]="false"
                          [disableViewSwitch]="true"
                          [data]="myPartitions"
                          class="mb-3">
            <ng-template mbsTableCell
                         let-disk>
              {{disk.label}}
            </ng-template>
            <ng-template mbsTableCell
                         let-disk
                         let-index="rowIndex">
              <mbs-form-group class="mb-0"
                              formArrayName="selectedSpecificPartitions">
                <mbs-select [appendTo]="'.modal-content'"
                            [bindValue]="'value'"
                            [bindLabel]="'label'"
                            [clearable]="false"
                            [dropdownPosition]="'auto'"
                            [formControlName]="index.toString()"
                            [id]="'specific-partition-select' + disk.id"
                            [items]="partitionsForSelectsData[disk.id]"
                            [searchable]="false"
                            [textOverflow]="false"
                            (change)="selectedSpecificPartitionsChangeHandler()">
                  <ng-template ng-option-tmp
                               let-part="item">
                    <div class="form-row">
                      <div class="col overflow-hidden">
                        <span container="body"
                              placement="top-left"
                              [ngbTooltip]="part.isSelected ? tooltipData : part.label">{{part.label}}</span>
                        <ng-template #tooltipData>
                          <span class="d-block text-left">
                            {{part.label}}<br>
                            {{'wizards:already_selected_tooltip' | i18next}}
                          </span>
                        </ng-template>
                      </div>
                      <div *ngIf="part.isSelected"
                           class="col-auto">
                        <span class="ico ico-ok text-gray-500"></span>
                      </div>
                    </div>
                  </ng-template>
                </mbs-select>
              </mbs-form-group>
            </ng-template>
          </mbs-table-grid>
        </ng-template>
      </mbs-radio>
    </mbs-form-group>
  </div>

  <div *ngIf="isRestorePlan"
       [attr.data-test-element]="elementsSelector.name.restorePlanBlock">
    <mbs-form-group>
      <mbs-radio formControlName="toOriginalLocation"
                 name="toOriginalLocation"
                 [label]="'wizards:dest_original_location_label' | i18next"
                 (change)="toOriginalLocationChangeHandler($event)"
                 [value]="true"
                 [id]="'to-original-location-1'"></mbs-radio>
      <mbs-radio formControlName="toOriginalLocation"
                 name="toOriginalLocation"
                 [label]="'wizards:dest_specific_location_label' | i18next"
                 (change)="toOriginalLocationChangeHandler($event)"
                 [descriptionOutside]="false"
                 [value]="false"
                 [id]="'to-original-location-2'">
        <ng-template radioDescription>
          <mbs-form-group>
            <mbs-input formControlName="destinationFolder"
                       [label]="'wizards:dest_folder_label' | i18next"
                       name="destinationFolder"
                       (input)="includeExcludeInputHandler($event)"
                       [placeholder]="isLinux ? '/home' : 'C:\\Folder Name'"
                       [id]="'destination-folder-2'"
                       [validationWhitespace]="true"
                       (buttonClick)="destinationFolderTreeOpen()"
                       [appendButtons]="[
                         {
                           text: 'Browse',
                           id: 'destination-folder-tree-2',
                           disabled: stepForm.get('destinationFolder').disabled,
                           type: 'secondary'
                         }
                       ]"></mbs-input>
          </mbs-form-group>
          <mbs-checkbox *ngIf="!isLinux"
                        [attr.data-test-element]="elementsSelector.name.saveLocationAsDefault"
                        formControlName="saveLocationAsDefault"
                        [label]="'wizards:dest_save_default_label' | i18next"
                        [id]="'save-location-as-default'"></mbs-checkbox>
        </ng-template>
      </mbs-radio>
    </mbs-form-group>

    <mbs-form-group *ngIf="!isLinux || isNBF"
                    [attr.data-test-element]="elementsSelector.name.notLinuxOrNBFBlock">
      <mbs-checkbox formControlName="restoreDeletedFiles"
                    [label]="'wizards:dest_deleted_label' | i18next"
                    [id]="'restore-deleted-files'">
        <ng-container appendLabel>
          <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                container="body"
                [ngbTooltip]="'wizards:dest_deleted_label_tooltip' | i18next"></span>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-checkbox formControlName="overwriteExistingFiles"
                    [label]="'wizards:dest_overwrite_label' | i18next"
                    (change)="overwriteChangeHandler($event)"
                    [descriptionOutside]="false"
                    [id]="'overwrite-existing-files'">
        <ng-template checkboxDescription>
          <mbs-checkbox formControlName="restoreOnlyNewFiles"
                        [label]="'wizards:dest_only_new_label' | i18next"
                        [id]="'restore-only-new-files'">
            <ng-container appendLabel>
                  <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                        container="body"
                        [ngbTooltip]="'wizards:dest_only_new_label_tooltip' | i18next"></span>
            </ng-container>
          </mbs-checkbox>
        </ng-template>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="!isLinux"
                    [attr.data-test-element]="elementsSelector.name.notLinuxBlock">
      <mbs-checkbox formControlName="restoreNTFSPermissions"
                    [label]="'wizards:dest_ntfs_perm_label' | i18next"
                    [id]="'restore-ntfs-permissions'">
        <ng-container appendLabel>
              <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                    container="body"
                    [ngbTooltip]="'wizards:dest_ntfs_perm_label_tooltip' | i18next"></span>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>
  </div>

  <mbs-alert *ngIf="stepForm.get('selectedSpecificPartitions')?.errors?.isAllRequired"
             [attr.data-test-element]="elementsSelector.name.isAllRequired"
             class="mb-0 mt-3"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:dest_all_partitions_select_alert' | i18next}}
  </mbs-alert>

  <mbs-alert *ngIf="stepForm.get('selectedSpecificPartitions')?.errors?.isNotUniq"
             [attr.data-test-element]="elementsSelector.name.isNotUniq"
             class="mb-0 mt-3"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:dest_must_be_unique_alert' | i18next}}
  </mbs-alert>

  <mbs-alert *ngIf="stepForm?.get('selectedDisk')?.errors?.isBoot || stepForm?.get('selectedSpecificPartitions')?.errors?.isBoot"
             [attr.data-test-element]="elementsSelector.name.isBoot"
             [type]="mbsPopupType.warning"
             class="mb-0 mt-3"
             [icon]="true">
    {{'wizards:dest_must_be_not_boot_alert' | i18next}}
  </mbs-alert>

  <mbs-alert *ngIf="!isVirtual && (stepForm.get('isPhysical')?.value ? stepForm.get('selectedDisk')?.errors?.diskSpace : stepForm.get('selectedSpecificPartitions')?.errors?.diskSpace)"
             [attr.data-test-element]="elementsSelector.name.diskSpace"
             class="mb-0 mt-3"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:dest_space_disk_alert' | i18next}}
  </mbs-alert>

  <mbs-alert *ngIf="!isCurrentComputer && !this.isLinux"
             [attr.data-test-element]="elementsSelector.name.notCurrentNotLinux"
             class="mb-0 mt-3"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:dest_remote_to_local_alert' | i18next}}
  </mbs-alert>
</section>
