import { Component, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuickRestoreSchemaModalComponent } from '@components/quick-restore-schema-modal/quick-restore-schema-modal.component';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { TempTokenData } from '@models/auth-models';
import { ShortPlanInfo } from '@models/backup/plan-info-model';
import { PlanRunInfo } from '@models/backup/plan-run-info-model';
import { PlanRunSimpleStatus } from '@models/backup/plan-run-simple-status';
import {
  ComputerIcon,
  DeepSyncEnum,
  GenerationGFSType,
  GenerationGFSTypeIcons,
  GetBackupContentType,
  ItemTypeEnum,
  MyTreeElements,
  StorageEncryptionState,
  TreeBunchesIcons,
  TreeRestorePointsIconPath
} from '@models/backup/storages-type';
import { Build } from '@models/build';
import Computer, { AgentType, OsType } from '@models/Computer';
import { RemoteCommandType } from '@models/rm/remote-command-model';
import { StorageAccountCamelCase } from '@models/StorageAccount';
import { StorageType } from '@models/StorageType.enum';
import { PasswordModalComponent, PasswordModalParams } from '@modules/password-modal/password-modal.component';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { BunchItem, ComputerBackupStorageItem, IdsForCurrentFormats } from '@modules/wizards/models/backup-to-restore-models';
import { RestorePointItem } from '@modules/wizards/models/restore-point-models';
import { TreeIconPath } from '@modules/wizards/models/what-backup-tree-model';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import {
  BitLockerPasswordType,
  GetBackupContentParams,
  ParamsForDeepSync,
  WizardStepsService
} from '@modules/wizards/services/wizard-steps.service';
import { NgbModalRef, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { BuildService } from '@services/build-service/build.service';
import { unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { mediumDateWithTimeMoment } from '@utils/date';
import fileToBase64 from '@utils/fileToBase64';
import { isWindows } from '@utils/is-windows';
import { I18NextPipe } from 'angular-i18next';
import { get, partition } from 'lodash';
import {
  AccordionComponent,
  MbsPopupType,
  MbsSize,
  ModalService,
  ModalSettings,
  TabsetItemDirective,
  ToastService,
  TreeElement
} from 'mbs-ui-kit';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, defer, EMPTY, from, noop, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, first, map, skip, startWith, switchMap, take, tap } from 'rxjs/operators';
import { DownloadToSelectedComputerComponent } from '../download-to-selected-computer/download-to-selected-computer.component';
import { StorageConnection } from '@models/storge-connections';
import { AbilityService } from 'ability';

@UntilDestroy()
@Component({
  selector: 'mbs-backup-storage-legacy-tab',
  templateUrl: './backup-storage-legacy.component.html',
  styleUrls: ['./backup-storage-legacy.component.scss']
})
export class BackupStorageLegacyComponent implements OnInit, OnDestroy {
  @Input() fullscreen: boolean;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @ViewChild('bitLockerPasswordModal', { static: true, read: TemplateRef }) bitLockerPasswordModal: TemplateRef<any>;
  @ViewChildren(AccordionComponent) accordions: QueryList<AccordionComponent>;

  public readonly FIRST_BACKUP_AGENT_SUPPORTED_BITLOCKER_FILE = 783;
  private readonly quickRestoreModalSettings: ModalSettings = {
    footer: { okButton: { show: false }, cancelButton: { text: this.i18nPipe.transform('buttons:close', { format: 'title' }) } }
  };
  public readonly storageType = StorageType;
  public readonly itemTypeEnum = ItemTypeEnum;
  public readonly AlertType = AlertType;
  public readonly newRMSupportsMinimalWindowsVersion = 7223;
  public readonly newRMSupportsMinimalUnixVersion = 4100;

  public passwordGroup = new UntypedFormGroup({ password: new FormControl('', [Validators.required]) });
  public bitLockerFileName: string;
  public bitlockerPasswordForm = new UntypedFormGroup({
    passwordType: new FormControl('1'),
    password: new FormControl(''),
    recoveryPassword: new FormControl(''),
    keyFile: new FormControl('')
  });

  public computerData: Computer;
  public mbsPopupType = MbsPopupType;
  public MbsSize = MbsSize;

  public deepSyncEnum = DeepSyncEnum;
  public generationGFSType = GenerationGFSType;
  public generationGFSTypeIcons = GenerationGFSTypeIcons;
  public passwordRecoveryRoot: MyTreeElements;
  public storages$ = new BehaviorSubject<StorageConnection[]>([]);
  public storageHashTable: {
    [key: string]: { prefix?: string; generationId?: string; isLoading?: boolean; isIbb?: boolean; data?: MyTreeElements[] };
  } = {};
  public loadingQuickRestore: { [key: string]: boolean } = {};
  public encryptionPasswordType = false;
  public recoveryPasswordInputType = false;
  public currentEncryptedRestorePoint = '';
  public selectedItemForQuickRestore: MyTreeElements;
  public selectedStorageForQuickRestore: StorageConnection;
  public backupVersionUpdated: string;

  public downloadLinkData: Build = null;

  public allowedRecovery = false;

  public isOnline: boolean;
  public planDestination$ = new BehaviorSubject<ShortPlanInfo>(null);
  public currentPlanDestination: ShortPlanInfo = null;
  public isBackupAgentSupportsNewRM: boolean;

  private bunchesHashTable: { [key: string]: { [key: string]: string } } = {};
  private ownerId: string;
  private email: string;
  private joinForInput = '\\';
  private rootNodes: { [key: string]: TreeElement } = {};
  private otherComputerNodesData: { [key: string]: { nodes: TreeElement[]; loadMoreText: string; loadMoreAppendText: string } } = {};

  private bitLockerFileContent: string | ArrayBuffer;
  private lastPasswordModalPath: string;
  private passwordModalIsOpen = false;

  get bitlockerPasswordTypeControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('passwordType');
  }

  get bitlockerPasswordControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('password');
  }

  get bitlockerRecoveryPasswordControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('recoveryPassword');
  }

  get bitlockerKeyFileControl(): FormControl {
    return <FormControl>this.bitlockerPasswordForm?.get('keyFile');
  }

  get bitlockerFileNotAvailable(): string {
    return this.i18nPipe.transform('computers.module:modals.bitlockerFileNotAvailable');
  }

  constructor(
    public ability: AbilityService,
    private authService: AuthService,
    private buildService: BuildService,
    private facade: ComputerBackupFacade,
    private modalService: ModalService,
    private stepService: WizardStepsService,
    public tabItem: TabsetItemDirective,
    private toastService: ToastService,
    public mainService: RemoteManagementWizardsService,
    public i18nPipe: I18NextPipe,
    private router: Router
  ) {
    this.authService.currentUser.subscribe((user) => {
      if (user) {
        this.ownerId = (user.ProviderInfo && user.ProviderInfo?.Id) || '';
        this.email = user.Email;
        this.allowedRecovery = user.ProviderInfo?.PasswordRecoveryEnabled && user.IsProvider;
      }
    });
    this.authService.fetchCurrentUser();
  }

  get isNBF(): boolean {
    const bunchId = this.passwordRecoveryRoot.bunchId;
    return bunchId !== IdsForCurrentFormats.File && bunchId !== IdsForCurrentFormats.Ibb;
  }

  ngOnInit() {
    this.facade.computer$
      .pipe(
        untilDestroyed(this),
        filter((computer) => !!computer)
      )
      .subscribe((computer: Computer) => {
        this.isOnline = computer?.online && Computer.getAgent(computer, AgentType.Backup)?.online;
        this.backupVersionUpdated = computer?.apps.find((app) => app.applicationId === AgentType.Backup)?.version?.replaceAll('.', '');
      });

    this.buildService
      .getAll()
      .pipe(first((data) => !!data))
      .subscribe((data) => {
        if (data?.builds?.length) {
          this.downloadLinkData = data.builds.find((build) => build.buildType === 'QuickRestore');
        }
      });

    this.facade.destinationsLoading$
      .pipe(
        untilDestroyed(this),
        tap((value) => queueMicrotask(() => (this.tabItem.loading = value)))
      )
      .subscribe();

    this.facade.computer$
      .pipe(
        tap((computer) => this.setCurrentComputer(computer)),
        untilDestroyed(this)
      )
      .subscribe(() => this.handleReload());

    this.loadStorages().subscribe((storages) => this.storages$.next(storages));

    combineLatest([this.storages$, this.planDestination$.asObservable()])
      .pipe(
        filter(
          ([storages, planDestination]) =>
            storages && storages.length && !!planDestination && storages.some((storage) => storage.ID === planDestination.destinationId)
        ),
        map(([, planDestination]) => planDestination),
        tap((planDestination) => {
          const accordion = this.accordions.find((accordion) => accordion.activeIds === planDestination.destinationId);
          if (accordion) {
            this.currentPlanDestination = planDestination;
            accordion.expandAll();
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.storageHashTable = {};
  }

  handleReload(force = false): void {
    this.isBackupAgentSupportsNewRM = this.getIsBackupAgentSupportsNewRM();
    this.facade.loadDestinations({ force });
  }

  openBackupHistory(): void {
    this.router.navigate(['/AP/BackupHistory'], { queryParams: { filter: 'hid:' + this.computerData?.hid } });
  }

  handleRefresh(): void {
    this.handleReload(true);
  }

  loadStorages(): Observable<StorageConnection[]> {
    return this.facade.destinations$.pipe(
      map((storageAccounts: StorageAccountCamelCase[]) => {
        return storageAccounts.reduce((acc, account: StorageAccountCamelCase) => {
          const newBuckets = account.buckets.map((bucket) => {
            return {
              DisplayName: `${bucket.destinationName} (${bucket.bucketName})`,
              ID: bucket.id,
              Region: bucket.regionDisplayName || 'N/A',
              StorageType: account.storageType,
              Bucket: bucket.bucketName,
              StorageAccountName: account.name,
              StorageAccountType: account.storageType
            };
          });
          acc.push(...newBuckets);
          return acc;
        }, []);
      }),
      untilDestroyed(this)
    );
  }

  panelChangeHandler(event: NgbPanelChangeEvent, storage: StorageConnection): void {
    if (event && event.nextState && storage && storage.ID) {
      let planDestination: ShortPlanInfo = null;
      if (this.currentPlanDestination && this.currentPlanDestination.destinationId === storage.ID) {
        planDestination = this.currentPlanDestination;
        this.currentPlanDestination = null;
      }
      this.loadDestination(storage.ID, !!planDestination)
        .pipe(
          switchMap(() => (planDestination ? this.loadPlanDestination(planDestination) : EMPTY)),
          untilDestroyed(this)
        )
        .subscribe();
      this.facade.refreshCurrentComputer();
    }
  }

  getPathFromMyTreeElement(item: MyTreeElements): string {
    let currentPath = item.path;
    if (item.type) {
      if (item.type === GetBackupContentType.Volume) {
        const label = item.label as string;
        currentPath = /^([a-zA-Z]:)/.test(label) ? label.split(' ')[0] : `partition: ${label}`;
      } else if (item.type === GetBackupContentType.Disk) {
        currentPath = item.label as string;
      } else if (item.type === GetBackupContentType.Folder) {
        const newPath = item.path.split(this.joinForInput).slice(2).join(this.joinForInput);
        let current = item;
        while (current.type === GetBackupContentType.Folder) {
          current = current.parent;
        }
        const rootPath = this.getPathFromMyTreeElement(current);
        currentPath = rootPath[-1] === this.joinForInput ? rootPath + newPath : rootPath + this.joinForInput + newPath;
      }
    }
    return currentPath;
  }

  downloadToSelectedComputer(item: MyTreeElements, invalidPass = false, selectedPath = ''): void {
    this.modalService
      .openCustom(DownloadToSelectedComputerComponent, {
        data: {
          invalidPass,
          selectedPath,
          computerData: this.computerData,
          path: item.path,
          restorePoint: item.restorePoint,
          currentPath: this.getPathFromMyTreeElement(item),
          isEncrypted: item.isEncrypted,
          encryptionPasswordHint: item.encryptionPasswordHint,
          bunchId: item.bunchId,
          backupVersionUpdated: this.backupVersionUpdated
        },
        collapsing: true
      })
      .then((result: any) => {
        if (result) {
          const params = {
            agentType: 'backup',
            commandType: 'StartQuickRestore',
            params: {
              RestorePointObjectPath: item.restorePointObjectPath,
              UseCustomPrefix: false,
              ItemsToRestore: [
                item.restorePath
                  ? item.restorePath
                  : result.restorePath
                      .split(this.joinForInput)
                      .filter(
                        (str) =>
                          str !== this.computerData.name &&
                          str !== this.storageHashTable[item.storageId].prefix &&
                          str !== item.bunchId &&
                          str !== item.restorePoint
                      )
                      .join(this.joinForInput)
              ],
              Password: result.password || '',
              StartAfterCreate: true,
              Destination: result.selectedPath
            }
          };
          this.runQuickRestoreOnSelectedComputer(params, item, result.selectedPath);
        }
      })
      .catch(noop);
  }

  runQuickRestoreOnSelectedComputer(params, item: MyTreeElements, selectedPath: string): void {
    this.loadingQuickRestore[item.id] = true;

    const status$: BehaviorSubject<PlanRunInfo | null> = new BehaviorSubject(null);
    let planId = '';
    this.stepService
      .getRemoteCommandData(params, this.computerData.hid)
      .pipe(
        filter((result) => result?.data?.planId),
        tap(() => this.toastService.toast({ header: 'Started', content: 'Restore started...', type: MbsPopupType.info, delay: 5000 })),
        switchMap((result) => {
          planId = result.data.planId;
          return status$.pipe(debounceTime(1000));
        }),
        switchMap((result) => {
          if (!result?.status || result.status === PlanRunSimpleStatus.Running) {
            const newParams = { agentType: AgentType.Backup, commandType: RemoteCommandType.GetPlanInfo, params: { planId } };
            this.stepService
              .getRemoteCommandData(newParams, this.computerData.hid)
              .pipe(first())
              .subscribe((data) => {
                if (get(data, 'data.planInfo.lastRunInfo')) {
                  status$.next(data.data.planInfo.lastRunInfo);
                }
              });
          }
          return of(result);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (result) => {
          const toast = { header: PlanRunSimpleStatus[0], content: 'Unknown status...', type: MbsPopupType.info, delay: 5000 };
          if (result?.status) {
            toast.header = PlanRunSimpleStatus[result.status];
            if (result.status !== PlanRunSimpleStatus.Running) {
              toast.content = 'Files restored';
              toast.type = MbsPopupType.success;
              if (result.status === PlanRunSimpleStatus.Failed) {
                toast.type = MbsPopupType.danger;
                toast.content = result.errorDetails[0]?.title;
              }

              this.toastService.toast(toast);
              status$.unsubscribe();
            } else {
              toast.content = 'In the process of restoration...';
              this.toastService.toast(toast);
            }
          }

          this.loadingQuickRestore[item.id] = false;
        },
        error: (e) => {
          // TODO MBS-19405 The agent should add errorCode 2000, but while clients in most cases have installed agents of version BEFORE 7.9.0.275, we do not remove the error text check.
          if (e?.error?.errorCode === 2000 || e?.error?.detail === 'Encryption password missing or incorrect') {
            this.downloadToSelectedComputer(item, true, selectedPath);
          }

          status$.unsubscribe();
          this.loadingQuickRestore[item.id] = false;
        }
      });
  }

  downloadQuickRestore(): void {
    if (this.downloadLinkData && this.downloadLinkData.public) {
      window.open(this.downloadLinkData.public.downloadLink, '_blank');
    }
  }

  downloadStorageClickHandler(storage: StorageConnection): void {
    this.selectedStorageForQuickRestore = storage;

    if (!isWindows() || this.storageHashTable[storage.ID]?.prefix) {
      this.openModalWithQuickRestore();
    } else {
      const params = {
        agentType: 'backup',
        commandType: 'GetRestoreSourcePrefix',
        params: { connectionId: storage.ID }
      };
      this.stepService
        .getRemoteCommandData(params, this.computerData.hid)
        .pipe(
          switchMap((result) => {
            if (result && result.data && result.data.length) {
              if (!this.storageHashTable[storage.ID]) this.storageHashTable[storage.ID] = {};
              const current = result.data.find((d) => d.isCurrent);
              this.storageHashTable[storage.ID].prefix = current ? current.name : this.computerData.name;
            }
            return of(true);
          }),
          untilDestroyed(this)
        )
        .subscribe(() => this.openModalWithQuickRestore());
    }
  }

  openModalWithQuickRestore(usePath = false): void {
    const modal = this.modalService.openRef(QuickRestoreSchemaModalComponent, this.quickRestoreModalSettings);
    modal.componentInstance.needPreparingTextShow = true;
    modal.componentInstance.downloadLink = this.downloadLinkData?.public?.downloadLink;

    modal.result.then(noop).catch(() => {
      this.selectedStorageForQuickRestore = null;
      if (this.selectedItemForQuickRestore) this.selectedItemForQuickRestore = null;
    });
    if (isWindows() && (!this.authService.isMBSMode || this.ability.can('read', 'RemoteManagement')))
      queueMicrotask(() => this.createQuickRestoreSchema(usePath, modal));
  }

  downloadClickHandler(item: MyTreeElements): void {
    this.selectedItemForQuickRestore = item;
    const modal = this.modalService.openRef(QuickRestoreSchemaModalComponent, this.quickRestoreModalSettings);
    modal.componentInstance.needPreparingTextShow = true;
    modal.componentInstance.downloadLink = this.downloadLinkData.public.downloadLink;
    modal.result.finally(noop);
    if (isWindows() && (!this.authService.isMBSMode || this.ability.can('read', 'RemoteManagement')))
      queueMicrotask(() => this.createQuickRestoreSchema(false, modal));
  }

  getRestorePointObjectPath(usePath = false): string | undefined {
    if (usePath || (!this.isOnline && this.selectedItemForQuickRestore?.restorePoint)) {
      return this.selectedItemForQuickRestore.restorePointObjectPath || this.selectedItemForQuickRestore?.path;
    }

    if (this.selectedStorageForQuickRestore) {
      return `${this.selectedStorageForQuickRestore.ID}${isWindows() ? '\\' : '/'}${
        this.storageHashTable[
          this.selectedStorageForQuickRestore ? this.selectedStorageForQuickRestore.ID : this.selectedItemForQuickRestore.storageId
        ].prefix || this.computerData.name
      }`;
    }

    return this.selectedItemForQuickRestore.restorePointObjectPath || (this.selectedItemForQuickRestore?.parent as any)?.path;
  }

  createQuickRestoreSchema(usePath = false, modal: NgbModalRef): void {
    modal.componentInstance.preparing = true;
    this.authService
      .getTempToken(this.computerData.userAccount.id, this.computerData.hid, 'OneTimeQuickRestore')
      .pipe(
        catchError(() => of(null)),
        untilDestroyed(this)
      )
      .pipe(
        map((tokenData: TempTokenData) => {
          const jsonIbj: any = {
            UserToken: tokenData.token,
            RestorePointObjectPath: this.getRestorePointObjectPath(usePath),
            ownerId: this.ownerId
          };
          if (
            !usePath &&
            !this.selectedStorageForQuickRestore &&
            (this.isOnline || this.selectedItemForQuickRestore.restorePath)
          ) {
            jsonIbj.ItemsToRestore = [
              this.selectedItemForQuickRestore.restorePath
                ? this.selectedItemForQuickRestore.restorePath
                : this.selectedItemForQuickRestore.path
                    .split(this.joinForInput)
                    .filter(
                      (str) =>
                        str !== this.computerData.name &&
                        str !== this.storageHashTable[this.selectedItemForQuickRestore.storageId].prefix &&
                        str !== this.selectedItemForQuickRestore.bunchId &&
                        str !== this.selectedItemForQuickRestore.restorePoint
                    )
                    .join(this.joinForInput)
            ];
          }
          return jsonIbj;
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (jsonIbj: { [key: string]: string }) => {
          modal.componentInstance.preparing = false;
          modal.componentInstance.createSchemaParams = jsonIbj;
        },
        error: () => (modal.componentInstance.preparing = false)
      });
  }

  getSubtree(root: MyTreeElements): Observable<MyTreeElements[]> {
    const subtree$ = new BehaviorSubject<MyTreeElements[]>(null);
    this.storageHashTable[root.storageId].isIbb = this.bunchesHashTable[root.storageId][root.bunchId] === 'DiskImage';
    this.newSubtreeByParams(this.getParams(root, false, true), subtree$, root);
    return subtree$ as Observable<MyTreeElements[]>;
  }

  getRoot(storageId: string) {
    if (!this.rootNodes[storageId]) {
      this.rootNodes[storageId] = { id: `rootNode-${storageId}`, label: '', storageId } as TreeElement;
    }
    return this.rootNodes[storageId];
  }

  private preparePasswordModalSettings(isBitlockerModal = false): ModalSettings {
    const title = this.i18nPipe.transform('computers.module:modals.bitlockerDrive', { format: 'title' });
    const confirm = this.i18nPipe.transform('buttons:ok', { format: 'title' });
    const group = this.bitlockerPasswordForm;
    return {
      header: { title },
      footer: {
        okButton: {
          text: confirm,
          type: 'primary',
          disabled$: group.valueChanges.pipe(
            startWith(true),
            switchMap(() => defer(() => of(!group.valid)))
          )
        },
        cancelButton: { text: this.i18nPipe.transform('buttons:cancel', { format: 'title' }) }
      }
    } as ModalSettings;
  }

  private showPasswordModal(root: MyTreeElements, subtree$: BehaviorSubject<MyTreeElements[]>, params = null): void {
    if (!root || this.passwordModalIsOpen) {
      return;
    }

    this.passwordRecoveryRoot = root;
    this.currentEncryptedRestorePoint = root.label as string;

    this.modalService
      .openCustom(PasswordModalComponent, {
        data: this.getPasswordModalParams()
      })
      .then((result: { password: FormControl }) => {
        this.processPasswordModalResponse(result, root, subtree$, params);
      })
      .catch(() => {
        this.processPasswordModalBadResponse(root, subtree$, params);
      })
      .finally(() => {
        this.passwordModalIsOpen = false;
      });

    this.passwordModalIsOpen = true;
  }

  private getPasswordModalParams(): PasswordModalParams {
    return {
      password: this.passwordGroup.get('password').value || '',
      hid: this.computerData.hid,
      restorePoint: this.passwordRecoveryRoot as unknown as RestorePointItem,
      currentEncryptedRestorePoint: this.currentEncryptedRestorePoint,
      backupVersionUpdated: this.backupVersionUpdated,
      passwordRecoveryEnabled: this.allowedRecovery && this.isNBF,
      passwordInvalid: false,
      showHint: false,
      collapsing: true
    };
  }

  private processPasswordModalResponse(
    result: { password: FormControl },
    root: MyTreeElements,
    subtree$: BehaviorSubject<MyTreeElements[]>,
    params = null
  ): void {
    const passControl = result?.password;

    if (!passControl.valid) {
      return void this.processPasswordModalBadResponse(root, subtree$, params);
    }

    this.passwordGroup.get('password').setValue(passControl.value);
    this.lastPasswordModalPath = root.path;
    if (!params) {
      const newParams = this.getParams(root);
      newParams.params.Password = this.passwordGroup.get('password').value;
      this.newSubtreeByParams(newParams, subtree$, root);
    } else {
      params.params.Password = this.passwordGroup.get('password').value;
      this.runDeepSync(params, subtree$, root);
    }
    this.passwordGroup.get('password').reset('');
    this.currentEncryptedRestorePoint = '';
  }

  private processPasswordModalBadResponse(root: MyTreeElements, subtree$: BehaviorSubject<MyTreeElements[]>, params = null): void {
    if (!params) {
      subtree$.error(1);
    }

    this.resetRoot(root);
  }

  showBitLockerPasswordModal(root: MyTreeElements, subtree$: BehaviorSubject<MyTreeElements[]>): void {
    if (root) {
      this.bitlockerPasswordForm.reset();
      this.bitLockerFileContent = '';
      this.bitLockerFileName = '';
      this.bitlockerPasswordTypeControl.setValue('1');
      this.modalService
        .open(this.preparePasswordModalSettings(true), this.bitLockerPasswordModal)
        .then((confirm) => {
          const passwordControl = this.getBitlockerPasswordControls.find(
            (controlData) => controlData.value === this.bitlockerPasswordTypeControl.value
          )?.control;
          const passwordType = this.bitlockerPasswordTypeControl?.value;
          if (confirm && passwordControl?.valid && passwordType) {
            this.lastPasswordModalPath = root.path;
            const newParams = this.getParams(root);
            const passwordTypeInt = parseInt(passwordType, 10);
            newParams.params.BitLockerPasswordValue =
              passwordTypeInt === BitLockerPasswordType.KeyFile ? this.bitLockerFileContent : passwordControl.value;
            newParams.params.BitlockerPasswordType = passwordTypeInt;
            this.newSubtreeByParams(newParams, subtree$, root);
          } else {
            subtree$.error(1);
            this.resetRoot(root);
          }
        })
        .catch(() => {
          subtree$.error(1);
          this.resetRoot(root);
        });
    }
  }

  getSubTreeByParams(params: any, root: MyTreeElements): Observable<MyTreeElements[]> {
    const storageId = root.storageId;
    return this.stepService.getRemoteCommandData(params, this.computerData.hid).pipe(
      map((result) => {
        if (result?.data?.items) {
          const newTreeItems = this.getNewTreeItemsFromBackupContent(
            result.data.items,
            storageId,
            root,
            result.data.isCanBeQuickRestored,
            result.data.restorePointObjectPath
          );

          root.totalChildren = StepsHelpers.getTotalChildren(result?.data, newTreeItems.length);

          return newTreeItems;
        } else return [];
      }),
      untilDestroyed(this)
    );
  }

  openQuickRestoreModal(): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform(`computers.module:backupSidePanel.restoreAppRequired`, { format: 'title' }) },
      footer: {
        okButton: {
          text: this.i18nPipe.transform(`computers.module:backupSidePanel.openQuickRestoreModalButton`, { format: 'title' })
        },
        cancelButton: { text: this.i18nPipe.transform('buttons:cancel', { format: 'title' }) }
      }
    };
    this.modalService
      .open(modalSettings, this.i18nPipe.transform(`computers.module:backupSidePanel.restoreAppRequiredDescription`))
      .then((result) => {
        if (result) this.openModalWithQuickRestore(true);
      })
      .catch(() => {
        this.selectedStorageForQuickRestore = null;
        if (this.selectedItemForQuickRestore) this.selectedItemForQuickRestore = null;
      });
  }

  newSubtreeByParams(params: any, subtree$: BehaviorSubject<MyTreeElements[]>, root: MyTreeElements = null): void {
    const showExcludedErrorMessage = (root: MyTreeElements = null, error) => {
      if (this.lastPasswordModalPath === root?.path) {
        this.toastService.error(error.title);
      }
    };
    this.getSubTreeByParams(params, root).subscribe({
      next: (result) => subtree$.next(result),
      error: (e) => {
        if (e?.error?.errorCode === 4999) {
          this.selectedItemForQuickRestore = root;
          this.openQuickRestoreModal();
          subtree$.next([]);
          return;
        }
        if (e && e.error && e.error.errorCode) {
          if ([2000, 2046].includes(+e.error.errorCode)) showExcludedErrorMessage(root, e.error);
          if (+e.error.errorCode === 2000) this.showPasswordModal(root, subtree$);
          else if (+e.error.errorCode === 2046) this.showBitLockerPasswordModal(root, subtree$);
          else if (+e.error.errorCode === 2526) {
            if (this.computerData.os === OsType.windows) this.getDeepSyncStatus(params, subtree$, root);
            else this.runDeepSync(params, subtree$, root);
          } else subtree$.next([]);
        } else {
          subtree$.next([]);
        }
      }
    });
  }

  runDeepSync(params, subtree$: BehaviorSubject<MyTreeElements[]>, root: MyTreeElements = null): void {
    const deepSyncParams = this.getDeepSyncParams(root, params.password || params.params.Password || params.params.password, true);
    this.stepService
      .getRemoteCommandData(deepSyncParams, this.computerData.hid)
      .pipe(first())
      .subscribe({
        next: (result) => {
          if (result && result.data) {
            const syncDataFromRes = { deepSync: DeepSyncEnum[result.data as string], progress: 0 };
            this.getDeepSyncStatus(params, subtree$, root, syncDataFromRes);
          } else this.resetRoot(root);
        },
        error: () => {
          subtree$.error(1);
          this.resetRoot(root);
        }
      });
  }

  getDeepSyncStatus(
    params,
    subtree$: BehaviorSubject<MyTreeElements[]>,
    root: MyTreeElements = null,
    syncDate = { progress: 0, deepSync: DeepSyncEnum.InProgress }
  ): void {
    root.deepSync = syncDate.deepSync;
    root.deepSyncProgress = syncDate.progress;
    if (syncDate.deepSync === DeepSyncEnum.InvalidPassword || syncDate.deepSync === DeepSyncEnum.NeedPassword) {
      this.showPasswordModal(root, subtree$, params);
    } else if (syncDate.deepSync !== DeepSyncEnum.InProgressColdStorage && syncDate.deepSync !== DeepSyncEnum.InProgress) {
      this.newSubtreeByParams(params, subtree$, root);
    } else {
      const getDeepSyncStatusParams = this.getDeepSyncParams(root, params?.params?.Password || params?.params?.password || '');
      this.stepService
        .getRemoteCommandData(getDeepSyncStatusParams, this.computerData.hid)
        .pipe(first(), debounceTime(800))
        .subscribe({
          next: (res) => {
            if (res && res.data) {
              const syncDataFromRes = { deepSync: DeepSyncEnum[res.data.state as string], progress: res.data.progress };
              if (syncDataFromRes.deepSync === DeepSyncEnum.Error) {
                this.toastService.error(res.data.error || res.data.progressMessage || unidentifiedErrorText);
                subtree$.error(1);
                this.resetRoot(root);
                return;
              }
              this.getDeepSyncStatus(params, subtree$, root, syncDataFromRes);
            } else this.resetRoot(root);
          },
          error: () => {
            subtree$.error(1);
            this.resetRoot(root);
          }
        });
    }
  }

  getNewTreeItemsFromBackupContent(
    data: any[],
    storageId: string,
    root: MyTreeElements,
    isCanBeQuickRestored = false,
    restorePointObjectPath = ''
  ): MyTreeElements[] {
    if (root.prefix) this.storageHashTable[storageId].prefix = root.prefix;

    if (data && data.length && this.storageHashTable[storageId]) {
      return data.map((item: any, idx: number) => {
        const itemData = this.getDataFromItemType(item);
        const treeElement = {
          label: itemData.label,
          id: item.path + idx,
          prefix: root.prefix,
          expanded: false,
          shown: true,
          isCanBeQuickRestored: isCanBeQuickRestored,
          restorePointObjectPath: restorePointObjectPath,
          path: item.path,
          itemType: item.itemType,
          restorePath: item.restorePath,
          generationId: root.generationId || item.generationId || '',
          restorePoint: root.restorePoint || itemData.restorePoint || '',
          isEncrypted: this.getStorageEncryptionStateByParent(item.isEncrypted, root.isEncrypted),
          encryptionPasswordHint: item.encryptionPasswordHint || root.encryptionPasswordHint || '',
          bunchId: root.bunchId || item.bunchId,
          storageId
        } as TreeElement;

        const additionalData: Partial<MyTreeElements> = {
          iconCustom: item.itemType === ItemTypeEnum.Bunch ? this.getBunchItemIcon(item) : itemData.src,
          leftTopIcon: itemData.leftTopIcon,
          rightBottomIcon: itemData.rightBottomIcon
        };

        if (item.gfsType) {
          (additionalData as any).GFSSettings = { type: item.gfsType, purgeSummary: '' };
        }

        return { ...treeElement, ...additionalData };
      });
    }
    return [];
  }

  getDataFromItemType(item: any): any {
    const data: { src: string; label: string; rightBottomIcon: string; leftTopIcon: string; restorePoint?: string } = {
      src: TreeRestorePointsIconPath.File,
      label:
        item.displayName && item.displayName.length === 2 && item.displayName[item.displayName.length - 1] === ':'
          ? item.displayName + this.joinForInput
          : item.displayName || 'Unnamed',
      leftTopIcon: '',
      rightBottomIcon: ''
    };
    const displayNameArr = item.type === GetBackupContentType.Volume ? item.displayName.split(' ') : [];
    switch (item.itemType) {
      case ItemTypeEnum.Disk:
        data.src = TreeRestorePointsIconPath.ParentDisk;
        break;
      case ItemTypeEnum.Volume:
        data.label = displayNameArr.length > 5 ? displayNameArr.slice(displayNameArr.length - 5).join(' ') : item.displayName;
        data.src = TreeRestorePointsIconPath.Disk;
        break;
      case ItemTypeEnum.Host:
        data.src = TreeRestorePointsIconPath.ServerGroup;
        break;
      case ItemTypeEnum.Machine:
        data.src = TreeRestorePointsIconPath.Server;
        break;
      case ItemTypeEnum.RestorePoint:
        data.label = moment(item.date).format(mediumDateWithTimeMoment);
        data.restorePoint = StepsHelpers.getNormalDate(item.date);
        data.src = item.isFull ? TreeRestorePointsIconPath.Full : TreeRestorePointsIconPath.Diff;
        data.leftTopIcon = this.getLeftTopIcon(item);
        data.rightBottomIcon = this.getRightBottomIcon(item);
        break;
      case ItemTypeEnum.Generation:
        data.src = TreeRestorePointsIconPath.Chain;
        data.leftTopIcon = this.getLeftTopIcon(item);
        data.rightBottomIcon = this.getRightBottomIcon(item);
        data.label = `${moment(item.firstRestoreDate).format(mediumDateWithTimeMoment)} - ${moment(item.lastRestoreDate).format(
          mediumDateWithTimeMoment
        )}`;
        break;
      case ItemTypeEnum.Folder:
      case ItemTypeEnum.Bunch:
        data.src = TreeIconPath.Folder;
        break;
    }
    return data;
  }

  getRightBottomIcon(item: any): string {
    const warning = item.needDeepSync || item.backupResult === 'Warning' || item.restoreVerificationResult === 'Warning';
    const error = item.backupResult === 'Fail';
    const key = item.backupResult ?? 'None';

    if (error) {
      return TreeRestorePointsIconPath.Error;
    }

    if (warning) {
      return TreeRestorePointsIconPath.WarnTriangle;
    }

    return TreeRestorePointsIconPath[key] || '';
  }

  getLeftTopIcon(item: any): string {
    return item.needDeepSync ? TreeRestorePointsIconPath.Quest : item.isEncrypted ? TreeRestorePointsIconPath.Encrypt : '';
  }

  resetRoot(root: MyTreeElements): void {
    this.currentEncryptedRestorePoint = '';
    setTimeout(() => {
      root.deepSync = DeepSyncEnum.Error;
      root.deepSyncProgress = 0;
    }, 0);
    this.passwordRecoveryRoot = null;
  }

  getOtherComputers(storageId: string) {
    return this.otherComputerNodesData[storageId] || { nodes: [] };
  }

  getMore(root: MyTreeElements): Observable<MyTreeElements[]> {
    return of(this.otherComputerNodesData[root.storageId].nodes);
  }

  loadMore(storageId: string): void {
    this.storageHashTable[storageId].data = this.otherComputerNodesData[storageId].nodes;
    this.otherComputerNodesData[storageId].nodes = [];
  }

  private setCurrentComputer(computer: Computer): void {
    this.joinForInput = computer?.os.toLowerCase() !== 'windows' ? '/' : '\\';
    this.computerData = computer;
  }

  private getParams(item: MyTreeElements, isConnection = false, needOffset = false): GetBackupContentParams {
    const sortField = isConnection ? 'Type' : 'DisplayName';
    return {
      agentType: 'backup',
      commandType: 'GetBackupContent',
      params: {
        SessionId: null,
        ConnectionId: null,
        Password: '',
        ContentFilter: 'Actual',
        path: isConnection ? item.storageId : item.path,
        offset: needOffset ? item?.children?.length || 0 : null,
        limit: 300,
        order: item.bunchId && (!item.generationId || !item.restorePoint) ? `${sortField}Desc` : `${sortField}Asc`
      }
    };
  }

  private getDeepSyncParams(root: MyTreeElements, pass = '', isRun = false): ParamsForDeepSync {
    const newParams: ParamsForDeepSync = {
      agentType: 'backup',
      commandType: isRun ? 'RunDeepSync' : 'GetDeepSyncStatus',
      params: {
        connectionId: root.storageId,
        restoreSourcePrefix: this.storageHashTable[root.storageId].prefix,
        bunchId: root.bunchId,
        restorePointDateUtc: root.restorePoint
      }
    };
    if (pass) newParams.params.Password = pass;
    return newParams;
  }

  private loadDestination(storageId: string, skipStopLoading = false): Observable<any> {
    if (!this.computerData) return EMPTY;
    this.storageHashTable[storageId] = { isLoading: true };
    this.bunchesHashTable[storageId] = {};
    const dataForParams = { storageId: storageId, label: '', id: '' };
    return this.facade.computer$.pipe(
      skip(1),
      take(1),
      switchMap((computer) => {
        if (!computer) return EMPTY;
        return this.stepService.getRemoteCommandData(this.getParams(dataForParams, true), this.computerData.hid).pipe(
          tap((results) => {
            this.storageHashTable[storageId].isLoading = skipStopLoading;
            Computer.isAgentOnline(computer, AgentType.Backup) && results?.data?.items?.[0]?.itemType === ItemTypeEnum.Bunch
              ? this.prepareBunches(results, storageId)
              : this.prepareComputers(results, storageId, this.computerData.name);
          }),
          catchError(() => {
            this.storageHashTable[storageId].isLoading = skipStopLoading;
            return of();
          })
        );
      })
    );
  }

  private loadPlanDestination(planDestination: ShortPlanInfo): Observable<MyTreeElements[]> {
    if (this.storageHashTable[planDestination.destinationId]) {
      const planElement = this.storageHashTable[planDestination.destinationId].data.find(
        (treeElement) => treeElement.id === planDestination.id
      );
      if (planElement) {
        return this.getSubTreeByParams(this.getParams(planElement), planElement).pipe(
          tap((results) => {
            if (results && results.length) {
              planElement.children = results;
              planElement.gotChildren = true;
              planElement.expanded = true;
              results.forEach((plan) => plan.parent === planElement);
              this.storageHashTable[planDestination.destinationId].isLoading = false;
            }
          }),
          untilDestroyed(this)
        );
      }
    }
    this.storageHashTable[planDestination.destinationId].isLoading = false;
    return of([]);
  }

  private getBunchItemIcon(item): string {
    return item.type === 'DiskImage'
      ? TreeBunchesIcons.Ibb
      : item.type === 'VMware'
      ? item.bunchName === IdsForCurrentFormats.VMWare
        ? TreeBunchesIcons.VmWareCurrent
        : TreeBunchesIcons.VmWare
      : item.type === 'HyperV'
      ? TreeBunchesIcons.HyperV
      : TreeIconPath.Folder;
  }

  private prepareBunches(bunches, storageId: string) {
    if (bunches?.data?.items) {
      if (bunches.data.items.length) {
        const myPrefixPath: string[] = bunches.data.items[0]?.path?.split(this.joinForInput) || [];
        const idx = myPrefixPath.findIndex((str) => str === storageId) + 1;

        if (idx && myPrefixPath[idx]) this.storageHashTable[storageId].prefix = myPrefixPath[idx];
      }

      this.storageHashTable[storageId].data = bunches.data.items.map((item: BunchItem) => {
        const id = item.bunchId || item.bunchName || item.displayName;
        this.bunchesHashTable[storageId][id] = item.type;
        return {
          label: item.bunchName || item.displayName,
          id,
          path: item.path,
          expanded: false,
          rightBottomIcon: item.bunchName === IdsForCurrentFormats.VMWare ? TreeBunchesIcons.VmWare : '',
          iconCustom: this.getBunchItemIcon(item),
          shown: true,
          itemType: item.itemType,
          prefix: this.storageHashTable[storageId].prefix,
          storageId: storageId,
          bunchId: id
        } as MyTreeElements;
      });
    }
  }

  private prepareComputers(computers, storageId: string, currentComputerName: string) {
    if (computers?.data?.items) {
      const allComputers: TreeElement[] = computers.data.items.map((item: ComputerBackupStorageItem) => {
        let newId = item.id || item.name;
        const splitedPath = item.path.split(this.joinForInput);
        if (!newId && splitedPath.length > 2) {
          newId = splitedPath[1];
        }
        return {
          prefix: item.name,
          label: item.name || item.displayName,
          id: newId,
          path: item.path,
          expanded: false,
          rightBottomIcon: '',
          iconCustom: ComputerIcon,
          shown: true,
          itemType: item.itemType,
          storageId: storageId
        } as TreeElement;
      });

      const splittedComputers: any = partition(
        allComputers,
        (computer) => computer.label.toString().toLowerCase() === currentComputerName.toLowerCase()
      );
      this.storageHashTable[storageId].data = splittedComputers[0];
      const otherNodes = splittedComputers[1];

      const getKeyText = (key: string) => {
        return this.i18nPipe.transform(`computers.module:backupSidePanel.${key}`, { param: this.computerData.name });
      };

      const len = this.storageHashTable[storageId].data.length;
      const otherLen = otherNodes.length;

      const root = this.getRoot(storageId);
      root.expanded = true;
      root.totalChildren = len + otherLen;
      root.children = this.storageHashTable[storageId].data;

      this.otherComputerNodesData[storageId] = {
        nodes: otherNodes,
        loadMoreText: otherLen ? getKeyText('dataForNotAvailable') : '',
        loadMoreAppendText: otherLen ? getKeyText('showOtherComputer') : ''
      };
    }
  }

  private getStorageEncryptionStateByParent(isEncrypted, parentIsEncrypted: StorageEncryptionState): StorageEncryptionState {
    if (isEncrypted === true || parentIsEncrypted === StorageEncryptionState.Encrypted) return StorageEncryptionState.Encrypted;
    if (isEncrypted === false) return StorageEncryptionState.NotEncrypted;
    return StorageEncryptionState.PossiblyEncrypted;
  }

  private get getBitlockerPasswordControls() {
    return [
      { value: '1', control: this.bitlockerPasswordControl },
      { value: '2', control: this.bitlockerRecoveryPasswordControl },
      { value: '3', control: this.bitlockerKeyFileControl }
    ];
  }

  get isBitLockedFileSupported(): boolean {
    return Computer.IsSupportedAgentVersion(this.computerData, AgentType.Backup, this.FIRST_BACKUP_AGENT_SUPPORTED_BITLOCKER_FILE);
  }

  public changeBitLockerPasswordType() {
    const requiredValidators: ValidatorFn[] = [Validators.required];
    this.getBitlockerPasswordControls.forEach((element) => {
      if (this.bitlockerPasswordTypeControl.value === element.value) {
        element.control.enable();
        element.control.addValidators(requiredValidators);
        element.control.updateValueAndValidity();
      } else {
        element.control.disable();
        element.control.removeValidators(requiredValidators);
      }
    });
  }

  public async onChangeBLPassFile(event: Event & HTMLInputElement) {
    this.bitLockerFileContent = '';
    this.bitLockerFileName = '';
    const eventTarget = event.target as HTMLInputElement;
    if (this.bitlockerKeyFileControl.valid && eventTarget && eventTarget.files && eventTarget.files.length) {
      const file = eventTarget.files[0];
      this.bitLockerFileContent = '';
      this.bitLockerFileName = '';
      from(fileToBase64(file))
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (fileContent) => {
            this.bitLockerFileContent = fileContent.split(',')[1];
            this.bitLockerFileName = file.name;
          },
          error: (err) => console.log('error while reading file', err)
        });
    }
  }

  private getIsBackupAgentSupportsNewRM(): boolean {
    const version = Number(this.backupVersionUpdated.substring(0, 4));

    return (
      version >= (this.computerData?.os === OsType.windows ? this.newRMSupportsMinimalWindowsVersion : this.newRMSupportsMinimalUnixVersion)
    );
  }
}
