import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeSpanPipe } from '@utils/pipes/time-span.pipe';
import { BoolDisplayPipe } from './pipes/bool-display.pipe';
import { BuildTypeDisplayPipe } from './pipes/BuildTypeDisplay.pipe';
import { ClientUrlPipe } from './pipes/client-url.pipe';
import { ComputerOsIconPipe } from './pipes/os-icon.pipe';
import { PlanStatusPipe } from './pipes/plan-status.pipe';
import { PlanTypePipe } from './pipes/plan-type.pipe';
import { StorageTypeIconPipe } from './pipes/storage-type-icon.pipe';
import { VirtualMachineTypeDisplayPipe } from './pipes/virtual-machine-type-display.pipe';

const exportsArray = [
  BuildTypeDisplayPipe,
  StorageTypeIconPipe,
  ClientUrlPipe,
  PlanTypePipe,
  ComputerOsIconPipe,
  VirtualMachineTypeDisplayPipe,
  BoolDisplayPipe,
  PlanStatusPipe,
  TimeSpanPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: exportsArray,
  exports: exportsArray
})
export class UtilsPipeModule {}
