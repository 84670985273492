export const IntMaxValue = 2147483647;
export const TimeSpanMaxValue = '10675199.02:48:05.4775807';
export const MaxFileSizeForBackend = '9223372036854775807';
export const MaxFileSizeStrFormat = '9223372036854776000';
export const MaxTicks = '9223372036854776000';
export const TimeSpanZeroValue = '00:00:00';
export const GuidEmpty = '00000000-0000-0000-0000-000000000000';
export const GuidNotEmpty = '00000000-0000-0000-0000-000000000001';
export const GuidDirectAccessVSS = 'b5946137-7b9f-4925-af80-51abd60b20d5';
export const EMAIL_REGEXP = '^[a-zA-Z0-9._+-]*[a-zA-Z0-9]+[a-zA-Z0-9._+-]*@[a-zA-Z0-9._-]*[a-zA-Z0-9]+[a-zA-Z0-9._-]*\\.[a-zA-Z0-9]+$';
export const HOME_EMAIL_REGEXP =
  /^[a-zA-Z0-9._+-]*[a-zA-Z0-9]+[a-zA-Z0-9._+-]*@[a-zA-Z0-9._-]*[a-zA-Z0-9]+[a-zA-Z0-9._-]*\.[a-zA-Z0-9]+(\(MSFT\))?$/;
export const validateIPV4Regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:[0-9]+)?$/;
export const validateIPV6Regex =
  /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;
export const validateWhitespace = /^\S([\s\S]*?)\S$/;
export const TIME_12_FORMAT_WITHOUT_SECONDS_REGEXP = /(^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]) ([AaPp][Mm])/;
export const TIME_24_FORMAT_WITHOUT_SECONDS_REGEXP = /^(2[0-3]|[01][0-9]):([0-5][0-9])$/;
export const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);
export const DNS_REGEX = /^[a-zA-Z0-9]+([.-]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]+$/;
export const ALIAS_REGEX = /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
export const ALIAS_TEXT_REGEX = /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9]*[a-zA-Z0-9])*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9]*[A-Za-z0-9])$/;
export const DOMAIN_REGEX =
  /^([a-zA-Z0-9][a-zA-Z0-9-_@]{0,61}[a-zA-Z0-9]{0,1}\.)+([a-zA-Z]{1,15}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})(\(MSFT\))?$/;
export const getComposedRegex = (...regexes) => new RegExp(regexes.map((regex) => regex.source).join('|'));
export const BREAKS_REGEX = new RegExp(/\r?\n|\r/g);
export const SPACES_REGEX = new RegExp(/[\s]+/g);
export const SPACE_REGEX = new RegExp(/\s/g);
export const COMMAS_REGEX = new RegExp(/,/g);
export const TAB = 'tab';
