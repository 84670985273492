import { BasePlan } from '@modules/wizards/models/base/base-plan-models';
import { EncryptionSettings } from '@modules/wizards/models/base/base-models/plan-encryption-settings-model';
import { EncryptionAlgorithm } from '@modules/wizards/models/compression-encription-models';
import { DeleteFileSettingsBase } from '@modules/wizards/models/base/base-models/plan-delete-file-settings-model';
import { SyntheticFull } from '@modules/wizards/models/base/base-models/plan-syntetic-full-model';

export class BaseSQLPlanModel extends BasePlan implements EncryptionSettings, DeleteFileSettingsBase, SyntheticFull {
  EncryptionAlgorithm: EncryptionAlgorithm;
  EncryptionKeySize: number;
  IsSyntheticSupported: boolean;
  RetentionDelay: string;
  RetentionDeleteLastVersion: boolean;
  RetentionNumberOfVersions: number;
  RetentionTime: string;
  RetentionUseDefaultSettings: boolean;
  SyntheticFull: boolean;
  UseBackupDate: boolean;
  UseServerSideEncryption: boolean;

  constructor() {
    super();
  }
}
