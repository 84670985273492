import StorageAccount from '@models/StorageAccount';
import { RemoteDeployPlanModel } from '@models/remote-deploy/remote-deploy-plan-model';

export function updatePlanBucketDisplayName(plan: RemoteDeployPlanModel, accounts: StorageAccount[]): string {
  const accIndex = accounts.findIndex(acc => acc.Id === plan.plan.SGAccountID);
  let planBucket = plan.plan.Bucket;

  if (~accIndex) {
    const bucket = accounts[accIndex].Buckets.find(b => b.BucketName === planBucket);
    planBucket = bucket ? `${bucket.DestinationName} (${bucket.BucketName})` : planBucket;
  }

  return planBucket;
}
