import { StorageType } from '@models/StorageType.enum';
import { GuidEmpty } from 'mbs-ui-kit/utils/constants';
import { BackupFileFormModel } from '../../models/backupFile/backup-form-model';
import { BaseForStepsHelper, WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { StepsDataFromPlanHelper } from '../steps-data-from-plan-helper/steps-data-from-plan-helper';
import { SupportMethodsForStepsFromPlan } from '../support-methods-for-steps-from-plan';

export class BackupFileModelForStepsHelper extends BaseForStepsHelper {
  public static BackupFileModel: BackupFileFormModel = null;

  public static BackupModelFromPlan(plan, settings: WizardHelpersSettings = this.DefaultSettings): BackupFileFormModel {
    const newPlan = plan && plan.plan;
    if (newPlan) {
      this.setThisValues(plan, settings);
      this.setStorageType(settings.mode, newPlan.HybridID, newPlan.ConnectionID);
      this.BackupFileModel = new BackupFileFormModel(this.isNBF, this.isLinux);
      if (this.isCreate) newPlan.AlwaysUseVSS = true;

      this.updatePlanNameStep(newPlan, settings.compName);
      this.updateWhereBackupStep(newPlan, settings);
      this.BackupFileModel.whatBackupTreeStep = StepsDataFromPlanHelper.updateWhatBackupTree(newPlan);
      this.BackupFileModel.compressionEncryptionStep = StepsDataFromPlanHelper.updateCompressionEncryption(
        newPlan,
        this.isNBF,
        this.isLinux,
        this.isCreate
      );
      if (!this.isNBF) {
        this.BackupFileModel.retentionPolicyStep = StepsDataFromPlanHelper.updateRetentionPolicy(
          newPlan,
          this.isCreate,
          this.planType,
          this.isLinux
        );
        this.BackupFileModel.scheduleStep = StepsDataFromPlanHelper.updateSchedule(
          newPlan,
          plan.OverdueAfterSuccess,
          this.isCreate,
          this.isLinux
        );
      } else {
        this.bucketId = this.accounts && this.isRD ? this.getBucketId(newPlan.Bucket, newPlan.SGAccountID) : newPlan.ConnectionID;
        this.BackupFileModel.retentionPolicyStep = StepsDataFromPlanHelper.updateNBFRetentionPolicy(
          newPlan,
          SupportMethodsForStepsFromPlan.needShowImmutability(this.storages.CloudStorages, this.bucketId),
          this.isCreate,
          this.isLinux
        );
        this.BackupFileModel.archiveConsistencyStep = StepsDataFromPlanHelper.updateArchiveConsistency(newPlan);
        this.BackupFileModel.scheduleStep = StepsDataFromPlanHelper.updateNBFSchedule(
          newPlan,
          plan.OverdueAfterSuccess,
          this.isCreate,
          this.isLinux,
          settings.isNBF,
          settings.isRD || settings.validVersionForSchedule
        );
      }
      this.updateAdvancedOptionsData(newPlan, settings.isNBF);
      this.BackupFileModel.advancedFilterStep = StepsDataFromPlanHelper.updateAdvancedFilter(newPlan, this.isLinux);
      this.BackupFileModel.prePostActionsStep = StepsDataFromPlanHelper.updatePrePostActions(newPlan, false, this.isLinux);
      if (!this.isLinux) {
        this.BackupFileModel.AvailableMachinePlans = StepsDataFromPlanHelper.updateAvailableMachinePlans(
          plan.AvailableMachinePlans,
          newPlan.ID
        );
      }
      this.BackupFileModel.notificationStep = StepsDataFromPlanHelper.updateNotification(
        newPlan.Notification,
        newPlan.WindowsEventLogNotification,
        this.isLinux
      );
    }

    return this.BackupFileModel;
  }

  public static updatePlanNameStep(newPlan, compName: string, ): void {
    const name = newPlan.Name || SupportMethodsForStepsFromPlan.generatePlanName(compName, this.planType, this.timeZoneOffset);
    this.BackupFileModel.planNameStep = StepsDataFromPlanHelper.updatePlanNameStep(name, newPlan.SavePlanInCloud, this.isNBF);
  }
  public static updateWhereBackupStep(newPlan, settings: WizardHelpersSettings): void {
    if (!this.accounts) {
      this.BackupFileModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(
        newPlan.IsHybridBackup && !this.isLinux && !this.isNBF,
        newPlan.HybridID,
        newPlan.ConnectionID
      );
    } else {
      const id = this.bucketId || this.getBucketId(newPlan.Bucket, newPlan.SGAccountID);

      if (id && id !== newPlan.ConnectionID && !this.storage) this.setStorageType(settings.mode, newPlan.HybridID, id);

      this.BackupFileModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(
        newPlan.IsHybridBackup && !this.isLinux && !this.isNBF,
        newPlan.HybridID,
        id || newPlan.ConnectionID
      );
      if (id && id !== GuidEmpty) {
        const storage = this.storages.CloudStorages.find((s) => s.ID === id) || this.storages.LocalStorages.find((s) => s.ID === id);
        this.storageType = storage ? storage.StorageType : this.storageType;
      }
    }
    if (!this.isRD && this.isNBF) SupportMethodsForStepsFromPlan.getFilteredStoragesByTypes(this.storages, [StorageType.OpenStack]);
    this.BackupFileModel.Storages = this.storages;
  }

  public static updateAdvancedOptionsData(newPlan, isNBF: boolean): void {
    const advancedOptionsData = StepsDataFromPlanHelper.updateAdvancedOptionsForBackup(newPlan, this.storage, this.isLinux, this.isNBF);
    this.updateEFSData(newPlan, isNBF, advancedOptionsData);
    if (this.isCreate && this.isNBF && !advancedOptionsData.advancedOptionsStep.SyntheticFull) {
      advancedOptionsData.advancedOptionsStep.SyntheticFull = true;
    }
    this.BackupFileModel.advancedOptionsStep = advancedOptionsData.advancedOptionsStep;
    this.BackupFileModel.advancedOptionsFlags = advancedOptionsData.advancedOptionsFlags;
  }
}
