<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:simple_schedule_title' | i18next: { format: 'title' } }}
    </h4>
    <div class="mbs-wizard_form-description">
      {{ 'wizards:simple_schedule_subtitle' | i18next }}
    </div>
  </header>

  <div class="mbs-wizard_form-content">

    <mbs-form-group>
      <div class="row">
        <div class="col-3">
          <mbs-datepicker formControlName="startFromDate"
                          [needSetCurrent]="true"
                          [label]="'wizards:start_backup_label' | i18next"
                          [id]="'start-from-date-picker'"></mbs-datepicker>
        </div>
        <div class="col-3">
          <mbs-timepicker formControlName="startFromTime"
                          [label]="'wizards:time_label' | i18next"
                          [id]="'start-from-time-picker'"
                          [meridian]="true"
                          [needChangeInvalid]="true"></mbs-timepicker>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-select formControlName="scheduleTemplate"
                  [label]="'wizards:select_template_label' | i18next"
                  [appendTo]="'.modal-content'"
                  [bindValue]="'Name'"
                  [bindLabel]="'Name'"
                  [items]="CurrentScheduleTemplates"
                  [id]="'schedule-template-select'"
                  [clearable]="false"
                  [searchable]="false"></mbs-select>
    </mbs-form-group>

    <mbs-form-group>
      <div class="form-row align-items-center">
        <div [class.col-5]="isBackupPlan"
             [class.col-6]="!isBackupPlan">
          <mbs-checkbox formControlName="fullScheduleEnabled"
                        [label]="fullScheduleLabel"
                        (change)="fullScheduleEnabledChangeHandler($event, true)"></mbs-checkbox>
        </div>
        <div [class.col-7]="isBackupPlan"
             [class.col-6]="!isBackupPlan">
          <div class="form-row align-items-center">
            <div class="col-3">
              <mbs-number formControlName="fullBackupEveryHours"
                          min="0"
                          max="87600"
                          (change)="everyHoursChangeHandler($event, 'fullBackupEveryMinutes')"
                          [attr.data-test-element]="elementSelector.name.fullBackupEveryHoursNumber"
                          [id]="'full-backup-every-hours-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="full-backup-every-hours-number">{{'wizards:hours' | i18next}}</label>
            </div>
            <div class="col-3">
              <mbs-number formControlName="fullBackupEveryMinutes"
                          min="0"
                          max="59"
                          (change)="minutesChangeHandler($event)"
                          [attr.data-test-element]="elementSelector.name.fullBackupEveryMinutesNumber"
                          [id]="'full-backup-every-minutes-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="full-backup-every-minutes-number">{{'wizards:minutes' | i18next}}</label>
            </div>

          </div>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group *ngIf="!isSQLPlan"
                    [attr.data-test-element]="elementSelector.name.notSQLBlock">
      <div class="form-row align-items-center">
        <div [class.col-5]="isBackupPlan"
             [class.col-6]="!isBackupPlan">
          <mbs-checkbox formControlName="blockLevelBackup"
                        [label]="blockScheduleLabel"
                        (change)="blockLevelBackupChangeHandler($event, true)"></mbs-checkbox>
        </div>
        <div [class.col-7]="isBackupPlan"
             [class.col-6]="!isBackupPlan">
          <div class="form-row align-items-center">
            <div class="col-3">
              <mbs-number formControlName="blockLevelBackupEveryHours"
                          min="0"
                          max="87600"
                          (change)="everyHoursChangeHandler($event, 'blockLevelBackupEveryMinutes')"
                          [attr.data-test-element]="elementSelector.name.blockBackupEveryHoursNumber"
                          [id]="'block-level-backup-every-hours-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('blockLevelBackup').value || !stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="block-level-backup-every-hours-number">{{'wizards:hours' | i18next}}</label>
            </div>
            <div class="col-3">
              <mbs-number formControlName="blockLevelBackupEveryMinutes"
                          min="0"
                          max="59"
                          (change)="minutesChangeHandler($event)"
                          [attr.data-test-element]="elementSelector.name.blockBackupEveryMinutesNumber"
                          [id]="'block-level-backup-every-minutes-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('blockLevelBackup').value || !stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="block-level-backup-every-minutes-number">{{'wizards:minutes' | i18next}}</label>
            </div>
          </div>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group *ngIf="isSQLPlan"
                    [attr.data-test-element]="elementSelector.name.sqlDiffBlock">
      <div class="form-row align-items-center">
        <div class="col-6">
          <mbs-checkbox formControlName="scheduleDiffEnabled"
                        [label]="'wizards:differential_label' | i18next"
                        (change)="scheduleDiffEnabledChangeHandler($event, true)"></mbs-checkbox>
        </div>
        <div class="col-6">
          <div class="form-row align-items-center">
            <div class="col-3">
              <mbs-number formControlName="differentialBackupEveryHours"
                          min="0"
                          max="87600"
                          (change)="everyHoursChangeHandler($event, 'differentialBackupEveryMinutes')"
                          [attr.data-test-element]="elementSelector.name.diffBackupEveryHoursNumber"
                          [id]="'differential-backup-every-hours-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('scheduleDiffEnabled').value || !stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="differential-backup-every-hours-number">{{'wizards:hours' | i18next}}</label>
            </div>
            <div class="col-3">
              <mbs-number formControlName="differentialBackupEveryMinutes"
                          min="0"
                          max="59"
                          (change)="minutesChangeHandler($event)"
                          [attr.data-test-element]="elementSelector.name.diffBackupEveryMinutesNumber"
                          [id]="'differential-backup-every-minutes-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('scheduleDiffEnabled').value || !stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="differential-backup-every-minutes-number">{{'wizards:minutes' | i18next}}</label>
            </div>

          </div>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group *ngIf="isSQLPlan"
                    [attr.data-test-element]="elementSelector.name.sqlTLogBlock">
      <div class="form-row align-items-center">
        <div class="col-6">
          <mbs-checkbox formControlName="scheduleTLogEnabled"
                        [label]="'wizards:transaction_label' | i18next"
                        (change)="scheduleTLogEnabledChangeHandler($event, true)"></mbs-checkbox>
        </div>
        <div class="col-6">
          <div class="form-row align-items-center">
            <div class="col-3">
              <mbs-number formControlName="tLogBackupEveryHours"
                          min="0"
                          max="87600"
                          (change)="everyHoursChangeHandler($event, 'tLogBackupEveryMinutes')"
                          [attr.data-test-element]="elementSelector.name.tLogBackupEveryHoursNumber"
                          [id]="'tLog-backup-every-hours-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('scheduleTLogEnabled').value || !stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="tLog-backup-every-hours-number">{{'wizards:hours' | i18next}}</label>
            </div>
            <div class="col-3">
              <mbs-number formControlName="tLogBackupEveryMinutes"
                          min="0"
                          max="59"
                          (change)="minutesChangeHandler($event, this.isSQLPlan)"
                          [attr.data-test-element]="elementSelector.name.tLogBackupEveryMinutesNumber"
                          [id]="'tLog-backup-every-minutes-number'"></mbs-number>
            </div>
            <div class="col-3">
              <label [class.-disabled]="!stepForm.get('scheduleTLogEnabled').value || !stepForm.get('fullScheduleEnabled').value"
                     class="mbs-form_label mb-0"
                     for="tLog-backup-every-minutes-number">{{'wizards:minutes' | i18next}}</label>
            </div>
          </div>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group *ngIf="totalShow"
                    [attr.data-test-element]="elementSelector.name.totalShowBlock">
      <mbs-card [size]="'sm'">
        <mbs-card-header [title]="'Total info:'"></mbs-card-header>
        <p *ngIf="stepForm.get('fullScheduleEnabled').value"
           class="mb-0"
           [attr.data-test-element]="elementSelector.name.totalFullText">
          {{isBackupPlan ? ('wizards:incremental_text' | i18next) : ('wizards:full_text' | i18next)}} {{fullBackupTime}}
        </p>

        <p *ngIf="stepForm.get('blockLevelBackup').value"
           class="mb-0"
           [attr.data-test-element]="elementSelector.name.totalBlockText">
          {{isBackupPlan ? ('wizards:block_text' | i18next) : ('wizards:block_incremental_text' | i18next)}}
          {{blockLevelBackupTime}}
        </p>

        <p *ngIf="stepForm.get('scheduleDiffEnabled').enabled && stepForm.get('scheduleDiffEnabled').value"
           class="mb-0"
           [attr.data-test-element]="elementSelector.name.totalDiffText">
          {{'wizards:diff_text' | i18next}} {{differentialBackupTime}}
        </p>

        <p *ngIf="stepForm.get('scheduleTLogEnabled').value"
           class="mb-0"
           [attr.data-test-element]="elementSelector.name.totalTLogText">
          {{'wizards:tlog_text' | i18next}} {{logBackupTime}}
        </p>
      </mbs-card>
    </mbs-form-group>
  </div>
</section>
