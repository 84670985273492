import { BuildType } from './BuildType.enum';
import Computer, { AgentType, OsType } from './Computer';

export const ComputerOsBuildTypeAdapter = [
  // windows
  { os: OsType.windows, agentType: AgentType.Backup, subTypes: null, buildType: BuildType.CommonWindowsBuild },
  { os: OsType.windows, agentType: AgentType.RMM, subTypes: null, buildType: BuildType.RMMAgent },
  { os: OsType.windows, agentType: AgentType.RA, subTypes: null, buildType: BuildType.CBLRA },
  { os: OsType.windows, agentType: AgentType.DeepInst, subTypes: null, buildType: BuildType.DeepInstinct },
  // macOS
  { os: OsType.apple, agentType: AgentType.Backup, subTypes: null, buildType: BuildType.LinuxMac },
  { os: OsType.apple, agentType: AgentType.RMM, subTypes: null, buildType: BuildType.RMMAgentMac },
  // linux deb
  {
    os: OsType.linux,
    agentType: AgentType.Backup,
    subTypes: [BuildType.LinuxDebian7, BuildType.LinuxUbuntu12, BuildType.LinuxUbuntu14, BuildType.RMMAgentLinuxDeb],
    buildType: BuildType.LinuxUbuntu14
  },
  {
    os: OsType.linux,
    agentType: AgentType.RMM,
    subTypes: [BuildType.LinuxDebian7, BuildType.LinuxUbuntu12, BuildType.LinuxUbuntu14, BuildType.RMMAgentLinuxDeb],
    buildType: BuildType.RMMAgentLinuxDeb
  },
  // linux rpm
  {
    os: OsType.linux,
    agentType: AgentType.Backup,
    subTypes: [BuildType.LinuxRedHat5, BuildType.LinuxRedHat6, BuildType.LinuxRedHat7, BuildType.RMMAgentLinuxRpm],
    buildType: BuildType.LinuxRedHat6
  },
  {
    os: OsType.linux,
    agentType: AgentType.RMM,
    subTypes: [BuildType.LinuxRedHat5, BuildType.LinuxRedHat6, BuildType.LinuxRedHat7, BuildType.RMMAgentLinuxRpm],
    buildType: BuildType.RMMAgentLinuxRpm
  }
];

export function getNeededBuildType(computer: Computer, agentType: AgentType): BuildType {
  const subType = Computer.getFirstBuildTypeValue(computer, [AgentType.Backup, AgentType.RMM]);
  return ComputerOsBuildTypeAdapter.find(
    (item) => item.os === computer?.os && item.agentType === agentType && (!item.subTypes || item.subTypes.includes(subType))
  )?.buildType;
}

export const InstallationAgents = [AgentType.Backup, AgentType.RMM, AgentType.RA];
