import { Period } from '@models/Period.enum';
import * as moment from 'moment';
import { Duration } from 'moment';
import { AMPMRegex, GetHoursFrom12HoursFormatString, hoursRegex, minutesRegex } from './date';

export class DateTimeUtils {
  static ConvertTimeFrom12FormatTo24Format(time): string {
    let hours = Number(time.match(hoursRegex)[1]);
    const minutes = Number(time.match(minutesRegex)[1]);
    const ampmMatches = time.match(AMPMRegex);
    const AMPM = ampmMatches[1];

    if ((AMPM == 'PM' || AMPM == 'pm') && hours < 12) {
      hours = hours + 12;
    } else if ((AMPM == 'AM' || AMPM == 'am') && hours == 12) {
      hours = hours - 12;
    }

    let sHours = hours.toString();
    let sMinutes = minutes.toString();

    if (hours < 10) {
      sHours = '0' + sHours;
    }
    if (minutes < 10) {
      sMinutes = '0' + sMinutes;
    }

    return sHours + ':' + sMinutes;
  }

  static GetHoursFrom24hFormatString(time: string): number {
    return Number(hoursRegex.exec(time)[1]);
  }

  static GetMinutes(time): number {
    return Number(time.match(minutesRegex)[1]);
  }

  static GetDateFromTimeString(str: string): Date {
    const isAmPmFormat = AMPMRegex.test(str);
    const dt = new Date();
    if (isAmPmFormat) {
      dt.setHours(GetHoursFrom12HoursFormatString(str));
    } else {
      dt.setHours(this.GetHoursFrom24hFormatString(str));
    }
    dt.setMinutes(this.GetMinutes(str));
    return dt;
  }

  static NormalizeTime(time): string {
    const hours = this.GetHoursFrom24hFormatString(time);
    const minutes = this.GetMinutes(time);
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) {
      sHours = '0' + sHours;
    }
    if (minutes < 10) {
      sMinutes = '0' + sMinutes;
    }
    return sHours + ':' + sMinutes;
  }

  /*
   * Gets the time from date in "hh:mm am/pm" format string
   */
  static GetTime12FormatFromDate(date: Date) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    let AMPM = '';

    if (hours > 12) AMPM = 'pm';
    else AMPM = 'am';
    hours = hours % 12 || 12;

    let sHours = hours.toString();
    let sMinutes = minutes.toString();

    if (hours < 10) sHours = '0' + sHours;
    else if (minutes < 10) sMinutes = '0' + sMinutes;

    return sHours + ':' + sMinutes + ' ' + AMPM;
  }

  static GetTime24FormatFromDate(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = '0' + sHours;
    if (minutes < 10) sMinutes = '0' + sMinutes;

    return sHours + ':' + sMinutes;
  }

  static GetTime24FormatFromDuration(duration: Duration) {
    const sHours = duration.hours();
    const sMinutes = duration.minutes();
    let minutesStr = '00';
    let hoursStr = '00';
    if (sMinutes < 10) minutesStr = '0' + sMinutes.toString();
    else minutesStr = sMinutes.toString();
    if (sHours < 10) hoursStr = '0' + sHours.toString();
    else hoursStr = sHours.toString();
    return hoursStr + ':' + minutesStr;
  }

  static TimeGroupToTimeSpanString(timeGroup: { value: number; period: Period }): string {
    let timeMultiplier = 1;
    switch (timeGroup.period) {
      case Period.day:
        timeMultiplier = 1;
        break;
      case Period.month:
        timeMultiplier = 30;
        break;
      case Period.week:
        timeMultiplier = 7;
        break;
      case Period.year:
        timeMultiplier = 365;
        break;
    }
    return `${timeGroup.value * timeMultiplier}.12:00 AM`;
  }

  static TimeSpanObjectToHoursMinutes(timespan) {
    const minutesPerHour = 60;
    const hours = timespan.TotalHours;
    const minutes = timespan.Minutes;
    return { hours, minutes };
  }

  // static TimeSpanStringToTimeGroup(timeSpanString: string) {
  //     let value = 0;
  //     let timeperiod = Period.year;
  //     const daysPerYear = 365;
  //     const daysPerMonth = 30;
  //     const daysPerWeek = 7;

  //     if (timeSpan.Days >= 1)
  //     {
  //         if (timeSpan.Days%daysPerYear == 0)
  //         {
  //             //years
  //             value = timeSpan.Days/daysPerYear;
  //             timeperiod = Period.year;
  //         }
  //         else if (timeSpan.Days%daysPerMonth == 0)
  //         {
  //             //months
  //             value = timeSpan.Days/daysPerMonth;
  //             timeperiod = Period.month;
  //         }
  //         else if (timeSpan.Days%daysPerWeek == 0)
  //         {
  //             //week
  //             value = timeSpan.Days/daysPerWeek;
  //             timeperiod = Period.week;
  //         }
  //         else
  //         {
  //             //days
  //             value = timeSpan.Days;
  //             timeperiod = Period.day;
  //         }
  //     }
  //     else
  //     {
  //         //set min value: '1 day'
  //         value = 1;
  //         timeperiod = Period.day;
  //     }

  //     return { value, timeperiod };
  // }

  static GetDateStringWithOffsetFromUTC(date: Date): string {
    return moment(new Date(date)).toISOString(true).split('.')[0];
  }

  static GetTimeSpanString(duration: Duration): string {
    return `${duration.days()}.${duration.hours()}:${duration.minutes()}:${duration.seconds()}.${duration.milliseconds()}`;
  }

  static GetTicks(duration: Duration): string {
    return (duration.asMilliseconds() * 10000).toString();
  }

  static HumanizeDuration(duration: Duration): string {
    return `${duration.years() > 0 ? duration.years() + ' year(s) ' : ''}
        ${duration.days() > 0 ? duration.days() + ' day(s) ' : ''}
        ${duration.hours() > 0 ? duration.hours() + ' hour(s) ' : ''}
        ${duration.minutes() > 0 ? duration.minutes() + ' minute(s) ' : ''}`.trim();
  }

  static GetDateOnlyWithSameNumbersInUTC(date: Date): Date {
    const newDate = new Date();
    newDate.setUTCDate(date.getDate());
    newDate.setUTCFullYear(date.getFullYear());
    newDate.setUTCMonth(date.getMonth());
    newDate.setUTCHours(0);
    return newDate;
  }

  static GetDateFullWithSameNumbersInUTC(date: Date): Date {
    const newDate = this.GetDateOnlyWithSameNumbersInUTC(date);
    newDate.setUTCHours(date.getHours());
    return newDate;
  }

  static DateToISOStringWithSameNumbersWOUTCOffset(date: Date): string {
    return moment(date).add('hours').format('YYYY-MM-DDTHH:mm:00.000');
  }

  static ConvertTimeZone(date, diff: number) {
    /**
     * as default Moment format like 2022-06-01T17:03:15+03:00
     * @date YYYY-MM-DD + T(string)
     * @time HH:mm:ss - time
     * @tz [+/-]HH:mm
     */
    const defaultMomentFormat = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(.+)/;

    const timeString = moment(new Date(date)).format();
    const converted = timeString.replace(defaultMomentFormat, `$1${DateTimeUtils.NormalizeUtcOffset(diff)}`);

    return moment(converted).toISOString();
  }
  /**
   * convert negative minutes diff to [+/-]hh:mm UTC offset string;
   *
   * @static
   * @param {number} diff
   * @return {string}
   */
  static NormalizeUtcOffset(diff: number) {
    const hours = Math.abs(Math.floor(diff / 60));
    const minutes = Math.abs(diff % 60);
    const formatted = moment(`${hours}:${minutes}`, 'HH:mm').format('HH:mm');

    return diff > 0 ? `-${formatted}` : `+${formatted}`;
  }

  static getDaysCountBetweenDates(firstDate: Date, secondDate: Date, precise = false) {
    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);

    return moment(firstDate).diff(moment(secondDate), 'days', precise);
  }
}

export function parseTimeSpan(timeSpan: string) {
  if (!timeSpan) {
    return '';
  }

  const [_ff, time, days] = timeSpan.split('.').reverse();
  const [seconds, minutes, hours] = (time || '').split(':').reverse();
  let timeAgo = '';

  if (seconds) {
    timeAgo = `${seconds}s ${timeAgo}`;
  }
  if (minutes) {
    timeAgo = `${minutes}m ${timeAgo}`;
  }
  if (hours) {
    timeAgo = `${hours}h ${timeAgo}`;
  }
  if (days) {
    timeAgo = `${days}d ${timeAgo}`;
  }

  return timeAgo;
}
