import { PlanTypes } from '@models/PlanTypes.enum';
import { HyperVWizard } from '@modules/wizards/remote-management-wizards/hyper-v-wizard/hyper-v-wizard.component';
import { SQLWizard } from '@modules/wizards/remote-management-wizards/sql-wizard/sql-wizard.component';
import { ImageBasedRestoreWizard } from '@modules/wizards/remote-management-wizards/image-based-restore-wizard/image-based-restore-wizard.component';
import { VMWareWizard } from '@modules/wizards/remote-management-wizards/vmware-wizard/vmware-wizard.component';
import { RestoreFileWizard } from '@modules/wizards/remote-management-wizards/restore-file-wizard/restore-file-wizard.component';
import { ImageBasedWizard } from '@modules/wizards/remote-management-wizards/image-based-wizard/image-based-wizard.component';
import { BackupFilesWizard } from '@modules/wizards/remote-management-wizards/backup-files-wizard/backup-files-wizard.component';

export function getWizardComponentType(type: PlanTypes) {
  switch (type) {
    case PlanTypes.Plan:
      return BackupFilesWizard;
    case PlanTypes.BackupHyperV:
      return HyperVWizard;
    case PlanTypes.BackupVMware:
      return VMWareWizard;
    case PlanTypes.BackupDiskImagePlan:
      return ImageBasedWizard;
    case PlanTypes.RestorePlan:
      return RestoreFileWizard;
    case PlanTypes.RestoreDiskImagePlan:
      return ImageBasedRestoreWizard;
    case PlanTypes.DataBasePlan:
      return SQLWizard;
    default:
      return BackupFilesWizard;
  }
}
