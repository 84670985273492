import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { AdministratorsQueryParams } from '@models/Administrator';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import { AdministratorLicenseAvailabilityInfo } from '@models/administrators/administrator-license-availability-info';
import { AdministratorLicense } from '@models/licenses/administrator-license';
import { AdministratorLicenseSummaryInfo } from '@models/licenses/administrator-license-summary-info';
import { LicenseSummary } from '@models/licenses/license-summary';
import { LicenseType } from '@models/LicenseType.enum';
import { PagedResponse } from '@models/Paging';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { AssignLicenseDialogueData } from '@modules/rmm-licenses/components/assign-license-dialogue/assign-license-dialogue-data';
import { AssignLicenseDialogueComponent } from '@modules/rmm-licenses/components/assign-license-dialogue/assign-license-dialogue.component';
import { LicensesManagingService } from '@modules/rmm-licenses/models/licenses-managing-service';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ManageLicenseAdminModalComponent } from '@shared/components/licenses/components/manage-license-admin-modal/manage-license-admin-modal.component';
import { POST_PAYMENT_TOKEN } from '@shared/components/licenses/tokens/post-payment.token';
import { hasActionsQueue } from '@utils/has-actions-queue';
import { LicensesHelper } from '@utils/helpers/licenses-helper';
import { AbilityService } from 'ability';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { ModalService, ModalSettings } from 'mbs-ui-kit';
import { BehaviorSubject, combineLatest, from, noop, Observable, of, take } from 'rxjs';
import { catchError, filter, finalize, first, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from './auth.service';

export type LicenseAssignToAdmin = { adminId: string; license: AdministratorLicense };
export type FilterLicenseAndAdminId = { filter: LicenseType[]; adminID: string; expiredCurrent?: boolean; email?: string };

@Injectable({
  providedIn: 'root'
})
export class AdministratorService extends EntityCollectionServiceBase<AdministratorInCamelCase> implements LicensesManagingService {
  private baseHref = '/api/administrators';

  public get(params?: AdministratorsQueryParams): Observable<PagedResponse<AdministratorInCamelCase>> {
    return this.http.get<PagedResponse<AdministratorInCamelCase>>(this.baseHref, { params });
  }

  public getById(id: string): Observable<AdministratorInCamelCase> {
    return this.http.get<AdministratorInCamelCase>(`${this.baseHref}/${id}`);
  }

  public readonly ignoreElements: PermissionsEnum[] = [
    PermissionsEnum.AccessToAllCompanies,
    PermissionsEnum.EC2Snapshots,
    PermissionsEnum.Rmm,
    PermissionsEnum.DeepInstinct,
    PermissionsEnum.RD,
    PermissionsEnum.MBS,
    PermissionsEnum.Provider,
    PermissionsEnum.RA,
    PermissionsEnum.RemoteConnection,
    PermissionsEnum.ShowRmm,
    PermissionsEnum.Menu2022
  ];
  private myLicensesSummaryList: BehaviorSubject<AdministratorLicenseSummaryInfo[]> = new BehaviorSubject<
    AdministratorLicenseSummaryInfo[]
  >([]);
  private myTrialInfo: BehaviorSubject<{ [key: number]: AdministratorLicenseAvailabilityInfo }> = new BehaviorSubject<{
    [key: number]: AdministratorLicenseAvailabilityInfo;
  }>([]);

  public licensesHasBeenAssigned$: BehaviorSubject<LicenseAssignToAdmin> = new BehaviorSubject<LicenseAssignToAdmin>(null);

  private myLicensesLoading$ = new BehaviorSubject(false);
  private myLicensesRequestPending$ = new BehaviorSubject(false);

  public get licensesLoading$(): Observable<boolean> {
    return this.myLicensesLoading$.asObservable().pipe(hasActionsQueue());
  }

  public get licenseRequestPending$(): Observable<boolean> {
    return this.myLicensesRequestPending$.asObservable().pipe(hasActionsQueue());
  }

  public get licensesSummary$(): Observable<AdministratorLicenseSummaryInfo[]> {
    return this.myLicensesSummaryList.asObservable();
  }

  public get trialInfo$(): Observable<{ [key: number]: AdministratorLicenseAvailabilityInfo }> {
    return this.myTrialInfo.asObservable();
  }

  licensesLoadingOrRequestPending$: Observable<boolean> = of(false);

  private filteredLicenseItems: LicenseSummary[];

  getByIdEntity(id: string): Observable<AdministratorInCamelCase> {
    return this.entities$.pipe(
      map((entities) => ({
        id,
        admin: entities.find((entity) => entity.id === id)
      })),
      switchMap((data) => (data.admin ? of(data.admin) : this.ability.cannot('read', 'Administrator') ? of(null) : this.getByKey(data.id)))
    );
  }

  private getFilteredLicenseItems(filter: LicenseType[]): Observable<Array<AdministratorLicenseSummaryInfo>> {
    return this.getAvailableLicenseSummaries().pipe(
      switchMap((licenses) => {
        if (filter && filter.length > 0) licenses = licenses.filter((x) => filter.includes(x.licenseType));
        this.filteredLicenseItems = licenses;
        return of(licenses);
      })
    );
  }

  // TODO: Remove it
  private assignLicenseOrOpenDialog(data: FilterLicenseAndAdminId, isRmm: boolean, withTrial: boolean, isPostPayment = false): void {
    if (this.hasAvailableLicense(isRmm) && isRmm) {
      this.assignLicense(data.adminID, isRmm ? LicenseType.RMM : LicenseType.RemoteDesktop, false).subscribe(noop);
    } else {
      this.openGrantOrManageLicenseModal(data, isPostPayment);
    }
  }

  private assignLicenseOrOpenDialog$(
    data: FilterLicenseAndAdminId,
    isRmm: boolean,
    isPostPayment = false
  ): Observable<AdministratorLicense | boolean> {
    return this.hasAvailableLicense(isRmm) && isRmm
      ? this.assignLicense(data.adminID, isRmm ? LicenseType.RMM : LicenseType.RemoteDesktop, false)
      : this.openGrantOrManageLicenseModal$(data, isPostPayment);
  }

  // TODO: Remove it
  public openGrantOrManageLicenseModal(data: FilterLicenseAndAdminId, isPostPayment: boolean) {
    if (!isPostPayment) {
      this.openGrantLicenseDialogue(data.adminID, data.filter, this.isTrialLicenseAvailable(), false, data.email);
    } else {
      this.modal.openCustom(ManageLicenseAdminModalComponent, this.openGrantOrManageLicenseModalSettings(data)).finally(noop);
    }
    return this.licensesHasBeenAssigned$;
  }

  private openGrantOrManageLicenseModal$(data: FilterLicenseAndAdminId, isPostPayment: boolean): Observable<boolean> {
    return !isPostPayment
      ? this.openGrantLicenseDialogue$(data.adminID, data.filter, this.isTrialLicenseAvailable(), false, data.email)
      : from(this.modal.openCustom(ManageLicenseAdminModalComponent, this.openGrantOrManageLicenseModalSettings(data))).pipe(
          map(() => true),
          catchError(() => of(false))
        );
  }

  private openGrantOrManageLicenseModalSettings(data: FilterLicenseAndAdminId): ModalSettings {
    return {
      data: {
        title: this.i18nextService.t('licenses:manageModal:assignTitle', {
          format: 'title'
        }),
        entityId: data.adminID
      }
    } as ModalSettings;
  }

  private hasAvailableLicense(isRmm: boolean) {
    const rmmLicense = this.filteredLicenseItems && this.filteredLicenseItems.find((l) => l.licenseType === LicenseType.RMM);
    const rdLicense = !isRmm
      ? this.filteredLicenseItems && this.filteredLicenseItems.find((l) => l.licenseType === LicenseType.RemoteDesktop)
      : null;
    return isRmm
      ? rmmLicense && rmmLicense.count
      : (!rdLicense && rmmLicense && rmmLicense.count) || (!rmmLicense && rdLicense && rdLicense.count);
  }

  // TODO: Remove it
  private assignLicenseIfConfirm(confirm: boolean, data: FilterLicenseAndAdminId, isRmm: boolean, isPostPayment = false): void {
    if (confirm) {
      const trialRmm = this.isTrialLicenseAvailable(LicenseType.RMM);
      const trialRD = this.isTrialLicenseAvailable(LicenseType.RemoteDesktop);
      if (isRmm ? trialRmm : trialRmm || trialRD) {
        this.assignLicense(data.adminID, isRmm ? LicenseType.RMM : trialRD ? LicenseType.RemoteDesktop : LicenseType.RMM, true).subscribe(
          noop
        );
      } else {
        this.assignLicenseOrOpenDialog(data, isRmm, false, isPostPayment);
      }
    }
  }

  private assignLicenseIfConfirm$(
    confirm: boolean,
    data: FilterLicenseAndAdminId,
    isRmm: boolean,
    isPostPayment = false
  ): Observable<null> {
    if (confirm) {
      const trialRmm = this.isTrialLicenseAvailable(LicenseType.RMM);
      const trialRD = this.isTrialLicenseAvailable(LicenseType.RemoteDesktop);

      return (isRmm ? trialRmm : trialRmm || trialRD)
        ? this.assignLicense(data.adminID, isRmm ? LicenseType.RMM : trialRD ? LicenseType.RemoteDesktop : LicenseType.RMM, true).pipe(
            map(() => null)
          )
        : this.assignLicenseOrOpenDialog$(data, isRmm, isPostPayment).pipe(map(() => null));
    }
    return of(null);
  }

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    private modal: ModalService,
    private router: Router,
    private authService: AuthService,
    private ability: AbilityService,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>
  ) {
    super('Administrator', serviceElementsFactory);

    this.authService.currentUser.subscribe({
      next: (user) => {
        user && this.myTrialInfo.next(user.AdminTrialLicences);
      }
    });

    this.licensesLoadingOrRequestPending$ = combineLatest([this.licensesLoading$, this.licenseRequestPending$]).pipe(
      map((val) => {
        return val[0] || val[1];
      })
    );

    this.licensesHasBeenAssigned$
      .pipe(
        withLatestFrom(this.authService.currentUser),
        filter(([license, user]) => user && license && license.adminId === user.Id),
        switchMap(() => this.authService.refreshToken().pipe(catchError(() => of())))
      )
      .subscribe();
  }

  createAdministrator(admin: AdministratorInCamelCase): Observable<AdministratorInCamelCase> {
    return this.http.post<AdministratorInCamelCase>(this.baseHref, admin);
  }

  updateAdministrator(admin: AdministratorInCamelCase): Observable<AdministratorInCamelCase> {
    return this.http.put<AdministratorInCamelCase>(`${this.baseHref}/${admin.id}`, admin);
  }

  deleteAdministrator(id: string): Observable<null> {
    return this.http.delete<null>(`${this.baseHref}/${id}`);
  }

  fetchValidateEmail(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseHref}/administrator-exists`, { params: { email } });
  }

  getAvailableLicenseSummaries(): Observable<AdministratorLicenseSummaryInfo[]> {
    this.myLicensesLoading$.next(true);
    return this.http
      .get<AdministratorLicenseSummaryInfo[]>('/api/licenses/summary/available_license', {
        params: { licenseType: LicenseType.RemoteDesktop + ',' + LicenseType.RMM }
      })
      .pipe(
        tap((licenses) => {
          this.myLicensesSummaryList.next(licenses);
        }),
        finalize(() => {
          this.myLicensesLoading$.next(false);
        })
      );
  }

  assignLicense(administratorId: string, licenseType: LicenseType, isTrial: boolean): Observable<AdministratorLicense> {
    this.myLicensesRequestPending$.next(true);
    return this.http.post<AdministratorLicense>(`${this.baseHref}/${administratorId}/license`, { licenseType, isTrial }).pipe(
      tap((license) => {
        this.updateAdministratorLicense(administratorId, license);
      }),
      finalize(() => {
        this.myLicensesRequestPending$.next(false);
      })
    );
  }

  grantLicense(licenseType, entityId, trial: boolean): Observable<AdministratorLicense> {
    this.myLicensesLoading$.next(true);
    return this.assignLicense(entityId, licenseType, trial).pipe(
      switchMap((license) => {
        return this.authService.fetchCurrentUser().pipe(mapTo(license));
      }),
      finalize(() => {
        this.myLicensesLoading$.next(false);
      })
    );
  }

  removeLicense(administratorId: string): Observable<any> {
    this.myLicensesRequestPending$.next(true);
    return this.http.delete(`${this.baseHref}/${administratorId}/license`).pipe(
      tap(() => this.updateAdministratorLicense(administratorId, null)),
      switchMap((license) => this.authService.fetchCurrentUser().pipe(map(() => license))),
      finalize(() => this.myLicensesRequestPending$.next(false))
    );
  }

  disableAutoRenewal(administratorId: string): Observable<AdministratorLicense> {
    this.myLicensesRequestPending$.next(true);
    return this.http.delete(`${this.baseHref}/${administratorId}/license`).pipe(
      switchMap(() => this.http.get<AdministratorInCamelCase>(`${this.baseHref}/${administratorId}`).pipe(map((admin) => admin.license))),
      tap((license: AdministratorLicense) => {
        this.updateAdministratorLicense(administratorId, license);
        return license;
      }),
      switchMap((license) => {
        return this.authService.fetchCurrentUser().pipe(mapTo(license));
      }),
      finalize(() => {
        this.myLicensesRequestPending$.next(false);
      })
    );
  }

  updateAdministratorLicense(adminId: string, license: AdministratorLicense) {
    this.licensesHasBeenAssigned$.next({ adminId, license });
    this.updateOneInCache({ id: adminId, license: license });
    this.refreshAuth();
  }

  isTrialLicenseAvailable(licenseType: LicenseType = null): boolean {
    if (this.myTrialInfo.value) {
      const now = new Date();
      if (licenseType != null) {
        const lic = this.myTrialInfo.value[licenseType];
        return lic && lic.Allowed && (!lic.ExpiredDate || now < new Date(lic.ExpiredDate));
      } else {
        for (const [key, val] of Object.entries(this.myTrialInfo.value)) {
          if (val.Allowed && (!val.ExpiredDate || now < new Date(val.ExpiredDate))) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isLicenseExpired(license: AdministratorLicense): boolean {
    return LicensesHelper.IsLicenseExpired(license);
  }

  isExpiresSoon(license: AdministratorLicense): boolean {
    const now = new Date();
    now.setHours(now.getHours() + 24 * 5); // add 5 days

    return license.isAutoRenew !== true && now >= new Date(license.dateExpired);
  }

  private isLicenseAvailable(licenseType: LicenseType): boolean {
    return this.myLicensesSummaryList.value.some((x) => x.licenseType === licenseType && x.count > 0);
  }

  isRMMOrRDLicenseAvailable(): boolean {
    return this.isLicenseAvailable(LicenseType.RMM) || this.isLicenseAvailable(LicenseType.RemoteDesktop);
  }

  buyLicense(lic: string | string[] = 'rd,rmm'): void {
    let licenses = lic;

    if (Array.isArray(licenses)) {
      licenses = licenses.join(',');
    }

    void this.router.navigate([RoutingPath.ApLicensesAspx], { queryParams: { buy: 'true', licenses } });
  }

  assignTrial(adminId: string) {
    this.assignLicense(adminId, LicenseType.RMM, true).subscribe();
  }

  refreshAuth(): void {
    this.authService.fetchCurrentUser().pipe(first()).subscribe();
  }

  // TODO: Remove it
  public openGrantLicenseDialogue(
    adminId: string,
    filter: LicenseType[] = null,
    withTrials = false,
    defaultFilter = true,
    email = ''
  ): void {
    const settings = this.prepareOpenGrantLicenseDialogueModalSettings(adminId, filter, withTrials, defaultFilter, email);

    this.isPostPayment.pipe(take(1)).subscribe((isPostPayment) => {
      this.modal.openCustom(isPostPayment ? ManageLicenseAdminModalComponent : AssignLicenseDialogueComponent, settings).catch(noop);
    });
  }

  public openGrantLicenseDialogue$(
    adminId: string,
    filter: LicenseType[] = null,
    withTrials = false,
    defaultFilter = true,
    email = ''
  ): Observable<boolean> {
    const settings = this.prepareOpenGrantLicenseDialogueModalSettings(adminId, filter, withTrials, defaultFilter, email);

    return this.isPostPayment.pipe(
      take(1),
      switchMap((isPostPayment) =>
        from(this.modal.openCustom(isPostPayment ? ManageLicenseAdminModalComponent : AssignLicenseDialogueComponent, settings)).pipe(
          map(() => true),
          catchError(() => of(null))
        )
      )
    );
  }

  private prepareOpenGrantLicenseDialogueModalSettings(
    adminId: string,
    filter: LicenseType[] = null,
    withTrials = false,
    defaultFilter = true,
    email = ''
  ): ModalSettings {
    const settings = new ModalSettings();
    settings.responsive = true;
    settings.data = {
      title: this.i18nextService.t('licenses:manageModal:assignTitle', {
        format: 'title'
      }),
      entityId: adminId,
      adminEmail: email,
      startTrial: false,
      showTrials: withTrials,
      service: this,
      filterLicensesFunc$: defaultFilter ? this.filterLicensesFunc.bind(this) : this.filterLicensesFuncForRM.bind(this),
      showBuyBtn: this.ability.can('read', PermissionsEnum.LicenseBuy)
    } as AssignLicenseDialogueData;
    if (filter && filter.length) settings.data.licenseTypesFilter = filter;

    return settings;
  }

  openGrantLicenseDialogueWithInfoModal$(data: FilterLicenseAndAdminId): Observable<LicenseAssignToAdmin> {
    return combineLatest({
      isPostPayment: this.isPostPayment,
      filteredLicenseItems: this.getFilteredLicenseItems(data.filter)
    }).pipe(
      take(1),
      switchMap(({ isPostPayment }) => {
        const isRmm = data.filter && data.filter.length === 1 && data.filter[0] === LicenseType.RMM;
        const modalSettings = this.prepareAssignLicenseModalSettings(isPostPayment);
        const modalText = this.prepareAssignLicenseModalText(data, isRmm, isPostPayment);

        return from(this.modal.open(modalSettings, modalText)).pipe(map((result) => ({ result, isRmm, isPostPayment })));
      }),
      switchMap(({ result, isRmm, isPostPayment }) => {
        return result && data.expiredCurrent
          ? this.assignLicenseOrOpenDialog$(data, isRmm, isPostPayment)
          : this.assignLicenseIfConfirm$(result, data, isRmm, isPostPayment);
      }),
      catchError(() => {
        window && window.sessionStorage && window.sessionStorage.removeItem('btnClientId');
        return of(null);
      }),
      switchMap(() => this.licensesHasBeenAssigned$)
    );
  }

  private prepareAssignLicenseModalText(data: FilterLicenseAndAdminId, isRmm: boolean, isPostPayment: boolean): string {
    let text = `A license is required to use <b>${
      isRmm ? this.i18nextService.t('app:products.rmm') : this.i18nextService.t('app:products.remote_desktop')
    }</b>.`;
    if (this.ability.can('read', PermissionsEnum.Licenses)) {
      text +=
        ' ' +
        this.i18nextService.t('licenses:assignLicenseNow', {
          action: isPostPayment ? this.i18nextService.t('licenses:actions:assign') : this.i18nextService.t('licenses:actions:grant')
        });
    } else {
      text += '<br>Please contact your service provider.';
    }

    return text;
  }

  private prepareAssignLicenseModalSettings(isPostPayment: boolean): ModalSettings {
    const modalSettings: ModalSettings = {
      header: { title: 'License Required' },
      footer: { okButton: { show: false }, cancelButton: { text: 'Cancel', type: 'secondary' } }
    };

    if (this.ability.can('read', PermissionsEnum.Licenses)) {
      modalSettings.footer.okButton = {
        text: isPostPayment ? this.i18nextService.t('licenses:actions:assign') : this.i18nextService.t('licenses:actions:grant'),
        type: 'success'
      };
    }

    return modalSettings;
  }

  assignLicenseDialogueModal(data: FilterLicenseAndAdminId): BehaviorSubject<LicenseAssignToAdmin> {
    const isRmm = data.filter && data.filter.length === 1 && data.filter[0] === LicenseType.RMM;

    combineLatest({
      isPostPayment: this.isPostPayment,
      filteredLicenseItems: this.getFilteredLicenseItems(data.filter)
    })
      .pipe(take(1))
      .subscribe(({ isPostPayment }) => {
        if (data.expiredCurrent) {
          this.assignLicenseOrOpenDialog(data, isRmm, this.isTrialLicenseAvailable(), isPostPayment);
        } else {
          this.assignLicenseIfConfirm(true, data, isRmm, isPostPayment);
        }
      });
    return this.licensesHasBeenAssigned$;
  }

  private filterLicensesFuncForRM(adminId: string, licenses: LicenseSummary[]): Observable<LicenseSummary[]> {
    return of(true).pipe(
      switchMap(() => {
        if (this.myTrialInfo.value) licenses.forEach((x) => (x.canTrial = this.isTrialLicenseAvailable(x.licenseType)));
        return of(licenses);
      })
    );
  }

  private filterLicensesFunc(adminId: string, licenses: LicenseSummary[]): Observable<LicenseSummary[]> {
    return of(true).pipe(
      withLatestFrom(this.entities$ || of(null)),
      map((x) => {
        return x ? x[1] : [];
      }),
      switchMap((admins: AdministratorInCamelCase[]) => {
        const admin = admins ? admins.find((x) => x.id === adminId) : null;
        if (admin) return of(admin);
        else return this.getByKey(adminId);
      }),
      switchMap((admin: AdministratorInCamelCase) => {
        if (admin.license && admin.license.licenseType === LicenseType.RMM) {
          licenses.forEach((x) => (x.show = x.licenseType === LicenseType.RMM));
        }

        if (this.myTrialInfo.value)
          licenses.forEach((x) => {
            x.canTrial = this.isTrialLicenseAvailable(x.licenseType);
          });

        return of(licenses);
      })
    );
  }

  public isTFACanDisabled(id: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseHref}/${id}/two-factor-auth`);
  }

  public permissionsValuesWithoutUnused(permissions): boolean[] {
    const permissionsObj = Object.assign({}, permissions);

    this.ignoreElements.forEach((permission) => {
      if (Object.prototype.hasOwnProperty.call(permissionsObj, permission)) {
        delete permissionsObj[permission];
      }
    });

    return Object.values(permissionsObj);
  }

  public checkForDeepInstAccount(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseHref}/${id}/deepinstinct`);
  }

  public createDeepInstinctAccount(id: string): Observable<{ email: string; password: string }> {
    return this.http.post<{ email: string; password: string }>(`${this.baseHref}/${id}/deepinstinct`, '');
  }
}
