import { Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { SimplePlanStatus } from '@models/backup/simple-plan-status';
import { EditEmailsNotification, OldNotificationPerPlanSettings } from '@models/Notification';
import { PlanMode, PlanTypes, PlanTypesDisplay } from '@models/PlanTypes.enum';
import { RecurType } from '@models/RecurType.enum';
import { RemoteDeployPlanModel } from '@models/remote-deploy/remote-deploy-plan-model';
import { SAWithDestinations } from '@models/StorageAccountsWithDestinations';
import { StorageClass, StorageType, TypesForFFI, TypesForSynthetic, TypesForIntelligentRetention } from '@models/StorageType.enum';
import { StorageConnection } from '@models/storge-connections';
import { PlanResponseModel } from '@modules/wizards/models/base/plan-response-model';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { StepLoadInfo } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgentOptionsService } from '@services/agent-options.service';
import { RemoteDeployDataService } from '@services/data-services/remote-deploy-data.service';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { GuidEmpty, WizardComponent, WizardStep, WizardStepQueue } from 'mbs-ui-kit';
import { BehaviorSubject, concatMap, Observable, of, Subject } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { WizardHelpersSettings } from '../helpers/bases/base-for-steps-helper';
import { StepsDataFromPlanHelper } from '../helpers/steps-data-from-plan-helper/steps-data-from-plan-helper';
import { SupportMethodsForStepsFromPlan } from '../helpers/support-methods-for-steps-from-plan';
import { AzureAccessTierType } from '../models/advanced-options-models';
import { Storages } from '../models/base/base-models/plan-storages-model';
import { PlansWizardMode } from '../models/base/base-plan-models';
import { IncrementalData } from '../models/schedule-models';
import { RemoteManagementWizardsService } from '../services/remote-management-wizards.service';
import { WizardsServicesHubService } from '../services/wizards-services-hub.service';

@UntilDestroy()
@Directive()
export abstract class WizardBaseClass implements OnInit, OnDestroy {
  public currentStep: WizardStep;
  public FormModel: any;
  public isEdit = false;
  public isRDMode = false;
  public isGFSMode: boolean;
  public loadingPlan = false;
  public loadedPlan = null;
  public myPlan$: Subject<any> = new Subject();
  public orientations: 'horizontal' | 'vertical' = 'vertical';
  public planType: PlanTypes = 16;
  public planTypeText: string;
  public storageAccounts: SAWithDestinations[] = [];
  protected storage: StorageConnection;
  private advancedStorage: { azureType?: AzureAccessTierType; isStorageBadForFFI: boolean };
  public incrementalData: IncrementalData;
  public wizardFinish = false;
  public canBackupEmptyFolders = true;
  public invalidIntelligentRetentionStorage = false;
  public isFFIMode = false;
  public invalidFFIStorage: string;
  public prependStepText = this.i18nPipe.transform('wizards:prepend_steps_if_offline');
  public advancedTitle = this.i18nPipe.transform('wizards:advanced_options_title', { format: 'title' });
  public retentionTitle = this.i18nPipe.transform('wizards:retention_policy_title', { format: 'title' });
  public scheduleTitle = this.i18nPipe.transform('wizards:schedule_title', { format: 'title' });
  public compressionEncryptionTitle = this.i18nPipe.transform('wizards:compression_encryption_step_title', { format: 'title' });
  public stepQueue: WizardStepQueue = new WizardStepQueue();
  public loadingStepsArray: boolean[] = [];
  public mainService: RemoteManagementWizardsService;
  public rdService: RemoteDeployDataService;
  public agentOptionsService: AgentOptionsService;
  public backupFacade: ComputerBackupFacade;

  get showImmutability(): boolean {
    return this.canShowImmutabilityByStorage && !this.isFFIMode;
  }

  get showGFS(): boolean {
    return !this.isFFIMode;
  }

  get isNBF(): boolean {
    return this.mainService.isNBF;
  }

  get isLinux(): boolean {
    return this.mainService.isLinux;
  }

  get isRestore(): boolean {
    return this.mainService.isRestore;
  }

  get advancedOptionsNeedShow(): boolean {
    return (
      this.FormModel.advancedOptionsFlags &&
      Object.keys(this.FormModel.advancedOptionsFlags).some((k) => {
        return k !== 'SyntheticFullSettingPossible'
          ? this.FormModel.advancedOptionsFlags[k]
          : this.FormModel.advancedOptionsFlags[k] && this.planType !== PlanTypes.Plan;
      })
    );
  }

  get wizardTitle(): string {
    return `${this.isEdit ? 'Edit' : 'Create'} ${this.planTypeText || ''} ${this.mainService.compName ? 'for' : ''}`;
  }

  public get minPeriod(): number {
    const advancedSC = this.FormModel?.advancedOptionsStep?.StorageClass || this.FormModel?.advancedSettingsStep?.storageClass;
    if (advancedSC) {
      return this.minPeriodValues.StorageClass[advancedSC] || 0;
    }
    if (this.advancedStorage?.azureType) {
      return this.minPeriodValues.AzureStorage[this.advancedStorage.azureType] || 0;
    }

    return this.storage?.MinimumStorageDuration || this.minPeriodValues.Storage[this.storage?.StorageType] || 0;
  }

  protected get needSubscribeThroughPipe(): boolean {
    return !this.isRDMode || (this.isRDMode && this.isEdit);
  }
  protected canShowImmutabilityByStorage = false;

  defaultSettings(): WizardHelpersSettings {
    return {
      validVersionForSchedule: this.mainService.validVersionForSchedule,
      timeZoneOffset: this.mainService.timeZoneOffset,
      mode: this.mainService.mode,
      compName: this.mainService.compName,
      currentPrefix: this.mainService.currentPrefix,
      accounts: this.isRDMode ? this.storageAccounts : null,
      isRD: this.isRDMode,
      isNBF: this.isNBF,
      isOffline: this.mainService.isOffline,
      planType: this.planType,
      isLinux: this.isLinux,
      allowPrePostActions: this.mainService.allowPrePostActions.value
    };
  }

  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;

  private ability: AbilityService;
  private scheduledFromPlanAdded = false;
  private readonly notFullScheduleTypes = [PlanTypes.RestorePlan, PlanTypes.RestoreDiskImagePlan, PlanTypes.DataBasePlan];

  /**
   * The following parameters are set on the Agent side
   *         private const long WasabiDays = 90; // WEB analog StorageType.Wasabi
   *         private const long WasabiUBDays = 30; // WEB analog StorageType.CblWasabi
   *         private const long AWSDays = 30; // WEB analog StorageClass.STANDARD_IA and StorageClass.ONEZONE_IA
   *         private const long AWSGlacierDays = 90; // WEB analog StorageClass.GLACIER
   *       * private const long AWSGlacierIRDays = 90; // WEB analog StorageClass.GLACIER_IR
   *         private const long AWSGlacierDeepArchiveDays = 180; // WEB analog StorageClass.DEEP_ARCHIVE
   *         For Azure: Cool - 30 days, Archive - 180 days
   *
   * UB S3 is not included in the list of storages with a minimum retention period
   * StorageClass it is Storage, which was changed by Advanced Options step
   */
  private minPeriodValues = {
    Storage: {
      [StorageType.Wasabi]: 90,
      [StorageType.CblWasabi]: 30
    },
    StorageClass: {
      [StorageClass.STANDARD_IA]: 30,
      [StorageClass.ONEZONE_IA]: 30,
      [StorageClass.GLACIER]: 90,
      [StorageClass.GLACIER_IR]: 90,
      [StorageClass.DEEP_ARCHIVE]: 180
    },
    AzureStorage: {
      [AzureAccessTierType.Cool]: 30,
      [AzureAccessTierType.Archive]: 180
    }
  };

  private needCheckVMWizard(): boolean {
    return this.isEdit && (this.planType === PlanTypes.BackupVMware || this.planType === PlanTypes.BackupHyperV);
  }

  private existExcludesIntoVMwarePlan(plan): boolean {
    return plan?.VirtualMachinesEx?.some((m) => m.DiskInfo?.some((di) => di.Volumes?.some((v) => v.BackupOptions?.ExcludeRules?.length)));
  }

  protected constructor(services: WizardsServicesHubService, public i18nPipe: I18NextPipe, protected stepService: WizardStepsService) {
    this.ability = services.ability;
    this.mainService = services.mainService;
    this.rdService = services.rdService;
    this.agentOptionsService = services.agentOptionsService;
    this.backupFacade = services.backupFacade;

    this.setBaseData();

    if (this.isRDMode) {
      this.mainService.allowPrePostActions.next(true);
    }

    this.agentOptionsService
      .getComputerAgentOptions(this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (opts) => this.mainService.allowPrePostActions.next(!!opts?.backupValues?.allowPrePostActions)
      });
  }

  ngOnInit(): void {
    this.loadingPlan = true;

    if (!this.isRDMode) {
      return void this.mainService.Plan$.pipe(
        switchMap((plan) =>
          !plan ? this.backupFacade.getLegacyPlan(null, !this.mainService.hasAllRequiredDataToGetPlan).pipe(map(() => null)) : of(plan)
        ),
        untilDestroyed(this)
      ).subscribe((plan) => plan && this.setLoadedPlan(plan));
    }

    if (this.isEdit) return void this.loadDataIfRDAndEdit();

    this.setLoadedPlan();
  }

  protected initSubscribes(): void {
    if (this.needSubscribeThroughPipe) {
      this.myPlan$
        .pipe(
          first((p) => !!p),
          untilDestroyed(this)
        )
        .subscribe((plan) => plan && this.updateStepData(plan));

      return;
    }

    this.updateStepData(new PlanResponseModel(), true);
  }

  protected abstract updateStepData(plan, isDefault?: boolean): void;

  loadDataIfRDAndEdit(): void {
    this.updateStorages();
    this.mainService.Plan$.pipe(untilDestroyed(this)).subscribe((plan) => {
      if (!plan) {
        const savedPlan = this.mainService.plansArray$.value.find((p) => p.plan.ID === this.mainService.planId);
        setTimeout((_) => this.mainService.Plan$.next(savedPlan), 100);
        return;
      }
      this.setLoadedPlan(plan);
    });
  }

  setBaseData(): void {
    this.isRDMode = this.mainService.wizardMode === PlansWizardMode.forRD;
    this.isEdit = this.mainService.mode === PlanMode.edit;
    this.planType = this.mainService.type;
    this.planTypeText = PlanTypesDisplay[this.mainService.type];
    this.mainService.password.next('');
  }

  setLoadedPlan(plan = null): void {
    if (this.needCheckVMWizard()) {
      if (!plan?.plan?.IsArchive) {
        this.mainService.openOnOldPageAndCloseAndReset();
        return;
      }
      if (+this.mainService.backupVersionUpdated.substring(0, 3) < 761) {
        this.mainService.closeAndReset();
        this.mainService.openErrorVersionModal();
        return;
      }
      if (+this.mainService.backupVersionUpdated.substring(0, 3) < 783 && this.existExcludesIntoVMwarePlan(plan?.plan)) {
        this.mainService.closeAndReset();
        this.mainService.openErrorVersionModal(true, this.i18nPipe.transform('wizards:virtual_wizards_error_fields_exclude_text_alert'));
        return;
      }
      if (this.planType === PlanTypes.BackupHyperV && plan?.plan?.ClusterBackup) {
        this.mainService.closeAndReset();
        this.mainService.openErrorVersionModal(true);
        return;
      }
    }
    if (this.mainService.needShowErrorVersionModalForLinux(plan?.plan?.IsArchive)) {
      this.mainService.showErrorVersionModalForLinux();
      return;
    }

    this.setStoragesAndLoadedPlan(plan);
  }

  setStoragesAndLoadedPlan(plan): void {
    if (plan) {
      if (!plan.Storages && this.FormModel.Storages.CloudStorages && this.FormModel.Storages.LocalStorages) {
        plan.Storages = {} as Storages;
        plan.Storages.CloudStorages = this.FormModel.Storages.CloudStorages;
        plan.Storages.LocalStorages = this.FormModel.Storages.LocalStorages;
      }
      if (this.isEdit && plan.plan) this.mainService.isNBF = !!plan.plan.IsArchive;
      this.myPlan$.next(plan);
    }
    this.loadingPlan = false;
    this.isGFSMode = this.FormModel?.retentionPolicyStep?.GFSPolicySettings?.enabled;
    this.incrementalData = this.FormModel.scheduleStep?.ScheduleType === 'ffi' ? this.FormModel.scheduleStep?.incrementalData : null;
    if (this.isRDMode) setTimeout((_) => this.updateAvailablePlans(plan ? plan.plan.ID : ''));
  }

  ngOnDestroy(): void {
    setTimeout((_) => {
      this.mainService.planNotification = new OldNotificationPerPlanSettings();
      this.mainService.Plan$.next(null);
    });
  }

  updateStorages(): void {
    this.rdService.SAWithDestinations$.pipe(untilDestroyed(this)).subscribe((accounts: SAWithDestinations[]) => {
      this.storageAccounts = accounts;
      const storagesObject = SupportMethodsForStepsFromPlan.getStoragesFromStorageAccounts(accounts);
      if (!this.isRDMode && this.isNBF) SupportMethodsForStepsFromPlan.getFilteredStoragesByTypes(storagesObject, [StorageType.OpenStack]);
      this.FormModel.Storages = {
        ...storagesObject,
        LocalStorages:
          this.isRDMode && this.isLinux
            ? storagesObject.LocalStorages.filter((s) => s.StorageType !== StorageType.LocalFS)
            : storagesObject.LocalStorages
      };
    });
  }

  updateAvailablePlans(id = ''): void {
    const keys = Object.keys(this.mainService.AvailablePlansForRD$.value[this.mainService.rdConfigurationId] || {});
    const AMPlans: any = {};
    keys.forEach((planId) => {
      AMPlans[this.mainService.AvailablePlansForRD$.value[this.mainService.rdConfigurationId][planId].plan.ID] =
        this.mainService.AvailablePlansForRD$.value[this.mainService.rdConfigurationId][planId].plan.Name;
    });
    this.FormModel.AvailableMachinePlans = StepsDataFromPlanHelper.updateAvailableMachinePlans(AMPlans, id);
    if (this.isRDMode) {
      this.FormModel.AvailableMachinePlans = this.FormModel.AvailableMachinePlans.filter((plan) => this.validateAvailablePlan(plan, id));
    }
  }

  validateAvailablePlan(plan: any, id: string, plans = this.mainService.plansArray$.value): boolean {
    const currentIdx: number = plans.findIndex((p) => p.plan.ID === plan.value);
    const foundPlan = ~currentIdx && plans[currentIdx].plan;
    if (~currentIdx && foundPlan.NextExectutionPlan === id) return false;
    return foundPlan && foundPlan.NextExectutionPlan && foundPlan.NextExectutionPlan !== GuidEmpty
      ? this.validateAvailablePlan(
          { value: foundPlan.NextExectutionPlan },
          id,
          plans.filter((_, idx) => idx !== currentIdx)
        )
      : true;
  }

  setLoadedPlanAndRunChainRequestsForBackup(plan): void {
    this.loadedPlan = plan;
    if (this.isEdit && !this.isRDMode) this.mainService.GetPlanNotificationOptions();
    else if (!this.isRDMode) this.mainService.getGlobalNotification();
  }

  restoreOnceHandler(event): void {
    if (event) this.FormModel.scheduleStep.ScheduleType = 'noschedule';
  }

  emailsNotificationChangeHandler(event: EditEmailsNotification): void {
    this.mainService.planNotification[event.type].Emails = event.emails.map((e) => e.email);
  }

  hybridChangedToDefaultHandler(event): void {
    if (
      event &&
      !this.isLinux &&
      this.FormModel.advancedOptionsStep &&
      !(this.FormModel.advancedOptionsStep.SyntheticFull === null || this.FormModel.advancedOptionsStep.SyntheticFull === undefined)
    )
      this.FormModel.advancedOptionsStep.SyntheticFull = true;
  }

  changeStorageHandler(connection: StorageConnection): void {
    if (isNil(connection)) {
      delete this.storage;

      if (this.FormModel.advancedOptionsFlags) {
        this.FormModel.advancedOptionsFlags = {
          ...this.FormModel.advancedOptionsFlags,
          UseS3AccelerationPossible: false,
          StorageClassSettingPossible: false,
          AzureAccessTierType: false,
          SyntheticFullSettingPossible: false
        };
      }

      return;
    }

    this.storage = connection;
    this.invalidIntelligentRetentionStorage = !TypesForIntelligentRetention.isSupported(connection);

    if (this.isNBF && this.FormModel && this.FormModel.whereBackupStep && this.FormModel.Storages.CloudStorages.length) {
      this.canShowImmutabilityByStorage = SupportMethodsForStepsFromPlan.needShowImmutability(
        this.FormModel.Storages.CloudStorages,
        this.FormModel.whereBackupStep.ConnectionID
      );
    }

    if (this.FormModel.advancedOptionsFlags) {
      this.setAdvancedOptionsFlags();
      if (!this.isNBF)
        this.canBackupEmptyFolders = SupportMethodsForStepsFromPlan.updateCanBackupEmptyFolders(connection.StorageType, this.planType);
    }

    this.updateInvalidFFIStorage(connection);
  }

  changeAdvancedStorageHandler(event: { azureType?: AzureAccessTierType; isStorageBadForFFI: boolean }) {
    this.advancedStorage = event;

    this.updateInvalidFFIStorage(this.storage);
  }

  protected updateInvalidFFIStorage(storage: StorageConnection): void {
    if (!storage) {
      delete this.invalidFFIStorage;

      return;
    }

    const isStorageSupported = TypesForFFI.isSupported(storage);

    if (this.isNBF && (!isStorageSupported || this.advancedStorage?.isStorageBadForFFI)) {
      this.invalidFFIStorage = storage.DisplayName;

      return;
    }

    delete this.invalidFFIStorage;
  }

  setAdvancedOptionsFlags(): void {
    const storageType = this.storage?.StorageType;

    this.FormModel.advancedOptionsFlags.UseS3AccelerationPossible = storageType === StorageType.AmazonS3;
    this.FormModel.advancedOptionsFlags.StorageClassSettingPossible = storageType === StorageType.AmazonS3;
    this.FormModel.advancedOptionsFlags.AzureAccessTierType = storageType === StorageType.Azure;

    if (this.FormModel.whereBackupStep && !this.FormModel.whereBackupStep.IsHybridBackup) {
      const storage =
        this.FormModel.Storages &&
        this.FormModel.Storages.CloudStorages.length &&
        this.FormModel.Storages.CloudStorages.find((i) => i.ID === this.FormModel.whereBackupStep.ConnectionID);

      if (this.isNBF) {
        this.FormModel.advancedOptionsFlags.SyntheticFullSettingPossible =
          !this.isLinux &&
          !this.FormModel.advancedOptionsStep.SyntheticFull &&
          TypesForSynthetic.isSupported({ StorageType: storageType, S3CompatibleSynthetic: storage?.S3CompatibleSynthetic });
      } else {
        this.FormModel.advancedOptionsFlags.SyntheticFullSettingPossible =
          (!this.isLinux && storage?.S3CompatibleSynthetic) || TypesForSynthetic.CBF.isSupported({ StorageType: storageType });
      }
    }

    this.FormModel.advancedOptionsFlags = { ...this.FormModel.advancedOptionsFlags };
  }

  scheduleTypeChangeHandler(event: string): void {
    this.isFFIMode = event === 'ffi';

    if ((!this.isNBF || this.isRestore) && this.loadedPlan) {
      const plan = this.loadedPlan.plan || this.loadedPlan.Plan;

      if (event === 'recurring') this.updateAdvancedSchedule(plan);
      if (event === 'predefined') this.updateSimpleScheduleStep(plan);
    }
  }

  updateAdvancedSchedule(plan): void {
    let validSchedule: boolean;

    if (this.notFullScheduleTypes.includes(this.planType)) {
      validSchedule = plan?.Schedule?.Enabled && !isNil(plan.Schedule.Hour);
    } else {
      validSchedule = plan?.ForceFullSchedule?.Enabled && !isNil(plan.ForceFullSchedule.Hour);
    }

    if (this.isEdit && !this.scheduledFromPlanAdded && validSchedule) {
      this.FormModel.scheduleAdvancedStep = StepsDataFromPlanHelper.updateAdvancedScheduleBasedOnMode(
        this.loadedPlan.plan || this.loadedPlan.Plan,
        !this.isEdit,
        this.planType,
        this.isLinux
      );
      this.scheduledFromPlanAdded = true;
    } else this.FormModel.scheduleAdvancedStep = StepsDataFromPlanHelper.advancedScheduleDefault(this.planType, this.isLinux);
  }

  updateSimpleScheduleStep(plan): void {
    if (
      this.isEdit &&
      !this.isLinux &&
      (plan?.Schedule?.RecurType === RecurType.Periodically || plan?.ForceFullSchedule?.RecurType === RecurType.Periodically)
    ) {
      this.updateAdvancedSchedule(plan);
      this.FormModel.simpleScheduleStep = StepsDataFromPlanHelper.updateSimpleSchedule(plan, this.planType);
    }
  }

  setIncrementalData(data: IncrementalData): void {
    this.incrementalData = data;
  }

  forceBackStepWizard(name: string, title: string): void {
    if (this.wizard.steps) {
      const stepsArray = this.wizard.steps.toArray();
      const currentStepIndex = stepsArray.findIndex((s) => s.title === title);
      const nextStepIndex = stepsArray.findIndex((s) => s.title === this.currentStep.title);

      if (nextStepIndex !== -1 && nextStepIndex === currentStepIndex) {
        this.wizard.changeStep(-1);
        this.wizard.currentStep = stepsArray[nextStepIndex - 1];
      }
    }
  }

  forceNextStepWizard(isSave: boolean, name: string, title: string): void {
    if (!this.wizard.steps) {
      return;
    }

    if (isSave) {
      this.forceValidStep(name);
      this.wizard.handleSave();

      return;
    }

    const stepsArray = this.wizard.steps.toArray();
    const currentStepIndex = stepsArray.findIndex((s) => s.title === title);
    const nextStepIndex = stepsArray.findIndex((s) => s.title === this.currentStep.title);

    if (nextStepIndex !== -1 && nextStepIndex === currentStepIndex) {
      this.forceValidStep(name);
      this.wizard.changeStep(1);

      return;
    }

    this.forceValidStep(name);
  }

  forceValidStep(name: string): void {
    this.FormModel[name].valid = true;
    this.wizard.currentStep.valid = true;
  }

  updateBucket(plan): void {
    let bucketName = '';

    for (let i = 0; i < this.storageAccounts.length; i++) {
      if (!bucketName) {
        for (let j = 0; j < this.storageAccounts[i].buckets.length; j++) {
          const bucket = this.storageAccounts[i].buckets[j];

          if (bucket.id === this.FormModel.whereBackupStep.ConnectionID) {
            bucketName = bucket.bucketName;

            break;
          }
        }
      } else break;
    }

    plan.Bucket = bucketName || plan.Bucket;
  }

  generatePlanForRDFormat(plan): RemoteDeployPlanModel {
    const newPlan = {
      AvailableFeatures: {},
      AvailableMachinePlans: null,
      DiskVolumes: null,
      IsLinux: this.isLinux,
      OverdueAfterSuccess: null,
      plan,
      PlanType: this.planType,
      Storages: null
    } as RemoteDeployPlanModel;

    this.mainService.updatePlanTextElements(newPlan, this.rdService.allStorageAccounts);
    this.mainService.updatePlanTextRetention(newPlan);
    this.mainService.setValidForRDStatuses(newPlan);

    return newPlan;
  }

  savePlan(newPlan): void {
    if (this.wizardFinish) {
      return;
    }

    if (!this.isEdit) newPlan.IsArchive = this.isNBF;

    if (this.isRDMode) {
      this.updateBucket(newPlan);
      const index = this.storageAccounts.findIndex((account) => account.buckets.some((bucket) => bucket.id === newPlan.ConnectionID));
      if (index !== -1) newPlan.SGAccountID = this.storageAccounts[index].id;
      this.mainService.updateArrayOfPlans(!this.isEdit ? this.generatePlanForRDFormat(newPlan) : newPlan);
      this.wizardFinish = true;

      return;
    }

    this.saveIsNotRD(newPlan);
  }

  private getPlanStatus(): Observable<SimplePlanStatus> {
    if (!this.isEdit) {
      return new BehaviorSubject<SimplePlanStatus>(SimplePlanStatus.Unknown).asObservable();
    }

    const dataExtractor = this.backupFacade.plans$.pipe(map((plans) => plans.find((plan) => plan.id === this.mainService.planId)?.status));

    return this.backupFacade.getComputerPlans(this.mainService.hid).pipe(concatMap(() => dataExtractor));
  }

  private stopPlan(): Observable<boolean> {
    return this.backupFacade.stopPlan({ hid: this.mainService.hid }, this.mainService.planId);
  }

  saveIsNotRD(plan): void {
    const final = () => {
      this.backupFacade
        .saveLegacyPlan(plan)
        .pipe(catchError(() => of(false)))
        .subscribe((result) => this.updatesAfterSave(result, plan));
    };

    this.loadingPlan = true;

    this.getPlanStatus()
      .pipe(take(1))
      .subscribe((status) => {
        if (status !== SimplePlanStatus.Running) {
          this.loadingPlan = false;

          return void final();
        }

        this.mainService.openErrorRunningPlan().subscribe({
          next: (answer) => {
            this.loadingPlan = false;

            if (!answer) {
              return;
            }

            this.stopPlan().subscribe(() => final());
          },
          error: () => {
            this.wizard.spinnerShow = false;
            this.loadingPlan = false;
          }
        });
      });
  }

  updatesAfterSave(result, newPlan): void {
    if (result && this.planType !== PlanTypes.CloudVMBackupEC2Plan) {
      const notification = { ...this.mainService.planNotification, UserId: this.mainService.userId, PlanId: newPlan.ID };

      if (this.ability.can('read', 'Monitoring')) {
        const overdue = {
          enabled: this.FormModel.scheduleStep.overdueAfterSuccessEnabled,
          amount: this.FormModel.scheduleStep.overdueAfterSuccessAmount || 7,
          period: !(
            this.FormModel.scheduleStep.overdueAfterSuccessPeriod === null ||
            this.FormModel.scheduleStep.overdueAfterSuccessPeriod === undefined
          )
            ? this.FormModel.scheduleStep.overdueAfterSuccessPeriod
            : 1
        };

        // TODO NESTED SUBSCRIPTIONS
        this.mainService
          .updateOverdueAlert(overdue, newPlan.Name)
          .pipe(first())
          .subscribe({
            next: (result) => {
              if (result && (this.mainService.isProvider || this.ability.can('read', 'Notification'))) {
                this.mainService.SavePlanNotification(notification).pipe(first()).subscribe();
              }
            },
            error: () => console.error('Plan overdue settings has not been saved. Something went wrong.')
          });
      } else this.mainService.SavePlanNotification(notification).pipe(first()).subscribe();
    }

    this.wizardFinish = true;
  }

  loadInfoHandler(event: StepLoadInfo): void {
    this.loadingStepsArray[event?.loading ? 'push' : 'shift'](event?.loading ? true : undefined);

    this.loadingPlan = !!this.loadingStepsArray.length;

    this.loadInfoHandlerFinally(event);
  }

  protected abstract loadInfoHandlerFinally(event?: StepLoadInfo): void;
}
