export default class OAuthData {
  client_id: 'mbs_provider_spa' | 'mbs_provider_oidc' = 'mbs_provider_spa';
  grant_type: 'password' | 'refresh_token';
  readonly scope = 'mbs_provider_api offline_access';
  refresh_token?: string;
  username?: string;
  password?: string;

  constructor(obj?: Partial<OAuthData>) {
    Object.assign(this, obj);
  }
}
