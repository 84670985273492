<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:plan_name_title' | i18next: { format: 'title', type: planTypeText } }}
    </h4>
  </header>

  <div class="mbs-wizard_form-content">
    <div class="text-center pb-4 mb-2">
      <img class="w-75"
           [src]="imageSrc"
           alt="" />
    </div>

    <mbs-form-group *ngIf="isRestore">
      <mbs-radio formControlName="SavePlanInCloud"
                 name="SaveBackupPlan"
                 class="mb-3"
                 [ngClass]="elementsSelector.css.planNameRestoreRadio"
                 [label]="'wizards:run_restore_once' | i18next"
                 (change)="changeSavePlanHandler($event)"
                 [descriptionClasses]="'mt-1'"
                 [descriptionOutside]="false"
                 [id]="'save-backup-plan-radio-0'"
                 [value]="false">
        <ng-template radioDescription>
          <span *ngFor="let item of runRestoreOnceSubtitle;let last = last"
                class="text-xs">
            {{item}}<br *ngIf="!last">
          </span>
        </ng-template>
      </mbs-radio>
      <mbs-radio formControlName="SavePlanInCloud"
                 name="SaveBackupPlan"
                 [ngClass]="elementsSelector.css.planNameRestoreRadio"
                 [label]="'wizards:save_restore_plan' | i18next"
                 (change)="changeSavePlanHandler($event)"
                 [descriptionClasses]="'text-xs mt-1'"
                 [descriptionOutside]="false"
                 [id]="'save-backup-plan-radio-1'"
                 [value]="true">
        <ng-template radioDescription>
          <div class="text-xs mb-3">{{'wizards:save_restore_plan_desc' | i18next}}</div>
          <mbs-input [id]="'planName'"
                     [validationWhitespace]="true"
                     [label]="'wizards:plan_name' | i18next"
                     [placeholder]="'wizards:plan_name_placeholder' | i18next"
                     name="planName"
                     formControlName="Name"></mbs-input>
        </ng-template>
      </mbs-radio>
    </mbs-form-group>

    <ng-container *ngIf="!isRestore">
      <mbs-form-group>
        <mbs-input [id]="'planName'"
                   [validationWhitespace]="true"
                   [label]="'wizards:plan_name' | i18next"
                   [placeholder]="'wizards:plan_name_placeholder' | i18next"
                   name="planName"
                   formControlName="Name"></mbs-input>
      </mbs-form-group>

      <ng-container *ngIf="!isNBF">
        <mbs-form-group>
          <mbs-checkbox [label]="'wizards:save_to_storage' | i18next"
                        [id]="'saveToStorage'"
                        [ngClass]="elementsSelector.css.planNameBackupCheckbox"
                        formControlName="SavePlanInCloud"></mbs-checkbox>
        </mbs-form-group>

        <mbs-alert class="mb-0 mt-3"
                   [type]="mbsPopupType.info"
                   [icon]="true">
          {{'wizards:plan_name_alert' | i18next}}
        </mbs-alert>
      </ng-container>
    </ng-container>
  </div>
</section>
