import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import Administrator from '@models/Administrator';
import { AdministratorLicense } from '@models/licenses/administrator-license';
import { LicenseSummary } from '@models/licenses/license-summary';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdministratorService } from '@services/administrator.service';
import { POST_PAYMENT_TOKEN } from '@shared/components/licenses/tokens/post-payment.token';
import { AbilityService } from 'ability';
import { ModalComponent, SharedPersistentStateEnum, SortEvent, TableHeader } from 'mbs-ui-kit';
import { Observable, of, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LicensesManagingService } from '../../models/licenses-managing-service';
import { AssignLicenseDialogueData } from './assign-license-dialogue-data';

@UntilDestroy()
@Component({
  selector: 'mbs-assign-license-dialogue',
  templateUrl: './assign-license-dialogue.component.html',
  styles: []
})
export class AssignLicenseDialogueComponent implements OnInit {
  public orderBy: SortEvent;
  public settings: AssignLicenseDialogueData = null;

  public licensesService: LicensesManagingService;

  public licenses: LicenseSummary[] = [];
  private entityId = '';

  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;

  public headers: TableHeader[] = [
    { name: 'Type', gridColSize: '5fr' },
    { name: 'Available', class: '-end', gridColSize: '3fr' },
    { name: '', class: '-end', gridColSize: '2fr' }
  ];
  private user: Administrator;
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private ability: AbilityService,
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>
  ) {}

  ngOnInit(): void {
    this.isPostPayment.pipe(take(1)).subscribe((isPostPayment) => {
      if (isPostPayment) {
        const index = this.headers.findIndex((header) => header.name === 'Available');
        this.headers.splice(index, 1);
      }
    });

    if (this.baseModal.data) {
      const data = this.baseModal.data as AssignLicenseDialogueData;
      this.settings = data;
      this.licensesService = data.service;
      this.entityId = data.entityId;

      if (this.settings.showTrials) {
        this.headers[this.headers.length - 1].name = 'License';
        this.headers.push({ name: 'Trial', class: '-end', gridColSize: '2fr' });
      }
    }
    if (!this.licensesService) {
      throw new Error(
        "You must provide licenses service that implements the @LicensesManagingService interface in modal settings 'data' property to use this dialogue"
      );
    }
    if (!this.entityId) {
      throw new Error('You must provide entityId in the data property that is being set in the modal settings');
    }
    this.licensesService
      .getAvailableLicenseSummaries()
      .pipe(
        switchMap((licenses) => {
          if (this.settings.licenseTypesFilter && this.settings.licenseTypesFilter.length > 0) {
            licenses = licenses.filter((x) => this.settings.licenseTypesFilter.includes(x.licenseType));
          }
          if (this.settings.filterLicensesFunc$) return this.settings.filterLicensesFunc$(this.entityId, [...licenses]);
          return of(licenses);
        })
      )
      .subscribe({
        next: (licenses) => {
          this.licenses = licenses;
          if (!licenses.some((l) => l.canTrial)) this.settings.showTrials = false;
        }
      });
    this.cdr.detectChanges();
  }

  handleSort(sortEvent: SortEvent): void {
    this.orderBy = sortEvent;
  }

  grantLicense(license: AdministratorLicense, trial = false): void {
    (this.licensesService as AdministratorService)
      .grantLicense(license.licenseType, this.entityId, trial)
      .pipe(untilDestroyed(this))
      .subscribe((license) => {
        this.baseModal.save(license);
      });
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }

  buyNewClickHandler(): void {
    // quick hack for now, while we have only administrators licenses with this dialogue..
    // elsewise TODO: it should be calling service method that returns observable or Promise that will emit value and close
    // after the license is bought and returned here
    const licenses = ['rd'];
    if (this.ability.can('read', 'MBS')) {
      licenses.push('rmm');
    }
    (this.licensesService as AdministratorService).buyLicense(licenses);
  }
}
