import { IBBFormModel } from '@modules/wizards/models/ibb/ibb-form-model';
import { AdvancedFilterStepValue } from '../advanced-filters-models';
import { WhatBackupTreeStepValue } from '../what-backup-tree-model';

export class BackupFileFormModel extends IBBFormModel {
  advancedFilterStep: AdvancedFilterStepValue = {
    backupAllFiles: 0,
    includeMask: '',
    excludeMask: '',
    skipFolders: false,
    BackupEmptyFolders: false,
    skipFolderName: '',
    ignoreSystemAndHidden: true,
    valid: true
  };
  whatBackupTreeStep: WhatBackupTreeStepValue = {
    includeFolders: [],
    excludeFolders: [],
    valid: true
  };

  constructor(public isNBF = false, public isLinux = false) {
    super(isNBF, isLinux);

    this.advancedOptionsStep = { valid: true };
    this.advancedOptionsFlags = {
      AlwaysUseVSSPossible: !isLinux,
      BackupNTFSPermissionsPossible: !isLinux,
      StorageClassSettingPossible: false,
      UseFastNtfsScanPossible: !isLinux,
      UseS3AccelerationPossible: false,
      UseVSSPossible: !isLinux,
      ExcludePossible: false,
      AzureAccessTierType: false,
      SyntheticFullSettingPossible: !isLinux,
      IgnoreBadSectorsPossible: false,
      DisableVSSPossible: false,
      PrefetchBlockCountPossible: false,
      BlockSizeSettingPossible: false
    };

    if (!isNBF) {
      this.retentionPolicyStep = {
        RetentionUseDefaultSettings: true,
        keepNumberOfVersions: false,
        RetentionNumberOfVersions: 3,
        deleteVersionsOlderThan: false,
        deleteVersionsOlderThanCount: 1,
        deleteVersionsOlderThanPeriod: 2,
        fromTimeSelected: 0,
        alwaysKeepLastVersion: true,
        deleteIfLocallyDeleted: false,
        deleteAfter: 30,
        HybridRetentionPolicy: {
          RetentionUseDefaultSettings: true,
          keepNumberOfVersions: false,
          RetentionNumberOfVersions: 1,
          deleteVersionsOlderThan: false,
          deleteVersionsOlderThanCount: 1,
          deleteVersionsOlderThanPeriod: 2,
          fromTimeSelected: 0,
          alwaysKeepLastVersion: true
        },
        valid: true
      };
      if (!isLinux) {
        this.retentionPolicyStep.doNotShowWarning = false;
        this.retentionPolicyStep.delayPurgeForEnabled = false;
        this.retentionPolicyStep.delayPurgeFor = 3;
        this.retentionPolicyStep.delayPurgeForSelect = 0;
        this.retentionPolicyStep.HybridRetentionPolicy.delayPurgeForEnabled = false;
        this.retentionPolicyStep.HybridRetentionPolicy.delayPurgeFor = 3;
        this.retentionPolicyStep.HybridRetentionPolicy.delayPurgeForSelect = 0;

        this.scheduleAdvancedStep.ForceFullApplyDiffSizeCondition = false;
        this.scheduleAdvancedStep.ForceFullDiffSizeCondition = 50;
      }
    } else {
      this.archiveConsistencyStep = { useFullConsistencyCheck: true, valid: true };
      if (!isLinux) {
        this.advancedOptionsStep.KeepEFSFilesAsIs = false;
        this.advancedOptionsStep.SyntheticFull = true;
      }
    }

    if (!isLinux) {
      this.compressionEncryptionStep.UseFileNameEncryption = false;

      this.advancedOptionsStep.useSystemVSSProvider = false;
      this.advancedOptionsStep.BackupNTFSPermissions = false;
      this.advancedOptionsStep.UseFastNtfsScan = true;
      this.advancedOptionsStep.SaveDeletedDataInCloud = false;
      this.advancedOptionsStep.AlwaysUseVSS = true;

      this.advancedFilterStep.backupFilesModified = false;
      this.advancedFilterStep.daysAgo = 14;
      this.advancedFilterStep.date = this.date;
      this.advancedFilterStep.time = this.time;
      this.advancedFilterStep.doNotBackup = false;
      this.advancedFilterStep.fileSize = 1024;
      this.advancedFilterStep.SkipInUseFiles = false;
      this.advancedFilterStep.ignoreOnDemand = true;
    }
    this.whatBackupStep = null;
  }
}
