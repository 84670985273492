import { Injectable } from '@angular/core';
import { SmartSearchTemplateName } from '@modules/smart-search-templates/models/smart-search-templates-models';
import { Store } from '@ngrx/store';
import { SmartSearchModel } from 'mbs-ui-kit';
import { SmartSearchValidationState } from 'mbs-ui-kit/smart-search/models';
import { getSmartSearchTagValues } from 'mbs-ui-kit/smart-search/models/smart-search-model';
import { BehaviorSubject, combineLatest, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { Tag } from '../models/tag';
import { TagsService } from '../services/tags.service';
import { ComputerTagsStoreActions, ComputerTagsStoreSelectors } from '../store/computerTags';

@Injectable({
  providedIn: 'root'
})
export class ComputerTagsFacade {
  public allTags$ = this.store.select(ComputerTagsStoreSelectors.selectAll);
  public selectedTag$ = this.store.select(ComputerTagsStoreSelectors.selectSelectedTag);
  public tagLoaded$ = this.store.select(ComputerTagsStoreSelectors.selectLoaded);
  public tagById$ = (id: number) => this.store.select(ComputerTagsStoreSelectors.selectById(id));

  private tagLoading$ = this.store.select(ComputerTagsStoreSelectors.selectLoading);
  private updating$ = new BehaviorSubject<boolean>(false);

  public loading$ = combineLatest([this.tagLoading$, this.updating$]).pipe(map(([loading, updating]) => loading || updating));

  constructor(private store: Store, private tagsService: TagsService) {}

  /**
   * Load computer tags by id in Computer Tags store
   * @param {{id: string, quiet: boolean, force: boolean}} params Parameters
   * @param {number} params.id Computer Tag Id
   * @param {boolean} params.quiet Quiet loading of tags. Without  changes in 'loading' store field (without spinner)
   * @param {boolean} params.force Force loading data from the backend (even if the data is already in store)
   * @return {void}
   */
  loadTagById(params: { id: number; quiet?: boolean; force?: boolean }): Observable<Tag> {
    this.store.dispatch(ComputerTagsStoreActions.loadComputerTagById(params));

    return this.tagById$(params.id);
  }

  loadAllTags({ force = false, quiet = false, calculateCount = true } = {}): Observable<Tag[]> {
    this.store.dispatch(ComputerTagsStoreActions.loadAllComputerTags({ force, quiet, calculateCount }));

    return this.allTags$;
  }

  setSelected(selected: number): Observable<Tag> {
    this.store.dispatch(ComputerTagsStoreActions.setSelected({ selected }));

    return this.selectedTag$;
  }

  create(tag: Partial<Tag>): Observable<Tag> {
    return this.upsert(tag);
  }

  update(tag: Tag): Observable<Tag> {
    return this.upsert(tag, true);
  }

  private upsert(tag: Partial<Tag>, isUpdate = false): Observable<Tag> {
    this.updating$.next(true);
    return (isUpdate ? this.tagsService.updateTag(tag) : this.tagsService.createTag(tag)).pipe(
      tap((result) => this.store.dispatch(ComputerTagsStoreActions.setComputerTags({ tags: [result] }))),
      finalize(() => this.updating$.next(false))
    );
  }

  delete(id: number): Observable<null> {
    this.updating$.next(true);
    return this.tagsService.deleteTag(id).pipe(
      tap(() => this.store.dispatch(ComputerTagsStoreActions.deleteComputerTag({ id }))),
      finalize(() => this.updating$.next(false))
    );
  }

  isTagsFilterValid(search: SmartSearchModel): Observable<SmartSearchValidationState> {
    return of(getSmartSearchTagValues(SmartSearchTemplateName.Tag, search)).pipe(
      switchMap((searchTags) =>
        searchTags.length
          ? this.allTags$.pipe(
              map((allTags) => allTags.map((tag) => tag.name.toLowerCase())),
              map((allTags) => searchTags.every((tagName) => allTags.includes(tagName.toLowerCase()))),
              map((result) => (result ? SmartSearchValidationState.Valid : SmartSearchValidationState.Invalid))
            )
          : of(SmartSearchValidationState.Unknown)
      )
    );
  }
}
