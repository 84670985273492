import { HostInfoRow } from '@components/computers-shared/get-general-host-Info';
import { AvailablePlanTypes } from '@models/backup/available-plan-types';
import { PlanInfo } from '@models/backup/plan-info-model';
import { StorageAccountCamelCase } from '@models/StorageAccount';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface ComputerBackupData<T> {
  data: T;
  loading: boolean;
  loaded: boolean;
}

export interface PlanStatusData {
  saving: boolean;
  running: boolean;
}

export type PlansStatuses = {
  [id: string]: PlanStatusData;
};

export interface ComputerDetails {
  id: string;
  hostInfo?: ComputerBackupData<HostInfoRow[]>;
  destinations?: ComputerBackupData<StorageAccountCamelCase[]>;
  plans?: ComputerBackupData<PlanInfo[]>;
  availablePlans?: ComputerBackupData<AvailablePlanTypes>;
  timeZoneOffset?: number;
}

export type ComputersBackupState = EntityState<ComputerDetails> & {
  selected: string;
  plansStatuses?: PlansStatuses;
  syncProcess: boolean;
};

export const computersBackupAdapter: EntityAdapter<ComputerDetails> = createEntityAdapter<ComputerDetails>();

export const initialComputersBackupState: ComputersBackupState = computersBackupAdapter.getInitialState({
  selected: null,
  plansStatuses: {},
  syncProcess: false
});
