<mbs-online-access-header></mbs-online-access-header>
<main class="mbs-container">
  <div class="mbs-container-inner">
    <div class="mbs-container-content">
      <mbs-loader *ngIf="isLoading$ | async"></mbs-loader>
      <ng-container *ngIf="(isLoading$ | async) === false">

        <router-outlet (activate)="handleActiveRoute($event)"
                       (deactivate)="handleActiveRoute()"></router-outlet>
      </ng-container>
    </div>
    <mbs-toasts data-position="bottom-right"></mbs-toasts>
    <mbs-footer></mbs-footer>
  </div>
</main>
