import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RemoteDeployConfiguration } from '@models/remote-deploy/remote-deploy-configuration';
import { DefaultDataServiceConfig, EntityDataModule, EntityMetadataMap, PropsFilterFnFactory } from '@ngrx/data';
import Administrator from '../models/Administrator';
import Brand from '../models/Brand';
import Computer from '../models/Computer';

const apiRoot = 'api';
const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: apiRoot,
  entityHttpResourceUrls: {
    Administrator: { entityResourceUrl: apiRoot + '/administrators/', collectionResourceUrl: apiRoot + '/administrators/' },
    Domain: {
      entityResourceUrl: apiRoot + `/apps/domains/`,
      collectionResourceUrl: apiRoot + `/apps/domains/`
    },
    StorageAccount: { entityResourceUrl: apiRoot + '/storageAccounts/', collectionResourceUrl: apiRoot + '/storageAccounts/' },
    StorageLimit: { entityResourceUrl: apiRoot + '/storageLimits/', collectionResourceUrl: apiRoot + '/storageLimits/' },
    Brand: { entityResourceUrl: apiRoot + '/brands/', collectionResourceUrl: apiRoot + '/brands/' },
    License: { entityResourceUrl: apiRoot + '/licenses/', collectionResourceUrl: apiRoot + '/licenses/' },
    AgentOptions: { entityResourceUrl: apiRoot + '/settings/', collectionResourceUrl: apiRoot + '/settings/' },
    RemoteDeployConfiguration: {
      entityResourceUrl: apiRoot + '/remote-deploy/configurations/',
      collectionResourceUrl: apiRoot + '/remote-deploy/configurations/'
    },
    RemoteDeployConfigurationHistory: {
      collectionResourceUrl: apiRoot + '/remote-deploy/history/',
      entityResourceUrl: apiRoot + '/remote-deploy/history/'
    },
    CustomDnsCertificate: {
      entityResourceUrl: apiRoot + '/customdns/certificates/',
      collectionResourceUrl: apiRoot + '/customdns/certificates/'
    },
    CustomDnsBinding: { entityResourceUrl: apiRoot + '/customdns/bindings/', collectionResourceUrl: apiRoot + '/customdns/bindings/' }
  }
};

export function selectId(object) {
  return object.Id || object.id || object.ID;
}

export function administratorsFilterFn(admins: Administrator[], pattern: string) {
  return PropsFilterFnFactory(['Id'])(admins, pattern);
}

export function rdConfigurationsFilterFn(configs: RemoteDeployConfiguration[], pattern: string) {
  return PropsFilterFnFactory(['id'])(configs as any, pattern);
}

export function brandsFilterFn(brands: Brand[], pattern: string) {
  return PropsFilterFnFactory(['id'])(brands as any, pattern);
}

export function filterFnByCamelCaseId<T>(data: T[], pattern: string) {
  return PropsFilterFnFactory(['id'])(data as any, pattern);
}

export function selectHid(object: Computer) {
  return object.hid;
}

export function entityMetadata(): EntityMetadataMap {
  return {
    Administrators: {
      entityName: 'Administrator',
      filterFn: administratorsFilterFn,
      selectId,
      additionalCollectionState: {
        total: 0
      }
    },
    Domains: {
      entityName: 'Domain',
      filterFn: filterFnByCamelCaseId,
      selectId,
      additionalCollectionState: {
        total: 0
      }
    },
    StorageAccounts: {
      entityName: 'StorageAccount',
      selectId
    },
    StorageLimits: {
      entityName: 'StorageLimit',
      selectId
    },
    Brands: {
      entityName: 'Brand',
      filterFn: brandsFilterFn,
      selectId
    },
    Licenses: {
      entityName: 'License',
      selectId
    },
    AgentOptions: {
      entityName: 'AgentOptions',
      filterFn: filterFnByCamelCaseId,
      selectId,
      additionalCollectionState: {
        total: 0
      }
    },
    CustomDnsCertificates: {
      entityName: 'CustomDnsCertificate',
      filterFn: filterFnByCamelCaseId,
      selectId,
      additionalCollectionState: {
        total: 0
      }
    },
    CustomDnsBindings: {
      entityName: 'CustomDnsBinding',
      filterFn: filterFnByCamelCaseId,
      selectId,
      additionalCollectionState: {
        total: 0
      }
    },
    RemoteDeployConfigurations: {
      entityName: 'RemoteDeployConfiguration',
      selectId,
      filterFn: rdConfigurationsFilterFn,
      additionalCollectionState: {
        total: 0
      }
    },
    RemoteDeployConfigurationHistories: {
      entityName: 'RemoteDeployConfigurationHistory',
      selectId,
      filterFn: rdConfigurationsFilterFn,
      additionalCollectionState: {
        total: 0
      }
    }
  };
}

@NgModule({
  imports: [CommonModule, EntityDataModule.forRoot({ entityMetadata: entityMetadata() })],
  declarations: [],
  providers: [
    {
      provide: DefaultDataServiceConfig,
      useValue: defaultDataServiceConfig
    }
  ]
})
export class EntityStoreModule {}
