export const IntMaxValue = 2147483647;
export const TimeSpanMaxValue = '10675199.02:48:05.4775807';
export const MinDate = '0001-01-01T00:00:00';
export const MaxDate = '9999-12-31T23:59:59.9999999';
export const ISOFormat = 'YYYY-MM-DDTHH:mm:00.0000000';
export const ISOFormatWithSec = 'YYYY-MM-DDTHH:mm:ss.0000000';
export const UTCFormatWithSec = 'YYYY-MM-DDTHH:mm:ss+00:00';
export const TimeSpanZeroValue = '12:00 AM';
export const GuidEmpty = '00000000-0000-0000-0000-000000000000';
export const GuidNotEmpty = '00000000-0000-0000-0000-000000000001';
