import { Pipe, PipeTransform } from '@angular/core';
import Computer, { OsType, VMDetectorInstance } from '@models/Computer';

export function getOsIcon(osValue: string | OsType, instanceType?: VMDetectorInstance): string {
  if ([VMDetectorInstance.AmazonV1, VMDetectorInstance.AmazonV2].includes(instanceType)) {
    return 'ico ico-ec2win';
  }
  if (VMDetectorInstance.Azure === instanceType) {
    return 'ico ico-azurewin';
  }

  if (!osValue) {
    return 'fa fa-server';
  }

  switch (osValue) {
    case 'Server':
      return 'fa fa-server';
    case OsType.windows:
    case 'Workstation':
    case 'WINNT':
      return 'fa fa-windows';
    case OsType.apple:
      return 'fa fa-apple';
    default:
      return 'fa fa-' + osValue.toLowerCase();
  }
}

@Pipe({
  name: 'computerOsIcon'
})
export class ComputerOsIconPipe implements PipeTransform {
  transform(computer: Computer, ...args: any[]): any {
    return getOsIcon(computer?.os, computer?.instanceType);
  }
}
