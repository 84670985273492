import { StorageType } from '@models/StorageType.enum';
import { GuidEmpty } from 'mbs-ui-kit';
import { VmwareFormModel } from '../../models/VMWare/vmware-form-model';
import { BaseForStepsHelper, WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { StepsDataFromPlanHelper } from '../steps-data-from-plan-helper/steps-data-from-plan-helper';
import { SupportMethodsForStepsFromPlan } from '../support-methods-for-steps-from-plan';

export class VmwareModelForStepsHelper extends BaseForStepsHelper {
  public static VmwareModel: VmwareFormModel = null;

  public static VMWareModelFromPlan(plan, settings: WizardHelpersSettings = this.DefaultSettings): VmwareFormModel {
    const newPlan = plan && (plan.plan || plan.Plan);
    if (newPlan) {
      this.setThisValues(plan, settings);
      this.setStorageType(settings.mode, newPlan.HybridID, newPlan.ConnectionID);
      this.VmwareModel = new VmwareFormModel(this.isLinux);

      this.updatePlanNameStep(newPlan, settings.compName);
      this.updateWhereBackupStep(newPlan);
      this.VmwareModel.compressionEncryptionStep = StepsDataFromPlanHelper.updateCompressionEncryption(
        newPlan,
        true,
        this.isLinux,
        this.isCreate,
        true
      );

      this.VmwareModel.selectHostStep = StepsDataFromPlanHelper.updateSelectHost(newPlan, !this.isCreate);
      this.VmwareModel.selectVirtualMachinesStep = StepsDataFromPlanHelper.updateSelectVirtualMachines(newPlan);
      this.VmwareModel.appAwareStep = StepsDataFromPlanHelper.updateSelectAppAware(newPlan);
      this.VmwareModel.advancedSettingsStep = StepsDataFromPlanHelper.updateAdvancedSettingsVirtual(
        newPlan,
        this.storage,
        this.isCreate,
        true
      );
      this.VmwareModel.selectVirtualDisksStep = StepsDataFromPlanHelper.updateSelectVirtualDisks(newPlan, true);

      this.VmwareModel.archiveConsistencyStep = StepsDataFromPlanHelper.updateArchiveConsistency(newPlan);
      this.VmwareModel.scheduleStep = StepsDataFromPlanHelper.updateNBFSchedule(
        newPlan,
        plan.OverdueAfterSuccess,
        this.isCreate,
        this.isLinux,
        settings.isNBF,
        settings.validVersionForSchedule
      );
      this.bucketId = this.accounts && this.isRD ? this.getBucketId(newPlan.Bucket, newPlan.SGAccountID) : newPlan.ConnectionID;
      this.VmwareModel.retentionPolicyStep = StepsDataFromPlanHelper.updateNBFRetentionPolicy(
        newPlan,
        SupportMethodsForStepsFromPlan.needShowImmutability(this.storages.CloudStorages, this.bucketId),
        this.isCreate
      );
      this.VmwareModel.prePostActionsStep = StepsDataFromPlanHelper.updatePrePostActions(newPlan);
      this.VmwareModel.notificationStep = StepsDataFromPlanHelper.updateNotification(
        newPlan.Notification,
        newPlan.WindowsEventLogNotification,
        this.isLinux
      );

      if (!this.isLinux) {
        this.VmwareModel.AvailableMachinePlans = StepsDataFromPlanHelper.updateAvailableMachinePlans(
          plan.AvailableMachinePlans,
          newPlan.ID
        );
      }
    }
    return this.VmwareModel;
  }

  public static updatePlanNameStep(newPlan, compName: string): void {
    const name = newPlan.Name || SupportMethodsForStepsFromPlan.generatePlanName(compName, this.planType, this.timeZoneOffset);
    this.VmwareModel.planNameStep = StepsDataFromPlanHelper.updatePlanNameStep(name, newPlan.SavePlanInCloud, true);
  }

  public static updateWhereBackupStep(newPlan): void {
    if (!this.accounts) {
      this.VmwareModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(false, newPlan.HybridID, newPlan.ConnectionID);
    } else {
      const id = this.bucketId || this.getBucketId(newPlan.Bucket, newPlan.SGAccountID);
      this.VmwareModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(false, newPlan.HybridID, id || newPlan.ConnectionID);
      if (id && id !== GuidEmpty) {
        const storage = this.storages.CloudStorages.find((s) => s.ID === id) || this.storages.LocalStorages.find((s) => s.ID === id);
        this.storageType = storage ? storage.StorageType : this.storageType;
      }
    }
    if (!this.isRD) SupportMethodsForStepsFromPlan.getFilteredStoragesByTypes(this.storages, [StorageType.OpenStack]);
    this.VmwareModel.Storages = this.storages;
    this.VmwareModel.StoragesArray = [...this.storages.CloudStorages, ...this.storages.LocalStorages];
  }
}
