import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { AgentOptions } from '@models/AgentOptions';
import { Specification } from '@models/rmm/FilterPayload';
import { FilterQuery } from '@models/rmm/FilterQuery';
import RmmStatResponse from '@models/rmm/RmmStatResponse';
import { map } from 'rxjs/operators';
import SoftwareInfo from '../models/rmm/SoftwareInfo';
import { ConfigurationService } from './configuration.service';

const statTypes = [
  'host',
  'runtime',
  'service',
  'update',
  'hotfix',
  'hardware',
  'hdd',
  'hddsmart',
  'printer',
  'antivirus',
  'eventtotal',
  'hyperv',
  'memory',
  'anti',
  'error',
  'sqlserver',
  'software',
  'summary'
] as const;

export type StatType = typeof statTypes[number];

// TODO move
// #region Interfaces

// #endregion

@Injectable()
export class RmmService {
  public stat: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public statBaseUrl = 'api/computers';
  public rmmBaseHref = '';
  public hid: string;

  private mbsStageKey: string;
  private myStats: { [K in StatType]: BehaviorSubject<any> } = statTypes.reduce((acc, type) => {
    acc[type] = new BehaviorSubject<any>(null);
    return acc;
  }, {} as any);

  public statsBus = merge(
    ...Object.entries(this.myStats).map(([k, v]) => {
      return v.pipe(map((data) => ({ statType: k as StatType, value: data })));
    })
  );

  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.statBaseUrl = config.get('rmmBaseHref') + '/' + this.statBaseUrl;
    this.rmmBaseHref = config.get('rmmBaseHref');
    this.mbsStageKey = config.get('mbsStageKey');
  }

  getStat<TData>(statName: StatType, resetObservable = false): BehaviorSubject<RmmStatResponse<TData>> {
    let stat = this.myStats[statName];
    if (!stat || resetObservable) {
      stat = new BehaviorSubject<any>(null);
      this.myStats[statName] = stat;
    }
    return stat;
  }

  fetchAgentOptions(hid = this.hid): Observable<AgentOptions> {
    return hid ? this.http.get<AgentOptions>(`/api/computers/${this.hid}/agentoptions`) : of(null);
  }

  fetchStat<TData>(statName: StatType): Observable<RmmStatResponse<TData>>;
  fetchStat<TData>(statName: StatType, hid?: string, orderBy?: string, limit?: number, offset?: number, fields?: { [key: string]: string });
  fetchStat<TData>(statName: StatType, hid?: string, params?: FilterQuery): Observable<RmmStatResponse<TData>>;
  fetchStat<TData>(statName: StatType, hid: string = this.hid, ...args: any[]): Observable<RmmStatResponse<TData>> {
    const stat = this.getStat<TData>(statName);

    if (typeof args[0] === 'object') {
      const { orderBy, limit, offset, fields } = args[0];
      return this.fetchStatDataInternal(statName, stat, hid, orderBy, limit, offset, fields);
    } else {
      const [orderBy, limit, offset, fields] = args;
      return this.fetchStatDataInternal(statName, stat, hid, orderBy, limit, offset, fields);
    }
  }

  private fetchStatDataInternal<TData>(
    statName: StatType,
    stat: BehaviorSubject<RmmStatResponse<TData>>,
    hid?: string,
    orderBy = 'header.utcTime:desc',
    limit = 1,
    offset = 0,
    fields?: { [key: string]: string }
  ): Observable<RmmStatResponse<TData>> {
    const fieldsObjs = fields ? Object.entries(fields).map(([k, v]) => ({ ['field.' + k]: v })) : [];
    const params = new HttpParams({
      fromObject: Object.assign(
        {},
        {
          limit: limit.toString(),
          offset: offset.toString(),
          orderBy: orderBy
        },
        ...fieldsObjs
      )
    });

    const url = this.statBaseUrl + '/' + hid + '/stat/' + statName;

    return new Observable((observer) => {
      this.http.get<RmmStatResponse<TData>>(url, { params }).subscribe(
        (res) => {
          stat.next(res);
          observer.next(res);
        },
        (err) => observer.error(err),
        () => observer.complete()
      );
    });
  }
  // UNIVERSAL METHOD
  fetchLastData = <TData>(statName: StatType, hid: string = this.hid): Observable<TData> => {
    const stat = this.getStat<TData>(statName);
    const url = this.statBaseUrl + '/' + hid + '/last/' + statName;
    const httpParams = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });

    return new Observable((observer) => {
      this.http.get<RmmStatResponse<TData>>(url, { params: httpParams }).subscribe(
        (res: any) => {
          stat.next(res);
          observer.next(res);
        },
        (err) => observer.error(err),
        () => observer.complete()
      );
    });
  };
  // Duplicate fetchLastData
  fetchLast = <TData>(statName: StatType, hid: string = this.hid): Observable<{ data: TData; error: any }> => {
    return this.fetchLastData(statName, hid);
  };

  fetchSpec = <TData>(statName: StatType, httpParams: HttpParams): Observable<TData[]> => {
    if (this.mbsStageKey) {
      httpParams = httpParams.set('mbsStageKey', this.mbsStageKey);
    }

    return this.http.get<TData[]>(this.rmmBaseHref + `/spec/${statName}`, { params: httpParams });
  };

  fetchSpecSoftwareDetails(hid: string): Observable<Partial<{ data: SoftwareInfo[] }>> {
    const params = {
      hid,
      pageNumber: 1,
      pageSize: 100
    };

    return this.http.get(this.rmmBaseHref + `/spec/softwaredetails`, {
      params: this.mbsStageKey ? { ...params, mbsStageKey: this.mbsStageKey } : params
    });
  }

  fetchSpecPost = <TData>(statName: StatType, httpParams: any = {}, bodyParams = { hids: [], companies: [] }): Observable<TData[]> => {
    if (this.mbsStageKey) {
      httpParams = { ...httpParams, mbsStageKey: this.mbsStageKey };
    }

    return this.http.post<TData[]>(this.rmmBaseHref + `/api/spec/${statName}`, bodyParams, {
      params: httpParams,
      headers: { 'Content-Type': 'application/json' }
    });
  };

  fetchLastStat<TData>(statName: StatType, hid: string = this.hid): Observable<RmmStatResponse<TData>> {
    const stat = this.getStat<TData>(statName);
    const url = this.statBaseUrl + '/' + hid + '/stat/' + statName + '/last';

    return new Observable((observer) => {
      this.http.get<RmmStatResponse<TData>>(url).subscribe(
        (res) => {
          stat.next(res);
          observer.next(res);
        },
        (err) => observer.error(err),
        () => observer.complete()
      );
    });
  }

  setHid(hid: string): void {
    this.hid = hid;
    this.resetState();
  }

  // TODO refactor with parse to `myStats`
  fetchStatDataAll(statType: StatType) {
    this.http.get<any>(this.statBaseUrl + '/' + statType).subscribe((res) => {
      this.stat.next(res);
    });
  }

  fetchLastStatAll<TData>(statType: StatType, httpParams?: HttpParams): Observable<RmmStatResponse<TData>> {
    return this.http.get<RmmStatResponse<TData>>(this.statBaseUrl + `/all/stat/${statType}/last`, { params: httpParams });
  }

  fetchComputers<TData>(httpParams): Observable<TData[]> {
    return this.http.get<TData[]>(this.statBaseUrl + `/rm/list`, { params: httpParams });
  }

  onlineStatus(hid, httpParams = {}): Observable<boolean> {
    return this.http.get<boolean>(this.statBaseUrl + `/rm/` + hid + '/onlinestatus', { params: httpParams });
  }

  private fetchStatByPost<TData>(
    statType: StatType,
    hid: string,
    stat: BehaviorSubject<RmmStatResponse<TData>>,
    specification: Specification
  ): Observable<RmmStatResponse<TData>> {
    const url = this.statBaseUrl + '/' + hid + '/stat/' + statType + '/query';

    return new Observable((observer) => {
      this.http.post<RmmStatResponse<TData>>(url, specification).subscribe(
        (res) => {
          stat.next(res);
          observer.next(res);
        },
        (error) => observer.error(error),
        () => observer.complete()
      );
    });
  }

  resetState(statType?: StatType): void {
    if (statType) {
      this.myStats[statType].next(null);
      return;
    }

    Object.values(this.myStats).forEach((stat) => {
      stat.next(null);
    });
  }
}
