import { StorageType } from './StorageType.enum';

export enum ImmutabilityMode {
  Disabled = 0,
  Compliance = 1,
  Governance = 2
}

export class StorageBucket {
  public BucketName: string;
  public DestinationName: string;
  public Id: string;
  public ImmutabilityMode: ImmutabilityMode;
  public Region: string;
  public RegionDisplayName: string;
}

export class StorageBucketCamelCase {
  public bucketName: string;
  public destinationName: string;
  public id: string;
  public immutabilityMode: ImmutabilityMode;
  public region: string;
  public regionDisplayName: string;
}

export enum StorageAccountState {
  FullyAccessible = 'FullyAccessible',
  ReadOnly = 'ReadOnly',
  Disabled = 'Disabled',
  Deleted = 'Deleted'
}

export default class StorageAccount {
  public AzureType: number;
  public Buckets?: StorageBucket[];
  public Id: string;
  public IsNonArchiveSyntheticSupported: boolean;
  public IsSyntheticSupported: boolean;
  public MinimumStorageDuration: number;
  public Name: string;
  public S3CompatibleSynthetic: boolean;
  public StorageAccountState?: StorageAccountState;
  public StorageType: StorageType;
  public disabled?: boolean; // internal property
}

// TODO camelCase for getByKey
export class StorageAccountCamelCase {
  public azureType: number;
  public buckets?: StorageBucketCamelCase[];
  public id: string;
  public isNonArchiveSyntheticSupported: boolean;
  public isSyntheticSupported: boolean;
  public minimumStorageDuration: number;
  public name: string;
  public s3CompatibleSynthetic: boolean;
  public storageAccountState?: StorageAccountState;
  public storageType: StorageType;
  public disabled?: boolean; // internal property
}
