import { Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { BuildsFacade } from '@facades/builds.facade';
import Administrator from '@models/Administrator';
import Brand from '@models/Brand';
import { Build, BuildDetails, BuildOsType, BuildVersionType, getSupportCompanyMinimalVersion } from '@models/build';
import { BuildType } from '@models/BuildType.enum';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services';
import { BuildService } from '@services/build-service/build.service';
import { AbilityService } from 'ability';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { AccordionComponent, MbsPopupType, ModalService, ToastService } from 'mbs-ui-kit';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, finalize, noop } from 'rxjs';
import { BrandsModalComponent } from './brands-modal/brands-modal.component';
import {
  BuildAction,
  buildImages,
  buildsThatSupportBranding,
  buildsThatSupportCommand,
  buildsThatSupportLinuxDebian,
  buildsThatSupportLinuxRedHat,
  CopyingStatus,
  InstallWindowsAgentCmdName
} from './download-build.constants';
import { getNormalizedVersion } from '@utils/get-normalized-version';

@UntilDestroy()
@Component({
  selector: 'mbs-download-build',
  templateUrl: './download-build.component.html'
})
export class DownloadBuildComponent implements OnInit {
  public readonly buildTypesEnum = BuildType;
  public readonly BUILD_ACTIONS_TRIGGER_BUTTON_TOKEN = 'download-sidepanel-build-actions';
  public readonly copyingStatus = CopyingStatus;
  public readonly alertType = MbsPopupType;
  public readonly PermissionsEnum = PermissionsEnum;

  /** Template tokens */
  public readonly newBuildSectionToken: {
    section: BuildDetails;
    isSandbox: boolean;
  };
  public readonly buildDescriptionToken: { build: Build };

  public panelId = null;
  public currentCopingStatus: CopyingStatus = null;
  public copyingTooltipText = new BehaviorSubject(null);
  public downloadBuildInProgress: { sandbox: boolean; notSandbox: boolean } = { sandbox: false, notSandbox: false };
  public showHelp = true;
  public makePublicButtonLoadingId: BuildType;

  /** Build descriptions */
  public windowsSupported = this.i18nextService.t('builds:windows.supported', { returnObjects: true });
  public windowsCanBackup = this.i18nextService.t('builds:windows.can_backup', { returnObjects: true });
  public vmwareSupported = this.i18nextService.t('builds:VMware.supported', { returnObjects: true });
  public linuxDebSupported = this.i18nextService.t('builds:linux_deb.supported', { returnObjects: true });
  public linuxRpmSupported = this.i18nextService.t('builds:linux_rpm.supported', { returnObjects: true });
  public macOsSupported = this.i18nextService.t('builds:mac_os.supported', { returnObjects: true });
  public rmmSupported = this.i18nextService.t('builds:rmm.supported', { returnObjects: true });
  public rmmMacSupported = this.i18nextService.t('builds:rmm_mac_os.supported', { returnObjects: true });
  public connectHostForWindowsSupported = this.i18nextService.t('builds:connectHostForWindows.supported', { returnObjects: true });
  public rmmLinuxRpmSupported = this.i18nextService.t('builds:rmm_linux_rpm.supported', { returnObjects: true });
  public rmmLinuxDebSupported = this.i18nextService.t('builds:rmm_linux_deb.supported', { returnObjects: true });

  @Input() build: Build;
  @Input() beta: boolean;
  @Input() isSandboxEnabled = false;
  @Input() set openedPanelIds(panelIds: string[]) {
    this.closeOthers && !panelIds?.includes(this.panelId) && this.accordion?.collapseAll();
  }
  @Input() licensedBrandList: Brand[] = [];
  @Input() buildsInProgress?: Build['mbsBuildType'][] = [];
  @Input() currentUser: Administrator;
  @Input() index: number;
  @Input() showDeleteButton = true;

  /** Keep only one build opened */
  @Input() closeOthers = true;

  /** Pass to open build description on component initiate */
  @Input() activeIds = [];

  /** Align content */
  @Input() align: 'left' = null;

  @Input() companyId: string = null;

  @Output() buildPanelClick = new EventEmitter<{ panelId: string; opening: boolean }>();
  @Output() removeBuild = new EventEmitter<{ build: Build; isSandbox: boolean }>();
  @Output() raActionButtonClick = new EventEmitter<null>();

  @ViewChildren('copyTooltip') copyTooltips: QueryList<NgbTooltip>;
  @ViewChild(AccordionComponent, { static: false }) accordion: AccordionComponent;

  constructor(
    private i18nPipe: I18NextPipe,
    private modalService: ModalService,
    private buildService: BuildService,
    private buildsFacade: BuildsFacade,
    private toastService: ToastService,
    private clipboardService: ClipboardService,
    private i18nextService: I18NextService,
    private auth: AuthService,
    private ability: AbilityService
  ) {}

  ngOnInit() {
    this.panelId = 'build-item-panel_' + this.build.mbsBuildType;

    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((admin) => {
      this.showHelp = admin?.IsProvider || this.ability.can('read', PermissionsEnum.HelpMarketing);
    });
  }

  public onBuildPanelClick({ panelId, event: opening }: { panelId: string; event: boolean }) {
    this.buildPanelClick.emit({ panelId, opening });
  }

  public getIcon(build: Build): string {
    return buildImages[build.buildOs.toLowerCase()];
  }

  public getPublicVersionName(build: Build): string {
    return build.public?.version ? `v${build.public?.version} (${this.i18nPipe.transform('download-build.module:versions.public')})` : '-';
  }

  public isOneOfTheBuildsIsInProgress(): boolean {
    /*
      Temporary fix. After additional Builder instances on backend side will be launched need to return previous logic:
        1. Replace isBuildInProgress logic with isGenerateButtonInLoadingState.
        2. Delete isGenerateButtonInLoadingState method.
    */
    return !!this.buildsInProgress?.length;
  }

  public isBuildInProgress(build: Build): boolean {
    return this.buildsInProgress?.includes(build.mbsBuildType);
  }

  public onDownloadButtonClick(build: Build, isSandbox: boolean): void {
    if (!this.licensedBrandList.length || !this.isBuildSupportBranding(build)) {
      this.downloadBuild(build, isSandbox);

      return;
    }

    if (this.needToTakeCommonBuild()) {
      this.downloadBuild(build, isSandbox);
    } else if (this.needToTakeAdvancedBuild()) {
      this.downloadAdvancedBuild(build, isSandbox);
    } else {
      this.openBrandsModal(build, isSandbox, BuildAction.Download);
    }
  }

  public isBuildSupportBranding(build: Build): boolean {
    return buildsThatSupportBranding.includes(build.mbsBuildType);
  }

  public generateBuild(build: Build, isSandboxBuildRequests = false): void {
    this.buildService
      .requestBuild({
        buildsTypeList: [build.mbsBuildType],
        isBuildDestinationSandbox: isSandboxBuildRequests
      })
      .subscribe((res) => this.handleResponse(res));
  }

  public onCopyDownloadLinkButtonClick(build: Build, isSandbox: boolean, index: number): void {
    if (!this.licensedBrandList.length || !this.isBuildSupportBranding(build)) {
      this.copyLink(build, isSandbox);

      return;
    }

    if (this.needToTakeCommonBuild()) {
      this.copyLink(build, isSandbox);
    } else if (this.needToTakeAdvancedBuild()) {
      this.onNeedAdvancedBuild({
        build,
        isSandbox,
        index,
        action: BuildAction.CopyLink
      });
    } else {
      this.openBrandsModal(build, isSandbox, BuildAction.CopyLink);
    }
  }

  public isBuildSupportCommand(build: Build): boolean {
    return buildsThatSupportCommand.includes(build.mbsBuildType);
  }

  public onCopyCommandForDeployBuildClick(build: Build, isSandbox: boolean, index: number): void {
    if (!this.licensedBrandList.length || !this.isBuildSupportBranding(build)) {
      const link = this.getPreparedLink(build[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public].downloadLink);

      this.copyCommandLink(build, link);

      return;
    }

    if (this.needToTakeCommonBuild()) {
      const link = this.getPreparedLink(build[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public].downloadLink);
      this.copyCommandLink(build, link);
    } else if (this.needToTakeAdvancedBuild()) {
      this.onNeedAdvancedBuild({
        build,
        isSandbox,
        index,
        action: BuildAction.CopyCommand
      });
    } else {
      this.openBrandsModal(build, isSandbox, BuildAction.CopyCommand);
    }
  }

  public onRemoveBuildButtonClick(build: Build, isSandbox: boolean, deleteConfirmTemplate: TemplateRef<any>): void {
    this.modalService
      .open(
        {
          header: { title: this.i18nPipe.transform('download-build.module:confirmBuildDeletionTitle') },
          footer: {
            okButton: {
              text: this.i18nPipe.transform('buttons:delete'),
              type: 'danger'
            }
          },
          data: {
            context: { build, isSandbox }
          }
        },
        deleteConfirmTemplate
      )
      .then((confirmed) => {
        if (!confirmed) return;

        this.buildService.removeBuild(build, isSandbox).subscribe((res) => {
          this.removeBuildHandle(build, isSandbox);
          this.handleResponse(res);
        });
      })
      .catch(noop);
  }

  public makeBuildPublic(build: Build): void {
    this.makePublicButtonLoadingId = build.mbsBuildType;
    this.buildsFacade.makePublic(build)
      .pipe(finalize(() => (this.makePublicButtonLoadingId = null)))
      .subscribe((res) => {
        this.handleResponse(res);
      });
  }

  public getNewBuildSection(build: Build): BuildDetails {
    return this.isSandboxEnabled && build?.sandbox ? build?.sandbox : build?.public;
  }

  public getDisabledByMinimalVersionSupportsCompany(details: BuildDetails, buildType: BuildType): boolean {
    if (!this.companyId) return false;

    return getNormalizedVersion(details?.version) < getSupportCompanyMinimalVersion(buildType);
  }

  private removeBuildHandle(build: Build, isSandbox: boolean): void {
    this.removeBuild.emit({ build, isSandbox });
  }

  private downloadBuild = (build: Build, isSandbox: boolean): void => {
    const link = this.getPreparedLink(build[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public].downloadLink);
    window.top.open(link);
  };

  private needToTakeCommonBuild(): boolean {
    if (this.companyId) return true;
    
    if (this.licensedBrandList.length === 2) {
      return !this.licensedBrandList.some((licensedBrand) => this.isBrandAdvancedAndDefault(licensedBrand));
    }

    return this.licensedBrandList.length === 1 && this.licensedBrandList[0].isBase;
  }

  private isBrandAdvancedAndDefault = (brand: Brand): boolean => !brand.isBase && brand.isDefault;

  private needToTakeAdvancedBuild(): boolean {
    return (
      this.licensedBrandList.length === 2 &&
      this.licensedBrandList.some((licensedBrand) => {
        return this.isBrandAdvancedAndDefault(licensedBrand);
      })
    );
  }

  private getPreparedLink(link: string, brandId?: string): string {
    if (!(this.companyId || brandId) || !link) return link;

    const url = new URL(link);

    /* Ignore brandId if companyId exists */
    if (this.companyId) {
      url.searchParams.append('companyId', this.companyId);
    } else {
      url.searchParams.append('brandId', brandId);
    }

    return url.toString();
  }

  private downloadAdvancedBuild(build: Build, isSandbox: boolean): void {
    const brand = this.licensedBrandList.find((licensedBrand) => this.isBrandAdvancedAndDefault(licensedBrand));
    const link = this.getPreparedLink(build[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public].downloadLink, brand.id);
    window.top.open(link);
  }

  private openBrandsModal(build: Build, isSandbox: boolean, action: BuildAction): void {
    const modal = this.modalService
      .openRef(BrandsModalComponent, {
        data: {
          action,
          licensedBrandList: this.licensedBrandList,
        }
      });

    modal.result.then((brandId) => {
      const link = this.getPreparedLink(build[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public].downloadLink, brandId);
      switch (action) {
        case BuildAction.Download:
          window.open(link, '_blank');
          break;
        case BuildAction.CopyLink:
          this.clipboardService.copyFromContent(link);
          this.toastService.success();
          break;
        case BuildAction.CopyCommand:
          this.copyCommandLink(build, link);
          this.toastService.success();
      }
    }).catch(noop);
  }

  private handleResponse(res): void {
    this.toastService[res.success ? 'success' : 'error'](res.message);
  }

  private onNeedAdvancedBuild({
    build,
    isSandbox,
    action
  }: {
    build: Build;
    isSandbox: boolean;
    index: number;
    action: BuildAction;
  }) {
    const brand = this.licensedBrandList.find((licensedBrand) => this.isBrandAdvancedAndDefault(licensedBrand));
    const link = this.getPreparedLink(build[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public].downloadLink, brand.id);

    link && action === BuildAction.CopyCommand ? this.copyCommandLink(build, link) : this.clipboardService.copyFromContent(link);
  }

  private copyLink = (build: Build, isSandbox: boolean): void => {
    const link = this.getPreparedLink(build[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public].downloadLink);
    this.clipboardService.copyFromContent(link);
  };

  private copyCommandLink(build: Build, link: string): void {
    let command;

    if (build.buildOs === BuildOsType.Windows) {
      command = this.getCommandWindows(link, this.getCmdName(build.mbsBuildType));
    }

    if (build.buildOs === BuildOsType.Mac) {
      command = this.getCommandMac(link);
    }

    if (buildsThatSupportLinuxRedHat.includes(build.mbsBuildType)) {
      command = this.getCommandLinuxRedhat(link);
    }

    if (buildsThatSupportLinuxDebian.includes(build.mbsBuildType)) {
      command = this.getCommandLinuxDebian(link);
    }

    this.clipboardService.copyFromContent(command);
  }

  private getCmdName(buildType: BuildType): InstallWindowsAgentCmdName {
    switch (buildType) {
      case BuildType.RMMAgent:
        return InstallWindowsAgentCmdName.RMM;
      case BuildType.CBLRA:
        return InstallWindowsAgentCmdName.Connect;
      default:
        return InstallWindowsAgentCmdName.Common;
    }
  }

  private getCommandWindows(link: string, installAgentName: InstallWindowsAgentCmdName = InstallWindowsAgentCmdName.Common): string {
    return `powershell -ExecutionPolicy Bypass -command "& {[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; iex (New-Object System.Net.WebClient).DownloadString('https://git.io/JUSAA'); Install-MSP360Module; Install-${installAgentName} -URL '${link}' -Force}"`;
  }

  private getCommandLinuxDebian(link: string): string {
    return `sudo curl "${origin}/Install/install_deb.sh" -L | sudo bash -s "${link}"`;
  }

  private getCommandLinuxRedhat(link: string): string {
    return `sudo curl "${origin}/Install/install_rpm.sh" -L | sudo bash -s "${link}"`;
  }

  private getCommandMac(link: string): string {
    return `sudo curl "${origin}/Install/install_mac.sh" -L | sudo bash -s "${link}"`;
  }
}
