<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>{{ 'wizards:restore_source_title' | i18next: { format: 'title' } }}</h4>
    <div *ngIf="isRDMode"
         class="text-xs">
      <div class="form-row">
        <div class="col-auto">
          {{isLinux ? 'Example: /home/user' : 'Example:'}}
        </div>
        <div *ngIf="!isLinux"
             class="col">
          D:\folder\subfolder <br>
          %userprofile%\folder
        </div>
      </div>
    </div>
  </header>
  <mbs-form-group>
    <mbs-button type="primary"
                [isCtrl]="true"
                (click)="showLegend()">
      {{'wizards:show_legend_text' | i18next}}
    </mbs-button>
  </mbs-form-group>

  <mbs-form-group class="restore-source-tree position-relative">
    <mbs-card *ngIf="!isRDMode"
              [size]="'sm'"
              [staticHeight]="300">
      <mbs-loader *ngIf="loadingTree"
                  size="small"></mbs-loader>
      <mbs-alert class="mb-0 mt-3"
                 *ngIf="!treeData.length"
                 [type]="mbsPopupType.warning"
                 [icon]="true">
        {{ 'wizards:no_data_to_restore' | i18next }}
      </mbs-alert>
      <mbs-tree *ngIf="treeData.length"
                (mbsChange)="changeTreeHandler($event)"
                [offCheckAndIndeterminate]="true"
                [disableIfLoadingSubtree]="true"
                [childrenManualControl]="true"
                [data]="treeData"
                [disableChildren]="false"
                [dontSelectChildren]="false"
                [loadMore]="getSubtree.bind(this)"
                [getItems]="getSubtree.bind(this)"
                [itemsCanBeHidden]="true"
                [lazy]="true"
                classesForRoot="restore-source-tree"
                mode="file">
        <ng-template treeElement
                     let-item>
          <div class="d-flex align-items-center">
            <img class="mr-2"
                 [class.restore-source-tree_img-version]="item.isVersion"
                 [src]="item.icon"
                 alt="">
            {{ item.label }}
          </div>
        </ng-template>
      </mbs-tree>
    </mbs-card>

    <mbs-form-group [hidden]="!isRDMode">
      <mbs-form-group [formGroup]="foldersForm">
        <mbs-files-folders-list #fileFoldersComponent
                                formControlName="filesFoldersData"
                                [needShowEnabled]="false"
                                [showItem]="isRDMode"
                                [isLinux]="isLinux"
                                [labelForEnabledFields]="'wizards:exclude_text' | i18next"></mbs-files-folders-list>
      </mbs-form-group>
    </mbs-form-group>
  </mbs-form-group>

  <mbs-alert class="mb-0 mt-3"
             [type]="mbsPopupType.info"
             [icon]="true">
    <span *ngFor="let item of restoreSourceInfoAlert; let i = index;">
       <a *ngIf="i === 1; else elseText"
          class="mx-1"
          href="https://help.mspbackups.com/backup-and-restore/backup-plans/file-level-backup"
          target="_blank">{{item}}</a>
      <ng-template #elseText>{{item}}</ng-template>
    </span>
  </mbs-alert>
</section>
