import { DayOfWeek } from '@models/DayOfWeek.enum';
import { RecurType } from '@models/RecurType.enum';
import { RecurringSchedule } from '@models/ScheduleModel';
import { WeekNumber } from '@models/WeekNumber.enum';

export type OccursEveryPeriodType = 'hours' | 'minutes';

export const HoursOrMinutesItems = [
  { value: 'hours', label: 'Hour(s)' },
  { value: 'minutes', label: 'Minute(s)' }
];

export class ScheduleAdvancedModel {
  ForceFullSchedule: boolean;
  ScheduleDiffAvailable: boolean;
  ScheduleDiffEnabled: boolean;
  ScheduleTLogAvailable: boolean;
  ScheduleTLogEnabled: boolean;
  BlockLevelBackup: boolean;
  BlockLevelBackupAvailable: boolean;

  PutFullScheduleInScheduleProp: boolean;
  FullRecurringSchedule: RecurringSchedule;
  BlockLevelRecurringSchedule: RecurringSchedule;
  ScheduleDiff: RecurringSchedule;
  ScheduleTLog: RecurringSchedule;
  constructor() {
    this.ForceFullSchedule = false;
    this.BlockLevelBackup = false;
    this.BlockLevelBackupAvailable = false;
    this.ScheduleDiffAvailable = false;
    this.ScheduleTLogAvailable = false;
    this.ScheduleDiffEnabled = false;
    this.ScheduleTLogEnabled = false;
    this.PutFullScheduleInScheduleProp = false;
  }
}

export class ScheduleSettings {
  constructor() {}
  public StopAfter: any;
  public DayOfMonth: number;
  public WeekNumber: WeekNumber;
  public DayOfWeek: DayOfWeek;
  public WeekDays: DayOfWeek[];
  public Seconds: number;
  public Minutes: number;
  public Hour: number;
  public DailyTillMinutes: number;
  public DailyTillHour: number;
  public DailyFromMinutes: number;
  public DailyFromHour: number;
  public DailyRecurrencePeriod: number;
  public DailyRecurrence: boolean;
  public OnceDate: string;
  public RepeatEvery: number;
  public RecurType: RecurType;
  public Enabled: boolean;
  public StopAfterTicks: string;
  public IsScheduled: boolean;
}

export class ScheduleFormSettings {
  Enabled: boolean;
  FormSchedule: RecurringSchedule;
  constructor(Enabled = false, FormSchedule = new RecurringSchedule()) {
    this.Enabled = Enabled;
    this.FormSchedule = FormSchedule;
  }
}

export interface ScheduleAdvancedStepValue {
  forceFull: ScheduleFormSettings;
  blockLevel?: ScheduleFormSettings;
  scheduleDiff?: ScheduleFormSettings;
  scheduleTLog?: ScheduleFormSettings;
  ForceFullApplyDiffSizeCondition?: boolean;
  ForceFullDiffSizeCondition?: number;
  valid: boolean;
}
