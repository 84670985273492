import { Injectable } from '@angular/core';
import { BuildType } from '@models/BuildType.enum';
import Company, { CompaniesQueryParams, LicensesPoolType } from '@models/Company';
import { PagedResponse } from '@models/Paging';
import { Store } from '@ngrx/store';
import { CompaniesService, SupportLicense } from '@services/companies.service';
import { CompaniesStore } from '@store/companies/companiesStore';
import { PagingStoreFacade } from '@store/pagingStoreFactory/pagingStore.facade';
import { Observable, tap } from 'rxjs';
import { ComputersFacade } from './computers.facade';

@Injectable({
  providedIn: 'root'
})
export class CompaniesFacade extends PagingStoreFacade<Company> {
  constructor(
    public store: Store,
    public companiesStore: CompaniesStore,
    private companiesService: CompaniesService,
    private computersFacade: ComputersFacade
  ) {
    super(store, companiesStore);
  }

  // specific
  getDefaultCompany$ = this.store.select(this.companiesStore.selectors.selectDefaultCompany);

  // service
  getCompanies(params?: CompaniesQueryParams): Observable<PagedResponse<Company>> {
    return this.companiesService.get(params);
  }

  getBuildsMapLicenses(): Observable<SupportLicense> {
    return this.companiesService.getBuildsMapLicenses();
  }

  getSupportBuilds(): Observable<BuildType[]> {
    return this.companiesService.getSupportBuilds();
  }

  assignLicensePoolTypeToCompany(licensePoolType: LicensesPoolType, companyId: string): Observable<any> {
    return this.companiesService.assignLicensePoolTypeToCompany(licensePoolType, companyId);
  }

  create(company: Company): Observable<Company> {
    return this.companiesService.create(company).pipe(tap(() => this.invalidateEntities(false)));
  }

  update(company: Company): Observable<Company> {
    return this.companiesService.update(company).pipe(tap((company) => this.invalidateEntities(true, company)));
  }

  delete(company: Company): Observable<null> {
    return this.companiesService.deleteCompany(company?.id).pipe(tap(() => this.invalidateEntities(true)));
  }

  private invalidateEntities(invalidateComputers: boolean, company?: Company): void {
    this.refreshAfterCRUD(company);
    invalidateComputers && this.invalidateComputers();
  }

  private invalidateComputers(): void {
    this.computersFacade.invalidate();
  }
}
