import { APP_BASE_HREF } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@mbs-ui/environments/environment';
import { ConfigurationService } from '@services/configuration.service';
import { convertToClientUrl, removeDoubleSlash } from '@utils/pipes/client-url.pipe';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private excludeUrls: (string | any)[];

  constructor(@Inject(APP_BASE_HREF) private appBaseHref: string, private configurationService: ConfigurationService) {
    this.excludeUrls = ['https://foris.cloudberrylab.com'];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newUrl = removeDoubleSlash(req.url);
    const excludeUrls = this.excludeUrls.concat(
      this.configurationService.loaded
        ? [
            this.configurationService.get('rmmBaseHref'),
            this.configurationService.get('rmmBaseSignalRHref'),
            this.configurationService.get('rmmScriptLibraryHref'),
            this.configurationService.get('rmmCustomSettings'),
            this.configurationService.get('licensesHref'),
            this.configurationService.get('appsBaseHref'),
            this.configurationService.get('deepInstinctHref')
          ]
        : []
    );

    if (environment.production && excludeUrls.every((url) => !newUrl.includes(url))) {
      newUrl = `${location.origin}` + convertToClientUrl(newUrl, this.appBaseHref);
    }

    req = req.clone({ url: removeDoubleSlash(newUrl), headers: req.headers.set('X-Requested-With', 'XMLHttpRequest') });

    return next.handle(req);
  }
}
