import { Component, ViewChild } from '@angular/core';
import Computer from '@models/Computer';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConnectService } from '@services/connect.service';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-allow-modal-component',
  templateUrl: './remote-connection-allow-modal.component.html'
})
export class RemoteConnectionAllowModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public readonly mbsPopupType = MbsPopupType;

  constructor(private connectService: ConnectService) {}

  getErrorMessage(): string {
    return this.connectService.getRemoteAccessNotAllowedError(this.baseModal.data.computer);
  }

  getComputerName(): string {
    return Computer.getComputerName(this.baseModal.data.computer);
  }
}
