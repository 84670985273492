import { StorageClass } from '@models/StorageType.enum';
import { EncryptionAlgorithm } from '../compression-encription-models';
import { DeleteFileSettingsBase } from './base-models/plan-delete-file-settings-model';
import { EncryptionSettings } from './base-models/plan-encryption-settings-model';
import { ImportSettings } from './base-models/plan-import-settings-model';
import { SyntheticFull } from './base-models/plan-syntetic-full-model';
import { BasePlan } from './base-plan-models';

export class BaseBackupPlan extends BasePlan implements EncryptionSettings, DeleteFileSettingsBase, SyntheticFull {
  constructor() {
    super();
  }
  public SerializationSupportRetentionTime: string;
  public RetentionDelay: string;
  public SerializationSupportRetentionDelay: string;
  public ActualRetentionDelay: string;
  public UseBackupDate: boolean;
  public RetentionNumberOfVersions: number;
  public RetentionUseDefaultSettings: boolean;
  public RetentionDeleteLastVersion: boolean;
  public UseServerSideEncryption: boolean;
  public SSEKMSKeyID: string;
  public EncryptionAlgorithm: EncryptionAlgorithm;
  public EncryptionKeySize: number;
  public IsSyntheticSupported: boolean;
  public SyntheticFull: boolean;
  public ImportSettings: ImportSettings;
  public HybridID: string;
  public RetentionTime: any;
  public HybridBackupSupported: boolean;
  public IsHybridBackup: boolean;
  public UseCompression: boolean;
  public StorageClass: StorageClass;
  public IsGFS: boolean;
  public UseAzureArchive: boolean;
  public UseAzureCool: boolean;
  public UseS3Acceleration: boolean;
  public UseRansomwareDetection: boolean;
  public IsPredefinedTemplatesSchedule: boolean;
  public ReBackupModifiedSinceUTC: string;
  protected CurrentRetentionTime: string;
  protected CurrentUseBackupDate: boolean;
  protected CurrentRetentionDeleteLastVersion: boolean;
  protected CurrentRetentionNumberOfVersions: number;
}
