<mbs-modal [title]="title">
  <ng-container modal-body>
    <mbs-alert *ngIf="!isMBSMode"
               [type]="alertType.warning"
               [icon]="true">
      {{ 'computers.module:modals:installUpdateOnlineAccessAlertText' | i18next: { param: computerName } }}
    </mbs-alert>
    <span *ngIf="isMBSMode"
          class="text-md text-dark">
      <ng-container *ngIf="isInstallMode; else updateBody">
        {{ 'computers.module:modals.installUpdateInstallText' | i18next: { computerName } }}
      </ng-container>
      <ng-template #updateBody>
        {{ 'computers.module:modals.installUpdateUpdateText' | i18next: { computerName } }}
      </ng-template>
    </span>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button *ngIf="isMBSMode"
                [type]="'primary'"
                (click)="baseModal.save(true)">
      {{ okButton }}
    </mbs-button>
    <mbs-button (click)="baseModal.close()">
      {{ 'app:buttons.' + (isMBSMode ? 'cancel' : 'close') | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
