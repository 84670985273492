import { I18NextPipe } from 'angular-i18next';

export interface Page {
  page: string[];
  id: number;
}

export type PagingParams = {
  currentPageNumber: number;
  perPage: number;
};

export function getPagingParams(pagination: PagingParams): QueryPagingParams {
  return {
    offset: (pagination.currentPageNumber - 1) * pagination.perPage,
    limit: pagination.perPage
  };
}

export type QueryPagingParams = {
  offset: number;
  limit?: number;
};

export enum SortDirections {
  Ascending = 'asc',
  Descending = 'desc'
}

export type SortDirection = 'asc' | 'desc' | '';

export interface SortParams {
  column: string;
  direction: SortDirection;
}

export function getSortParams(sorting: SortParams, defaultSorting?: SortParams): QuerySortParams {
  if (defaultSorting && sorting?.column === defaultSorting.column && sorting?.direction === defaultSorting.direction)
    return { orderBy: null };

  // temporarily inverted sort parameter (until the bug in the table-sort.directive is fixed)
  const direction = sorting?.direction === 'asc' ? 'desc' : sorting?.direction === 'desc' ? 'asc' : '';
  return {
    orderBy: sorting && sorting.column && direction ? `${sorting.column}:${direction}` : null
  };
}

export interface QuerySortParams {
  orderBy: string;
}

export type QueryFiltersParams = {
  [param: string]: string | number | boolean;
};

export type QueryParams = Partial<QuerySortParams> & Partial<QueryPagingParams> & Partial<QueryFiltersParams>;

export class PagedResponse<T> {
  data: T[];
  total: number;
}

export type ClearPagingState = {
  loaded: boolean;
  loading: boolean;
  total: number;
};

export type PagingState = ClearPagingState &
  PagingParams & {
    sort: SortParams;
  };

export type AllPagesState = {
  [page: number]: string[];
};

export type ExtendedPagingState<T, F> = {
  paging: ClearPagingState &
    PagingParams & {
      pages: AllPagesState;
      filters: T;
      obsolescenceMark: number;
    };
  data: ClearPagingState & {
    // no filters, no sorting
    entities: string[];
    obsolescenceMark: number;
  };
  loading: boolean; // combined loading for data and entities
  selected: string;
  custom: F;
};

export enum PerPageSize {
  Small = 10,
  Medium = 25,
  Large = 50
}

export function tableSelectedEntitiesText(params: {
  i18nPipe: I18NextPipe;
  oneEntityKey: string;
  manyEntitiesKey: string;
  count: number;
  isSelectAllOnAllPages: boolean;
}): string {
  const selected = params.i18nPipe.transform('app:common.selected', { format: 'title' });
  const entities = params.i18nPipe.transform(params.count === 1 ? params.oneEntityKey : params.manyEntitiesKey);
  const page = params.i18nPipe.transform('app:paging.' + (params.isSelectAllOnAllPages ? 'onAllPages' : 'onThisPage'));

  return `${selected} ${params.count} ${entities} ${page}`;
}

export function tableSelectAllEntitiesText(params: {
  i18nPipe: I18NextPipe;
  oneEntityKey: string;
  manyEntitiesKey: string;
  total: number;
}): string {
  const selectAll = params.i18nPipe.transform('app:paging.selectAll');
  const entities = params.i18nPipe.transform(params.total === 1 ? params.oneEntityKey : params.manyEntitiesKey);

  return `${selectAll} ${entities}: ${params.total}`;
}
