<div class="mbs-footer"
     *ngIf="isShowFooter">

  <div class="row no-gutters mbs-footer-inner">
    <div class="col-12 col-md-4 mbs-footer_logo order-1 justify-content-center justify-content-md-start">
      <ng-container *ngIf="rebrandingActivated; else defaultImage">
        <span class="mbs-footer_logo-link">
          <img src="{{ footerLogoImage }}"
               alt=""
               class="mbs-footer_logo-img" />
        </span>
      </ng-container>
      <ng-template #defaultImage>
        <a href="https://www.msp360.com"
           target="_blank"
           class="mbs-footer_logo-link">
          <img src="assets/Logo_main_tag.svg"
               alt="MSP360"
               class="mbs-footer_logo-img" />
        </a>
      </ng-template>
    </div>
    <div *ngIf="!rebrandingActivated"
         class="col-12 col-md-4 mbs-footer_copyrights order-3 order-md-2">
      <span class="nowrap">All rights reserved.</span>
      <span class="nowrap">&copy; {{ currentDate | date: 'yyyy' }} MSP360</span><br />
      <a href="https://www.msp360.com"
         target="_blank">www.msp360.com</a>
    </div>
    <!--  hide amazon logo from B2 Only MBS-7000  -->
    <div *ngIf="!rebrandingActivated && !('2Only' |  can: 'read')"
         class="col-12 col-md-4 mbs-footer_logo order-2 order-md-3 justify-content-center justify-content-md-end my-2 my-md-0">
      <a href="https://aws.amazon.com/partners/find/partnerdetails/?id=001E000000Rp57WIAR"
         class="mbs-footer_logo-link"
         target="_blank">
        <img src="assets/logo-aws.svg"
             alt=""
             class="mbs-footer_logo-img" />
      </a>
    </div>
  </div>

</div>
