import { ChangeDetectorRef, Component, forwardRef, Input, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DefaultSelectData } from '@models/SelectData';
import { VolumeInfoCommunication } from '@modules/wizards/models/base/base-models/plan-volume-info-model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { linuxPathValidator, winFileNameValidator, winPathValidator } from '@utils/validators';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { FormsUtil, GuidEmpty, ModalService, TableHeader } from 'mbs-ui-kit';
import { noop } from 'rxjs';
import { BaseForStepsHelper, DataForPath } from '../../helpers/bases/base-for-steps-helper';
import { StepsHelpers } from '../../helpers/steps-helpers';
import { BackupToRestoreStepValue } from '../../models/backup-to-restore-models';
import { DiskInfoCommunication } from '../../models/base/base-models/plan-disk-info-model';
import { DestinationStepValue } from '../../models/destination-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { ParamsForTreeData } from '../../services/wizard-steps.service';
import { TreeInModalComponent } from '../components/tree-in-modal/tree-in-modal.component';
import { StepBase } from '../StepBase.class';

const DestinationStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => DestinationStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-destination-step',
  templateUrl: './destination-step.component.html',
  providers: [DestinationStepValueAccessor]
})
export class DestinationStepComponent extends StepBase<DestinationStepValue> {
  @Input() diskInfo: DiskInfoCommunication[] = [];
  @Input() isCurrentComputer: boolean;
  @Input() isVirtual: boolean;
  @Input() partitions: DefaultSelectData[] = [];
  @Input() restorePartitions: any[] = [];
  @Input() backupToRestoreStep: BackupToRestoreStepValue;

  public minimumSize: number;
  public minimumSizeGB: number | string;
  public maximumSizeGB: number | string;

  public partitionsForSelectsData: { [key: string]: Array<DefaultSelectData & { isSelected?: boolean }> } = {};
  public diskItems: DefaultSelectData[] = [];
  public selectedDisk = '';
  public myPartitions: any[] = [];
  public elementsSelector = {
    name: {
      virtualImageName: 'destination-step-virtual-image-name',
      notRestorePlanBlock: 'notRestorePlanBlock',
      isVirtualBlock: 'isVirtualBlock',
      isNotVirtualBlock: 'isNotVirtualBlock',
      restorePlanBlock: 'restorePlanBlock',
      saveLocationAsDefault: 'saveLocationAsDefault',
      notLinuxOrNBFBlock: 'notLinuxOrNBFBlock',
      notLinuxBlock: 'notLinuxBlock',
      isAllRequired: 'isAllRequired',
      isNotUniq: 'isNotUniq',
      isBoot: 'isBoot',
      diskSpace: 'diskSpace',
      notCurrentNotLinux: 'notCurrentNotLinux'
    }
  };

  public headers: TableHeader[] = [
    { name: 'Selected partition', gridColSize: '2fr' },
    { name: 'Volume', overflow: true, gridColSize: '3fr', class: 'w-100' }
  ];

  public numberIsFocused = false;

  private cashStepData = null;

  private getNewTreeParams(): ParamsForTreeData {
    return {
      agentType: 'Backup',
      commandType: 'getComputerContent',
      params: { path: '', offset: 0, limit: 300, order: 'DisplayNameAsc' }
    };
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public mainService: RemoteManagementWizardsService,
    private modalService: ModalService,
    private i18nPipe: I18NextPipe
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();

    if (this.isLinux) {
      this.stepForm.get('saveLocationAsDefault').disable();

      if (!this.isNBF) this.stepForm.get('restoreDeletedFiles').disable();
    }

    this.setValues();
  }

  initForm(): void {
    this.createStepForm();
    this.initFormEvents();
  }

  /**
   * Separated function for unit tests
   * @private
   */
  private createStepForm(): void {
    const virtualImageNameValidators = [Validators.required];

    if (!this.isLinux) {
      virtualImageNameValidators.push(winFileNameValidator);
    }

    this.stepForm = new UntypedFormGroup({
      destinationFolder: new FormControl('', [Validators.required, this.isLinux ? linuxPathValidator : winPathValidator]),
      virtualImageName: new FormControl('', virtualImageNameValidators),
      capacity: new FormControl('0.02', Validators.required),
      toOriginalLocation: new FormControl(false),
      saveLocationAsDefault: new FormControl(false),
      restoreDeletedFiles: new FormControl(false),
      overwriteExistingFiles: new FormControl(false),
      restoreOnlyNewFiles: new FormControl(false),
      restoreNTFSPermissions: new FormControl(false),
      isPhysical: new FormControl(true),
      selectedDisk: new FormControl('', [
        Validators.required,
        this.selectedDiskIsBootValidate.bind(this),
        this.selectedDiskSpaceValidate.bind(this)
      ]),
      selectedSpecificPartitions: new FormArray([], this.selectedSpecificPartitionsValidator.bind(this))
    });
  }

  selectedSpecificPartitionsValidator(control: AbstractControl): ValidationErrors | null {
    if (!control?.value?.length) return null;

    if ((control as any).controls.some((c) => c.errors && c.errors.isAllRequired)) return { isAllRequired: true };
    if ((control as any).controls.some((c) => c.errors && c.errors.isNotUniq)) return { isNotUniq: true };
    if ((control as any).controls.some((c) => c.errors && c.errors.diskSpace)) return { diskSpace: true };
    if ((control as any).controls.some((c) => c.errors && c.errors.isBoot)) return { isBoot: true };

    return null;
  }

  specificPartitionsValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return { isAllRequired: true };

    const dataFromString = control.value.split('--');

    if (!dataFromString[0] || !this.partitions.some((p) => p.value === dataFromString[0])) {
      return { isAllRequired: true };
    }

    const parts = this.stepForm?.get('selectedSpecificPartitions') || null;

    if (!parts || parts?.errors?.isAllRequired) return null;

    const notUniq = parts.value.some((part) => {
      const arrayFromPart = part.split('--');
      return dataFromString[2] !== arrayFromPart[2] && dataFromString[0] === arrayFromPart[0];
    });

    if (notUniq) return { isNotUniq: true };

    const ids = dataFromString[0].split('__');
    const foundDisk: any = this.mainService.Plan$?.value?.DiskVolumes?.find((v) => v.Identity === ids[0] && v.DiskId === ids[1]);

    if (!foundDisk) return null;

    if (foundDisk.Length < +dataFromString[1]) return { diskSpace: true };

    const isBoot = this.diskInfo.some((disk: DiskInfoCommunication) =>
      disk.Volumes.some((v: VolumeInfoCommunication) => v.Identity === ids[0] && v.DiskId === ids[1] && v.IsBoot)
    );

    if (foundDisk.IsBoot || isBoot) return { isBoot: true };

    return null;
  }

  selectedDiskIsBootValidate(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value !== GuidEmpty) {
      const found = this.diskInfo.find((disk) => disk.DiskId === control.value);

      return found && found.Volumes && found.Volumes.some((v) => v.IsBoot) ? { isBoot: true } : null;
    }

    return null;
  }

  selectedDiskSpaceValidate(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value !== GuidEmpty) {
      const found = this.diskInfo.find((disk) => disk.DiskId === control.value);
      const max = this.restorePartitions.reduce((acc, next) => {
        acc += next.length;
        return acc;
      }, 0);

      return found && found.Capacity < max ? { diskSpace: true } : null;
    }

    return null;
  }

  updateRestoreDeletedFilesFromChanges(): void {
    if (!this.isLinux || this.isNBF) {
      this.stepForm.get('restoreDeletedFiles').enable();
      return void this.stepForm.get('restoreDeletedFiles').setValue(this.isCreate || this.value.restoreDeletedFiles);
    }

    this.stepForm.get('restoreDeletedFiles').disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm) {
      if (changes.backupToRestoreStep) {
        if (this.isLinux || (this.isRestorePlan && this.isEdit)) {
          queueMicrotask(() => this.updateRestoreDeletedFilesFromChanges());
        } else {
          this.updateRestoreDeletedFilesFromChanges();
        }
      }

      if (changes.isVirtual) {
        queueMicrotask(() => this.updateFormFromIsVirtual(changes.isVirtual.currentValue));
      }

      if (this.isLinux) {
        queueMicrotask(() => this.stepForm.get('restoreNTFSPermissions').disable());
      } else {
        if (this.isRestorePlan && this.isEdit) {
          queueMicrotask(() => this.stepForm.get('restoreNTFSPermissions').enable());
        } else this.stepForm.get('restoreNTFSPermissions').enable();
      }

      if (changes.isCurrentComputer) {
        this.updateFileRestoreFromIsCurrentComputer();
      }
    }

    if (changes.diskInfo && changes.diskInfo.currentValue && changes.diskInfo.currentValue.length) {
      this.setDiskItems(changes.diskInfo.currentValue);
    }

    if (changes.restorePartitions && changes.restorePartitions.currentValue && changes.restorePartitions.currentValue.length) {
      if (this.stepForm) {
        this.setPartitions(changes.restorePartitions.currentValue);
      } else if (this.isEdit && this.isVirtual) queueMicrotask(() => this.setPartitions(changes.restorePartitions.currentValue));
    }
  }

  updateFileRestoreFromIsCurrentComputer(): void {
    if (!this.isRestorePlan) return;
    if (this.isCurrentComputer) return this.stepForm.get('toOriginalLocation').enable();

    this.stepForm.get('toOriginalLocation').setValue(false);
    this.stepForm.get('toOriginalLocation').disable();
    this.toOriginalLocationChangeHandler(false);
  }

  setMinMaxSizes(restorePartitions): void {
    if (!this.isRestoreIbb || !this.isVirtual) return;

    this.minimumSize = 0;
    this.maximumSizeGB = 0;

    restorePartitions.forEach((part) => {
      if (part.targetSize) this.minimumSize += +part.targetSize;
      if (part.uiDiskLimit && part.uiDiskLimit > this.maximumSizeGB) this.maximumSizeGB = +part.uiDiskLimit.toFixed(2);
    });

    this.minimumSizeGB = !this.minimumSize ? 0.02 : +Math.ceil(this.minimumSize / 1024).toFixed(2);
    this.stepForm.get('capacity').reset(this.minimumSizeGB || 0.02);
  }

  setDiskItems(items: any[]): void {
    this.diskItems = items.map((disk) => ({
      value: disk.DiskId,
      label: `HDD: ${disk.DiskNumber || ''} ${disk.DriveType} ${
        disk.Capacity ? (disk.Capacity / 1024 / 1024 / 1024).toFixed(1) + ' GB' : ''
      } ${disk.Model || ''}`
    }));

    if (this.isCreate && this.diskItems?.length) {
      this.selectedDisk = this.diskItems[0].value;
      setTimeout(() => this.stepForm.get('selectedDisk').setValue(this.diskItems[0].value));
    }
  }

  setPartitions(items: any[]): void {
    this.setMinMaxSizes(items);
    const myPartitionsForm = this.stepForm ? (this.stepForm.get('selectedSpecificPartitions') as FormArray) : new FormArray([]);
    const selectedParts = this.isEdit && this.stepForm ? myPartitionsForm.value : null;

    this.myPartitions = items.map((disk) => {
      const parent = this.diskInfo?.length ? this.diskInfo.find((d) => d.DiskId === disk.diskId) : null;
      const fromForm = selectedParts
        ? selectedParts.find((p) => {
            const id = p.split('--');
            const idNext = id[2] ? id[2].split('__') : [];
            return idNext[0] == disk.identity && idNext[1] == disk.diskId;
          })
        : null;
      const lengthStr = disk.lengthString || StepsHelpers.getDiskSizeFromMB(disk.length / 1024 / 1024) || '-';

      this.partitionsForSelectsData[disk.identityForUiField] = this.partitions.map((p) => {
        return {
          label: p.label,
          value: `${p.value}--${disk.length}--${disk.identityForUiField}`
        };
      });

      return {
        id: disk.identityForUiField,
        label: `${lengthStr} ${
          parent?.Model || disk.mountPointsInfo || disk.label || (disk.typeAsString ? 'Type: ' + disk.typeAsString : '')
        }`,
        restoreAs: `${fromForm ? fromForm.split('--')[0] || disk.identityForUiField || '' : parent ? disk.identityForUiField : ''}--${
          disk.length
        }--${disk.identityForUiField}`
      };
    });

    myPartitionsForm.clear();
    this.myPartitions.forEach((part) => {
      myPartitionsForm.push(new FormControl(part.restoreAs, this.specificPartitionsValidator.bind(this)));
    });

    this.updateFormFromIsVirtual(this.isVirtual);
    this.selectedSpecificPartitionsChangeHandler();
    this.cdr.detectChanges();
  }

  updateForm(value: DestinationStepValue): void {
    this.setFormControls(value);

    if (
      this.isEdit &&
      !this.isVirtual &&
      this.isRestoreIbb &&
      !this.isCurrentComputer &&
      this.restorePartitions &&
      this.restorePartitions.length
    ) {
      this.setPartitions(this.restorePartitions);
    }

    this.selectedDisk = value.selectedDisk;
    if (this.isRestoreIbb) {
      this.updateFormFromIsVirtual(this.isVirtual);
      this.isPhysicalChangeHandler(value.isPhysical);
      if (this.isVirtual) {
        this.stepForm.get('selectedDisk').disable();
        this.stepForm.get('selectedSpecificPartitions').disable();
      }
    }

    !isNil(value.toOriginalLocation) && this.toOriginalLocationChangeHandler(value.toOriginalLocation);
    !isNil(value.overwriteExistingFiles) && this.overwriteChangeHandler(value.overwriteExistingFiles);

    if (this.isRestorePlan) {
      this.stepForm.get('capacity').disable();
      this.stepForm.get('isPhysical').disable();
      this.stepForm.get('selectedDisk').disable();
      this.stepForm.get('selectedSpecificPartitions').disable();
      this.stepForm.get('virtualImageName').disable();

      if (this.isCreate) this.updateFileRestoreFromIsCurrentComputer();
    }
  }

  setFormControls(value: DestinationStepValue): void {
    Object.keys(value).forEach((key) => {
      if (key === 'selectedSpecificPartitions') this.updateSelectedPartitions(value.selectedSpecificPartitions);
      else if (key !== 'valid') this.stepForm.get(key).reset(value[key]);
    });
  }

  updateSelectedPartitions(partitions: string[]): void {
    if (!partitions?.length) return;

    const myPartitionsForm = this.stepForm ? (this.stepForm.get('selectedSpecificPartitions') as FormArray) : new FormArray([]);

    if (!myPartitionsForm?.value?.length && !this.myPartitions?.length) {
      return void partitions?.forEach((part) => myPartitionsForm.push(new FormControl(part, this.specificPartitionsValidator.bind(this))));
    }

    partitions.forEach((part, idx) => myPartitionsForm.controls[idx].setValue(part));
  }

  forceValid(data: any = null): void {
    this.stepForm.markAsTouched();
    FormsUtil.triggerValidation(this.stepForm);
    this.updateFormValidatorsState();
    this.resetValidStateForValidFields();
  }

  updateFormValidatorsState(): void {
    if (!this.isVirtual && !this.stepForm.get('isPhysical').value) {
      (this.stepForm.get('selectedSpecificPartitions') as any).controls.forEach((control) => {
        control.markAsDirty();
        control.markAsTouched();
        control.updateValueAndValidity();
      });
    }
  }

  updateFormFromIsVirtual(isVirtual: boolean): void {
    this.toggleFormControls(['destinationFolder', 'virtualImageName', 'overwriteExistingFiles'], isVirtual);

    if (isVirtual) {
      this.toggleFormControls(['isPhysical', 'selectedDisk', 'selectedSpecificPartitions'], false);

      if (!this.isRestoreIbb) return;

      if (!this.stepForm.get('capacity').value) this.stepForm.get('capacity').reset(this.minimumSizeGB || 0.02);

      return this.stepForm.get('capacity').enable();
    }

    this.stepForm.get('capacity').disable();
    this.stepForm.get('isPhysical').enable();

    if (this.isRestoreIbb) this.isPhysicalChangeHandler(this.stepForm.get('isPhysical').value);
  }

  capacityBlurHandler(): void {
    this.numberIsFocused = false;
    this.updateCapacityInput(+this.stepForm.get('capacity').value, true);
  }

  capacityFocusHandler(): void {
    this.numberIsFocused = true;
  }

  capacityInputHandler(capacity: number): void {
    if (!this.numberIsFocused) this.updateCapacityInput(capacity);
  }

  updateCapacityInput(capacity: number, fromBlur = false): void {
    if (fromBlur && capacity < +this.minimumSizeGB) {
      return this.stepForm.get('capacity').setValue((+this.minimumSizeGB).toFixed(2));
    }

    if (fromBlur && capacity > +this.maximumSizeGB) {
      return this.stepForm.get('capacity').setValue((+this.maximumSizeGB).toFixed(2));
    }

    const capacityDec = capacity ? capacity.toString().split('.') : [];

    if (capacityDec[1]?.length > 2) {
      this.stepForm.get('capacity').setValue(Math.ceil(capacity * 100) / 100);
    }
  }

  includeExcludeInputHandler(event: Event): void {
    const dataForPath: DataForPath = {
      isLinux: this.isLinux,
      regExpAfter: this.regExpForInputAfter,
      regExpBefore: this.regExpForInputBefore,
      split: this.splitForInput,
      join: this.joinForInput,
      splitForBefore: this.splitForInputBefore
    };
    const formattedObj = BaseForStepsHelper.getStringPathFormatted(event, dataForPath);

    if (formattedObj?.needUpdate) this.stepForm.get('destinationFolder').patchValue(formattedObj.path);
  }

  isPhysicalChangeHandler(event: boolean): void {
    if (event) {
      this.stepForm.get('selectedDisk').enable();
      return this.stepForm.get('selectedSpecificPartitions').disable();
    }

    this.stepForm.get('selectedDisk').disable();

    if (!this.stepForm.get('selectedDisk')?.getRawValue()) {
      this.stepForm.get('selectedDisk').reset(this.cashStepData?.selectedDisk || this.diskItems?.[0]?.value || '');
    }

    this.stepForm.get('selectedSpecificPartitions').enable();
  }

  overwriteChangeHandler(event: boolean): void {
    if (event) this.stepForm.get('restoreOnlyNewFiles').enable();
    else {
      this.stepForm.get('restoreOnlyNewFiles').setValue(false);
      this.stepForm.get('restoreOnlyNewFiles').disable();
    }
  }

  toOriginalLocationChangeHandler(event: boolean): void {
    if (isNil(event) || (event as any).target) return;

    this.toggleFormControls(['destinationFolder'], !event);
  }

  destinationFolderTreeOpen(): void {
    const folder = this.stepForm.get('destinationFolder').value;
    const dataForTree = {
      title: this.i18nPipe.transform('wizards:dest_tree_in_modal_title', { format: 'title' }),
      hid: this.mainService.hid,
      resultFolders: folder ? [folder] : [],
      isEncrypted: false,
      hideFileType: true,
      oldBackupContentVariant: true,
      onlyOneSelect: true,
      params: this.getNewTreeParams(),
      dataForPath: this.getValueForOperationsWithPath()
    };

    this.modalService
      .openCustom(TreeInModalComponent, { data: dataForTree, collapsing: true })
      .then((result: any) => {
        if (result?.length) this.stepForm.get('destinationFolder').setValue(result[0]);
      })
      .catch(noop);
  }

  selectedSpecificPartitionsChangeHandler(): void {
    const formArray = this.stepForm.get('selectedSpecificPartitions') as FormArray;
    const keys = Object.keys(this.partitionsForSelectsData);
    const partLength = this.partitionsForSelectsData[keys[0]].length;
    keys.forEach((key) => this.partitionsForSelectsData[key].forEach((el) => (el.isSelected = false)));
    formArray.value.forEach((part: string, idx: number) => {
      const arrayFromPart = part.split('--');
      keys.forEach((key) => {
        if (key !== arrayFromPart[2]) {
          for (let i = 0; i < partLength; i++) {
            const newId = this.partitionsForSelectsData[key][i].value.split('--')[0];
            if (newId === arrayFromPart[0]) {
              this.partitionsForSelectsData[key][i].isSelected = true;
              break;
            }
          }
        }
        this.partitionsForSelectsData[key] = Array.from(this.partitionsForSelectsData[key]);
      });
    });
    this.partitionsForSelectsData = Object.assign({}, this.partitionsForSelectsData);
    this.updateFormValidatorsState();
  }
}
