export enum CommandApiPrefix {
  PowerShellTerminalCmd = 'invokepscommand',
  InvokeActiveCommand = 'invokeactivecommand',
  invokeCommand = 'invokecommand'
}
export interface RmmCommandResponse {
  agentVersion?: string;
  error: boolean;
  result: string;
  taskGuid: string;
}

export interface RmmHubResponse {
  command: {
    id: string;
    asyncID: string;
    withAsyncProgress: boolean;
    parameters: RmmHubResponseParam[];
    taskID: string;
    isDirectRun: boolean;
    externalContext: unknown;
  };
  status: string;
  data: string[];
  records: [];
}

export interface RmmHubResponseParam {
  name: string;
  value: string;
}
