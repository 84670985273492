import { ChangeDetectorRef, Component, forwardRef, Input, NgZone, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { PlanFormatTypeShortNames, PlanMode } from '@models/PlanTypes.enum';
import { ParamsForRCRestoreMethods, WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isCBFBunch } from '@utils/is-cbf-bunch';
import { I18NextPipe } from 'angular-i18next';
import { GuidEmpty, ModalService, ModalSettings, TableHeader } from 'mbs-ui-kit';
import { noop } from 'rxjs';
import { ArchiveType, BackupToRestoreStepValue, BunchItem, IdsForCurrentFormats } from '../../models/backup-to-restore-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const BackupToRestoreStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => BackupToRestoreStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-backup-to-restore-step',
  templateUrl: './backup-to-restore-step.component.html',
  providers: [BackupToRestoreStepValueAccessor]
})
export class BackupToRestoreStepComponent extends StepBase<BackupToRestoreStepValue> implements OnInit {
  @Input() storageId: string;
  @Input() computerName: string;
  public readonly planFormatTypeShortNames = PlanFormatTypeShortNames;
  public readonly idsForCurrentFormats = IdsForCurrentFormats;
  private readonly noDataModalSettings: ModalSettings = {
    header: { title: this.i18nextPipe.transform('wizards:noDataToRestore', { format: 'title' }) },
    footer: { okButton: { show: false }, cancelButton: { text: this.i18nextPipe.transform('buttons:close') } }
  };

  public headers: TableHeader[] = [{ name: '', gridColSize: '10fr', class: '-stretch' }];
  public bunches: BunchItem[] = [];

  @ViewChild('nothingRestore', { static: true, read: TemplateRef }) nothingRestore: TemplateRef<any>;

  constructor(
    private cdr: ChangeDetectorRef,
    public i18nextPipe: I18NextPipe,
    public mainService: RemoteManagementWizardsService,
    private modalService: ModalService,
    private ngZone: NgZone,
    private stepService: WizardStepsService
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      selectedBunch: new FormControl('', [Validators.required]),
      backupPlanName: new FormControl('')
    });

    this.initFormEvents();
  }

  onStepFormChange(value: BackupToRestoreStepValue): void {
    if (this.stepForm.dirty || this.stepForm.touched) this.value = { ...value, valid: this.stepForm.valid };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.stepForm &&
      ((changes.computerName && !changes.computerName.currentValue) ||
        (changes.storageId && (!changes.storageId.currentValue || changes.storageId.currentValue === GuidEmpty || !this.computerName)))
    ) {
      this.resetValues();
    }

    if (changes.computerName && changes.computerName.currentValue && this.storageId && this.storageId !== GuidEmpty) {
      this.getBunches();
    }
  }

  setBunches(bunches: BunchItem[], type: ArchiveType): void {
    this.bunches = bunches
      .filter((bunch: BunchItem) => bunch.type === ArchiveType[type])
      .map((bunch: BunchItem) => {
        if (!bunch.bunchId) bunch.bunchId = bunch.bunchName;
        if (bunch.bunchId === IdsForCurrentFormats.Ibb) bunch.bunchName = bunch.bunchName.replace(' ', '-');

        return bunch;
      })
      .sort((a: BunchItem, b: BunchItem) => (isCBFBunch(a.bunchId) ? 1 : -1));

    if (!this.bunches?.length) this.showInfoModal();
  }

  showInfoModal(): void {
    this.modalService.open(this.noDataModalSettings, this.nothingRestore).finally(noop);
  }

  updateSelectedBunchFromName(): void {
    const backupPlanName = this.stepForm.get('backupPlanName').value;
    const found =
      this.bunches.length === 1
        ? this.bunches[0]
        : this.bunches.find((b: BunchItem) => b.bunchName === backupPlanName || b.bunchId === backupPlanName);

    this.stepForm.get('selectedBunch').markAsTouched();
    this.stepForm.get('selectedBunch').setValue('');

    queueMicrotask(() => {
      this.ngZone.run(() => {
        if (found) {
          this.stepForm.get('selectedBunch').setValue(found.bunchId);
          this.mainService.isNBF = !isCBFBunch(found.bunchId);
        } else if (this.mainService.mode === PlanMode.create) {
          const currentFound = this.isRestoreIbb
            ? this.bunches.find((c: BunchItem) => c.bunchId === IdsForCurrentFormats.Ibb)
            : this.bunches.find((c: BunchItem) => c.bunchId === IdsForCurrentFormats.File || c.bunchId === IdsForCurrentFormats.FileLinux);

          if (currentFound) this.stepForm.get('selectedBunch').setValue(currentFound.bunchId);

          this.mainService.isNBF = false;
        }

        this.loadInfo.emit({ loading: false });
        this.cdr.detectChanges();
      });
    });
  }

  clickHandler(): void {
    if (this.stepForm.dirty) return;

    this.stepForm.markAsDirty();
    this.stepForm.updateValueAndValidity();
  }

  changeSelectedHandler(selectedBunches: string[]): void {
    if (!selectedBunches?.length) {
      this.stepForm.get('selectedBunch').setValue('');
      return void this.cdr.detectChanges();
    }

    const selectedId = selectedBunches[0];
    this.stepForm.get('selectedBunch').setValue(selectedId);
    const bunch = this.bunches.find((b: BunchItem) => b.bunchId === selectedId);

    if (bunch) this.stepForm.get('backupPlanName').setValue(bunch.bunchName);

    this.mainService.isNBF = !isCBFBunch(selectedId);
    this.cdr.detectChanges();
  }

  // Private block
  private resetValues(): void {
    this.bunches = [];
    this.stepForm.get('selectedBunch').setValue('');
    this.stepForm.get('backupPlanName').setValue('');
  }

  private getBunches(): void {
    const type = this.isRestoreIbb ? ArchiveType.DiskImage : ArchiveType.Files;
    this.bunches = [];
    this.stepForm.get('selectedBunch').setValue('');
    const treeParams: ParamsForRCRestoreMethods = {
      agentType: 'backup',
      commandType: 'GetBunchList',
      params: {
        ConnectionId: this.storageId,
        RestoreSourcePrefix: this.computerName,
        Type: type
      }
    };

    this.loadInfo.emit({ loading: true });

    this.stepService
      .getRemoteCommandData(treeParams, this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (bunches) => {
          if (!bunches?.data?.length) return void this.loadInfo.emit({ loading: false });

          this.setBunches(bunches.data, type);
          this.updateSelectedBunchFromName();
        },
        error: () => {
          this.loadInfo.emit({ loading: false });
          this.showInfoModal();
          this.stepForm.get('selectedBunch').setValue('');
          this.stepForm.get('backupPlanName').setValue('');
        }
      });
  }
}
