import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { PlanInfo } from '@models/backup/plan-info-model';
import Computer from '@models/Computer';
import { ComputerPlanBasedModal } from '@models/ComputersModals';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { ToastService } from 'mbs-ui-kit/toast/toast.service';
import { of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-clone-plan-modal',
  templateUrl: './clone-plan-modal.component.html'
})
export class ClonePlanModalComponent implements ComputerPlanBasedModal, OnInit {
  public computer: Computer;
  public plan: PlanInfo;
  public loading = false;

  @ViewChild(ModalComponent, { static: true }) private baseModal;

  public clonePlanForm: UntypedFormGroup = new UntypedFormGroup({
    cloneName: new FormControl('')
  });

  constructor(
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    private facade: ComputerBackupFacade,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.clonePlanForm.setValue({ cloneName: '' });
  }

  /**
   * Set clone name
   */
  handleResolve(): void {
    const cloneName = this.clonePlanForm.get('cloneName').value;

    this.loading = true;

    this.facade
      .clonePlan(this.computer, this.plan?.id, cloneName)
      .pipe(
        map(() => true),
        catchError(() => {
          return of(false);
        }),
        tap(() => {
          this.loading = false;
        }),
        filter(Boolean)
      )
      .subscribe(() => {
        this.facade.loadPlans({ hid: this.computer?.hid, force: true });
        this.toast.success(`The plan has been cloned`);
        this.baseModal.close();
      });
  }
}
