import { PlanItem } from './base-models/plan-item-model';
import { PlanVersionInfo } from './base-models/plan-version-info-model';
import { BaseRestorePlan } from './base-restore-plan-model';

export class RestorePlan extends BaseRestorePlan {
  public RestoreNTFSPermissions: boolean;
  public SkipExistingFiles: boolean;
  public RenameExistingFilesBeforeOverride: boolean;
  public RestoreToOriginalLocation: boolean;
  public Destination: string;
  public OnlyNewFiles: boolean;
  public Items: PlanItem[];
  public Versions: PlanVersionInfo[];
  public RestoreDeletedFiles: boolean;
  public UseBackupDate: boolean;
  public RestoreFromPointInTime: Date | string;
}
