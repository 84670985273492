import { NgModule } from '@angular/core';
import { ComputersSharedModule } from '@components/computers-shared/computers-shared.module';
import { I18NextModule } from 'angular-i18next';
import { OnlineAccessSidepanelHandlerDirective } from './online-access-sidepanel-handler.directive';
import { OnlineAccessSidepanelHandler } from './online-access-sidepanel-handler.service';

const declarations = [OnlineAccessSidepanelHandlerDirective];

@NgModule({
  declarations: declarations,
  exports: declarations,
  providers: [OnlineAccessSidepanelHandler],
  imports: [ComputersSharedModule, I18NextModule]
})
export class OnlineAccessDirectiveModule {}
