const minDynamicMemory = 32;
const maxPossibleDynamicMemory = 1048576; // magic number from agent side
const maxDynamicMemory = 8198;
const possibleMinMaxMemory = [512, 1024, 2048, 4096, 8198];
const minStartupRAM = 512;
const maxStartupRAM = 65536;

/**
 * Checker based on https://utrack.cloudberrylab.com/issue/MBS-15628
 * @param {number} min
 * @param {number} max
 * @param {number} startupRAM
 * @return {(null|Object)}
 */
const dynamicMemoryCheck: (
  min: number,
  max: number,
  startupRAM: number
) => null | { min: number, max: number, reason: 'min' | 'max' } = (min, max, startupRAM) => {
  if (min < minDynamicMemory || min > startupRAM) {
    return {
      min: minDynamicMemory,
      max: startupRAM,
      reason: 'min'
    }
  }

  if (max < startupRAM || max > maxPossibleDynamicMemory) {
    return {
      min: startupRAM,
      max: maxPossibleDynamicMemory,
      reason: 'max'
    }
  }

  return null;
}

export {
  minDynamicMemory,
  maxDynamicMemory,
  maxPossibleDynamicMemory,
  possibleMinMaxMemory,
  minStartupRAM,
  maxStartupRAM,
  dynamicMemoryCheck
}
