import { Pipe, PipeTransform } from '@angular/core';
import { CloudStorageProvider, StorageType } from '@models/StorageType.enum';

const cloudStorageProvider = {
  [CloudStorageProvider.AmazonUB]: 'UbAmazonS3',
  [CloudStorageProvider.Amazon]: 'AmazonS3',
  [CloudStorageProvider.B2]: 'BackblazeB2',
  [CloudStorageProvider.Cloudwatt]: 'CloudWatt',
  [CloudStorageProvider.GlacierChinese]: 'Glacier',
  [CloudStorageProvider.Glacier]: 'Glacier',
  [CloudStorageProvider.Rackspace]: 'Rackspace',
  [CloudStorageProvider.S3Chinese]: 'AmazonS3Chinese',
  [CloudStorageProvider.Swiftstack]: 'SwiftStack',
  [CloudStorageProvider.Thinkon]: 'S3Compatible',
  [CloudStorageProvider.Tiscaly]: 'S3Compatible',
  [CloudStorageProvider.VMwareVCloudAirEMC]: 'VMwareVCloudAir',
  [CloudStorageProvider.VMwareVCloudAirGoogle]: 'VMwareVCloudAirGoogle',
  [CloudStorageProvider.WasabiUB]: 'CblWasabi'
};
const storageType = {
  [StorageType.Akaza]: 'Akaza',
  [StorageType.AmazonCloudDrive]: 'S3Compatible',
  [StorageType.AmazonGlacier]: 'Glacier',
  [StorageType.AmazonS3Chinese]: 'AmazonS3Chinese',
  [StorageType.AmazonS3]: 'AmazonS3',
  [StorageType.Aruba]: 'S3Compatible',
  [StorageType.Auro]: 'Auro',
  [StorageType.AzureFile]: 'AzureFS',
  [StorageType.AzureVM]: 'MicrosoftAzureVM',
  [StorageType.Azure]: 'MicrosoftAzure',
  [StorageType.BackblazeB2]: 'BackblazeB2',
  [StorageType.Caringo]: 'Caringo',
  [StorageType.CblWasabi]: 'CblWasabi',
  [StorageType.CenturyLink]: 'CenturyLink',
  [StorageType.Cisco]: 'Cisco',
  [StorageType.Clodo]: 'Clodo',
  [StorageType.CloudA]: 'CloudA',
  [StorageType.CloudWatt]: 'CloudWatt',
  [StorageType.Cloudian]: 'Cloudian',
  [StorageType.Connectria]: 'S3Compatible',
  [StorageType.Constant]: 'Constant',
  [StorageType.DDN]: 'DDN',
  [StorageType.Dedup]: 'DedupServer',
  [StorageType.DinCloud]: 'DinCloud',
  [StorageType.DreamObjects]: 'S3Compatible',
  [StorageType.Dunkel]: 'S3Compatible',
  [StorageType.EasyStorage]: 'EasyStorage',
  [StorageType.Exoscale]: 'Exoscale',
  [StorageType.FTP]: 'FTP',
  [StorageType.GoogleCloud]: 'GoogleCloud',
  [StorageType.Google]: 'GoogleCloud',
  [StorageType.GreenQloud]: 'S3Compatible',
  [StorageType.HGST]: 'HGST',
  [StorageType.HPCloud]: 'HPCloud',
  [StorageType.HPHelion]: 'HPHelion',
  [StorageType.Havenco]: 'S3Compatible',
  [StorageType.Hitachi]: 'Hitachi',
  [StorageType.HostEurope]: 'S3Compatible',
  [StorageType.IDCFrontier]: 'IDCFrontier',
  [StorageType.LeoNovusS3]: 'LeoNovusS3',
  [StorageType.LocalFS]: 'FileSystem',
  [StorageType.Minio]: 'Minio',
  [StorageType.NetApp]: 'NetApp',
  [StorageType.NiftyCloud]: 'Nifty',
  [StorageType.Numergy]: 'Numergy',
  [StorageType.OVH]: 'OVH',
  [StorageType.OneDrive]: 'OneDrive',
  [StorageType.OpenStack]: 'OpenStack',
  [StorageType.OracleCloud]: 'OracleCloud',
  [StorageType.QNAP]: 'QNAP',
  [StorageType.RackSpace]: 'Rackspace',
  [StorageType.SFTP]: 'SFTP',
  [StorageType.ScalityOriginal]: 'Scality',
  [StorageType.Scality]: 'S3Compatible',
  [StorageType.Seeweb]: 'S3Compatible',
  [StorageType.Selectel]: 'Selectel',
  [StorageType.SoftLayer]: 'SoftLayer',
  [StorageType.SwiftStackS3]: 'SwiftStackS3',
  [StorageType.SwiftStack]: 'SwiftStack',
  [StorageType.ThinkOn]: 'S3Compatible',
  [StorageType.Tiscali]: 'S3Compatible',
  [StorageType.UbAmazonS3]: 'UbAmazonS3',
  [StorageType.VCloudAirEMC]: 'VMwareVCloudAir',
  [StorageType.VCloudAirGoogle]: 'VMwareVCloudAirGoogle',
  [StorageType.Verizon]: 'Verizon',
  [StorageType.Walrus]: 'S3Compatible',
  [StorageType.Wasabi]: 'Wasabi',
  [StorageType.Zetta]: 'Zetta',
  [StorageType.Zettagrid]: 'Zettagrid'
};

@Pipe({
  name: 'storageIcon'
})
export class StorageTypeIconPipe implements PipeTransform {
  transform(value: StorageType | CloudStorageProvider, isCloudStorageProvider?: boolean): string {
    const dict = isCloudStorageProvider ? cloudStorageProvider : storageType;

    return dict[value] ?? 'Default';
  }
}
