import { MbsPopupType, TextBreak, Toast } from 'mbs-ui-kit';
import { ToastService } from 'mbs-ui-kit/toast/toast.service';

export interface Result {
  hid: string;
  name: string;
  displayName: string;
  agentType: string;
  result: {
    method: string;
    ok: boolean;
    type: string;
    title: string;
    detail: string;
    message: string;
    errorCode: number;
  };
}

export function handleRMMError(resultList: Array<Result>, toastService: ToastService) {
  const error = resultList?.find((res) => res.result.ok === false);
  toastService.toast(
    new Toast({
      type: MbsPopupType.danger,
      content: error.result.message,
      textBreak: TextBreak.content
    })
  );
}

export function handleError(error: { message: string }, toastService: ToastService) {
  toastService.toast(
    new Toast({
      type: MbsPopupType.danger,
      content: error.message,
      textBreak: TextBreak.content
    })
  );
}
