<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>{{ 'wizards:type_data_title' | i18next: { format: 'title' } }}</h4>
  </header>

  <mbs-form-group>
    <mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               [label]="'wizards:physical_disk_label' | i18next"
               (change)="changeRestoreAsHandler($event)"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [id]="'restore-as-disk-radio-0'"
               [value]="0">
      <ng-template radioDescription>
        <span class="text-sm">{{'wizards:physical_disk_subtitle' | i18next}}</span>
      </ng-template>
    </mbs-radio>
    <mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               [label]="'wizards:virtual_disk_label' | i18next"
               (change)="changeRestoreAsHandler($event)"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [id]="'restore-as-radio-1'"
               [value]="1">
      <ng-template radioDescription>
        <div class="text-sm mb-2">{{'wizards:virtual_disk_subtitle' | i18next}}</div>
        <mbs-select formControlName="selectedFileFormat"
                    class="mt-3"
                    [label]="'wizards:virtual_format_label' | i18next"
                    [clearable]="false"
                    [id]="'file-format-select'"
                    [items]="fileFormats"></mbs-select>
      </ng-template>
    </mbs-radio>
    <!--TODO ADD This code then will be reade api for Azure EC2 Temporary instance step controls-->
    <!--<mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               label="Restore as Amazon EC2 Instance"
               (change)="changeRestoreAsHandler($event)"
               [descriptionOutside]="false"
               [id]="'restore-as-radio-2'"
               [value]="2">
      <ng-container appendLabel>
            <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                  container="body"
                  ngbTooltip="Supported OS: Windows 2003, 2008, 2012, 2016"></span>
      </ng-container>
      <ng-template radioDescription>
        <mbs-checkbox formControlName="createAMI"
                      label="Create AMI (Amazon Machine Image)"
                      [id]="'create-AMI-checkbox'"></mbs-checkbox>
      </ng-template>
    </mbs-radio>
    <mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               label="Restore as EBS Volume"
               (change)="changeRestoreAsHandler($event)"
               [descriptionOutside]="false"
               [id]="'restore-as-radio-3'"
               [value]="3"></mbs-radio>
    <mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               label="Restore as Azure Virtual Machine"
               (change)="changeRestoreAsHandler($event)"
               [descriptionOutside]="false"
               [id]="'restore-as-radio-4'"
               [value]="4"></mbs-radio>
    <mbs-radio formControlName="restoreAs"
               name="restoreAsRadio"
               label="Restore as Azure Data Disk"
               (change)="changeRestoreAsHandler($event)"
               [descriptionOutside]="false"
               [id]="'restore-as-radio-5'"
               [value]="5"></mbs-radio>-->
  </mbs-form-group>
</section>
