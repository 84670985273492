import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';

@Component({
  templateUrl: './offline-edit-version-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfflineEditVersionModalComponent {
  public readonly alertType = MbsPopupType;

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;

  constructor() {}

  handleClose() {
    this.modal.close();
  }
}
