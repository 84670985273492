<!-- todo anna.art: refactoring MBS-11216 -->
<div class="mbs-page">
  <div class="mbs-page_content d-flex align-items-center justify-content-center flex-column text-center">
    <figure class="mb-4 mb-xl-5">
      <img [src]="imagePath[type]" />
    </figure>

    <h1 class="text-brand mt-0 pb-1 pb-xl-2 mb-3 mb-xl-4">{{ getI18NPath('title') | i18next }}</h1>

    <div class="container pb-1 pb-xl-2 mb-3 mb-xl-4">
      <div class="row justify-content-center">
        <div class="col-3 col-xl-2">
          <div class="border-top"></div>
        </div>
      </div>
    </div>

    <p class="pb-3 pb-xl-4 mb-3 mb-xl-4 pre-wrap">
      {{ getI18NPath('description') | i18next }}
    </p>

    <ng-container *ngIf="type === errorType.Critical">
      <mbs-button type="primary"
                  (click)="refresh()"> {{ getI18NPath('action') | i18next: { format: 'title' } }}</mbs-button>
    </ng-container>

    <ng-container *ngIf="type === errorType.NotFound && !hideNotFoundLink">
      <a href="/AP/Default.aspx"
         class="btn btn-primary">{{ getI18NPath('action') | i18next: { format: 'title' } }}</a>
    </ng-container>
  </div>
</div>
