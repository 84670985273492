import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@mbs-ui/environments/environment';
import Administrator from '@models/Administrator';
import { OldNotificationPerPlanSettings } from '@models/Notification';
import { Period } from '@models/Period.enum';
import { PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { RecurType } from '@models/RecurType.enum';
import { RemoteDeployPlanModel } from '@models/remote-deploy/remote-deploy-plan-model';
import StorageAccount from '@models/StorageAccount';
import { BaseForPlanHelper } from '@modules/wizards/helpers/bases/wizard-base-helpers';
import { MachinesDisksLoadedStatuses } from '@modules/wizards/services/wizard-steps.service';
import { CantOpenModalComponent } from '@modules/wizards/steps/components/cant-open-modal/cant-open-modal.component';
import { AuthService } from '@services/auth.service';
import { RemoteDeployDataService } from '@services/data-services/remote-deploy-data.service';
import { GlobalNotificationService } from '@services/global-notification.service';
import { updatePlanBucketDisplayName } from '@utils/rd-and-wizards/bucketDisplayNameToPlan';
import { ScheduleDataHelper } from '@utils/schedule-data-helper';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType } from 'mbs-ui-kit';
import { ModalService, ModalSettings } from 'mbs-ui-kit/modal/modal.service';
import { ToastService } from 'mbs-ui-kit/toast/toast.service';
import { GuidEmpty } from 'mbs-ui-kit/utils/constants';
import { BehaviorSubject, from, noop, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { StepsDataFromPlanHelper } from '../helpers/steps-data-from-plan-helper/steps-data-from-plan-helper';
import { PlansWizardMode } from '../models/base/base-plan-models';
import { FromTime } from '../models/retention-policy-models';
import { BackupTargetVolumesEnum } from '../models/what-backup-models';

const AGENT_VERSION_FOR_APP_AWARE = 79;

export interface OverdueAfterSuccess {
  enabled: boolean;
  amount: number;
  period: number;
}

export type afterCloseType = { afterClosed: Observable<any> };

export interface SQLIsSysAdminOrCredentialsModel {
  hid: string;
  profile: string;
  isWindowsAuth: boolean;
  instanceName: string;
  userName: string;
  password: string;
  planId: string;
}

export type WizardState = {
  isSQLPlan: boolean;
  isRDMode: boolean;
  isRestore: boolean;
  isHyperV: boolean;
  isVMWare: boolean;
  isAppAwareEnabled: boolean;
};
export interface WizardSettings {
  fromGettingStarted?: boolean;
  compName?: string;
  email?: string;
  hid: string;
  isNBF?: boolean;
  isOffline?: boolean;
  mode: PlanMode;
  planId?: string;
  profile?: string;
  rdConfigurationId?: string;
  type?: PlanTypes;
  userId?: string;
  wizardMode?: PlansWizardMode;
  backupVersion?: string;
  wasNotExecuted?: boolean;
  isLinux?: boolean;
  supressShowingBackupFormatDialog?: boolean;
}

export abstract class RemoteManagementWizardsServiceAbstract implements WizardSettings {
  public fromGettingStarted: boolean;
  public compName = '';
  public email: string;
  public hid: string;
  public isNBF = false;
  public isOffline: boolean;
  public mode: PlanMode = PlanMode.create;
  public profile = '';
  public rdConfigurationId: string;
  public type: PlanTypes = PlanTypes.BackupDiskImagePlan;
  public userId: string;
  public wizardMode: PlansWizardMode = 0;
  public backupVersion: string;
  public wasNotExecuted: boolean;
  public isLinux: boolean;
  public Plan$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public dataBases$: BehaviorSubject<any> = new BehaviorSubject([]);
  public machinesDisksStatuses$: BehaviorSubject<MachinesDisksLoadedStatuses> = new BehaviorSubject(null);
  public password = new BehaviorSubject<string>('');
  public get planId(): string {
    return this.planIdValue;
  }
  public set planId(value: string) {
    this.planIdValue = value || null; // Empty string is not equal null, and it is very important!
  }

  private planIdValue: string;

  public abstract openErrorRunningPlan(): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class RemoteManagementWizardsService extends RemoteManagementWizardsServiceAbstract {
  /**
   * Emits value when a plans is saved in a configuration.
   * Emits tuple of <string, string>.
   * First value is configurationId, second - plans array
   */
  public plansSaved$: Subject<[string, RemoteDeployPlanModel[]]> = new Subject();
  public plansArray$: BehaviorSubject<any> = new BehaviorSubject([]);
  public AvailablePlansForRD$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public error = '';
  public hasAllRequiredDataToGetPlan = false;
  public accountId: string;
  public providerId: string;
  public backupVersionUpdated: string;
  public validVersionForSchedule: boolean;
  public timeZoneOffset: number;
  public isProvider: boolean;
  public currentPrefix: string;
  public allowPrePostActions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public planNotification: OldNotificationPerPlanSettings = new OldNotificationPerPlanSettings();

  public get isRestore(): boolean {
    return RemoteManagementWizardsService.getWizardState(this).isRestore;
  }

  private headerContentType = 'application/json; charset=utf-8';

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private http: HttpClient,
    private modalService: ModalService,
    private notificationService: GlobalNotificationService,
    public rdService: RemoteDeployDataService,
    private i18nPipe: I18NextPipe,
    private router: Router
  ) {
    super();

    authService.currentUser
      .pipe(first((user: Administrator) => user && user.ProviderInfo !== null && user.ProviderInfo !== undefined))
      .subscribe((user) => {
        this.timeZoneOffset = user.ProviderInfo.TimeZoneOffset;
        this.isProvider = user.IsProvider;
      });
  }

  private needShowErrorVersionModal(): boolean {
    return (
      this.isNBF &&
      (this.type === PlanTypes.BackupHyperV || this.type === PlanTypes.BackupVMware) &&
      this.backupVersionUpdated &&
      +this.backupVersionUpdated.substring(0, 3) < 761
    );
  }

  public setAvailablePlansForRD(id: string, plans: any): void {
    this.rdConfigurationId = id;
    const AvailablePlans = { [id]: {} };
    plans.forEach((p) => (AvailablePlans[id][p.plan.ID] = p));
    this.AvailablePlansForRD$.next(AvailablePlans);
  }

  public setWizardAndGetRequiredData(component: any, settings: ModalSettings, settingsForWizard: WizardSettings): afterCloseType {
    this.hasAllRequiredDataToGetPlan = false;
    return this.setDataAndOpenWizard(component, settings, settingsForWizard);
  }

  public setWizard(component: any, settings: ModalSettings, settingsForWizard: WizardSettings): afterCloseType {
    this.hasAllRequiredDataToGetPlan = true;
    return this.setDataAndOpenWizard(component, settings, settingsForWizard);
  }

  public showErrorVersionModalForLinux(): void {
    this.openErrorVersionModal(
      true,
      this.i18nPipe.transform('wizards:wizards_error_agent_version_text_alert'),
      this.i18nPipe.transform('wizards:wizards_error_agent_version_title', { format: 'title' })
    );
  }

  public needShowErrorVersionModalForLinux(isNbf = false): boolean {
    return (
      this.isLinux &&
      (this.isRestore || this.isNBF || isNbf) &&
      this.backupVersionUpdated &&
      +this.backupVersionUpdated.substring(0, 2) < 41
    );
  }

  private setDataAndOpenWizard(component: any, settings: ModalSettings, wizardSettings: WizardSettings): afterCloseType {
    for (const key in wizardSettings) {
      if (!Object.prototype.hasOwnProperty.call(wizardSettings, key)) continue;

      this[key] = wizardSettings[key];
    }
    if (this.backupVersion) {
      this.backupVersionUpdated = this.backupVersion ? this.backupVersion.split('.').join('') : '';
      if (this.backupVersionUpdated.length < 3) this.backupVersionUpdated += '0';
      if (this.needShowErrorVersionModalForLinux()) {
        this.showErrorVersionModalForLinux();
        return { afterClosed: of(null) };
      }
      this.validVersionForSchedule = this.isLinux || (this.backupVersionUpdated && +this.backupVersionUpdated.substring(0, 3) >= 741);
    }

    if (this.needShowErrorVersionModal()) {
      this.openErrorVersionModal();
      return { afterClosed: of(null) };
    } else return this.openWizard(component, settings);
  }

  openErrorVersionModal(isInvalidFields = false, text = '', title = ''): void {
    this.modalService.openCustom(CantOpenModalComponent, { collapsing: true, data: { isInvalidFields, text, title } }).finally(noop);
  }

  openErrorRunningPlan(): Observable<boolean> {
    return from(
      this.modalService.openCustom<boolean>(CantOpenModalComponent, {
        collapsing: true,
        data: {
          isInvalidFields: true,
          title: this.i18nPipe.transform('wizards:common:cannotEdit'),
          text: this.i18nPipe.transform('wizards:common:planIsRunning'),
          alertType: MbsPopupType.warning,
          okButtonText: this.i18nPipe.transform('wizards:buttons:stopPlanAndSaveChanges'),
          cancelButtonText: this.i18nPipe.transform('buttons:cancel')
        }
      })
    );
  }

  closeAndReset(): void {
    this.modalService.dismissAll();
    this.resetData();
  }

  openOnOldPageAndCloseAndReset(): void {
    this.modalService.dismissAll();
    this.openOldEditPagePlan();
    this.resetData();
  }

  openOldEditPagePlan(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/AP/EditPlan2.aspx'], {
        queryParams: {
          hid: this.hid,
          prof: '',
          id: this.mode === PlanMode.edit ? this.planId : this.generateUUID(),
          command: this.mode === PlanMode.edit ? 'edit' : 'newconsistencycheck',
          name: this.compName,
          email: this.email || '',
          userId: this.userId || ''
        }
      })
    );
    window.open(url, '_blank');
  }

  resetData(): void {
    this.fromGettingStarted = false;
    this.isNBF = false;
    this.compName = '';
    this.backupVersionUpdated = null;
    this.password.next('');
  }

  private openWizard(component: any, settings: ModalSettings): afterCloseType {
    const wizard = this.modalService.openRef(component, settings);
    if (wizard) {
      wizard.componentInstance.orientations = 'vertical';
      wizard.result.then(() => this.resetData()).catch(() => this.resetData());
      return { afterClosed: from(wizard.result) };
    }

    return null;
  }

  public GetRMPlanWithAdditionalRequests(): Observable<any> {
    const data = { computerHID: this.hid, profile: this.profile };
    const formData = new FormData();
    for (const key of Object.keys(data)) formData.append(key, data[key]);
    return this.http.post('/Admin/Computers/GetRMComputerInfo', formData).pipe(
      switchMap((info: any) => {
        if (info.isNotAuth) return this.showLoginInfo();
        this.compName = info.ComputerName;
        this.email = info.Email;
        this.isOffline = info.IsOffline;
        this.profile = info.ProfileName;
        const linkArray = info.Link.split('&');
        linkArray.forEach((item) => {
          if (item.indexOf('hid') !== -1) {
            let hid = item.split('=')[1];
            hid = hid.replace('%7b', '{');
            hid = hid.replace('%7d', '}');
            this.hid = hid;
          }
        });
        if (this.mode === PlanMode.create) {
          return of(null);
        }
        return this.GetShortInfo();
      }),
      switchMap((shortInfo: any) => {
        if (this.mode === PlanMode.create) {
          return this.GetRMPlanModel();
        } else if (shortInfo && shortInfo.length) {
          const idx = shortInfo.findIndex(
            (item) => +PlanTypes[item.PlanType] === this.type && (this.planId ? item.PlanId === this.planId : true)
          );
          if (idx !== -1) {
            this.planId = shortInfo[idx].PlanId;
            this.userId = shortInfo[idx].UserId;
            return this.GetRMPlanModel();
          } else {
            this.showErrorAndClose(shortInfo);
            return of(null);
          }
        } else return of(null);
      })
    );
  }

  public GetShortInfo(): Observable<any> {
    const data = {
      computerHID: this.hid,
      profile: this.profile || '',
      email: this.email,
      isOffline: this.isOffline ? 'True' : 'False'
    };
    const formData = new FormData();
    for (const key of Object.keys(data)) formData.append(key, data[key]);
    return this.http.post('/Admin/Computers/GetPlansShortInfo', formData);
  }

  public GetRMPlanModel(settings = null): Observable<any> {
    const params = settings || {
      mode: this.mode.toString(),
      type: this.type.toString(),
      email: this.email,
      planId: this.mode === PlanMode.edit ? this.planId : '',
      userId: this.userId,
      hid: this.hid,
      profile: this.profile || ''
    };

    return this.http
      .get('/Admin/PlansManagement/GetPlan', {
        params,
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      .pipe(
        switchMap((plan: any) => this.processGetPlanResponse(plan)),
        catchError((err: HttpErrorResponse) => {
          this.modalService.dismissAll();

          return throwError(() => err);
        })
      );
  }

  private processGetPlanResponse(plan: any): Observable<any> {
    if (this.wizardMode === PlansWizardMode.forRD) {
      if (!plan || !plan.plan) {
        this.showErrorAndClose(plan);
        return of(null);
      } else {
        this.Plan$.next(plan);
        return of(plan);
      }
    } else {
      if (plan.isNotAuth) {
        this.showLoginInfo();
        return of(null);
      } else if (plan.StatusCode !== 200) {
        this.errorHandlerGetPlan(plan);
        return of(null);
      } else {
        this.Plan$.next(plan.PayLoad);
        return of(plan.PayLoad);
      }
    }
  }

  errorHandlerGetPlan(plan): void {
    if (
      (this.type === PlanTypes.BackupHyperV || this.type === PlanTypes.BackupVMware) &&
      plan.PayLoad.includes('GuidAttributesAsUlong') &&
      plan.PayLoad.includes('VolumeInfoCommunication')
    ) {
      this.modalService.dismissAll();
      this.openErrorVersionModal(+this.backupVersionUpdated.substring(0, 3) >= 761);
    } else {
      this.showErrorAndClose(plan);
    }
  }

  showLoginInfo(): Observable<any> {
    const modalSettings: ModalSettings = {
      header: { title: 'Session Expired' },
      footer: { okButton: { text: 'Login' }, cancelButton: { show: false } }
    };
    this.modalService
      .open(modalSettings, 'Your session has expired. Please log in again.')
      .then(() => this.redirectToLogin())
      .catch(() => this.redirectToLogin());
    return of(null);
  }

  redirectToLogin(): void {
    if (environment.production) {
      this.authService.logoutUser();
    } else {
      this.router.navigate(['login']);
    }
  }

  public UpdateAndRunPlan(plan): Observable<any> {
    const payload = {
      agentType: 'backup',
      commandType: 'UpdatePlan',
      params: {
        planContent: JSON.stringify(plan),
        runAfterSave: true
      }
    };
    return this.http.post<any>(`api/computers/${this.hid}/remotecommand`, payload).pipe(
      map((response) => {
        if (!response) throwError(() => new HttpErrorResponse({ error: response, status: 400 }));
        return response;
      })
    );
  }

  public checkSQLCredentials(params: SQLIsSysAdminOrCredentialsModel): Observable<{
    StatusCode?: number;
    PayLoad: boolean;
  }> {
    return this.http
      .post<{ PayLoad: boolean }>('Admin/PlansManagement/CheckSQLInstanceCredentials', params)
      .pipe(catchError(() => of({ PayLoad: false })));
  }

  public checkSQLInstanceIsSysadminRoleMember(params: SQLIsSysAdminOrCredentialsModel): Observable<{
    StatusCode?: number;
    PayLoad: boolean;
  }> {
    return this.http
      .post<{ PayLoad: boolean }>('Admin/PlansManagement/GetSQLInstanceIsSysadminRoleMember', params)
      .pipe(catchError(() => of({ PayLoad: false })));
  }

  public getDataBases(params: SQLIsSysAdminOrCredentialsModel): Observable<{ PayLoad: boolean; StatusCode?: number }> {
    return this.http.post<{ PayLoad: any[]; StatusCode?: number }>('Admin/PlansManagement/GetDatabases', params).pipe(
      catchError(() => of({ PayLoad: false, StatusCode: HttpStatusCode.ServiceUnavailable })),
      map((result) => {
        const isOk = !!result.PayLoad && result.StatusCode === HttpStatusCode.Ok;

        this.dataBases$.next(isOk ? result.PayLoad : []);

        return { PayLoad: isOk };
      })
    );
  }

  public PreparePlanBeforePostRM(plan): any {
    if (this.mode === PlanMode.create) {
      plan.ID = this.generateUUID();
      this.planId = plan.ID;
    }
    return plan;
  }

  public PostRMPlan(plan, settings = null): Observable<any> {
    const runAfterSave = !!(settings?.fromGettingStarted || this.fromGettingStarted);

    const params: HttpParams = new HttpParams()
      .append('mode', settings?.mode || this.mode.toString())
      .append('type', settings?.type || this.type.toString())
      .append('email', settings?.email || this.email)
      .append('userId', settings?.userId || this.userId)
      .append('hid', settings?.hid || this.hid)
      .append('computerName', settings?.computerName || this.compName)
      .append('profile', settings?.profile || this.profile)
      .append('runAfterSave', runAfterSave.toString());

    return this.http
      .post('Admin/PlansManagement/PostPlan', plan, {
        params: params,
        headers: { 'Content-Type': this.headerContentType }
      })
      .pipe(
        map((response) => {
          if (!response) throwError(() => new HttpErrorResponse({ error: response, status: 400 }));
          return response;
        })
      );
  }

  updateArrayOfPlans(dataModel: RemoteDeployPlanModel): any {
    if (!dataModel.PlanType) dataModel.PlanType = this.type;

    const arrayOfPlansToSave = this.plansArray$.value;

    if (this.mode === PlanMode.create) {
      dataModel.plan.ID = this.generateUUID();
      arrayOfPlansToSave.push(dataModel);
    } else {
      const idx = arrayOfPlansToSave.findIndex((p) => p.plan.ID === (dataModel as any).ID);
      arrayOfPlansToSave[idx].plan = dataModel;
      this.updatePlanTextElements(arrayOfPlansToSave[idx], this.rdService.allStorageAccounts);
      this.updatePlanTextRetention(arrayOfPlansToSave[idx]);
      this.setValidForRDStatuses(arrayOfPlansToSave[idx]);
    }

    this.plansArray$.next(arrayOfPlansToSave);
    this.setAvailablePlansForRD(this.rdConfigurationId, arrayOfPlansToSave);
    return dataModel.plan;
  }

  setValidForRDStatuses(wrapper: RemoteDeployPlanModel): void {
    const plan = wrapper.plan;
    wrapper.invalidForRd =
      !!plan.IsHybridBackup ||
      (plan.HybridID && plan.HybridID !== GuidEmpty) ||
      (plan.HybridRetentionPolicy && !plan.HybridRetentionPolicy.RetentionUseDefaultSettings) ||
      (wrapper.PlanType === PlanTypes.BackupDiskImagePlan &&
        (plan.BackupVolumes === BackupTargetVolumesEnum.SelectedOnly || plan.ExcludeEnabled || this.existSomeExcludeRule(plan.DiskInfo)));
  }

  existSomeExcludeRule(DiskInfo): boolean {
    return (
      !!DiskInfo &&
      !!DiskInfo.length &&
      !!DiskInfo[0].Volumes &&
      !!DiskInfo[0].Volumes.length &&
      DiskInfo[0].Volumes.some((v) => v.BackupOptions && v.BackupOptions.ExcludeRules.length)
    );
  }

  getUpdatePlansForRD(plans: RemoteDeployPlanModel[], storages: StorageAccount[]): RemoteDeployPlanModel[] {
    if (plans && plans.length) {
      return plans.map((plan) => {
        plan = BaseForPlanHelper.ToPaskalCase(plan);
        this.updatePlanTextElements(plan, storages);
        this.updatePlanTextRetention(plan);
        this.setValidForRDStatuses(plan);
        return plan;
      });
    }

    return [];
  }

  updatePlanTextElements(plan: RemoteDeployPlanModel, storageAccounts: StorageAccount[]): void {
    const full = plan.plan.ForceFullSchedule;
    const schedule = plan.plan.Schedule;
    const isPlan = plan.PlanType === PlanTypes.Plan;
    plan.BucketDisplayName = updatePlanBucketDisplayName(plan, storageAccounts);

    if (this.isNBF || plan.plan.IsArchive) {
      const isFull = full && full.Enabled;
      const scheduleArchive = isFull ? plan.plan.ForceFullSchedule : plan.plan.Schedule;

      plan.BucketDisplayName = updatePlanBucketDisplayName(plan, storageAccounts);
      plan.DisplayFullSchedule = scheduleArchive.Enabled
        ? (scheduleArchive.RecurType !== RecurType.Instantly
            ? isFull
              ? 'Full Backup: '
              : plan?.plan?.ForwardIncremental
              ? 'Forever Forward Incremental: '
              : 'Incremental backup: '
            : '') + ScheduleDataHelper.GetScheduleSummaryFromScheduleSettings(scheduleArchive)
        : '';
      plan.DisplaySchedule =
        isFull && plan.plan.Schedule.Enabled && plan.plan.Schedule.RecurType !== RecurType.Instantly
          ? ' Incremental Backup: ' + ScheduleDataHelper.GetScheduleSummaryFromScheduleSettings(plan.plan.Schedule)
          : '';

      return;
    }

    plan.DisplayFullSchedule =
      full && full.Enabled
        ? (schedule.RecurType !== RecurType.Instantly ? (isPlan ? 'Incremental backup: ' : 'Full Backup: ') : '') +
          ScheduleDataHelper.GetScheduleSummaryFromScheduleSettings(full)
        : '';
    plan.DisplaySchedule =
      schedule && schedule.Enabled && schedule.RecurType !== RecurType.Instantly
        ? (isPlan ? ' Block-level Backup: ' : ' Block-level Incremental Backup: ') +
          ScheduleDataHelper.GetScheduleSummaryFromScheduleSettings(schedule)
        : '';
  }

  updatePlanTextRetention(planWrapper: RemoteDeployPlanModel): void {
    const text: string[] = [];
    const step = StepsDataFromPlanHelper.updateRetentionPolicy(planWrapper.plan, false, planWrapper.PlanType, this.isLinux);
    if (!step.RetentionUseDefaultSettings) {
      if (step.deleteVersionsOlderThan) {
        text.push(`Delete versions older than: ${step.deleteVersionsOlderThanCount} ${Period[step.deleteVersionsOlderThanPeriod]}`);
        if (planWrapper.PlanType === PlanTypes.Plan) {
          text.push(`from ${FromTime[step.fromTimeSelected]}`);
        }
      }
      if (step.alwaysKeepLastVersion) text.push('Always keep the last version');
      if (step.keepNumberOfVersions) text.push(`Keep number of versions: ${step.RetentionNumberOfVersions} for each file`);
      if (step.delayPurgeForEnabled) text.push(`Delay purge for: ${step.delayPurgeFor} ${Period[step.deleteVersionsOlderThanPeriod]}`);
      if (step.deleteIfLocallyDeleted) text.push(`Delete after: ${step.deleteAfter} days`);
      if (step.doNotShowWarning) text.push('Do not show warning for files to be deleted');
    }
    if (planWrapper.plan.IsArchive && planWrapper.plan.GFSPolicySettings && planWrapper.plan.GFSPolicySettings.IsEnabled) {
      const GFSSettings = planWrapper.plan.GFSPolicySettings;
      if (GFSSettings.Weekly && GFSSettings.Weekly.IsEnabled) {
        text.push(`Period of Keeping Weekly Full Backups: ${GFSSettings.Weekly.StorePeriod}`);
      }
      if (GFSSettings.Monthly && GFSSettings.Monthly.IsEnabled) {
        text.push(`Period of Keeping Monthly Full Backups: ${GFSSettings.Monthly.StorePeriod}`);
      }
      if (GFSSettings.Yearly && GFSSettings.Yearly.IsEnabled) {
        text.push(`Period of Keeping Yearly Full Backups: ${GFSSettings.Yearly.StorePeriod}`);
      }
      if (GFSSettings.ImmutabilityEnabled) {
        text.push('Immutability Enabled');
      }
    }
    planWrapper.RetentionText = text;
  }

  deletePlanFromArray(planId: string): Observable<boolean> {
    const arrayOfPlans: any[] = Array.from(this.plansArray$.value);
    const index = arrayOfPlans.findIndex((p) => p.plan.ID === planId);
    if (~index) {
      arrayOfPlans.splice(index, 1);
      this.plansArray$.next(arrayOfPlans);
    }
    this.setAvailablePlansForRD(this.rdConfigurationId, arrayOfPlans);
    return of(true);
  }

  public GetPlanNotificationOptions(): void {
    this.http
      .get<OldNotificationPerPlanSettings>(`api/plans/${this.planId}/${this.hid}/${this.userId}/notification-options`)
      .pipe(first((x) => !!x))
      .subscribe((result: OldNotificationPerPlanSettings) => {
        this.planNotification = result;
        this.getGlobalNotification();
      });
  }

  public getGlobalNotification(): void {
    this.notificationService.loaded$.pipe(first()).subscribe((loaded) => !loaded && this.notificationService.get());
  }

  public updateOverdueAlert(overdueAlert: OverdueAfterSuccess, name: string, settings?): Observable<any> {
    const viewModel = {
      userEmail: this.email || settings?.email,
      userId: this.userId || settings?.userId,
      planId: this.planId || settings?.planId,
      planName: name,
      hid: this.hid || settings?.hid,
      computerName: this.compName || settings?.compName,
      overdueAlert: overdueAlert
    };
    return this.http.post('/Admin/Monitoring/UpdateOverdueAlert', viewModel);
  }

  public SavePlanNotification(notification: OldNotificationPerPlanSettings, hid?: string): Observable<any> {
    return this.http.put('api/plans/notification-options', { ...notification, Hid: hid || this.hid });
  }

  public showErrorAndClose(data: any): void {
    this.modalService.dismissAll();
    const message = 'Error. The plan was not loaded, please try again later.';
    if (data.PayLoad === 'USER_NO_DESTINATIONS') {
      this.toastService.error('The user has no backup destinations assigned. Please, consider adding backup destinations to the user.');
    } else if (data.code === 'MBS-ERROR-0001' || data.PayLoad === 'MBS-ERROR-0001') this.toastService.error(data.message || message);
    else this.toastService.error(message);
  }

  public generateUUID(): string {
    let date = new Date().getTime();
    let date2 = (performance && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;
      if (date > 0) {
        r = (date + r) % 16 | 0;
        date = Math.floor(date / 16);
      } else {
        r = (date2 + r) % 16 | 0;
        date2 = Math.floor(date2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  static getWizardState(mainService: RemoteManagementWizardsService): WizardState {
    const isRDMode = mainService.wizardMode === PlansWizardMode.forRD;
    const isRestore = mainService.type === PlanTypes.RestoreDiskImagePlan || mainService.type === PlanTypes.RestorePlan;
    const isSQLPlan = mainService.type === PlanTypes.DataBasePlan;
    const isHyperV = mainService.type === PlanTypes.BackupHyperV;
    const isVMWare = mainService.type === PlanTypes.BackupVMware;
    const rightVersion = +mainService.backupVersionUpdated?.substring(0, 2) >= AGENT_VERSION_FOR_APP_AWARE;
    const isAppAwareEnabled = rightVersion && (isHyperV || isVMWare);

    return {
      isSQLPlan,
      isRDMode,
      isRestore,
      isHyperV,
      isVMWare,
      isAppAwareEnabled
    };
  }
}
