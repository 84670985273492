import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GetBackupContentType, MyTreeElements } from '@models/backup/storages-type';
import { PasswordModalComponent } from '@modules/password-modal/password-modal.component';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { GetBackupContentParams, ParamsForTreeData, WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, ModalComponent, ModalService, ModalSettings, TreeComponent, TreeElement } from 'mbs-ui-kit';
import { BehaviorSubject, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { DataForPath } from '../../../helpers/bases/base-for-steps-helper';
import { ExtendedTreeElement, GenerateTreeDataSettings } from '../../../helpers/steps-helpers';
import { RestorePointItem } from '../../../models/restore-point-models';
import { TreeIconPath } from '../../../models/what-backup-tree-model';
import { RemoteManagementWizardsService } from '../../../services/remote-management-wizards.service';

export type ExtendForModalTreeElement = TreeElement & { originalPath: string; path: string };
type TreeRequestData = { data: { items: any[]; totalCount: number } };

@UntilDestroy()
@Component({
  selector: 'mbs-tree-in-modal',
  templateUrl: './tree-in-modal.component.html',
  styleUrls: ['./tree-in-modal.component.scss']
})
export class TreeInModalComponent implements OnInit {
  public readonly mbsPopupType = MbsPopupType;
  public readonly elementsSelector = {
    name: {
      treeElement: 'tree-element',
      nonNtfsIcon: 'non-ntfs-icon',
      errorAlert: 'error-alert',
      confirmButton: 'confirm-button'
    }
  };

  public showHint = false;
  public isVirtual = false;
  public title = this.i18nPipe.transform('wizards:tree_modal_default_title', { format: 'title' });
  public params: ParamsForTreeData | GetBackupContentParams = null;
  public dataForPath: DataForPath = null;
  public hid = '';
  public hideFileType = false;
  public isEncrypted = false;
  public onlyOneSelect = false;
  public oldBackupContentVariant = false;
  public loading = false;
  public resultFolders: string[] = [];
  public backupVersionUpdated: string;
  public needHide: (items: any) => boolean;
  public notCheckParentIfSelectAll = false;

  public encryptionPasswordHint = '';
  public rootPath = '';
  public separator = ')-(';
  public treeData: TreeElement[] = [];
  public passwordRecoveryEnabled = false;
  public resultPoint: RestorePointItem;

  public allowedRecovery = false;
  public firstLoadError: { title: string; details: string } = null;

  public passwordInvalid = false;

  private isFirstPassError = true;
  private readonly passwordErrorCodes = [2000, 2046];

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  @ViewChildren(TreeComponent) mbsTreeComponent!: QueryList<any>;

  constructor(
    private modalService: ModalService,
    private stepService: WizardStepsService,
    private i18nPipe: I18NextPipe,
    private auth: AuthService,
    public mainService: RemoteManagementWizardsService
  ) {}

  ngOnInit(): void {
    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((user) => {
      this.allowedRecovery = user?.ProviderInfo?.PasswordRecoveryEnabled && user?.IsProvider;
    });

    this.auth.fetchCurrentUser();

    if (this.baseModal && this.baseModal.data) {
      this.initDataFromBaseModelData();
      this.getBaseTreeData();
    }

    this.mainService.password.pipe(skip(1), untilDestroyed(this)).subscribe((password: string) => {
      this.params.params.Password = password || '';
    });
  }

  initDataFromBaseModelData(): void {
    this.title = this.baseModal.data.title;
    this.hid = this.baseModal.data.hid;
    this.resultPoint = this.baseModal.data.resultPoint;
    this.hideFileType = this.baseModal.data.hideFileType;
    this.isVirtual = this.baseModal.data.isVirtual;
    this.needHide = this.baseModal.data.needHide;
    this.params = this.baseModal.data.params;
    this.rootPath = this.baseModal.data.params.params.path;
    this.resultFolders = Array.from(this.baseModal.data.resultFolders);
    this.dataForPath = this.baseModal.data.dataForPath;
    this.onlyOneSelect = !!this.baseModal.data.onlyOneSelect;
    this.oldBackupContentVariant = !!this.baseModal.data.oldBackupContentVariant;
    this.isEncrypted = !!this.baseModal.data.isEncrypted;
    this.notCheckParentIfSelectAll = !!this.baseModal.data.notCheckParentIfSelectAll;
    this.encryptionPasswordHint = this.baseModal.data.encryptionPasswordHint;
    this.backupVersionUpdated = this.baseModal.data.backupVersionUpdated;
  }

  getBaseTreeData(): void {
    this.loading = true;

    this.stepService
      .getRemoteCommandData(this.params, this.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          if (data.data?.items) {
            data.data.items = StepsHelpers.getSortedTreeItems(data.data.items, !!data.data.items.length && isNaN(+data.data.items[0].type));

            this.setTreeDataFromRequestItems(data.data.items);
            this.updateResultFoldersIfOnlyOneSelect();
            this.updateTreeDataFormArray();
          } else this.treeData = [];

          this.loading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;

          this.baseRequestErrorHandler(err);
        }
      });
  }

  getSubtree(root: ExtendForModalTreeElement): Observable<TreeElement[]> {
    const subtree$ = new BehaviorSubject<TreeElement[]>(null);

    this.params.params.path = this.getPathFromRoot(root);
    this.newSubtreeByParams(subtree$, root);

    return subtree$;
  }

  changeTreeHandler(event: TreeElement): void {
    const str = this.getPathStrFromElementOrParentElement(event);

    if (event.parent) {
      const strParent = this.getPathStrFromElementOrParentElement(event, true);
      let pathStrings = this.getFilteredPathArray(str, strParent);

      pathStrings = event.checked
        ? this.updatePathStringsIfTreeItemChecked(event, pathStrings, strParent)
        : this.updatePathStringsIfTreeItemNotChecked(event, pathStrings);

      this.resultFolders = [...new Set(pathStrings)];

      return void this.updateTreeAfterChange(event);
    }

    const pathStrings = this.resultFolders.filter((s) => (this.onlyOneSelect ? false : !s.includes(str) && !str.includes(s)));

    if (event.checked) pathStrings.push(str);

    this.resultFolders = pathStrings;

    this.updateTreeAfterChange(event);
  }

  save(): void {
    this.baseModal.save(this.resultFolders);
  }

  close(): void {
    this.baseModal.close();
  }

  // Private functions block
  private updateAllParentItems(parent: TreeElement, pathStrings: string[]): string[] {
    parent.children.forEach((c) => {
      const shownCheckedAndNotIndeterminate = c.shown && c.checked && (!c.children?.length || !c.indeterminate);

      if (shownCheckedAndNotIndeterminate) {
        pathStrings.push(
          StepsHelpers.getPathInStringFormatByOs(c, this.dataForPath.isLinux, !this.oldBackupContentVariant, this.isVirtual)
        );
      }
    });

    const parentStrLen = (parent.label as string).length + 1;
    const newPathStrings = pathStrings.filter((str) => {
      return parent?.parent
        ? !str.endsWith(parent.label as string) || str[str.length - parentStrLen] !== this.dataForPath.join
        : str !== parent.label;
    });

    if (parent?.parent) return this.updateAllParentItems(parent.parent, newPathStrings);

    return newPathStrings;
  }

  private updateAllParentItemsChecked(parent: TreeElement, strParent = ''): string {
    if (strParent) {
      parent.checked = true;
      parent.indeterminate = false;
    }

    if (parent.parent && !parent.parent.children.some((c) => c.shown && !c.checked)) {
      return this.updateAllParentItemsChecked(parent.parent, this.getPathStrFromElementOrParentElement(parent, true));
    }

    return strParent.replace(this.rootPath, '');
  }

  private getIndeterminateOrCheckedParent(item: ExtendedTreeElement, stringsArray: string[]): ExtendedTreeElement {
    if (!item.parent) return item;

    const existPath = stringsArray.some((s) => StepsHelpers.equalStrings(s, this.getPathFromItem(item), this.dataForPath.isLinux));

    if ((item.indeterminate || !item.checked) && existPath) return item;

    return this.getIndeterminateOrCheckedParent(item.parent, stringsArray);
  }

  private updateAllChildren(children: ExtendedTreeElement[], stringsArray: string[]): void {
    children.forEach((c) => {
      if (c.shown && c.checked) stringsArray.push(this.getPathFromItem(c));
      if (c.children?.length) this.updateAllChildren(c.children, stringsArray);
    });
  }

  private switchToFalseAllChildren(children: ExtendedTreeElement[]): void {
    children.forEach((c) => {
      if (c.shown) c.checked = false;
      if (c.children?.length) this.switchToFalseAllChildren(c.children);
    });
  }

  private getPathFromItem(item: ExtendedTreeElement): string {
    if (this.isVirtual) return (item as ExtendForModalTreeElement).originalPath || (item as ExtendForModalTreeElement).path;

    return (item as ExtendForModalTreeElement).path?.replace(this.rootPath, '') || (item.label as string).replace(this.rootPath, '');
  }

  private updateTreeAfterChange(event: TreeElement): void {
    if (!this.onlyOneSelect) return;

    if (!event.checked) {
      let parent: TreeElement = event.parent || event;

      while (parent?.parent) {
        parent.checked = false;
        parent.indeterminate = false;
        parent = parent.parent;
      }
    }

    if (event.children?.every((c) => c.checked)) this.switchToFalseAllChildren(event.children);
  }

  private updatePathStringsIfTreeItemNotChecked(event: TreeElement, strings: string[]): string[] {
    let pathStrings = [...strings];

    if (!event.parent.indeterminate && !event.parent.checked && event.parent.parent) {
      const indeterminateOrChecked = this.getIndeterminateOrCheckedParent(event.parent, pathStrings);
      const newStr = this.getPathFromItem(indeterminateOrChecked);

      pathStrings = pathStrings.filter((s) => {
        return (
          !StepsHelpers.equalStrings(s, newStr, this.dataForPath.isLinux) &&
          StepsHelpers.pathNotIncludePath(this.dataForPath.isLinux, this.dataForPath.join, newStr, s)
        );
      });

      this.updateAllChildren(indeterminateOrChecked.children, pathStrings);
    }

    if (!event.parent.children.some((c) => c.shown && c.checked && c.id !== event.id)) {
      StepsHelpers.uncheckAllParent(event.parent, true, true);

      return pathStrings;
    }

    if (!this.onlyOneSelect) {
      pathStrings = this.updateAllParentItems(event.parent, pathStrings);
    } else {
      event.parent.children.forEach((c) => (c.checked = false));
      StepsHelpers.uncheckAllParent(event.parent, true, true);
    }

    return pathStrings;
  }

  private setTreeDataFromRequestItems(items: any[]): void {
    this.treeData = items.map((item, idx) => {
      const pathArray = item.path.split(this.dataForPath.join);
      const isAlt = isNaN(+item.type);
      const isVirtualOrLinux = this.isVirtual || this.dataForPath.isLinux;
      let gotChildren = isAlt ? !item.type || item.type === 'File' : +item.type === 3;
      const originalPath = isAlt
        ? pathArray[pathArray.length - 1].toUpperCase() + this.dataForPath.join
        : isVirtualOrLinux
        ? item.path
        : item.path.toUpperCase();

      if (this.isVirtual && !item.isCanExcludeContent) {
        gotChildren = true;
      }

      return {
        label:
          isVirtualOrLinux || item.type === GetBackupContentType.Folder || item.type === GetBackupContentType.File
            ? item.displayName
            : originalPath,
        originalPath: originalPath,
        id: `disk-${idx}`,
        icon: isAlt
          ? StepsHelpers.getIconFromType(item.type, isAlt)
          : +item.type === 1
          ? TreeIconPath.Folder
          : TreeIconPath[+item.type || 'Disk'],
        gotChildren,
        hideCheckbox: this.needHide ? this.needHide(item) : false,
        showNTFSAlert: this.isVirtual && !item.isCanExcludeContent,
        checked: false,
        expanded: false,
        shown: true
      };
    });
  }

  private updateResultFoldersIfOnlyOneSelect(): void {
    if (!this.onlyOneSelect) return;

    const lastIsJoin = this.resultFolders[0]?.endsWith(this.dataForPath.join);
    const isRoot =
      this.resultFolders[0] === this.dataForPath.join ||
      (this.resultFolders[0]?.length === 3 && /^([a-zA-Z]:\\)/.test(this.resultFolders[0]) && lastIsJoin);

    if ((this.treeData[0] as any).originalPath.length === 2 && !isRoot && lastIsJoin) {
      this.resultFolders[0] = this.resultFolders[0].slice(0, -1);
    }
  }

  private baseRequestErrorHandler(error: HttpErrorResponse): void {
    if (this.isEncrypted && error?.error?.errorCode && this.passwordErrorCodes.includes(+error.error.errorCode)) {
      return void this.passErrorHandler(+error.error.errorCode);
    }

    const title = error.error?.title || (error as any).title;
    const message = title ? error.error?.detail : error.error?.message || error.message;

    delete this.title;
    this.firstLoadError = { title: message ? title : undefined, details: message || title };
  }

  private passErrorHandler(code: number, root: TreeElement = null, subtree$: BehaviorSubject<TreeElement[]> = null): void {
    if (code !== 2000) return void this.showPasswordModal(root, subtree$);

    if (!this.isFirstPassError) this.passwordInvalid = true;

    this.isFirstPassError = false;

    this.showPasswordModal(root, subtree$);
  }

  private updateTreeDataFormArray(): void {
    const functionName = this.oldBackupContentVariant ? 'updateTreeItemFromOldRequest' : 'updateTreeItemFromNewRequest';

    this.treeData.forEach((item: TreeElement) => this[functionName](item));
  }

  private updateTreeItemFromNewRequest(item: TreeElement): void {
    const path = this.dataForPath.isLinux || this.isVirtual ? (item as ExtendForModalTreeElement).originalPath : (item.label as string);
    const itemPathLen = path?.length || 0;
    const existStr = StepsHelpers.findStrForGetSubtree(this.resultFolders, path, this.dataForPath.join, this.dataForPath.isLinux, true);
    const existLen = existStr?.length || 0;

    item.checked = !!existStr && existLen === itemPathLen;

    if (this.dataForPath.isLinux && path === this.dataForPath.join) {
      const newInc = StepsHelpers.findInNotRootFolder(this.resultFolders, this.treeData);
      item.indeterminate = !!newInc.length && !item.checked;

      return;
    }

    item.indeterminate = existLen > itemPathLen && !item.checked;
  }

  private updateTreeItemFromOldRequest(item: TreeElement): void {
    const isLinux = this.dataForPath.isLinux;
    const joinStr = this.dataForPath.join;
    const path = isLinux ? (item as ExtendForModalTreeElement).originalPath : (item.label as string);

    if (isLinux && path === joinStr) {
      const newInc = StepsHelpers.findInNotRootFolder(this.resultFolders, this.treeData, true);
      item.checked = (this.onlyOneSelect && !!newInc.length) || this.resultFolders.some((str) => str === path);
      item.indeterminate = !!newInc.length && !item.checked;

      return;
    }

    const incCurrent = this.resultFolders.some((incStr) => !StepsHelpers.pathNotIncludePath(isLinux, joinStr, incStr, path));
    item.checked = this.onlyOneSelect
      ? this.resultFolders.some(
          (s) => StepsHelpers.equalStrings(path, s, isLinux) || !StepsHelpers.pathNotIncludePath(isLinux, joinStr, s, path)
        )
      : this.resultFolders.some((s) => StepsHelpers.equalStrings(path, s, isLinux));
    item.indeterminate = incCurrent && !item.checked;
  }

  private getPathFromRoot(root: ExtendForModalTreeElement): string {
    if (!root.parent) {
      return !this.isVirtual && !this.oldBackupContentVariant ? `${this.rootPath}\\${root.originalPath}` : root.originalPath;
    }

    return (root as any).path;
  }

  private newSubtreeByParams(subtree$: BehaviorSubject<TreeElement[]>, root: MyTreeElements = null): void {
    this.params.params.offset = root?.children?.length | 0;

    this.stepService
      .getRemoteCommandData(this.params, this.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => subtree$.next(this.getTreeItemsAndUpdateRoot(data, root)),
        error: (error: HttpErrorResponse) => {
          if (this.isEncrypted && error?.error?.errorCode && this.passwordErrorCodes.includes(+error.error.errorCode)) {
            return void this.passErrorHandler(+error.error.errorCode, root, subtree$);
          }

          subtree$.next([]);
        }
      });
  }

  private getTreeItemsAndUpdateRoot(data: TreeRequestData, root: MyTreeElements): TreeElement[] {
    const settings = this.getSettingsForTreeHelper(root, data);
    const newResultForm = this.resultFolders.map((str) => (!this.isVirtual ? this.rootPath + str : str));

    if (data?.data?.items?.length) {
      data.data.items = StepsHelpers.getSortedTreeItems(data.data.items, settings.alt);
    }

    const treeData = StepsHelpers.generateTreeData(data, newResultForm, [], settings).filter((f) => (this.hideFileType ? f.shown : true));

    if (this.notCheckParentIfSelectAll) this.updateNewTreeElementsIfNotCheckParentIfSelectAll(treeData, newResultForm, root);

    this.onlyOneSelectUpdate(root, treeData);

    if (this.hideFileType && data?.data?.items.length === data?.data?.totalCount) {
      root.totalChildren = (treeData.length || 0) - 1;
    } else {
      root.totalChildren = StepsHelpers.getTotalChildren(data?.data, treeData.length);
    }

    return treeData;
  }

  private getSettingsForTreeHelper(root: MyTreeElements, data: TreeRequestData): GenerateTreeDataSettings {
    return {
      root,
      alt: !!data?.data?.items?.length && isNaN(+data.data.items[0].type),
      newInc: [],
      newExc: [],
      isManually: false,
      joinStr: this.dataForPath.join,
      sep: this.separator,
      hideFileType: this.hideFileType,
      onlyOneSelect: this.onlyOneSelect,
      isLinux: this.dataForPath.isLinux,
      isVirtual: this.isVirtual,
      needHide: this.needHide
    };
  }

  private updateNewTreeElementsIfNotCheckParentIfSelectAll(treeData: ExtendedTreeElement[], folders: string[], root: TreeElement): void {
    const rootStr = this.getPathStrFromElementOrParentElement(root, false);

    /**
     * Add mock hidden element for force root indeterminate state if:
     * 1) Root indeterminate
     * 2) Root is not checked
     * 3) All root children is checked and not indeterminate
     * 4) Not exist path into folders list equal root path
     * */
    const needPushVoid =
      root.indeterminate &&
      !root.checked &&
      treeData.every((c) => c.checked && !c.indeterminate) &&
      !folders.some((s) => StepsHelpers.equalStrings(s, rootStr, this.dataForPath.isLinux));

    if (needPushVoid) treeData.push({ label: '', id: root.id + this.separator, shown: false });
  }

  private onlyOneSelectUpdate(root, treeData): void {
    if (this.onlyOneSelect) treeData.forEach((e) => (e.indeterminate = false));

    if ((this.onlyOneSelect || root.onlyOneSelect) && root.checked && !treeData.some((e) => !e.shown)) {
      treeData.push({
        label: '',
        id: root.id + this.separator,
        shown: false,
        checked: this.onlyOneSelect && !treeData.some((e) => e.checked)
      });
    }

    if (
      this.onlyOneSelect &&
      treeData.every((c) => !c.shown || c.checked) &&
      this.resultFolders.some((s) =>
        StepsHelpers.equalStrings(s, root.originalPath || this.getPathFromItem(root), this.dataForPath.isLinux)
      )
    ) {
      treeData.forEach((c) => (c.checked = !c.shown));
    }
  }

  private showPasswordModal(root: TreeElement = null, subtree$: BehaviorSubject<TreeElement[]> = null): void {
    this.modalService
      .openCustom(PasswordModalComponent, this.getSettingsForPasswordModal())
      .then((result: { password: FormControl<string> }) => this.actionsAfterSetPasswordFromModal(root, subtree$, result))
      .catch(() => {
        if (root && subtree$) {
          this.mainService.password.next('');

          return void subtree$.error(1);
        }

        this.close();
      });
  }

  private getSettingsForPasswordModal(): ModalSettings {
    return {
      data: {
        password: this.mainService.password.value || '',
        hid: this.mainService.hid,
        passwordInvalid: this.passwordInvalid,
        showHint: false,
        passwordRecoveryEnabled: this.allowedRecovery && this.mainService.isNBF && !this.dataForPath.isLinux,
        backupVersionUpdated: this.mainService.backupVersionUpdated,
        restorePoint: this.resultPoint
      },
      collapsing: true
    };
  }

  private actionsAfterSetPasswordFromModal(
    root: TreeElement = null,
    subtree$: BehaviorSubject<TreeElement[]> = null,
    result: { password: FormControl<string> }
  ): void {
    const passControl = result.password;

    this.mainService.password.next(passControl.value);
    this.params.params.Password = passControl.value;

    if (passControl.valid) {
      root && subtree$ ? this.newSubtreeByParams(subtree$, root) : this.getBaseTreeData();

      return;
    }

    if (root && subtree$) {
      this.mainService.password.next('');

      return void subtree$.error(1);
    }

    this.close();
  }

  private getPathStrFromElementOrParentElement(event: TreeElement, isParent = false): string {
    if (isParent) {
      return (
        (this.isVirtual || this.dataForPath.isLinux
          ? (event.parent as ExtendForModalTreeElement).originalPath || (event.parent as ExtendForModalTreeElement).path
          : (event.parent as ExtendForModalTreeElement).path?.replace(this.rootPath, '')) ||
        (event.parent.label as string).replace(this.rootPath, '')
      );
    }

    return (
      ((this.isVirtual || this.dataForPath.isLinux
        ? (event as ExtendForModalTreeElement).originalPath || (event as ExtendForModalTreeElement).path
        : (event as ExtendForModalTreeElement).path) || event.label) as string
    ).replace(this.rootPath, '');
  }

  private getFilteredPathArray(str: string, strParent: string): string[] {
    const strParLen =
      !this.isVirtual && !strParent.startsWith(this.dataForPath.join) ? (this.dataForPath.join + strParent).length : strParent.length;

    if (this.onlyOneSelect) return [];

    return this.resultFolders.filter((s) => {
      const sLast = s[strParLen];

      if (!s.includes(strParent) || (sLast && sLast !== this.dataForPath.join)) return true;

      if (!StepsHelpers.pathNotIncludePath(this.dataForPath.isLinux, this.dataForPath.join, s, str)) return false;

      const sStartJoinSymbol = s.startsWith(this.dataForPath.join);
      const strParentStartJoinSymbol = strParent.startsWith(this.dataForPath.join);

      if (sStartJoinSymbol === strParentStartJoinSymbol) {
        return s.split(this.dataForPath.join).length - strParent.split(this.dataForPath.join).length > 1;
      }

      return sStartJoinSymbol
        ? s.split(this.dataForPath.join).length - strParent.split(this.dataForPath.join).length > 2
        : s.split(this.dataForPath.join).length - strParent.split(this.dataForPath.join).length > 0;
    });
  }

  private updatePathStringsIfTreeItemChecked(event: TreeElement, strings: string[], strParent: string): string[] {
    let pathStrings = [...strings];

    if (this.notCheckParentIfSelectAll) {
      this.updateTreeIfExistParentAndCheckedNodeNotCheckParent(pathStrings, event);

      return pathStrings;
    }

    if (!this.onlyOneSelect && !event.parent.children.some((c) => c.shown && !c.checked)) {
      const newParentStr = this.updateAllParentItemsChecked(event.parent, strParent);

      pathStrings = pathStrings.filter(
        (s) =>
          StepsHelpers.pathNotIncludePath(this.dataForPath.isLinux, this.dataForPath.join, s, newParentStr) &&
          StepsHelpers.pathNotIncludePath(this.dataForPath.isLinux, this.dataForPath.join, s, this.dataForPath.join + newParentStr)
      );

      pathStrings.push(newParentStr);

      return pathStrings;
    }

    this.updatePathArrayFromChildren(pathStrings, event.parent.children);

    if (this.onlyOneSelect && event.children?.length && !event.children.some((e) => !e.shown)) {
      event.children.push({ label: '', id: event.id + this.separator, shown: false, checked: true });

      this.switchToFalseAllChildren(event.children);
    }

    return pathStrings;
  }

  private updateTreeIfExistParentAndCheckedNodeNotCheckParent(pathStrings: string[], event: TreeElement): void {
    this.updatePathArrayFromChildren(pathStrings, event.parent.children);
    StepsHelpers.uncheckAllParent(event.parent, true, true);
  }

  private updatePathArrayFromChildren(pathStrings: string[], children: TreeElement[]): void {
    children.forEach(
      (c) =>
        c.shown &&
        c.checked &&
        pathStrings.push(
          StepsHelpers.getPathInStringFormatByOs(
            c,
            this.dataForPath.isLinux,
            !this.oldBackupContentVariant,
            this.isVirtual,
            this.dataForPath.isLinux && this.oldBackupContentVariant
          )
        )
    );
  }
}
