export enum BuildType {
  NA = 0,
  Desktop = 1, // 1
  WHS = 2, // 2
  Vail = 4, // 3
  Aurora = 8, // 4
  Mac = 16, // 5
  SqlServer = 32, // 6
  Exchange = 64, // 7
  SqlExchange = 128, // 8
  NetworkShares5 = 256, // 9
  NetworkShares20 = 512, // 10
  LinuxEc2SnapshotAgent = 1024, // 11
  BareMetal = 2048, // 12 Server
  VMware = 4096, // 13
  LinuxUbuntu12 = 8192, // 14
  LinuxUbuntu14 = 16384, // 15
  LinuxMac = 32768, // 16
  Box = 65536, // 17
  CommonWindowsBuild = 131072, // 18
  LinuxRedHat7 = 262144, // 19
  LinuxRedHat6 = 524288, // 20
  ADBridge = 1048576, // 21
  Dedup = 2097152, // 22
  AndroidOnlineAccess = 4194304, // 23
  IOSOnlineAccess = 8388608, // 24

  Drive = 16777216, // 25
  Enterprise = 33554432, // 26
  Server = 67108864, // 27

  Synology370 = 134217728, // 0x08000000 //28 DSM6
  Synologyx64 = 268435456, // 0x10000000 //29 DSM6
  Qnapx64 = 536870912, // 0x20000000 //30

  QnapARMx31 = 536870913, // 31
  QnapARMx41 = 536870914, // 32
  Synology6281 = 536870920, // 33
  SynologyDSM52_370 = 536870921, // 34
  SynologyDSM52_x64 = 536870922, // 35
  LinuxRedHat5 = 536870930, // 36
  LinuxDebian7 = 536870931, // 37
  Ultimate = 536870940, // 38

  RemoteAssistant = 536870950, // 39
  CBLRA = 536870951, // 40

  // RMM
  RMMAgent = 536870960, // 41

  DesktopIBB = 536870970, // Desktop IBB
  QuickRestore = 536870980,

  DeepInstinct = 536870990,

  RMMAgentMac = 536871000, // 42
  RMMAgentLinuxDeb = 536871001, // 43
  RMMAgentLinuxRpm = 536871002, // 44,

  NetworkDiscovery = 536871010 // 46
}

export function getBuildTypeKeysByValues(values: BuildType[]): (keyof typeof BuildType)[] {
  return (values || []).map((value) => BuildType[value] as keyof typeof BuildType);
}
