import { DeepPartial } from '@utils/types/DeepPartial';
import { BuildType } from './BuildType.enum';
import { MBSEntities } from './entity-decorator';
import { LicenseType } from './LicenseType.enum';
import { NotificationCustom } from './Notification';
import { QueryPagingParams, QuerySortParams } from './Paging';
import { StorageType } from './StorageType.enum';

export enum LicensesSettings {
  Custom = 0,
  GlobalPool = 1,
  CompanyPool = 2
}

export enum LimitsMode {
  Unlimited,
  CompanyLimit
}

export enum LicensesLimitsSettings {
  NoLimits,
  CompanyLimits
}

export enum LicensesPoolType {
  GlobalPool = 0,
  UserPool = 1
}

export enum EditionsSettings {
  None = -1,
  All = 0,
  Specific = 1,
  OnlyAllowed = 2
}

export class LicensesGranted {
  licenseType: LicenseType;
  activatedCount?: number;
  trialCount?: number;
  available?: number;
  granted: number;
}

export class Destinations {
  id?: string;
  displayStorageName: string;
  displayDestinationName: string;
  storageAccountId: string;
  storageAccountType: StorageType;
  storageAccountState?: string;
  bucketName: string;
  bucketId: string;
  path?: string;
  storageLimitId?: string;
  storageLimitName?: string;
  storageLimitVolume?: number;
  region?: string;
  regionDisplayName?: string;
}

export enum AuthType {
  LOGIN_PASS,
  AD_BRIDGE,
  AD_DIRECT
}

interface CompanyLicenseType {
  id: number;
  displayName: string;
}

export interface AllowedLicense {
  licenseType: CompanyLicenseType;
  isAllow: boolean;
}

export interface Limit {
  licenseType: CompanyLicenseType;
  assigned: number;
  limit: number;
}

export interface CompanyPoolSetting {
  licenseType: CompanyLicenseType;
  assigned: number;
  companyPool: number;
  globalPool: number;
}

interface AssignLicensesFrom {
  mode: LicensesSettings;
  companyPoolSettings: Array<CompanyPoolSetting>;
}

export interface LimitSettings {
  mode: number;
  limits: Array<Limit>;
}

export interface LicenseSettings {
  allowedLicenses: Array<AllowedLicense>;
  defaultLicense: CompanyLicenseType;
  assignLicensesFrom: AssignLicensesFrom;
  limitSettings: LimitSettings;
}

@MBSEntities.register()
export default class Company {
  id: string;
  name?: string;
  administrators: string[] = [];
  defaultCompany = false;
  notificationSettings: NotificationCustom = new NotificationCustom();
  availableEditions = EditionsSettings.All;
  specificEditions: BuildType[];
  defaultEdition = BuildType.NA;
  licensesSettings = LicensesSettings.GlobalPool;
  licenseSettings: DeepPartial<LicenseSettings>;
  licensesGranted: LicensesGranted[] = [];
  defaultDestinations: Destinations[] = [];
  inheritedDestinations: Destinations[] = [];
  storageLimit = -1;
  agentSettingsId: string;
  backupDestinationsCount?: number;

  readonly usersCount: number;
  readonly licensesCount: number;
  readonly storageUsage: number;
  disabled?: boolean;
}

export type CompaniesFilters = {
  search?: string;
  licensePoolType?: number;
};

export enum CompaniesAPIFilterKeys {
  Search = 'search',
  LicensePoolType = 'licensePoolType'
}

export type CompaniesSortFiltersParams = Partial<QuerySortParams> & CompaniesFilters;
export type CompaniesQueryParams = CompaniesSortFiltersParams & Partial<QueryPagingParams>;
