export enum UiStorageKey {
  AllowOfflineEdit = 'AllowOfflineEdit',
  BackupVersionsHashCode = 'backupVersionsHashCode',
  HidePasswordRecoveryModal = 'hidePasswordRecoveryModal',
  HidePasswordRecoveryComputersModal = 'hidePasswordRecoveryComputersModal',
  HideMainUserMenuBadge = 'hideMainUserMenuBadge',
  HideMSP2FAModal = 'hideMSP2FAModal',
  RmmVersionsHashCode = 'rmmVersionsHashCode',
  ShowOfflineEditBanner = 'ShowOfflineEditBanner'
}

export type UiStorageType = {
  [UiStorageKey.AllowOfflineEdit]?: string;
  [UiStorageKey.BackupVersionsHashCode]?: string;
  [UiStorageKey.HideMainUserMenuBadge]?: 'true' | 'false';
  [UiStorageKey.HideMSP2FAModal]?: 'true' | 'false';
  [UiStorageKey.HidePasswordRecoveryModal]?: 'true' | 'false';
  [UiStorageKey.HidePasswordRecoveryComputersModal]?: 'true' | 'false';
  [UiStorageKey.RmmVersionsHashCode]?: string;
  [UiStorageKey.ShowOfflineEditBanner]?: 'true' | 'false';
};
