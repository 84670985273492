import { Pipe, PipeTransform } from '@angular/core';
import { PlansUtil } from '../plans-util';
import { PlanTypes } from "@models/PlanTypes.enum";

@Pipe({
  name: 'planType'
})
export class PlanTypePipe implements PipeTransform {
  transform(value: number, args?: any): any {
    const typeValue = typeof value;
    const newValue = typeValue === 'number' ? value : typeValue === 'string' ? +PlanTypes[value] : null;
    if (newValue) {
      let prop = null;
      Array.isArray(args) ? (prop = args[0]) : (prop = args);

      switch (prop) {
        case 'name':
          return PlansUtil.getName(newValue);
        case 'iconClass':
        default:
          return PlansUtil.getIcon(newValue);
      }
    }
  }
}
