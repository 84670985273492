import { FormControl } from '@angular/forms';

export type DatabaseServerInstances = { Name: string }[];

export enum SQLServerInstanceAuthenticationTypeEnum {
  'Windows Authentication' = 0,
  'SQL Server Authentication' = 1
}

export type SQLServerInstanceStepValueForm = {
  sqlServerInstanceName: FormControl<string>;
  authenticationType: FormControl<SQLServerInstanceAuthenticationTypeEnum>;
  userName: FormControl<string>;
  password: FormControl<string>;
  useSecureConnection: FormControl<boolean>;
  checkIsSysAdmin: FormControl<boolean>;
};

export type SQLServerInstanceAndAuthData = {
  sqlServerInstanceName: string;
  authenticationType: SQLServerInstanceAuthenticationTypeEnum;
  userName: string;
  password: string;
  checkIsSysAdmin: boolean;
};

export type SQLServerInstanceStepValue = SQLServerInstanceAndAuthData & {
  useSecureConnection: boolean;
  checkIsSysAdmin: boolean;
  valid: boolean;
};
