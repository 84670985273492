import { GuidEmpty } from 'mbs-ui-kit/utils/constants';
import { BackupToRestoreStepValue } from '../backup-to-restore-models';
import { DestinationStepValue } from '../destination-models';
import { EncryptionOptionsStepValue } from '../encryption-options-models';
import { NotificationsStepValue } from '../notifications-models';
import { PlanNameStepValue } from '../plan-name-models';
import { PrePostActionsStepValue } from '../pre-post-actions-models';
import { RestorePointStepValue } from '../restore-point-models';
import { ScheduleAdvancedStepValue, ScheduleFormSettings } from '../schedule-advanced-models';
import { ScheduleStepValue } from '../schedule-models';
import { ComputerToRestoreStepValue } from '../selected-computers-models';
import { WhereBackupStepValue } from '../where-backup-models';
import { Storages } from './base-models/plan-storages-model';

export class BaseRestoreFormsModel {
  planNameStep: PlanNameStepValue = { Name: '', SavePlanInCloud: false, valid: true };
  whereBackupStep: WhereBackupStepValue = { IsHybridBackup: false, HybridID: GuidEmpty, ConnectionID: GuidEmpty, valid: false };
  computerToRestoreStep: ComputerToRestoreStepValue = { computerName: '', isCurrent: true, valid: false };
  backupToRestoreStep: BackupToRestoreStepValue;
  restorePointStep: RestorePointStepValue = {
    restoreType: 0,
    fromDateTime: { date: '', time: '' },
    toDateTime: { date: '', time: '' },
    valid: true
  };
  Storages: Storages = { CloudStorages: [], LocalStorages: [] };
  fileFormats: string[] = [
    'Hyper-V Virtual Disk (VHD-format) fixed',
    'Hyper-V Virtual Disk (VHD-format) dynamic',
    'RAW disk image raw',
    'RAW disk image raws parse',
    'RAW disk image tar',
    'RAW disk image tgz',
    'VMware Virtual Disk vmfsfixed',
    'VMware Virtual Disk vmfsdynamic',
    'Hyper-V Virtual Disk (VHDX-format) dynamic',
    'VirtualBox Virtual Disk fixed',
    'VirtualBox Virtual Disk dynamic'
  ];
  destinationStep: DestinationStepValue = {
    destinationFolder: '',
    virtualImageName: '',
    overwriteExistingFiles: false,
    valid: true
  };
  encryptionOptionsStep: EncryptionOptionsStepValue = {
    password: '',
    valid: true
  };
  scheduleStep: ScheduleStepValue = {
    ScheduleType: 'noschedule',
    specificDateGroup: { date: new Date(), time: '00:00:00' },
    StopAfterEnabled: false,
    stopAfterHours: 0,
    stopAfterMinutes: 0,
    ForceMissedSchedule: false,
    overdueAfterSuccessEnabled: false,
    overdueAfterSuccessAmount: 7,
    overdueAfterSuccessPeriod: 1,
    syncBeforeRun: false,
    valid: true
  };
  scheduleAdvancedStep: ScheduleAdvancedStepValue = {
    forceFull: new ScheduleFormSettings(),
    blockLevel: new ScheduleFormSettings(),
    scheduleDiff: new ScheduleFormSettings(null, null),
    scheduleTLog: new ScheduleFormSettings(null, null),
    valid: true
  };
  prePostActionsStep: PrePostActionsStepValue = {
    executeCommandBeforeBackup: false,
    preAction: '',
    exitOnPreActionFail: true,
    executeCommandAfterBackupCompletion: false,
    postAction: '',
    executePostActionOnSuccess: true,
    valid: true
  };
  notificationStep: NotificationsStepValue = {
    receiveNotificationOnCompleteGroup: { flag: true, case: 'whenFails' },
    addEntryToEventLogOnCompleteGroup: { flag: false, case: 'whenFails' },
    valid: true
  };
  constructor(public isNBF = false) {
    if (isNBF) this.backupToRestoreStep = { selectedBunch: '', valid: false };
  }
}
