<mbs-modal [title]="'computers.module:modals.allowConnectTitle' | i18next : { computerName: getComputerName() }">
  <ng-container modal-body>
    <mbs-alert [type]="mbsPopupType.danger"
               [icon]="true">
      <span [innerHTML]="getErrorMessage()"></span>
    </mbs-alert>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button (click)="baseModal.close()">{{'buttons:close' | i18next}}</mbs-button>
  </ng-container>
</mbs-modal>
