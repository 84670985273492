import Computer, { ComputersApplications, ComputersHealthFilterType, ComputersMode } from '@models/Computer';
import { SortParams } from '@models/Paging';
import { createAction, props } from '@ngrx/store';
import { SmartSearchModel } from 'mbs-ui-kit/smart-search/models';
import { PagingActionTypes } from '../pagingStoreFactory/pagingStoreTypes';
import { StoreTypes } from '../storeTypes.enum';
import { ComputersActionTypes } from './types';

const getActionType = (actionType: PagingActionTypes | ComputersActionTypes): string => `[${StoreTypes.Computers}] ${actionType}`;

// entity
export const setComputers = createAction(getActionType(PagingActionTypes.SetEntities), props<{ computers: Computer[] }>());
export const setSelected = createAction(getActionType(PagingActionTypes.SetSelected), props<{ selected: string }>());
export const setSelectedSuccess = createAction(
  getActionType(PagingActionTypes.SetSelectedSuccess),
  props<{ computer?: Computer; selected?: string }>()
);
export const clear = createAction(getActionType(PagingActionTypes.Clear));
export const setLoading = createAction(getActionType(PagingActionTypes.SetLoading), props<{ loading: boolean }>());
export const loadComputerByHid = createAction(
  getActionType(PagingActionTypes.LoadEntityById),
  props<{ hid: string; force?: boolean; quiet?: boolean }>()
);
export const loadComputerError = createAction(getActionType(PagingActionTypes.LoadError));

// data
export const loadData = createAction(
  getActionType(PagingActionTypes.LoadData),
  props<{ isFullRefresh?: boolean; allowLoadMore?: boolean; limit?: number }>()
);
export const loadDataSuccess = createAction(
  getActionType(PagingActionTypes.LoadDataSuccess),
  props<{ computers: Computer[]; total: number }>()
);
export const loadDataError = createAction(getActionType(PagingActionTypes.LoadDataError));
export const clearData = createAction(getActionType(PagingActionTypes.ClearData));

// paging
export const setMode = createAction(getActionType(ComputersActionTypes.SetMode), props<{ mode: ComputersMode }>());
export const loadPage = createAction(
  getActionType(PagingActionTypes.LoadPage),
  props<{ pageNumber?: number; perPage?: number; isFullRefresh?: boolean }>()
);
export const loadPageSuccess = createAction(
  getActionType(PagingActionTypes.LoadPageSuccess),
  props<{ computers: Computer[]; pageNumber: number; total: number }>()
);
export const loadPageError = createAction(getActionType(PagingActionTypes.LoadPageError));
export const clearPages = createAction(getActionType(PagingActionTypes.ClearPages));
export const setPageNumber = createAction(getActionType(PagingActionTypes.SetPageNumber), props<{ pageNumber: number }>());
export const setPageNumberSuccess = createAction(getActionType(PagingActionTypes.SetPageNumberSuccess), props<{ pageNumber: number }>());
export const setSortSettings = createAction(getActionType(ComputersActionTypes.SetSortSettings), props<{ sort: SortParams }>());
export const setPerPageSetting = createAction(getActionType(PagingActionTypes.SetPerPage), props<{ perPage: number }>());

// filters
export const setFilterHidden = createAction(getActionType(ComputersActionTypes.SetFilterHidden), props<{ hidden: boolean }>());
export const setFilterCompany = createAction(getActionType(ComputersActionTypes.SetFilterCompany), props<{ company: string }>());
export const setFilterSearch = createAction(
  getActionType(ComputersActionTypes.SetFilterSearch),
  props<{ searchModel: SmartSearchModel }>()
);
export const setFilterHealth = createAction(
  getActionType(ComputersActionTypes.SetFilterHealth),
  props<{ healthType: ComputersHealthFilterType }>()
);
export const setAllFilters = createAction(
  getActionType(ComputersActionTypes.SetFilters),
  props<{
    searchModel?: SmartSearchModel;
    hidden?: boolean;
    healthType?: ComputersHealthFilterType;
    company?: string;
    apply?: boolean;
  }>()
);

// applications
export const loadApplicationsCount = createAction(
  getActionType(ComputersActionTypes.LoadComputersApplications),
  props<{ force?: boolean }>()
);
export const setApplicationsCount = createAction(
  getActionType(ComputersActionTypes.LoadComputersApplicationsSuccess),
  props<{ count: ComputersApplications }>()
);
