<mbs-modal>
  <ng-container modal-body>
    <mbs-alert [type]="mbsPopupType.info"
               class="mb-4"
               icon="ico ico-info-circle">
      {{ 'computers.module:connect:youWillBePrompted' | i18next }}
    </mbs-alert>
    <form class="form-group mb-0">
      <mbs-radio [label]="'computers.module:connect:openSSHConnection' | i18next"
                 class="mb-3"
                 [size]="'lg'"
                 [formControl]="form"
                 [value]="connectionType.SSH"></mbs-radio>
      <mbs-radio [label]="'computers.module:connect:openVNCConnection' | i18next"
                 class="mb-0"
                 [size]="'lg'"
                 [formControl]="form"
                 [value]="connectionType.VNC"></mbs-radio>
    </form>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="baseModal.save(form.value)">
      {{ 'app:products.ra' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                (click)="baseModal.close()">
      {{ 'app:buttons.cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
