import { FormArray, FormControl, UntypedFormGroup } from '@angular/forms';
import { DayOfWeek } from '@models/DayOfWeek.enum';
import { StorageClass, TypesForFFI } from '@models/StorageType.enum';
import { WeekNumber } from '@models/WeekNumber.enum';
import { GetBackupContentType, ItemTypeEnum, TreeRestorePointsIconPath } from '@models/backup/storages-type';
import { cobbledDateFormat, dateTimePointsFormatWithAm, mediumDateWithTimeMoment, mediumDateWithoutTimeMoment } from '@utils/date';
import { isNil } from 'lodash';
import { TreeElement } from 'mbs-ui-kit';
import * as moment from 'moment';
import { AzureAccessTierType } from '../models/advanced-options-models';
import { AlternativeTreeIconPath, TreeIconPath } from '../models/what-backup-tree-model';

export type StorageClassesArray = { value: StorageClass; label: string }[];
export type TreeData = { elements: TreeElement[]; indeterminate: boolean };
export type ExtendedTreeElement = TreeElement & {
  path?: string;
  isVersion?: boolean;
  isFolder?: boolean;
  parent?: ExtendedTreeElement;
  restorePath?: string;
};
export type GenerateTreeDataSettings = {
  rootTree?: TreeElement[];
  root: TreeElement;
  newInc: string[];
  newExc: string[];
  joinStr: string;
  sep: string;
  alt: boolean;
  onlyOneSelect?: boolean;
  hideFileType?: boolean;
  isLinux: boolean;
  isManually?: boolean;
  check?: boolean;
  isVirtual?: boolean;
  needHide?: (item: any) => boolean;
};

export class StepsHelpers {
  private static getIndexOtherPath(
    strings: string[],
    elements: ExtendedTreeElement[],
    parentPath: string,
    settings: GenerateTreeDataSettings
  ): number {
    const parentNew = settings.isLinux ? parentPath : parentPath.toLowerCase();
    let newStrings = strings;
    if (settings.isLinux && settings.rootTree) {
      const rootStrings = StepsHelpers.findInNotRootFolder(strings, settings.rootTree);
      newStrings = this.isChildRoot(settings.root, settings.joinStr)
        ? rootStrings
        : Array.from(strings).filter((s) => !rootStrings.includes(s));
    }
    return newStrings.findIndex((s) => {
      const sNew = settings.isLinux ? s : s.toLowerCase();
      const idx = elements.findIndex((e) => {
        const eStr = settings.isLinux ? (e as any).path : (e as any).path.toLowerCase();
        return eStr === sNew || sNew.includes(eStr);
      });
      return !this.pathNotIncludePath(settings.isLinux, settings.joinStr, s, parentPath) && idx === -1 && sNew !== parentNew;
    });
  }

  public static findStrForGetSubtree(strings: string[], path: string, separator: string, isLinux = false, needTest = false): string {
    const itemPathLen = path.split(separator).length;
    if (isLinux) {
      const equal = strings.find((str) => str === path);
      return equal || strings.find((str) => str.includes(path) && str.split(separator).length > itemPathLen);
    } else {
      const lowPath = path.toLowerCase();
      const equal = strings.find((str) => {
        const lowStr = needTest && str.startsWith(separator) ? str.substring(1) : str.toLowerCase();
        return lowStr === lowPath;
      });
      return (
        equal ||
        strings.find((str) => {
          const lowStr = needTest && str.startsWith(separator) ? str.substring(1) : str.toLowerCase();
          return !this.pathNotIncludePath(isLinux, separator, lowStr, lowPath) && str.split(separator).length > itemPathLen;
        })
      );
    }
  }

  public static getSortedTreeItems(items: any[], alt: boolean): any[] {
    const files: any[] = [];
    const folders: any[] = [];
    items.forEach((a) => {
      if (alt ? StepsHelpers.getDiskType(a, alt) === 'File' : StepsHelpers.getDiskType(a, alt) === 0) files.push(a);
      else folders.push(a);
    });
    folders.push(...files);
    return folders;
  }

  public static getDiskType(item, isAlt = false): string | number {
    return isAlt ? item.type || 'Disk' : +item.type === 3 ? 0 : +item.type;
  }

  private static getNewTreeItem(item, idx, checked, indeterminate, settings: GenerateTreeDataSettings): ExtendedTreeElement {
    const type = this.getDiskType(item, settings.alt);
    const isVersion = type === GetBackupContentType.Version;
    const pathArr = isVersion ? item.path.split(settings.joinStr) : [];
    const displayDate = isVersion
      ? moment
          .utc(item.displayName, dateTimePointsFormatWithAm.test(item.displayName) ? 'M/D/YYYY HH:mm:ss A' : 'D.MM.YYYY HH:mm:ss')
          .format(mediumDateWithTimeMoment)
      : '';

    return {
      label:
        type === GetBackupContentType.Volume
          ? item.displayName.split(' ').slice(2).join(' ')
          : isVersion
          ? displayDate === 'Invalid date'
            ? moment.utc(pathArr[pathArr.length - 1], cobbledDateFormat).format(mediumDateWithTimeMoment) + ' (UTC)'
            : displayDate
          : item.displayName,
      id: `${idx}${settings.sep}${item.restorePath || item.path}`.replaceAll(' ', '-'),
      checked: checked || (indeterminate && type === GetBackupContentType.File),
      indeterminate: indeterminate && type !== GetBackupContentType.File && type !== GetBackupContentType.Version,
      icon: this.getIconFromType(type, settings.alt),
      gotChildren: !settings.isManually ? (settings.alt ? !type || type === 'File' : !+type || +type === 3) : type === 'Version',
      expanded: false,
      shown: !settings.hideFileType || (item.itemType !== ItemTypeEnum.File && (settings.alt ? type !== 'File' : +type !== 0)),
      hideCheckbox: settings.needHide && settings.needHide(item),
      isVersion: isVersion,
      isFolder: type === GetBackupContentType.Folder,
      path: isVersion ? pathArr.slice(0, pathArr.length - 1).join(settings.joinStr) + settings.joinStr + item.modifyDateUTC : item.path,
      restorePath: item.restorePath
    };
  }

  public static getIconFromType(type: string | number, isAlt: boolean): string {
    switch (type) {
      case GetBackupContentType.Disk:
        return TreeRestorePointsIconPath.ParentDisk;
      case GetBackupContentType.Volume:
        return TreeRestorePointsIconPath.Disk;
      case GetBackupContentType.Version:
        return TreeRestorePointsIconPath.File;
      default:
        if (isAlt) return TreeIconPath[type];

        return type === 1 ? TreeIconPath.Folder : AlternativeTreeIconPath[+type];
    }
  }

  private static addInvisibleItemsToTree(
    newElements: ExtendedTreeElement[],
    settings: GenerateTreeDataSettings,
    mainFormArray: string[],
    excFormArray: string[]
  ): void {
    if (newElements.length) {
      const root = settings.root;
      const str = settings.isLinux ? (root as any).path : (((root as any).path || root.label) as string);
      const idx = this.getIndexOtherPath(mainFormArray, newElements, str, settings);
      const idxExc = this.getIndexOtherPath(excFormArray, newElements, str, settings);
      const includeInvisible = idx !== -1;
      if (includeInvisible || idxExc !== -1) {
        const path = includeInvisible ? mainFormArray[idx] : excFormArray[idxExc];
        newElements.push({
          label: path,
          id: `${idx}${settings.sep}${path}`,
          checked: includeInvisible,
          indeterminate: false,
          icon: '',
          gotChildren: false,
          expanded: false,
          shown: false,
          path: path
        });
      }
    }
  }

  public static getPathFromItem(item, joinStr: string): string {
    if (item.type === GetBackupContentType.Version) {
      const path = item.path.split(joinStr);
      path[path.length - 1] = item.modifyDateUTC ? this.getNormalDate(item.modifyDateUTC) : path[path.length - 1];

      return path.join(joinStr);
    }

    return item.path;
  }

  public static generateTreeData(
    data: any,
    formArray: string[],
    excFormArray: string[],
    settings: GenerateTreeDataSettings
  ): ExtendedTreeElement[] {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const newElements = data.data.items.map((item, idx) => {
      const myPath = this.getPathFromItem(item, settings.joinStr);
      const itemPathLen = myPath.length;
      const existStr = this.findStrForGetSubtree(formArray, myPath, settings.joinStr, settings.isLinux, settings.isVirtual);
      const existLen = existStr ? existStr.length : 0;
      const existInExclude = this.findStrForGetSubtree(excFormArray, myPath, settings.joinStr, settings.isLinux, settings.isVirtual);
      const childExclude = !!existInExclude && existInExclude.length > itemPathLen;
      const checked = settings.onlyOneSelect
        ? !!existStr || !this.pathNotIncludePath(settings.isLinux, settings.joinStr, myPath, formArray[0])
        : (!!existStr && existLen === itemPathLen) ||
          (item.type !== 'Version' && (!existInExclude || childExclude) && settings.root.checked);
      const forIndeterminate = childExclude && (checked || settings.root.checked || this.someParentIsChecked(settings.root));
      let indeterminate = (!!existStr && existStr.length > itemPathLen) || forIndeterminate;
      if (settings.isLinux && this.isChildRoot(settings.root, settings.joinStr) && settings.newInc.length && settings.newExc.length) {
        const incStrForSubtree = this.findStrForGetSubtree(settings.newInc, myPath, settings.joinStr, settings.isLinux, settings.isVirtual);
        const excStrForSubtree = this.findStrForGetSubtree(settings.newExc, myPath, settings.joinStr, settings.isLinux, settings.isVirtual);
        indeterminate =
          indeterminate &&
          ((settings.newInc.length && incStrForSubtree && incStrForSubtree[myPath.length] === settings.joinStr) ||
            (forIndeterminate && settings.newExc.length && excStrForSubtree && excStrForSubtree[myPath.length] === settings.joinStr));
      }

      return this.getNewTreeItem(item, idx, checked, indeterminate, settings);
    });
    this.addInvisibleItemsToTree(newElements, settings, formArray, excFormArray);
    return newElements;
  }

  public static oldGenerateTreeData(data: any, formArray: string[], separator: string, isManually = false): TreeData {
    let childChecked: boolean;
    let indeterminate: boolean;

    const newElements = data.d.map((item, idx) => {
      const newElement = {
        label: item.Name,
        id: `${idx}${separator}${item.Path}`,
        checked: formArray.some((str) => str.includes(item.Path)),
        icon: AlternativeTreeIconPath[!item.Type && item.Type !== 0 ? 3 : item.Type !== 1 ? item.Type : 0],
        gotChildren: !isManually ? !item.Type : item.Type === 1,
        expanded: false
      };
      if (indeterminate === null || indeterminate === undefined) {
        if (childChecked === null || childChecked === undefined) childChecked = newElement.checked;
        if (childChecked !== newElement.checked) indeterminate = true;
      }
      return newElement;
    });

    return { elements: newElements, indeterminate: indeterminate };
  }

  public static getPathInStringFormat(root: TreeElement): string {
    const arrayFolders = this.getArrayOfPath(root);
    if (arrayFolders.length > 1) {
      const idx = arrayFolders.length - 1;
      if (root.icon === AlternativeTreeIconPath[0]) arrayFolders[0] = arrayFolders[0].slice(0, arrayFolders[0].length - 1); // TODO Del it for new tree endpoint...
      const newArray = arrayFolders.slice(0, idx);
      return arrayFolders[idx].toUpperCase() + newArray.reverse().join('');
    }
    return (root.label as string).toUpperCase();
  }

  public static getArrayOfPath(root: TreeElement): string[] {
    const result = [];
    result.push(`${root.label}\\`);
    if (root.parent) result.push(...this.getArrayOfPath(root.parent));
    return result;
  }

  public static getArrayOfPathByOs(root: ExtendedTreeElement, isLinux: boolean, isVirtual = false, notFloat = false): string[] {
    const result = [];
    if (isLinux && root.path) {
      result.push(root.path);
      if (notFloat) return result;
    } else {
      if (isVirtual || root.isVersion) {
        const pathArr = (root.path || (root as any).originalPath).split(isLinux ? '/' : '\\');
        result.push(pathArr[pathArr.length - 1]);
      } else {
        result.push(root.label);
      }
    }
    if (root.parent) {
      const parent = this.getArrayOfPathByOs(root.parent, isLinux, isVirtual);
      result.push(...parent);
    }
    return result;
  }

  public static toggleTreeElementsStatuses(element: TreeElement): void {
    if (element.children && element.children.length) {
      if (!element.children.some((c) => c.checked)) {
        if (element.indeterminate) {
          element.checked = true;
          element.children.forEach((e) => this.toggleTreeElementsStatuses(e));
        } else {
          element.checked = false;
          element.indeterminate = false;
        }
      } else {
        if (element.indeterminate) element.checked = true;
        element.children.forEach((e) => this.toggleTreeElementsStatuses(e));
      }
    }
  }

  public static getArrayOfStrings(event: TreeElement, separator: string, isFullPath = false): string[] {
    const result = [];
    if (event.checked) {
      const hasChildren = event.children && !!event.children.length;
      if (!isFullPath) {
        if (!event.indeterminate && (!hasChildren || !event.children.some((c) => !c.checked)))
          result.push(this.getStringFromTreeElement(event, separator));
        else event.children.forEach((child) => result.push(...this.getArrayOfStrings(child, separator)));
      } else {
        if (hasChildren) event.children.forEach((child) => result.push(...this.getArrayOfStrings(child, separator, isFullPath)));
        else result.push(this.getStringFromTreeElement(event, separator));
      }
    }
    return result;
  }

  public static getNormalDate(date: string): string {
    return moment.utc(date).format(cobbledDateFormat);
  }

  static getRootPathWithJoin(path: string, join: string, isLinux = false, replaceStr = ''): string {
    let newPath = !isLinux && path.length === 2 && /^([a-zA-Z]:)/.test(path) ? path + join : path;

    if (!replaceStr) return newPath;

    newPath = newPath.split(replaceStr + join)[1] || newPath.split(replaceStr + join)[0];

    if (newPath.startsWith('\\\\\\')) newPath = newPath.replace('\\\\\\', '');

    return newPath;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public static getPathInStringFormatByOs(
    root: TreeElement,
    isLinux = false,
    reverse = false,
    isVirtual = false,
    notFloat = false,
    needFirst = false
  ): string {
    const arrayFolders = this.getArrayOfPathByOs(root, isLinux, isVirtual, notFloat);
    if (arrayFolders.length > 1) {
      if (needFirst) return arrayFolders[0];
      if (reverse) {
        const join = isLinux ? '/' : '\\';
        let result = arrayFolders.reverse().join(join);
        if (!result.startsWith(join, 0) && !isVirtual) result = join + result;
        return result;
      } else {
        const idx = arrayFolders.length - 1;
        const newArray = arrayFolders.slice(0, idx);
        return arrayFolders[idx] + newArray.reverse().join(isLinux ? '/' : '\\');
      }
    }
    return isLinux || isVirtual ? (root as any).path || (root as any).originalPath : (root.label as string);
  }

  public static uncheckAllParent(event: TreeElement, needOffIndeterminate = false, optionality = false, linuxOnWindowsPC = false): void {
    event.checked = false;
    if (needOffIndeterminate) {
      event.indeterminate = optionality
        ? event.children && event.children.some((c) => (c.shown || !linuxOnWindowsPC) && (c.checked || c.indeterminate))
        : false;
    }
    if (event.parent) this.uncheckAllParent(event.parent, needOffIndeterminate, optionality, linuxOnWindowsPC);
  }

  public static findInNotRootFolder(strings: string[], treeData: TreeElement[], needOriginal = false): string[] {
    return needOriginal
      ? strings.filter((s) => !treeData.some((d) => (d as any).originalPath !== '/' && s.includes((d as any).originalPath)))
      : strings.filter((s) => !treeData.some((d) => (d as any).path !== '/' && s.includes((d as any).path)));
  }

  public static pathNotIncludePath(isLinux, separator: string, parentPath: string, childPath: string): boolean {
    const newParentPath = isLinux ? parentPath : parentPath?.toLowerCase() || '';
    const newChildPath = isLinux ? childPath : childPath?.toLowerCase() || '';
    return (
      !newParentPath.includes(newChildPath) ||
      (newParentPath[newChildPath.length - 1] !== separator && newParentPath[newChildPath.length] !== separator)
    );
  }

  public static someParentIsChecked(event: TreeElement): boolean {
    if (event.parent) return event.parent.checked || this.someParentIsChecked(event.parent);
    else return event.checked;
  }

  public static isChildRoot(element: TreeElement, joinForInput: string): boolean {
    if (element.parent) return this.isChildRoot(element.parent, joinForInput);
    else return (element as any).path === joinForInput;
  }

  public static getRootPathForShare(path: string): string {
    const splitPath = path.split('\\').filter((str) => !!str);
    return `\\\\${splitPath.slice(0, 2).join('\\')}\\`;
  }
  public static equalStrings(first: string, second: string, isLinux: boolean): boolean {
    return isLinux ? first === second : first.toLowerCase() === second.toLowerCase();
  }

  public static toggleAllInTree(element: TreeElement, state = false, expanded = false): void {
    element.checked = state;
    element.indeterminate = false;
    element.expanded = expanded;
    if (element.children && element.children.length) element.children.forEach((el) => this.toggleAllInTree(el, state));
  }

  public static updateStorageClasses(storageClasses: StorageClassesArray): void {
    if (!storageClasses.some((c) => c.value === StorageClass.GLACIER_IR)) {
      storageClasses.push({ value: StorageClass.GLACIER_IR, label: 'Glacier Instant Retrieval' });
    }
  }

  public static getStorageClasses(): StorageClassesArray {
    return [
      { value: StorageClass.STANDARD, label: 'Standard' },
      { value: StorageClass.INTELLIGENT_TIERING, label: 'Intelligent-Tiering' },
      { value: StorageClass.STANDARD_IA, label: 'Standard-IA' },
      { value: StorageClass.ONEZONE_IA, label: 'One Zone-IA' },
      { value: StorageClass.GLACIER, label: 'Glacier Flexible Retrieval' },
      { value: StorageClass.DEEP_ARCHIVE, label: 'Glacier Deep Archive' }
    ];
  }

  public static isStorageBadForFFI(storage: StorageClass | AzureAccessTierType, type: 'Amazon' | 'Azure'): boolean {
    return (TypesForFFI.unsupported[type] as number[]).includes(storage);
  }

  public static getDefaultIncrementalSchedule(validVersionForSchedule = false): any {
    const formData: any = {
      recurringPeriod: new FormControl('Monthly'),
      weeklyDayOfWeek: new FormArray([]),
      occurrence: new FormControl(0),
      dayOfWeek: new FormControl(3),
      dayOfMonthCount: new FormControl(1),
      dailyFreqPeriodOption: new FormControl('OccursAt'),
      occursAtTime: new FormControl('00:00'),
      occursEveryCount: new FormControl(1),
      occursEveryPeriod: new FormControl('hours'),
      occursEveryFromTime: new FormControl('00:00'),
      occursEveryToTime: new FormControl('23:59')
    };
    if (validVersionForSchedule) {
      formData.repeatEveryCount = new FormControl(1);
      formData.startFromDate = new FormControl(new Date());
    }
    return formData;
  }

  public static getDefaultFullSchedule(validVersionForSchedule = false): any {
    const formData: any = {
      enabled: new FormControl(false),
      recurringPeriod: new FormControl('Monthly'),
      weeklyDayOfWeek: new FormArray([]),
      occurrence: new FormControl(0),
      dayOfWeek: new FormControl(3),
      dayOfMonthCount: new FormControl(1),
      dailyFreqPeriodOption: new FormControl('OccursAt'),
      occursAtTime: new FormControl('00:00')
    };
    if (validVersionForSchedule) {
      formData.repeatEveryCount = new FormControl(1);
      formData.startFromDate = new FormControl(new Date());
    }
    return formData;
  }

  public static updateDaysOfWeek(days: boolean[] = null, form: UntypedFormGroup, daysOfWeekEnumArray: any[] = []): void {
    const WeeklyDayOfWeek = form.get('weeklyDayOfWeek') as FormArray;
    if (days && days.length) {
      const len = WeeklyDayOfWeek.controls.length;
      for (let i = 0; i < len; i++) WeeklyDayOfWeek.removeAt(0);
      days.forEach((elem) => WeeklyDayOfWeek.push(new FormControl(elem)));
    } else {
      daysOfWeekEnumArray.forEach(() => WeeklyDayOfWeek.push(new FormControl(false)));
    }
  }

  public static getStringByScheduleData(schedule, needStartingFrom = true): string {
    let str = '';
    str = this.getStringIfSchedule(schedule);
    str += this.getStringPeriodOptions(schedule);
    if (needStartingFrom) str += ` starting from ${moment(schedule.StartFromDate).format(mediumDateWithoutTimeMoment)}`;
    return str;
  }

  public static getStringPeriodOptions(schedule): string {
    const atTime = moment(schedule.OccursAtTime, 'HH:mm').format('h:mm\u00A0A');
    const OccursEveryFromTime = moment(schedule.OccursEveryFromTime, 'HH:mm').format('h:mm\u00A0A');
    const OccursEveryToTime = moment(schedule.OccursEveryToTime, 'HH:mm').format('h:mm\u00A0A');
    return schedule.DailyFreqPeriodOption === 'OccursAt'
      ? 'at ' + atTime
      : `every ${schedule.OccursEveryCount} ${schedule.OccursEveryPeriod} from ${OccursEveryFromTime} to ${OccursEveryToTime}`;
  }

  public static getStringIfSchedule(schedule): string {
    let summary = 'Occurs every ';
    switch (schedule.RecurringPeriod) {
      case 'Daily':
        summary += `${schedule.RepeatEveryCount} day${schedule.RepeatEveryCount > 1 ? 's' : ''} `;
        break;
      case 'DayOfMonth':
        summary += this.getSummaryPartForDayOfMonth(schedule);
        break;
      case 'Monthly':
        summary +=
          schedule.Occurrence == WeekNumber['Day Of Month']
            ? this.getSummaryPartForDayOfMonth(schedule)
            : `${schedule.RepeatEveryCount} month${schedule.RepeatEveryCount > 1 ? 's' : ''} on ${WeekNumber[schedule.Occurrence]} ${
                DayOfWeek[schedule.DayOfWeek]
              } `;
        break;
      case 'Weekly': {
        const days: Array<string> = schedule.WeeklyDayOfWeek.map((x, i) => (x ? DayOfWeek[i] : '')).filter((x) => !!x);
        summary += `${schedule.RepeatEveryCount} week${schedule.RepeatEveryCount > 1 ? 's' : ''} on ${days.join(', ')} `;
        break;
      }
      case 'Yearly':
        summary += schedule.RepeatEveryCount + ' year' + (schedule.RepeatEveryCount > 1 ? 's' : '') + ' ';
        break;
    }
    return summary;
  }

  public static getDiskSizeFromMB(mb: number, needRound = false): string {
    if (mb >= 1024) {
      const gb = mb / 1024;
      const isGB = gb < 1024;
      let result = isGB ? +(gb || 0.02).toFixed(2) : +(gb / 1024 || 0.02).toFixed(2);
      if (needRound) result = Math.ceil(result);
      return isGB ? result + ' GB' : result + ' TB';
    } else if (mb < 1) {
      return mb ? +(needRound ? Math.ceil(mb * 1024) : mb * 1024).toFixed(2) + ' KB' : '';
    } else return mb ? +(Math.ceil(mb) || 0.02).toFixed(2) + ' MB' : '';
  }

  public static getSummaryPartForDayOfMonth(schedule): string {
    return `${schedule.RepeatEveryCount} month${schedule.RepeatEveryCount > 1 ? 's' : ''} on ${schedule.DayOfMonthCount} day `;
  }

  public static updateTreeItemFromStringArray(newItem: TreeElement, strArray: string[], isLinux = false): void {
    newItem.checked = true;
    newItem.indeterminate = !!strArray.length;
    if (newItem.children && newItem.children.length && strArray.length) {
      if (isLinux) {
        newItem.children.forEach((el) => {
          if (el.label === strArray[0]) this.updateTreeItemFromStringArray(el, strArray.slice(1, strArray.length));
        });
      } else {
        newItem.children.forEach((el) => {
          if ((el.label as string).toLowerCase() === strArray[0].toLowerCase())
            this.updateTreeItemFromStringArray(el, strArray.slice(1, strArray.length));
        });
      }
    }
  }

  public static getTotalChildren(data: { totalCount?: number }, childrenLength: number): number {
    return data && isNil(data.totalCount) && childrenLength >= 300 ? Number.MAX_SAFE_INTEGER : (data?.totalCount || 0) - 1;
  }

  private static getStringFromTreeElement(event: TreeElement & { path?: string }, separator: string): string {
    return (event.path || event.label).toString();
  }
}
