import { createFeatureSelector, createSelector } from '@ngrx/store';
import { computerTagsAdapter, ComputerTagsState, COMPUTER_TAGS_STORE_TYPE } from './state';

const selectComputerTagsFeatureSelector = createFeatureSelector<ComputerTagsState>(COMPUTER_TAGS_STORE_TYPE);

const {
  selectIds: selectIdsByAdapter,
  selectAll: selectAllByAdapter,
  selectTotal: selectTotalByAdapter,
  selectEntities: selectEntitiesByAdapter
} = computerTagsAdapter.getSelectors();

export const selectSelected = createSelector(selectComputerTagsFeatureSelector, (state) => state.selected);
export const selectAll = createSelector(selectComputerTagsFeatureSelector, selectAllByAdapter);
export const selectEntities = createSelector(selectComputerTagsFeatureSelector, selectEntitiesByAdapter);
export const selectSelectedTag = createSelector(selectSelected, selectAll, (selected, tags) => tags.find((tag) => tag.id === selected));
export const selectLoading = createSelector(selectComputerTagsFeatureSelector, (state) => state.loading);
export const selectLoaded = createSelector(selectComputerTagsFeatureSelector, (state) => state.loaded);
export const selectTotal = createSelector(selectComputerTagsFeatureSelector, selectTotalByAdapter);
export const selectIds = createSelector(selectComputerTagsFeatureSelector, selectIdsByAdapter);
export const selectById = (id: number) => createSelector(selectEntities, (entities) => entities[id]);
