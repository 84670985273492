import { pipe } from 'lodash/fp';
import RmmCommandParam from './RmmCommandParam';

export type AddParamOperator = (name: string, value: any) => (instance: RmmCommand<any>) => RmmCommand<any>;

export const addRmmParam: AddParamOperator = (name: string, value: any): ((instance: RmmCommand<any>) => RmmCommand<any>) => {
  return (instance: RmmCommand<any>) => {
    return instance.addParam(name, value);
  };
};

export default class RmmCommand<TResult> {
  async = false;
  /**
   * @deprecated use `async`
   */
  asyncID?: string = null;
  parameters: RmmCommandParam[] = [];
  body: any;

  constructor(public id: string, asyncID?: string) {
    if (asyncID) {
      this.asyncID = asyncID;
    }
  }

  static create<TResult>(cmdId: string, asyncID?: string): RmmCommand<TResult> {
    return new RmmCommand<TResult>(cmdId, asyncID);
  }

  static createPipe<TResult>(...args) {
    return (cmdId: string): RmmCommand<TResult> => pipe(...args)(new RmmCommand<TResult>(cmdId));
  }

  static parse<TResult>(json: string) {
    const obj = JSON.parse(json);
    const command = new RmmCommand('');
    Object.assign(command, obj);
    return command;
  }

  addParam(name: string, value: any): RmmCommand<TResult> {
    this.parameters.push({ name, value });
    return this;
  }

  addParams(params: RmmCommandParam[]) {
    params.forEach(p => {
      this.parameters.push(p);
    });
    return this;
  }

  addBody<T = any>(body: T) {
    this.body = body;

    return this;
  }

  toString(): string {
    return JSON.stringify(
      Object.keys(this)
        .filter(key => key !== 'async')
        .sort()
        .reduce((p, q) => ((p[q] = this[q]), p), {})
    );
  }
}
