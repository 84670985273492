export enum EC2InstanceTypes {
  'Unknown' = 0,
  't1_micro' = 1,
  't2_nano' = 2,
  't2_micro' = 3,
  't2_small' = 4,
  't2_medium' = 5,
  't2_large' = 6,
  't2_xlarge' = 7,
  't2_2xlarge' = 8,
  't3_nano' = 9,
  't3_micro' = 10,
  't3_small' = 11,
  't3_medium' = 12,
  't3_large' = 13,
  't3_xlarge' = 14,
  't3_2xlarge' = 15,
  'm1_small' = 16,
  'm1_medium' = 17,
  'm1_large' = 18,
  'm1_xlarge' = 19,
  'm3_medium' = 20,
  'm3_large' = 21,
  'm3_xlarge' = 22,
  'm3_2xlarge' = 23,
  'm4_large' = 24,
  'm4_xlarge' = 25,
  'm4_2xlarge' = 26,
  'm4_4xlarge' = 27,
  'm4_10xlarge' = 28,
  'm4_16xlarge' = 29,
  'm2_xlarge' = 30,
  'm2_2xlarge' = 31,
  'm2_4xlarge' = 32,
  'cr1_8xlarge' = 33,
  'r3_large' = 34,
  'r3_xlarge' = 35,
  'r3_2xlarge' = 36,
  'r3_4xlarge' = 37,
  'r3_8xlarge' = 38,
  'r4_large' = 39,
  'r4_xlarge' = 40,
  'r4_2xlarge' = 41,
  'r4_4xlarge' = 42,
  'r4_8xlarge' = 43,
  'r4_16xlarge' = 44,
  'r5_large' = 45,
  'r5_xlarge' = 46,
  'r5_2xlarge' = 47,
  'r5_4xlarge' = 48,
  'r5_12xlarge' = 49,
  'r5_24xlarge' = 50,
  'r5_metal' = 51,
  'r5d_large' = 52,
  'r5d_xlarge' = 53,
  'r5d_2xlarge' = 54,
  'r5d_4xlarge' = 55,
  'r5d_12xlarge' = 56,
  'r5d_24xlarge' = 57,
  'r5d_metal' = 58,
  'r5a_large' = 59,
  'r5a_xlarge' = 60,
  'r5a_2xlarge' = 61,
  'r5a_4xlarge' = 62,
  'r5a_12xlarge' = 63,
  'r5a_24xlarge' = 64,
  'x1_16xlarge' = 65,
  'x1_32xlarge' = 66,
  'x1e_xlarge' = 67,
  'x1e_2xlarge' = 68,
  'x1e_4xlarge' = 69,
  'x1e_8xlarge' = 70,
  'x1e_16xlarge' = 71,
  'x1e_32xlarge' = 72,
  'i2_xlarge' = 73,
  'i2_2xlarge' = 74,
  'i2_4xlarge' = 75,
  'i2_8xlarge' = 76,
  'i3_large' = 77,
  'i3_xlarge' = 78,
  'i3_2xlarge' = 79,
  'i3_4xlarge' = 80,
  'i3_8xlarge' = 81,
  'i3_16xlarge' = 82,
  'i3_metal' = 83,
  'hi1_4xlarge' = 84,
  'hs1_8xlarge' = 85,
  'c1_medium' = 86,
  'c1_xlarge' = 87,
  'c3_large' = 88,
  'c3_xlarge' = 89,
  'c3_2xlarge' = 90,
  'c3_4xlarge' = 91,
  'c3_8xlarge' = 92,
  'c4_large' = 93,
  'c4_xlarge' = 94,
  'c4_2xlarge' = 95,
  'c4_4xlarge' = 96,
  'c4_8xlarge' = 97,
  'c5_large' = 98,
  'c5_xlarge' = 99,
  'c5_2xlarge' = 100,
  'c5_4xlarge' = 101,
  'c5_9xlarge' = 102,
  'c5_18xlarge' = 103,
  'c5n_large' = 104,
  'c5n_xlarge' = 105,
  'c5n_2xlarge' = 106,
  'c5n_4xlarge' = 107,
  'c5n_9xlarge' = 108,
  'c5n_18xlarge' = 109,
  'c5d_large' = 110,
  'c5d_xlarge' = 111,
  'c5d_2xlarge' = 112,
  'c5d_4xlarge' = 113,
  'c5d_9xlarge' = 114,
  'c5d_18xlarge' = 115,
  'cc1_4xlarge' = 116,
  'cc2_8xlarge' = 117,
  'g2_2xlarge' = 118,
  'g2_8xlarge' = 119,
  'g3_4xlarge' = 120,
  'g3_8xlarge' = 121,
  'g3_16xlarge' = 122,
  'cg1_4xlarge' = 123,
  'p2_xlarge' = 124,
  'p2_8xlarge' = 125,
  'p2_16xlarge' = 126,
  'p3_2xlarge' = 127,
  'p3_8xlarge' = 128,
  'p3_16xlarge' = 129,
  'p3dn_24xlarge' = 130,
  'd2_xlarge' = 131,
  'd2_2xlarge' = 132,
  'd2_4xlarge' = 133,
  'd2_8xlarge' = 134,
  'f1_2xlarge' = 135,
  'f1_4xlarge' = 136,
  'f1_16xlarge' = 137,
  'm5_large' = 138,
  'm5_xlarge' = 139,
  'm5_2xlarge' = 140,
  'm5_4xlarge' = 141,
  'm5_12xlarge' = 142,
  'm5_24xlarge' = 143,
  'm5_metal' = 144,
  'm5d_large' = 145,
  'm5d_xlarge' = 146,
  'm5d_2xlarge' = 147,
  'm5d_4xlarge' = 148,
  'm5d_12xlarge' = 149,
  'm5d_24xlarge' = 150,
  'm5d_metal' = 151,
  'm5a_large' = 152,
  'm5a_xlarge' = 153,
  'm5a_2xlarge' = 154,
  'm5a_4xlarge' = 155,
  'm5a_12xlarge' = 156,
  'm5a_24xlarge' = 157,
  'h1_2xlarge' = 158,
  'h1_4xlarge' = 159,
  'h1_8xlarge' = 160,
  'h1_16xlarge' = 161,
  'a1_medium' = 162,
  'a1_large' = 163,
  'a1_xlarge' = 164,
  'a1_2xlarge' = 165,
  'a1_4xlarge' = 166,
  'z1d_large' = 167,
  'z1d_xlarge' = 168,
  'z1d_2xlarge' = 169,
  'z1d_3xlarge' = 170,
  'z1d_6xlarge' = 171,
  'z1d_12xlarge' = 172,
  'z1d_metal' = 173
}
