<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:backupToRestoreTitle' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-list-select bindSelected="bunchId"
                   id="list-local-or-cloud-storages"
                   [tableContentClass]="'cursor-pointer'"
                   [invalid]="stepForm.touched && stepForm.invalid"
                   [keepState]="true"
                   [loadSource]="false"
                   [maxHeight]="'336px'"
                   [multipleSelect]="false"
                   [showCheckboxes]="false"
                   [showHeaders]="false"
                   [showSearch]="false"
                   [selectedData]="stepForm.get('selectedBunch').value || ''"
                   [data]="bunches"
                   (changeSelected)="changeSelectedHandler($event)"
                   (click)="clickHandler()"
                   [headers]="headers">
    <ng-template mbsTableCell
                 let-bunch>
      <div class="row align-items-center py-1 flex-nowrap">
        <div class="col-auto d-flex">
          <img [src]="isRestorePlan ? 'assets/images/for-wizards/file-small.svg' : 'assets/images/for-wizards/ibb-small.svg'"
               alt="Icon">
        </div>
        <div class="col text-overflow font-weight-semibold text-black">
          {{bunch.bunchName}}
        </div>
        <div *ngIf="bunch.bunchId !== idsForCurrentFormats.File && bunch.bunchId !== idsForCurrentFormats.FileLinux && bunch.bunchId !== idsForCurrentFormats.Ibb"
             class="col-2 d-flex justify-content-end">
          <mbs-tag [clickable]="false"
                   [closable]="false"
                   [active]="true"
                   [noBorder]="true"
                   [class]="'background-green'">
            {{planFormatTypeShortNames.NBF}}
          </mbs-tag>
        </div>
      </div>
    </ng-template>
  </mbs-list-select>

  <ng-template #nothingRestore>
    <mbs-alert [type]="mbsPopupType.info"
               [icon]="true"
               class="mb-0">
      {{ 'wizards:backupToRestoreNothingRestoreText' | i18next: { name: isRestoreIbb ? 'Image-Based' : 'Files' } }}
    </mbs-alert>
  </ng-template>
</section>
