import { PerPageSize } from './Paging';

export class AppPersistentState {
  isFirstVisitRDPage = false;
  isFirstVisitBrandingPage = false;
  isRMNestedBackupLevelActive = false;
  showUnsupportedVersionsMessage = true;
  showRdLicenseNotGranted = true;
  showUnconfiguredEmailNotifications = true;
  timeZoneAlertDismissed = false;
  betaMode = {
    rmm: false
  };

  rmmSidepanel = {
    updateInterval: 5,
    agentOptions: null
  };

  /**
   * Key is storageId
   * For right behavior override refs
   */
  showUbMessage: { [key: string]: boolean } = {};
  lastSignIn = new Date();
  userId = '';
  signUpDate: Date = null;
  feedbackRemindLateDate = null;
  administratorsPerPage = PerPageSize.Large;
  computersPerPage = PerPageSize.Large;
  companiesPerPage = PerPageSize.Medium;
  auditLogPerPage = PerPageSize.Medium;
  usersPerPage = PerPageSize.Medium;
  licensesPageOpened = false;
}
