import { ComputersHealthFilterType } from '@models/Computer';
import { ComputersStoreFilters } from '@models/ComputersStoreFilters';
import { PagingParams } from '@models/Paging';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNil } from 'lodash';
import { StoreTypes } from '../storeTypes.enum';
import { computersAdapter, ComputersState } from './state';

const selectComputersFeatureSelector = createFeatureSelector<ComputersState>(StoreTypes.Computers);

const {
  selectIds: selectIdsByAdapter,
  selectAll: selectAllByAdapter,
  selectTotal: selectTotalByAdapter,
  selectEntities: selectEntitiesByAdapter
} = computersAdapter.getSelectors();

// entity
export const selectSelected = createSelector(selectComputersFeatureSelector, (state) => state.selected);
export const selectAll = createSelector(selectComputersFeatureSelector, selectAllByAdapter);
export const selectEntities = createSelector(selectComputersFeatureSelector, selectEntitiesByAdapter);
export const selectSelectedComputer = createSelector(selectSelected, selectAll, (selected, computers) =>
  computers.find((computer) => computer.hid === selected)
);
export const selectLoading = createSelector(selectComputersFeatureSelector, (state) => state.loading);
export const selectTotal = createSelector(selectComputersFeatureSelector, selectTotalByAdapter);
export const selectIds = createSelector(selectComputersFeatureSelector, selectIdsByAdapter);
export const selectByHid = (hid: string) => createSelector(selectEntities, (entities) => entities[hid]);

// applications
export const selectApplicationsCountInitiated = createSelector(selectComputersFeatureSelector, (state) => state.applicationsCountInitiated);
export const selectApplicationsCount = createSelector(selectComputersFeatureSelector, (state) => state.applicationsCount);

// paging
export const selectMode = createSelector(selectComputersFeatureSelector, (state) => state.mode);
export const selectModeInitiated = createSelector(selectComputersFeatureSelector, (state) => state.modeInitiated);

export const selectPagingEntities = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.pagingData[mode].pages
);
export const selectPageNumbers = createSelector(selectMode, selectComputersFeatureSelector, (mode, state) =>
  Object.keys(state.pagingData[mode]?.pages).map((v) => parseInt(v, 10))
);

export const selectPagingLoading = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.pagingData[mode].loading
);
export const selectPagingLoaded = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.pagingData[mode].loaded
);
export const selectCurrentPageNumber = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.pagingData[mode].currentPageNumber
);
export const selectCurrentPage = createSelector(
  selectCurrentPageNumber,
  selectPagingEntities,
  (currentPage, pages) => (currentPage && pages[currentPage]) || []
);
export const selectPerPage = createSelector(selectMode, selectComputersFeatureSelector, (mode, state) => state.pagingData[mode].perPage);
export const selectTotalItems = createSelector(selectMode, selectComputersFeatureSelector, (mode, state) => state.pagingData[mode].total);
export const selectSortSettings = createSelector(selectMode, selectComputersFeatureSelector, (mode, state) => state.pagingData[mode].sort);
export const selectPagingParams = createSelector(
  selectCurrentPageNumber,
  selectPerPage,
  (currentPageNumber, perPage) => ({ currentPageNumber, perPage } as PagingParams)
);

// filters
export const selectFilterHealthType = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.filtersData[mode].healthType
);
export const selectFilterAuthStatus = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.filtersData[mode].authStatus
);
export const selectFilterHealth = createSelector(selectFilterHealthType, selectFilterAuthStatus, (healthType, authStatus) => ({
  healthType,
  authStatus
}));
export const selectFilterHidden = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.filtersData[mode].hidden
);
export const selectFilterCompany = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.filtersData[mode].company
);
export const selectFilterSearchModel = createSelector(
  selectMode,
  selectComputersFeatureSelector,
  (mode, state) => state.filtersData[mode].searchModel
);
export const selectAllFilters = createSelector(
  selectFilterHealthType,
  selectFilterAuthStatus,
  selectFilterHidden,
  selectFilterCompany,
  selectFilterSearchModel,
  (healthType, authStatus, hidden, company, searchModel) =>
    ({
      healthType,
      authStatus,
      hidden,
      company,
      searchModel
    } as ComputersStoreFilters)
);
export const selectHasFilters = createSelector(
  selectFilterHealthType,
  selectFilterHidden,
  selectFilterCompany,
  selectFilterSearchModel,
  (healthType, hidden, company, searchModel) => {
    return healthType !== ComputersHealthFilterType.All || hidden || company || isNil(searchModel) || Object.keys(searchModel).length;
  }
);

export const selectRequestParams = createSelector(selectPagingParams, selectSortSettings, selectAllFilters, (paging, sorting, filters) => ({
  paging,
  sorting,
  filters
}));

export const selectPreQueryPagingParams = createSelector(selectRequestParams, selectPageNumbers, (params, pageNumbers) => ({
  requestParams: params,
  pageNumbers
}));

export const selectFilterInitiated = createSelector(selectComputersFeatureSelector, (state) => state.filterInitiated);

// data
export const selectDataLoading = createSelector(selectComputersFeatureSelector, (state) => state.data.loading);
export const selectDataLoaded = createSelector(selectComputersFeatureSelector, (state) => state.data.loaded);
export const selectDataTotal = createSelector(selectComputersFeatureSelector, (state) => state.data.total);

export const selectData = createSelector(selectComputersFeatureSelector, selectEntities, (state, entities) =>
  (state.data.entities || []).map((id) => entities[id])
);
export const selectDataCount = createSelector(selectComputersFeatureSelector, (state) => (state.data.entities || []).length);
export const selectAllDataLoaded = createSelector(
  selectDataLoaded,
  selectDataTotal,
  selectDataCount,
  (loaded, total, count) => loaded && total <= count
);
export const selectPreQueryDataParams = createSelector(selectDataCount, selectAllDataLoaded, (dataCount, allLoaded) => ({
  dataCount,
  allLoaded
}));

// combined
export const selectDataEntityLoading = createSelector(selectLoading, selectDataLoading, (loading, dataLoading) => loading || dataLoading);
export const selectPagingEntityLoading = createSelector(
  selectLoading,
  selectPagingLoading,
  (loading, pagingLoading) => loading || pagingLoading
);
