import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DayOfWeek } from '@models/DayOfWeek.enum';
import { RecurTypeText } from '@models/RecurType.enum';
import { WeekNumber } from '@models/WeekNumber.enum';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { HoursOrMinutesItems } from '@modules/wizards/models/schedule-advanced-models';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { EnumHelper } from 'mbs-ui-kit/utils/enum-helper';
import moment from 'moment';

@Component({
  selector: 'mbs-schedule-modal',
  templateUrl: './schedule-modal.component.html'
})
export class ScheduleModalComponent implements OnInit {
  public readonly maxHours = 23;
  public readonly maxMinutes = 1439;

  public recurringPeriodItems = ['Daily', 'Monthly'];
  public hoursOrMinutesItems = HoursOrMinutesItems;
  public daysOfWeekEnumArray = EnumHelper.EnumToSelectIndexesArray(DayOfWeek);
  public weekNumberEnumArray = EnumHelper.EnumToSelectIndexesArray(WeekNumber).sort((a, b) =>
    a.label === WeekNumber[-1] || b.label === 'Penultimate' ? -1 : 0
  );

  public form: UntypedFormGroup;

  get isLinux(): boolean {
    return !!this.baseModal && !!this.baseModal.data && this.baseModal.data.isLinux;
  }

  get isIncremental(): boolean {
    return !!this.baseModal && !!this.baseModal.data && this.baseModal.data.isIncremental;
  }

  get validVersionForSchedule(): boolean {
    return !!this.baseModal && !!this.baseModal.data && this.baseModal.data.validVersionForSchedule;
  }

  get isFFI(): boolean {
    return !!this.baseModal?.data?.isFFI;
  }

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor() {}

  ngOnInit(): void {
    const helperName = this.isIncremental ? 'getDefaultIncrementalSchedule' : 'getDefaultFullSchedule';
    this.form = new UntypedFormGroup(StepsHelpers[helperName](this.validVersionForSchedule));

    this.recurringPeriodChangeHandler(this.baseModal?.data?.value?.recurringPeriod);

    this.setForm(this.isIncremental);
  }

  setForm(isIncremental = true): void {
    const data = this.baseModal.data.value;
    for (const key in data) {
      if (!(data[key] === null || data[key] === undefined)) {
        if (key === 'dayOfMonthCount' && !+data[key]) data[key] = 1;
        this.form.get(key).reset(data[key]);
      }
    }
    StepsHelpers.updateDaysOfWeek(data.weeklyDayOfWeek, this.form, this.daysOfWeekEnumArray);
    if (isIncremental) this.occursAtChangeHandler(data.dailyFreqPeriodOption);
    else if (this.isLinux) {
      this.form.get('occursAtTime').enable();
    }
  }

  getCounterText(): string {
    const formValue = this.form.get('recurringPeriod').value;
    return formValue ? RecurTypeText[formValue] : '';
  }

  occursAtChangeHandler(event: string, disable = true): void {
    if (event === 'OccursAt') {
      this.form.get('occursAtTime').enable();
      this.form.get('occursEveryCount').disable();
      this.form.get('occursEveryPeriod').disable();
      this.form.get('occursEveryFromTime').disable();
      this.form.get('occursEveryToTime').disable();
    } else if (event === 'OccursEvery') {
      if (disable) this.form.get('occursAtTime').disable();
      this.form.get('occursEveryCount').enable();
      this.form.get('occursEveryPeriod').enable();
      this.form.get('occursEveryFromTime').enable();
      this.form.get('occursEveryToTime').enable();
    }
  }

  recurringPeriodChangeHandler(event: string): void {
    if (event === 'Monthly') {
      this.form.get('weeklyDayOfWeek').disable();
      if (this.isIncremental) {
        this.occursAtChangeHandler('OccursAt');
        this.form.get('dailyFreqPeriodOption').setValue('OccursAt');
        this.form.get('dayOfWeek').enable();
        this.form.get('dayOfMonthCount').enable();
      }
    } else if (event === 'Daily') {
      this.form.get('weeklyDayOfWeek').enable();
      if (this.isIncremental) {
        this.occursAtChangeHandler('OccursAt', false);
        this.form.get('dayOfWeek').disable();
        this.form.get('dayOfMonthCount').disable();
      }
    }
    this.form.updateValueAndValidity();
  }

  occursEveryPeriodChangeHandler(event): void {
    if (event === 'hours' && this.form.get('occursEveryCount').value > this.maxHours) {
      this.form.get('occursEveryCount').setValue(this.maxHours);
    }
  }

  getFormatTime(time: string): string {
    return moment(time, 'HH:mm').format('h:mm\u00A0A');
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }

  saveClickHandler(): void {
    this.baseModal.save({ [this.isIncremental ? 'incrementalData' : 'fullBackupData']: this.form.value });
  }
}
