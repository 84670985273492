<form [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:select_virtual_disks_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-alert *ngIf="isOffline"
             class="isOfflineAlert"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:select_virtual_disks_offline_alert' | i18next}}
  </mbs-alert>

  <mbs-form-group>
    <mbs-radio formControlName="type"
               name="VirtualDiskSelectedType"
               [label]="'wizards:select_all_virtual_disks' | i18next"
               (change)="virtualDiskSelectedTypeChangeHandler(virtualDisksSelectedType.All)"
               [value]="virtualDisksSelectedType.All"
               [id]="'type-select-disks-radio-1'"></mbs-radio>
    <mbs-radio formControlName="type"
               name="VirtualDiskSelectedType"
               [label]="'wizards:select_selected_virtual_disks' | i18next"
               (change)="virtualDiskSelectedTypeChangeHandler(virtualDisksSelectedType.Selected)"
               [value]="virtualDisksSelectedType.Selected"
               [id]="'type-select-disks-radio-2'"></mbs-radio>
  </mbs-form-group>


  <mbs-table-grid [checkboxCellWidth]="'36px'"
                  [collapsibleMode]="true"
                  [data]="tableData"
                  [invalid]="tableIsInvalid"
                  [dependenceChildren]="true"
                  [headers]="headers"
                  [keepState]="true"
                  [loading]="mainService?.machinesDisksStatuses$?.value?.loadingDisks"
                  [multipleSelect]="true"
                  [needChildrenPaddingLeft]="false"
                  [needToggleOnEndRow]="true"
                  [selectedItems]="selectedDisks"
                  [showHeaders]="false"
                  [showChildrenHeaders]="false"
                  [showTableCheckboxes]="true"
                  [stripedTable]="false"
                  [subtitleHeaders]="subHeaders"
                  (changeSelected)="diskSelectChangeHandler($event)"
                  [showTopControls]="true"
                  [topControlsLabel]="true"
                  childHeaderClasses='background-white'
                  classesTable="-accordion"
                  bindChildren="disks"
                  bindParentKey="uiGuid"
                  bindSelectedChildren="uiGuid"
                  rowClasses='background-light'
                  viewMode="table">
    <ng-template prependTopControlsLabel>
      <strong>
        {{
        'wizards:selected_virtual_disks' | i18next:
          {
            selected: stepForm.get('type')?.value === virtualDisksSelectedType.Selected ? stepForm.get('disks')?.value?.length : availableDisks?.length,
            all: availableDisks?.length
          }
        }}
      </strong>
    </ng-template>
    <ng-template mbsTableCell
                 let-computer>
      <div class="form-row">
        <div class="col">
          <img [src]="virtualMachinesIcon"
               class="mr-2"
               [alt]="computer.displayName">
          {{ computer.displayName }}
        </div>
        <div *ngIf="needShowTag(computer)"
             [attr.data-test-element]="elementsSelector.name.computerExcludeTag"
             class="col-auto">
          <mbs-tag [clickable]="false"
                   [closable]="false"
                   [active]="true"
                   [noBorder]="true"
                   [size]="mbsSize.sm"
                   [class]="'background-orange'">
            {{'wizards:exclude_exist' | i18next}}
          </mbs-tag>
        </div>
      </div>
    </ng-template>

    <ng-template mbsTableCell
                 group="child"
                 let-disk>
      <div placement="auto"
           ngbTooltip="{{ validVersionHyperVLabelDisk ? disk.label : disk.fileName }}"
           tooltipClass="tooltip-lg"
           class="text-overflow">
        {{ validVersionHyperVLabelDisk ? disk.label : disk.fileName }}
      </div>
    </ng-template>
    <ng-template mbsTableCell
                 group="child"
                 let-disk>
      <mbs-button *ngIf="validVersionForShowExcludes; else onlyTextCapacity"
                  type="primary"
                  [isCtrl]="true"
                  [disabled]="isOffline"
                  (click)="excludeClickHandler(disk)">
        <mbs-tag *ngIf="stepForm?.get('type')?.value === virtualDisksSelectedType.Selected && disk.excludes?.length"
                 [attr.data-test-element]="elementsSelector.name.diskExcludeTag"
                 [clickable]="false"
                 [closable]="false"
                 [active]="true"
                 [noBorder]="true"
                 [size]="mbsSize.sm"
                 [class]="'background-orange'">
          {{'wizards:exclude_exist' | i18next}}
        </mbs-tag>
        {{disk.diskCapacityStr || '--'}}
        <span class="fa fa-folder-open ml-1"></span>
      </mbs-button>

      <ng-template #onlyTextCapacity>
        {{disk.diskCapacityStr || '--'}}
        <span class="fa fa-folder-open ml-1"></span>
      </ng-template>
    </ng-template>
  </mbs-table-grid>

  <mbs-alert *ngIf="tableIsInvalid"
             class="mt-3"
             [attr.data-test-element]="elementsSelector.name.invalidTableAlert"
             [type]="mbsPopupType.danger"
             [icon]="true">
    {{'wizards:select_virtual_disks_some_disks_not_selected_alert' | i18next}}
  </mbs-alert>
</form>
