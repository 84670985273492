<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{isBackupPlan || isVMWare || isHyperV ?
      ('wizards:archive_consistency_backup_title' | i18next: { format: 'title' }) :
        ('wizards:archive_consistency_title' | i18next: { format: 'title' }) }}
    </h4>
  </header>

  <div class="mbs-wizard_form-content">

    <mbs-form-group>
      <mbs-checkbox formControlName="useFullConsistencyCheck"
                    [label]="'wizards:use_full_consistency_label' | i18next : { format: 'title' }"
                    [descriptionOutside]="false"
                    [id]="'use-full-consistency-checkbox'">
        <ng-template checkboxDescription>
          <div class="mbs-form-text text-sm"
               [class.mb-5]="isIBBPlan">
            {{ 'wizards:use_full_consistency_description_part1' | i18next }}<br>
            {{ 'wizards:use_full_consistency_description_part2' | i18next }}
          </div>
        </ng-template>
      </mbs-checkbox>
    </mbs-form-group>

    <ng-container *ngIf="isIBBPlan">
      <div class="mbs-form-text mb-3">
        <div class="d-flex align-items-center mb-2">
          <span class="fa fa-shield text-lg mr-2"></span>
          <h5 class="mt-0 mb-0 lh-1">{{ 'wizards:restore_verification_title' | i18next }}</h5>
        </div>
        <div class="text-sm">
          {{ 'wizards:restore_verification_description' | i18next }} <br>
          <a *ngIf="ability.can('read', 'HelpMarketing')"
             href="https://help.msp360.com/cloudberry-backup/backup/about-backups/restore-verification" target="_blank">
            {{ 'wizards:learn_more_link' | i18next }}
          </a>
        </div>
      </div>

      <mbs-form-group>
        <mbs-radio formControlName="RunRestoreVerificationOn"
                   name="RunRestoreVerificationOn"
                   [label]="'wizards:run_restore_verification_none_label' | i18next"
                   (change)="needCheckChange(false)"
                   [id]="'run-restore-verification-radio-0'"
                   [subtitle]="'wizards:run_restore_verification_none_subtitle' | i18next"
                   [subtitleOutside]="false"
                   [value]="0"></mbs-radio>
        <ng-container *ngIf="restoreVerificationEnabled">
          <mbs-radio formControlName="RunRestoreVerificationOn"
                     name="RunRestoreVerificationOn"
                     [label]="'wizards:run_restore_verification_full_label' | i18next"
                     (change)="needCheckChange()"
                     [id]="'run-restore-verification-radio-1'"
                     [value]="1"></mbs-radio>
          <mbs-radio formControlName="RunRestoreVerificationOn"
                     name="RunRestoreVerificationOn"
                     [label]="'wizards:run_restore_verification_incremental_label' | i18next"
                     (change)="needCheckChange()"
                     [id]="'run-restore-verification-radio-2'"
                     [value]="2"></mbs-radio>
          <mbs-radio formControlName="RunRestoreVerificationOn"
                     name="RunRestoreVerificationOn"
                     [label]="'wizards:run_restore_verification_full_incremental_label' | i18next"
                     (change)="needCheckChange()"
                     [id]="'run-restore-verification-radio-3'"
                     [value]="3"></mbs-radio>
        </ng-container>
      </mbs-form-group>

      <ng-container *ngIf="restoreVerificationEnabled">
        <mbs-form-group>
          <div class="form-row align-items-center">
            <div class="col-auto">
                  <span class="mbs-form_label mb-0"
                        [class.-disabled]="stepForm.get('minutes').disabled">{{ 'wizards:screenshot_interval_label' | i18next }}</span>
            </div>
            <div class="col-2">
              <mbs-number formControlName="minutes"
                          max="59"
                          min="0"
                          [id]="'interval-minutes-number'"></mbs-number>
            </div>
            <div class="col-auto">
              <label class="mbs-form_label mb-0"
                     for="interval-minutes-number"
                     [class.-disabled]="stepForm.get('minutes').disabled">
                {{ 'wizards:interval_minutes' | i18next }}
              </label>
            </div>
            <div class="col-2">
              <mbs-number formControlName="seconds"
                          max="59"
                          min="0"
                          [id]="'interval-seconds-number'"></mbs-number>
            </div>
            <div class="col-auto">
              <label class="mbs-form_label mb-0"
                     for="interval-seconds-number"
                     [class.-disabled]="stepForm.get('seconds').disabled">
                {{ 'wizards:interval_seconds' | i18next }}
              </label>
            </div>
          </div>
        </mbs-form-group>

        <mbs-form-group>
          <div class="form-row align-items-baseline">
            <div class="col-auto">
              <label class="mbs-form_label mb-0"
                     for="num-cpu-number"
                     [class.-disabled]="stepForm.get('numCpu').disabled">
                {{ 'wizards:num_cpu_number_label' | i18next }}
              </label>
            </div>
            <div class="col-2">
              <mbs-number formControlName="numCpu"
                          max="4"
                          min="1"
                          [id]="'num-cpu-number'"></mbs-number>
            </div>
            <div class="col-auto">
              <label class="mbs-form_label mb-0"
                     for="memory-in-mb-select"
                     [class.-disabled]="stepForm.get('memoryInMb').disabled">
                {{ 'wizards:memory_in_mb_select_label' | i18next }}
              </label>
            </div>
            <div class="col-3">
              <mbs-number formControlName="memoryInMb"
                          [max]='maxStartupRAM'
                          [min]='minStartupRAM'
                          [placeholder]="'wizards:memory_in_mb_select_placeholder' | i18next"
                          [id]="'memory-in-mb-select'"></mbs-number>
            </div>
          </div>
        </mbs-form-group>

        <mbs-form-group formGroupName="dynamicMemory">
          <mbs-checkbox formControlName="enabled"
                        [label]="'wizards:dynamic_memory_enable_label' | i18next"
                        [descriptionOutside]="false"
                        (change)="dynamicMemoryChangeHandler($event)">
            <ng-template checkboxDescription>
              <div class="row">
                <div class="col-6">
                  <mbs-select bindLabel="name"
                              [items]="possibleMinMaxMemory"
                              [label]="'wizards:dynamic_memory_min_ram_label' | i18next"
                              [id]="'minimum-ram-1'"
                              [appendTo]="'body'"
                              [clearable]="false"
                              formControlName="min"></mbs-select>
                </div>
                <div class="col-6">
                  <mbs-select bindLabel="name"
                              [items]="possibleMinMaxMemory"
                              [label]="'wizards:dynamic_memory_max_ram_label' | i18next"
                              [id]="'maximum-ram-1'"
                              [appendTo]="'body'"
                              [clearable]="false"
                              formControlName="max"></mbs-select>
                </div>
              </div>
              <mbs-input-errors [errors]="[{ message: minMaxMemoryError }]"></mbs-input-errors>
            </ng-template>
          </mbs-checkbox>
        </mbs-form-group>

        <mbs-form-group>
          <div class="form-row align-items-center">
            <div class="col-auto">
            <span class="mbs-form_label mb-0"
                  [class.-disabled]="stepForm.get('failureMinutes').disabled">
              {{ 'wizards:failure_minutes_label' | i18next }}
            </span>
            </div>
            <div class="col-2">
              <mbs-number formControlName="failureMinutes"
                          max="900"
                          min="1"
                          [id]="'failure-minutes-number'"></mbs-number>
            </div>
            <div class="col-auto">
              <label class="mbs-form_label mb-0"
                     for="failure-minutes-number"
                     [class.-disabled]="stepForm.get('failureMinutes').disabled">
                {{ 'wizards:interval_minutes' | i18next }}
              </label>
            </div>
          </div>
        </mbs-form-group>
      </ng-container>

      <mbs-alert *ngIf="isRDMode && stepForm.get('RunRestoreVerificationOn').value"
                 [type]="mbsPopupType.info"
                 [icon]="true"
                 class="mb-0">
        {{ 'wizards:requires_hyper_v_text' | i18next }}
      </mbs-alert>

      <mbs-alert *ngIf="!restoreVerificationEnabled"
                 [type]="mbsPopupType.warning"
                 [icon]="true"
                 class="mb-0">
        <span *ngFor="let item of restoreVerificationRequiresText;let last = last">
          {{item}}<br *ngIf="!last">
        </span>
      </mbs-alert>

    </ng-container>
  </div>
</section>
