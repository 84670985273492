import { PlanTypes } from "../models/PlanTypes.enum";

const restoreTypes = new Set([
  PlanTypes.RestorePlan,
  PlanTypes.RestoreDataBasePlan,
  PlanTypes.RestoreBareMetalPlan,
  PlanTypes.RestoreSystemState,
  PlanTypes.RestoreExchangePlan,
  PlanTypes.RestoreDatabaseFilesPlan,
  PlanTypes.RestoreVMware,
  PlanTypes.RestoreHyperV,
  PlanTypes.RestoreDiskImagePlan
]);

export function isRestorePlan(plan: PlanTypes) {
  return restoreTypes.has(plan);
}

const backupTypes = new Set([
  PlanTypes.Plan,
  PlanTypes.ExchangePlan,
  PlanTypes.DataBasePlan,
  PlanTypes.BareMetalPlan,
  PlanTypes.BackupVMware,
  PlanTypes.BackupHyperV,
  // PlanTypes.ConsistencyCheck,
  PlanTypes.BackupDiskImagePlan,
  PlanTypes.BackupSystemState,
  PlanTypes.CloudVMBackupEC2Plan,
  PlanTypes.CloudVMRestoreEC2Plan
]);

export function isBackupPlan(plan: PlanTypes) {
  return backupTypes.has(plan);
}

export function getOfflineDuration(duration: number): string {
  const units = [
    { name: 'year', value: 24 * 60 * 365 },
    { name: 'month', value: 24 * 60 * 30 },
    { name: 'week', value: 24 * 60 * 7 },
    { name: 'day', value: 24 * 60 },
    { name: 'hour', value: 60 }
  ];
  const unitDurationValue = (unitValue: number) => Math.floor(duration / unitValue);
  const unitDuration = units.find((unit) => !!unitDurationValue(unit.value));
  if (unitDuration) {
    const value = unitDurationValue(unitDuration.value);
    return `${value} ${unitDuration.name}${value > 1 ? 's' : ''}`;
  }
  return !duration ? '' : `${duration} min.`;
}
