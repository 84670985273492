import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BackupDateTimeFormatComponent } from './backup-date-time-format.component';
import { DateTimeFormatComponent } from './date-time-format.component';
import { DateTimeFormatModule } from './date-time-format.module';

@NgModule({
  imports: [CommonModule, DateTimeFormatModule, NgbTooltipModule],
  declarations: [BackupDateTimeFormatComponent],
  exports: [BackupDateTimeFormatComponent, DateTimeFormatComponent]
})
export class BackupDateTimeFormatModule {}
