import { Component, ViewChild } from '@angular/core';
import Computer from '@models/Computer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, SidepanelService } from 'mbs-ui-kit';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { SidepanelDownloadsComponent } from '../../sidepanel-downloads/sidepanel-downloads.component';

@UntilDestroy()
@Component({
  selector: 'agent-update-required-modal',
  templateUrl: './agent-update-required-modal.component.html'
})
export class AgentUpdateRequiredModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public alertType = MbsPopupType.warning;

  constructor(private i18n: I18NextPipe, private sidepanelService: SidepanelService) {}

  public get osName(): string {
    const isMac = Computer.isMac(this.baseModal?.data?.computer);

    return this.i18n.transform(`computers.module:searchOSType.${isMac ? 'macOS' : 'linux'}`);
  }

  public openDownloadsSidepanel(): void {
    this.sidepanelService.addAndOpenByType(SidepanelDownloadsComponent).pipe(untilDestroyed(this)).subscribe();
    this.baseModal.close();
  }
}
