import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import CustomDnsBinding from '../models/CustomDnsBinding';

@Injectable({
  providedIn: 'root'
})
export class CustomDnsBindingsService extends EntityCollectionServiceBase<CustomDnsBinding> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CustomDnsBinding', serviceElementsFactory);
  }
}
