<div class="mbs-header mbs-main-menu">
  <nav class="navbar navbar-light navbar-expand-nav-lg mbs-navbar">
    <div class="row no-gutters navbar-inner align-items-center">
      <div class="d-flex col-10">
        <div class="home-page-area text-center border-right">
          <a id="homePageUrl"
             class="navbar-brand mbs-navbar_brand"
             [routerLink]="homePageUrl">
            <span *ngIf="appEnvState?.type === 'STAGE';else homeIcon"
                  class="fa fa-pie-chart text-danger"
                  [title]="appEnvState?.type.toLocaleUpperCase() + ' / ' + appEnvState?.version"></span>
            <ng-template #homeIcon>
              <span class="fa fa-pie-chart nav-link_ico"></span>
            </ng-template>
          </a>
        </div>
      </div>
      <div class="d-flex col-2 justify-content-end">
        <div class="row no-gutters align-items-center justify-content-end">
          <div ngbDropdown
               display="dynamic"
               class="nav-item"
               #accountDropdown="ngbDropdown"
               placement="bottom-right bottom-left"
               *ngIf="currentUser">
            <button class="font-weight-bold btn btn-light"
                    id="dropdownUser"
                    ngbDropdownToggle>
              <span class="nav-link_ico fa fa-user-circle-o"></span>
            </button>
            <ul ngbDropdownMenu
                aria-labelledby="dropdownUser"
                placement="bottom"
                class="nav-submenu -arrow">
              <li class="nav-item mbs-navbar_userinfo disabled"
                  disabled="true">
                <div class="mbs-navbar_userinfo-item text-muted">
                  {{ displayName }}
                </div>
                <div class="mbs-navbar_userinfo-item text-muted"
                     *ngIf="email">
                  {{ email }}
                </div>
                <div *ngIf="currentUser?.ProviderInfo?.CompanyName"
                     class="mbs-navbar_userinfo-item text-muted">
                  {{ currentUser?.ProviderInfo?.CompanyName }} {{ 'app-header:texts.company' | i18next }}
                </div>
              </li>
              <li class="dropdown-divider"></li>
              <li ngbDropdownItem
                  class="nav-submenu-item">
                <button class="nav-submenu-link right-submenu"
                        (click)="handleLogout()">
                  <span class="nav-link_ico fa fa-sign-out"></span>
                  {{ 'app-header:buttons.logOff' | i18next }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>

<div class="mbs-header">
  <nav class="navbar mbs-navbar empty-navbar">
  </nav>
</div>
