import { Injectable } from '@angular/core';
import { getGeneralHostInfo, HostInfoRow } from '@components/computers-shared/get-general-host-Info';
import { ComputersFacade } from '@facades/computers.facade';
import { AvailablePlanStartModes } from '@models/backup/available-plan-start-modes';
import { PlanInfoData } from '@models/backup/plan-info-model';
import Computer, { AgentType } from '@models/Computer';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ComputersAspxAbstractService } from '@services/computers-legacy/computers-legacy.service';
import { RmCommandsAbstractWrapper } from '@services/rm-commands.wrapper';
import moment from 'moment';
import { combineLatest, first, map, Observable } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { ComputersBackupActions, ComputersBackupSelectors } from '../store/computersBackup';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ComputerBackupFacade {
  private hid$ = this.store.select(ComputersBackupSelectors.selectSelected);

  public computer$ = combineLatest([this.computersFacade.currentComputer$, this.hid$]).pipe(
    map(([computer, hid]) => (computer?.hid === hid ? computer : null))
  );
  public computerOnline$ = this.computer$.pipe(map((computer) => computer?.online));
  public hasBackupAgent$ = this.computer$.pipe(map((computer) => Computer.hasAgent(computer, AgentType.Backup)));
  public backupAgentOnline$ = this.computer$.pipe(map((computer) => Computer.isAgentOnline(computer, AgentType.Backup)));

  public hostInfo$ = this.store.select(ComputersBackupSelectors.selectCurrentHostInfo);
  public hostInfoLoading$ = this.store.select(ComputersBackupSelectors.selectCurrentHostInfoLoading);
  public hostInfoLoaded$ = this.store.select(ComputersBackupSelectors.selectCurrentHostInfoLoaded);

  public destinations$ = this.store.select(ComputersBackupSelectors.selectCurrentDestinations);
  public destinationsLoading$ = this.store.select(ComputersBackupSelectors.selectCurrentDestinationsLoading);
  public destinationsLoaded$ = this.store.select(ComputersBackupSelectors.selectCurrentDestinationsLoaded);

  public plans$ = this.store.select(ComputersBackupSelectors.selectCurrentPlans);
  public plansLoading$ = this.store.select(ComputersBackupSelectors.selectCurrentPlansLoading);
  public plansLoaded$ = this.store.select(ComputersBackupSelectors.selectCurrentPlansLoaded);

  public isPlanRunningById$ = (id: string) => this.store.select(ComputersBackupSelectors.selectIsPlanRunningById(id));
  public isPlanSavingById$ = (id: string) => this.store.select(ComputersBackupSelectors.selectIsPlanSavingById(id));

  public availablePlans$ = this.store.select(ComputersBackupSelectors.selectCurrentAvailablePlans);
  public availablePlansLoading$ = this.store.select(ComputersBackupSelectors.selectCurrentAvailablePlansLoading);
  public syncProcess$ = this.store.select(ComputersBackupSelectors.getSyncProcess);

  public currentTimezone$ = this.store.select(ComputersBackupSelectors.selectCurrentAgentTimezone);

  constructor(
    private store: Store,
    private rmCommands: RmCommandsAbstractWrapper,
    private computersFacade: ComputersFacade,
    private wizardService: RemoteManagementWizardsService,
    private computersLegacyService: ComputersAspxAbstractService
  ) {}

  public hostInfoByHid$(hid: string, directly = false): Observable<HostInfoRow[]> {
    return directly
      ? this.computersLegacyService.getComputerHostInfoLegacy(hid).pipe(
          map((data) => (data && !data.message ? getGeneralHostInfo(data, true).filter((r) => (r.visible ? r.visible() : true)) : [])),
          tap((data) => this.store.dispatch(ComputersBackupActions.loadGeneralInfoSuccess({ hid, data })))
        )
      : this.store.select(ComputersBackupSelectors.selectHostInfoByHid(hid));
  }

  public destinationsByHid$ = (hid: string) => this.store.select(ComputersBackupSelectors.selectDestinationsByHid(hid));
  public plansByHid$ = (hid: string) => this.store.select(ComputersBackupSelectors.selectPlansByHid(hid));

  public isHidExist$ = (hid: string) => this.store.select(ComputersBackupSelectors.selectIsHidExist(hid));
  public isHostInfoByHidExist$ = (hid: string) => this.store.select(ComputersBackupSelectors.selectIsHostInfoByHidExist(hid));
  public isDestinationByHidExist$ = (hid: string) => this.store.select(ComputersBackupSelectors.selectIsDestinationByHidExist(hid));
  public isPlansByHidExist$ = (hid: string) => this.store.select(ComputersBackupSelectors.selectIsPlansByHidExist(hid));

  public setComputer(hid: string): void {
    this.store.dispatch(ComputersBackupActions.setComputer({ hid }));
  }

  public refreshCurrentComputer(): void {
    this.computer$.pipe(first()).subscribe((computer) => {
      computer?.hid && this.computersFacade.loadComputerByHid({ hid: computer.hid, quiet: true, force: true });
    });
  }

  public clear(): void {
    this.store.dispatch(ComputersBackupActions.clear());
  }

  public loadGeneralInfo(options?: { force?: boolean; quiet?: boolean }): void {
    this.store.dispatch(ComputersBackupActions.loadGeneralInfo({ force: options?.force, quiet: options?.quiet }));
  }

  public loadDestinations(options?: { hid?: string; force?: boolean; quiet?: boolean }): void {
    this.store.dispatch(ComputersBackupActions.loadDestinations({ hid: options?.hid, force: options?.force, quiet: options?.quiet }));
  }

  public loadPlans(options?: { hid?: string; force?: boolean; quiet?: boolean }): void {
    this.store.dispatch(ComputersBackupActions.loadPlans({ hid: options?.hid, force: options?.force, quiet: options?.quiet }));
  }

  public getComputerPlans(hid: string): Observable<PlanInfoData> {
    return this.rmCommands.getComputerPlans(hid);
  }

  public getServiceAccountName(computer: Computer, agentType: AgentType = AgentType.Backup): Observable<string> {
    return this.rmCommands.getServiceAccountName(computer, agentType);
  }

  public changeServiceAccountName(
    computer: Computer,
    account: { accountName: string; accountPassword?: string },
    agentType: AgentType = AgentType.Backup
  ): Observable<boolean> {
    return this.rmCommands.changeServiceAccountName(computer, account, agentType);
  }

  public startPlan(computer: Computer, id: string, mode: AvailablePlanStartModes): Observable<boolean> {
    return this.rmCommands.startPlan(computer, id, mode);
  }

  public stopPlan(computer: Computer, id: string): Observable<boolean> {
    return this.rmCommands.stopPlan(computer, id);
  }

  public removePlan(computer: Computer, id: string): Observable<boolean> {
    return this.rmCommands.removePlan(computer, id);
  }

  public clonePlan(computer: Computer, id: string, name: string): Observable<boolean> {
    return this.rmCommands.clonePlan(computer, id, name);
  }

  public saveLegacyPlan(plan, settings = null): Observable<any> {
    const planParam = this.wizardService.PreparePlanBeforePostRM(plan);

    this.setPlanSaving(planParam.ID, true);

    return this.wizardService.PostRMPlan(plan, settings).pipe(finalize(() => this.setPlanSaving(planParam.ID, false)));
  }

  public getLegacyPlan(settings = null, extended = false): Observable<any> {
    return extended ? this.wizardService.GetRMPlanWithAdditionalRequests() : this.wizardService.GetRMPlanModel(settings);
  }

  public getAgentUtcOffsetValue(hid: string = null): Observable<number> {
    const hostInfo$ = hid ? this.isHostInfoByHidExist$(hid).pipe(switchMap((exist) => this.hostInfoByHid$(hid, !exist))) : this.hostInfo$;
    return hostInfo$.pipe(
      map((data) => {
        const timeZone = (data || []).find((hostInfo) => hostInfo.key === 'TimeZone');
        if (!timeZone?.value) return 0;
        const regexp = /^\(UTC([+-]*2[0-3]|[+-][01][0-9]:[0-5][0-9])\)/;
        const regexpArr = regexp.exec(timeZone.value);
        const utcOffsetValue = regexpArr?.length ? regexpArr[1] : null;
        return utcOffsetValue ? moment().utcOffset(utcOffsetValue).utcOffset() : null;
      })
    );
  }

  public getAvailablePlanTypes(options = { force: false }): void {
    this.store.dispatch(ComputersBackupActions.loadAvailablePlans(options));
  }

  public setPlanSaving(id: string, saving = true): void {
    this.store.dispatch(ComputersBackupActions.setPlanSaving({ id, saving }));
  }

  public setPlanRunning(id: string, running = true): void {
    this.store.dispatch(ComputersBackupActions.setPlanRunning({ id, running }));
  }

  getSyncStatus(hid: string, force = false): void {
    this.store.dispatch(ComputersBackupActions.loadSyncStatus({ hid, force }));
  }
}
