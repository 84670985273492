<mbs-modal>
  <ng-container modal-body>
    <section class="d-flex flex-column mbs-remote-connection-prepare-modal-component_content">
      <ng-container [ngSwitch]="connectionMode$ | async">
        <ng-template [ngSwitchCase]="PrepareConnectionMode.Ready">
          <header class="mb-3 mt-4 pt-4">
            <h4 class="mb-0 d-flex justify-content-center align-items-center">
              <span>{{ 'computers.module:connect:connectionReady' | i18next }}</span>
            </h4>
          </header>
          <p class="text-center mb-0">
            <span [innerHTML]="'computers.module:connect:readyBody1' | i18next"></span>
            <mbs-button type="link"
                        [isCtrl]="true"
                        (click)="connect(connectionType)">
              {{ 'computers.module:connect:readyBody1Link' | i18next }}
            </mbs-button>
          </p>
          <p class="text-center mb-0 mt-3">
            {{ 'computers.module:connect:ifConnectNotInstalled' | i18next }}
            <br />
            <a class="ctrl-primary"
               [href]="downloadLink"
               download>{{ 'computers.module:connect:clickHere' | i18next }}</a>
            {{ 'computers.module:connect:toDownloadAndInstall' | i18next }}
            <ng-container *ngIf="isAgentSupportWebRTC$ | async">
              <span [innerHTML]="'computers.module:connect:orUse' | i18next"></span>
              <mbs-button [isCtrl]="true"
                          (click)="connect(Type.WebRTC)">
                {{ 'computers.module:connect:webVersion' | i18next }}
              </mbs-button>
            </ng-container>
          </p>
        </ng-template>
        <ng-template [ngSwitchCase]="PrepareConnectionMode.Preparation">
          <header class="mb-3 mt-4 pt-4">
            <h4 class="mb-0 d-flex justify-content-center align-items-center">
              <span>{{ 'computers.module:connect:preparingConnection' | i18next }}</span>
            </h4>
          </header>
          <p class="text-x text-center pb-4">
            {{ 'computers.module:connect:click' | i18next }}
            <strong>{{ 'computers.module:connect:openConnect' | i18next }}</strong>
            {{ 'computers.module:connect:inTheBrowserPopUp' | i18next }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <div class="py-2">
              <span class="loader loader-lg"></span>
            </div>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="PrepareConnectionMode.Installation">
          <header class="mb-3 mt-4 pt-4">
            <h4 class="mb-0 d-flex justify-content-center align-items-center">
              <span>{{ 'computers.module:connect:installingApplication' | i18next }}</span>
            </h4>
          </header>
          <p class="text-x text-center pb-4">
            {{ 'computers.module:connect:installingApplicationSubText' | i18next }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <div class="py-2">
              <span class="loader loader-lg"></span>
            </div>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="PrepareConnectionMode.Installed">
          <header class="mb-3 mt-4 pt-4">
            <h4 class="mb-0 d-flex justify-content-center align-items-center">
              <span>{{ 'computers.module:connect:installationCompleted' | i18next }}</span>
            </h4>
          </header>
          <p class="text-x text-center pb-4">
            {{ 'computers.module:connect:installationCompletedSubText1' | i18next }}
            <b>{{ 'app:products.ra' | i18next }}</b>
            {{ 'computers.module:connect:installationCompletedSubText2' | i18next }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <div class="py-3">
              <mbs-button type="primary"
                          (click)="connect(connectionType, true)">
                {{ 'app:products.ra' | i18next }}
              </mbs-button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </section>
  </ng-container>
</mbs-modal>
