<mbs-modal [isCustomModal]="true"
           [showFooter]="true"
           title="{{ 'computers.module:modals:clonePlanCaption' | i18next : { format: 'title' } }}"
           #modal>
  <div modal-body>
    <form [formGroup]="clonePlanForm"
          class="mbs-form">
      <mbs-input placeholder="Clone plan name..."
          [autocomplete]="'off'"
          formControlName="cloneName"
          [validationWhitespace]="true"></mbs-input>
    </form>
  </div>
  <ng-container modalFooter>
    <mbs-button type="success"
                [loading]="loading"
                (click)="handleResolve()">{{ 'app:buttons:clone' | i18next : { format: 'title' } }}</mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'app:buttons:cancel' | i18next : { format: 'title' } }}</mbs-button>
  </ng-container>
</mbs-modal>
