import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import Computer from '@models/Computer';
import { LicenseType } from '@models/LicenseType.enum';
import { ComputerLicense, ComputerLicenseStatus } from '@root/mbs-ui/src/app/shared/models/License';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { mediumDateWithoutTimeMoment } from '@utils/date';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { MbsPopupType, ModalService } from 'mbs-ui-kit';
import { noop, Observable, tap } from 'rxjs';
import { POST_PAYMENT_TOKEN } from '../../../../licenses/tokens/post-payment.token';
import { BuyLicenseModalComponent } from '../../../modals/buy-license-modal/buy-license-modal.component';
import { ReleaseLicenseModalComponent } from '../../../modals/release-license-modal/release-license-modal.component';
import { SelectLicenseModalComponent } from '../../../modals/select-license-modal/select-license-modal.component';
import { LicensesService } from './../../../../../services/licenses.service';

@Component({
  selector: 'mbs-sidepanel-backup-license-tab-card',
  templateUrl: './sidepanel-backup-license-tab-card.component.html',
  styleUrls: ['./sidepanel-backup-license-tab-card.component.css']
})
export class SidepanelBackupLicenseTabCardComponent {
  @Input() license: ComputerLicense;
  @Input() computer: Computer;
  @Output() onReleaseLicense = new EventEmitter<void>();
  @Output() onVMLicenseChange = new EventEmitter<void>();

  public ComputerLicenseStatus = ComputerLicenseStatus;
  public mediumDateWithoutTimeMoment = mediumDateWithoutTimeMoment;
  public readonly MbsPopupType = MbsPopupType;

  public get licenseName(): string {
    return this.license?.licenseType?.displayName;
  }

  public get licenseStatus(): ComputerLicenseStatus | null {
    if (!this.license) {
      return null;
    }

    if (this.license?.isExpired) {
      return ComputerLicenseStatus.EXPIRED;
    }

    return this.license?.isTrial ? ComputerLicenseStatus.TRIAL : ComputerLicenseStatus.PAID;
  }

  public get licenseStatusClass(): string {
    if (this.license?.isExpired) {
      return 'expired';
    }

    return this.license?.isTrial ? 'trial' : 'paid';
  }

  public get hint(): string {
    if (this.license?.isExpired) {
      return 'computers.module:license.status.expired';
    }

    return this.license?.isTrial ? 'computers.module:license.status.trial' : 'computers.module:license.status.paid';
  }

  get isTrialVMLicense(): boolean {
    return this.isVMLicense && this.license?.isTrial;
  }

  get isPaidVMLicense(): boolean {
    return this.isVMLicense && !this.license?.isTrial;
  }

  get isVMLicense(): boolean {
    return Boolean(this.license) && this.license.licenseType?.id === LicenseType.VirtualMachine;
  }

  get isVMSocketLicense(): boolean {
    return Boolean(this.license) && this.license.licenseType?.id === LicenseType.VirtualMachineSocket;
  }

  constructor(
    private modalService: ModalService,
    private licensesService: LicensesService,
    private i18: I18NextPipe,
    private computersFacade: ComputersFacade,
    private ability: AbilityService,
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>
  ) {}

  public buyLicense(): void {
    this.modalService
      .openCustom(BuyLicenseModalComponent, {
        data: {
          licenseType: this.license.licenseType.id
        }
      })
      .catch(noop);
  }

  public releaseLicense(): void {
    this.modalService
      .openCustom(ReleaseLicenseModalComponent, {
        data: {
          computer: this.computer,
          license: this.license
        }
      })
      .then(() => {
        this.onReleaseLicense.emit();
        this.loadComputer();
      })
      .catch(noop);
  }

  public selectLicense(): void {
    const kindForEmptyLicense = 1;

    this.modalService
      .openCustom(SelectLicenseModalComponent, {
        data: {
          title: this.i18.transform(!this.license ? 'backup:selectLicense' : 'backup:changeLicense'),
          button: this.i18.transform(!this.license ? 'buttons:assign' : 'backup:changeLicense'),
          computer: this.computer,
          licenseTypeId: this.license?.licenseType?.id || null,
          kind: !this.license ? kindForEmptyLicense : this.license?.licenseKind,
          isAssign: !this.license
        }
      })
      .then((result: ComputerLicense) => {
        if (this.isVMSocketLicense || this.isVMLicense) this.onVMLicenseChange.emit();

        this.license = result;
        this.loadComputer();
      })
      .catch(noop);
  }

  public loadComputer() {
    this.computersFacade.loadComputerByHid({ hid: this.computer?.hid, force: true });
  }

  public getAutoRenewRequest(isEnable: boolean): Observable<null> {
    return this.licensesService
      .setAutoRenewalForComputerLicense(this.computer?.hid, this.license?.licenseType?.id, isEnable)
      .pipe(tap(() => (this.license.autoRenew = isEnable)));
  }

  public isReleaseBtnShown(isPostPayment: boolean): boolean {
    return (
      (!isPostPayment && this.licenseStatus === ComputerLicenseStatus.PAID) ||
      (isPostPayment && this.licenseStatus === ComputerLicenseStatus.TRIAL) ||
      this.isVMSocketLicense
    );
  }

  public isBuyBtnShown(isPostPayment: boolean): boolean {
    return (
      (this.licenseStatus === ComputerLicenseStatus.EXPIRED || this.licenseStatus === ComputerLicenseStatus.TRIAL) &&
      !isPostPayment &&
      this.ability.can('read', 'LicenseBuy')
    );
  }

  public isSelectBtnShown(isPostPayment: boolean): boolean {
    return (
      !isNil(this.license?.licenseKind) &&
      !isNil(this.licenseStatus) &&
      !(isPostPayment && this.licenseStatus === ComputerLicenseStatus.TRIAL) &&
      !this.isVMSocketLicense
    );
  }

  public getLicenseStatusTitle(status: ComputerLicenseStatus, isHint = false) {
    if (status === ComputerLicenseStatus.TRIALEXPIRED) {
      return isHint
        ? Computer.getLicenseStatusTitle(ComputerLicenseStatus.TRIAL)
        : Computer.getLicenseStatusTitle(ComputerLicenseStatus.EXPIRED);
    }

    return Computer.getLicenseStatusTitle(status);
  }
}
