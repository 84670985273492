<mbs-modal [title]="'computers.module:connectionLicense:licenseRequired' | i18next">
  <ng-container modal-body>
    <mbs-alert [type]="'info'">
      <ng-container *ngIf="!isMacOrLinux; else macorlinux">
        {{ 'computers.module:connectionLicense:licenseIsRequiredWeb' | i18next }}
      </ng-container>
      <ng-template #macorlinux>
        {{ 'computers.module:connectionLicense:licenseIsRequiredSSHVNC' | i18next }}
      </ng-template>
      <a *ngIf="'HelpMarketing' | can: 'read'"
         href="/AP/Help/remote-management/connect/licensing"
         target="_blank">
        {{ 'computers.module:connectionLicense:learnMore' | i18next }}
      </a>
    </mbs-alert>
    <mbs-form-group>
      <mbs-radio [label]="'computers.module:connectionLicense:useBackupLicense' | i18next"
                 [subtitle]="'computers.module:connectionLicense:selectOptionToConnectUsingWebBrowser' | i18next"
                 [subtitleHide]="isMacOrLinux"
                 [subtitleOutside]="false"
                 [(ngModel)]="type"
                 [descriptionOutside]="false"
                 [value]="connectionLicenseType.Backup">
      </mbs-radio>
      <mbs-radio [label]="'computers.module:connectionLicense:useConnectOrRMMLicense' | i18next"
                 [subtitle]="'computers.module:connectionLicense:selectOptionToConnectToAllYourComputers' | i18next"
                 [subtitleHide]="isMacOrLinux"
                 [subtitleOutside]="false"
                 [(ngModel)]="type"
                 [descriptionOutside]="true"
                 [value]="connectionLicenseType.RMM">
      </mbs-radio>
    </mbs-form-group>
    {{ 'computers.module:connectionLicense:onceYouGrantRequiredLicense' | i18next }}
  </ng-container>
  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                (click)="baseModal.save(type)">
      {{ 'app:buttons:proceed' | i18next }}
    </mbs-button>
    <mbs-button (click)="baseModal.close()">
      {{ 'app:buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
