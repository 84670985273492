<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:computers_to_restore_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-list-select bindSelected="name"
                   id="list-selected-computers"
                   [tableContentClass]="'cursor-pointer'"
                   [invalid]="stepForm.touched && stepForm.invalid"
                   [keepState]="true"
                   [loadSource]="false"
                   [maxHeight]="'336px'"
                   [multipleSelect]="false"
                   [showCheckboxes]="false"
                   [showHeaders]="false"
                   [showSearch]="false"
                   [selectedData]="stepForm.get('computerName').value ? [stepForm.get('computerName').value] : []"
                   [data]="computers"
                   (changeSelected)="changeSelectedHandler($event)"
                   (click)="clickHandler()"
                   [headers]="headers">
    <ng-template mbsTableCell
                 let-comp>
      <div class="row align-items-center py-1 flex-nowrap">
        <div class="col-auto d-flex">
          <img [src]="comp.isCurrent ? 'assets/images/for-wizards/HomeComputerPicture.svg' : 'assets/images/for-wizards/MyCompPicture.svg'"
               alt="Icon">
        </div>
        <div class="col text-overflow font-weight-semibold text-black">
          {{comp.name}}
        </div>
      </div>
    </ng-template>
  </mbs-list-select>


</section>
