import { BaseRestoreFormsModel } from '../base/base-restore-forms-model';
import { RestoreSourceStepValue } from '../restore-source-models';

export class RestoreFileFormModel extends BaseRestoreFormsModel {
  restoreSourceStep: RestoreSourceStepValue = { folders: [], valid: true };

  constructor(public isNBF = false, public isLinux = false) {
    super(isNBF);
    this.destinationStep = {
      destinationFolder: '',
      toOriginalLocation: true,
      saveLocationAsDefault: false,
      restoreDeletedFiles: false,
      overwriteExistingFiles: false,
      restoreOnlyNewFiles: false,
      valid: true
    };

    if (isLinux) {
      this.restorePointStep = {
        restoreType: 0,
        fromDateTime: { date: '', time: '' },
        toDateTime: { date: '', time: '' },
        syncBeforeRun: false,
        valid: true
      };
    } else this.destinationStep.restoreNTFSPermissions = false;
  }
}
