import { AuthStatus } from '@models/Computer';
import {
  ComputersAuthStatusModel,
  ComputersStatisticHealthStateModel,
  ComputersStatisticStateModel,
  ComputersStatisticVersionStatusModel
} from '@models/computersStatistic';

export interface ComputersStatisticState {
  oneComputerExist: boolean;
  oneComputerExistLoading: boolean;
  oneComputerExistLoaded: boolean;

  state: ComputersStatisticStateModel;
  healthState: ComputersStatisticHealthStateModel;
  authStatus: ComputersAuthStatusModel;
  versionStatus: ComputersStatisticVersionStatusModel;

  stateTotal: number;
  healthTotal: number;

  stateLoading: boolean;
  stateLoaded: boolean;
  stateError: string;

  healthLoading: boolean;
  healthLoaded: boolean;
  healthError: string;

  statisticLoading: boolean;
  statisticLoaded: boolean;
  statisticError: string;

  isStateEmpty: boolean;
}

export const initialComputersStatisticState: ComputersStatisticState = {
  oneComputerExist: false,
  oneComputerExistLoading: false,
  oneComputerExistLoaded: false,

  state: {
    active: 0,
    hidden: 0
  },
  healthState: {
    healthy: 0,
    problem: 0,
    warning: 0
  },
  authStatus: {
    [AuthStatus.Pending]: 0,
    [AuthStatus.Authorized]: 0
  },
  versionStatus: {
    outdated: 0,
    unsupported: 0
  },
  stateTotal: 0,
  healthTotal: 0,

  stateLoading: false,
  stateLoaded: false,
  stateError: null,

  healthLoading: false,
  healthLoaded: false,
  healthError: null,

  statisticLoading: false,
  statisticLoaded: false,
  statisticError: null,

  isStateEmpty: true
};
