import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { RestorePointItem } from '@modules/wizards/models/restore-point-models';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';

export interface PasswordModalParams {
  title?: string;
  password?: string;
  hid: string;
  restorePoint: RestorePointItem;
  currentEncryptedRestorePoint: string;
  backupVersionUpdated: string;
  passwordRecoveryEnabled?: boolean;
  passwordInvalid?: boolean;
  showHint?: boolean;
  collapsing?: boolean;
}

@Component({
  selector: 'mbs-password-modal',
  templateUrl: './password-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordModalComponent implements OnInit {
  // Not updated from baseModal.data
  public readonly mbsPopupType = MbsPopupType;
  public readonly elementsSelector = {
    css: {
      passwordModalTopText: 'passwordModalTopText',
      passwordModalRecovery: 'passwordModalRecovery',
      passwordModalHintBlock: 'passwordModalHintBlock',
      passwordModalHintText: 'passwordModalHintText'
    },
    name: {
      passwordModalPassField: 'passwordModalPassNameField',
      passwordModalHintButton: 'passwordModalHintButton'
    },
    id: {
      passwordModalPassField: 'passwordModalPassField',
      passwordModalChangeTypePassword: 'passwordModalChangeTypePassword'
    }
  };

  public passwordInputType: 'password' | 'text' = 'password';
  public passForm = new FormGroup({ password: new FormControl('', [Validators.required, this.passValidator.bind(this)]) });
  passValidator(): ValidationErrors | null {
    return this.passwordInvalid ? { invalidPass: { message: 'Invalid password' } } : null;
  }

  // Updated from baseModal.data
  public title = '';
  public okButtonText = '';
  public cancelButtonText = '';
  public hid = '';
  public currentEncryptedRestorePoint = '';
  public passwordInvalid = false;
  public showHint = false;
  public passwordRecoveryEnabled = false;
  public backupVersionUpdated = '';
  public restorePoint: RestorePointItem;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  ngOnInit(): void {
    if (this.baseModal?.data) {
      this.title = this.baseModal.data.title;
      this.hid = this.baseModal.data.hid;
      this.currentEncryptedRestorePoint = this.baseModal.data.currentEncryptedRestorePoint;
      this.passwordInvalid = this.baseModal.data.passwordInvalid;
      this.showHint = this.baseModal.data.showHint;
      this.passwordRecoveryEnabled = this.baseModal.data.passwordRecoveryEnabled;
      this.backupVersionUpdated = this.baseModal.data.backupVersionUpdated;
      this.restorePoint = this.baseModal.data.restorePoint;
      if (this.baseModal.data.password) this.passForm.get('password').setValue(this.baseModal.data.password);
      if (this.passwordInvalid) {
        this.passForm.get('password').markAsTouched();
        this.passForm.get('password').markAsDirty();
        this.passForm.get('password').updateValueAndValidity();
      }
    }
  }

  changePasswordType(): void {
    this.passwordInputType = this.passwordInputType === 'text' ? 'password' : 'text';
  }

  passwordInputHandler(): void {
    this.passwordInvalid = false;
  }

  showPasswordHintChangeHandler(): void {
    this.showHint = !this.showHint;
  }

  confirmClickHandler(): void {
    if (this.passForm.get('password').valid) {
      this.baseModal.save({ password: this.passForm.get('password') });
    }
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }
}
