import { DefaultSelectData } from '@models/SelectData';
import { AzureDataValue, AzureStepValue } from '../azure-models';
import { DiskInfoCommunication } from '../base/base-models/plan-disk-info-model';
import { BaseRestoreFormsModel } from '../base/base-restore-forms-model';
import { EC2InstanceTypes } from '../base/other-base-models/EC2-instance-types-model';
import { EC2DataValue, EC2StepValue } from '../EC2-models';
import { PartitionsStepValue } from '../partitions-models';
import { TemporaryDataValue, TemporaryInstanceStepValue } from '../temporary-instance-models';
import { RestoreAsDiskEnum, TypeDataStepValue } from '../type-data-models';

export class RestoreIbbFormModel extends BaseRestoreFormsModel {
  azureStep: AzureStepValue = {
    account: '',
    computerName: '',
    dataDiskName: '',
    locations: '',
    resourceGroup: '',
    vmSize: '',
    network: '',
    subnet: '',
    storage: '',
    container: '',
    bootDiagnosticStorage: '',
    valid: true
  };
  azureData: AzureDataValue = {
    accounts: [],
    locations: [],
    resourceGroups: [],
    vmSizes: [],
    networks: [],
    subnetItems: [],
    storageItems: [],
    containers: [],
    BDSItems: []
  };
  diskItems: DiskInfoCommunication[] = [];
  partitions: DefaultSelectData[] = [];
  partitionsStep: PartitionsStepValue = { restorePartitions: [], convertToMBR: false, valid: true };
  typeDataStep: TypeDataStepValue;
  temporaryInstanceStep: TemporaryInstanceStepValue = {
    useTemporary: false,
    account: '',
    region: '',
    availabilityZones: '',
    instanceType: 't1_micro',
    subnet: '',
    securityGroup: '',
    ami: '',
    valid: true
  };
  EC2: EC2StepValue = {
    account: '',
    region: '',
    availabilityZones: '',
    instanceType: 't1_micro',
    subnet: '',
    securityGroup: '',
    valid: true
  };
  temporaryData: TemporaryDataValue;
  EC2Data: EC2DataValue;

  constructor(public isNBF = false) {
    super(isNBF);
    const types = Object.keys(EC2InstanceTypes).filter((k) => typeof EC2InstanceTypes[k] === 'number' && k !== 'Unknown');
    this.destinationStep.isPhysical = false;
    this.destinationStep.selectedDisk = '';
    this.typeDataStep = { restoreAs: RestoreAsDiskEnum.Physical, createAMI: false, selectedFileFormat: this.fileFormats[0], valid: true };
    this.temporaryData = { accounts: [], regions: [], zones: [], types: types, subnetItems: [], securityGroups: [], amiItems: [] };
    this.EC2Data = { accounts: [], regions: [], zones: [], types: types, subnetItems: [], securityGroups: [] };
  }
}
