<mbs-modal [isCustomModal]="true"
           [title]="'pass-recovery:enablePasswordRecovery' | i18next">
  <ng-container modal-body>
    <mbs-alert [type]="mbsPopupType.warning"
               [class.mb-0]="!tryToClose"
               [icon]="true">
      {{ 'pass-recovery:passRecoveryImportanceAlertText' | i18next }}
    </mbs-alert>

    <mbs-checkbox *ngIf="tryToClose"
                  [label]="'pass-recovery:passRecoveryImportanceWarning' | i18next"
                  [formControl]="warningCheckbox">
    </mbs-checkbox>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="onPasswordRecoveryEnableButtonClick()">
      {{ 'buttons:enable' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                [disabled]="tryToClose && !warningCheckbox.value"
                (click)="onCloseButtonClick()">
      {{ 'buttons:close' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
