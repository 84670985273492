import { AzureAccessTierType } from '../advanced-options-models';
import { RestoreType } from '../restore-point-models';
import { GlacierRestoreTiers } from './base-models/plan-glacier-restor-tiers-model';
import { RehydratePriority } from './base-models/plan-rehydrate-priority-model';
import { DeletePlan } from './base-models/plan-restore-delete-plan-model';
import { RestorePlanType } from './base-models/plan-restore-plan-type-model';

export class BaseRestorePlan {
  public UseHistory: boolean;
  public PeakRetrievalRateLimit: number | string;
  public RehydratePriority: RehydratePriority;
  public AccessTier: AzureAccessTierType;
  public KeepRestoredColdFilesDaysCount: number;
  public GlacierRestoreTier: GlacierRestoreTiers;
  public LimitRestore: boolean;
  public DeletePlan: DeletePlan;
  public PointInTimeUTC: Date | string;
  public PointInTime: Date | string;
  public RestoreSourcePrefix: string;
  public RestoreType: RestoreType;
  public RestorePlanType: RestorePlanType;
  public IsRestoreLatestVersion: boolean;
  public IsPlanCanBeRunnedWithoutLicense: boolean;
}
