import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { MbsSize, ModalComponent } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-tfa-require-modal',
  templateUrl: './tfa-require-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TFARequireModalComponent implements OnInit {
  public readonly MbsSize = MbsSize;
  public actionName = 'Remote Management';
  public isVerb = false;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.actionName = this.baseModal?.data?.actionName;
    this.isVerb = this.baseModal?.data?.isVerb ?? false;
  }
}
