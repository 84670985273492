import { map, scan } from 'rxjs/operators';

export function hasActionsQueue() {
  return (
    map((i: boolean & any) => (i ? 1 : -1)),
    scan((acc, i: 1 | -1) => {
      acc = acc + i;
      return acc > -1 ? acc : 0;
    }, 0),
    map(Boolean)
  );
}
