import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { I18NextPipe } from 'angular-i18next';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';

@UntilDestroy()
@Component({
  selector: 'remote-connection-install-update-modal',
  templateUrl: './remote-connection-install-update-modal.component.html'
})
export class RemoteConnectionInstallUpdateModalComponent implements AfterViewInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public readonly alertType = AlertType;

  public isInstallMode: boolean;
  public computerName: string;
  public isMBSMode: boolean;

  public get title(): string {
    return this.getI18Text('connectTo', this.computerName);
  }

  public get okButton(): string {
    return this.getI18Text(this.isInstallMode ? 'installAndConnect' : 'updateAndConnect');
  }

  constructor(private i18n: I18NextPipe, private auth: AuthService, private cdr: ChangeDetectorRef) {
    this.isMBSMode = this.auth.isMBSMode;
  }

  ngAfterViewInit(): void {
    if (this.baseModal.data) {
      this.isInstallMode = this.baseModal.data.isInstallMode;
      this.computerName = this.baseModal.data.computerName;
      this.cdr.detectChanges();
    }
  }

  private getI18Text(key: string, param = '') {
    return this.i18n.transform(`computers.module:modals.${key}`, param ? { param } : {});
  }
}
