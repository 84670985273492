<mbs-modal [isCustomModal]="true">
  <ng-container modal-header>{{title}}</ng-container>
  <ng-container modal-body>
    <div class="mbs-wizard-legend">
      <mbs-checkbox [id]="'legend-check-1'"
                    [indeterminate]="false"
                    [checked]="false"
                    [label]="'wizards:legend_sub_exclude_label' | i18next"
                    [labelClasses]="'cursor-default'"
                    onclick="return false"></mbs-checkbox>
      <mbs-checkbox [id]="'legend-check-2'"
                    [indeterminate]="false"
                    [checked]="true"
                    [label]="'wizards:legend_sub_include_label' | i18next"
                    [labelClasses]="'cursor-default'"
                    onclick="return false"></mbs-checkbox>
      <mbs-checkbox *ngIf="!isRestore"
                    [id]="'legend-check-3'"
                    [indeterminate]="false"
                    [checked]="true"
                    [label]="'wizards:legend_selected_exclude_label' | i18next"
                    [labelClasses]="'cursor-default'"
                    onclick="return false"
                    [class.-checked]="true"
                    [class.-indeterminate]="true"></mbs-checkbox>
      <mbs-checkbox [id]="'legend-check-4'"
                    [indeterminate]="false"
                    [checked]="false"
                    [label]="'wizards:legend_selected_include_label' | i18next"
                    [labelClasses]="'cursor-default'"
                    onclick="return false"
                    [class.-indeterminate]="true"></mbs-checkbox>
    </div>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button data-dismiss="modal"
                type="secondary"
                (click)="close()">
      {{ 'buttons:close' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
