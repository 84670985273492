import { Pipe, PipeTransform } from '@angular/core';
import { PlanRunSimpleStatus } from '@models/backup/plan-run-simple-status';

// if to use parent path then get cyclic dependencies

@Pipe({
  name: 'planStatus'
})
export class PlanStatusPipe implements PipeTransform {
  transform(value: PlanRunSimpleStatus, mode: 'icon' | 'display' = 'icon'): any {
    if (mode === 'display') return this.getDisplayValue(value);
    if (mode === 'icon') return this.getIconValue(value);

    console.error('PlanStatusPipe: Unknown mode');
  }

  getIconValue(value: PlanRunSimpleStatus): string {
    switch (value) {
      case PlanRunSimpleStatus.Succeed:
        return 'text-success';
      case PlanRunSimpleStatus.Overdue:
      case PlanRunSimpleStatus.Failed:
        return 'text-danger';
      case PlanRunSimpleStatus.Warning:
        return 'text-warning';
      case PlanRunSimpleStatus.Unknown:
      case PlanRunSimpleStatus.Started:
      case PlanRunSimpleStatus.Running:
      default:
        return 'text-black';
    }
  }

  getDisplayValue(value: PlanRunSimpleStatus): string {
    switch (value) {
      case PlanRunSimpleStatus.Succeed:
        return 'Success';
      case PlanRunSimpleStatus.Failed:
        return 'Fail';
      case PlanRunSimpleStatus.Warning:
        return 'Warning';
      case PlanRunSimpleStatus.Started:
      case PlanRunSimpleStatus.Running:
        return 'Running';
      case PlanRunSimpleStatus.Overdue:
        return 'Overdue';
      case PlanRunSimpleStatus.Unknown:
      default:
        return 'Unknown';
    }
  }
}
