import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BuildsEffects } from '@store/builds/effects';
import { buildsReducer } from '@store/builds/reducer';
import { StoreTypes } from '../storeTypes.enum';

@NgModule({
  imports: [StoreModule.forFeature(StoreTypes.Builds, buildsReducer), EffectsModule.forFeature([BuildsEffects])]
})
export class BuildsStoreModule {}
