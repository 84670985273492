import { PlanTypes, PlanTypesDisplay } from '@models/PlanTypes.enum';
import { RecurType } from '@models/RecurType.enum';
import { ImmutabilityMode } from '@models/StorageAccount';
import { Destination, SAWithDestinations } from '@models/StorageAccountsWithDestinations';
import { StorageType } from '@models/StorageType.enum';
import { VolumeInfoCommunication } from '@modules/wizards/models/base/base-models/plan-volume-info-model';
import { GetDateFromString, mediumDateWithTimeMoment, TimeSpanToTimeGroup } from '@utils/date';
import { ScheduleDataHelper } from '@utils/schedule-data-helper';
import { IntMaxValue, MaxTicks, TimeSpanMaxValue } from 'mbs-ui-kit/utils/constants';
import * as moment from 'moment';
import { BackupAction } from '../models/base/base-models/plan-backup-actions-model';
import { Storages } from '../models/base/base-models/plan-storages-model';
import { BaseRetentionPolicy } from '../models/retention-policy-models';
import { ScheduleFormSettings } from '../models/schedule-advanced-models';
import { StorageConnection } from '@models/storge-connections';

export class SupportMethodsForStepsFromPlan {
  private static planTypesWithCompName = [PlanTypes.Plan, PlanTypes.DataBasePlan];
  public static generatePlanName(compName: string, planType: PlanTypes, offset: number): string {
    const compNamePart = compName && this.planTypesWithCompName.includes(planType) ? `of ${compName} ` : '';
    const provider = moment.utc().utcOffset(offset);
    const datePart = `on ${provider.format(mediumDateWithTimeMoment)}`;
    return `${PlanTypesDisplay[planType]} ${compNamePart}${datePart}`;
  }

  public static needUpdateExcludeRules(volume: VolumeInfoCommunication): boolean {
    return volume?.BackupOptions && !!volume.BackupOptions?.ExcludeRules?.length;
  }

  public static getExcludeRuleString(mountPoints: string[], WindowsVolumeIdentity: string, folder: string, needVolume = false): string {
    let excludeRule = '';
    if (needVolume && WindowsVolumeIdentity) excludeRule += 'Volume{' + WindowsVolumeIdentity + '}';
    else if (mountPoints.length) excludeRule += mountPoints[0].replace(/\\$/, '');
    else excludeRule += '\\\\?\\Volume{' + WindowsVolumeIdentity ? WindowsVolumeIdentity : '' + '}';
    excludeRule += folder;
    return excludeRule;
  }

  public static getExcludeRules(diskInfo: any[]): any[] {
    if (diskInfo.length) {
      const excludes: string[] = [];
      diskInfo.forEach((disk) => {
        if (disk.Volumes) {
          disk.Volumes.forEach((volume) => {
            if (this.needUpdateExcludeRules(volume)) {
              volume.BackupOptions.ExcludeRules.forEach((excl) => {
                const excludeRule = this.getExcludeRuleString(volume.MountPoints, volume.WindowsVolumeIdentity, excl.Folder);
                excludes.push(excludeRule);
              });
            }
          });
        }
      });
      return excludes;
    }
    return [];
  }

  public static getScheduleType(isPredefined, isSchedule, isOtherSchedule, RecurType: RecurType, isLinux = false): string {
    let scheduleType = 'noschedule';
    if (isSchedule) scheduleType = this.setScheduleTypeIfSchedule(isPredefined, RecurType, isLinux);
    else if (isOtherSchedule) scheduleType = !isLinux && isPredefined ? 'predefined' : 'recurring';
    return scheduleType;
  }

  public static setScheduleTypeIfSchedule(isPredefined: boolean, RecType: RecurType, isLinux = false): string {
    if (!isLinux) {
      return RecType === RecurType.Instantly
        ? 'instantly'
        : isPredefined || RecType === RecurType.Periodically
        ? 'predefined'
        : RecType === RecurType.Once
        ? 'once'
        : 'recurring';
    } else return RecType === RecurType.Once ? 'once' : 'recurring';
  }

  public static setSpecificDateGroup(
    isOnce: boolean,
    OnceDate: string = null,
    isUtc = false
  ): { date: Date | string; time: Date | string } {
    if (isOnce) {
      if (isUtc) {
        const point = moment.utc(OnceDate);
        return { date: point.toDate(), time: point.format('HH:mm:ss') };
      }
      const newDate = moment(OnceDate).toDate();
      return { date: newDate, time: moment(OnceDate).format('HH:mm:ss') };
    }
    const date = moment(new Date()).add(1, 'hours').set('minute', 0).toDate();
    return { date: date, time: moment().format('HH:mm:ss') };
  }

  public static getScheduleDiffOrTLog(schedule: any): ScheduleFormSettings {
    const isSchedule = !(schedule === null || schedule === undefined);
    const scheduleFormSettings = new ScheduleFormSettings();
    scheduleFormSettings.Enabled = isSchedule ? schedule.Enabled : null;
    scheduleFormSettings.FormSchedule = isSchedule ? ScheduleDataHelper.generateNewRecurringSchedule(schedule) : null;
    return scheduleFormSettings;
  }

  public static getStoragesFromStorageAccounts(Accounts: SAWithDestinations[]): Storages {
    const cloudStorages: StorageConnection[] = [];
    const localStorages: StorageConnection[] = [];
    Accounts.forEach((storageAccount: SAWithDestinations) => {
      if (storageAccount.storageType === StorageType.AzureVM) return;
      const storageTypeLocal = storageAccount.storageType === StorageType.LocalFS;
      storageAccount.buckets.forEach((bucket: Destination) => {
        const newStorage = {
          DisplayName: `${bucket.destinationName} (${bucket.bucketName})`,
          ID: bucket.id,
          StorageType: storageAccount.storageType,
          ImmutabilityMode: bucket.immutabilityMode,
          S3CompatibleSynthetic: storageAccount.S3CompatibleSynthetic,
          StorageAccountType: null,
          MinimumStorageDuration: storageAccount.MinimumStorageDuration
        };
        storageTypeLocal ? localStorages.push(newStorage) : cloudStorages.push(newStorage);
      });
    });
    return { CloudStorages: cloudStorages, LocalStorages: localStorages };
  }

  public static getFilteredStoragesByTypes(storages: Storages, types: StorageType[]): void {
    storages.CloudStorages = storages.CloudStorages.filter((s) => !types.some((t) => t === s.StorageType));
    storages.LocalStorages = storages.LocalStorages.filter((s) => !types.some((t) => t === s.StorageType));
  }

  public static getHybridRetentionPolicy(newPlan, isLinux = false, isIbb = false): BaseRetentionPolicy {
    const helpData = this.getRetentionPolicyHelpData(newPlan);
    const data: BaseRetentionPolicy = {
      RetentionUseDefaultSettings: !newPlan.RetentionUseDefaultSettings,
      keepNumberOfVersions: !helpData.equalMax,
      RetentionNumberOfVersions:
        helpData.equalMax || newPlan.RetentionNumberOfVersions === null || newPlan.RetentionNumberOfVersions === undefined
          ? 3
          : newPlan.RetentionNumberOfVersions,
      deleteVersionsOlderThan: helpData.isDeleteOlderThan,
      deleteVersionsOlderThanCount: helpData.timeGroup.value,
      deleteVersionsOlderThanPeriod: helpData.timeGroup.timePeriod
    };
    if (!isIbb) {
      data.alwaysKeepLastVersion =
        (helpData.isDeleteOlderThan && !newPlan.RetentionDeleteLastVersion) || !newPlan.RetentionUseDefaultSettings;
      data.fromTimeSelected = newPlan.UseBackupDate ? 1 : 0;
      if (!isLinux) {
        const RetDelay = newPlan.RetentionDelay && newPlan.RetentionDelay !== '00:00:00';
        const serialRetDelay = newPlan.SerializationSupportRetentionDelay && newPlan.SerializationSupportRetentionDelay !== '00:00:00';
        data.delayPurgeForEnabled = RetDelay || serialRetDelay;
        data.delayPurgeFor = helpData.delayGroup.value;
        data.delayPurgeForSelect = helpData.delayGroup.timePeriod;
      }
    }
    return data;
  }

  public static getRetentionPolicyHelpData(newPlan: any): any {
    const RetTime = newPlan.RetentionTime && newPlan.RetentionTime !== '00:00:00' && newPlan.RetentionTime !== TimeSpanMaxValue;
    const serialRetTime = newPlan.SerializationSupportRetentionTime && newPlan.SerializationSupportRetentionTime !== '00:00:00';
    const retentionTime = RetTime ? newPlan.RetentionTime : newPlan.SerializationSupportRetentionTime;
    const equalMaxTime = retentionTime === TimeSpanMaxValue || !!(retentionTime && retentionTime.Ticks && retentionTime.Ticks === MaxTicks);
    const timeGroup = !equalMaxTime && (RetTime || serialRetTime) ? TimeSpanToTimeGroup(retentionTime) : { value: 1, timePeriod: 2 };

    const RetDelay = newPlan.RetentionDelay && newPlan.RetentionDelay !== '00:00:00' && newPlan.RetentionDelay !== TimeSpanMaxValue;
    const serialRetDelay = newPlan.SerializationSupportRetentionDelay && newPlan.SerializationSupportRetentionDelay !== '00:00:00';
    const retentionDelay = RetDelay ? newPlan.RetentionDelay : newPlan.SerializationSupportRetentionDelay;
    const delayGroup = RetDelay || serialRetDelay ? TimeSpanToTimeGroup(retentionDelay) : { value: 3, timePeriod: 0 };
    const isDeleteOlderThan = !newPlan.RetentionUseDefaultSettings && !equalMaxTime;
    const equalMax =
      newPlan.RetentionNumberOfVersions === null ||
      newPlan.RetentionNumberOfVersions === undefined ||
      newPlan.RetentionNumberOfVersions === IntMaxValue;
    return { equalMax, equalMaxTime, timeGroup, isDeleteOlderThan, delayGroup };
  }

  public static getOnceDateFromSchedule(schedule): Date {
    return schedule && (schedule.Enabled || schedule.RecurType === RecurType.Once) ? GetDateFromString(schedule.OnceDate) : new Date();
  }

  public static updateCanBackupEmptyFolders(event: StorageType, planType: PlanTypes): boolean {
    if (planType === PlanTypes.Plan) {
      return event !== StorageType.Azure && event !== StorageType.BackblazeB2 && event !== StorageType.Minio;
    } else return true;
  }

  public static needShowImmutability(StorageConnections: StorageConnection[], id: string): boolean {
    return StorageConnections.some(
      (s) =>
        s.ID === id &&
        !(s.ImmutabilityMode === null || s.ImmutabilityMode === undefined) &&
        s.ImmutabilityMode !== ImmutabilityMode.Disabled
    );
  }

  public static getActionString(action: BackupAction): string {
    return action
      ? action.FullCommandLine
        ? action.FullCommandLine
        : !action.Arguments
        ? action.CommandLine
        : `${action.CommandLine}  ${action.Arguments}`
      : '';
  }
}
