import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProviderService } from '@services/provider.service';
import { TFAService } from '@shared/components/tfa/services/tfa.service';
import * as FileSaver from 'file-saver';
import { debounce } from 'lodash';
import { ModalComponent, ModalService, ToastService, WizardStep } from 'mbs-ui-kit';
import * as moment from 'moment';
import { Clipboard } from '@angular/cdk/clipboard';
import { catchError, of } from 'rxjs';

const COPY_ANIMATION_DURATION = 1500;

@UntilDestroy()
@Component({
  selector: 'mbs-enable-password-recovery-wizard-component',
  templateUrl: 'enable-password-recovery-wizard.component.html',
  styleUrls: ['./enable-password-recovery-wizard.component.scss']
})
export class EnablePasswordRecoveryWizardComponent implements OnInit {
  public publicKey: string;
  public pemKey: string;
  public privateKey: string;
  public isOverlayVisible = false;
  public isCopyButtonClicked = false;
  public confirmControl = new FormControl(false);
  public currentStep: WizardStep;
  public closable = false;
  public loading = false;
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private providerService: ProviderService,
    private toastService: ToastService,
    private clipboardService: Clipboard ,
    private modalService: ModalService,
    private TFAService: TFAService
  ) {}
  //
  async ngOnInit() {
    this.loading = true;
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-1'
      },
      true,
      ['encrypt', 'decrypt']
    );
    const buffer = await crypto.subtle.exportKey('pkcs8', keyPair.privateKey);
    this.privateKey = window.btoa(this.arrayBufferToString(buffer));
    this.publicKey = JSON.stringify(await crypto.subtle.exportKey('jwk', keyPair.publicKey));

    this.loading = false;
  }

  public handleCopyOnButton(): void {
    this.debouncedCopyPkToClipboard();
    this.isCopyButtonClicked = true;
    setTimeout(() => {
      this.isCopyButtonClicked = false;
    }, COPY_ANIMATION_DURATION);
  }

  public handleCopyOnText(): void {
    this.debouncedCopyPkToClipboard();
    this.isOverlayVisible = true;
    setTimeout(() => {
      this.isOverlayVisible = false;
    }, COPY_ANIMATION_DURATION);
  }

  private handleCopyPrivateKey = (): void => {
    const pem = this.formatAsPem(this.privateKey);
    this.clipboardService.copy(pem);
  };

  public debouncedCopyPkToClipboard = debounce(this.handleCopyPrivateKey, COPY_ANIMATION_DURATION) as () => void;

  handleDownloadPrivateKey(): void {
    const pem = this.formatAsPem(this.privateKey);
    const file = new Blob([pem], { type: 'text/plain' });
    FileSaver.saveAs(file, `password-recovery-key-${moment().format('MMM-DD-YYYY_hh-mm-a')}.txt`.toLowerCase());
  }

  handleEnable(): void {
    this.providerService
      .setPasswordRecoveryState(true, this.publicKey ? JSON.parse(this.publicKey) : false)
      .pipe(catchError((error) => of(error)))
      .subscribe((result) => {
        if (result?.error?.authId) {
          this.showTFAModal(result.error);
        } else {
          this.baseModal.save();
        }
      });
  }

  showTFAModal(data) {
    this.TFAService.openApproveModal({ title: 'Enable Password Recovery', ...data }).then(() => {
      this.handleEnable();
    });
  }

  handleClose(): void {
    this.baseModal.save();
  }

  arrayBufferToString(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary;
  }

  formatAsPem(str): string {
    let finalString = '-----BEGIN PRIVATE KEY-----\n';

    while (str.length > 0) {
      finalString += str.substring(0, 64) + '\n';
      str = str.substring(64);
    }

    finalString = finalString + '-----END PRIVATE KEY-----';

    return finalString;
  }
}
