import { ScheduleSettings } from '../schedule-advanced-models';
import { BackupTargetVolumesEnum } from '../what-backup-models';
import { BaseBackupPlan } from './base-backup-plan-model';
import { DiskInfoCommunication } from './base-models/plan-disk-info-model';

export class BackupDiskImagePlan extends BaseBackupPlan {
  constructor() {
    super();
  }
  public CopyBufferSize: number;
  public BackupVolumes: BackupTargetVolumesEnum;
  public IsPlanCanBeRunnedWithoutLicense: boolean;
  public IsSyntheticSupported: boolean;
  public UsePersistentVss: boolean;
  public VSSProviderID: string;
  public IgnoreReadingBeyondTheEndOfTheDisk: boolean;
  public IgnoreBadSectors: boolean;
  public BlockSize: number;
  public PrefetchBlockCount: number;
  public DisableDASDIO: boolean;
  public DisableVSS: boolean;
  public DiskInfo: DiskInfoCommunication[] = [];
  public ForceFullDiffSizeCondition: number;
  public ForceFullApplyDiffSizeCondition: boolean;
  public ForceFullSchedule: ScheduleSettings;
  public UseFullSchedule: boolean;
  public UseDifferentialUpload: boolean;
  public ExcludeEnabled: boolean;

  static Guid_DirectAccessVSS = 'b5946137-7b9f-4925-af80-51abd60b20d5';
}
