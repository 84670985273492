import { Component, forwardRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScheduleDataHelper } from '@utils/schedule-data-helper';
import { stopAfterMinutesValidator } from '@utils/validators';
import { I18NextPipe } from 'angular-i18next';
import { MbsValidators, ModalService, ShortDateParserFormatter } from 'mbs-ui-kit';
import { duration } from 'moment';
import { debounceTime } from 'rxjs/operators';
import { SchedulePredefinedTemplate, SimpleScheduleStepValue, TemplatesActionParams } from '../../models/simple-schedule-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { FormPipeOperators, StepBase } from '../StepBase.class';

const I18FullShortScheduleKey = 'wizards:predefined_name_full_short_text';
const I18CustomScheduleKey = 'wizards:predefined_name_custom_text';

const ParamsForFullAction = {
  full: { state: true, hours: 24, minutes: 0 },
  block: { state: false, hours: 0, minutes: 0 },
  diff: { state: false, hours: 0, minutes: 0 },
  tLog: { state: false, hours: 0, minutes: 0 }
};

const SimpleScheduleStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SimpleScheduleStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-simple-schedule-step',
  templateUrl: './simple-schedule-step.component.html',
  providers: [SimpleScheduleStepValueAccessor, { provide: NgbDateParserFormatter, useClass: ShortDateParserFormatter }]
})
export class SimpleScheduleStepComponent extends StepBase<SimpleScheduleStepValue> implements OnInit {
  @Input() copyOnly: boolean;

  public readonly elementSelector = {
    name: {
      fullBackupEveryHoursNumber: 'full-backup-every-hours-number',
      blockBackupEveryHoursNumber: 'block-level-backup-every-hours-number',
      diffBackupEveryHoursNumber: 'differential-backup-every-hours-number',
      tLogBackupEveryHoursNumber: 'tLog-backup-every-hours-number',
      fullBackupEveryMinutesNumber: 'full-backup-every-minutes-number',
      blockBackupEveryMinutesNumber: 'block-level-backup-every-minutes-number',
      diffBackupEveryMinutesNumber: 'differential-backup-every-minutes-number',
      tLogBackupEveryMinutesNumber: 'tLog-backup-every-minutes-number',
      notSQLBlock: 'not-sql-block',
      sqlDiffBlock: 'sql-diff-block',
      sqlTLogBlock: 'sql-t-log-block',
      totalShowBlock: 'total-show-block',
      totalFullText: 'total-full-text',
      totalBlockText: 'total-block-text',
      totalDiffText: 'total-diff-text',
      totalTLogText: 'total-t-log-text'
    }
  };
  public CurrentScheduleTemplates: SchedulePredefinedTemplate[];

  private ScheduleTemplates: SchedulePredefinedTemplate[] = [
    {
      Name: this.isBackupPlan
        ? this.i18nPipe.transform('wizards:predefined_name_incremental_week_day_text')
        : this.i18nPipe.transform('wizards:predefined_name_full_week_day_text'),
      FullBackupEnabled: true,
      FullBackupEveryDuration: duration(168, 'hours'),
      BlockLevelBackupEnabled: true,
      BlockLevelBackupEveryDuration: duration(24, 'hours'),
      DifferentialBackupEnabled: false,
      DifferentialBackupEveryDuration: null,
      TLogEnabled: false,
      TLogBackupEveryDuration: null,
      templateAction: () => {
        this.templatesAction({
          full: { state: true, hours: 168, minutes: 0 },
          block: { state: true, hours: 24, minutes: 0 },
          diff: { state: false, hours: 0, minutes: 0 },
          tLog: { state: false, hours: 0, minutes: 0 }
        });
      }
    },
    {
      Name: this.isBackupPlan
        ? this.i18nPipe.transform('wizards:predefined_name_incremental_day_hour_text')
        : this.i18nPipe.transform('wizards:predefined_name_full_day_hour_text'),
      FullBackupEnabled: true,
      FullBackupEveryDuration: duration(24, 'hours'),
      BlockLevelBackupEnabled: true,
      BlockLevelBackupEveryDuration: duration(1, 'hours'),
      DifferentialBackupEnabled: false,
      DifferentialBackupEveryDuration: null,
      TLogEnabled: false,
      TLogBackupEveryDuration: null,
      templateAction: () => {
        this.templatesAction({
          full: { state: true, hours: 24, minutes: 0 },
          block: { state: true, hours: 1, minutes: 0 },
          diff: { state: false, hours: 0, minutes: 0 },
          tLog: { state: false, hours: 0, minutes: 0 }
        });
      }
    },
    {
      Name: this.isBackupPlan
        ? this.i18nPipe.transform('wizards:predefined_name_incremental_short_text')
        : this.i18nPipe.transform(I18FullShortScheduleKey),
      FullBackupEnabled: true,
      FullBackupEveryDuration: duration(24, 'hours'),
      BlockLevelBackupEnabled: false,
      BlockLevelBackupEveryDuration: duration(0, 'hours'),
      DifferentialBackupEnabled: false,
      DifferentialBackupEveryDuration: null,
      TLogEnabled: false,
      TLogBackupEveryDuration: null,
      templateAction: () => this.templatesAction(ParamsForFullAction)
    },
    {
      Name: this.i18nPipe.transform(I18CustomScheduleKey),
      FullBackupEnabled: true,
      FullBackupEveryDuration: null,
      BlockLevelBackupEnabled: true,
      BlockLevelBackupEveryDuration: null,
      DifferentialBackupEnabled: true,
      DifferentialBackupEveryDuration: null,
      TLogEnabled: true,
      TLogBackupEveryDuration: null
    }
  ];

  private BackupDatabaseScheduleTemplates: SchedulePredefinedTemplate[] = [
    {
      Name: this.i18nPipe.transform('wizards:predefined_name_full_short_week_day_text'),
      FullBackupEveryDuration: duration(168, 'hours'),
      FullBackupEnabled: true,
      BlockLevelBackupEveryDuration: null,
      BlockLevelBackupEnabled: false,
      DifferentialBackupEveryDuration: duration(24, 'hours'),
      DifferentialBackupEnabled: true,
      TLogBackupEveryDuration: duration(0, 'hours'),
      TLogEnabled: false,
      templateAction: () => {
        this.templatesAction({
          full: { state: true, hours: 168, minutes: 0 },
          block: { state: false, hours: 0, minutes: 0 },
          diff: { state: true, hours: 24, minutes: 0 },
          tLog: { state: false, hours: 0, minutes: 0 }
        });
      }
    },
    {
      Name: this.i18nPipe.transform('wizards:predefined_name_full_short_day_hour_text'),
      FullBackupEnabled: true,
      FullBackupEveryDuration: duration(24, 'hours'),
      BlockLevelBackupEveryDuration: null,
      BlockLevelBackupEnabled: false,
      DifferentialBackupEnabled: true,
      DifferentialBackupEveryDuration: duration(1, 'hours'),
      TLogEnabled: false,
      TLogBackupEveryDuration: duration(0, 'hours'),
      templateAction: () => {
        this.templatesAction({
          full: { state: true, hours: 24, minutes: 0 },
          block: { state: false, hours: 0, minutes: 0 },
          diff: { state: true, hours: 1, minutes: 0 },
          tLog: { state: false, hours: 0, minutes: 0 }
        });
      }
    },
    {
      Name: this.i18nPipe.transform(I18FullShortScheduleKey),
      FullBackupEnabled: true,
      FullBackupEveryDuration: duration(24, 'hours'),
      BlockLevelBackupEnabled: false,
      BlockLevelBackupEveryDuration: null,
      DifferentialBackupEnabled: false,
      DifferentialBackupEveryDuration: duration(0, 'hours'),
      TLogEnabled: false,
      TLogBackupEveryDuration: duration(0, 'hours'),
      templateAction: () => this.templatesAction(ParamsForFullAction)
    },
    {
      Name: this.i18nPipe.transform('wizards:predefined_name_full_for_hour_text'),
      FullBackupEnabled: true,
      FullBackupEveryDuration: duration(24, 'hours'),
      BlockLevelBackupEveryDuration: null,
      BlockLevelBackupEnabled: false,
      DifferentialBackupEnabled: true,
      DifferentialBackupEveryDuration: duration(4, 'hours'),
      TLogEnabled: true,
      TLogBackupEveryDuration: duration(1, 'hours'),
      templateAction: () => {
        this.templatesAction({
          full: { state: true, hours: 24, minutes: 0 },
          block: { state: false, hours: 0, minutes: 0 },
          diff: { state: true, hours: 4, minutes: 0 },
          tLog: { state: true, hours: 1, minutes: 0 }
        });
      }
    },
    {
      Name: this.i18nPipe.transform('wizards:predefined_name_full_one_hour_text'),
      FullBackupEnabled: true,
      FullBackupEveryDuration: duration(24, 'hours'),
      BlockLevelBackupEveryDuration: null,
      BlockLevelBackupEnabled: false,
      DifferentialBackupEnabled: true,
      DifferentialBackupEveryDuration: duration(1, 'hours'),
      TLogEnabled: true,
      TLogBackupEveryDuration: duration(15, 'minute'),
      templateAction: () => {
        this.templatesAction({
          full: { state: true, hours: 24, minutes: 0 },
          block: { state: false, hours: 0, minutes: 0 },
          diff: { state: true, hours: 1, minutes: 0 },
          tLog: { state: true, hours: 0, minutes: 15 }
        });
      }
    },
    {
      Name: this.i18nPipe.transform(I18CustomScheduleKey),
      FullBackupEnabled: true,
      FullBackupEveryDuration: null,
      BlockLevelBackupEnabled: true,
      BlockLevelBackupEveryDuration: null,
      DifferentialBackupEnabled: true,
      DifferentialBackupEveryDuration: null,
      TLogEnabled: true,
      TLogBackupEveryDuration: null
    }
  ];

  private selectedTemplates: { [key: string]: SchedulePredefinedTemplate } = {
    ['168:0-24:0-null:null-null:null']: this.ScheduleTemplates[0],
    ['24:0-1:0-null:null-null:null']: this.ScheduleTemplates[1],
    ['24:0-null:null-null:null-null:null']: this.ScheduleTemplates[2]
  };

  private selectedSQLTemplates: { [key: string]: SchedulePredefinedTemplate } = {
    ['168:0-null:null-24:0-null:null']: this.BackupDatabaseScheduleTemplates[0],
    ['24:0-null:null-1:0-null:null']: this.BackupDatabaseScheduleTemplates[1],
    ['24:0-null:null-null:null-null:null']: this.BackupDatabaseScheduleTemplates[2],
    ['24:0-null:null-4:0-1:0']: this.BackupDatabaseScheduleTemplates[3],
    ['24:0-null:null-1:0-0:15']: this.BackupDatabaseScheduleTemplates[4]
  };

  private cashPreviousStateControls: { [key: string]: boolean } = {};

  get fullScheduleLabel(): string {
    return this.isBackupPlan
      ? this.i18nPipe.transform('wizards:incremental_every_label')
      : this.i18nPipe.transform('wizards:full_every_label');
  }

  get blockScheduleLabel(): string {
    return this.isBackupPlan
      ? this.i18nPipe.transform('wizards:block_every_label')
      : this.i18nPipe.transform('wizards:block_incremental_every_label');
  }

  get totalShow(): boolean {
    return (
      this.stepForm.get('fullScheduleEnabled').value ||
      this.stepForm.get('blockLevelBackup').value ||
      this.stepForm.get('scheduleDiffEnabled').value ||
      this.stepForm.get('scheduleTLogEnabled').value
    );
  }

  get fullBackupTime(): string {
    const hours = +this.stepForm.get('fullBackupEveryHours').value;
    const minutes = +this.stepForm.get('fullBackupEveryMinutes').value;

    return ScheduleDataHelper.backupTimeString(hours, minutes);
  }

  get blockLevelBackupTime(): string {
    const hours = +this.stepForm.get('blockLevelBackupEveryHours').value;
    const minutes = +this.stepForm.get('blockLevelBackupEveryMinutes').value;

    return ScheduleDataHelper.backupTimeString(hours, minutes);
  }

  get differentialBackupTime(): string {
    const hours = +this.stepForm.get('differentialBackupEveryHours').value;
    const minutes = +this.stepForm.get('differentialBackupEveryMinutes').value;

    return ScheduleDataHelper.backupTimeString(hours, minutes);
  }

  get logBackupTime(): string {
    const hours = +this.stepForm.get('tLogBackupEveryHours').value;
    const minutes = +this.stepForm.get('tLogBackupEveryMinutes').value;

    return ScheduleDataHelper.backupTimeString(hours, minutes);
  }

  constructor(public mainService: RemoteManagementWizardsService, public modalService: ModalService, public i18nPipe: I18NextPipe) {
    super(mainService);
  }

  ngOnInit(): void {
    if (this.isSQLPlan) {
      this.CurrentScheduleTemplates = this.BackupDatabaseScheduleTemplates
    }

    this.initForm();
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      startFromDate: new FormControl('', MbsValidators.dateValidator.bind(this)),
      startFromTime: new FormControl('00:00', MbsValidators.timeValidatorWithoutText),
      scheduleTemplate: new FormControl(''),
      fullScheduleEnabled: new FormControl(false, Validators.requiredTrue),
      fullBackupEveryHours: new FormControl(0),
      fullBackupEveryMinutes: new FormControl(0, stopAfterMinutesValidator.bind(this, 'fullBackupEveryHours')),
      blockLevelBackup: new FormControl(false),
      blockLevelBackupEveryHours: new FormControl(0),
      blockLevelBackupEveryMinutes: new FormControl(0, stopAfterMinutesValidator.bind(this, 'blockLevelBackupEveryHours')),
      scheduleDiffEnabled: new FormControl(false),
      differentialBackupEveryHours: new FormControl(0),
      differentialBackupEveryMinutes: new FormControl(0, stopAfterMinutesValidator.bind(this, 'differentialBackupEveryHours')),
      scheduleTLogEnabled: new FormControl(false),
      tLogBackupEveryHours: new FormControl(0),
      tLogBackupEveryMinutes: new FormControl(0, stopAfterMinutesValidator.bind(this, 'tLogBackupEveryHours'))
    });

    this.setScheduleTemplatesAndSubscribeToTemplates();
    this.initFormEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.copyOnly && this.isSQLPlan && this.stepForm) {
      this.setScheduleTemplates();
      this.toggleFormControls(['scheduleDiffEnabled'], !changes.copyOnly.currentValue);
      this.toggleFormControlsValue(['scheduleDiffEnabled'], !changes.copyOnly.currentValue);
      this.scheduleDiffEnabledChangeHandler(!changes.copyOnly.currentValue && this.stepForm.get('scheduleDiffEnabled').value);
      this.setScheduleTemplatesFromValue(this.stepForm.value);
    }
  }

  setScheduleTemplatesAndSubscribeToTemplates(): void {
    this.setScheduleTemplates();
    this.stepForm
      .get('scheduleTemplate')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        const template = this.CurrentScheduleTemplates.find((tmpl) => tmpl.Name === value);

        if (template?.templateAction) template.templateAction();

        this.forceMinutesValidators();
      });
  }

  forceValid(): void {
    this.stepForm.markAllAsTouched();
    this.forceMinutesValidators();

    if (this.stepForm) {
      const value = this.stepForm.getRawValue();

      for (const key in value) {
        if (!Object.prototype.hasOwnProperty.call(value, key)) continue;

        const ctrl = this.stepForm.get(key) as FormControl;

        if (key !== 'valid' && ctrl.valid && !ctrl.disabled) ctrl.reset(value[key], { emitEvent: key !== 'scheduleTemplate' });
      }
    }
  }

  updateForm(value: SimpleScheduleStepValue): void {
    this.stepForm.reset(value);
    this.setScheduleTemplatesFromValue(value);

    this.fullScheduleEnabledChangeHandler(value.fullScheduleEnabled);
    this.blockLevelBackupChangeHandler(value.blockLevelBackup);
    this.scheduleDiffEnabledChangeHandler(value.scheduleDiffEnabled);
    this.scheduleTLogEnabledChangeHandler(value.scheduleTLogEnabled);

    if (this.copyOnly && this.isSQLPlan && this.stepForm) {
      this.setScheduleTemplates();
      this.toggleFormControls(['scheduleDiffEnabled', 'differentialBackupEveryHours', 'differentialBackupEveryMinutes'], false);
      this.toggleFormControlsValue(['scheduleDiffEnabled'], false);
      this.setScheduleTemplatesFromValue(this.stepForm.value);
    }
  }

  setScheduleTemplates(): void {
    if (this.isSQLPlan) {
      return;
    }

    this.CurrentScheduleTemplates = this.ScheduleTemplates;
  }

  setScheduleTemplatesFromValue(value: SimpleScheduleStepValue): void {
    if (this.isSQLPlan) {
      return void this.setScheduleTemplatesFromValueForSQL(value);
    }

    this.setScheduleTemplatesFromValueForNotSQL(value);
  }

  everyHoursChangeHandler(event, fieldName: string): void {
    if (typeof event === 'number' && !isNaN(event)) {
      if (event === 168 || event === 24 || event === 4 || event === 1 || (fieldName === 'tLogBackupEveryMinutes' && event === 0)) {
        this.setScheduleTemplatesFromValue(this.stepForm.value);
      } else this.stepForm.get('scheduleTemplate').setValue('Custom');

      this.stepForm.get(fieldName).updateValueAndValidity();
    }
  }

  minutesChangeHandler(event, needCheckTemplate = false): void {
    if (!event || needCheckTemplate) return void this.setScheduleTemplatesFromValue(this.stepForm.value);

    this.stepForm.get('scheduleTemplate').setValue('Custom');
  }

  fullScheduleEnabledChangeHandler(event, fromUi = false): void {
    if (this.isSQLPlan) {
      this.toggleFormControls(
        ['fullBackupEveryHours', 'fullBackupEveryMinutes', 'blockLevelBackup', 'scheduleTLogEnabled'].concat(
          this.copyOnly ? [] : ['scheduleDiffEnabled']
        ),
        event
      );

      if (fromUi) {
        this.toggleFormControlsValue(
          event && this.copyOnly ? ['scheduleTLogEnabled'] : ['scheduleDiffEnabled', 'scheduleTLogEnabled'],
          event
        );
      }

      this.blockLevelBackupChangeHandler(event);
      this.scheduleDiffEnabledChangeHandler(event);
      this.scheduleTLogEnabledChangeHandler(event);

      if (fromUi) this.setScheduleTemplatesFromValue(this.stepForm.value);

      return;
    }

    this.toggleFormControls(['fullBackupEveryHours', 'fullBackupEveryMinutes', 'blockLevelBackup'], event);

    this.blockLevelBackupChangeHandler(event);
  }

  toggleFormControlsValue(controlNames: string[], state: boolean): void {
    if (state) {
      controlNames.forEach((name: string) => this.stepForm.get(name).setValue(!!this.cashPreviousStateControls[name]));
      this.cashPreviousStateControls = {};

      return;
    }

    controlNames.forEach((name: string) => {
      this.cashPreviousStateControls[name] = this.stepForm.get(name).value;
      this.stepForm.get(name).setValue(false);
    });
  }

  blockLevelBackupChangeHandler(event, fromUi = false): void {
    this.toggleFormControls(
      ['blockLevelBackupEveryHours', 'blockLevelBackupEveryMinutes'],
      event && (fromUi || this.stepForm.get('blockLevelBackup').value)
    );

    if (fromUi) this.setScheduleTemplatesFromValue(this.stepForm.value);
  }

  scheduleDiffEnabledChangeHandler(event, fromUi = false): void {
    this.toggleFormControls(
      ['differentialBackupEveryHours', 'differentialBackupEveryMinutes'],
      event && (fromUi || this.stepForm.get('scheduleDiffEnabled').value)
    );

    if (fromUi) this.setScheduleTemplatesFromValue(this.stepForm.value);
  }

  scheduleTLogEnabledChangeHandler(event, fromUi = false): void {
    this.toggleFormControls(
      ['tLogBackupEveryHours', 'tLogBackupEveryMinutes'],
      event && (fromUi || this.stepForm.get('scheduleTLogEnabled').value)
    );

    if (fromUi) this.setScheduleTemplatesFromValue(this.stepForm.value);
  }

  private setScheduleTemplatesFromValueForSQL(value: SimpleScheduleStepValue): void {
    const fullHours = value.fullScheduleEnabled ? value.fullBackupEveryHours : null;
    const fullMinutes = value.fullScheduleEnabled ? value.fullBackupEveryMinutes : null;

    const diffHours = value.scheduleDiffEnabled ? value.differentialBackupEveryHours : null;
    const diffMinutes = value.scheduleDiffEnabled ? value.differentialBackupEveryMinutes : null;

    const tLogHours = value.scheduleTLogEnabled ? value.tLogBackupEveryHours : null;
    const tLogMinutes = value.scheduleTLogEnabled ? value.tLogBackupEveryMinutes : null;

    const key = `${fullHours}:${fullMinutes}-null:null-${diffHours}:${diffMinutes}-${tLogHours}:${tLogMinutes}`;

    const sqlTemplate = this.selectedSQLTemplates[key] || this.BackupDatabaseScheduleTemplates[5];
    this.stepForm.get('scheduleTemplate').setValue(sqlTemplate.Name);
  }

  private setScheduleTemplatesFromValueForNotSQL(value: SimpleScheduleStepValue): void {
    const fullHours = value.fullScheduleEnabled ? value.fullBackupEveryHours : null;
    const fullMinutes = value.fullScheduleEnabled ? value.fullBackupEveryMinutes : null;

    const blockHours = value.blockLevelBackup ? value.blockLevelBackupEveryHours : null;
    const blockMinutes = value.blockLevelBackup ? value.blockLevelBackupEveryMinutes : null;

    const key = `${fullHours}:${fullMinutes}-${blockHours}:${blockMinutes}-null:null-null:null`;

    const sqlTemplate = this.selectedTemplates[key] || this.ScheduleTemplates[3];
    this.stepForm.get('scheduleTemplate').setValue(sqlTemplate.Name);
  }

  private templatesAction = (params: TemplatesActionParams) => {
    this.stepForm.get('fullScheduleEnabled').patchValue(params.full.state);
    this.fullScheduleEnabledChangeHandler(params.full.state);
    this.stepForm.get('fullBackupEveryHours').patchValue(params.full.hours);
    this.stepForm.get('fullBackupEveryMinutes').patchValue(params.full.minutes);

    this.stepForm.get('blockLevelBackup').patchValue(params.block.state);
    this.blockLevelBackupChangeHandler(params.block.state);
    this.stepForm.get('blockLevelBackupEveryHours').patchValue(params.block.hours);
    this.stepForm.get('blockLevelBackupEveryMinutes').patchValue(params.block.minutes);

    this.stepForm.get('scheduleTLogEnabled').patchValue(params.tLog.state);
    this.scheduleTLogEnabledChangeHandler(params.tLog.state);
    this.stepForm.get('tLogBackupEveryHours').patchValue(params.tLog.hours);
    this.stepForm.get('tLogBackupEveryMinutes').patchValue(params.tLog.minutes);

    if (this.copyOnly) {
      return;
    }

    this.stepForm.get('scheduleDiffEnabled').patchValue(params.diff.state);
    this.scheduleDiffEnabledChangeHandler(this.isSQLPlan && this.copyOnly ? false : params.diff.state);
    this.stepForm.get('differentialBackupEveryHours').patchValue(params.diff.hours);
    this.stepForm.get('differentialBackupEveryMinutes').patchValue(params.diff.minutes);
  };

  private forceMinutesValidators(): void {
    ['fullBackupEveryMinutes', 'blockLevelBackupEveryMinutes', 'differentialBackupEveryMinutes', 'tLogBackupEveryMinutes'].forEach(
      (field) => {
        this.stepForm.get(field).markAsDirty();
        this.stepForm.get(field).updateValueAndValidity();
      }
    );
  }
}
