<mbs-wizard [(ngModel)]="currentStep"
            [buttonLoadingMode]="true"
            [canClosed]="!loadingPlan && wizardFinish"
            [isOpenSteps]="!loadingPlan && isEdit"
            [loading]="loadingPlan"
            [stepsOrientation]="orientations"
            [stepQueue]="stepQueue"
            [stepsContainerClass]="mainService.isOffline ? 'overflow-hidden' : ''"
            (save)="handleSave()"
            [title]="wizardTitle + ' ' +mainService.compName">
  <div *ngIf="mainService.isOffline"
       prependStep
       class="pl-4 py-2 background-secondary">
    <span class="fa fa-power-off"></span> {{ prependStepText }}
  </div>

  <mbs-wizard-step [title]="'wizards:welcome' | i18next: { format: 'title' }"
                   [valid]="FormModel?.planNameStep?.valid"
                   (next)="planNameStep?.forceValid()">
    <mbs-plan-name-step #planNameStep
                        [(ngModel)]="FormModel.planNameStep"
                        (restoreOnce)="restoreOnceHandler($event)"
                        [planTypeText]="planTypeText"></mbs-plan-name-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:where_backup_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.whereBackupStep?.valid"
                   (next)="whereBackupStep?.forceValid()">
    <mbs-where-backup-step #whereBackupStep
                           [(ngModel)]="FormModel.whereBackupStep"
                           (changeStorage)="changeStorageHandler($event)"
                           [Storages]="FormModel?.Storages"></mbs-where-backup-step>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="'wizards:computers_to_restore_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.computerToRestoreStep?.valid"
                   (next)="computerToRestore?.forceValid()">
    <mbs-select-computer-step #computerToRestore
                              [storageId]="FormModel?.whereBackupStep?.ConnectionID"
                              (loadInfo)="loadInfoHandler($event)"
                              [(ngModel)]="FormModel.computerToRestoreStep"></mbs-select-computer-step>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="'wizards:backupToRestoreTitle' | i18next: { format: 'title' }"
                   [valid]="FormModel?.backupToRestoreStep?.valid"
                   (next)="backupToRestore?.forceValid()">
    <mbs-backup-to-restore-step #backupToRestore
                                [storageId]="FormModel?.whereBackupStep?.ConnectionID"
                                [computerName]="FormModel?.computerToRestoreStep?.computerName"
                                (loadInfo)="loadInfoHandler($event)"
                                [(ngModel)]="FormModel.backupToRestoreStep"></mbs-backup-to-restore-step>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="restorePointTitle"
                   [valid]="FormModel?.restorePointStep?.valid"
                   (next)="restorePointStep?.forceValid(currentStep)">
    <mbs-restore-point-step #restorePointStep
                            (loadInfo)="loadInfoHandler($event)"
                            (nextStep)="forceNextStepWizard($event, 'restorePointStep', restorePointTitle)"
                            [bunchId]="FormModel?.backupToRestoreStep?.selectedBunch"
                            [computerName]="FormModel?.computerToRestoreStep?.computerName"
                            [storageId]="FormModel?.whereBackupStep?.ConnectionID"
                            [(ngModel)]="FormModel.restorePointStep"></mbs-restore-point-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:type_data_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.typeDataStep?.valid"
                   (next)="typeDataStep?.forceValid()">
    <mbs-type-data-step #typeDataStep
                        (restoreAsChange)="restoreAsChangeHandler($event)"
                        [(ngModel)]="FormModel.typeDataStep"
                        [fileFormats]="FormModel.fileFormats"></mbs-type-data-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="partitionsTitle"
                   [valid]="FormModel?.partitionsStep?.valid"
                   (next)="partitionsStep?.forceValid()">
    <mbs-partitions-step #partitionsStep
                         (loadInfo)="loadInfoHandler($event)"
                         (nextStep)="forceNextStepWizard($event, 'partitionsStep', partitionsTitle)"
                         [bunchId]="FormModel?.backupToRestoreStep?.selectedBunch"
                         [computerName]="FormModel?.computerToRestoreStep?.computerName"
                         [resultPoint]="FormModel?.restorePointStep?.resultPoint"
                         [selectedFileFormat]="FormModel?.typeDataStep?.selectedFileFormat"
                         [storageId]="FormModel?.whereBackupStep?.ConnectionID"
                         [(ngModel)]="FormModel.partitionsStep"></mbs-partitions-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="isEdit ? FormModel?.partitionsStep?.restorePartitions?.length : true"
                   [title]="'wizards:dest_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.destinationStep?.valid"
                   (next)="destinationStep?.forceValid()">
    <mbs-destination-step #destinationStep
                          [(ngModel)]="FormModel.destinationStep"
                          [diskInfo]="FormModel?.diskItems"
                          [isCurrentComputer]="FormModel?.computerToRestoreStep?.isCurrent"
                          [isVirtual]="FormModel?.typeDataStep?.restoreAs === restoreAsDiskEnum.Virtual"
                          [partitions]="FormModel.partitions"
                          [restorePartitions]="FormModel?.partitionsStep?.restorePartitions"></mbs-destination-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:encryption_options_step_title' | i18next: { format: 'title' }"
                   *ngIf='!isNBF || FormModel?.restorePointStep?.resultPoint?.IsEncrypted || FormModel?.restorePointStep?.resultPoint?.isEncrypted'
                   [valid]="FormModel?.encryptionOptionsStep?.valid"
                   (next)="encryptionOptionsStep?.forceValid()">
    <mbs-encryption-options-step #encryptionOptionsStep
                                 (loadInfo)="loadInfoHandler($event)"
                                 [backupToRestore]="FormModel?.backupToRestoreStep?.selectedBunch"
                                 [resultPoint]="FormModel?.restorePointStep?.resultPoint"
                                 [(ngModel)]="FormModel.encryptionOptionsStep"></mbs-encryption-options-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="FormModel.planNameStep.SavePlanInCloud"
                   [title]="scheduleTitle"
                   [valid]="FormModel?.scheduleStep?.valid"
                   (next)="scheduleStep?.forceValid(currentStep)">
    <mbs-schedule-step #scheduleStep
                       [(ngModel)]="FormModel.scheduleStep"
                       [stepFocused]="currentStep?.title === scheduleTitle"
                       [invalidFFIStorage]="invalidFFIStorage"
                       [isGFSMode]="isGFSMode"
                       [retentionTitle]="retentionTitle"
                       (nextStep)="forceNextStepWizard($event, 'scheduleStep', scheduleTitle)"
                       (registerRequiredStep)="stepQueue.registerRequiredStep($event)"
                       (unregisterRequiredStep)="stepQueue.unregisterRequiredStep($event)"
                       (changeType)="scheduleTypeChangeHandler($event)"></mbs-schedule-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="FormModel?.scheduleStep?.ScheduleType === 'recurring'"
                   [title]="'wizards:advanced_schedule_restore_title' | i18next: { format: 'title' }"
                   [valid]="FormModel.scheduleAdvancedStep.valid"
                   (next)="scheduleAdvancedStep?.forceValid()">
    <mbs-schedule-advanced-step #scheduleAdvancedStep
                                [(ngModel)]="FormModel.scheduleAdvancedStep"></mbs-schedule-advanced-step>
  </mbs-wizard-step>
  <mbs-wizard-step *ngIf="mainService.allowPrePostActions | async"
                   [title]="(mainService.allowPrePostActions | async) ? ('wizards:pre_post_title' | i18next: { format: 'title' }) :
                            ('wizards:pre_post_title_backup_chain' | i18next: { format: 'title' })"
                   [valid]="FormModel?.prePostActionsStep?.valid"
                   (next)="prePostActionsStep?.forceValid()">
    <mbs-pre-post-actions-step #prePostActionsStep
                               [(ngModel)]="FormModel.prePostActionsStep"
                               [ChainPlans]="FormModel?.AvailableMachinePlans"></mbs-pre-post-actions-step>
  </mbs-wizard-step>
  <mbs-wizard-step [title]="'wizards:notification_step_title' | i18next: { format: 'title' }"
                   [valid]="FormModel?.notificationStep?.valid">
    <mbs-notifications-step [(ngModel)]="FormModel.notificationStep"
                            (emailsNotificationChange)="emailsNotificationChangeHandler($event)"
                            [notification]="mainService.planNotification"></mbs-notifications-step>
  </mbs-wizard-step>
</mbs-wizard>
