import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { StorageClass, StorageType, TypesForFFI, TypesForSynthetic } from '@models/StorageType.enum';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { AzureAccessTierType } from '@modules/wizards/models/advanced-options-models';
import { AdvancedSettingsVirtualStepValue, VMQuiesceType } from '@modules/wizards/models/advanced-settings-vm-models';
import { StorageConnection } from '@models/storge-connections';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { WindowsFeatures, WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { EnumHelper, FormsUtil, ModalService, ModalSettings, WizardStep } from 'mbs-ui-kit';

const AdvancedSettingsVMStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => AdvancedSettingsVirtualStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-advanced-settings-virtual-step',
  templateUrl: './advanced-settings-virtual-step.component.html',
  providers: [AdvancedSettingsVMStepValueAccessor]
})
export class AdvancedSettingsVirtualStepComponent extends StepBase<AdvancedSettingsVirtualStepValue> implements OnInit {
  @Input() selectedStorage: StorageConnection;
  @Output() changeStorageType = new EventEmitter<{ azureType?: AzureAccessTierType; isStorageBadForFFI: boolean }>();

  public isCBTAvailable = true;
  public storageType = StorageType;
  public vmQuiesceType = VMQuiesceType;
  public StorageClasses = StepsHelpers.getStorageClasses();
  public AccessTires = EnumHelper.EnumToSelectIndexesArray(AzureAccessTierType);
  public advancedSettingsInfoAlertText = this.i18nextService.t('wizards:advanced_settings_info_alert_text', { returnObjects: true });
  public isShowSyntheticFull = false;
  public elementSelectors = {
    id: {
      VMWareFormGroup: 'vmware-form-group',
      quiesceTypeRadio1: 'quiesce-type-radio-1',
      quiesceTypeRadio2: 'quiesce-type-radio-2',
      quiesceTypeRadio3: 'quiesce-type-radio-3',
      CBTCheckbox: 'advanced_settings_block_tracking_checkbox'
    },
    name: {
      CBTCheckDoxDisableTooltip: 'advanced_settings_block_tracking_checkbox_disable_tooltip'
    },
    css: {
      appAwareInfoPanel: 'app-aware-info-panel'
    }
  };

  private verifiedStorageClassOrAzure = false;

  syntheticFullValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && !this.verifiedStorageClassOrAzure) {
      const storageClass = this.stepForm.get('storageClass')?.value;

      if (TypesForFFI.unsupported.Amazon.includes(storageClass) || TypesForFFI.unsupported.Azure.includes(storageClass)) {
        return { invalidClass: true };
      }
    }
    return null;
  }

  @ViewChild('errorSyntheticFull', { static: true, read: TemplateRef }) errorSyntheticFull: TemplateRef<any>;

  constructor(
    public ability: AbilityService,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    public mainService: RemoteManagementWizardsService,
    public modalService: ModalService,
    public stepService: WizardStepsService
  ) {
    super(mainService);
    const version = mainService?.backupVersionUpdated?.substring(0, 3);

    if (this.isRDMode || (version && (+version >= 731 || (this.isLinux && +version >= 331)))) {
      StepsHelpers.updateStorageClasses(this.StorageClasses);
    }
  }

  ngOnInit(): void {
    this.initForm();

    if (this.isHyperV) {
      this.checkCBT();
    }
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      quiesceType: new FormControl(VMQuiesceType.TryApplicationConsistent),
      useChangeBlockTracking: new FormControl(false),
      useS3Acceleration: new FormControl(false),
      storageClass: new FormControl(1),
      azureAccessTier: new FormControl(0),
      syntheticFull: new FormControl(false, this.syntheticFullValidator.bind(this))
    });

    this.initFormEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm && changes?.selectedStorage) {
      this.changeDisabledStateControls();
      this.changeSyntheticFullControlsStatus();
      const selectedStorage: StorageConnection =
        (changes.selectedStorage.currentValue as unknown as StorageConnection) || this.selectedStorage;

      if (selectedStorage?.StorageType === StorageType.Azure) {
        this.azureAccessTierChangeHandler(this.stepForm.get('azureAccessTier').value);
      }

      if (selectedStorage?.StorageType === StorageType.AmazonS3) {
        this.storageClassChangeHandler(this.stepForm.get('storageClass').value);
      }
    }

    if (!this.confirmedAsEnabled && changes?.stepFocused?.currentValue) {
      this.showEnableStepModal(this.modalService);
    }
  }

  updateForm(value: AdvancedSettingsVirtualStepValue): void {
    if (this.isRDMode) {
      StepsHelpers.updateStorageClasses(this.StorageClasses);
    }

    this.stepForm.reset(value);
  }

  forceValid(step: WizardStep & { isSave?: boolean; isNext?: boolean } = null): void {
    if (step.isNext && this.stepForm.get('syntheticFull').invalid) {
      this.showSyntheticFullModal(step.isNext);
    } else {
      FormsUtil.triggerValidation(this.stepForm);
      this.resetValidStateForValidFields();
    }
  }

  changeSyntheticFullControlsStatus(): void {
    this.isShowSyntheticFull = !this.value.syntheticFull && TypesForSynthetic.isSupported(this.selectedStorage);
  }

  showSyntheticFullModal(needNextStep: boolean): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:error_synthetic_title', { format: 'title' }) },
      footer: {
        okButton: { text: this.i18nPipe.transform('buttons:yes', { format: 'title' }), type: 'success' },
        cancelButton: { text: this.i18nPipe.transform('buttons:no', { format: 'title' }) }
      }
    };
    this.modalService
      .open(modalSettings, this.errorSyntheticFull)
      .then((confirm) => {
        if (confirm) {
          this.isShowSyntheticFull = true;
          this.verifiedStorageClassOrAzure = true;
          this.stepForm.get('syntheticFull').setValue(false);
          this.stepForm.updateValueAndValidity();
          if (needNextStep) this.nextStep.emit();
        }
      })
      .catch(() => this.stepForm.updateValueAndValidity());
  }

  storageClassChangeHandler(event: StorageClass): void {
    this.verifiedStorageClassOrAzure = false;
    this.stepForm.get('syntheticFull').updateValueAndValidity();
    this.changeStorageType.emit({
      isStorageBadForFFI: StepsHelpers.isStorageBadForFFI(event, 'Amazon')
    });
  }

  azureAccessTierChangeHandler(event: AzureAccessTierType): void {
    this.verifiedStorageClassOrAzure = false;
    this.stepForm.get('syntheticFull').updateValueAndValidity();
    this.changeStorageType.emit({
      azureType: event,
      isStorageBadForFFI: StepsHelpers.isStorageBadForFFI(event, 'Azure')
    });
  }

  syntheticFullNeedValidate(): void {
    this.verifiedStorageClassOrAzure = false;
    this.stepForm.get('syntheticFull').updateValueAndValidity();
  }

  changeDisabledStateControls(): void {
    if (this.selectedStorage?.StorageType === StorageType.AmazonS3) {
      this.stepForm.get('useS3Acceleration').enable();
      this.stepForm.get('storageClass').enable();
    } else {
      this.stepForm.get('useS3Acceleration').disable();
      this.stepForm.get('storageClass').disable();
    }

    if (this.selectedStorage?.StorageType === StorageType.Azure) {
      this.stepForm.get('azureAccessTier').enable();
    } else {
      this.stepForm.get('azureAccessTier').disable();
    }
  }

  private checkCBT(): void {
    this.stepService
      .VMFeatureTest(this.mainService.hid, WindowsFeatures.hyperVChangedBlockTracking)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.isCBTAvailable = !!res?.data;
        this.toggleFormControls(['useChangeBlockTracking'], this.isCBTAvailable);

        if (!this.isCBTAvailable) {
          this.stepForm.get('useChangeBlockTracking').setValue(false);
        }
      });
  }
}
