<section [formGroup]="stepForm">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:what_backup_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <mbs-form-group class="mb-4">
    <div class="mbs-form_label">
      {{ 'wizards:partitions_subtitle' | i18next }}
      <span class="mbs-form_label-required">*</span>
    </div>
    <mbs-list-select id="partition-step-table"
                     listType="table"
                     [bindSelected]="bindSelected"
                     [bindDisabledValues]="disabledRowBy"
                     [invalid]="needValidate && stepForm.touched && !value?.valid"
                     [keepState]="true"
                     [maxHeight]="'200px'"
                     [headers]="headers"
                     [isNeedSelectRow]="false"
                     [loadSource]="false"
                     [showCheckboxes]="true"
                     [showHeaders]="true"
                     [showSearch]="false"
                     [selectedData]="selectedItems"
                     [data]="partitions"
                     (changeSelected)="itemsCheckedHandler($event)">
      <ng-template mbsTableCell
                   let-disk>
        <mbs-text-ellipsis [tooltip]="disk.diskNumber || '-'">
          {{disk.diskNumber || '-'}}
        </mbs-text-ellipsis>
      </ng-template>
      <ng-template mbsTableCell
                   let-disk>
        {{disk.mountPointsInfo}}
        <ng-container
                *ngIf="isEdit && (disk[disabledRowBy.key] === disabledRowBy.value || disk[disabledRowBy.key] === +VolumeUsageStateEnum.New)">
            <span class="ico ico-InfoCircle ml-2 cursor-help text-info font-weight-bold"
                  container="body"
                  [ngbTooltip]="disk[disabledRowBy.key] === disabledRowBy.value ? ('wizards:partitions_volume_missing_text' | i18next) : 'wizards:partitions_volume_detected_text' | i18next"></span>
        </ng-container>
      </ng-template>
      <ng-template mbsTableCell
                   let-disk>
        {{disk.lengthString}}
      </ng-template>
      <ng-template mbsTableCell
                   let-disk>
        <mbs-button *ngIf="disk.fileSystemType === fileSystemTypeEnum[fileSystemTypeEnum.NTFS]; else onlyUsed"
                    [isCtrl]="true"
                    [customClasses]="getUsedSpaceStringClass(disk)"
                    type="primary"
                    (click)="usedSpaceClickHandler(disk)">
          {{disk.usedSpaceString}}
          <span class="fa fa-folder-open ml-1"></span>
        </mbs-button>
        <ng-template #onlyUsed>
          {{disk.usedSpaceString}}
        </ng-template>
      </ng-template>
      <ng-template mbsTableCell
                   let-disk>
        <mbs-button *ngIf="disk.fileSystemType === fileSystemTypeEnum[fileSystemTypeEnum.NTFS]; else onlySize"
                    [isCtrl]="true"
                    type="primary"
                    (click)="targetSizeClickHandler(disk, targetSizeModal)">
          {{getDiskSizeFromMB( disk.targetSize ) || 0}}
          <span class="fa fa-pencil ml-1"></span>
        </mbs-button>
        <ng-template #onlySize>
          {{getDiskSizeFromMB( disk.targetSize ) || 0}}
        </ng-template>
      </ng-template>
      <ng-template mbsTableCell
                   let-disk>
        <mbs-text-ellipsis [tooltip]="disk.label">
          {{disk.label || "&mdash;"}}
        </mbs-text-ellipsis>
      </ng-template>
      <ng-template mbsTableCell
                   let-disk>
        <mbs-text-ellipsis [tooltip]="disk.fileSystemType">
          {{disk.fileSystemType}}
        </mbs-text-ellipsis>
      </ng-template>
      <ng-template mbsTableCell
                   let-disk>
        {{getSectorSize( disk.sectorSize )}}
      </ng-template>
    </mbs-list-select>
  </mbs-form-group>

  <mbs-checkbox formControlName="convertToMBR"
                [label]="'wizards:partitions_convert_gpt_label' | i18next"
                [id]="'restore-convertGPTToBios-checkbox'">
    <ng-container appendLabel>
      <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
            [ngbTooltip]="'wizards:partitions_tooltip_text' | i18next"></span>
    </ng-container>
  </mbs-checkbox>
</section>


<ng-template #targetSizeModal>
  <div class="mbs-form_content"
       [formGroup]="sizeForm">
    <mbs-form-group>
      {{'wizards:dest_minimum_label' | i18next}} {{sizeForm.get( 'min' ).value}} {{sizeForm.get( 'sizeFormat' ).value}}
    </mbs-form-group>
    <mbs-form-group>
      {{'wizards:dest_maximum_label' | i18next}} {{sizeForm.get( 'max' ).value}} {{sizeForm.get( 'sizeFormat' ).value}}
    </mbs-form-group>
    <mbs-form-group>
      <div class="form-row align-items-center">
        <div class="col-auto">
          <label [for]="'size-number'"
                 class="mbs-form_label mb-0">
            {{'wizards:partitions_size_label' | i18next}}
          </label>
        </div>
        <div class="col-3">
          <mbs-number formControlName="size"
                      (focus)="capacityFocusHandler()"
                      (blur)="capacityBlurHandler()"
                      (change)="sizeChangeHandler($event)"
                      [min]="numberIsFocused ? 0 : sizeForm.get('min').value || 0.02"
                      [max]="sizeForm.get('max').value || 65536.00"
                      [id]="'size-number'"></mbs-number>
        </div>
        <div class="col-3">
          <mbs-select formControlName="sizeFormat"
                      (change)="sizeFormatChangeHandler($event)"
                      [items]="sizeFormats"
                      [searchable]="false"
                      [clearable]="false"></mbs-select>
        </div>
      </div>
    </mbs-form-group>

    <mbs-alert class="mb-0 mt-3"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:partitions_size_modal_alert_text' | i18next}}
    </mbs-alert>
  </div>
</ng-template>

<ng-template #systemRequiredModalContent>
  <p>{{'wizards:system_required_modal_text' | i18next}}</p>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{'wizards:system_required_modal_alert_text' | i18next}}
  </mbs-alert>
</ng-template>
