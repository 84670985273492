import { PlanTypes, PlanTypesDisplay } from '@models/PlanTypes.enum';

export class PlansUtil {
  public static getIcon(type: PlanTypes): string {
    switch (+type) {
      case PlanTypes.NA:
        return 'ico ico-question-circle';
      case PlanTypes.Plan:
      case PlanTypes.RestorePlan:
        return 'ico ico-file';
      case PlanTypes.BackupDiskImagePlan:
      case PlanTypes.RestoreDiskImagePlan:
        return 'ico ico-imagebased';
      case PlanTypes.DataBasePlan:
      case PlanTypes.RestoreDataBasePlan:
        return 'ico ico-databased';
      case PlanTypes.ExchangePlan:
      case PlanTypes.RestoreExchangePlan:
        return 'ico ico-exchange';
      case PlanTypes.BackupVMware:
      case PlanTypes.RestoreVMware:
        return 'ico ico-vmware';
      case PlanTypes.BackupHyperV:
      case PlanTypes.RestoreHyperV:
        return 'ico ico-hyperv';
      case PlanTypes.ConsistencyCheck:
        return 'ico ico-consistencycheck';
      default:
        return 'ico ico-file';
    }
  }

  public static getName(type: PlanTypes): string {
    return PlanTypesDisplay[type];
  }
}
