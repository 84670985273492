import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@mbs-ui/environments/environment.prod';
import Administrator from '@models/Administrator';
import { AudiencePermissionScope } from '@models/JwtToken';
import RmmCommand from '@models/rmm/RmmCommand';
import { RmmCommandType } from '@models/rmm/RmmCommandType';
import RmmHubResponse from '@models/rmm/RmmHubResponse';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import { RmmWebsocketService } from '@services/rmm-websocket.service';
import { RmmService } from '@services/rmm.service';
import { generateUid } from '@utils/generateUid';
import { I18NextPipe } from 'angular-i18next';
import { isUndefined } from 'lodash';
import { ErrorsEnum, ModalService, ResponseError, ToastService } from 'mbs-ui-kit';
import { from, Observable, of, ReplaySubject, switchMap, take, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, pluck } from 'rxjs/operators';
import { StatType } from '../../../shared/services/rmm.service';
import { CommandApiPrefix } from './consts';

const COMMAND_BASE_URL = 'api/Computers/rm';

export interface AuthorizedFactoryParams {
  commandType: RmmCommandType | string;
  command: RmmCommand<unknown>;
  commandBody?: any;
  hid: string;
  isActive?: boolean;
  strict?: boolean;
  container?: string;
}

export type AuthorizedFactory<T> = (params: AuthorizedFactoryParams) => Observable<T>;

@Injectable({
  providedIn: 'root'
})
export class CommandService {
  /*
   * Observe replay 30 last messages in 10 sec time window,
   * if WSS send response before API resolve command sending
   */
  public messages$: ReplaySubject<RmmHubResponse<unknown>> = new ReplaySubject(30, 10000);
  public tokenExpired: EventEmitter<any> = new EventEmitter();
  public canGoNext: EventEmitter<boolean> = new EventEmitter();

  private serverUrl = '';
  private user: Administrator;
  private mbsStageKey: string;

  constructor(
    private http: HttpClient,
    public rmmService: RmmService,
    public modalService: ModalService,
    private rmmHub: RmmWebsocketService, // prettier
    private toast: ToastService,
    private auth: AuthService,
    private config: ConfigurationService,
    private i18n: I18NextPipe
  ) {
    if (environment.production) {
      this.serverUrl = this.config.get('rmmBaseHref');
    }

    this.mbsStageKey = config.get('mbsStageKey');
    this.auth.currentUser.subscribe((user) => (this.user = user));
    this.rmmHub.messages$.pipe().subscribe((message) => {
      this.messageLogger(message);
      this.messages$.next(message);
    });
  }
  /**
   * Select messages from rmmHub by asyncId
   *
   * @param {string} asyncID
   * @return {Observable<RmmHubResponse<TData>>}
   */
  public selectMessagesByAsyncId$(asyncID: string) {
    return this.messages$.pipe(filter((message) => message.MessageId === asyncID));
  }
  /**
   * Select message data from rmmHub by asyncId
   *
   * @param {string} asyncID
   * @return {Observable<RmmHubResponse<TData>>}
   */
  public selectDataByAsyncId$(asyncID: string) {
    return this.selectMessagesByAsyncId$(asyncID).pipe(pluck('Data'));
  }
  /**
   * request static commands by http chanel
   *
   * @template TData
   * @param {StatType} statName
   * @param {string} hid
   * @param {any} [params={}]
   * @return {Observable<TData>}
   */
  public getAgentStat = <TData>(statName: StatType, hid: string): Observable<TData> => {
    const url = this.rmmService.statBaseUrl + '/rm/' + hid + '/stat/' + statName;
    const httpParams: HttpParams = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    const request$ = this.http.get(url, {
      params: httpParams,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      headers: { 'Content-Type': 'application/json' }
    });

    return request$.pipe(map(this.parseData));
  };

  /**
   * request static commands by http chanel
   *
   * @template TData
   * @param {string} commandType
   * @param {string} hid
   * @param {any} [body={}]
   * @return {Observable<TData>}
   */
  public sendAgentCommand = <TData>(commandType: string, hid: string, body?): Observable<TData> => {
    const url = this.rmmService.statBaseUrl + '/rm/' + hid + '/commands/' + commandType;
    const params: HttpParams = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    const request$ = isUndefined(body)
      ? this.http.get(url, {
          params,
          headers: { 'Content-Type': 'application/json' }
        })
      : this.http.post(url, body, {
          params,
          headers: { 'Content-Type': 'application/json' }
        });

    return request$.pipe(map(this.parseData));
  };

  public sendAgentCommandAsync<TResult>(commandType: string, command: RmmCommand<TResult>, hid: string): Observable<unknown> {
    return this.initSocketMessage(this.asyncAgentRequest)({ commandType, command, hid });
  }
  /**
   * Method to execution sync RmmCommand, with emulate response from websocket.
   * Message pushed to rmmHub stack manually.
   *
   * @param { RmmCommandType } commandType type of command
   * @param { RmmCommand } command current command
   * @param { string } hid computer hid
   * @param { boolean } isActive active state
   * @return { Observable } observable execution command state
   */
  public sendCommand<TResult>(
    commandType: RmmCommandType,
    command: RmmCommand<TResult>,
    hid: string,
    isActive: boolean
  ): Observable<unknown> {
    const asyncID = command.asyncID;
    command.asyncID = null;

    const promise = new Promise((resolve, reject) => {
      this.authorizedRequest({ commandType, command, hid, isActive })
        .pipe(
          catchError((err) => throwError(() => err)),
          take(1)
        )
        .subscribe({
          next: (response: any) => {
            if (response.code) {
              reject(response);
            } else {
              /**
               * Detect command data from command for 1.4 agents and 1.4+
               */
              this.rmmHub.invokeMessage(this.parseData(response), asyncID);
              resolve(response);
            }
          },
          error: (err) => reject(err)
        });
    });

    return from(promise).pipe(catchError((error) => this.generateError(error.code, error.title)));
  }
  /**
   * Method to execution async RmmCommand, response in websocket
   * Message pushed to rmmHub automatically.
   * Command ignore authorize for current PC on execution, 2fa only for user
   *
   * @param { RmmCommandType } commandType type of command
   * @param { RmmCommand } command current command
   * @param { string } hid computer hid
   * @param { boolean } isActive active state
   * @return { Observable } observable execution command state
   */
  public sendCommandAsync<TResult>(commandType: RmmCommandType, command: RmmCommand<TResult>, hid: string, isActive: boolean) {
    return this.initSocketMessage(this.authorizedRequest)({ commandType, command, hid, isActive });
  }
  /**
   * Method to execution async RmmCommand, response in websocket
   * Message pushed to rmmHub automatically.
   * Command execute only for authorized with 2fa computer by hid
   *
   * @param { RmmCommandType } commandType type of command
   * @param { RmmCommand } command current command
   * @param { string } hid computer hid
   * @param { boolean } isActive active state
   * @return { Observable } observable execution command state
   */
  public sendStrictCommandAsync<TResult>(commandType: RmmCommandType, command: RmmCommand<TResult>, hid: string, isActive: boolean) {
    return this.initSocketMessage(this.strictRequest)({ commandType, command, hid, isActive });
  }

  initSocketMessage =
    <TResult>(factory: AuthorizedFactory<TResult>) =>
    (factoryParams: AuthorizedFactoryParams) => {
      if (!factoryParams.command.asyncID) {
        factoryParams.command.asyncID = generateUid();
      }

      return from(this.rmmHub.init()).pipe(
        concatMap((status) => {
          this.rmmHub.subscribe<string>(factoryParams.command.asyncID);

          return status ? factory(factoryParams) : this.generateError<TResult>(503, this.i18n.transform('error:rmm.ws'));
        }),
        catchError((error) => {
          return of(error);
        })
      );
    };

  authorizedRequest = ({ commandType, command, hid, isActive }: AuthorizedFactoryParams): Observable<unknown> => {
    let url = '';

    if (commandType === 'PowerShellTerminalCmd') {
      url = this.generateUrl(hid, commandType, CommandApiPrefix.PowerShellTerminalCmd);
    } else {
      const prefix = isActive ? CommandApiPrefix.InvokeActiveCommand : CommandApiPrefix.invokeCommand;
      url = this.generateUrl(hid, commandType, prefix);
    }

    const request$ = this.http.post<unknown>(url, command.toString());

    if (this.canAccess(!!isActive)) {
      return request$.pipe(catchError((err) => throwError(() => err)));
    } else {
      return this.handleExpired(request$, !!isActive);
    }
  };

  strictRequest = ({ commandType, command, hid }: AuthorizedFactoryParams): Observable<unknown> => {
    const prefix = CommandApiPrefix[commandType];
    const url = this.generateUrl(hid, commandType, prefix);

    const request$ = this.http.post<any>(url, command.toString());

    if (this.canHidAccess(hid)) {
      return request$;
    } else {
      return this.handleExpired(request$, true);
    }
  };

  asyncAgentRequest = ({ commandType, command, hid }: AuthorizedFactoryParams): Observable<unknown> => {
    const httpParams: HttpParams = new HttpParams({
      fromObject: {
        mbsStageKey: this.mbsStageKey,
        channelId: command.asyncID
      }
    });

    const url = `${this.serverUrl}/${COMMAND_BASE_URL}/${hid}/commands/async/${commandType}`;

    return this.http.post<any>(url, command.body, {
      params: httpParams
    });
  };

  private handleExpired(request$: Observable<any>, strict = false) {
    if (strict) {
      this.tokenExpired.emit(true);
    }

    return this.canGoNext.pipe(
      take(1),
      switchMap((isConfirmed: boolean) => (isConfirmed ? request$ : this.generateError<any>(403, this.i18n.transform('error:rmm.access'))))
    );
  }

  private generateUrl(hid, commandType, prefix) {
    return `${this.serverUrl}/${COMMAND_BASE_URL}/${hid}/commands/${prefix}?commandName=${commandType}`;
  }

  generateError = <TResult>(errorCode, message): Observable<TResult> =>
    of({
      status: errorCode,
      error: {
        code: errorCode || ErrorsEnum.RmmAccessDenied,
        title: message,
        knownType: errorCode
      } as ResponseError
    } as any);

  public canAccess(isActive: boolean): boolean {
    if (!isActive) {
      return true;
    }
    const token = this.auth.decodedToken;
    return token.hasScope(AudiencePermissionScope.MBS_RM_ACCESS) && !token.isExpired();
  }

  // Returns true, if 2FA complete
  public canHidAccess(hid: string): boolean {
    const token = this.auth.decodedToken;
    return token.hasScope(AudiencePermissionScope.MBS_RM_ACCESS) && !token.isExpired() && token.hid.includes(hid);
  }

  public parseMessage = (message) => {
    let data = this.isJSONString(message?.Data) ? JSON.parse(message.Data) : message.Data;

    if (this.isJSONString(data)) {
      data = JSON.parse(data);
    }

    return {
      ...message,
      Data: data
    };
  };

  private isJSONString(data: string) {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }

    return true;
  }

  private parseData = (response) => {
    if (!response) {
      return {};
    }
    if (Array.isArray(response)) {
      return response;
    }

    return response.data || (response.result ? JSON.parse(response.result).data : null);
  };

  private messageLogger(message) {
    // ONLY FOR DEV & STAGE
    if (this.config.get('mbsStageKey')) {
      const { Data } = this.parseMessage(message);

      console.group(`%cRmmHub :: MessageID [%c${message.MessageId}%c]`, 'color: green', null, 'color: green');
      console.log(Data);
      console.groupEnd();
    }
  }

  // Utility
  getAsyncIdFromResponse(response): string {
    if (response?.result) {
      const obj = JSON.parse(response?.result);

      const unixId = obj?.data?.command?.asyncID;
      if (unixId) return unixId;

      const splitter = ' = ';
      const asyncId = obj?.data?.split(splitter)[1];

      return asyncId ?? null;
    }

    return null;
  }

  getMessageByAsyncId(response) {
    const asyncId = this.getAsyncIdFromResponse(response);
    return asyncId ? this.getResponseData(this.selectMessagesByAsyncId$(asyncId)) : this.getResponseData(this.messages$);
  }

  private getResponseData(stream$: Observable<any>) {
    return stream$.pipe(map((response: any) => response?.Data && typeof response?.Data === 'string' && JSON.parse(response?.Data)));
  }
}
