import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@components/login/login.component';
import { NotFoundWrapperComponent } from '@components/not-found-wrapper/not-found-wrapper.component';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';
import { CurrentUserGuard } from '../../shared/guards/currentUser.guard';
import { NotFoundOnlineAccessComponent } from './components/not-found/not-found.component';
import { OnlineAccessSidepanelWrapperComponent } from './components/online-access-sidepanel-wrapper/online-access-sidepanel-wrapper.component';
import { OnlineAccessLayoutComponent } from './online-access-layout/online-access-layout.component';
import { OnlineAccessComponent } from './online-access.component';

const routes: Routes = [
  {
    path: 'OnlineAccess/WebConnect',
    canActivate: [CurrentUserGuard],
    canActivateChild: [CurrentUserGuard],
    data: {
      i18nextNamespaces: ['webrtc', 'error']
    },
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    },
    loadChildren: () => import('@domains/webrtc-connect/webrtc-connect.module').then((m) => m.WebrtcConnectModule)
  },
  {
    path: 'OnlineAccess',
    canActivate: [CurrentUserGuard],
    canActivateChild: [CurrentUserGuard],
    data: {
      i18nextNamespaces: ['online-access.module', 'computers.module']
    },
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    },
    component: OnlineAccessLayoutComponent,
    children: [
      {
        path: '',
        component: OnlineAccessComponent,
        children: [
          {
            path: ':hid',
            component: OnlineAccessSidepanelWrapperComponent
          }
        ]
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'NotFound',
    component: NotFoundOnlineAccessComponent,
    data: {
      i18nextNamespaces: ['error']
    }
  },
  // otherwise redirect to home
  { path: '**', component: NotFoundWrapperComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CurrentUserGuard]
})
export class OnlineAccessRoutingModule {}
