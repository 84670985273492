import { Injectable } from '@angular/core';
import { getPagingParams, getSortParams, PagingParams } from '@models/Paging';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ComputersAbstractService } from '@services/computers.service';
import { ComputersFiltersAbstractWrapper } from '@wrappers/computer.filters.wrapper';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { ComputersStoreActions, ComputersStoreSelectors } from '.';

@Injectable()
export class ComputersEffects {
  loadComputer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStoreActions.loadComputerByHid),
      concatLatestFrom((action) => this.store.select(ComputersStoreSelectors.selectByHid(action.hid))),
      filter(([action, computer]) => !!action.hid && (!computer || action.force)),
      mergeMap(([action]) => {
        return this.computersService.getComputerByHid(action.hid).pipe(
          map((result) => ComputersStoreActions.setComputers({ computers: [result] })),
          catchError(() => of(ComputersStoreActions.loadComputerError()))
        );
      })
    );
  });

  setSelectedComputer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStoreActions.setSelected),
      concatLatestFrom(() => this.store.select(ComputersStoreSelectors.selectSelected)),
      filter(([action, selected]) => action.selected && action.selected !== selected),
      concatLatestFrom(([action]) => this.store.select(ComputersStoreSelectors.selectByHid(action.selected))),
      switchMap(([[action], computer]) => {
        return computer
          ? of(ComputersStoreActions.setSelectedSuccess({ selected: action.selected }))
          : this.computersService.getComputerByHid(action.selected).pipe(
              map((result) => ComputersStoreActions.setSelectedSuccess({ computer: result })),
              catchError(() => of(ComputersStoreActions.loadComputerError()))
            );
      })
    );
  });

  loadApplicationsCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStoreActions.loadApplicationsCount),
      concatLatestFrom(() => this.store.select(ComputersStoreSelectors.selectApplicationsCountInitiated)),
      filter(([action, initiated]) => !initiated || action.force),
      mergeMap(() => {
        return this.computersService.getComputersApplicationsCount().pipe(
          map((result) => ComputersStoreActions.setApplicationsCount({ count: result })),
          catchError(() => of(ComputersStoreActions.setApplicationsCount({ count: {} })))
        );
      })
    );
  });

  loadPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStoreActions.loadPage),
      concatLatestFrom(() => this.store.select(ComputersStoreSelectors.selectPreQueryPagingParams)),
      filter(
        ([, { requestParams, pageNumbers }]) => !pageNumbers.some((pageIndex) => pageIndex === requestParams.paging.currentPageNumber)
      ),
      map(([action, params]) => ({
        ...params.requestParams,
        paging: {
          ...params.requestParams.paging,
          currentPageNumber: action.pageNumber ?? params.requestParams.paging.currentPageNumber
        } as PagingParams
      })),
      switchMap(({ paging, sorting, filters }) => {
        return this.filtersWrapper.getComputersHttpParams(filters, getSortParams(sorting), getPagingParams(paging)).pipe(
          switchMap((params) => this.computersService.getComputers(params)),
          map((data) =>
            ComputersStoreActions.loadPageSuccess({
              computers: data.data,
              pageNumber: paging.currentPageNumber,
              total: data.total
            })
          ),
          catchError(() => of(ComputersStoreActions.loadPageError()))
        );
      })
    );
  });

  setPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStoreActions.setPageNumber),
      switchMap(({ pageNumber }) => of(ComputersStoreActions.loadPage({ pageNumber })))
    );
  });

  setPerPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStoreActions.setPerPageSetting),
      switchMap(({ perPage }) => of(ComputersStoreActions.loadPage({ perPage })))
    );
  });

  loadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStoreActions.loadData),
      concatLatestFrom(() => this.store.select(ComputersStoreSelectors.selectPreQueryDataParams)),
      filter(([action, { dataCount, allLoaded }]) => (action.allowLoadMore || !dataCount) && !allLoaded),
      mergeMap(([action, { dataCount }]) =>
        this.computersService.getComputers({ offset: dataCount, ...(action.limit > 0 ? { limit: action.limit } : {}) }).pipe(
          map((result) =>
            ComputersStoreActions.loadDataSuccess({
              computers: result.data,
              total: result.total
            })
          ),
          catchError(() => of(ComputersStoreActions.loadDataError()))
        )
      )
    );
  });

  constructor(
    public store: Store,
    public actions$: Actions,
    private filtersWrapper: ComputersFiltersAbstractWrapper,
    private computersService: ComputersAbstractService
  ) {}
}
