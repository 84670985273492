import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';

@Component({
  templateUrl: './immutability-confirm-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmutabilityConfirmModalComponent implements OnInit {
  public isStorageWizard: boolean;
  public confirmed = false;
  public readonly MbsPopupType = MbsPopupType;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  ngOnInit(): void {
    this.isStorageWizard = this.baseModal.data?.isStorageWizard;
  }

  confirmClickHandler(): void {
    if (this.confirmed) this.baseModal.save({ confirm: true });
    this.confirmed = false;
  }

  cancelClickHandler(): void {
    this.baseModal.close();
    this.confirmed = false;
  }
}
