import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { SidepanelRouteType } from '@models/backup/sidepanel-route-type';
import { filter, first } from 'rxjs/operators';
import { OnlineAccessSidepanelHandler } from '../../shared/online-access-sidepanel-handler.service';

@Component({
  template: ''
})
export class OnlineAccessSidepanelWrapperComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private onlineAccessSidepanelHandler: OnlineAccessSidepanelHandler,
    private facade: ComputersFacade
  ) {}

  ngOnInit(): void {
    const computerHid = this.route.snapshot.params.hid;
    const sidepanelType: SidepanelRouteType = (this.route.snapshot.queryParams.sidepanel || '').toLowerCase();

    if (sidepanelType === SidepanelRouteType.Backup) {
      this.handleBackup(computerHid);
    } else {
      this.onlineAccessSidepanelHandler.updateSlug(null);
    }
  }

  handleBackup(hid: string): void {
    this.facade.currentComputer$
      .pipe(
        filter((computer) => !!computer && computer.hid === hid),
        first()
      )
      .subscribe((computer) => {
        if (computer && !this.onlineAccessSidepanelHandler.handleOpenBackupPanel(computer)) {
          this.onlineAccessSidepanelHandler.updateSlug(null);
        }
      });
    this.facade.setSelected(hid);
  }
}
