import { DayOfWeek } from '@models/DayOfWeek.enum';
import { StorageConnection } from '@models/storge-connections';
import { WeekNumber } from '@models/WeekNumber.enum';
import { SelectVirtualDisksStepValue, VirtualDisksSelectedType } from '@modules/wizards/models/select-virtual-disks-models';
import { SelectVirtualMachinesStepValue, VirtualMachinesSelectedType } from '@modules/wizards/models/select-virtual-machines-models';
import { GuidEmpty } from 'mbs-ui-kit/utils/constants';
import { ArchiveConsistencyStepValue } from '../archive-consistency-step-value';
import { CompressionEncryptionStepValue } from '../compression-encription-models';
import { NotificationsStepValue } from '../notifications-models';
import { PlanNameStepValue } from '../plan-name-models';
import { PrePostActionsStepValue } from '../pre-post-actions-models';
import { RetentionPolicyStepValue } from '../retention-policy-models';
import { ScheduleStepValue } from '../schedule-models';
import { WhereBackupStepValue } from '../where-backup-models';
import { Storages } from './base-models/plan-storages-model';
import { AppAwareStepValue } from '../app-aware-models'
import { BaseFormModels } from '@modules/wizards/models/base/base-form-models';

export class VmwareHyperVBaseModel extends BaseFormModels {
  StoragesArray: StorageConnection[] = [];

  selectVirtualMachinesStep: SelectVirtualMachinesStepValue = {
    type: VirtualMachinesSelectedType.All,
    machines: [],
    machinesFullFormat: [],
    valid: true
  };
  appAwareStep: AppAwareStepValue = {
    settings: [],
    valid: true
  };
  selectVirtualDisksStep: SelectVirtualDisksStepValue = {
    type: VirtualDisksSelectedType.Selected,
    disks: [],
    allDisks: [],
    valid: true
  };

  archiveConsistencyStep: ArchiveConsistencyStepValue = { useFullConsistencyCheck: true, valid: true };
  compressionEncryptionStep: CompressionEncryptionStepValue = {
    UseCompression: false,
    UseEncryption: false,
    EncryptionAlgorithm: 3,
    EncryptionKeySize: 256,
    EncryptionPassword: '',
    valid: true
  };
  scheduleStep: ScheduleStepValue = {
    ScheduleType: 'noschedule',
    StopAfterEnabled: false,
    stopAfterHours: 0,
    stopAfterMinutes: 0,
    ForceMissedSchedule: false,
    incrementalData: {
      recurringPeriod: 'Monthly',
      weeklyDayOfWeek: [],
      occurrence: WeekNumber.First,
      dayOfWeek: DayOfWeek.Wednesday,
      dayOfMonthCount: 1,
      dailyFreqPeriodOption: 'OccursAt',
      occursAtTime: '00:00',
      occursEveryCount: 1,
      occursEveryPeriod: 'hours',
      occursEveryFromTime: '00:00',
      occursEveryToTime: '00:00'
    },
    fullBackupData: {
      enabled: false,
      recurringPeriod: 'Monthly',
      weeklyDayOfWeek: [],
      occurrence: WeekNumber.First,
      dayOfWeek: DayOfWeek.Wednesday,
      dayOfMonthCount: 1,
      dailyFreqPeriodOption: 'OccursAt',
      occursAtTime: '00:00'
    },
    syncBeforeRun: false,
    valid: true
  };
  retentionPolicyStep: RetentionPolicyStepValue = {
    RetentionUseDefaultSettings: true,
    deleteVersionsOlderThanCount: 1,
    deleteVersionsOlderThanPeriod: 2,
    intelligentRetention: false,
    GFSPolicySettings: {
      enabled: false,
      enabledImmutability: false,
      weekly: { enabled: false, storePeriod: 2 },
      monthly: { enabled: false, storePeriod: 4 },
      yearly: { enabled: false, storePeriod: 2, takeBackupFromMonth: 1 }
    },
    valid: true
  };

  constructor(public isLinux = false) {
    super(false, isLinux);
  }
}
