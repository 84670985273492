<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           [showCloseCross]="false"
           [size]="MbsSize.sm"
           #modal>

  <div modal-body>
    <mbs-recovery-code [codes]="baseModal.data.codes"
                       [isLogin]="false"
                       [isResetCodes]="true"
                       (onSaveRecoveryCodes)="isSavedRecoveryCodes = $event"></mbs-recovery-code>
  </div>

  <ng-container modalFooter>
    <mbs-button type="secondary"
                [type]="'primary'"
                [disabled]="!isSavedRecoveryCodes"
                data-dismiss="modal"
                (click)="modal.save()">
      {{ 'tfa:finish' | i18next: { format:'title' } }}
    </mbs-button>
  </ng-container>

</mbs-modal>
