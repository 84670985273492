import { ScheduleSettings } from '../schedule-advanced-models';
import { BackupFilter } from '../what-backup-tree-model';
import { BaseBackupPlan } from './base-backup-plan-model';

export class BackupPlan extends BaseBackupPlan {
  constructor() {
    super();
  }
  public BackupFilter: BackupFilter;
  public IsPlanCanBeRunnedWithoutLicense: boolean;
  public IsSyntheticSupported: boolean;
  public VSSProviderID: string;
  public ForceFullDiffSizeCondition: number;
  public ForceFullApplyDiffSizeCondition: boolean;
  public ForceFullSchedule: ScheduleSettings;
  public UseFullSchedule: boolean;
  public UseDifferentialUpload: boolean;

  static Guid_DirectAccessVSS = 'b5946137-7b9f-4925-af80-51abd60b20d5';
}
