<mbs-modal [title]="'wizards:addNetworkShare' | i18next: { format: 'title' }"
           [loading]="loading">
  <ng-container modal-body
                [formGroup]="form">
    <mbs-form-group>
      <mbs-input *ngIf="!canUseShareTree"
                 formControlName="path"
                 name="newPath"
                 (input)="resetTestResult()"
                 [id]="'new-path-input'"
                 [label]="'wizards:addNetworkSharePath' | i18next"
                 [placeholder]="'wizards:addNetworkSharePathPlaceholder' | i18next"
                 [validationWhitespace]="true"></mbs-input>
      <mbs-input *ngIf="canUseShareTree"
                 formControlName="path"
                 name="newPath"
                 (input)="resetTestResult()"
                 [id]="'new-path-input'"
                 [label]="'wizards:addNetworkSharePath' | i18next"
                 [placeholder]="'wizards:addNetworkSharePathPlaceholder' | i18next"
                 [validationWhitespace]="true"
                 (buttonClick)="pathNetworkTreeOpen()"
                 [appendButtons]="[
                   {
                     text: 'Browse',
                     id: 'network-path-folder-tree-1',
                     disabled: form.get('path').disabled,
                     type: 'secondary'
                   }
                 ]"></mbs-input>
    </mbs-form-group>

    <div class="d-flex justify-content-end">
      <mbs-button [isCtrl]="true"
                  (click)="manageNetworkCredentialsClickHandler()"
                  [type]="'primary'">
        Manage network credentials
      </mbs-button>
    </div>
  </ng-container>

  <ng-container modalFooter>
    <button type="button"
            class="btn btn-primary"
            (click)="addClickHandler()">{{ 'buttons:add' | i18next: { format: 'title' } }}</button>
    <button type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancelClickHandler()">{{ 'buttons:cancel' | i18next: { format: 'title' } }}</button>
  </ng-container>
</mbs-modal>
