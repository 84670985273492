import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import Administrator from '@models/Administrator';
import Computer from '@models/Computer';
import { ProviderData } from '@models/ProviderData';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { ProviderService } from '@services/provider.service';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';
import { BehaviorSubject } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-remote-connection-enable-modal-component',
  templateUrl: './remote-connection-enable-modal.component.html',
  styleUrls: ['./remote-connection-enable-modal.component.scss']
})
export class RemoteConnectionEnableModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public readonly mbsPopupType = MbsPopupType;

  public checkbox = new FormControl(false);
  public loading$ = new BehaviorSubject(false);
  public computer: Computer;
  constructor(private providerService: ProviderService, private auth: AuthService) {}

  ngOnInit(): void {
    if (this.baseModal.data) {
      this.computer = this.baseModal.data.computer;
    }
  }

  handleSubmit(): void {
    this.loading$.next(true);
    this.providerService
      .toggleRemoteAccess(true)
      .pipe(
        switchMap(() => {
          return this.auth.fetchCurrentUser();
        }),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((admin: Administrator) => {
        this.baseModal.save(admin);
      });
  }
}
