import { Tag } from '@models/tag';
import { createAction, props } from '@ngrx/store';
import { COMPUTER_TAGS_STORE_TYPE } from './state';

export const setComputerTags = createAction(`[${COMPUTER_TAGS_STORE_TYPE}] Set Computer Tags`, props<{ tags: Tag[] }>());
export const setSelected = createAction(`[${COMPUTER_TAGS_STORE_TYPE}] Set Selected Computer Tag`, props<{ selected: number }>());
export const setSelectedSuccess = createAction(
  `[${COMPUTER_TAGS_STORE_TYPE}] Set Selected Computer Tag Success`,
  props<{ selected: number }>()
);
export const clear = createAction(`[${COMPUTER_TAGS_STORE_TYPE}] Clear Computer Tags`);
export const loadComputerTagById = createAction(
  `[${COMPUTER_TAGS_STORE_TYPE}] Load Computer Tag By Id`,
  props<{ id: number; force?: boolean; quiet?: boolean }>()
);
export const loadAllComputerTags = createAction(
  `[${COMPUTER_TAGS_STORE_TYPE}] Load All Computer Tags`,
  props<{ force?: boolean; quiet?: boolean; calculateCount?: boolean }>()
);
export const loadComputerTagsError = createAction(`[${COMPUTER_TAGS_STORE_TYPE}] Load Computer Tags Error`);
export const deleteComputerTag = createAction(`[${COMPUTER_TAGS_STORE_TYPE}] Delete Computer Tag`, props<{ id: number }>());
