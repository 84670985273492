export enum ComputersActionTypes {
  SetMode = 'Set Mode',
  SetSortSettings = 'Set Sort Settings',
  SetFilterHidden = 'Set Filter Hidden',
  SetFilterCompany = 'Set Filter Company',
  SetFilters = 'Set Filters',
  SetFilterSearch = 'Set Filter Search',
  SetFilterHealth = 'Set Filter Health',
  LoadComputersApplications = 'Load Computers Applications Count',
  LoadComputersApplicationsSuccess = 'Set Computers Applications Count Success'
}
