import { AdvancedSettingsVirtualStepValue } from '@modules/wizards/models/advanced-settings-vm-models';
import { VmwareHyperVBaseModel } from '@modules/wizards/models/base/vmware-hyper-v-base-model';

export class HyperVFormModel extends VmwareHyperVBaseModel {
  advancedSettingsStep: AdvancedSettingsVirtualStepValue = {
    useChangeBlockTracking: false,
    useS3Acceleration: false,
    storageClass: 1,
    syntheticFull: true,
    valid: true
  };
  AvailableMachinePlans: any[] = [];

  constructor(public isLinux = false) {
    super(isLinux);
  }
}
