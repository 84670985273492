export enum ProgressLineStatus {
  NoStatus,

  // running group
  Created,
  Pending,
  Running,
  RunningExtended,

  // finished group
  Success,
  Warning,
  Failed,
  Total
}
