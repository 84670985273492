import { PlanNameStepValue } from '@modules/wizards/models/plan-name-models';
import { WhereBackupStepValue } from '@modules/wizards/models/where-backup-models';
import { Storages } from '@modules/wizards/models/base/base-models/plan-storages-model';
import { GuidEmpty } from 'mbs-ui-kit';
import { NotificationsStepValue } from '@modules/wizards/models/notifications-models';
import { PrePostActionsStepValue } from "@modules/wizards/models/pre-post-actions-models";

export class BaseFormModels {
  planNameStep: PlanNameStepValue = { Name: '', SavePlanInCloud: true, valid: true };
  whereBackupStep: WhereBackupStepValue = { IsHybridBackup: false, HybridID: GuidEmpty, ConnectionID: GuidEmpty, valid: false };
  Storages: Storages = { CloudStorages: [], LocalStorages: [] };

  prePostActionsStep: PrePostActionsStepValue = {
    executeCommandBeforeBackup: false,
    preAction: '',
    exitOnPreActionFail: true,
    executeCommandAfterBackupCompletion: false,
    postAction: '',
    executePostActionOnSuccess: true,
    valid: true
  };
  notificationStep: NotificationsStepValue = {
    receiveNotificationOnCompleteGroup: { flag: true, case: 'whenFails' },
    valid: true
  };

  constructor(public isNBF = false, public isLinux = false) {}
}
