import { Action, createReducer, on } from '@ngrx/store';
import * as ApplicationActions from './actions';
import { ApplicationState, initialApplicationState } from './state';

const reducer = createReducer(
  initialApplicationState,
  on(ApplicationActions.setMbsRAMode, (state, action): ApplicationState => {
    return {
      ...state,
      mbsRA: action.payload
    };
  }),
  on(ApplicationActions.setCurrentAdmin, (state, action): ApplicationState => {
    return {
      ...state,
      currentAdmin: action.payload
    };
  }),
  on(ApplicationActions.setFooterExist, (state, action): ApplicationState => {
    return {
      ...state,
      footerExist: action.payload
    };
  }),
  on(
    ApplicationActions.setStorage,
    (state, action): ApplicationState => ({
      ...state,
      storage: action.payload
    })
  ),
  on(ApplicationActions.setStorageSetting, (state, action): ApplicationState => {
    const newStorageValue = { ...(state.storage || {}), [action.payload.key]: action.payload.value };

    return {
      ...state,
      storage: newStorageValue
    };
  })
);

export function applicationReducer(state: ApplicationState | undefined, action: Action) {
  return reducer(state, action);
}
