import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuildsInfo } from '@models/build';

@Injectable({
  providedIn: 'root'
})
export class OnlineAccessBuildService {
  constructor(private http: HttpClient) {
  }

  getAll(): Observable<BuildsInfo> {
    return this.http.get<BuildsInfo>(`/api/downloads/GetDownloadsModel`);
  }
}
