import { Component, ViewChild } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-wizards-legacy-property-disabling-modal',
  template: ` <mbs-modal [isCustomModal]="true">
    <ng-container modal-header>
      {{ 'wizards:legacy.disablingModalTitle' | i18next: { format: 'title' } }}
    </ng-container>

    <ng-container modal-body>
      <span>{{ 'wizards:legacy.disablingModalTextPrefix' | i18next }}</span>
      &nbsp;<strong>'{{ baseModal?.data?.propertyTitle }}'</strong>&nbsp;
      <span>{{ 'wizards:legacy.disablingModalText' | i18next }}</span>
      <p class="mt-2">{{ 'wizards:legacy.disablingModalTextAction' | i18next }}</p>
    </ng-container>

    <ng-container modalFooter>
      <mbs-button data-dismiss="modal" type="primary" (click)="baseModal.save(true)">
        {{ 'buttons:confirm' | i18next }}
      </mbs-button>
      <mbs-button data-dismiss="modal" type="secondary" (click)="baseModal.close()">
        {{ 'buttons:cancel' | i18next }}
      </mbs-button>
    </ng-container>
  </mbs-modal>`
})
export class LegacyPropertyDisablingModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  constructor(private i18nPipe: I18NextPipe) {}
}
