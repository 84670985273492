<mbs-modal [title]="title || ('wizards:tree_modal_inner_modal_title' | i18next: { format: 'title' })">
  <ng-container modal-body>
    <p *ngIf="currentEncryptedRestorePoint"
       [class]="elementsSelector.css.passwordModalTopText">
      The &ldquo;{{currentEncryptedRestorePoint}}&rdquo; restore point contains encrypted data.
      To&nbsp;view the restore point contents, enter the encryption password you used in&nbsp;the backup plan
    </p>
    <mbs-form-group [formGroup]="passForm">
      <mbs-input #partitionsPassField
                 [label]="'wizards:password_encryption_pass_label' | i18next"
                 [id]="elementsSelector.id.passwordModalPassField"
                 [attr.data-test-element]="elementsSelector.name.passwordModalPassField"
                 name="PasswordModalPassword"
                 [autocomplete]="'new-password'"
                 [type]="passwordInputType"
                 formControlName="password"
                 readonly
                 (focus)="partitionsPassField.readonly = false"
                 (buttonClick)="changePasswordType()"
                 (input)="passwordInputHandler()"
                 (keydown.enter)="confirmClickHandler()"
                 [appendButtons]="[
                 {
                   icon: passwordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                   id: elementsSelector.id.passwordModalChangeTypePassword,
                   disabled: false,
                   type: 'secondary'
                 }
               ]">
      </mbs-input>
    </mbs-form-group>

    <div *ngIf="passwordRecoveryEnabled"
         class="mb-2"
         [ngClass]="elementsSelector.css.passwordModalRecovery">
      <app-password-recovery-handler [hid]="hid"
                                     [resultPoint]="{ path: restorePoint?.path, restorePoint: restorePoint?.restorePointId || restorePoint?.restorePoint }"
                                     [passwordControl]="passForm.get('password')"
                                     [backupVersionUpdated]="backupVersionUpdated"></app-password-recovery-handler>
    </div>

    <mbs-form-group *ngIf="restorePoint?.encryptionPasswordHint"
                    [class]="elementsSelector.css.passwordModalHintBlock">
      <mbs-button [isCtrl]="true"
                  [attr.data-test-element]="elementsSelector.name.passwordModalHintButton"
                  type="primary"
                  (click)="showPasswordHintChangeHandler()">
        {{ 'wizards:pass_hint_btn_text' | i18next: { state: (showHint ? 'Hide' : 'Show') } }}
      </mbs-button>
      <div *ngIf="showHint"
           class="font-weight-bold"
           [ngClass]="elementsSelector.css.passwordModalHintText">
        {{restorePoint.encryptionPasswordHint}}
      </div>
    </mbs-form-group>

    <mbs-alert class="mb-0 mt-3"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{ 'wizards:pass_modal_alert_text' | i18next }}
    </mbs-alert>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button type="primary"
                class="mr-1"
                [disabled]="this.passForm.get('password').invalid"
                (click)="confirmClickHandler()">
      {{ okButtonText || ('buttons:confirm' | i18next) }}
    </mbs-button>
    <mbs-button type="secondary"
                class="ml-1"
                (click)="cancelClickHandler()">
      {{ cancelButtonText || ('buttons:cancel' | i18next) }}
    </mbs-button>
  </ng-container>
</mbs-modal>
