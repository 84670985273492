/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { getPagingParams, PagedResponse, PagingParams, QueryFiltersParams, QueryPagingParams, QueryParams } from '@models/Paging';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import {
  Action,
  ActionCreator,
  createAction,
  createFeatureSelector,
  createSelector,
  on,
  props,
  ReducerManager,
  ReducerTypes,
  Store
} from '@ngrx/store';
import { isDataObsolete } from '@utils/store';
import { produce } from 'immer';
import { cloneDeep, isEmpty, isEqual, isNil } from 'lodash';
import moment from 'moment';
import { createImmerReducer } from 'ngrx-immer/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { StoreTypes } from '../storeTypes.enum';
import {
  EntityStateModel,
  getPagingStoreActionType,
  initialPagingEntityState,
  PagingActions,
  PagingActionTypes,
  PagingSelectors
} from './pagingStoreTypes';

@Injectable()
// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class PagingStoreFactory<T, F extends {} = Record<string, never>> {
  protected abstract storeType: StoreTypes;
  protected abstract selectId: (T) => string;

  protected abstract getEntityById(id: string): Observable<T>;
  protected abstract getEntities(params: QueryFiltersParams & QueryPagingParams): Observable<PagedResponse<T>>;

  public adapter: EntityAdapter<T>;
  public actions: PagingActions<T>;
  public selectors: PagingSelectors<T, F>;
  public initialEntityState: EntityStateModel<T, F>;
  public customReducer: ReducerTypes<EntityStateModel<T, F>, ActionCreator[]>[] = [];

  constructor(public actions$: Actions, public store: Store, public reducerManager: ReducerManager) {}

  public initStore(): void {
    this.prepareAdapter();
    this.prepareActions();
    this.prepareCustomActions();
    this.prepareEffects();
    this.prepareCustomEffects();
    this.prepareCustomReducer();
    this.reducerManager.addReducer(this.storeType, this.prepareReducer);
    this.prepareSelectors();
    this.prepareCustomSelectors();
  }

  // adapter
  private prepareAdapter(): void {
    this.adapter = createEntityAdapter<T>({ selectId: this.selectId });
    this.initialEntityState = this.adapter.getInitialState({ ...initialPagingEntityState, ...this.getCustomInitialState() });
  }

  // actions
  private prepareActions(): void {
    const getActionType = (pagingActionType: PagingActionTypes): `[${StoreTypes}] ${PagingActionTypes}` =>
      getPagingStoreActionType(this.storeType, pagingActionType);
    // actions
    this.actions = {
      setEntities: createAction(getActionType(PagingActionTypes.SetEntities), props<{ entities: T[] }>()),
      setSelected: createAction(getActionType(PagingActionTypes.SetSelected), props<{ selected: string; force: boolean }>()),
      setSelectedSuccess: createAction(getActionType(PagingActionTypes.SetSelectedSuccess), props<{ selected: string; entity?: T }>()),
      clear: createAction(getActionType(PagingActionTypes.Clear)),
      loadEntityById: createAction(
        getActionType(PagingActionTypes.LoadEntityById),
        props<{ id: string; force?: boolean; quiet?: boolean }>()
      ),
      loadError: createAction(getActionType(PagingActionTypes.LoadError)),
      setLoading: createAction(getActionType(PagingActionTypes.SetLoading), props<{ loading: boolean }>()),

      // data
      loadData: createAction(
        getActionType(PagingActionTypes.LoadData),
        props<{ isFullRefresh?: boolean; allowLoadMore?: boolean; limit?: number }>()
      ),
      loadDataSuccess: createAction(getActionType(PagingActionTypes.LoadDataSuccess), props<{ entities: T[]; total: number }>()),
      loadDataError: createAction(getActionType(PagingActionTypes.LoadDataError)),
      clearData: createAction(getActionType(PagingActionTypes.ClearData)),

      // paging
      loadPage: createAction(
        getActionType(PagingActionTypes.LoadPage),
        props<{ filters?: QueryParams; pageNumber?: number; perPage?: number; isFullRefresh?: boolean }>()
      ),
      loadPageSuccess: createAction(
        getActionType(PagingActionTypes.LoadPageSuccess),
        props<{ pageNumber: number; entities: T[]; total: number }>()
      ),
      loadPageError: createAction(getActionType(PagingActionTypes.LoadPageError)),
      clearPages: createAction(getActionType(PagingActionTypes.ClearPages)),
      setPageNumber: createAction(getActionType(PagingActionTypes.SetPageNumber), props<{ pageNumber: number }>()),
      setPageNumberSuccess: createAction(getActionType(PagingActionTypes.SetPageNumberSuccess), props<{ pageNumber: number }>()),
      setPerPage: createAction(getActionType(PagingActionTypes.SetPerPage), props<{ perPage: number }>()),
      refreshDataAndPaging: createAction(getActionType(PagingActionTypes.RefreshDataAndPaging)),
      refreshDataAndPagingSuccess: createAction(
        getActionType(PagingActionTypes.RefreshDataAndPagingSuccess),
        props<{ entities: T[]; total: number }>()
      )
    };
  }

  protected prepareCustomActions(): void {}

  // selectors
  private prepareSelectors(): void {
    const adapterSelectors = this.adapter.getSelectors();
    const selectIdsByAdapter = adapterSelectors.selectIds;
    const selectAllByAdapter = adapterSelectors.selectAll;
    const selectEntitiesByAdapter = adapterSelectors.selectEntities;

    const selectEntityFeatureSelector = createFeatureSelector<EntityStateModel<T, F>>(this.storeType);
    const selectAll = createSelector(selectEntityFeatureSelector, selectAllByAdapter);
    const selectEntities = createSelector(selectEntityFeatureSelector, selectEntitiesByAdapter);

    const selectIds = createSelector(selectEntityFeatureSelector, selectIdsByAdapter);
    const selectById = (id: string) => createSelector(selectEntities, (entities) => entities[id]);
    const selectLoadedById = (id: string) => createSelector(selectById(id), (entity) => ((entity as any)?.loadedById ? entity : null));
    const selectFirstEntity = createSelector(selectAll, (entities) => entities.find(Boolean));

    // common
    const selectSelected = createSelector(selectEntityFeatureSelector, (state) => state.selected);
    const selectSelectedEntity = createSelector(selectSelected, selectAll, (selected, entities) =>
      entities.find((entity) => this.adapter.selectId(entity) === selected)
    );
    const selectLoading = createSelector(selectEntityFeatureSelector, (state) => state.loading);

    // data
    const selectDataLoading = createSelector(selectEntityFeatureSelector, (state) => state.data.loading);
    const selectDataLoaded = createSelector(selectEntityFeatureSelector, (state) => state.data.loaded);
    const selectDataTotal = createSelector(selectEntityFeatureSelector, (state) => state.data.total);

    const selectData = createSelector(selectEntityFeatureSelector, selectEntities, (state, entities) =>
      (state.data.entities || []).map((id) => entities[id])
    );
    const selectDataCount = createSelector(selectEntityFeatureSelector, (state) => (state.data.entities || []).length);
    const selectAllDataLoaded = createSelector(
      selectDataLoaded,
      selectDataTotal,
      selectDataCount,
      (loaded, total, count) => loaded && total <= count
    );
    const selectPreQueryDataParams = createSelector(selectDataCount, selectAllDataLoaded, (dataCount, allLoaded) => ({
      dataCount,
      allLoaded
    }));

    // paging
    const selectPagingLoading = createSelector(selectEntityFeatureSelector, (state) => state.paging.loading);
    const selectPagingLoaded = createSelector(selectEntityFeatureSelector, (state) => state.paging.loaded);
    const selectPagingPageNumbers = createSelector(selectEntityFeatureSelector, (state) =>
      Object.keys(state.paging.pages).map((v) => parseInt(v, 10))
    );
    const selectPagingCurrentPageNumber = createSelector(selectEntityFeatureSelector, (state) => state.paging.currentPageNumber);
    const selectPagingPerPage = createSelector(selectEntityFeatureSelector, (state) => state.paging.perPage);
    const selectPagingTotal = createSelector(selectEntityFeatureSelector, (state) => state.paging.total);
    const selectPagingParams = createSelector(
      selectPagingPerPage,
      selectPagingCurrentPageNumber,
      (perPage, currentPageNumber) => ({ perPage, currentPageNumber } as PagingParams)
    );
    const selectPagingFilters = createSelector(selectEntityFeatureSelector, (state) => state.paging.filters);
    const selectPreQueryPagingParams = createSelector(
      selectPagingFilters,
      selectPagingParams,
      selectPagingPageNumbers,
      (filters, pagingParams, pageNumbers) => ({ filters, pagingParams, pageNumbers })
    );

    const selectCurrentPage = createSelector(
      selectEntityFeatureSelector,
      selectPagingCurrentPageNumber,
      selectEntities,
      (state, currentPage, entities) => ((currentPage && state.paging.pages[currentPage]) || []).map((id) => entities[id])
    );

    // combined
    const selectDataEntityLoading = createSelector(selectLoading, selectDataLoading, (loading, dataLoading) => loading || dataLoading);
    const selectPagingEntityLoading = createSelector(
      selectLoading,
      selectPagingLoading,
      (loading, pagingLoading) => loading || pagingLoading
    );

    this.selectors = {
      featureSelector: selectEntityFeatureSelector,
      selectAll,
      selectIds,
      selectById,
      selectLoadedById,
      selectFirstEntity,
      selectSelected,
      selectSelectedEntity,
      selectLoading,
      selectDataLoading,
      selectDataLoaded,
      selectDataTotal,
      selectData,
      selectDataCount,
      selectAllDataLoaded,
      selectPreQueryDataParams,
      selectPagingLoading,
      selectPagingLoaded,
      selectPagingPageNumbers,
      selectPagingCurrentPageNumber,
      selectPagingPerPage,
      selectPagingTotal,
      selectPagingParams,
      selectPagingFilters,
      selectPreQueryPagingParams,
      selectCurrentPage,
      selectDataEntityLoading,
      selectPagingEntityLoading
    };
  }

  protected prepareCustomSelectors(): void {}

  // reducer
  private prepareReducer = (state: EntityStateModel<T, F> | undefined, action: Action) => {
    const mainReducer: ReducerTypes<EntityStateModel<T, F>, ActionCreator[]>[] = [
      on(this.actions.loadEntityById, (state, action) => {
        if (!action.id || (!!state.entities[action.id] && !action.force) || action.quiet) return state;

        state.loading = true;

        return state;
      }),
      on(this.actions.setEntities, (state, action) => {
        return this.replaceEntities(
          action.entities,
          produce(state, (draft) => {
            draft.loading = false;
          })
        );
      }),
      on(this.actions.setSelectedSuccess, (state, action) => {
        return this.replaceEntities(
          action.entity ? [action.entity] : [],
          produce(state, (draft) => {
            draft.selected = action.selected;
          })
        );
      }),
      on(this.actions.clear, (state) => {
        return this.adapter.removeAll(state);
      }),
      on(this.actions.loadError, (state) => {
        state.loading = false;

        return state;
      }),
      on(this.actions.setLoading, (state, action) => {
        state.loading = action.loading;

        return state;
      }),
      on(this.actions.loadData, (state, action) => {
        const isDataOutdated = isDataObsolete(state.data.obsolescenceMark);
        const isPagingOutdated = isDataObsolete(state.paging.obsolescenceMark);
        const allLoaded = state.data.total <= (state.data.entities || []).length;
        if (!isDataOutdated && !action.isFullRefresh && state.data.loaded && allLoaded) return state;

        if (action.isFullRefresh || isDataOutdated) {
          state = this.clearData(state);
        }

        // cross copying suitable data between date and paging
        if (
          !action.isFullRefresh &&
          !isPagingOutdated &&
          !state.data.loaded &&
          state.paging.loaded &&
          isEmpty(state.paging.filters) &&
          Object.keys(state.paging.pages)
            .map((v) => parseInt(v, 10))
            .some((pageId) => pageId === 1)
        ) {
          state.data.entities = [...state.paging.pages[1]];
          state.data.total = state.paging.total;
          state.data.loaded = true;
          state.data.obsolescenceMark = state.paging.obsolescenceMark;
        }

        state.data.loading = !state.data.loaded || state.data.total > state.data.entities.length;

        return state;
      }),
      on(this.actions.loadDataSuccess, (state, action) => {
        return this.replaceEntities(
          action.entities,
          produce(state, (draft) => {
            draft.data = {
              ...draft.data,
              total: action.total,
              loaded: true,
              loading: false,
              entities: state.data.entities.concat(action.entities.map((entity) => this.adapter.selectId(entity) as string)),
              obsolescenceMark: draft.data.obsolescenceMark ?? moment().valueOf()
            };
          })
        );
      }),
      on(this.actions.loadDataError, (state) => {
        state.data.loading = false;

        return state;
      }),
      on(this.actions.clearData, (state) => {
        return this.clearData(state);
      }),
      on(this.actions.loadPage, (state, action) => {
        const isDataOutdated = isDataObsolete(state.data.obsolescenceMark);
        const isPagingOutdated = isDataObsolete(state.paging.obsolescenceMark);
        let clear = false;

        state.paging.currentPageNumber = action.pageNumber ?? state.paging.currentPageNumber;

        if (isPagingOutdated || action.isFullRefresh) {
          clear = true;
        }

        if (!isNil(action.filters) && !isEqual(state.paging.filters, action.filters)) {
          state.paging.filters = cloneDeep(action.filters);
          state.paging.currentPageNumber = this.initialEntityState.paging.currentPageNumber;
          clear = true;
        }

        if (action.perPage && state.paging.perPage !== action.perPage) {
          state.paging.perPage = action.perPage;
          state.paging.currentPageNumber = this.initialEntityState.paging.currentPageNumber;
          clear = true;
        }

        state = clear ? this.clearPaging(state) : state;

        // cross copying suitable data between date and paging
        if (
          !action.isFullRefresh &&
          !isDataOutdated &&
          state.data.loaded &&
          isEmpty(state.paging.filters) &&
          state.paging.currentPageNumber === 1 &&
          !Object.keys(state.paging.pages).some((v) => parseInt(v, 10) === 1) &&
          (state.data.entities.length >= state.paging.perPage ||
            (state.data.total < state.paging.perPage && state.data.entities.length === state.data.total))
        ) {
          state.paging.pages = { ...state.paging.pages, [1]: state.data.entities.slice(0, state.paging.perPage) };
          state.paging.total = state.data.total;
          state.paging.loaded = true;
          state.paging.obsolescenceMark = state.paging.obsolescenceMark
            ? Math.min(state.paging.obsolescenceMark, state.data.obsolescenceMark)
            : state.data.obsolescenceMark;
        }

        state.paging.loading = !(state.paging.loaded && !!state.paging.pages[state.paging.currentPageNumber]);

        return state;
      }),
      on(this.actions.loadPageSuccess, (state, action) => {
        return this.replaceEntities(
          action.entities,
          produce(state, (draft) => {
            draft.paging = {
              ...draft.paging,
              pages: {
                ...draft.paging.pages,
                [action.pageNumber]: [...action.entities.map((entity) => this.adapter.selectId(entity) as string)]
              },
              total: action.total,
              loading: false,
              loaded: true,
              obsolescenceMark: draft.paging.obsolescenceMark ?? moment().valueOf()
            };

            // cross copying suitable data between date and paging
            if (
              isEmpty(draft.paging.filters) &&
              action.pageNumber === 1 &&
              (!draft.data.loaded || action.entities.length > draft.data.entities.length)
            ) {
              draft.data.entities = [...action.entities.map((entity) => this.adapter.selectId(entity) as string)];
              draft.data.total = action.total;
              draft.data.loaded = true;
              draft.data.obsolescenceMark = draft.paging.obsolescenceMark;
            }
          })
        );
      }),
      on(this.actions.loadPageError, (state) => {
        state.paging.loading = false;

        return state;
      }),
      on(this.actions.clearPages, (state) => {
        return this.clearPaging(state, true);
      }),
      on(this.actions.setPageNumberSuccess, (state, action) => {
        state.paging.loading = false;
        state.paging.currentPageNumber = action.pageNumber;

        return state;
      }),
      on(this.actions.refreshDataAndPaging, (state) => {
        state = this.clearData(state);
        state = this.clearPaging(state, true);
        state.data.loading = true;
        state.paging.loading = true;

        return state;
      }),
      on(this.actions.refreshDataAndPagingSuccess, (state, action) => {
        const obsolescenceMark = moment().valueOf();

        return this.replaceEntities(
          action.entities,
          produce(state, (draft) => {
            draft.data = {
              ...draft.data,
              total: action.total,
              loaded: true,
              loading: false,
              entities: state.data.entities.concat(action.entities.map((entity) => this.adapter.selectId(entity) as string)),
              obsolescenceMark: obsolescenceMark
            };
            draft.paging = {
              ...draft.paging,
              pages: {
                ...draft.paging.pages,
                [1]: [...action.entities.slice(0, state.paging.perPage).map((entity) => this.adapter.selectId(entity) as string)]
              },
              total: action.total,
              loading: false,
              loaded: true,
              obsolescenceMark: obsolescenceMark
            };
          })
        );
      })
    ];
    const reducer = createImmerReducer(this.initialEntityState, ...mainReducer, ...this.customReducer);

    return reducer(state, action);
  };

  private replaceEntities = (entities: T[], state: EntityStateModel<T, F>): EntityStateModel<T, F> => {
    const newState = this.adapter.removeMany(
      entities.map((entity) => this.adapter.selectId(entity) as string),
      state
    );

    return this.adapter.addMany(entities, newState);
  };

  private clearData = (state: EntityStateModel<T, F>): EntityStateModel<T, F> => {
    state.data = {
      ...state.data,
      entities: [],
      total: this.initialEntityState.data.total,
      loaded: this.initialEntityState.data.loaded,
      obsolescenceMark: this.initialEntityState.data.obsolescenceMark,
      loading: this.initialEntityState.data.loading
    };
    return state;
  };

  private clearPaging = (state: EntityStateModel<T, F>, clearAllFields = false): EntityStateModel<T, F> => {
    state.paging = {
      ...state.paging,
      pages: {},
      total: this.initialEntityState.paging.total,
      loaded: this.initialEntityState.paging.loaded,
      obsolescenceMark: this.initialEntityState.paging.obsolescenceMark,
      loading: this.initialEntityState.paging.loading,
      ...(clearAllFields ? { currentPageNumber: this.initialEntityState.paging.currentPageNumber } : {})
    };
    return state;
  };

  protected prepareCustomReducer(): void {}

  // effects
  public loadEntity$: Observable<Action>;
  public setSelectedEntity$: Observable<Action>;
  public loadData$: Observable<Action>;
  public loadPage$: Observable<Action>;
  public setPageNumber$: Observable<Action>;
  public setPerPage$: Observable<Action>;
  public reloadDataAndPaging$: Observable<Action>;

  private prepareEffects(): void {
    this.loadEntity$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.loadEntityById),
        concatLatestFrom((action) => this.store.select(this.selectors.selectById(action.id))),
        filter(([action, entity]) => !!action.id && (!entity || action.force)),
        mergeMap(([action]) => {
          return this.getEntityLoadedById(action.id).pipe(
            map((result) => this.actions.setEntities({ entities: [result] })),
            catchError(() => of(this.actions.loadError()))
          );
        })
      );
    });

    this.setSelectedEntity$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.setSelected),
        concatLatestFrom(() => this.store.select(this.selectors.selectSelected)),
        filter(([action, selected]) => action.selected !== selected),
        map(([action]) => action),
        concatLatestFrom((action) => (action.selected ? this.store.select(this.selectors.selectById(action.selected)) : of(null))),
        switchMap(([action, entity]) => {
          if ((!action.selected || entity) && !action.force) return of(this.actions.setSelectedSuccess({ selected: action.selected }));

          return this.getEntityLoadedById(action.selected).pipe(
            map((result) => this.actions.setSelectedSuccess({ selected: this.adapter.selectId(result) as string, entity: result })),
            catchError(() => of(this.actions.loadError()))
          );
        })
      );
    });

    this.loadData$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.loadData),
        concatLatestFrom(() => this.store.select(this.selectors.selectPreQueryDataParams)),
        filter(([action, { dataCount, allLoaded }]) => (action.allowLoadMore || !dataCount) && !allLoaded),
        mergeMap(([action, { dataCount }]) =>
          this.getEntities({ offset: dataCount, ...(action.limit > 0 ? { limit: action.limit } : {}) }).pipe(
            map((result) =>
              this.actions.loadDataSuccess({
                entities: result.data,
                total: result.total
              })
            ),
            catchError(() => of(this.actions.loadDataError()))
          )
        )
      );
    });

    this.loadPage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.loadPage),
        concatLatestFrom(() => this.store.select(this.selectors.selectPreQueryPagingParams)),
        filter(([, { pagingParams, pageNumbers }]) => !pageNumbers.some((pageIndex) => pageIndex === pagingParams.currentPageNumber)),
        switchMap(([, { filters, pagingParams }]) =>
          this.getEntities({ ...filters, ...getPagingParams(pagingParams) }).pipe(
            map((result) =>
              this.actions.loadPageSuccess({
                pageNumber: pagingParams.currentPageNumber,
                entities: result.data,
                total: result.total
              })
            ),
            catchError(() => of(this.actions.loadPageError()))
          )
        )
      );
    });

    this.setPageNumber$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.setPageNumber),
        switchMap(({ pageNumber }) => of(this.actions.loadPage({ pageNumber })))
      );
    });

    this.setPerPage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.setPerPage),
        switchMap(({ perPage }) => of(this.actions.loadPage({ perPage })))
      );
    });

    this.reloadDataAndPaging$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(this.actions.refreshDataAndPaging),
        mergeMap(() =>
          this.getEntities({ offset: 0 }).pipe(
            map((result) =>
              this.actions.refreshDataAndPagingSuccess({
                entities: result.data,
                total: result.total
              })
            ),
            catchError(() => of(this.actions.loadDataError()))
          )
        )
      );
    });
  }

  private getEntityLoadedById(id: string): Observable<T> {
    return this.getEntityById(id).pipe(map((result) => ({ ...result, loadedById: true })));
  }

  protected prepareCustomEffects(): void {}

  protected getCustomInitialState() {
    return {
      custom: {} as F
    };
  }
}
