<mbs-modal [isCustomModal]="true"
           [loading]="loading"
           [title]="'Password Recovery'">
  <div modal-body>
    <ng-container *ngIf="passwordCanBeRestored; else cantDecodeTemplate">
      <p>Enter the password recovery key created&nbsp;on
        <mbs-backup-date-time-format [container]="'body'"
                                     [isProviderTime]="true"
                                     [dateTimeFormat]="dateFormat"
                                     [time]="keysCreatedDate"></mbs-backup-date-time-format>
      </p>
      <mbs-input [attr.data-test-element]="elementsSelector.name.recoveryKeyControl"
                 [formControl]="recoveryKeyControl"
                 [type]="'textarea'"
                 [resize]="'none'"
                 [maxLength]="maxLengthRecoveryKeyControl"
                 [isErrorsShow]="true"
                 [rows]="7"
                 [label]="'Recovery key'"></mbs-input>

      <mbs-button (click)="triggerUploadFile()"
                  class="mt-3"
                  [icon]="'fa fa-upload'">Upload key
      </mbs-button>
      <input type="file"
             accept=".txt"
             multiple="false"
             #fileInput
             (input)="handleUploadFile($event)"
             class="d-none">
    </ng-container>
  </div>

  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                *ngIf="passwordCanBeRestored"
                [disabled]="!recoveryKeyControl.valid"
                (click)="handleRecovery()">
      {{ ('pass-recovery:two-factor-modal.recoverBtn' | i18next) }}
    </mbs-button>
    <mbs-button [type]="'secondary'"
                (click)="dismiss()">
      {{ ability.can('read', '2FAEnabled') && passwordCanBeRestored ? ('pass-recovery:two-factor-modal.cancelBtn' | i18next) :
      ('pass-recovery:two-factor-modal.closeBtn' | i18next) }}
    </mbs-button>
  </ng-container>
</mbs-modal>

<ng-template #cantDecodeTemplate>
  <ng-container *ngIf="errorDetails ;else cantDecodeTemplateUnknownReason">
    <mbs-alert class="mb-2"
               [attr.data-test-element]="elementsSelector.name.errorContainer"
               [type]="mbsPopup.danger"
               [icon]="true">
      {{ errorDetails.title }}
    </mbs-alert>
    <p class="mb-1 font-weight-semibold">
      {{ errorDetails.detail }}
    </p>
  </ng-container>
</ng-template>

<ng-template #cantDecodeTemplateUnknownReason>
  <mbs-alert class="mb-2"
             [attr.data-test-element]="elementsSelector.name.errorContainerUnknownReason"
             [type]="mbsPopup.danger"
             [icon]="true">
    {{ 'pass-recovery:cannot-recover.alert' | i18next }}
  </mbs-alert>
  <p class="mb-1 font-weight-semibold">
    {{ 'pass-recovery:cannot-recover.title' | i18next }}
  </p>
  <ul class="m-0 pl-4">
    <li>{{ 'pass-recovery:cannot-recover.solution_pass_recovery_service' | i18next }}</li>
    <li>{{ 'pass-recovery:cannot-recover.solution_backup_agent' | i18next }}</li>
  </ul>
</ng-template>
