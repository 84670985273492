import Computer, { AuthStatus, ComputerModeAdapter, ComputersHealthFilterType, ComputersMode } from '@models/Computer';
import { ComputersStoreFilters } from '@models/ComputersStoreFilters';
import { SmartSearchTemplateName } from '@modules/smart-search-templates/models/smart-search-templates-models';
import { Action, on } from '@ngrx/store';
import { isDataObsolete } from '@utils/store';
import { produce } from 'immer';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { createImmerReducer } from 'ngrx-immer/store';
import * as ComputersActions from './actions';
import { computersAdapter, ComputersState, initialComputersFilters, initialComputersPagingModeState, initialComputersState } from './state';

const reducer = createImmerReducer(
  initialComputersState,
  on(ComputersActions.loadComputerByHid, (state, action) => {
    if (!action.hid || (!!state.entities[action.hid] && !action.force) || action.quiet) return state;

    state.loading = true;

    return state;
  }),
  on(ComputersActions.setComputers, (state, action) => {
    const update = replaceEntities(action.computers, state);

    update.loading = false;

    return update;
  }),
  on(ComputersActions.setSelectedSuccess, (state, action) => {
    if (action.computer) {
      return replaceEntities(
        [action.computer],
        produce(state, (draft) => {
          draft.selected = action.computer.hid;
        })
      );
    }

    action.selected && (state.selected = action.selected);

    return state;
  }),
  on(ComputersActions.clear, (state) => {
    return computersAdapter.removeAll(state);
  }),
  on(ComputersActions.setLoading, (state, action) => {
    state.loading = action.loading;

    return state;
  }),
  on(ComputersActions.loadComputerError, (state) => {
    state.loading = false;

    return state;
  }),
  on(ComputersActions.setApplicationsCount, (state, action) => {
    state.applicationsCountInitiated = true;
    state.applicationsCount = action.count;

    return state;
  }),
  // paging
  on(ComputersActions.setMode, (state, action) => {
    const oldMode = state.mode;
    const newMode = action.mode;

    if (!Object.values(ComputersMode).includes(newMode) || (state.mode === newMode && state.modeInitiated)) return state;

    state.modeInitiated = true;
    state.mode = action.mode;

    const lastFilter = cloneDeep(state.filtersData[newMode]);

    // set filter
    state.filtersData[newMode] = {
      healthType: state.filtersData[oldMode].healthType,
      authStatus: state.filtersData[oldMode].authStatus,
      hidden: state.filtersData[oldMode].hidden,
      company: state.filtersData[oldMode].company,
      searchModel: cloneDeep(state.filtersData[oldMode].searchModel)
    } as ComputersStoreFilters;

    if (state.filtersData[newMode].searchModel[SmartSearchTemplateName.ProductInstalled]) {
      delete state.filtersData[newMode].searchModel[SmartSearchTemplateName.ProductInstalled];
    }

    const modeAgentTypes = ComputerModeAdapter.filter((data) => !!data.agentType);

    if (modeAgentTypes.map((value) => value.mode).includes(newMode)) {
      state.filtersData[newMode].searchModel[SmartSearchTemplateName.ProductInstalled] = [
        { condition: undefined, value: modeAgentTypes.find((value) => value.mode === newMode).displayAgentType }
      ];
    }

    // clear data if filter is changed
    if (!isEqual(lastFilter, state.filtersData[newMode])) {
      clearPaging(state, true);
    }

    return state;
  }),
  on(ComputersActions.loadPage, (state, action) => {
    const mode = state.mode;
    const isDataOutdated = isDataObsolete(state.data.obsolescenceMark);
    const isPagingOutdated = isDataObsolete(state.pagingData[mode].obsolescenceMark);
    let clear = false;

    state.pagingData[mode].currentPageNumber = action.pageNumber ?? state.pagingData[mode].currentPageNumber;

    if (isPagingOutdated || action.isFullRefresh) {
      clear = true;
    }

    if (action.perPage && state.pagingData[mode].perPage !== action.perPage) {
      state.pagingData[mode].perPage = action.perPage;
      state.pagingData[mode].currentPageNumber = initialComputersPagingModeState.currentPageNumber;
      clear = true;
    }

    state = clear ? clearPaging(state) : state;

    // cross copying suitable data between date and paging
    if (
      !action.isFullRefresh &&
      !isDataOutdated &&
      state.data.loaded &&
      filterIsEmpty(state, mode) &&
      isCurrentFirstPage(state, mode) &&
      !isFirstPageLoaded(state, mode) &&
      dataContainsEnoughEntities(state, mode)
    ) {
      state.pagingData[mode].pages = { ...state.pagingData[mode].pages, [1]: state.data.entities.slice(0, state.pagingData[mode].perPage) };
      state.pagingData[mode].total = state.data.total;
      state.pagingData[mode].loaded = true;
      state.pagingData[mode].obsolescenceMark = state.pagingData[mode].obsolescenceMark
        ? Math.min(state.pagingData[mode].obsolescenceMark, state.data.obsolescenceMark)
        : state.data.obsolescenceMark;
    }

    state.pagingData[mode].loading =
      !state.pagingData[mode].loaded || !state.pagingData[mode].pages[state.pagingData[mode].currentPageNumber];

    return state;
  }),
  on(ComputersActions.loadPageSuccess, (state, action) => {
    return replaceEntities(
      action.computers,
      produce(state, (draft) => {
        draft.pagingData[draft.mode].pages[action.pageNumber] = action.computers.map((c) => c.hid);
        draft.pagingData[draft.mode].total = action.total;
        draft.pagingData[draft.mode].loading = false;
        draft.pagingData[draft.mode].loaded = true;
        draft.pagingData[draft.mode].obsolescenceMark = draft.pagingData[draft.mode].obsolescenceMark ?? moment().valueOf();
      })
    );
  }),
  on(ComputersActions.loadPageError, (state) => {
    state.pagingData[state.mode].loading = false;

    return state;
  }),
  on(ComputersActions.clearPages, (state) => {
    return clearPaging(state, true);
  }),
  on(ComputersActions.setPageNumberSuccess, (state, action) => {
    state.pagingData[state.mode].loading = false;
    state.pagingData[state.mode].currentPageNumber = action.pageNumber;

    return state;
  }),
  on(ComputersActions.setSortSettings, (state, action) => {
    const mode = state.mode;

    if (isEqual(state.pagingData[mode].sort, action.sort)) return state;

    state.pagingData[mode].sort = cloneDeep(action.sort);

    return state;
  }),
  on(ComputersActions.setFilterHidden, (state, action) => {
    const mode = state.mode;

    if (state.filtersData[mode].hidden === action.hidden) return state;

    state.filtersData[mode].hidden = action.hidden;

    return state;
  }),
  on(ComputersActions.setFilterSearch, (state, action) => {
    const mode = state.mode;

    if (isEqual(state.filtersData[mode].searchModel, action.searchModel)) return state;

    state.filtersData[mode].searchModel = cloneDeep(action.searchModel);

    return state;
  }),
  on(ComputersActions.setFilterCompany, (state, action) => {
    const mode = state.mode;

    if (state.filtersData[mode].company === action.company) return state;

    state.filtersData[mode].company = action.company;

    return state;
  }),
  on(ComputersActions.setFilterHealth, (state, action) => {
    const mode = state.mode;
    const authStatus = action?.healthType === ComputersHealthFilterType.Pending ? AuthStatus.Pending : null;

    if (state.filtersData[mode].healthType === action.healthType && state.filtersData[mode].authStatus === authStatus) return state;

    state.filtersData[mode].healthType = action.healthType;
    state.filtersData[mode].authStatus = authStatus;

    return state;
  }),
  on(ComputersActions.setAllFilters, (state, action) => {
    const mode = state.mode;
    const authStatus = action?.healthType === ComputersHealthFilterType.Pending ? AuthStatus.Pending : null;
    const searchFilterChanged = (!!action.searchModel || action.apply) && !isEqual(state.filtersData[mode].searchModel, action.searchModel);
    const healthFilterChanged =
      (!!action.healthType || action.apply) &&
      (state.filtersData[mode].healthType !== action.healthType || state.filtersData[mode].authStatus !== authStatus);
    const hiddenFilterChanged = !state.filtersData[mode].hidden && !!action.hidden;
    const companyFilterChanged = (!!action.company || action.apply) && state.filtersData[mode].company !== action.company;
    const changed = searchFilterChanged || healthFilterChanged || hiddenFilterChanged || companyFilterChanged;

    if (!changed && state.filterInitiated) return state;
    state.filterInitiated = true;

    if (!changed) return state;

    if (searchFilterChanged) {
      state.filtersData[mode].searchModel = cloneDeep(action.searchModel ?? {});
    }

    if (healthFilterChanged) {
      state.filtersData[mode].healthType = action.healthType ?? ComputersHealthFilterType.All;
      state.filtersData[mode].authStatus = authStatus;
    }

    if (hiddenFilterChanged) {
      state.filtersData[mode].hidden = action.hidden;
    }

    if (companyFilterChanged) {
      state.filtersData[mode].company = action.company;
    }

    return state;
  }),

  // data
  on(ComputersActions.loadData, (state, action) => {
    const isDataOutdated = isDataObsolete(state.data.obsolescenceMark);
    const isPagingOutdated = isDataObsolete(state.pagingData[ComputersMode.Computers].obsolescenceMark);
    const allLoaded = state.data.total <= (state.data.entities || []).length;
    if (!isDataOutdated && !action.isFullRefresh && state.data.loaded && allLoaded) return state;

    if (action.isFullRefresh || isDataOutdated) {
      state.data.entities = [];
      state.data.total = initialComputersState.data.total;
      state.data.loaded = initialComputersState.data.loaded;
      state.data.obsolescenceMark = initialComputersState.data.obsolescenceMark;
    }

    // cross copying suitable data between date and paging
    if (
      !action.isFullRefresh &&
      !isPagingOutdated &&
      !state.data.loaded &&
      state.pagingData[ComputersMode.Computers].loaded &&
      filterIsEmpty(state, ComputersMode.Computers) &&
      isFirstPageLoaded(state, ComputersMode.Computers)
    ) {
      state.data.entities = [...state.pagingData[ComputersMode.Computers].pages[1]];
      state.data.total = state.pagingData[ComputersMode.Computers].total;
      state.data.loaded = true;
      state.data.obsolescenceMark = state.pagingData[ComputersMode.Computers].obsolescenceMark;
    }

    state.data.loading = !state.data.loaded || state.data.total > state.data.entities.length;

    return state;
  }),
  on(ComputersActions.loadDataSuccess, (state, action) => {
    return replaceEntities(
      action.computers,
      produce(state, (draft) => {
        draft.data = {
          ...draft.data,
          total: action.total,
          loaded: true,
          loading: false,
          entities: state.data.entities.concat(action.computers.map((entity) => computersAdapter.selectId(entity) as string)),
          obsolescenceMark: draft.data.obsolescenceMark ?? moment().valueOf()
        };
      })
    );
  }),
  on(ComputersActions.loadDataError, (state) => {
    state.data.loading = false;

    return state;
  }),
  on(ComputersActions.clearData, (state) => {
    state.data = {
      ...state.data,
      entities: [],
      total: initialComputersState.data.total,
      loaded: initialComputersState.data.loaded,
      obsolescenceMark: initialComputersState.data.obsolescenceMark
    };
    state.data.loading = initialComputersState.data.loading;

    return state;
  })
);

export function computersReducer(state: ComputersState | undefined, action: Action) {
  return reducer(state, action);
}

function replaceEntities(entities: Computer[], state: ComputersState): ComputersState {
  const newState = computersAdapter.removeMany(
    entities.map((entity) => computersAdapter.selectId(entity) as string),
    state
  );

  return computersAdapter.addMany(entities, newState);
}

function clearPaging(state: ComputersState, clearAllFields = false): ComputersState {
  const mode = state.mode;
  const oldData = state.pagingData[mode];

  state.pagingData = [...initialComputersState.pagingData];
  state.pagingData[mode] = {
    ...oldData,
    pages: {},
    total: initialComputersPagingModeState.total,
    loaded: initialComputersPagingModeState.loaded,
    obsolescenceMark: initialComputersPagingModeState.obsolescenceMark,
    ...(clearAllFields
      ? {
          currentPageNumber: initialComputersPagingModeState.currentPageNumber,
          loading: initialComputersPagingModeState.loading
        }
      : {})
  };

  return state;
}

function filterIsEmpty(state: ComputersState, mode: ComputersMode): boolean {
  return isEqual(state.filtersData[mode], initialComputersFilters);
}
function isFirstPageLoaded(state: ComputersState, mode: ComputersMode): boolean {
  return Object.keys(state.pagingData[mode].pages).some((v) => parseInt(v, 10) === 1);
}
function dataContainsEnoughEntities(state: ComputersState, mode: ComputersMode): boolean {
  return (
    state.data.entities.length >= state.pagingData[mode].perPage ||
    (state.data.total < state.pagingData[mode].perPage && state.data.entities.length === state.data.total)
  );
}
function isCurrentFirstPage(state: ComputersState, mode: ComputersMode): boolean {
  return state.pagingData[mode].currentPageNumber === 1;
}
