import { Injectable } from '@angular/core';
import RmmCommand from '@models/rmm/RmmCommand';
import RmmCommandResponse from '@models/rmm/RmmCommandResponse';
import { RmmHubCommandResponse } from '@models/rmm/RmmHubCommandResponse';
import SoftwareInfo from '@models/rmm/SoftwareInfo';
import { Observable } from 'rxjs';
import { CommandService } from '../../modules/rmm/services/rmm-command.service';

export enum CommandExecutionStatusState {
  Running = 0,
  Succeeded = 1,
  Failed = 2,
  Cancelled = 3
}

export interface CommandExecutionStatus {
  command: RmmCommand<any>;
  history: string[];
  state: CommandExecutionStatusState;
  result: object;
}

export interface UninstalResult {
  isCompletedSuccessfully: boolean;
  isRebootRequired: boolean;
  errorMessage: string;
}

@Injectable()
export class RmmSoftwareService {
  constructor(private commandService: CommandService) {}

  getInstalledSoftwareListPerMachine(hid: string): Observable<RmmCommandResponse<SoftwareInfo>> {
    const command = RmmCommand.create('GetInstalledSoftwareListPerMachine');
    return this.commandService.sendCommandAsync<SoftwareInfo>('SoftwareCmd', command, hid, false);
  }

  canUninstallProduct(hid: string, soft: SoftwareInfo): Observable<RmmCommandResponse<boolean>> {
    const command = RmmCommand.create('CanUninstallProduct')
      .addParam('identifyingNumber', soft.identifyingNumber)
      .addParam('productdisplayname', soft.name);
    return this.commandService.sendCommandAsync<boolean>('SoftwareCmd', command, hid, false);
  }

  uninstallProduct(hid: string, soft: SoftwareInfo): Observable<RmmHubCommandResponse<UninstalResult>> {
    const command = RmmCommand.create('UninstallProduct')
      .addParam('identifyingNumber', soft.identifyingNumber)
      .addParam('productdisplayname', soft.name);
    return this.commandService.sendCommandAsync<RmmHubCommandResponse<UninstalResult>>('SoftwareCmd', command, hid, true);
  }

  uninstallProductMultiple(hid: string, soft: SoftwareInfo[]): Observable<RmmHubCommandResponse<UninstalResult>> {
    const command = RmmCommand.create('UninstallSeveralProducts')
      .addParam('identifyingNumber', soft.map((item) => item.identifyingNumber).join(';'))
      .addParam('productdisplayname', soft.map((item) => item.name).join(';'));
    return this.commandService.sendCommandAsync<RmmHubCommandResponse<UninstalResult>>('SoftwareCmd', command, hid, true);
  }

  searchSoftwareUsingWinget(hid: string, softName: string): Observable<RmmHubCommandResponse<any>> {
    const command = RmmCommand.create('SearchSoftwareUsingWinget').addParam('COMMANDDATA', softName);
    return this.commandService.sendCommandAsync<RmmHubCommandResponse<any>>('SoftwareCmd', command, hid, false);
  }

  updateProduct(hid: string, soft: SoftwareInfo[], useInstallMode = false): Observable<RmmHubCommandResponse<any>> {
    const command = RmmCommand.create(useInstallMode ? 'InstallSoftwareUsingWinget' : 'UpdateSoftwareUsingWinget')
      .addParam('COMMANDDATA', soft.map((item) => item.packageID).join(';'))
      .addParam('TIMEOUT', `${1200}`);
    return this.commandService.sendCommandAsync<RmmHubCommandResponse<any>>('SoftwareCmd', command, hid, true);
  }

  getCurrentAsyncOperationStatus(hid: string, asyncId?: string): Observable<RmmCommandResponse<CommandExecutionStatus>> {
    const command = RmmCommand.create('GetCurrentAsyncOperationStatus');
    if (asyncId) {
      command.addParam('asyncID', asyncId);
    }
    return this.commandService.sendCommandAsync<CommandExecutionStatus>('SoftwareCmd', command, hid, true);
  }

  getLatestAsyncOperationStatus(hid: string): Observable<RmmCommandResponse<CommandExecutionStatus>> {
    const command = RmmCommand.create('GetLatestAsyncOperationStatus');
    return this.commandService.sendCommandAsync<CommandExecutionStatus>('SoftwareCmd', command, hid, false);
  }

  tryCancelAsyncOperation(hid: string, asyncId: string): Observable<RmmCommandResponse<boolean>> {
    const command = RmmCommand.create('TryCancelAsyncOperation').addParam('asyncID', asyncId);
    return this.commandService.sendCommandAsync<boolean>('SoftwareCmd', command, hid, false);
  }
}
