<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ (isRestore ? 'wizards:advanced_schedule_restore_title' : 'wizards:advanced_schedule_title') | i18next: { format: 'title' } }}
    </h4>
    <div class="mbs-wizard_form-description">
      {{ 'wizards:advanced_schedule_subtitle' | i18next: { type: isRestore ? 'restore' : 'backup' } }}
    </div>
  </header>

  <div class="mbs-wizard_form-content pb-5">

    <mbs-form-group [class.mb-5]="!isBackupPlan || isLinux">
      <mbs-accordion [closeOthers]="false"
                     [size]="'sm'"
                     (panelChange)='togglePanel($event.nextState, $event.panelId)'>
        <mbs-accordion-panel *ngFor="let name of panelNames"
                             [disabled]="(name !== 'forceFull' && !stepForm.get('forceFull').value.Enabled) || stepForm.get(name).disabled || !stepForm.get(name).value.Enabled"
                             [id]="name">
          <ng-container panelTitle>
            <span class="d-block">
              {{getPanelTitle(name)}}
            </span>
            <span *ngIf="panelsInfo[name].infoString"
                  class="text-xs font-weight-normal d-block">
              {{panelsInfo[name].infoString}}
            </span>
          </ng-container>
          <div class="d-flex justify-content-end pr-3"
               [formGroupName]="name"
               panelAppendHeader>
            <mbs-switcher formControlName="Enabled"
                          class="pt-1"
                          [id]="name + '-switcher'"
                          (change)="switcherChangeHandler($event.target.checked, name)"></mbs-switcher>
          </div>

          <mbs-form-group class="mb-0"
                          [formGroup]="scheduleSettingsForm">
            <div class="row"
                 [formGroupName]="name">
              <div class="col-6">

                <mbs-form-group class="mb-0">
                  <h6 class="mbs-form-group_title">
                    {{'wizards:frequency_label' | i18next}}
                  </h6>

                  <mbs-form-group>
                    <mbs-select formControlName="RecurringPeriod"
                                (change)="recurringPeriodChangeHandler($event, name)"
                                [items]="RecurringPeriodItems"
                                [clearable]="false"
                                [searchable]="false"
                                [id]="name + '-RecurringPeriod'"></mbs-select>
                  </mbs-form-group>

                  <mbs-form-group *ngIf="scheduleSettingsForm.get(name).value.RecurringPeriod === 'Monthly'">
                    <div class="form-row">
                      <div class="col-6">
                        <mbs-select formControlName="Occurrence"
                                    [label]="'wizards:occurrence_label' | i18next"
                                    [appendTo]="'.mbs-wizard-steps_container'"
                                    [bindValue]="'value'"
                                    [bindLabel]="'label'"
                                    [clearable]="false"
                                    [hideSelected]="true"
                                    [id]="name + '-occurrence-select'"
                                    [items]="WeekNumberEnumArray"
                                    [searchable]="false"></mbs-select>
                      </div>
                      <div class="col-6">
                        <mbs-number *ngIf="scheduleSettingsForm.get(name).value.Occurrence === -1"
                                    formControlName="DayOfMonthCount"
                                    min="1"
                                    max="31"
                                    [label]="'wizards:day_label' | i18next"
                                    [id]="name + 'DayOfMonthCount'"></mbs-number>
                        <mbs-select *ngIf="scheduleSettingsForm.get(name).value.Occurrence !== -1"
                                    formControlName="DayOfWeek"
                                    [label]="'wizards:day_label' | i18next"
                                    [appendTo]="'.mbs-wizard-steps_container'"
                                    [bindValue]="'value'"
                                    [bindLabel]="'label'"
                                    [clearable]="false"
                                    [hideSelected]="true"
                                    [id]="name + '-DayOfWeek-select'"
                                    [items]="DaysOfWeekEnumArray"
                                    [searchable]="false"></mbs-select>
                      </div>
                    </div>
                  </mbs-form-group>

                  <mbs-form-group *ngIf="!isLinux || scheduleSettingsForm.get(name).value.RecurringPeriod === 'Monthly'"
                                  [class.mb-0]="scheduleSettingsForm.get(name).value.RecurringPeriod !== 'Weekly'">
                    <div class="form-row">
                      <div class="col-6">
                        <mbs-input-label [label]="'wizards:repeat_every_label' | i18next">
                        </mbs-input-label>
                        <div class="form-row align-items-center">
                          <div class="col-6">
                            <mbs-number formControlName="RepeatEveryCount"
                                        min="1"
                                        [max]="scheduleSettingsForm.get(name).value.RecurringPeriod === 'Daily'
                                          ? 31 : scheduleSettingsForm.get(name).value.RecurringPeriod === 'Weekly'
                                            ? 52 : scheduleSettingsForm.get(name).value.RecurringPeriod === 'Monthly'
                                              ? 12 : 99"
                                        [id]="name + 'repeat-every-count'"></mbs-number>
                          </div>
                          <span class="col-auto">
                            <label [for]="name + 'repeat-every-count'"
                                   class="mbs-form_label mb-0">
                              {{getCounterText(name)}}
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="col-6">
                        <mbs-datepicker formControlName="StartFromDate"
                                        [needSetCurrent]="true"
                                        [label]="'wizards:start_from_label' | i18next"></mbs-datepicker>
                      </div>
                    </div>
                  </mbs-form-group>

                  <mbs-form-group *ngIf="scheduleSettingsForm.get(name).value.RecurringPeriod === 'Weekly'"
                                  class="mb-0">
                    <div formArrayName="WeeklyDayOfWeek"
                         class="d-flex align-items-center">
                      <mbs-checkbox *ngFor="let day of DaysOfWeekEnumArray; let i = index; let len = count"
                                    class="mt-0"
                                    [class.mr-3]="i !== len - 1"
                                    [formControlName]="i.toString()"
                                    [label]="day.label.slice(0,3)"
                                    [labelPosition]="'top'"
                                    [centerCheckbox]="true"></mbs-checkbox>
                    </div>
                  </mbs-form-group>
                </mbs-form-group>
              </div>

              <div class="col-6">

                <h6 class="mbs-form-group_title">
                  {{'wizards:daily_frequency_label' | i18next}}
                </h6>

                <mbs-form-group>
                  <mbs-radio formControlName="DailyFreqPeriodOption"
                             value="OccursAt"
                             [name]="name + '-dailyFreqPeriodOption'"
                             [label]="'wizards:occurs_at_label' | i18next"
                             (change)="dailyFreqPeriodChangeHandler($event, name)"
                             [descriptionOutside]="false"
                             [id]="name + '-occurs-at-radio-1'">
                    <ng-template radioDescription>
                      <div class="form-row">
                        <div class="col-7">
                          <mbs-timepicker formControlName="OccursAtTime"
                                          [meridian]="true"
                                          [needChangeInvalid]="true"></mbs-timepicker>
                        </div>
                      </div>
                    </ng-template>
                  </mbs-radio>
                </mbs-form-group>

                <mbs-form-group>
                  <mbs-radio formControlName="DailyFreqPeriodOption"
                             value="OccursEvery"
                             [name]="name + '-dailyFreqPeriodOption'"
                             [label]="'wizards:occurs_every_label' | i18next"
                             (change)="dailyFreqPeriodChangeHandler($event, name)"
                             [descriptionOutside]="false"
                             [id]="name + '-occurs-at-radio-2'">
                    <ng-template radioDescription>
                      <mbs-form-group>
                        <div class="form-row">
                          <div class="col-4">
                            <mbs-number formControlName="OccursEveryCount"
                                        [max]="scheduleSettingsForm.get(name).value.OccursEveryPeriod === 'hours' ? maxHours : maxMinutes"
                                        min="1"></mbs-number>
                          </div>
                          <div class="col-6">
                            <mbs-select formControlName="OccursEveryPeriod"
                                        (change)="occursEveryPeriodChangeHandler($event, name)"
                                        [items]="hoursOrMinutesItems"
                                        [bindLabel]="'label'"
                                        [bindValue]="'value'"
                                        [searchable]="false"
                                        [clearable]="false"></mbs-select>
                          </div>
                        </div>
                      </mbs-form-group>

                      <mbs-form-group class="mb-0">
                        <div class="form-row align-items-center">
                          <div class="col-6">
                            <mbs-timepicker formControlName="OccursEveryFromTime"
                                            [id]="name + 'occurs-every-from-time'"
                                            [label]="'wizards:big_from_label' | i18next"
                                            [meridian]="true"
                                            [needChangeInvalid]="true"></mbs-timepicker>
                          </div>
                          <div class="col-6">
                            <mbs-timepicker formControlName="OccursEveryToTime"
                                            [id]="name + 'occurs-every-to-time'"
                                            [label]="'wizards:big_to_label' | i18next"
                                            [meridian]="true"
                                            [needChangeInvalid]="true"></mbs-timepicker>
                          </div>
                        </div>
                      </mbs-form-group>
                    </ng-template>
                  </mbs-radio>
                </mbs-form-group>
              </div>
            </div>
          </mbs-form-group>
        </mbs-accordion-panel>
      </mbs-accordion>
    </mbs-form-group>

    <mbs-form-group *ngIf="isBackupPlan && !isLinux"
                    class="mb-0">
      <mbs-checkbox formControlName="ForceFullApplyDiffSizeCondition"
                    [label]="'wizards:run_incremental_label' | i18next"
                    [id]="'force-full-apply-diff-size-condition-checkbox'"
                    [descriptionOutside]="false">
        <ng-template checkboxDescription>
          <div class="form-row align-items-center">
            <div class="col-2">
              <mbs-number formControlName="ForceFullDiffSizeCondition"
                          min="1"
                          [id]="'force-full-diff-size-condition'"></mbs-number>
            </div>
            <div class="col-auto">
              <label for="force-full-diff-size-condition"
                     class="mbs-form_label mb-0"
                     [class.-disabled]="!stepForm.get('ForceFullApplyDiffSizeCondition').value">
                {{'wizards:incremental_size_label' | i18next}}
              </label>
            </div>
          </div>
        </ng-template>
      </mbs-checkbox>
    </mbs-form-group>

  </div>
</section>
