import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';
import { ComputersEffects } from './effects';
import { computersReducer } from './reducer';

@NgModule({
  imports: [StoreModule.forFeature(StoreTypes.Computers, computersReducer), EffectsModule.forFeature([ComputersEffects])]
})
export class ComputersStoreModule {}
