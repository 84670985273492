import { StorageType } from '@models/StorageType.enum';
import { HyperVFormModel } from '@modules/wizards/models/HyperV/hyper-v-form-model';
import { GuidEmpty } from 'mbs-ui-kit';
import { BaseForStepsHelper, WizardHelpersSettings } from '../bases/base-for-steps-helper';
import { StepsDataFromPlanHelper } from '../steps-data-from-plan-helper/steps-data-from-plan-helper';
import { SupportMethodsForStepsFromPlan } from '../support-methods-for-steps-from-plan';

export class HyperVModelForStepsHelper extends BaseForStepsHelper {
  public static HyperVModel: HyperVFormModel = null;

  public static HyperVModelFromPlan(plan, settings: WizardHelpersSettings = this.DefaultSettings): HyperVFormModel {
    const newPlan = plan && (plan.plan || plan.Plan);
    if (newPlan) {
      this.setThisValues(plan, settings);
      this.setStorageType(settings.mode, newPlan.HybridID, newPlan.ConnectionID);
      this.HyperVModel = new HyperVFormModel(this.isLinux);

      this.updatePlanNameStep(newPlan, settings.compName);
      this.updateWhereBackupStep(newPlan);

      this.HyperVModel.selectVirtualMachinesStep = StepsDataFromPlanHelper.updateSelectVirtualMachines(newPlan);
      this.HyperVModel.appAwareStep = StepsDataFromPlanHelper.updateSelectAppAware(newPlan);
      this.HyperVModel.advancedSettingsStep = StepsDataFromPlanHelper.updateAdvancedSettingsVirtual(
        newPlan,
        this.storage,
        this.isCreate
      );
      this.HyperVModel.selectVirtualDisksStep = StepsDataFromPlanHelper.updateSelectVirtualDisks(newPlan);

      this.HyperVModel.archiveConsistencyStep = StepsDataFromPlanHelper.updateArchiveConsistency(newPlan);
      this.HyperVModel.compressionEncryptionStep = StepsDataFromPlanHelper.updateCompressionEncryption(
        newPlan,
        true,
        this.isLinux,
        this.isCreate,
        true
      );
      this.HyperVModel.scheduleStep = StepsDataFromPlanHelper.updateNBFSchedule(
        newPlan,
        plan.OverdueAfterSuccess,
        this.isCreate,
        this.isLinux,
        settings.isNBF,
        settings.validVersionForSchedule
      );
      this.bucketId = this.accounts && this.isRD ? this.getBucketId(newPlan.Bucket, newPlan.SGAccountID) : newPlan.ConnectionID;
      this.HyperVModel.retentionPolicyStep = StepsDataFromPlanHelper.updateNBFRetentionPolicy(
        newPlan,
        SupportMethodsForStepsFromPlan.needShowImmutability(this.storages.CloudStorages, this.bucketId),
        this.isCreate
      );
      this.HyperVModel.prePostActionsStep = StepsDataFromPlanHelper.updatePrePostActions(newPlan);
      this.HyperVModel.notificationStep = StepsDataFromPlanHelper.updateNotification(
        newPlan.Notification,
        newPlan.WindowsEventLogNotification,
        this.isLinux
      );

      if (!this.isLinux) {
        this.HyperVModel.AvailableMachinePlans = StepsDataFromPlanHelper.updateAvailableMachinePlans(
          plan.AvailableMachinePlans,
          newPlan.ID
        );
      }
    }
    return this.HyperVModel;
  }

  public static updatePlanNameStep(newPlan, compName: string): void {
    const name = newPlan.Name || SupportMethodsForStepsFromPlan.generatePlanName(compName, this.planType, this.timeZoneOffset);
    this.HyperVModel.planNameStep = StepsDataFromPlanHelper.updatePlanNameStep(name, newPlan.SavePlanInCloud, true);
  }
  public static updateWhereBackupStep(newPlan): void {
    if (!this.accounts) {
      this.HyperVModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(false, newPlan.HybridID, newPlan.ConnectionID);
    } else {
      const id = this.bucketId || this.getBucketId(newPlan.Bucket, newPlan.SGAccountID);
      this.HyperVModel.whereBackupStep = StepsDataFromPlanHelper.updateWhereBackup(false, newPlan.HybridID, id || newPlan.ConnectionID);
      if (id && id !== GuidEmpty) {
        const storage = this.storages.CloudStorages.find((s) => s.ID === id) || this.storages.LocalStorages.find((s) => s.ID === id);
        this.storageType = storage ? storage.StorageType : this.storageType;
      }
    }
    if (!this.isRD) SupportMethodsForStepsFromPlan.getFilteredStoragesByTypes(this.storages, [StorageType.OpenStack]);
    this.HyperVModel.Storages = this.storages;
    this.HyperVModel.StoragesArray = [...this.storages.CloudStorages, ...this.storages.LocalStorages];
  }
}
