import { DayOfWeek } from '@models/DayOfWeek.enum';
import { RecurType } from '@models/RecurType.enum';
import { ScheduleType } from '@models/schedule-type.enum';
import { RecurringSchedule, ScheduleModel } from '@models/ScheduleModel';
import { ScheduleSettings } from '@models/ScheduleSettings';
import { WeekNumber } from '@models/WeekNumber.enum';
import * as moment from 'moment';
import { FullBackupData, IncrementalData, ScheduleStepValue } from '@modules/wizards/models/schedule-models';
import { TimeSpanMaxValue } from './constants/c-sharp-constants';
import { GetDateFromString, mediumDateWithoutTimeMoment, mediumDateWithTimeMoment } from './date';
import { DateTimeUtils } from './DateTimeUtils';

export class ScheduleDataHelper {
  static dayOfMonthLiteral = 'Day Of Month';

  static GetScheduleModelFromScheduleSettings(Schedule: ScheduleSettings): ScheduleModel {
    const model = new ScheduleModel();
    model.RecurringSchedule.DailyFrequency.StartFromDate = new Date();
    model.RecurringSchedule.WeeklyFrequency.StartFromDate = new Date();
    model.RecurringSchedule.MonthlyFrequency.StartFromDate = new Date();
    model.RecurringSchedule.YearlyFrequency.StartFromDate = new Date();
    model.RecurringSchedule.WeeklyFrequency.RepeatEveryCount = 1;
    model.RecurringSchedule.MonthlyFrequency.RepeatEveryCount = 1;
    model.RecurringSchedule.YearlyFrequency.RepeatEveryCount = 1;
    model.RecurringSchedule.DailyFrequency.RepeatEveryCount = 1;

    if (Schedule.Enabled) {
      switch (Schedule.RecurType) {
        case RecurType.Once:
          model.ScheduleType = ScheduleType.once;
          model.SpecificDateSchedule.OnceDate = GetDateFromString(Schedule.OnceDate);
          break;
        // Recurring - Frequency Type
        case RecurType.Daily:
        case RecurType.Weekly:
        case RecurType.Monthly:
        case RecurType.DayOfMonth:
        case RecurType.Yearly:
          model.ScheduleType = ScheduleType.recurring;
          // Schedule_RecurringFrequency_dtYear.SetTime(schedule.OnceDate);
          break;
        case RecurType.Periodically:
          model.ScheduleType = ScheduleType.predefined;
          // Schedule_RecurringFrequency_dtYear.SetTime(schedule.OnceDate);
          break;
      }
    } else {
      model.ScheduleType = ScheduleType.noschedule;
    }

    model.RecurringSchedule = this.generateNewRecurringSchedule(Schedule);

    return model;
  }

  public static generateNewRecurringSchedule(schedule: ScheduleSettings, isLinux = false): RecurringSchedule {
    let recurringSchedule = new RecurringSchedule(isLinux);
    if (!isLinux || schedule.RecurType === RecurType.DayOfMonth || schedule.RecurType === RecurType.Monthly) {
      for (const key in recurringSchedule) {
        if (key !== 'RecurringPeriod') {
          recurringSchedule[key].StartFromDate = schedule.OnceDate ? GetDateFromString(schedule.OnceDate) : new Date();
          recurringSchedule[key].RepeatEveryCount = schedule.RepeatEvery || 1;
        }
      }
    }
    if (isLinux && schedule.RecurType === RecurType.Yearly) schedule.RecurType = RecurType.Daily;

    recurringSchedule.WeeklyFrequency.DaysOfWeek.push(...(schedule.WeekDays || []));
    recurringSchedule.MonthlyFrequency.OccurrencePeriod = schedule.WeekNumber;
    recurringSchedule.MonthlyFrequency.DayOfWeek = schedule.DayOfWeek;
    recurringSchedule.MonthlyFrequency.DayOfMonthCount = schedule.DayOfMonth;

    recurringSchedule = this.updateNewRecurringScheduleByRecurType(recurringSchedule, schedule);

    recurringSchedule.DailyFrequency.OccursAtTime = this.getOccursWithHourAndMinutes(schedule.Hour, schedule.Minutes);
    recurringSchedule.DailyFrequency.OccursEveryFromTime = this.getOccursWithHourAndMinutes(
      schedule.DailyFromHour,
      schedule.DailyFromMinutes
    );
    recurringSchedule.DailyFrequency.OccursEveryToTime = this.getOccursWithHourAndMinutes(
      schedule.DailyTillHour,
      schedule.DailyTillMinutes
    );
    return recurringSchedule;
  }

  public static updateOverdueFieldsOnStep(step: ScheduleStepValue, overdueAfterSuccess, isCreate = true): void {
    if (!isCreate && overdueAfterSuccess) {
      step.overdueAfterSuccessEnabled = overdueAfterSuccess.Enabled;
      step.overdueAfterSuccessAmount = overdueAfterSuccess.Amount;
      step.overdueAfterSuccessPeriod = overdueAfterSuccess.Period;
    } else {
      step.overdueAfterSuccessEnabled = false;
      step.overdueAfterSuccessAmount = 7;
      step.overdueAfterSuccessPeriod = 1;
    }
  }

  public static getOccursWithHourAndMinutes(hour: number, minutes: number): string {
    const occursAtDate = new Date();
    occursAtDate.setHours(hour, minutes);
    return DateTimeUtils.GetTime24FormatFromDate(occursAtDate);
  }

  public static getIncrementalSchedule(incremental, validVersionForSchedule = false, isLinux = false, isFFI = false): IncrementalData {
    const isHours = incremental.DailyRecurrencePeriod % 60 === 0;
    const weeks = incremental.WeekDays
      ? Object.keys(DayOfWeek)
          .filter((v) => !isNaN(+v))
          .map((i) => incremental.WeekDays.includes(+i))
      : [];
    const formData: IncrementalData = {
      recurringPeriod: incremental.RecurType === RecurType.DayOfMonth || incremental.RecurType === RecurType.Monthly ? 'Monthly' : 'Daily',
      weeklyDayOfWeek: weeks || [],
      occurrence: incremental.RecurType === RecurType.DayOfMonth ? -1 : incremental.WeekNumber,
      dayOfWeek: incremental.DayOfWeek,
      dayOfMonthCount: incremental.RecurType === RecurType.DayOfMonth ? incremental.DayOfMonth : 1,
      dailyFreqPeriodOption:
        incremental.RecurType === RecurType.Periodically || (incremental.RecurType && incremental.DailyRecurrence)
          ? 'OccursEvery'
          : 'OccursAt',
      occursAtTime: ScheduleDataHelper.getOccursWithHourAndMinutes(incremental.Hour || 0, incremental.Minutes || 0),
      occursEveryCount: isHours ? incremental.DailyRecurrencePeriod / 60 : incremental.DailyRecurrencePeriod,
      occursEveryPeriod: isHours ? 'hours' : 'minutes',
      occursEveryFromTime: ScheduleDataHelper.getOccursWithHourAndMinutes(incremental.DailyFromHour || 0, incremental.DailyFromMinutes || 0),
      occursEveryToTime: ScheduleDataHelper.getOccursWithHourAndMinutes(incremental.DailyTillHour || 0, incremental.DailyTillMinutes || 0)
    };
    if (validVersionForSchedule && (!isLinux || isFFI || formData.recurringPeriod === 'Monthly')) {
      formData.repeatEveryCount = incremental.RepeatEvery || 1;
      formData.startFromDate = incremental.OnceDate ? GetDateFromString(incremental.OnceDate) : new Date();
    }
    return formData;
  }

  public static getFullSchedule(full, validVersionForSchedule = false, isLinux = false): FullBackupData {
    if (full && full.Enabled) {
      const isDayOfWeek = full.WeekNumber === WeekNumber.First && full.DayOfWeek === 0 && full.DayOfMonth;
      const weeks = Object.keys(DayOfWeek)
        .filter((v) => !isNaN(+v))
        .map((i) => (full.WeekDays ? full.WeekDays.includes(+i) : false));
      const formData: FullBackupData = {
        enabled: true,
        recurringPeriod: full.RecurType === RecurType.DayOfMonth || full.RecurType === RecurType.Monthly ? 'Monthly' : 'Daily',
        weeklyDayOfWeek: weeks || [],
        occurrence: isDayOfWeek ? WeekNumber[this.dayOfMonthLiteral] : full.WeekNumber,
        dayOfWeek: full.DayOfWeek,
        dayOfMonthCount: full.DayOfMonth,
        dailyFreqPeriodOption: 'OccursAt',
        occursAtTime: ScheduleDataHelper.getOccursWithHourAndMinutes(full.Hour, full.Minutes)
      };
      if (validVersionForSchedule && (!isLinux || formData.recurringPeriod === 'Monthly')) {
        formData.repeatEveryCount = full.RepeatEvery || 1;
        formData.startFromDate = full.OnceDate ? GetDateFromString(full.OnceDate) : new Date();
      }
      return formData;
    } else {
      const formData: FullBackupData = {
        enabled: false,
        recurringPeriod: 'Monthly',
        weeklyDayOfWeek: [],
        occurrence: 0,
        dayOfWeek: 3,
        dayOfMonthCount: 1,
        dailyFreqPeriodOption: 'OccursAt',
        occursAtTime: '00:00'
      };
      if (validVersionForSchedule) {
        formData.repeatEveryCount = full.RepeatEvery || 1;
        formData.startFromDate = full.OnceDate ? GetDateFromString(full.OnceDate) : new Date();
      }
      return formData;
    }
  }

  public static updateNewRecurringScheduleByRecurType(recurringSchedule: RecurringSchedule, schedule: any): RecurringSchedule {
    if (schedule.RecurType === RecurType.DayOfMonth) {
      recurringSchedule.DailyFrequency.PeriodOption = schedule.RecurType && schedule.DailyRecurrence ? 'OccursEvery' : 'OccursAt';
      recurringSchedule.DailyFrequency.OccursEveryCount =
        schedule.DailyRecurrencePeriod % 60 === 0 ? schedule.DailyRecurrencePeriod / 60 : schedule.DailyRecurrencePeriod;
      recurringSchedule.DailyFrequency.OccursEveryPeriod = schedule.DailyRecurrencePeriod % 60 === 0 ? 'hours' : 'minutes';
      recurringSchedule.RecurringPeriod = RecurType.Monthly;
      recurringSchedule.MonthlyFrequency.OccurrencePeriod = WeekNumber['Day Of Month'];
    } else {
      recurringSchedule.RecurringPeriod = typeof schedule.RecurType === 'number' ? schedule.RecurType : RecurType[schedule.RecurType];
      recurringSchedule.MonthlyFrequency.RepeatEveryCount = schedule.RepeatEvery;
      if (schedule.RecurType === RecurType.Periodically) {
        recurringSchedule.DailyFrequency.PeriodOption = 'OccursEvery';
        recurringSchedule.DailyFrequency.OccursEveryDuration = moment.duration(schedule.Hour, 'hours').add(schedule.Minutes, 'minutes');
      } else {
        recurringSchedule.DailyFrequency.PeriodOption = schedule.RecurType && schedule.DailyRecurrence ? 'OccursEvery' : 'OccursAt';
        recurringSchedule.DailyFrequency.OccursEveryCount =
          schedule.DailyRecurrencePeriod % 60 === 0 ? schedule.DailyRecurrencePeriod / 60 : schedule.DailyRecurrencePeriod;
        recurringSchedule.DailyFrequency.OccursEveryPeriod = schedule.DailyRecurrencePeriod % 60 === 0 ? 'hours' : 'minutes';
      }
    }
    return recurringSchedule;
  }

  static SetRecurringScheduleSettings(Schedule: ScheduleSettings, RecurringSchedule: RecurringSchedule, isLinux = false): void {
    Schedule.Enabled = true;
    Schedule.RecurType = RecurringSchedule.RecurringPeriod;
    if (RecurringSchedule.RecurringPeriod == RecurType.Daily && !isLinux) {
      Schedule.RepeatEvery = RecurringSchedule.DailyFrequency.RepeatEveryCount;
      Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(RecurringSchedule.DailyFrequency.StartFromDate);
    }
    if (RecurringSchedule.RecurringPeriod == RecurType.Weekly) {
      if (!isLinux) {
        Schedule.RepeatEvery = RecurringSchedule.WeeklyFrequency.RepeatEveryCount;
        Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(RecurringSchedule.WeeklyFrequency.StartFromDate);
      }
      Schedule.WeekDays = [];
      Schedule.WeekDays.push(...RecurringSchedule.WeeklyFrequency.DaysOfWeek);
    }
    if (RecurringSchedule.RecurringPeriod == RecurType.Monthly) {
      Schedule.RepeatEvery = RecurringSchedule.MonthlyFrequency.RepeatEveryCount;
      Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(RecurringSchedule.MonthlyFrequency.StartFromDate);
      Schedule.WeekNumber = RecurringSchedule.MonthlyFrequency.OccurrencePeriod;
      Schedule.DayOfWeek = RecurringSchedule.MonthlyFrequency.DayOfWeek;
      if (Schedule.WeekNumber == WeekNumber[this.dayOfMonthLiteral]) RecurringSchedule.RecurringPeriod = RecurType.DayOfMonth;
    }
    if (RecurringSchedule.RecurringPeriod == RecurType.DayOfMonth) {
      Schedule.RepeatEvery = RecurringSchedule.MonthlyFrequency.RepeatEveryCount;
      Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(RecurringSchedule.MonthlyFrequency.StartFromDate);
      Schedule.WeekNumber = WeekNumber.First;
      Schedule.DayOfWeek = 0;
      Schedule.DayOfMonth = RecurringSchedule.MonthlyFrequency.DayOfMonthCount;
      Schedule.RecurType = RecurType.DayOfMonth;
    }
    if (RecurringSchedule.RecurringPeriod == RecurType.Yearly && !isLinux) {
      Schedule.RepeatEvery = RecurringSchedule.YearlyFrequency.RepeatEveryCount;
      Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(RecurringSchedule.YearlyFrequency.StartFromDate);
    }

    if (RecurringSchedule.RecurringPeriod == RecurType.Periodically) {
      Schedule.DailyFromHour = 0;
      Schedule.DailyFromMinutes = 0;
      Schedule.DailyRecurrence = false;
      Schedule.DailyRecurrencePeriod = 120;
      Schedule.DailyTillHour = 23;
      Schedule.DailyTillMinutes = 59;
      Schedule.DayOfMonth = 0;
      Schedule.DayOfWeek = 0;
      Schedule.Hour = Math.floor(RecurringSchedule.DailyFrequency.OccursEveryDuration.asHours());
      Schedule.Minutes = Math.floor(RecurringSchedule.DailyFrequency.OccursEveryDuration.minutes());
      Schedule.Seconds = 0;
      if (!isLinux) Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(RecurringSchedule.DailyFrequency.StartFromDate);
    } else {
      Schedule.DailyRecurrence = RecurringSchedule.DailyFrequency.PeriodOption == 'OccursEvery';
      const occursAtTime = DateTimeUtils.GetDateFromTimeString(RecurringSchedule.DailyFrequency.OccursAtTime);
      Schedule.Hour = occursAtTime.getHours();
      Schedule.Minutes = occursAtTime.getMinutes();

      let dailyRecurrencePeriod = RecurringSchedule.DailyFrequency.OccursEveryCount;
      if (RecurringSchedule.DailyFrequency.OccursEveryPeriod == 'hours') dailyRecurrencePeriod = dailyRecurrencePeriod * 60;

      Schedule.DailyRecurrencePeriod = dailyRecurrencePeriod || 0;

      if (!Schedule.DailyRecurrence) {
        Schedule.DailyFromHour = 8;
        Schedule.DailyFromMinutes = 0;
        Schedule.DailyTillHour = 6;
        Schedule.DailyTillMinutes = 0;
      } else {
        const occursEveryFrom = DateTimeUtils.GetDateFromTimeString(RecurringSchedule.DailyFrequency.OccursEveryFromTime);
        Schedule.DailyFromHour = occursEveryFrom.getHours();
        Schedule.DailyFromMinutes = occursEveryFrom.getMinutes();

        const occursEveryTo = DateTimeUtils.GetDateFromTimeString(RecurringSchedule.DailyFrequency.OccursEveryToTime);
        Schedule.DailyTillHour = occursEveryTo.getHours();
        Schedule.DailyTillMinutes = occursEveryTo.getMinutes();
      }
    }
  }

  static SetArchiveSchedules(Schedule: ScheduleSettings, step: ScheduleStepValue, isIncremental = true, isLinux = false): void {
    const data = isIncremental ? step.incrementalData : step.fullBackupData;
    let recurType = RecurType[data.recurringPeriod];
    Schedule.Enabled = true;
    Schedule.RepeatEvery = 1;
    Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(new Date());
    if (!isLinux && !(data.repeatEveryCount === null || data.repeatEveryCount === undefined)) {
      Schedule.RepeatEvery = data.repeatEveryCount;
      Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(data.startFromDate);
    }
    if (recurType === RecurType.Daily) {
      Schedule.RecurType = RecurType.Weekly;
      Schedule.WeekDays = [];
      data.weeklyDayOfWeek.forEach((day, idx) => day && Schedule.WeekDays.push(idx));
    }
    if (recurType === RecurType.Monthly) {
      Schedule.RecurType = RecurType.Monthly;
      Schedule.WeekNumber = data.occurrence;
      Schedule.DayOfWeek = data.dayOfWeek;
      if (Schedule.WeekNumber === WeekNumber[this.dayOfMonthLiteral]) recurType = RecurType.DayOfMonth;
      if (isLinux) {
        Schedule.RepeatEvery = data.repeatEveryCount;
        Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(data.startFromDate);
      }
    }
    if (recurType === RecurType.DayOfMonth) {
      Schedule.WeekNumber = WeekNumber.First;
      Schedule.DayOfWeek = 0;
      Schedule.DayOfMonth = data.dayOfMonthCount;
      Schedule.RecurType = RecurType.DayOfMonth;
    }

    if (data.dailyFreqPeriodOption === 'OccursAt') {
      Schedule.DailyRecurrence = false;
      Schedule.DailyFromHour = 0;
      Schedule.DailyFromMinutes = 0;
      Schedule.DailyTillHour = 0;
      Schedule.DailyTillMinutes = 0;
      const duration = moment.duration(data.occursAtTime);
      Schedule.Hour = duration.hours();
      Schedule.Minutes = duration.minutes();
    } else if (isIncremental && data.dailyFreqPeriodOption === 'OccursEvery') {
      const newData = data as IncrementalData;
      Schedule.DailyRecurrence = true;
      Schedule.DailyRecurrencePeriod = newData.occursEveryPeriod === 'hours' ? newData.occursEveryCount * 60 : newData.occursEveryCount;

      const fromDuration = moment.duration(newData.occursEveryFromTime);
      Schedule.DailyFromHour = fromDuration.hours();
      Schedule.DailyFromMinutes = fromDuration.minutes();

      const toDuration = moment.duration(newData.occursEveryToTime);
      Schedule.DailyTillHour = toDuration.hours();
      Schedule.DailyTillMinutes = toDuration.minutes();
    }
  }

  public static SetRecurringEmptySchedule(Schedule: ScheduleSettings, isLinux = false): void {
    if (!Schedule) Schedule = new ScheduleSettings();
    Schedule.Enabled = false;
    Schedule.Hour = 0;
    Schedule.Minutes = 0;
    Schedule.IsScheduled = false;
    Schedule.RecurType = RecurType.Once;
    Schedule.Seconds = 0;
    Schedule.StopAfter = TimeSpanMaxValue;
    Schedule.WeekDays = [];
    Schedule.WeekNumber = WeekNumber.First;
    Schedule.DayOfMonth = 0;
    Schedule.DailyFromHour = 0;
    Schedule.DailyFromMinutes = 0;
    Schedule.DailyRecurrence = false;
    Schedule.DailyRecurrencePeriod = 120;
    Schedule.DailyTillHour = 23;
    Schedule.DailyTillMinutes = 59;
    Schedule.DayOfWeek = DayOfWeek.Sunday;
    if (!isLinux) {
      Schedule.OnceDate = DateTimeUtils.GetDateStringWithOffsetFromUTC(new Date());
      Schedule.RepeatEvery = 1;
    }
  }

  static GetRecurringScheduleSummary(schedule: RecurringSchedule): string {
    let summary = '';
    if (schedule) {
      summary = 'Occurs Every ';
      switch (schedule.RecurringPeriod) {
        case RecurType.Daily:
          summary += schedule.DailyFrequency.RepeatEveryCount + ' Day' + (schedule.DailyFrequency.RepeatEveryCount > 1 ? 's' : '') + ' ';
          break;
        case RecurType.DayOfMonth:
          summary += this.GetOccursEveryDayOfMonthRecurTypeRecurringScheduleSummaryPart(schedule);
          break;
        case RecurType.Monthly:
          summary += this.GetOccursEveryMonthlyRecurTypeRecurringScheduleSummaryPart(schedule);
          break;
        case RecurType.Weekly:
          summary +=
            schedule.WeeklyFrequency.RepeatEveryCount +
            ' Week' +
            (schedule.WeeklyFrequency.RepeatEveryCount > 1 ? 's' : '') +
            ' on ' +
            schedule.WeeklyFrequency.DaysOfWeek.map((x) => DayOfWeek[x]).join(', ') +
            ' ';
          break;
        case RecurType.Yearly:
          summary += schedule.YearlyFrequency.RepeatEveryCount + ' Year' + (schedule.YearlyFrequency.RepeatEveryCount > 1 ? 's' : '') + ' ';
          break;
      }

      summary += this.GetOccurencyRecurringScheduleSummaryPart(schedule);

      summary += ' Starting from ';

      summary += this.GetStartingFromRecurringSummaryPart(schedule);
    }

    return summary;
  }

  private static GetOccursEveryMonthlyRecurTypeRecurringScheduleSummaryPart(schedule: RecurringSchedule): string {
    let summary = '';

    if (schedule.MonthlyFrequency.OccurrencePeriod == WeekNumber[this.dayOfMonthLiteral]) {
      summary += this.GetOccursEveryDayOfMonthRecurTypeRecurringScheduleSummaryPart(schedule);
    } else {
      summary +=
        schedule.MonthlyFrequency.RepeatEveryCount +
        ' month' +
        (schedule.MonthlyFrequency.RepeatEveryCount > 1 ? 's' : '') +
        ' on ' +
        WeekNumber[schedule.MonthlyFrequency.OccurrencePeriod] +
        ' ' +
        (typeof schedule.MonthlyFrequency.DayOfWeek === 'string'
          ? schedule.MonthlyFrequency.DayOfWeek
          : DayOfWeek[schedule.MonthlyFrequency.DayOfWeek]) +
        ' ';
    }
    return summary;
  }

  private static GetOccurencyRecurringScheduleSummaryPart(schedule: RecurringSchedule): string {
    let summary = '';
    const atTime = moment(schedule.DailyFrequency.OccursAtTime, 'HH:mm').format('h:mm\u00A0A');
    const OccursEveryFromTime = moment(schedule.DailyFrequency.OccursEveryFromTime, 'HH:mm').format('h:mm\u00A0A');
    const OccursEveryToTime = moment(schedule.DailyFrequency.OccursEveryToTime, 'HH:mm').format('h:mm\u00A0A');
    if (!schedule.DailyFrequency.PeriodOption || schedule.DailyFrequency.PeriodOption == 'OccursAt') {
      summary += 'at ' + atTime;
    } else {
      summary +=
        'every ' +
        schedule.DailyFrequency.OccursEveryCount +
        ' ' +
        schedule.DailyFrequency.OccursEveryPeriod +
        ' from ' +
        OccursEveryFromTime +
        ' to ' +
        OccursEveryToTime;
    }
    return summary;
  }

  private static GetStartingFromRecurringSummaryPart(schedule: RecurringSchedule): string {
    let summary = '';
    switch (schedule.RecurringPeriod) {
      case RecurType.Daily:
        summary += moment(schedule.DailyFrequency.StartFromDate).format(mediumDateWithoutTimeMoment);
        break;
      case RecurType.DayOfMonth:
      case RecurType.Monthly:
        summary += moment(schedule.MonthlyFrequency.StartFromDate).format(mediumDateWithoutTimeMoment);
        break;
      case RecurType.Weekly:
        summary += moment(schedule.WeeklyFrequency.StartFromDate).format(mediumDateWithoutTimeMoment);
        break;
      case RecurType.Yearly:
        summary += moment(schedule.YearlyFrequency.StartFromDate).format(mediumDateWithoutTimeMoment);
        break;
    }
    return summary;
  }

  private static GetOccursEveryDayOfMonthRecurTypeRecurringScheduleSummaryPart(schedule: RecurringSchedule): string {
    return (
      schedule.MonthlyFrequency.RepeatEveryCount +
      ' Month' +
      (schedule.MonthlyFrequency.RepeatEveryCount > 1 ? 's' : '') +
      ' on ' +
      schedule.MonthlyFrequency.DayOfMonthCount +
      ' Day '
    );
  }

  static GetScheduleSummaryFromScheduleSettings(settings: ScheduleSettings): string {
    if (!settings.Enabled) return 'No Schedule';
    const scheduleModel = ScheduleDataHelper.GetScheduleModelFromScheduleSettings(settings);
    if (scheduleModel.ScheduleType == ScheduleType.once) {
      return 'Occurs on ' + moment(scheduleModel.SpecificDateSchedule.OnceDate).format(mediumDateWithTimeMoment);
    } else if (scheduleModel.ScheduleType == ScheduleType.recurring) {
      return ScheduleDataHelper.GetRecurringScheduleSummary(scheduleModel.RecurringSchedule);
    } else if (scheduleModel.ScheduleType == ScheduleType.predefined) {
      return this.backupTimeString(settings.Hour, settings.Minutes);
    } else if (settings.RecurType == RecurType.Instantly) return 'Real-time Backup';
    return 'N/A';
  }

  static backupTimeString(hours: number, minutes: number): string {
    return hours >= 24
      ? `Occurs Every ${Math.floor(hours / 24)} Day(s) ${hours % 24} Hour(s) ${minutes} Minute(s)`
      : `Occurs Every ${hours} Hour(s) ${minutes} Minute(s)`;
  }
}
