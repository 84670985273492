import { Page, SortParams } from '@models/Paging';
import { createAction, props } from '@ngrx/store';
import { StoreTypes } from '@store/storeTypes.enum';

export const loadPage = createAction(
  `[${StoreTypes.OnlineAccessComputersPaging}] Load Page`,
  props<{ pageNumber?: number; perPage?: number; isFullRefresh?: boolean }>()
);
export const loadPageSuccess = createAction(
  `[${StoreTypes.OnlineAccessComputersPaging}] Load Page Success`,
  props<{ page: Page; total: number }>()
);
export const loadPageError = createAction(`[${StoreTypes.OnlineAccessComputersPaging}] Load Page Error`);
export const clearPages = createAction(`[${StoreTypes.OnlineAccessComputersPaging}] Clear Pages`);
export const setPageLoading = createAction(`[${StoreTypes.OnlineAccessComputersPaging}] Set Loading`, props<{ loading: boolean }>());
export const setPageNumber = createAction(`[${StoreTypes.OnlineAccessComputersPaging}] Set Page Number`, props<{ pageNumber: number }>());
export const setPageNumberSuccess = createAction(
  `[${StoreTypes.OnlineAccessComputersPaging}] Set Page Number Success`,
  props<{ pageNumber: number }>()
);
export const setSortSettings = createAction(`[${StoreTypes.OnlineAccessComputersPaging}] Set Sort Settings`, props<{ sort: SortParams }>());
export const setPerPageSetting = createAction(
  `[${StoreTypes.OnlineAccessComputersPaging}] Set Per Page Setting`,
  props<{ perPage: number; skipReload?: boolean }>()
);
