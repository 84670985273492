import { Component, ViewChild } from '@angular/core';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'mbs-enable-step-modal',
  templateUrl: './enable-step-modal.component.html'
})
export class EnableStepModalComponent {
  public readonly alertType = MbsPopupType;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  constructor(private i18nPipe: I18NextPipe) {}

  save(): void {
    this.baseModal.save(true);
  }

  close(): void {
    this.baseModal.close();
  }
}
