<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           [loading]="loadingReleaseDate"
           #modal>

  <div modal-body>
    {{ (baseModal.data.itemsCount === 1 ? "licenses:disableAutoRenewalModal:descriptionForOneLicense" :
    "licenses:disableAutoRenewalModal:description") | i18next : { count: baseModal.data.itemsCount }
    }}
    <mbs-alert [type]="alertType.warning"
               [icon]="true"
               *ngIf="!this.baseModal.data.isAdminPage && !baseModal.data.isPremiumSupport && !this.baseModal.data.isDeepInstinct"
               class="mt-3">
      {{ "licenses:disableAutoRenewalModal:firstHint" | i18next }}
    </mbs-alert>
    <mbs-alert [type]="alertType.warning"
               [icon]="true"
               *ngIf="this.baseModal.data.isDeepInstinct"
               class="mt-3">
      All instances of Deep Instinct will be uninstalled from the target computer once the associated license expires.
      If computer is offline, this will be done as soon as it is online again
    </mbs-alert>
    <mbs-alert [type]="alertType.warning"
               [icon]="true"
               *ngIf="baseModal.data.isPremiumSupport && !this.baseModal.data.isAdminPage"
               class="mt-3">
      {{ "licenses:disableAutoRenewalModal:secondHint" | i18next }}
      <b>
        <mbs-rmm-date-time-format [time]="releaseDate"
                                  [dateTimeFormat]="mediumDateWithoutTimeMoment"></mbs-rmm-date-time-format>
      </b>
    </mbs-alert>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                [loading]="loading"
                (click)="disable()">{{ 'licenses:disableAutoRenewalModal:submit' | i18next: { format: 'title' } }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'licenses:common:cancel' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
