import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { getSecondsFromDate } from '@components/tfa/utils/date';

@Component({
  selector: 'mbs-tfa-timer',
  templateUrl: './tfa-timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TfaTimerComponent implements OnChanges {
  @Input() expiredDate: string;
  @Output() dateExpired = new EventEmitter();

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    this.startTimer();
  }

  public time: number = null;

  private interval: NodeJS.Timeout;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.startTimer();
  }

  private pauseTimer(): void {
    clearInterval(this.interval);
  }

  private startTimer(): void {
    this.pauseTimer();

    this.time = getSecondsFromDate(this.expiredDate);

    if (this.isExpiredDate()) {
      this.onDateExpired();
      this.cdr.detectChanges();
      return;
    }

    this.interval = setInterval(() => {
      if (this.isExpiredDate()) {
        this.onDateExpired();
      } else {
        this.time--;
      }
      this.cdr.detectChanges();
    }, 1000);
  }

  private isExpiredDate() {
    return this.time < 1;
  }

  private onDateExpired() {
    this.dateExpired.emit();
    this.pauseTimer();
  }
}
