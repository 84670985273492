import { PagingParams } from '@models/Paging';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreTypes } from '@store/storeTypes.enum';
import { OnlineAccessPagingState } from './state';

export const selectComputersPagingFeatureSelector = createFeatureSelector<OnlineAccessPagingState>(StoreTypes.OnlineAccessComputersPaging);

export const selectEntities = createSelector(selectComputersPagingFeatureSelector, (state) => state.pages);
export const selectPageNumbers = createSelector(selectComputersPagingFeatureSelector, (state) =>
  Object.keys(state.pages).map((v) => parseInt(v, 10))
);

export const selectLoading = createSelector(selectComputersPagingFeatureSelector, (state) => state.loading);
export const selectLoaded = createSelector(selectComputersPagingFeatureSelector, (state) => state.loaded);
export const selectCurrentPageNumber = createSelector(selectComputersPagingFeatureSelector, (state) => state.currentPageNumber);
export const selectCurrentPage = createSelector(
  selectCurrentPageNumber,
  selectEntities,
  (currentPage, pages) => (currentPage && pages[currentPage]) || []
);
export const selectPerPage = createSelector(selectComputersPagingFeatureSelector, (state) => state.perPage);
export const selectTotalItems = createSelector(selectComputersPagingFeatureSelector, (state) => state.total);
export const selectSortSettings = createSelector(selectComputersPagingFeatureSelector, (state) => state.sort);
export const selectPagingParams = createSelector(
  selectCurrentPageNumber,
  selectPerPage,
  (currentPageNumber, perPage) => ({ currentPageNumber, perPage } as PagingParams)
);

export const selectRequestParams = createSelector(selectPagingParams, selectSortSettings, (paging, sorting) => ({
  paging,
  sorting
}));
