import { Injectable, OnDestroy } from '@angular/core';
import { Build } from '@models/build';
import { AgentType } from '@models/Computer';
import { UiStorageKey } from '@models/ui-storage';
import { WSTopicName } from '@modules/signal-r/signal-r.constants';
import { SignalRService } from '@modules/signal-r/signal-r.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CommonResponseType } from '@services/build-service/build-service.types';
import { BuildService } from '@services/build-service/build.service';
import { UiStorageService } from '@services/ui-storage.service';
import { BuildsStoreActions, BuildsStoreSelectors } from '@store/builds';
import { Observable, of, switchMap } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class BuildsFacade implements OnDestroy {
  public builds$ = this.store.select(BuildsStoreSelectors.selectBuilds);
  public buildsInProgress$ = this.store.select(BuildsStoreSelectors.selectBuildsInProgress);
  public buildsSettings$ = this.store.select(BuildsStoreSelectors.selectBuildSettings);
  public newVersionsAlerts$ = this.store.select(BuildsStoreSelectors.selectNewVersionsAlerts);

  constructor(
    private store: Store,
    private buildService: BuildService,
    private signalR: SignalRService,
    private uiStorage: UiStorageService
  ) {
    this.fillInitialData();
    this.initiateSignalR();
  }

  ngOnDestroy(): void {
    this.destroySignalR();
  }

  public updateBuildList() {
    this.store.dispatch(BuildsStoreActions.updateBuilds());
  }

  public makePublic(build: Build): Observable<CommonResponseType> {
    return this.buildService.makePublic(build).pipe(tap(() => this.store.dispatch(BuildsStoreActions.updateBuilds())));
  }

  public onTabVisit(tabId: string) {
    if (tabId !== AgentType.Backup && tabId !== AgentType.RMM) return;

    this.store
      .select(BuildsStoreSelectors.selectState)
      .pipe(
        first(),
        switchMap(({ builds, isSandBoxAvailable }) => {
          const { backupVersions, rmmVersions } = this.buildService.getNewVersions(builds, isSandBoxAvailable);
          const backupVersionsHashCode = this.buildService.generateHashCode(backupVersions);
          const rmmVersionsHashCode = this.buildService.generateHashCode(rmmVersions);

          if (tabId === AgentType.Backup) {
            this.store.dispatch(
              BuildsStoreActions.updateShowNewVersionsAlertParams({
                showBackupUpdateDot: false
              })
            );
            return this.uiStorage.storeSetting(UiStorageKey.BackupVersionsHashCode, backupVersionsHashCode);
          }

          if (tabId === AgentType.RMM) {
            this.store.dispatch(
              BuildsStoreActions.updateShowNewVersionsAlertParams({
                showRmmUpdateDot: false
              })
            );
            return this.uiStorage.storeSetting(UiStorageKey.RmmVersionsHashCode, rmmVersionsHashCode);
          }

          return of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private fillInitialData() {
    this.store.dispatch(BuildsStoreActions.loadInitialBuilds());
    this.store.dispatch(BuildsStoreActions.loadInitialBuildsInProgress());
  }

  private initiateSignalR() {
    this.signalR.authorizedZoneConnection.on(WSTopicName.OnBuildCompleted, () => {
      this.store.dispatch(BuildsStoreActions.updateBuilds());
    });

    this.signalR.authorizedZoneConnection.on(WSTopicName.OnBuildStarted, (data: string) => {
      const parsed = JSON.parse(data);
      this.store.dispatch(BuildsStoreActions.addBuildsInProgress({ buildTypes: parsed.requestedBuilds }));
    });
  }

  private destroySignalR() {
    this.signalR.authorizedZoneConnection.off(WSTopicName.OnBuildCompleted);
    this.signalR.authorizedZoneConnection.off(WSTopicName.OnBuildStarted);
  }
}
