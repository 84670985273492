import { PlanTypes } from '@models/PlanTypes.enum';
import { RecurType } from '@models/RecurType.enum';
import { RecurringSchedule } from '@models/ScheduleModel';
import { DefaultSelectData } from '@models/SelectData';
import { StorageClass, StorageType, TypesForSynthetic } from '@models/StorageType.enum';
import { StorageConnection } from '@models/storge-connections';
import { AdvancedSettingsVirtualStepValue } from '@modules/wizards/models/advanced-settings-vm-models';
import { AppAwareStepValue } from '@modules/wizards/models/app-aware-models';
import { HyperVMachineExType } from '@modules/wizards/models/base/base-hyper-v-plan-model';
import { DiskInfoCommunication } from '@modules/wizards/models/base/base-models/plan-disk-info-model';
import { VirtualMachineExType } from '@modules/wizards/models/base/base-vmware-plan-model';
import { SelectHostStepValue } from '@modules/wizards/models/select-host-models';
import { SelectVirtualDisksStepValue, VirtualDisk, VirtualDisksSelectedType } from '@modules/wizards/models/select-virtual-disks-models';
import {
  SelectVirtualMachinesStepValue,
  VirtualMachinesSelectedType,
  VirtualMachinesSelectedWithNumbersType
} from '@modules/wizards/models/select-virtual-machines-models';
import { SQLServerInstanceAuthenticationTypeEnum, SQLServerInstanceStepValue } from '@modules/wizards/models/sql-server-instance-models';
import { SQLSourceDatabasesStepValue } from '@modules/wizards/models/sql-source-databases-models';
import { firstNumberGreaterThanSecondIfNotFloat } from '@utils/bigMath';
import { MinDate } from '@utils/constants/c-sharp-constants';
import { baseDateFormat, dateFormatWithoutT, getDateWithoutZ, TimeSpanToTimeGroup } from '@utils/date';
import { ScheduleDataHelper } from '@utils/schedule-data-helper';
import { GuidEmpty, MaxFileSizeStrFormat, MaxTicks, TimeSpanMaxValue } from 'mbs-ui-kit/utils/constants';
import * as moment from 'moment';
import { AdvancedFilterStepValue } from '../../models/advanced-filters-models';
import { AdvancedOptionsStepValue, AzureAccessTierType, DataForAdvancedStep } from '../../models/advanced-options-models';
import { ArchiveConsistencyStepValue, RunRestoreVerification } from '../../models/archive-consistency-step-value';
import { CompressionEncryptionStepValue } from '../../models/compression-encription-models';
import { NotificationsStepValue, WindowsEventLogNotificationSettings } from '../../models/notifications-models';
import { PlanNameStepValue } from '../../models/plan-name-models';
import { PrePostActionsStepValue } from '../../models/pre-post-actions-models';
import { getDefaultGFSPolicySettings, RetentionPolicyStepValue } from '../../models/retention-policy-models';
import { ScheduleAdvancedStepValue, ScheduleFormSettings, ScheduleSettings } from '../../models/schedule-advanced-models';
import { ScheduleStepValue } from '../../models/schedule-models';
import { SimpleScheduleStepValue } from '../../models/simple-schedule-models';
import { WhatBackupStepValue } from '../../models/what-backup-models';
import { WhatBackupTreeStepValue } from '../../models/what-backup-tree-model';
import { WhereBackupStepValue } from '../../models/where-backup-models';
import { SupportMethodsForStepsFromPlan } from '../support-methods-for-steps-from-plan';

export class StepsDataFromPlanHelper {
  public static updatePlanNameStep(planName: string, saveInCloud: boolean, isNBF = false): PlanNameStepValue {
    return { Name: planName || '', SavePlanInCloud: saveInCloud || isNBF, valid: true };
  }

  public static updateWhereBackup(IsHybridBackup: boolean, HybridID: string, ConnectionID: string): WhereBackupStepValue {
    const hybridNotEmpty = !!HybridID && HybridID !== GuidEmpty;
    const connectionNotEmpty = !!ConnectionID && ConnectionID !== GuidEmpty;
    const valid = !IsHybridBackup ? hybridNotEmpty || connectionNotEmpty : hybridNotEmpty && connectionNotEmpty;
    return { IsHybridBackup: IsHybridBackup, HybridID: HybridID, ConnectionID: ConnectionID, valid };
  }

  public static updateWhatBackup(planWrapper: any, needFilter = false): WhatBackupStepValue {
    const diskVolumesFromDiskInfo: any = {};
    const diskVolumes = needFilter ? planWrapper.DiskVolumes.filter((volume) => volume.Enabled) : planWrapper.DiskVolumes;
    if (planWrapper.plan.DiskInfo && planWrapper.plan.DiskInfo.length) {
      planWrapper.plan.DiskInfo.forEach((next) => {
        if (next.Volumes && next.Volumes.length) {
          next.Volumes.forEach((v) => {
            diskVolumesFromDiskInfo[v.Identity] = v;
            diskVolumesFromDiskInfo[v.Identity].DriveType = next.DriveType;
          });
        }
      });
    }
    (diskVolumes || []).forEach((x) => {
      x.IdentityForUiField = x.Identity + x.DiskId + x.WindowsVolumeIdentity + x.ExtentBasedId;
      x.DriveType = diskVolumesFromDiskInfo[x.Identity] ? diskVolumesFromDiskInfo[x.Identity].DriveType : '';
    });
    return { BackupVolumes: planWrapper.plan.BackupVolumes || 1, partitions: diskVolumes, valid: false };
  }

  public static updateWhatBackupTree(newPlan): WhatBackupTreeStepValue {
    return {
      includeFolders: newPlan.Items.map((i) => i.Path),
      excludeFolders: newPlan.ExcludedItems.map((i) => i.Path),
      valid: true
    };
  }

  public static updateCompressionEncryption(
    newPlan,
    isNBF = false,
    isLinux = false,
    isCreate = false,
    isVirtual = false
  ): CompressionEncryptionStepValue {
    const newObject: CompressionEncryptionStepValue = {
      UseCompression: newPlan.UseCompression,
      UseEncryption: newPlan.UseEncryption,
      EncryptionAlgorithm: newPlan.EncryptionAlgorithm,
      EncryptionKeySize: newPlan.EncryptionKeySize,
      EncryptionPassword: '',
      valid: true
    };
    if (!isVirtual) newObject.UseServerSideEncryption = newPlan.UseServerSideEncryption;

    if (newPlan.UseEncryption) {
      const pass = newPlan.EncryptionPassword || '';
      newObject.EncryptionPassword = pass;
    }

    if (isNBF) newObject.hint = newPlan.HintString || '';
    else if (!isLinux && !(newPlan.UseFileNameEncryption === null || newPlan.UseFileNameEncryption === undefined))
      newObject.UseFileNameEncryption = newPlan.UseFileNameEncryption;

    return newObject;
  }

  public static updateArchiveConsistency(newPlan, isIbb = false): ArchiveConsistencyStepValue {
    const step: ArchiveConsistencyStepValue = { useFullConsistencyCheck: !!newPlan.UseFullConsistencyCheck, valid: true };
    if (isIbb) {
      step.RunRestoreVerificationOn = newPlan.RunRestoreVerificationOn || RunRestoreVerification.None;
      const duration = moment.duration({ second: newPlan.FrameRateSeconds || 0 });
      step.minutes = duration.minutes();
      step.seconds = duration.seconds();
      step.failureMinutes = !(newPlan.CheckTimeSeconds === null || newPlan.CheckTimeSeconds === undefined)
        ? newPlan.CheckTimeSeconds / 60
        : 1;

      if (newPlan.TestVmSettings) {
        step.numCpu = newPlan.TestVmSettings.NumCpu;
        step.memoryInMb = newPlan.TestVmSettings.MemoryInMb;
        if (newPlan.TestVmSettings.DynamicMemoryEnabled) {
          step.dynamicMemory = {
            enabled: true,
            min: newPlan.TestVmSettings.MinMemoryInMb,
            max: newPlan.TestVmSettings.MaxMemoryInMb
          };
        }
      } else {
        step.numCpu = 1;
        step.memoryInMb = 1024;
      }
    }
    return step;
  }

  public static updateNBFRetentionPolicy(
    newPlan,
    needShowImmutability: boolean,
    isCreate = false,
    isLinux = false
  ): RetentionPolicyStepValue {
    let retentionTime = newPlan.RetentionTime || newPlan.SerializationSupportRetentionTime;
    if (newPlan.SerializationSupportRetentionTime && newPlan.SerializationSupportRetentionTime !== TimeSpanMaxValue) {
      retentionTime = newPlan.SerializationSupportRetentionTime;
    }
    const equalMaxTime = retentionTime && (retentionTime === TimeSpanMaxValue || (retentionTime.Ticks && retentionTime.Ticks === MaxTicks));
    const timeGroup = !equalMaxTime && retentionTime ? TimeSpanToTimeGroup(retentionTime) : { value: 1, timePeriod: 2 };
    const step: RetentionPolicyStepValue = {
      RetentionUseDefaultSettings:
        newPlan.RetentionUseDefaultSettings || equalMaxTime || (!newPlan.RetentionTime && !newPlan.SerializationSupportRetentionTime),
      deleteVersionsOlderThanCount: timeGroup.value,
      deleteVersionsOlderThanPeriod: timeGroup.timePeriod,
      intelligentRetention: newPlan.AutomaticallyReduceEarlyDeletionFee,
      GFSPolicySettings: getDefaultGFSPolicySettings(),
      valid: true
    };
    if (newPlan.ForwardIncremental) {
      step.RetentionUseDefaultSettings = false;
    }
    if (!isCreate && !(newPlan.GFSPolicySettings === null || newPlan.GFSPolicySettings === undefined)) {
      step.GFSPolicySettings.enabled = newPlan.GFSPolicySettings.IsEnabled;
      if (newPlan.GFSPolicySettings.IsEnabled) {
        if (needShowImmutability) step.GFSPolicySettings.enabledImmutability = newPlan.GFSPolicySettings.ImmutabilityEnabled;
        if (newPlan.GFSPolicySettings.Weekly) {
          step.GFSPolicySettings.weekly = {
            enabled: newPlan.GFSPolicySettings.Weekly.IsEnabled,
            storePeriod: newPlan.GFSPolicySettings.Weekly.StorePeriod
          };
        }
        if (newPlan.GFSPolicySettings.Monthly) {
          step.GFSPolicySettings.monthly = {
            enabled: newPlan.GFSPolicySettings.Monthly.IsEnabled,
            storePeriod: newPlan.GFSPolicySettings.Monthly.StorePeriod
          };
        }
        if (newPlan.GFSPolicySettings.Yearly) {
          step.GFSPolicySettings.yearly = {
            enabled: newPlan.GFSPolicySettings.Yearly.IsEnabled,
            storePeriod: newPlan.GFSPolicySettings.Yearly.StorePeriod,
            takeBackupFromMonth: newPlan.GFSPolicySettings.Yearly.TakeBackupFromMonth
          };
        }
      }
    }
    return step;
  }

  public static updateRetentionPolicy(
    newPlan,
    isCreate: boolean,
    planType: PlanTypes = PlanTypes.BackupDiskImagePlan,
    isLinux = false
  ): RetentionPolicyStepValue {
    const helpData = SupportMethodsForStepsFromPlan.getRetentionPolicyHelpData(newPlan);
    if (planType === PlanTypes.Plan || planType === PlanTypes.DataBasePlan) {
      return this.updateRetentionPolicyFileOrSQL(newPlan, helpData, isCreate, isLinux, planType === PlanTypes.DataBasePlan);
    }

    const RetentionPolicy: RetentionPolicyStepValue = {
      RetentionUseDefaultSettings: newPlan.RetentionUseDefaultSettings,
      keepNumberOfVersions: !helpData.equalMax,
      RetentionNumberOfVersions:
        helpData.equalMax || newPlan.RetentionNumberOfVersions === null || newPlan.RetentionNumberOfVersions === undefined
          ? 3
          : newPlan.RetentionNumberOfVersions,
      deleteVersionsOlderThan: helpData.isDeleteOlderThan,
      deleteVersionsOlderThanCount: helpData.timeGroup.value,
      deleteVersionsOlderThanPeriod: helpData.timeGroup.timePeriod,
      valid: true
    };

    if (newPlan.IsHybridBackup)
      RetentionPolicy.HybridRetentionPolicy = SupportMethodsForStepsFromPlan.getHybridRetentionPolicy(
        newPlan.HybridRetentionPolicy,
        isLinux,
        true
      );

    return RetentionPolicy;
  }

  public static updateRetentionPolicyFileOrSQL(
    newPlan,
    helpData,
    isCreate: boolean,
    isLinux = false,
    isSQL = false
  ): RetentionPolicyStepValue {
    const RetentionPolicy: RetentionPolicyStepValue = {
      RetentionUseDefaultSettings: newPlan.RetentionUseDefaultSettings,
      keepNumberOfVersions: !helpData.equalMax,
      RetentionNumberOfVersions:
        helpData.equalMax || newPlan.RetentionNumberOfVersions === null || newPlan.RetentionNumberOfVersions === undefined
          ? 3
          : newPlan.RetentionNumberOfVersions,
      deleteVersionsOlderThan: helpData.isDeleteOlderThan,
      deleteVersionsOlderThanCount: helpData.timeGroup.value,
      deleteVersionsOlderThanPeriod: helpData.timeGroup.timePeriod,
      // Unique for backup plan and SQL plan
      alwaysKeepLastVersion:
        isCreate || (helpData.isDeleteOlderThan && !newPlan.RetentionDeleteLastVersion) || newPlan.RetentionUseDefaultSettings,
      fromTimeSelected: newPlan.UseBackupDate ? 1 : 0,
      valid: true
    };

    if (!newPlan.IsHybridBackup) {
      RetentionPolicy.deleteIfLocallyDeleted = newPlan.DeleteCloudVersionIfDeletedLocally;
      RetentionPolicy.deleteAfter = newPlan.DeleteIfDeletedLocallyAfter;
    }

    if (!isLinux) {
      const RetDelay = newPlan.RetentionDelay && newPlan.RetentionDelay !== '00:00:00';
      const serialRetDelay = newPlan.SerializationSupportRetentionDelay && newPlan.SerializationSupportRetentionDelay !== '00:00:00';
      RetentionPolicy.delayPurgeForEnabled = RetDelay || serialRetDelay;
      RetentionPolicy.delayPurgeFor = helpData.delayGroup.value;
      RetentionPolicy.delayPurgeForSelect = helpData.delayGroup.timePeriod;
      if (!newPlan.IsHybridBackup) RetentionPolicy.doNotShowWarning = newPlan.HideDeleteFilesWarning;
    }

    if (newPlan.IsHybridBackup)
      RetentionPolicy.HybridRetentionPolicy = SupportMethodsForStepsFromPlan.getHybridRetentionPolicy(
        newPlan.HybridRetentionPolicy,
        isLinux
      );

    return RetentionPolicy;
  }

  public static updateAdvancedOptions(newPlan, storage: StorageConnection, isNBF = false): DataForAdvancedStep {
    const existExcludeEnabled = !(newPlan.ExcludeEnabled === null || newPlan.ExcludeEnabled === undefined);
    const storageType = storage?.StorageType;
    const isAmazonS3 = storageType === StorageType.AmazonS3;
    const isNeedSyntheticFull =
      existExcludeEnabled &&
      (isNBF ? isAmazonS3 || TypesForSynthetic.isSupported(storage) : isAmazonS3 || TypesForSynthetic.CBF.isSupported(storage));
    let azureAccessTier = AzureAccessTierType.Hot;

    if (newPlan.UseAzureArchive) azureAccessTier = AzureAccessTierType.Archive;
    if (newPlan.UseAzureCool) azureAccessTier = AzureAccessTierType.Cool;

    return {
      advancedOptionsStep: {
        UseS3Acceleration: isAmazonS3 && newPlan.UseS3Acceleration,
        StorageClass: !(newPlan.StorageClass === null || newPlan.StorageClass === undefined) ? newPlan.StorageClass : 1,
        azureAccessTier: azureAccessTier,
        ExcludeEnabled: newPlan.ExcludeEnabled,
        excludeRules: newPlan.DiskInfo ? SupportMethodsForStepsFromPlan.getExcludeRules(newPlan.DiskInfo) : [],
        SyntheticFull:
          isNeedSyntheticFull &&
          newPlan.SyntheticFull &&
          azureAccessTier !== AzureAccessTierType.Archive &&
          newPlan.StorageClass !== StorageClass.GLACIER &&
          newPlan.StorageClass !== StorageClass.DEEP_ARCHIVE,
        IgnoreBadSectors: !!newPlan.IgnoreBadSectors,
        DisableVSS: !!newPlan.DisableVSS,
        useSystemVSSProvider: newPlan.VSSProviderID !== GuidEmpty,
        PrefetchBlockCount: newPlan.PrefetchBlockCount ? newPlan.PrefetchBlockCount : 0,
        BlockSize: newPlan.BlockSize,
        valid: true
      },
      advancedOptionsFlags: {
        ExcludePossible: existExcludeEnabled,
        UseS3AccelerationPossible: isAmazonS3,
        AzureAccessTierType: storageType === StorageType.Azure,
        StorageClassSettingPossible: !(newPlan.StorageClass === null || newPlan.StorageClass === undefined),
        SyntheticFullSettingPossible: !newPlan.IsHybridBackup && isNeedSyntheticFull && newPlan.SyntheticFull !== undefined,
        IgnoreBadSectorsPossible: !(newPlan.IgnoreBadSectors === null || newPlan.IgnoreBadSectors === undefined),
        DisableVSSPossible: !(newPlan.DisableVSS === null || newPlan.DisableVSS === undefined),
        UseVSSPossible: !(newPlan.VSSProviderID === null || newPlan.VSSProviderID === undefined),
        PrefetchBlockCountPossible: !(newPlan.PrefetchBlockCount === null || newPlan.PrefetchBlockCount === undefined),
        BlockSizeSettingPossible: !(newPlan.BlockSize === null || newPlan.BlockSize === undefined)
      }
    };
  }

  public static updateAdvancedOptionsForBackup(newPlan, storage: StorageConnection, isLinux = false, isNBF = false): DataForAdvancedStep {
    const storageType = storage?.StorageType;
    const isAmazonS3 = storageType === StorageType.AmazonS3;
    const isNeedSyntheticFull =
      !isLinux && (isNBF ? isAmazonS3 || TypesForSynthetic.isSupported(storage) : isAmazonS3 || TypesForSynthetic.CBF.isSupported(storage));
    let azureAccessTier = AzureAccessTierType.Hot;

    if (newPlan.UseAzureArchive) azureAccessTier = AzureAccessTierType.Archive;
    if (newPlan.UseAzureCool) azureAccessTier = AzureAccessTierType.Cool;

    const step: AdvancedOptionsStepValue = {
      azureAccessTier: azureAccessTier,
      StorageClass: !(newPlan.StorageClass === null || newPlan.StorageClass === undefined) ? newPlan.StorageClass : 1,
      UseS3Acceleration: isAmazonS3 && newPlan.UseS3Acceleration,
      SyntheticFull:
        isNeedSyntheticFull &&
        newPlan.SyntheticFull &&
        azureAccessTier !== AzureAccessTierType.Archive &&
        newPlan.StorageClass !== StorageClass.GLACIER &&
        newPlan.StorageClass !== StorageClass.DEEP_ARCHIVE,
      valid: true
    };

    if (!isLinux) {
      step.AlwaysUseVSS = newPlan.AlwaysUseVSS || newPlan.UseFastNtfsScan;
      step.BackupNTFSPermissions = newPlan.BackupNTFSPermissions;
      step.UseFastNtfsScan = newPlan.UseFastNtfsScan;
      step.SaveDeletedDataInCloud = newPlan.SaveDeletedDataInCloud;
      step.useSystemVSSProvider = newPlan.VSSProviderID !== GuidEmpty;
    }

    return {
      advancedOptionsStep: step,
      advancedOptionsFlags: {
        AlwaysUseVSSPossible: !isLinux && !(newPlan.AlwaysUseVSS === null || newPlan.AlwaysUseVSS === undefined),
        AzureAccessTierType: storageType === StorageType.Azure,
        BackupNTFSPermissionsPossible: !isLinux && !(newPlan.BackupNTFSPermissions === null || newPlan.BackupNTFSPermissions === undefined),
        StorageClassSettingPossible: isAmazonS3 && !(newPlan.StorageClass === null || newPlan.StorageClass === undefined),
        SyntheticFullSettingPossible: isNeedSyntheticFull && newPlan.SyntheticFull === false,
        UseFastNtfsScanPossible: !isLinux && !(newPlan.UseFastNtfsScan === null || newPlan.UseFastNtfsScan === undefined),
        UseS3AccelerationPossible: isAmazonS3 && !(newPlan.UseS3Acceleration === null || newPlan.UseS3Acceleration === undefined),
        UseVSSPossible: !isLinux && !(newPlan.VSSProviderID === null || newPlan.VSSProviderID === undefined)
      }
    };
  }

  public static updateAdvancedFilter(newPlan: any, isLinux = false): AdvancedFilterStepValue {
    const separator = '; ';
    const filters = newPlan.BackupFilter.Filters.filter((s) => !!s).join(separator);
    const isEqual = +newPlan.MaxFileSize === +MaxFileSizeStrFormat;
    const isGreaterOrEqual = isEqual || firstNumberGreaterThanSecondIfNotFloat(newPlan.MaxFileSize, MaxFileSizeStrFormat);
    const step: AdvancedFilterStepValue = {
      backupAllFiles: newPlan.BackupFilter.FilterType,
      includeMask: filters,
      excludeMask: filters,
      skipFolders: newPlan.ExcludeFodlerList.length > 0,
      BackupEmptyFolders: newPlan.BackupEmptyFolders,
      skipFolderName: newPlan.ExcludeFodlerList.filter((s) => !!s).join(separator),
      ignoreSystemAndHidden: !newPlan.BackupFilter.IncludeSystemAndHidden,
      SkipInUseFiles: newPlan.SkipInUseFiles,
      valid: true
    };

    if (!isLinux) {
      const withoutZDate = getDateWithoutZ(newPlan.BackupOnlyAfterUTC);
      const isNotMinDate = moment(withoutZDate).format(baseDateFormat) !== MinDate;
      const date = isNotMinDate ? moment(newPlan.BackupOnlyAfterUTC).format(dateFormatWithoutT) : moment().format(dateFormatWithoutT);
      step.backupFilesModified = newPlan.BackupOnlyModifiedDaysAgo !== 0;
      step.daysAgo = newPlan.BackupOnlyModifiedDaysAgo !== 0 ? newPlan.BackupOnlyModifiedDaysAgo : 14;
      step.backupFilesModifiedSince = isNotMinDate;
      step.date = date;
      step.time = moment(date, dateFormatWithoutT).format('HH:mm:ss');
      step.doNotBackup = !isEqual;
      step.fileSize = !newPlan.MaxFileSize ? 1024 : isGreaterOrEqual ? 1024 : newPlan.MaxFileSize / 1024 / 1024;
      step.ignoreOnDemand = !newPlan.BackupFilter.IncludeRecallOnDataAccessAttribute;
    }

    return step;
  }

  public static updateSchedule(newPlan, overdueAfterSuccess, isCreate = false, isLinux = false, isRestoreOrSQL = false): ScheduleStepValue {
    const Schedule = isRestoreOrSQL ? newPlan.Schedule : newPlan.ForceFullSchedule;
    const isSchedule = Schedule && Schedule.Enabled;
    const isOtherSchedule =
      (isRestoreOrSQL ? newPlan.ForceFullSchedule && newPlan.ForceFullSchedule.Enabled : newPlan.Schedule && newPlan.Schedule.Enabled) ||
      (newPlan.ScheduleDiff && newPlan.ScheduleDiff.Enabled) ||
      (newPlan.ScheduleTLog && newPlan.ScheduleTLog.Enabled);
    const isStopAfter =
      (Schedule.StopAfter && Schedule.StopAfter !== TimeSpanMaxValue) ||
      (Schedule.StopAfterTicks && Schedule.StopAfterTicks.toString() !== MaxTicks);
    const newDuration = isStopAfter
      ? Schedule.StopAfter
        ? moment.duration(Schedule.StopAfter)
        : moment.duration(Schedule.StopAfterTicks / 10000, 'milliseconds')
      : moment.duration({ hours: 0 });

    const scheduleType = SupportMethodsForStepsFromPlan.getScheduleType(
      newPlan.IsPredefinedTemplatesSchedule,
      isSchedule,
      isOtherSchedule,
      Schedule.RecurType,
      isLinux
    );
    const step: ScheduleStepValue = {
      ScheduleType: scheduleType,
      specificDateGroup: SupportMethodsForStepsFromPlan.setSpecificDateGroup(
        !isCreate && isSchedule && Schedule.RecurType === RecurType.Once,
        Schedule.OnceDate
      ),
      StopAfterEnabled: isStopAfter,
      stopAfterHours: Math.floor(newDuration.asHours()),
      stopAfterMinutes: newDuration.minutes(),
      ForceMissedSchedule: !!newPlan.ForceMissedSchedule,
      syncBeforeRun: newPlan.SyncBeforeRun,
      valid: true
    };
    ScheduleDataHelper.updateOverdueFieldsOnStep(step, overdueAfterSuccess, isCreate);
    return step;
  }

  public static updateNBFSchedule(
    newPlan,
    overdueAfterSuccess,
    isCreate = false,
    isLinux = false,
    isNBF = false,
    validVersionForSchedule = false
  ): ScheduleStepValue {
    const incremental = newPlan.Schedule;
    const full = newPlan.ForceFullSchedule;
    const isSchedule = incremental && incremental.Enabled;
    const isFFI = newPlan.ForwardIncremental;
    const isStopAfter =
      (incremental.StopAfter && incremental.StopAfter !== TimeSpanMaxValue) ||
      (incremental.StopAfterTicks && incremental.StopAfterTicks.toString() !== MaxTicks);
    const newDuration = isStopAfter
      ? incremental.StopAfter
        ? moment.duration(incremental.StopAfter)
        : moment.duration(incremental.StopAfterTicks / 10000, 'milliseconds')
      : moment.duration({ hours: 0 });
    const step: ScheduleStepValue = {
      ScheduleType: isFFI ? 'ffi' : isSchedule ? 'predefined' : 'noschedule',
      StopAfterEnabled: isStopAfter,
      stopAfterHours: Math.floor(newDuration.asHours()),
      stopAfterMinutes: newDuration.minutes(),
      ForceMissedSchedule: !!newPlan.ForceMissedSchedule,
      incrementalData: ScheduleDataHelper.getIncrementalSchedule(incremental, validVersionForSchedule || isFFI, isLinux, isFFI),
      fullBackupData: ScheduleDataHelper.getFullSchedule(full, validVersionForSchedule, isLinux),
      syncBeforeRun: newPlan.SyncBeforeRun,
      valid: true
    };
    ScheduleDataHelper.updateOverdueFieldsOnStep(step, overdueAfterSuccess, isCreate);
    return step;
  }

  public static updatePrePostActions(newPlan, isRestore = false, isLinux = false): PrePostActionsStepValue {
    const PreAction = newPlan.Actions ? newPlan.Actions.Pre : null;
    const PostAction = newPlan.Actions ? newPlan.Actions.Post : null;
    const step: PrePostActionsStepValue = {
      executeCommandBeforeBackup: PreAction ? PreAction.Enabled : false,
      preAction: SupportMethodsForStepsFromPlan.getActionString(PreAction),
      exitOnPreActionFail: PreAction ? PreAction.TerminateOnFailure : true,
      executeCommandAfterBackupCompletion: PostAction ? PostAction.Enabled : false,
      postAction: SupportMethodsForStepsFromPlan.getActionString(PostAction),
      executePostActionOnSuccess: PostAction ? !PostAction.RunOnBackupFailure : true,
      valid: true
    };
    if (!isLinux && !isRestore) {
      step.enableBackupChain = newPlan.ExecuteNextPlan;
      step.backupChainNextPlan = newPlan.NextExectutionPlan || '';
      step.executeChainPlanOnSuccess = newPlan.ExecuteNextPlanOnlyIfSucces;
      step.ForceFullNextPlan = !!newPlan.ForceFullNextPlan;
    }
    return step;
  }

  public static updateAvailableMachinePlans(AMPlans, id: string): DefaultSelectData[] {
    return AMPlans
      ? Object.keys(AMPlans)
          .filter((i) => i !== id)
          .map((key) => ({ value: key, label: AMPlans[key] }))
      : [];
  }

  public static updateNotification(Notification, eventLog: WindowsEventLogNotificationSettings, isLinux = false): NotificationsStepValue {
    const step: NotificationsStepValue = {
      receiveNotificationOnCompleteGroup: {
        flag: Notification.SendNotification,
        case: Notification.OnlyOnFailure ? 'whenFails' : 'allCases'
      },
      valid: true
    };
    if (!isLinux) {
      step.addEntryToEventLogOnCompleteGroup = {
        flag: eventLog.SendNotification,
        case: eventLog.OnlyOnFailure ? 'whenFails' : 'allCases'
      };
    }
    return step;
  }

  public static updateAdvancedScheduleBasedOnMode(
    newPlan,
    isDefault = false,
    planType: PlanTypes,
    isLinux = false
  ): ScheduleAdvancedStepValue {
    if (!isDefault) {
      return this.updateAdvancedSchedule(newPlan, planType, isLinux);
    } else return this.advancedScheduleDefault(planType, isLinux);
  }

  private static updateAdvancedSchedule(newPlan, planType: PlanTypes, isLinux = false): ScheduleAdvancedStepValue {
    const isRestore = planType === PlanTypes.RestoreDiskImagePlan || planType === PlanTypes.RestorePlan;
    const isSQL = planType === PlanTypes.DataBasePlan;
    const full = isSQL || isRestore ? newPlan.Schedule : newPlan.ForceFullSchedule;

    const step: ScheduleAdvancedStepValue = {
      forceFull: {
        Enabled: full?.Enabled,
        FormSchedule: ScheduleDataHelper.generateNewRecurringSchedule(full, isLinux)
      },
      blockLevel: isSQL
        ? new ScheduleFormSettings(null, null)
        : {
            Enabled: isRestore ? false : newPlan?.Schedule?.Enabled,
            FormSchedule:
              !isRestore && !isSQL && newPlan?.Schedule?.Enabled
                ? ScheduleDataHelper.generateNewRecurringSchedule(newPlan.Schedule, isLinux)
                : new RecurringSchedule()
          },
      scheduleDiff:
        planType === PlanTypes.DataBasePlan
          ? SupportMethodsForStepsFromPlan.getScheduleDiffOrTLog(newPlan.ScheduleDiff)
          : new ScheduleFormSettings(null, null),
      scheduleTLog:
        planType === PlanTypes.DataBasePlan
          ? SupportMethodsForStepsFromPlan.getScheduleDiffOrTLog(newPlan.ScheduleTLog)
          : new ScheduleFormSettings(null, null),
      valid: true
    };

    if (planType === PlanTypes.Plan) {
      step.ForceFullApplyDiffSizeCondition = newPlan.ForceFullApplyDiffSizeCondition;
      step.ForceFullDiffSizeCondition = newPlan.ForceFullDiffSizeCondition;
    }

    return step;
  }

  public static advancedScheduleDefault(planType: PlanTypes, isLinux = false): ScheduleAdvancedStepValue {
    const step: ScheduleAdvancedStepValue = {
      forceFull: new ScheduleFormSettings(),
      blockLevel: planType === PlanTypes.DataBasePlan ? new ScheduleFormSettings(null, null) : new ScheduleFormSettings(),
      scheduleDiff: planType === PlanTypes.DataBasePlan ? new ScheduleFormSettings() : new ScheduleFormSettings(null, null),
      scheduleTLog: planType === PlanTypes.DataBasePlan ? new ScheduleFormSettings() : new ScheduleFormSettings(null, null),
      valid: false
    };

    if (!isLinux && planType === PlanTypes.Plan) {
      step.ForceFullApplyDiffSizeCondition = false;
      step.ForceFullDiffSizeCondition = 50;
    }

    return step;
  }

  public static updateSimpleSchedule(newPlan, planType: PlanTypes = PlanTypes.Plan): SimpleScheduleStepValue {
    const isSQL = planType === PlanTypes.DataBasePlan;
    const full = isSQL ? newPlan.Schedule : newPlan.ForceFullSchedule;
    const block = isSQL ? new ScheduleSettings() : newPlan.Schedule;
    const isFull = full?.Enabled;
    const fullSchedule = isFull ? full : new ScheduleSettings();
    const isBlock = block?.Enabled;
    const blockLevel = isBlock ? block : new ScheduleSettings();
    const isDiff = !!newPlan?.ScheduleDiff?.Enabled;
    const isTLog = !!newPlan?.ScheduleTLog?.Enabled;
    const dateNow = SupportMethodsForStepsFromPlan.getOnceDateFromSchedule(isBlock ? blockLevel : fullSchedule);

    return {
      startFromDate: dateNow,
      startFromTime: moment(dateNow).format('HH:mm:ss'),
      scheduleTemplate: '',
      fullScheduleEnabled: isFull,
      fullBackupEveryHours: isFull ? fullSchedule.Hour : 0,
      fullBackupEveryMinutes: isFull ? fullSchedule.Minutes : 0,
      blockLevelBackup: isBlock,
      blockLevelBackupEveryHours: isBlock ? blockLevel.Hour : 0,
      blockLevelBackupEveryMinutes: isBlock ? blockLevel.Minutes : 0,
      scheduleDiffEnabled: isDiff,
      differentialBackupEveryHours: isDiff ? newPlan.ScheduleDiff.Hour : 0,
      differentialBackupEveryMinutes: isDiff ? newPlan.ScheduleDiff.Minutes : 0,
      scheduleTLogEnabled: isTLog,
      tLogBackupEveryHours: isTLog ? newPlan.ScheduleTLog.Hour : 0,
      tLogBackupEveryMinutes: isTLog ? newPlan.ScheduleTLog.Minutes : 0,
      valid: true
    };
  }

  public static updateSelectHost(newPlan, isEdit = true): SelectHostStepValue {
    return {
      server: newPlan.Host,
      login: newPlan.Login,
      password: isEdit ? newPlan.Password || '' : '',
      valid: false
    };
  }

  public static updateSelectVirtualMachines(newPlan): SelectVirtualMachinesStepValue {
    let newType = VirtualMachinesSelectedType.All;
    if (newPlan.VMBackupType) {
      newType =
        typeof newPlan.VMBackupType === 'string'
          ? newPlan.VMBackupType
          : (VirtualMachinesSelectedWithNumbersType[newPlan.VMBackupType] as VirtualMachinesSelectedType);
    }

    return { type: newType, machines: newPlan.VirtualMachines || [], valid: false };
  }

  public static updateSelectAppAware(newPlan): AppAwareStepValue {
    return {
      settings: (newPlan.AppAwareGuestVmIndividualSettings || []).map((item) => {
        return {
          virtualMachineId: null, // now we don`t know this information
          VMName: item.VMName,
          processingMode: item.QuiesceType
        };
      }),
      valid: true
    };
  }

  public static getExcludesFromDiskVolumes(disk: DiskInfoCommunication): string[] {
    const excludes: string[] = [];

    if (disk.Volumes) {
      disk.Volumes.forEach((volume) => {
        if (!volume.Enabled) {
          excludes.push(
            SupportMethodsForStepsFromPlan.getExcludeRuleString(
              volume.MountPoints,
              volume.WindowsVolumeIdentity || volume.Identity,
              '',
              true
            )
          );
        } else if (SupportMethodsForStepsFromPlan.needUpdateExcludeRules(volume)) {
          volume.BackupOptions.ExcludeRules.forEach((excl) => {
            const excludeRule = SupportMethodsForStepsFromPlan.getExcludeRuleString(
              volume.MountPoints,
              volume.WindowsVolumeIdentity || volume.Identity,
              excl.Folder === '\\' ? '' : excl.Folder,
              true
            );

            excludes.push(excludeRule);
          });
        }
      });
    }

    return excludes;
  }

  public static updateSelectVirtualDisks(newPlan, isVMWare = false): SelectVirtualDisksStepValue {
    const machines = isVMWare ? newPlan.VirtualMachinesEx : newPlan.HyperVMachinesEx;

    return {
      type: !machines?.length ? VirtualDisksSelectedType.All : newPlan.Type || VirtualDisksSelectedType.Selected,
      disks:
        (isVMWare
          ? this.getVMWareDisksForSelectedDisksStep(machines)
          : this.getHyperVDisksForSelectedDisksStep(machines, newPlan.VirtualMachinesEx || [])) || [],
      allDisks: this.getAllDisksFromPlan(isVMWare, machines),
      valid: false
    };
  }

  private static getAllDisksFromPlan(isVMWare: boolean, machines: Array<HyperVMachineExType & VirtualMachineExType>): VirtualDisk[] {
    return machines.reduce((disks, machine) => {
      const diskInfo = isVMWare ? machine.DiskInfo : machine.SnapshotConfig.Disks;
      const machineUUID = isVMWare ? machine.UUID : machine.VMUUID;

      diskInfo.forEach((d) => {
        d.uiGuid = isVMWare ? d.DiskId + machineUUID : d.UUID + machineUUID;
        disks.push(d);
      });

      return disks;
    }, []);
  }

  private static getHyperVDisksForSelectedDisksStep(machines: HyperVMachineExType[], withExcludes: VirtualMachineExType[]): VirtualDisk[] {
    const volumesMap = withExcludes.reduce((result: any, item: VirtualMachineExType) => {
      item.DiskInfo.forEach((disk: DiskInfoCommunication) => (result[disk.DiskId + item.UUID] = disk));
      return result;
    }, {});

    return machines.reduce((disks, machine) => {
      machine.SnapshotConfig?.Disks?.forEach((disk) => {
        if (disk.Enabled) {
          disks.push({
            uuid: disk.UUID,
            uiGuid: disk.UUID + machine.VMUUID,
            fileName: disk.Path,
            label: 'Label',
            changeId: 'ChangeId',
            deviceKey: 0,
            diskCapacity: 0,
            virtualMachineName: machine.VMName,
            virtualMachineId: machine.VMUUID,
            id: disk.UUID,
            rootDiskId: GuidEmpty,
            excludes: volumesMap[disk.UUID + machine.VMUUID] ? this.getExcludesFromDiskVolumes(volumesMap[disk.UUID + machine.VMUUID]) : []
          });
        }
      });

      return disks;
    }, []);
  }

  private static getVMWareDisksForSelectedDisksStep(machines: VirtualMachineExType[]): VirtualDisk[] {
    return machines.reduce((disks, machine) => {
      machine?.DiskInfo?.forEach((disk, idx) => {
        if (disk.Enabled) {
          disks.push({
            uuid: disk.DiskId,
            uiGuid: disk.DiskId + machine.UUID,
            fileName: disk.DiskName,
            label: 'Label',
            changeId: 'ChangeId',
            deviceKey: 0,
            diskCapacity: 0,
            virtualMachineName: machine.VMName,
            virtualMachineId: machine.UUID,
            id: disk.DiskId,
            rootDiskId: GuidEmpty,
            excludes: this.getExcludesFromDiskVolumes(disk)
          });
        }
      });

      return disks;
    }, []);
  }

  public static updateAdvancedSettingsVirtual(
    newPlan,
    storage: StorageConnection,
    isCreate: boolean,
    isVMWare = false
  ): AdvancedSettingsVirtualStepValue {
    const stepData: AdvancedSettingsVirtualStepValue = {
      useChangeBlockTracking: newPlan.UseChangeBlockTracking,
      useS3Acceleration: newPlan.UseS3Acceleration,
      storageClass: newPlan.StorageClass || 1,
      azureAccessTier: newPlan.UseAzureArchive
        ? AzureAccessTierType.Archive
        : newPlan.UseAzureCool
        ? AzureAccessTierType.Cool
        : AzureAccessTierType.Hot,
      syntheticFull:
        isCreate ||
        (newPlan.SyntheticFull &&
          !newPlan.UseAzureArchive &&
          newPlan.StorageClass !== StorageClass.GLACIER &&
          newPlan.StorageClass !== StorageClass.DEEP_ARCHIVE &&
          TypesForSynthetic.isSupported(storage)),
      valid: true
    };

    if (isVMWare) {
      stepData.quiesceType = newPlan.QuiesceType;
    }

    return stepData;
  }

  public static updateSQLServerInstance(newPlan, isCreate = true): SQLServerInstanceStepValue {
    return {
      sqlServerInstanceName: newPlan.InstanceName,
      authenticationType:
        newPlan.WindowsAuthentication || isCreate
          ? SQLServerInstanceAuthenticationTypeEnum['Windows Authentication']
          : SQLServerInstanceAuthenticationTypeEnum['SQL Server Authentication'],
      userName: newPlan.UserName,
      password: newPlan.Password,
      useSecureConnection: newPlan.SecureConnection,
      checkIsSysAdmin: true,
      valid: false
    };
  }

  public static updateSQLSSourceDatabases(newPlan): SQLSourceDatabasesStepValue {
    return {
      databaseBackupType: newPlan.DatabaseSelectionType,
      selectedDatabases: newPlan.Databases,
      copyOnly: newPlan.CopyOnly,
      valid: true
    };
  }
}
