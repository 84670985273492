import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';
import { ComputersBackupEffects } from './effects';
import { computersBackupReducer } from './reducer';

@NgModule({
  imports: [StoreModule.forFeature(StoreTypes.ComputersBackup, computersBackupReducer), EffectsModule.forFeature([ComputersBackupEffects])]
})
export class ComputersBackupStoreModule {}
