/* eslint-disable sonarjs/no-duplicate-string */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseGeneric } from '@components/licenses/models/generic.model';
import {
  AssignRequest,
  AutoRenewalRequest,
  Balance,
  Debt,
  LicensePool,
  LicensePoolLimit,
  LicenseTypeDto,
  LicenseUsageItem,
  ManageRequest,
  PayDebt,
  ReleaseRequest,
  SelectLicenseParams,
  Total
} from '@components/licenses/models/license.model';
import {
  AvailableLicenses,
  AvailableLicenseType,
  AvailablePaidLicense,
  ComputerGrantLicenseResultDTO,
  ComputerLicense,
  LicenseTypeSummary
} from '@models/License';
import { LicenseType } from '@models/LicenseType.enum';
import { BehaviorSubject, finalize, iif, Observable, of, take } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class LicensesService {
  public balance = new BehaviorSubject<Balance>(null);

  private isTypesLoading = new BehaviorSubject(false);
  private types$ = new BehaviorSubject<LicenseTypeSummary[]>(undefined);
  public get types(): LicenseTypeSummary[] {
    return this.types$.value;
  }
  private readonly licensesServiceUrl = '';

  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.licensesServiceUrl = config.get('licensesHref');
  }

  public getComputerLicenses(hid: string): Observable<Array<ComputerLicense>> {
    const availableLicenseKinds = [1, 7];
    return this.http
      .get<Array<ComputerLicense>>(`api/licenses/computer?hid=${hid}`)
      .pipe(map((licenses) => licenses?.filter((license) => availableLicenseKinds.includes(license.licenseKind))));
  }

  public getAvailableLicenses(hid: string, licenseTypeId: string): Observable<AvailableLicenses> {
    return this.http.get<AvailableLicenses>(`api/licenses/availablelicenses?hid=${hid}&licenseTypeId=${licenseTypeId}`);
  }

  public getAvailablePaidLicenses(hid: string, licenseTypeId: string, offset = 0, limit = 1000): Observable<Array<AvailablePaidLicense>> {
    return this.http.get<Array<AvailablePaidLicense>>(
      `api/licenses/availablelicenses/paid?hid=${hid}&licenseTypeId=${licenseTypeId}&offset=${offset}&limit=${limit}`
    );
  }

  public getTypes(): Observable<LicenseTypeSummary[]> {
    this.isTypesLoading.next(true);
    return this.http.get<LicenseTypeSummary[]>('api/licenses/types').pipe(
      tap((licenses) => this.types$.next(licenses)),
      finalize(() => {
        this.isTypesLoading.next(false);
        this.isTypesLoading.complete();
      })
    );
  }

  public getLicenseDisplayName(type: LicenseType): Observable<string> {
    const getDisplayName = () => {
      return this.types.find((item) => item.type === type)?.displayName;
    };

    const types = this.isTypesLoading.value
      ? this.types$.pipe(filter((value) => Boolean(value?.length)))
      : this.types$.pipe(switchMap((value) => iif(() => Boolean(value?.length), of(value), this.getTypes())));

    return types.pipe(take(1), map(getDisplayName));
  }

  public getLicenses(params?: HttpParams): Observable<ResponseGeneric<Array<LicenseUsageItem>>> {
    return this.http.get<ResponseGeneric<Array<LicenseUsageItem>>>(this.licensesServiceUrl + '/api/v1/Licenses/usage', { params });
  }

  public tryGrantLicense(hid: string): Observable<ComputerGrantLicenseResultDTO> {
    return this.http.post<ComputerGrantLicenseResultDTO>(`/api/computers/${hid}/grantlicense/trygrant`, '');
  }

  public getLicensesTypes(params?: HttpParams): Observable<ResponseGeneric<Array<LicenseTypeDto>>> {
    return this.http.get<ResponseGeneric<Array<LicenseTypeDto>>>(this.licensesServiceUrl + '/api/v1/Licenses/usage/types', { params });
  }

  public getLicenseTotals(params?: HttpParams): Observable<ResponseGeneric<Total>> {
    return this.http.get<ResponseGeneric<Total>>(this.licensesServiceUrl + '/api/v1/Licenses/usage/totals', { params });
  }

  public setAutoRenewal(params?: AutoRenewalRequest): Observable<null> {
    return this.http.post<null>(this.licensesServiceUrl + '/api/v1/Licenses/usage/set-auto-renewal', params);
  }

  public setAutoRenewalForComputerLicense(hid: string, licenseTypeId: number | string, enabled: boolean): Observable<null> {
    return this.http.put<null>('api/licenses/computer/set-auto-renewal', {
      hid,
      licenseTypeId,
      enabled
    });
  }

  public licenseRelease(params?: ReleaseRequest): Observable<null> {
    return this.http.post<null>(this.licensesServiceUrl + '/api/v1/Licenses/usage/release', params);
  }

  public licenseManage(params?: ManageRequest): Observable<null> {
    return this.http.post<null>(this.licensesServiceUrl + '/api/v1/Licenses/usage/manage', params);
  }

  public licenseAssign(params?: AssignRequest): Observable<null> {
    return this.http.post<null>(this.licensesServiceUrl + '/api/v1/Licenses/usage/assign', params);
  }

  public licenseExport(params?: HttpParams) {
    return this.http.get<Array<LicenseUsageItem>>(this.licensesServiceUrl + '/api/v1/Licenses/usage/export', { params });
  }

  public calculateReleaseDate(id: string | number): Observable<{ releaseDate: string }> {
    return this.http.get<{ releaseDate: string }>(
      `${this.licensesServiceUrl}/api/v1/Licenses/usage/calculate-release-date?licenseUsageId=${id}&setAutoRenewalEnable=false`
    );
  }

  public getBalance(): Observable<Balance> {
    return this.http.get<Balance>('api/Subscription/balance').pipe(tap((balance) => this.balance.next(balance)));
  }

  public getSubscriptionEditLink() {
    return this.http.get('api/subscription/edit-link');
  }

  public getDebt(): Observable<Debt> {
    return this.http.get<Debt>('api/Subscription/debt');
  }

  public getPayDebt(): Observable<PayDebt> {
    return this.http.put<PayDebt>('api/Subscription/pay-debt', null);
  }

  public grantLicenseByType(hid: string, type: string) {
    return this.http.post(`api/computers/${hid}/grantlicense/grantbytype`, { type });
  }

  public getPool(groupType: number, groupValue: string): Observable<Array<LicensePool>> {
    return this.http.get<Array<LicensePool>>(`${this.licensesServiceUrl}/api/v1/Limits/${groupType}/${groupValue}`);
  }

  public setPool(limits: Array<LicensePoolLimit>): Observable<Array<LicensePool> & { errors: { [key: string]: number } }> {
    return this.http.post<Array<LicensePool> & { errors: { [key: string]: number } }>(this.licensesServiceUrl + '/api/v1/Limits', limits);
  }

  public removePool(limits: Array<LicensePoolLimit>): Observable<null> {
    return this.http.post<null>(this.licensesServiceUrl + '/api/v1/Limits/remove', limits);
  }

  public releaseComputerLicense(hid: string, licenseTypeId: string | number): Observable<null> {
    return this.http.put<null>('api/licenses/computer/release', { hid, licenseTypeId });
  }

  public assignComputerLicense(params: SelectLicenseParams): Observable<ComputerLicense> {
    return this.http.post<ComputerLicense>('api/licenses/computer/assign', { ...params });
  }

  public changeComputerLicense(params: SelectLicenseParams): Observable<ComputerLicense> {
    return this.http.put<ComputerLicense>('api/licenses/computer/change', { ...params });
  }

  public getSupportedLicenseTypes(hid: string, kind: number): Observable<Array<AvailableLicenseType>> {
    return this.http.get<Array<AvailableLicenseType>>(`api/licenses/computer/supported-license-types?hid=${hid}&kind=${kind}`);
  }
}
