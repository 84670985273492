import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { MbsSize, ModalComponent } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-recovery-code-modal',
  templateUrl: './recovery-code-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecoveryCodeModalComponent {
  public codes: Array<string>;
  public isSavedRecoveryCodes = false;

  public MbsSize = MbsSize;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(public authService: AuthService) {}
}
