import { PagingState } from '@models/Paging';

export interface OnlineAccessPagingModeStorage {
  [page: number]: string[];
}

export interface OnlineAccessPagingState extends PagingState {
  pages: OnlineAccessPagingModeStorage;
}

export const initialOnlineAccessPagingState: OnlineAccessPagingState = {
  pages: {},
  loading: false,
  loaded: false,
  currentPageNumber: 1,
  perPage: null,
  total: 0,
  sort: {
    column: null,
    direction: null
  }
};
