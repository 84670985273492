import { Component, forwardRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsValidators, ShortDateParserFormatter } from 'mbs-ui-kit';
import { debounceTime } from 'rxjs/operators';
import { AdvancedFilterStepValue, FilterTypes } from '../../models/advanced-filters-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { FormPipeOperators, StepBase } from '../StepBase.class';

const AdvancedFilterStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => AdvancedFilterStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-advanced-filter-step',
  templateUrl: './advanced-filter-step.component.html',
  providers: [AdvancedFilterStepValueAccessor, { provide: NgbDateParserFormatter, useClass: ShortDateParserFormatter }]
})
export class AdvancedFilterStepComponent extends StepBase<AdvancedFilterStepValue> implements OnInit {
  @Input() canBackupEmptyFolders = true;

  public notBackupDemandTooltipTexts = this.i18NextPipe.transform('wizards:not_backup_demand_tooltip', { returnObjects: true });
  public linuxLessThat4_2: boolean = (() => {
    const minorVersion = this.mainService.backupVersionUpdated && +this.mainService.backupVersionUpdated.substring(0, 2) < 42;

    return minorVersion && this.isLinux;
  })()

  constructor(public mainService: RemoteManagementWizardsService, public i18NextPipe: I18NextPipe) {
    super(mainService);
  }

  skipFolderNameValidator(control: AbstractControl): ValidationErrors | null {
    return /[:"\\/]/.test(control.value as string)
      ? { invalidName: { message: this.i18NextPipe.transform('wizards:skip_folders_tooltip') } }
      : null;
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      backupAllFiles: new FormControl(0),
      includeMask: new FormControl('', [Validators.required, Validators.minLength(2)]),
      excludeMask: new FormControl('', [Validators.required, Validators.minLength(2)]),
      skipFolders: new FormControl(false),
      BackupEmptyFolders: new FormControl(false),
      skipFolderName: new FormControl('', [Validators.required, this.skipFolderNameValidator.bind(this)]),
      backupFilesModified: new FormControl(false),
      daysAgo: new FormControl(14),
      backupFilesModifiedSince: new FormControl(false),
      date: new FormControl('', [Validators.required]),
      time: new FormControl('00:00', [Validators.required, MbsValidators.timeValidatorWithoutText]),
      doNotBackup: new FormControl(false),
      fileSize: new FormControl(1024),
      ignoreSystemAndHidden: new FormControl(false),
      SkipInUseFiles: new FormControl(false),
      ignoreOnDemand: new FormControl(false)
    });

    this.initFormEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm && changes.canBackupEmptyFolders) this.changeStateBackupEmptyFolders(changes.canBackupEmptyFolders.currentValue);
  }

  updateForm(value: AdvancedFilterStepValue): void {
    this.stepForm.reset(value);

    this.changeStateBackupEmptyFolders(this.canBackupEmptyFolders);

    if (!this.stepForm.touched && !this.stepForm.dirty) this.updateFormFieldsStates(value);
  }

  changeStateBackupEmptyFolders(state: boolean): void {
    this.toggleFormControls(['BackupEmptyFolders'], state);
  }

  updateFormFieldsStates(value): void {
    this.backupAllFilesChangeHandler(value.backupAllFiles);
    this.skipFoldersChangeHandler(value.skipFolders);
    this.backupFilesModifiedChangeHandler(value.backupFilesModified);
    this.backupFilesModifiedSinceChangeHandler(value.backupFilesModifiedSince);
    this.doNotBackupChangeHandler(value.doNotBackup);
  }

  backupAllFilesChangeHandler(event: FilterTypes): void {
    if (event === FilterTypes.UseIncludeMask) {
      this.stepForm.get('includeMask').enable();
      this.stepForm.get('excludeMask').enabled && this.stepForm.get('excludeMask').disable();

      return;
    }

    if (event === FilterTypes.UseExcludeMask) {
      this.stepForm.get('excludeMask').enable();
      this.stepForm.get('includeMask').enabled && this.stepForm.get('includeMask').disable();

      return;
    }

    if (!(event as any).target) {
      this.stepForm.get('includeMask').disable();
      this.stepForm.get('excludeMask').disable();
    }
  }

  skipFoldersChangeHandler(state: boolean): void {
    this.toggleFormControls(['skipFolderName'], state);
  }

  backupFilesModifiedChangeHandler(state: boolean): void {
    this.toggleFormControls(['daysAgo'], state);
  }

  backupFilesModifiedSinceChangeHandler(state: boolean): void {
    this.toggleFormControls(['date', 'time'], state);
  }

  doNotBackupChangeHandler(state: boolean): void {
    this.toggleFormControls(['fileSize'], state);
  }
}
