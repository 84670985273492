import { PlanMode } from '@models/PlanTypes.enum';
import { WizardHelpersSettings } from '@modules/wizards/helpers/bases/base-for-steps-helper';
import { BaseForVMPlanHelper } from '@modules/wizards/helpers/bases/base-for-vm-plan-helper';
import { PlanDataFromStepsHelper } from '@modules/wizards/helpers/plan-data-from-steps-helper/plan-data-from-steps-helper';
import { HyperVFormModel } from '@modules/wizards/models/HyperV/hyper-v-form-model';

export class HyperVPlanFromStepsHelper extends BaseForVMPlanHelper {
  public static HyperVModel: HyperVFormModel = null;

  public static getUpdatedPlan(plan, model: HyperVFormModel, settings: WizardHelpersSettings): any {
    this.HyperVModel = model;
    const newPlan = Object.assign({}, plan);
    this.updateModel(newPlan, settings);
    return newPlan;
  }

  static updateModel(newPlan, settings: WizardHelpersSettings): void {
    const forceRemoveAppAware = !settings.isAppAwareEnabled;
    const versionForExclude = +settings.agentVersion.substring(0, 2) >= 79;
    const isCreate = settings.mode !== PlanMode.edit;
    const existExclude = this.HyperVModel.selectVirtualDisksStep.allDisks.some((disk) => !!disk?.excludes?.length);

    PlanDataFromStepsHelper.updateNewPlanByNameStep(newPlan, this.HyperVModel.planNameStep);
    PlanDataFromStepsHelper.updateNewPlanByWhereBackupStep(newPlan, this.HyperVModel.whereBackupStep, true);
    PlanDataFromStepsHelper.updateNewPlanByVirtualAdvancedSettings(
      newPlan,
      this.HyperVModel.advancedSettingsStep,
      true,
      versionForExclude && (isCreate || existExclude)
    );

    PlanDataFromStepsHelper.updateNewPlanBySelectVirtualMachineStep(newPlan, this.HyperVModel.selectVirtualMachinesStep);
    PlanDataFromStepsHelper.updateNewPlanByAppAwareStep(newPlan, this.HyperVModel.appAwareStep, forceRemoveAppAware);
    PlanDataFromStepsHelper.updateNewPlanBySelectVirtualDisksStep(newPlan, this.HyperVModel.selectVirtualDisksStep);

    PlanDataFromStepsHelper.updateNewPlanArchiveByArchiveConsistencyStep(newPlan, this.HyperVModel.archiveConsistencyStep);
    PlanDataFromStepsHelper.updateNewPlanByCompressionEncryptionStep(newPlan, this.HyperVModel.compressionEncryptionStep, true);
    PlanDataFromStepsHelper.updateNewNBFPlanByScheduleStep(newPlan, this.HyperVModel.scheduleStep);
    PlanDataFromStepsHelper.updateNewNBFPlanByRetentionPolicyStep(newPlan, this.HyperVModel.retentionPolicyStep, true);
    PlanDataFromStepsHelper.updateNewPlanByPrePostActionsStep(
      newPlan,
      this.HyperVModel.prePostActionsStep,
      !settings.allowPrePostActions,
      true
    );
    PlanDataFromStepsHelper.updateNewPlanByNotificationStep(newPlan, this.HyperVModel.notificationStep, this.isLinux);
    PlanDataFromStepsHelper.preparePlanForSending(newPlan, false);
  }
}
