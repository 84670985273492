import { AgentType, BulkAgentType, GetComputersParams } from '../Computer';

export enum RemoteCommandType {
  GetPlanInfo = 'GetPlanInfo',
  GetPlanInfoList = 'GetPlanInfoList',
  StartPlan = 'StartPlan',
  StopPlan = 'StopPlan',
  RemovePlan = 'RemovePlan',
  ClonePlan = 'ClonePlan',
  GetAccForBackupOnlineService = 'GetAccForBackupOnlineService',
  ChangeAccForBackupOnlineService = 'ChangeAccForBackupOnlineService',
  ForceUpdate = 'forceUpdate',
  SendLogs = 'sendLogs',
  InstallApp = 'installApp',
  WakeOnLan = 'wakeonlan',
  GetStorageConnections = 'GetStorageConnections',
  SaveFileSystemSettings = 'SaveFileSystemSettings',
  DeleteFileSystemStorage = 'DeleteFileSystemStorage',
  GetNetworkCredentialsList = 'GetNetworkCredentialsList',
  AddNetworkCredentials = 'AddNetworkCredentials',
  DeleteNetworkCredentials = 'DeleteNetworkCredentials',
  CheckNetworkCredentials = 'CheckNetworkCredentials',
  ConfigureService = 'ConfigureService',
  RegenerateClientHID = 'RegenerateClientHID',
  UpdateConfig = 'UpdateConfig',
  GetAvailablePlanTypes = 'GetAvailablePlanTypes'
}

export enum InstallBuildsType {
  Public = 'Public',
  Sandbox = 'Sandbox'
}

export interface RemoteCommand<T> {
  commandType: T;
}

export type AgentRemoteCommandType = ParamsSendLogs | Record<string, any>;

export interface AgentRemoteCommand<TParams extends AgentRemoteCommandType> {
  computerHid?: string;
  agentType: AgentType;
  params?: TParams;
}

export interface SingleRemoteCommand extends AgentRemoteCommand<AgentRemoteCommandType>, RemoteCommand<RemoteCommandType> {}

export interface GroupRemoteCommand<TCommands extends AgentRemoteCommand<AgentRemoteCommandType>> extends RemoteCommand<RemoteCommandType> {
  agentCommands: TCommands[];
}

export interface UpdateBuild {
  appId: string;
  versionType: string;
}

export interface ParamsForceUpdate {
  updateBuilds: UpdateBuild[];
}

export interface ParamsSendLogs {
  profile: string;
  sendToSuperAdmin: boolean;
  sendToSupport: boolean;
  sendToCustomEmail: boolean;
  customEmails: any;
  message: string;
  includeEventLogs?: boolean;
}

export interface BuildVersionParams {
  appId: AgentType;
  versionType: InstallBuildsType;
}

export interface InstallBuildsParams {
  installBuilds: BuildVersionParams[];
}

export type BulkRemoteCommandType = ParamsSendLogs | ParamsForceUpdate | InstallBuildsParams;

export interface BulkRemoteCommand<TParams extends BulkRemoteCommandType> extends RemoteCommand<RemoteCommandType> {
  filter: GetComputersParams;
  appIds?: BulkAgentType[];
  params?: TParams;
}

export interface RemoteCommandResponse<TData> {
  data: TData;
  message: string;
  ok: boolean;
}

export enum RepositorySyncState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export enum GroupActionMode {
  Single,
  Group,
  Bulk,
  Legacy // TODO: Old legacy mode for legacy Computers page
}
