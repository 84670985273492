<mbs-modal [title]="'immutability:immutability_confirm_modal_tittle' | i18next: { format: 'title' }">

  <ng-container modal-body>
    <mbs-alert class="mb-0"
               [type]="MbsPopupType.warning"
               [icon]="true">

      <ng-container *ngIf="isStorageWizard; else defaultText">
        {{ 'immutability:immutability_confirm_storage_wizard_first_text_modal_alert' | i18next }}
        <br />
        {{ 'immutability:immutability_confirm_storage_wizard_second_text_modal_alert' | i18next }}
      </ng-container>

      <ng-template #defaultText>
        {{ 'immutability:immutability_confirm_modal_alert' | i18next }}
      </ng-template>

    </mbs-alert>
  </ng-container>

  <ng-container modalFooter>

    <div class="d-flex w-100 justify-content-between align-items-center">

      <mbs-checkbox [label]="'immutability:immutability_confirm_modal_checkbox_label' | i18next"
                    [(ngModel)]="confirmed"></mbs-checkbox>

      <div class="d-flex">

        <mbs-button type="primary"
                    class="mr-1"
                    [disabled]="!confirmed"
                    (click)="confirmClickHandler()">
          {{ 'buttons:confirm' | i18next }}
        </mbs-button>

        <mbs-button type="secondary"
                    class="ml-1"
                    (click)="cancelClickHandler()">
          {{ 'buttons:cancel' | i18next }}
        </mbs-button>

      </div>

    </div>

  </ng-container>

</mbs-modal>
