import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TagKind, Tag } from '@models/tag';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  constructor(private http: HttpClient) {}

  public getTagsByKind(kind: TagKind, calculateCount = true): Observable<Tag[]> {
    return this.http.get<Tag[]>(`api/tags?kind=${kind}&calculateCount=${calculateCount}`);
  }

  public getTagById(id: number): Observable<Tag> {
    return this.http.get<Tag>(`api/tags/${id}`);
  }

  public createTag(tag: Partial<Tag>): Observable<Tag> {
    return this.http.post<Tag>(`api/tags`, tag);
  }

  public updateTag(tag: Partial<Tag>): Observable<Tag> {
    return this.http.put<Tag>(`api/tags/${tag?.id}`, tag);
  }

  public deleteTag(id: number): Observable<null> {
    return this.http.delete<null>(`api/tags/${id}`);
  }
}
