import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BuildsFacade } from '@facades/builds.facade';
import { environment } from '@mbs-ui/environments/environment';
import { BuildType } from '@models/BuildType.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConnectSettingsService } from '@services/connect.settings.service';
import { filter, map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-download-connect',
  templateUrl: './download-connect.component.html',
  styleUrls: ['./download-connect.component.scss']
})
export class DownloadConnectComponent {
  public readonly environment = environment;
  public readonly keys = Object.keys;
  public connectClientUrl: string = environment.raDownloadURL.windows;
  public readonly raClientConfig = {
    mac: 'download-connect-icon fa fa-apple text-lg',
    ios: 'download-connect-icon fa fa-apple text-lg',
    android: 'download-connect-icon fa fa-android text-lg'
  };
  public builds$ = this.buildsFacade.builds$.pipe(
    untilDestroyed(this),
    filter(Boolean),
    map((builds) => builds.filter(({ mbsBuildType }) => mbsBuildType === BuildType.CBLRA))
  );

  @Output() configureButtonClick = new EventEmitter<boolean>();

  @Input() hideConnectFrom = false;

  constructor(private buildsFacade: BuildsFacade, private connectSettings: ConnectSettingsService) {
    this.connectSettings.getConnectClientBuild().subscribe((connectClientUrl) => {
      this.connectClientUrl = connectClientUrl;
    });
  }
}
