import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, UntypedFormGroup } from '@angular/forms';
import { PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';
import { FormsUtil } from 'mbs-ui-kit/utils/forms-util';
import { DataForPath } from '../helpers/bases/base-for-steps-helper';
import { RemoteManagementWizardsService } from '../services/remote-management-wizards.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OperatorFunction } from 'rxjs';
import { ModalService, ModalSettings } from 'mbs-ui-kit';
import { EnableStepModalComponent } from '@modules/wizards/steps/components/enable-step-modal/enable-step-modal.component';
import { LegacyPropertyDisablingModalComponent } from '@modules/wizards/steps/components/legacy-property-disabling-modal.component';

export type StepLoadInfo = { loading: boolean; isHyperV?: boolean };

export type FormPipeOperators = [
  OperatorFunction<unknown, unknown>?,
  OperatorFunction<unknown, unknown>?,
  OperatorFunction<unknown, unknown>?,
  OperatorFunction<unknown, unknown>?
];

export enum AfterEnabledStepActionType {
  next = 0,
  back = 1,
  save = 2
}

@UntilDestroy()
@Directive()
export abstract class StepBase<T extends { valid?: boolean }> implements ControlValueAccessor {
  @Input() planTypeText = 'Default';
  @Input() stepFocused = false;

  @Output() loadInfo = new EventEmitter<StepLoadInfo>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() registerRequiredStep = new EventEmitter<string>();
  @Output() unregisterRequiredStep = new EventEmitter<string>();
  @Output() nextStep = new EventEmitter<boolean>();
  @Output() backStep = new EventEmitter();

  public readonly mbsPopupType = MbsPopupType;

  public importedPlanChanged = false;
  public stepForm: UntypedFormGroup;
  public regExpForInputAfter = /\\[ ]/;
  public regExpForInputBefore = /[ ]\\/;
  public splitForInputBefore = ' \\';
  public splitForInput = '\\ ';
  public joinForInput = '\\';

  protected confirmedAsEnabled = false;
  protected legacyUnsupportedProperties: Array<string> = [];

  private myValue: T = null;
  private get state() {
    return RemoteManagementWizardsService.getWizardState(this.mainService);
  }

  get planType(): PlanTypes {
    return this.mainService.type;
  }

  get isNBF(): boolean {
    return this.mainService.isNBF;
  }

  get isOffline(): boolean {
    return !this.isRDMode && this.mainService.isOffline;
  }

  get isLinux(): boolean {
    return this.mainService.isLinux;
  }

  get isRDMode(): boolean {
    return this.state.isRDMode;
  }

  get isCreate(): boolean {
    return this.mainService.mode === PlanMode.create;
  }

  get isEdit(): boolean {
    return this.mainService.mode === PlanMode.edit;
  }

  get value(): T {
    return this.myValue;
  }

  set value(value: T) {
    this.myValue = value;
    this.notifyValueChange();
  }

  get isHyperV(): boolean {
    return this.state.isHyperV;
  }

  get isVMWare(): boolean {
    return this.state.isVMWare;
  }

  get isRestore(): boolean {
    return this.state.isRestore;
  }

  get isRestorePlan(): boolean {
    return this.planType === PlanTypes.RestorePlan;
  }

  get isRestoreIbb(): boolean {
    return this.planType === PlanTypes.RestoreDiskImagePlan;
  }

  get isIBBPlan(): boolean {
    return this.planType === PlanTypes.BackupDiskImagePlan;
  }

  get isBackupPlan(): boolean {
    return this.planType === PlanTypes.Plan;
  }

  get isSQLPlan(): boolean {
    return this.state.isSQLPlan;
  }

  get canShowFFI(): boolean {
    if (this.isLinux || this.isFFIEnabled || this.isRestore || !this.isNBF) {
      return false;
    }

    return true;
  }

  get isFFIEnabled(): boolean {
    if (this.isLinux || this.isRestore || this.isSQLPlan) {
      return false;
    }

    const rightVersion = this.mainService.backupVersionUpdated && +this.mainService.backupVersionUpdated.substring(0, 2) >= 78;
    return (this.isRDMode && this.isNBF) || (this.isNBF && rightVersion);
  }

  get isAppAwareEnabled(): boolean {
    return this.state.isAppAwareEnabled;
  }

  protected constructor(public mainService: RemoteManagementWizardsService) {}

  /**
   * Method for mass change state
   * Also can consider legacyUnsupportedProperties property
   * @param {String[]} controlNames
   * @param {Boolean} isEnabled
   * @protected
   */
  protected toggleFormControls(controlNames: string[], isEnabled: boolean): void {
    let controls = controlNames;
    const actionName = isEnabled ? 'enable' : 'disable';

    if (isEnabled && !this.isNBF) {
      controls = controls.filter((name) => !this.legacyUnsupportedProperties.includes(name));
    }

    controls.forEach((name: string) => this.stepForm.get(name)?.[actionName]?.());
  }

  abstract initForm(): void;

  /**
   * Don`t forget to use <mbs-wizards-legacy-property-tooltip *ngIf="stepForm.get(...).disabled"></mbs-wizards-legacy-property-tooltip>
   * @param {Boolean} force
   * @protected
   * @see legacyUnsupportedProperties
   * @see getLegacyPropertyChangeHandler
   */
  protected disableLegacyUnsupportedProperties(force = false): void {
    if (this.isNBF) {
      return;
    }

    this.legacyUnsupportedProperties.forEach((key) => {
      if (!this.stepForm.get(key).value || force) {
        this.stepForm.get(key).disable();
      }
    });
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this)];
  }

  protected initFormEvents(): void {
    this.stepForm.valueChanges.pipe(...this.getPipeOperators()).subscribe({ next: (value: T) => this.onStepFormChange(value) });
  }

  onStepFormChange(value: T): void {
    this.value = { ...value, valid: this.stepForm.valid };
  }

  updateForm(value: T): void {
    this.stepForm.reset(value);
  }

  forceValid(data: any = null): void {
    FormsUtil.triggerValidation(this.stepForm);
    this.resetValidStateForValidFields();
  }

  resetValidStateForValidFields(): void {
    if (this.stepForm) {
      const value = this.stepForm.getRawValue();
      for (const key in value) {
        if (!Object.prototype.hasOwnProperty.call(value, key)) continue;

        const ctrl = this.stepForm.get(key) as FormControl;
        if (ctrl.valid) ctrl.reset(value[key]);
      }
    }
  }

  setValues(): void {
    if (this.isLinux) {
      this.regExpForInputAfter = /\/[ ]/;
      this.regExpForInputBefore = /[ ]\//;
      this.splitForInput = '/ ';
      this.splitForInputBefore = ' /';
      this.joinForInput = '/';
    }
  }

  getValueForOperationsWithPath(): DataForPath {
    return {
      isLinux: this.isLinux,
      regExpAfter: this.regExpForInputAfter,
      regExpBefore: this.regExpForInputBefore,
      split: this.splitForInput,
      join: this.joinForInput,
      splitForBefore: this.splitForInputBefore
    };
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: T): void {
    this.myValue = value;
    if (value) this.updateForm(value);
  }

  onChange: (value) => {};
  onTouched: () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected showEnableStepModal(
    modalService: ModalService,
    settings: ModalSettings = { collapsing: true },
    type: AfterEnabledStepActionType = AfterEnabledStepActionType.next
  ): void {
    const isSave = type === AfterEnabledStepActionType.save;
    const actionName = type === AfterEnabledStepActionType.back ? 'backStep' : 'nextStep';

    modalService
      .openCustom(EnableStepModalComponent, settings)
      .then((confirm) => {
        if (!confirm) return void (isSave ? this[actionName].emit(isSave) : this[actionName].emit());

        this.confirmedAsEnabled = true;
      })
      .catch(() => (isSave ? this[actionName].emit(isSave) : this[actionName].emit()));
  }

  getLegacyPropertyChangeHandler(propertyName: string, propertyTitle: string, modalService: ModalService): (newValue: boolean) => void {
    return (newValue: boolean) => {
      if (!this.isNBF && !newValue) {
        this.askDisablingLegacyProperty(propertyTitle, modalService)
          .then((confirmDisabling) => confirmDisabling && this.stepForm.get(propertyName).setValue(false));

        this.stepForm.get(propertyName).setValue(true)
      }
    }
  }

  protected askDisablingLegacyProperty(
    propertyTitle: string,
    modalService: ModalService
  ): Promise<boolean> {

    return modalService
      .openCustom(LegacyPropertyDisablingModalComponent, { collapsing: true, data: { propertyTitle } })
      .then(() => true)
      .catch(() => false);
  }
}
