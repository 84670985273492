export enum Period {
  day = 0,
  week = 1,
  month = 2,
  year = 3
}

export enum PeriodDisplay {
  day = 'Day(s)',
  week = 'Week(s)',
  month = 'Month(s)',
  year = 'Year(s)'
}
