<mbs-modal [isCustomModal]="true"
           [title]="'computers.module:modals:agentVersionOfflineEditTitle' | i18next: { format: 'title' }">
  <div modal-body>
    <mbs-alert class="mb-0"
               [type]="alertType.warning"
               [icon]="true">
      {{'computers.module:modals:agentVersionOfflineEditText' | i18next}}
    </mbs-alert>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                data-dismiss="modal"
                (click)="handleClose()">
      {{ 'app:buttons:close' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
