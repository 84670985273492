import { Injectable } from '@angular/core';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { AgentOptionsService } from '@services/agent-options.service';
import { RemoteDeployDataService } from '@services/data-services/remote-deploy-data.service';
import { AbilityService } from 'ability';
import { RemoteManagementWizardsService } from './remote-management-wizards.service';

@Injectable({
  providedIn: 'root'
})
export class WizardsServicesHubService {
  constructor(
    public ability: AbilityService,
    public mainService: RemoteManagementWizardsService,
    public rdService: RemoteDeployDataService,
    public agentOptionsService: AgentOptionsService,
    public backupFacade: ComputerBackupFacade
  ) {}
}
