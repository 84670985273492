import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'mbs-recovery-code',
  templateUrl: './recovery-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecoveryCodeComponent {
  @Input() isLogin = false;
  @Input() isResetCodes = false;
  @Input() codes;
  @Output() onSaveRecoveryCodes = new EventEmitter<boolean>();

  public isSavedRecoveryCodes = false;

  public codesGeneratedDate = this.i18.transform('tfa:recoveryCode:generationCodesDate', {
    date: this.datePipe.transform(new Date(), 'medium')
  });

  constructor(
    public authService: AuthService,
    private router: Router,
    private clipboard: Clipboard,
    private i18: I18NextPipe,
    private datePipe: DatePipe
  ) {}

  finish() {
    this.router.navigateByUrl('AP/Default.aspx');
  }

  copy() {
    this.clipboard.copy([this.codesGeneratedDate, ...this.codes].join('\r\n'));
  }

  save() {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,%EF%BB%BF' + encodeURIComponent([this.codesGeneratedDate, ...this.codes].join('\r\n'))
    );
    element.setAttribute('download', 'codes.txt');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  print() {
    const windowInstance = window.open('', 'PRINT', 'height=400,width=600');

    const elements = this.codes?.reduce((acc, el) => {
      return acc + `<div>${el}</div>`;
    }, '');

    windowInstance.document.write(`
      ${this.codesGeneratedDate}
      ${elements}
    `);

    windowInstance.document.close();
    windowInstance.focus();
    windowInstance.print();
    windowInstance.close();
  }
}
