import { RecurType } from '@models/RecurType.enum';
import { WizardHelpersSettings } from '@modules/wizards/helpers/bases/base-for-steps-helper';
import { ISOFormatWithSec, MaxDate, MinDate, TimeSpanMaxValue, UTCFormatWithSec } from '@utils/constants/c-sharp-constants';
import { windowsUpperCaseInvalidPath } from '@utils/constants/folders-path-constants';
import {
  baseDateFormatWithSeconds,
  cobbledDateFormat,
  dateTimePointsFormat,
  dateTimePointsReg,
  getFullDateFromDateTimeObject,
  getFullISODateFromDateTimeObject,
  GetTimeSpanString
} from '@utils/date';
import * as moment from 'moment';
import { AzureStepValue } from '../../models/azure-models';
import { BackupToRestoreStepValue } from '../../models/backup-to-restore-models';
import { DeletePlan } from '../../models/base/base-models/plan-restore-delete-plan-model';
import { RestoreToAzureArgs } from '../../models/base/base-models/plan-restore-to-azure-args-model';
import { RestoreToEC2Args } from '../../models/base/base-models/plan-restore-to-ec2-args-model';
import { DestinationStepValue } from '../../models/destination-models';
import { EC2StepValue } from '../../models/EC2-models';
import { EncryptionOptionsStepValue } from '../../models/encryption-options-models';
import { PartitionsStepValue } from '../../models/partitions-models';
import { PlanNameStepValue } from '../../models/plan-name-models';
import { RestorePointStepValue, RestoreType } from '../../models/restore-point-models';
import { RestoreSourceStepValue } from '../../models/restore-source-models';
import { ScheduleAdvancedStepValue } from '../../models/schedule-advanced-models';
import { ScheduleStepValue, ScheduleType } from '../../models/schedule-models';
import { TemporaryInstanceStepValue } from '../../models/temporary-instance-models';
import {
  AzureRestoreTypeEnum,
  DiskImageRestoreTypeEnum,
  EC2RestoreTypeEnum,
  RestoreAsDiskEnum,
  TypeDataStepValue
} from '../../models/type-data-models';
import { SupportMethodsForPlanFromSteps } from '../support-methods-for-plan-from-steps';

export class RestorePlanFromStepsHelper {
  public static updateNewPlanByNameStep(newPlan, step: PlanNameStepValue): void {
    newPlan.Name = step.Name;
    newPlan.DeletePlan = step.SavePlanInCloud ? DeletePlan.Never : DeletePlan.OnSucceeds;
    newPlan.SavePlanInCloud = false;
  }

  public static updateNewPlanByTypeDataStep(newPlan, step: TypeDataStepValue): void {
    switch (step.restoreAs) {
      case RestoreAsDiskEnum.Physical:
        newPlan.DiskImageRestoreType = DiskImageRestoreTypeEnum.PhysicalDisk;
        newPlan.DiskImageFileFormat = {
          DisplayName: 'Hyper-V Virtual Disk (VHDX-format) dynamic',
          Name: 'VHDX',
          UseBuilder: true,
          VariantName: 'dynamic'
        };
        break;
      case RestoreAsDiskEnum.Virtual:
        newPlan.DiskImageRestoreType = DiskImageRestoreTypeEnum.VirtualDisk;
        newPlan.DiskImageFileFormat =
          newPlan.VirtualDiskFileFormatList && newPlan.VirtualDiskFileFormatList.length
            ? newPlan.VirtualDiskFileFormatList.find((f) => f.DisplayName === step.selectedFileFormat)
            : SupportMethodsForPlanFromSteps.getDiskImageFileFormat(step.selectedFileFormat);
        break;
      case RestoreAsDiskEnum.EC2:
        newPlan.DiskImageRestoreType = DiskImageRestoreTypeEnum.RestoreToEC2;
        if (!newPlan.RestoreToEC2) newPlan.RestoreToEC2 = new RestoreToEC2Args();
        newPlan.RestoreToEC2.EC2RestoreType = step.createAMI ? EC2RestoreTypeEnum.EC2Image : EC2RestoreTypeEnum.EC2Instance;
        newPlan.DiskImageFileFormat = {
          DisplayName: 'Hyper-V Virtual Disk (VHDX-format) dynamic',
          Name: 'VHDX',
          UseBuilder: true,
          VariantName: 'dynamic'
        };
        break;
      case RestoreAsDiskEnum.EBSVolume:
        newPlan.DiskImageRestoreType = DiskImageRestoreTypeEnum.RestoreToEC2;
        if (!newPlan.RestoreToEC2) newPlan.RestoreToEC2 = new RestoreToEC2Args();
        newPlan.RestoreToEC2.EC2RestoreType = EC2RestoreTypeEnum.EC2Volume;
        break;
      case RestoreAsDiskEnum.AVM:
        newPlan.DiskImageRestoreType = DiskImageRestoreTypeEnum.RestoreToAzure;
        if (!newPlan.RestoreToAzure) newPlan.RestoreToAzure = new RestoreToAzureArgs();
        newPlan.RestoreToAzure.AzureRestoreType = AzureRestoreTypeEnum.AzureInstance;
        break;
      case RestoreAsDiskEnum.ADD:
        newPlan.DiskImageRestoreType = DiskImageRestoreTypeEnum.RestoreToAzure;
        if (!newPlan.RestoreToAzure) newPlan.RestoreToAzure = new RestoreToAzureArgs();
        newPlan.RestoreToAzure.AzureRestoreType = AzureRestoreTypeEnum.AzureDataDisk;
        break;
    }
  }

  public static updatePlanFromAzureStep(plan, step: AzureStepValue): void {
    plan.RestoreToAzure.AzureVMConnectionID = step.account;
    plan.RestoreToAzure.InstanceName = step.computerName || step.dataDiskName;
    plan.RestoreToAzure.Location = step.locations;
    plan.RestoreToAzure.ResourceGroupName = step.resourceGroup;
    plan.RestoreToAzure.VMSize = step.vmSize;
    plan.RestoreToAzure.NetworkName = step.network;
    plan.RestoreToAzure.SubnetName = step.subnet;
    plan.RestoreToAzure.StorageName = step.storage;
    plan.RestoreToAzure.BucketName = step.container;
    plan.RestoreToAzure.BootDiagnosticStorageName = step.bootDiagnosticStorage;
  }

  public static updatePlanFromEC2(plan, step: EC2StepValue): void {
    plan.RestoreToEC2.EC2ConnectionId = step.account;
    plan.RestoreToEC2.RegionName = step.region;
    plan.RestoreToEC2.AvailabilityZone = step.availabilityZones;
    plan.RestoreToEC2.InstanceType = step.instanceType;
    plan.RestoreToEC2.SubnetId = step.subnet;
    plan.RestoreToEC2.SecurityGroupId = step.securityGroup;
  }

  public static updatePlanFromTemporaryInstance(plan, step: TemporaryInstanceStepValue): void {
    plan.RestoreToEC2TemporaryInstance.IsRemoteRestore = step.useTemporary;
    plan.RestoreToEC2TemporaryInstance.EC2ConnectionId = step.account;
    plan.RestoreToEC2TemporaryInstance.RegionName = step.region;
    plan.RestoreToEC2TemporaryInstance.AvailabilityZone = step.availabilityZones;
    plan.RestoreToEC2TemporaryInstance.InstanceType = step.instanceType;
    plan.RestoreToEC2TemporaryInstance.SubnetId = step.subnet;
    plan.RestoreToEC2TemporaryInstance.SecurityGroupId = step.securityGroup;
    plan.RestoreToEC2TemporaryInstance.AMI = step.ami;
  }

  public static updateNewPlanByPartitionsStep(newPlan, partitionsStep: PartitionsStepValue): void {
    newPlan.DiskInfo =
      partitionsStep.restoreParentPartitions && partitionsStep.restoreParentPartitions.length
        ? partitionsStep.restoreParentPartitions.sort((a, b) => a.diskNumber - b.diskNumber)
        : [];
    newPlan.ConvertToMBR = partitionsStep.convertToMBR;
  }

  public static updateNewPlanByDestinationStep(
    newPlan,
    step: DestinationStepValue,
    partitionsStep: PartitionsStepValue,
    forIBB = true,
    needRestoreDeleted = true
  ): void {
    if (forIBB) {
      newPlan.Destination = step.destinationFolder;
      newPlan.SpecificPartitions = newPlan.DiskImageRestoreType !== DiskImageRestoreTypeEnum.VirtualDisk && !step.isPhysical;
      newPlan.OverwriteExisting = newPlan.DiskImageRestoreType === DiskImageRestoreTypeEnum.VirtualDisk;
      // newPlan.VirtualImageFileName = step.virtualImageName;

      const newDisks: any[] = [];
      partitionsStep.restorePartitions.forEach((disk) => {
        const newValue: any = newDisks.length
          ? newDisks[0]
          : {
              Capacity: step.capacity ? step.capacity * 1024 * 1024 * 1024 : 0,
              RestoreVolumes: [],
              SourceDiskId: disk.diskId
            };
        if (newPlan.DiskImageRestoreType !== DiskImageRestoreTypeEnum.VirtualDisk) {
          const idx =
            step.selectedSpecificPartitions && step.selectedSpecificPartitions.length
              ? step.selectedSpecificPartitions.findIndex((p) => {
                  const pData = p.split('--');
                  return pData.length && pData[2] && pData[2].includes(disk.identityForUiField);
                })
              : -1;
          if (!step.isPhysical && idx !== -1) {
            const pData = step.selectedSpecificPartitions[idx].split('--');
            const what = pData[2].split('__');
            const to = pData[0].split('__');
            newValue.DestinationDiskId = to[1];
            newValue.RestoreVolumes.push({
              Length: disk.length,
              ExcludeRules: partitionsStep.restoreConfigs[`${disk.diskId}__${disk.identity}`] || [],
              SourceVolumeId: { Identity: what[0], Enabled: true, DiskId: what[1], Supported: disk.supported },
              DestinationVolumeId: { Identity: to[0], Enabled: true, DiskId: to[1], Supported: disk.supported }
            });
          } else {
            newValue.DestinationDiskId = step.selectedDisk;
            newValue.RestoreVolumes.push({
              Length: disk.length,
              ExcludeRules: partitionsStep.restoreConfigs[`${disk.diskId}__${disk.identity}`] || [],
              SourceVolumeId: { Identity: disk.identity, Enabled: true, DiskId: disk.diskId, Supported: disk.supported },
              DestinationVolumeId: null
            });
          }
        } else {
          newValue.Name = step.virtualImageName;
          newValue.RestoreVolumes.push({
            Length: disk.length,
            ExcludeRules: partitionsStep.restoreConfigs[`${disk.diskId}__${disk.identity}`] || [],
            SourceVolumeId: { Identity: disk.identity, Enabled: true, DiskId: disk.diskId, Supported: disk.supported },
            DestinationVolumeId: null
          });
        }
        if (!newDisks.length) newDisks.push(newValue);
      });
      newPlan.RestoreDiskConfiguration = newDisks;
    } else {
      newPlan.RestoreToOriginalLocation = !!step.toOriginalLocation;
      if (!step.toOriginalLocation) newPlan.Destination = step.destinationFolder;
      if (needRestoreDeleted) newPlan.RestoreDeletedFiles = !!step.restoreDeletedFiles;
      newPlan.SkipExistingFiles = !step.overwriteExistingFiles;
      if (step.overwriteExistingFiles) newPlan.OnlyNewFiles = step.restoreOnlyNewFiles;
      else newPlan.OnlyNewFiles = false;
      newPlan.RestoreNTFSPermissions = !!step.restoreNTFSPermissions;
      // TODO Set plan data from saveLocationAsDefault...
    }
  }

  public static updateNewPlanByEncryptionOptionsStep(newPlan, step: EncryptionOptionsStepValue): void {
    if (!(step.isCryptWithPassword === null || step.isCryptWithPassword === undefined)) {
      newPlan.UseEncryption = step.isCryptWithPassword;
      if (newPlan.UseEncryption) {
        SupportMethodsForPlanFromSteps.updatePlanPassword(newPlan, step.password);
      } else {
        delete newPlan.EncryptionPassword;
      }
    } else {
      newPlan.UseEncryption = !!step.password;
      SupportMethodsForPlanFromSteps.updatePlanPassword(newPlan, step.password);
    }
  }

  public static updateNewPlanByRestorePointStep(
    newPlan,
    step: RestorePointStepValue,
    settings: WizardHelpersSettings,
    isIbb = false
  ): void {
    if (step.restoreType === RestoreType.Manually) {
      newPlan.RestoreType = step.restoreType;
      newPlan.UseBackupDate = false;
      newPlan.RestoreFromPointInTime = MinDate;
      newPlan.PointInTime = isIbb ? moment(getFullDateFromDateTimeObject(step.fromDateTime, true)).format(ISOFormatWithSec) : MaxDate;
    } else if (step.restoreType === RestoreType.LatestVersion) {
      newPlan.RestoreType = step.restoreType;
      newPlan.PointInTime = MaxDate;
    } else if (step.restoreType === RestoreType.PointInTime) {
      newPlan.RestoreType = step.restoreType;
      if (settings.isNBF) {
        newPlan.PointInTime = moment(getFullDateFromDateTimeObject(step.fromDateTime, true)).format(ISOFormatWithSec);
      } else {
        newPlan.PointInTime = moment
          .utc(getFullISODateFromDateTimeObject(step.fromDateTime))
          .utcOffset(settings.timeZoneOffset * -1)
          .format(UTCFormatWithSec);
      }
      newPlan.UseBackupDate = false;
      newPlan.RestoreFromPointInTime = MinDate;
    } else if (step.restoreType === RestoreType.ModificationPeriod || step.restoreType === RestoreType.BackupPeriod) {
      newPlan.RestoreType = RestoreType.PointInTime;
      if (settings.isNBF) {
        newPlan.PointInTime = moment(getFullDateFromDateTimeObject(step.toDateTime, true)).format(ISOFormatWithSec);
        newPlan.RestoreFromPointInTime = moment(getFullDateFromDateTimeObject(step.fromDateTime, true)).format(ISOFormatWithSec);
      } else {
        newPlan.PointInTime = moment
          .utc(getFullISODateFromDateTimeObject(step.toDateTime))
          .utcOffset(settings.timeZoneOffset * -1)
          .format(UTCFormatWithSec);
        newPlan.RestoreFromPointInTime = moment
          .utc(getFullISODateFromDateTimeObject(step.fromDateTime))
          .utcOffset(settings.timeZoneOffset * -1)
          .format(UTCFormatWithSec);
      }
      newPlan.UseBackupDate = step.restoreType === RestoreType.BackupPeriod;
    }
    if (settings.isLinux && !settings.isNBF) newPlan.SyncBeforeRun = !!step.syncBeforeRun;
  }

  public static updateNewPlanByRestoreSourceStep(newPlan, step: RestoreSourceStepValue, isLinux = false, computerName = ''): void {
    const join = isLinux ? '/' : '\\';
    const linuxOnWindowsPC = step.folders.every((str) => !isLinux && windowsUpperCaseInvalidPath.test(str));
    const prefixWithCompName = `\\\\${computerName}\\`;
    const getModifiedPath = (str: string) => (!isLinux && str.endsWith(join) ? str.substring(0, str.length - 1) : str);

    newPlan.Versions = [];
    newPlan.Items = [];

    if (linuxOnWindowsPC && newPlan.RestoreType !== RestoreType.Manually) {
      newPlan.Items = step.folders.map((f) => ({ Path: prefixWithCompName + getModifiedPath(f) }));

      return;
    }

    if (newPlan.RestoreType !== RestoreType.Manually) {
      newPlan.Items = step.folders.map((f) => ({ Path: getModifiedPath(f) }));

      return;
    }

    step.folders.forEach((str) => {
      const strArr = str.split(join);
      const lastElem = strArr[strArr.length - 1];
      const existNormal = dateTimePointsReg.test(lastElem);
      const existCobble = dateTimePointsFormat.test(lastElem);
      const exist = existNormal || existCobble;
      const result: { Path?: string; ModifyDate?: string } = {};

      if (exist) {
        const newPath = strArr.splice(0, strArr.length - 1).join(join);
        result.ModifyDate = existCobble ? moment.utc(lastElem, cobbledDateFormat).format(baseDateFormatWithSeconds) + 'Z' : lastElem;
        result.Path = getModifiedPath(newPath);

        if (linuxOnWindowsPC) result.Path = prefixWithCompName + result.Path;

        return void newPlan.Versions.push(result);
      }

      result.Path = getModifiedPath(str);

      if (linuxOnWindowsPC) result.Path = prefixWithCompName + result.Path;

      newPlan.Items.push(result);
    });
  }

  public static updateNewPlanByBackupToRestoreStep(newPlan, step: BackupToRestoreStepValue): void {
    newPlan.BackupPlanName = step.backupPlanName;
  }

  public static updateNewPlanByScheduleStep(
    newPlan,
    step: ScheduleStepValue,
    advancedStep: ScheduleAdvancedStepValue,
    isLinux = false,
    isNBF = false
  ): void {
    const recTypeNumber = +ScheduleType[step.ScheduleType];
    if (!isLinux || isNBF) newPlan.SyncBeforeRun = step.syncBeforeRun;
    if (recTypeNumber === ScheduleType.recurring || recTypeNumber == ScheduleType.once) {
      SupportMethodsForPlanFromSteps.SetPlanScheduleSettingsAdvanced(step, newPlan, advancedStep, isLinux, true);
    } else {
      newPlan.ForceFullSchedule = { Enabled: false };
      newPlan.Schedule = { Enabled: false };
      newPlan.ScheduleDiff = { Enabled: false };
      newPlan.ScheduleTLog = { Enabled: false };
      const stopAfterDuration = moment.duration({ hours: step.stopAfterHours, minutes: step.stopAfterMinutes });
      newPlan.Schedule = {
        Enabled: recTypeNumber !== ScheduleType.noschedule,
        StopAfter: !step.StopAfterEnabled ? TimeSpanMaxValue : GetTimeSpanString(stopAfterDuration)
      };
      if (recTypeNumber == ScheduleType.instantly) newPlan.ForceFullSchedule.RecurType = RecurType.Instantly;
      newPlan.ForceMissedSchedule = !!step.ForceMissedSchedule;
      newPlan.UseDifferentialUpload = false;
    }
    SupportMethodsForPlanFromSteps.delStopAfterTickFromPlan(newPlan);
  }
}
