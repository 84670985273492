import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { bytesFrom, Size } from '../../utils';
import { DropLabelTextDirective } from './drop-label-text.directive';
import { isNil } from 'lodash';

@Component({
  selector: 'mbs-drop-file',
  templateUrl: './drop-file.component.html'
})
export class DropFileComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() imageMode = false;
  @Input() returnBase64Allways = false;
  @Input() defaultImage = '';
  @Input() labelText = '';
  @Input() id = `f${(+new Date()).toString(16)}` + `f${(~~(Math.random() * 1e8)).toString(16)}`;
  @Input() labelClasses = '';
  @Input() accept = '';
  @Input() hideIconIfError = false;
  @Input() clearButton = false;
  @Input() hideTextError = false;
  @Input() horizontal = false;
  @Input() disabled: boolean;
  @Input() maxFileSize: string;
  @Input() testByDimensions: { height: number; width: number } = null;
  get disabledState(): boolean {
    return !isNil(this.disabled) && this.disabled !== false;
  }

  @Output() fileLoad = new EventEmitter<any>();

  @Output() fileValidation = new EventEmitter<boolean>();

  @Output() updateError = new EventEmitter<string>();

  @Output() clear = new EventEmitter<void>();

  @ContentChild(DropLabelTextDirective, { static: true, read: DropLabelTextDirective }) DropLabelTextTemplate: DropLabelTextDirective;

  @ViewChild('fileInputElement', { static: false }) public fileInputElement: ElementRef<HTMLInputElement>;

  public showLabel = false;
  public imageSrc: string;
  public file: File;
  private _error = '';

  set error(value: string) {
    this.updateError.emit(value);
    this._error = value;
  }
  get error(): string {
    return this._error;
  }

  public sizeText: string;
  public sizeNum: number;

  constructor() {}

  ngOnInit(): void {
    // empty
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultImage && changes.defaultImage.currentValue) {
      this.imageSrc = changes.defaultImage.currentValue as string;
    }
    if (changes.maxFileSize) {
      this.sizeText = this.maxFileSize.split(/\d+/).pop() || 'kb';
      this.sizeNum = +this.maxFileSize.split(/\D+/).shift() || 500;
    }
  }

  ngAfterViewInit(): void {
    // empty
  }

  dragOverHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.showLabel = true;
  }

  dragLeaveHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.showLabel = false;
  }

  clearInput() {
    if (this.fileInputElement && this.fileInputElement.nativeElement) {
      this.fileInputElement.nativeElement.value = '';
    }
  }

  handleClear(event: MouseEvent = null, elem: HTMLInputElement = null): void {
    if (event && elem) {
      event.preventDefault();
      elem.value = '';
    }
    this.file = null;
    this.imageSrc = this.defaultImage;
    this.error = '';
    this.clear.emit();
  }

  validationLoadFile(file: File): boolean {
    const hasFileType = !!file.type;
    const fileTypeOrName = hasFileType ? file.type : file.name;


    if (!!this.accept && !fileTypeOrName.includes(hasFileType ? this.accept : `.${this.accept}`)) {
      this.error = `Only "${this.accept}" types are allowed.`;
      return false;
    }

    if (this.maxFileSize) {
      const size = this.sizeText.toUpperCase() as Size;
      const fileSize = +(file.size / bytesFrom(size)).toFixed(2);
      if (fileSize > this.sizeNum) {
        this.error = `Max file size "${this.maxFileSize}".`;
        return false;
      }
    }
    this.error = '';
    return true;
  }

  async fileLoadHandler(event: FileList): Promise<undefined> {
    if (event && event.length) {
      const file = event[0];
      if (!this.validationLoadFile(file)) {
        this.fileValidation.emit(false);
        return undefined;
      }

      const base64: string | ArrayBuffer | void = await this.toBase64(file).catch((err) => {
        console.log('error while reading file', err);
      });

      let result = null;
      if (this.imageMode) {
        if (this.testByDimensions) {
          const error = await this.dimensionsValidate(base64 as string);
          if (error) {
            this.fileValidation.emit(false);
            this.error = error;
            return undefined;
          }
        }
        this.imageSrc = base64 as string;
        this.file = file;
        result = { base64, file: file };
      } else {
        this.file = file;
        result = this.returnBase64Allways ? { base64, file: file } : file;
      }
      this.fileValidation.emit(true);
      this.fileLoad.emit(result);
      this.showLabel = false;
    }
    return undefined;
  }

  async dimensionsValidate(base64: string): Promise<string> {
    const width = this.testByDimensions.width;
    const height = this.testByDimensions.height;
    let testResult = '';
    const img = new Image();
    img.src = base64;
    return new Promise<string>((resolve, reject) => {
      img.onload = (e: Event) => {
        const image = (e.composedPath().length ? e.composedPath()[0] : e.target) as HTMLImageElement; // e.path[0] - Chrome, e.target - Firefox
        if (!this.targetHasValidSizes(image, width, height)) {
          testResult = `The image width and height should not be more than: ${width} and ${height}.`;
        }
        return resolve(testResult);
      };
    });
  }

  toBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  private targetHasValidSizes(target: HTMLImageElement, maxWidth: number, maxHeight: number): boolean {
    const targetHasWidthAndHeight = target && target.width && target.height;
    return targetHasWidthAndHeight && target.height <= maxHeight && target.width <= maxWidth;
  }
}
