import { cloneDeep } from 'lodash';
import { SmartSearchCharacters, SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { getSmartSearchTagValues } from 'mbs-ui-kit/smart-search/models/smart-search-model';
import { SmartSearchValidationState } from 'mbs-ui-kit/smart-search/models/smart-search-models';
import { SmartSearchValues } from '../models/SmartSearchValues';

export class SmartSearchUtils {
  public static initialUpdateModelDelay = 300;

  public static getValues(model: SmartSearchModel, separator = ' '): SmartSearchValues {
    return Object.fromEntries(
      Object.entries(model).map(([key, value]) => [
        key,
        (value as SmartSearchModelField[]).map((d) => (d.condition || '') + d.value.replace(/\s/g, separator))
      ])
    );
  }

  public static getJoinedValue(value: SmartSearchValues, key: string, separator = ' '): string {
    return (value && value[key] && value[key]?.join(separator)) || '';
  }

  public static toggleValueByTag(model: SmartSearchModel, tag: string, value: string): SmartSearchModel {
    const smartModel = cloneDeep(model);
    let tagValues = (smartModel[tag] ?? []) as SmartSearchModelField[];

    tagValues = tagValues.some((tagValue) => tagValue.value === value)
      ? tagValues.filter((tagValue) => tagValue.value !== value)
      : [...tagValues, { condition: undefined, value }];

    if (!tagValues.length) {
      delete smartModel[tag];

      return smartModel;
    }

    return { ...model, [tag]: tagValues };
  }

  public static prepareSmartSearchStringByTag(tag: string, value: string): string {
    return `${tag}: ${
      value.includes(SmartSearchCharacters.SPACE) ? SmartSearchCharacters.GROUP_LEFT + value + SmartSearchCharacters.GROUP_RIGHT : value
    }`;
  }

  // Smart Search Validation
  public static haveValues(tagName: string, search: SmartSearchModel): boolean {
    return !!getSmartSearchTagValues(tagName, search).length;
  }

  public static areValuesByTagValid(tagName: string, search: SmartSearchModel, values: string[]): SmartSearchValidationState {
    const currentValues = getSmartSearchTagValues(tagName, search);

    if (!currentValues.length) return SmartSearchValidationState.Unknown;

    return this.areValuesValid(currentValues, values) ? SmartSearchValidationState.Valid : SmartSearchValidationState.Invalid;
  }

  public static areValuesValid(currentValues: string[], possibleValues: string[]): boolean {
    return (currentValues || []).every((value) => (!possibleValues ? true : possibleValues.includes(value.toLowerCase())));
  }
}
