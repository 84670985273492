import { AzureRestoreTypeEnum } from '../../type-data-models';
import { AzureStorageTypeEnum } from './plan-azure-storage-type-model';
import { AzureVmTypeEnum } from './plan-azure-vm-type-model';
import { OSType } from './plan-os-type-model';

export class RestoreToAzureArgs {
  public AvailableDiagnosticStorageTypes: string[];
  public VMSize: string;
  public AzureTempInstanceDeploymentTemplate: string;
  public OSType: OSType;
  public RemoteRestorePrefix: string;
  public IsRemoteRestore: boolean;
  public BootDiagnosticStorageName: string;
  public EnableBootDiagnostic: boolean;
  public AzureVMConnectionID: string;
  public InstanceNameTag: string;
  public InstancePlanTag: string;
  public LeftIntermediateFilesInStorage: boolean;
  public RefreshToken: string;
  public SubscriptionId: string;
  public ResourceGroupName: string;
  public BucketName: string;
  public StorageName: string;
  public SubnetName: string;
  public NetworkName: string;
  public PublicIpName: string;
  public AzureValidDiskName: string;
  public AzureValidInstanceName: string;
  public InstanceName: string;
  public Location: string;
  public AzureStorageType: AzureStorageTypeEnum;
  public AzureVmType: AzureVmTypeEnum;
  public AzureRestoreType: AzureRestoreTypeEnum;
}
