import BrandLicense from '@models/BrandLicense';
import { AdministratorLicense } from '@models/licenses/administrator-license';
import { License } from '../../models/licenses/License';

export class LicensesHelper {
  public static IsLicenseExpired(license: License): boolean {
    const now = new Date();
    return now > new Date(license.dateExpired);
  }
  public static isLicenseExpiresAfterManyYears(license: License | BrandLicense | AdministratorLicense): boolean {
    const manyYears = 9000;
    const expiredDate = new Date(license.dateExpired);

    return expiredDate.getFullYear() > manyYears;
  }
}
