export enum RecurType {
  Once = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  DayOfMonth = 4,
  Instantly = 5,
  Periodically = 6,
  Yearly = 7
}

/**
 * Provide text values according to Recurring Period
 * (according to the current Schedule Options form design)
 */
export enum RecurTypeText {
  Daily = 'Week(s)',
  Monthly = 'Month(s)'
}

/**
 * Provide text values according to Recurring Period
 * (according to the current Advanced Schedule Options form design)
 */
export enum AdvancedRecurTypeText {
  Daily = 'Day(s)',
  Weekly = 'Week(s)',
  Monthly = 'Month(s)',
  DayOfMonth = 'Month(s)',
  Yearly = 'Year(s)'
}
