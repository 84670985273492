import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreTypes } from '@store/storeTypes.enum';
import { OnlineAccessPagingEffects } from './effects';
import { onlineAccessPagingReducer } from './reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(StoreTypes.OnlineAccessComputersPaging, onlineAccessPagingReducer),
    EffectsModule.forFeature([OnlineAccessPagingEffects])
  ]
})
export class OnlineAccessPagingStoreModule {}
