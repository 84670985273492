import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RestoreAsDiskEnum, TypeDataStepValue } from '../../../models/type-data-models';
import { RemoteManagementWizardsService } from '../../../services/remote-management-wizards.service';
import { StepBase } from '../../StepBase.class';

const TypeDataStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => TypeDataStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-type-data-step',
  templateUrl: './type-data-step.component.html',
  providers: [TypeDataStepValueAccessor]
})
export class TypeDataStepComponent extends StepBase<TypeDataStepValue> {
  @Input() fileFormats = [];
  @Output() restoreAsChange = new EventEmitter();

  constructor(public mainService: RemoteManagementWizardsService) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      restoreAs: new FormControl(0),
      createAMI: new FormControl(false),
      selectedFileFormat: new FormControl('', [Validators.required])
    });

    this.initFormEvents();
  }

  updateForm(value: TypeDataStepValue): void {
    if (!value.selectedFileFormat && this.fileFormats.length) value.selectedFileFormat = this.fileFormats[0];

    this.stepForm.reset(value);

    this.changeRestoreAsHandler(value.restoreAs);
  }

  changeRestoreAsHandler(event): void {
    if (event === RestoreAsDiskEnum.EC2) this.stepForm.get('createAMI').enable();
    else this.stepForm.get('createAMI').disable();
    if (event === RestoreAsDiskEnum.Virtual) this.stepForm.get('selectedFileFormat').enable();
    else this.stepForm.get('selectedFileFormat').disable();
    this.restoreAsChange.emit(event);
  }
}
