import { Component, forwardRef, Input, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import Administrator from '@models/Administrator';
import { AgentType } from '@models/Computer';
import { WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { PasswordFromPlan } from '@utils/constants/misc-constants';
import { FormsUtil, ModalService, WizardStep } from 'mbs-ui-kit';
import { EncryptionOptionsStepValue } from '../../models/encryption-options-models';
import { RestorePointItem } from '../../models/restore-point-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { StepBase } from '../StepBase.class';

const EncryptionOptionsStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => EncryptionOptionsStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-encryption-options-step',
  templateUrl: './encryption-options-step.component.html',
  providers: [EncryptionOptionsStepValueAccessor]
})
export class EncryptionOptionsStepComponent extends StepBase<EncryptionOptionsStepValue> {
  @Input() resultPoint: RestorePointItem;
  @Input() backupToRestore: any;

  public readonly elementsSelector = {
    id: {
      password: 'encryption-options-password'
    },
    name: {
      recoveryAlert: 'recoveryAlert',
      nbfPasswordBlock: 'nbfPasswordBlock',
      notNBFPasswordBlock: 'notNBFPasswordBlock',
      passwordRecoveryBlock: 'passwordRecoveryBlock',
      hintBlock: 'hintBlock',
      hintTextBlock: 'hintTextBlock'
    }
  };

  public isProvider: boolean;
  public passwordRecoveryEnabled: boolean;
  public providerEmail: string;
  public showPassword = false;
  public showHint = false;
  public allowedRecovery = false;
  public InitialValue: EncryptionOptionsStepValue;
  public passwordControlFacade = new FormControl('');

  private needCheckPassword = false;
  private invalidPassword = false;
  private get canCheckPassword(): boolean {
    return +this.mainService?.backupVersionUpdated.substring(0, 3) >= 792;
  }

  constructor(
    public mainService: RemoteManagementWizardsService,
    private modalService: ModalService,
    private auth: AuthService,
    private stepService: WizardStepsService
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((user: Administrator) => {
      this.isProvider = user.IsProvider;
      this.passwordRecoveryEnabled = user.ProviderInfo.PasswordRecoveryEnabled;
      this.providerEmail = user.ProviderInfo.EmailNotificationsTo;
      this.allowedRecovery = user.ProviderInfo.PasswordRecoveryEnabled && user.IsProvider;
    });

    this.initForm();

    this.auth.fetchCurrentUser();
    this.mainService.password.asObservable().subscribe((value) => this.stepForm.get('password').setValue(value || this.value?.password));
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      isCryptWithPassword: new FormControl(false),
      password: new FormControl('', [
        Validators.required,
        () => {
          return this.invalidPassword ? { invalidPassword: true } : null;
        }
      ])
    });

    this.initFormEvents();
  }

  protected initFormEvents(): void {
    let lastPassword: string;

    super.initFormEvents();

    this.stepForm
      .get('isCryptWithPassword')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((v) => this.changeIsCryptWithPasswordHandler(v));

    this.stepForm
      .get('password')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (lastPassword !== value) {
          lastPassword = value;

          this.needCheckPassword = true;
        }
      });

    this.passwordControlFacade.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.stepForm.get('password').setValue(value);

      delete this.needCheckPassword;
      delete this.invalidPassword;

      this.stepForm.get('password').updateValueAndValidity();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.stepForm) return;

    if (changes.backupToRestore && (this.isCreate || this.resultPoint)) {
      this.showHint = false;
      this.stepForm.get('password').reset(this.mainService.password.value || '');
    }

    if (changes.backupToRestore && this.resultPoint) this.resetIsCryptWithPassword();

    if (changes.resultPoint && this.isNBF) {
      this.stepForm.get('isCryptWithPassword').disable();
      this.changeIsCryptWithPasswordHandler(true);
    }
  }

  private resetIsCryptWithPassword(needResetCrypt = true): void {
    this.toggleFormControls(['isCryptWithPassword'], !this.isNBF);

    if (this.isNBF) return this.changeIsCryptWithPasswordHandler(true);

    if (needResetCrypt) {
      this.stepForm.get('isCryptWithPassword').setValue(false);
      this.changeIsCryptWithPasswordHandler(false);
    }
  }

  checkPassword(): void {
    const rightVersion = this.canCheckPassword;
    const value = this.stepForm.get('password').value;

    if (!rightVersion) {
      return void delete this.needCheckPassword;
    }

    if (!value || !this.resultPoint) {
      return;
    }

    this.loadInfo.emit({ loading: true });

    this.stepService
      .getRemoteCommandData(
        {
          agentType: AgentType.Backup,
          commandType: 'VerifyBackupPassword',
          params: {
            path: this.resultPoint.path,
            password: value
          }
        },
        this.mainService.hid
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (answer) => {
          this.invalidPassword = !answer?.data?.result;

          this.loadInfo.emit({ loading: false });

          if (!this.invalidPassword) {
            delete this.needCheckPassword;
          }

          this.stepForm.get('password').updateValueAndValidity();
        },
        error: () => {
          this.needCheckPassword = true;
          this.invalidPassword = true;

          this.loadInfo.emit({ loading: false });
          this.stepForm.get('password').updateValueAndValidity();
        }
      });
  }

  updateForm(value: EncryptionOptionsStepValue): void {
    if (this.isEdit) this.InitialValue = value;

    this.stepForm.reset(value);

    this.resetIsCryptWithPassword(this.isCreate);

    const passValid = value.password && value.password !== PasswordFromPlan;
    if (this.isNBF && this.isRestoreIbb && this.backupToRestore && passValid && (this.isCreate || this.resultPoint)) {
      queueMicrotask(() => {
        this.showHint = false;
        this.stepForm.get('password').reset(value.password || '');
      });
    }
  }

  forceValid(step: WizardStep & { isSave?: boolean; isNext?: boolean } = null): void {
    if (this.stepForm.invalid || (this.canCheckPassword && this.needCheckPassword)) {
      return;
    }

    FormsUtil.triggerValidation(this.stepForm);
    this.resetValidStateForValidFields();
  }

  private changeIsCryptWithPasswordHandler(event): void {
    this.toggleFormControls(['password'], !!event);
  }

  changeTypePassword(): void {
    this.showPassword = !this.showPassword;
  }

  showPasswordHintChangeHandler(): void {
    this.showHint = !this.showHint;
  }

  passwordFocusHandler(event): void {
    if (event?.target?.value && this.InitialValue && event.target.value === this.InitialValue.password) {
      this.stepForm.get('password').setValue('');
    }
  }
}
