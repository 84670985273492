import { Component, Input } from '@angular/core';
import { ErrorType, ImagePathByErrorType } from './error.constants';

@Component({
  selector: 'mbs-error',
  templateUrl: 'error.component.html'
})
export class ErrorComponent {
  readonly errorType = ErrorType;
  readonly imagePath = ImagePathByErrorType;
  @Input() type: ErrorType;
  @Input() hideNotFoundLink = false;

  refresh() {
    location.reload();
  }

  getI18NPath(part: string) {
    return `error:type.${this.type}.${part}`;
  }
}
