import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Build, BuildsInfo } from '@models/build';
import { BuildType } from '@models/BuildType.enum';
import { AgentType } from '@models/Computer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonResponseType, RequestBuildOptionsType } from './build-service.types';

@Injectable({
  providedIn: 'root'
})
export class BuildService {
  private readonly controllerPath = '/api/build';

  constructor(private http: HttpClient) {}

  getAll(): Observable<BuildsInfo> {
    return this.http.get<BuildsInfo>(`${this.controllerPath}/info`);
  }

  getDownloadInfo(): Observable<{ builds: Build[] }> {
    return this.http.get<{ builds: Build[] }>(`${this.controllerPath}/downloads-info`);
  }

  requestBuild({ buildsTypeList: builds, isBuildDestinationSandbox }: RequestBuildOptionsType): Observable<CommonResponseType> {
    return this.http.post<CommonResponseType>(`${this.controllerPath}/request`, {
      builds,
      isBuildDestinationSandbox
    });
  }

  removeBuild({ mbsBuildType: type }: Build, isSandboxBuild: boolean): Observable<CommonResponseType> {
    return this.http.post<CommonResponseType>(`${this.controllerPath}/remove`, {
      type,
      isSandboxBuild
    });
  }

  makePublic({ mbsBuildType: type }: Build): Observable<CommonResponseType> {
    return this.http.post<CommonResponseType>(`${this.controllerPath}/make-public`, { type });
  }

  updateDownloadSettings(settings: Partial<{ isSandboxEnabled: boolean; isAutoUpdateEnabled: boolean }>): Observable<CommonResponseType> {
    return this.http.post<CommonResponseType>(`${this.controllerPath}/downloads/update-settings`, settings);
  }

  isSandBoxAvailable(): Observable<boolean> {
    return this.getAll().pipe(map((data) => data?.sandBoxAvailable ?? false));
  }

  getActiveBuildRequest(): Observable<{ requestedBuild: BuildType }[]> {
    return this.http.get<{ requestedBuild: BuildType }[]>(`${this.controllerPath}/active-build-request`);
  }

  getNewVersions(
    builds: Build[],
    isSandBoxAvailable: boolean
  ): {
    backupVersions: string[];
    rmmVersions: string[];
  } {
    const getSection = (build: Build, isSandBoxAvailable: boolean) =>
      isSandBoxAvailable && build?.sandbox ? build?.sandbox : build?.public;

    const getVersions = (builds: Build[], isSandBoxAvailable: boolean): string[] => {
      return builds
        .filter((build) => getSection(build, isSandBoxAvailable)?.isNewerVersionAvailable)
        .sort((first, second) => first.mbsBuildType - second.mbsBuildType)
        .map((build) => getSection(build, isSandBoxAvailable)?.availableVersion);
    };

    const backupAgentsNewVersions = getVersions(
      builds.filter((build) => build.applicationId === AgentType.Backup),
      isSandBoxAvailable
    );
    const rmmAgentsVersions = getVersions(
      builds.filter((build) => build.applicationId === AgentType.RMM),
      isSandBoxAvailable
    );

    return {
      backupVersions: backupAgentsNewVersions,
      rmmVersions: rmmAgentsVersions
    };
  }

  generateHashCode(versions: string[]): string {
    const hash = versions
      .join('')
      .split('')
      .reduce((acc, char) => {
        acc = (acc << 5) - acc + char.charCodeAt(0);
        return acc & acc;
      }, 0);

    return String(hash);
  }
}
